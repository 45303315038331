import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { Box, FormControlLabel, IconButton, Modal, Tooltip } from "@mui/material";
import CloseRounded from "@mui/icons-material/CloseRounded";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import PhoneInput from "react-phone-input-2";
import { SketchPicker, ColorResult } from "react-color";

import pencil from "../../assets/images/editPencil.svg";
import pencilText from "../../assets/images/editPencilText.svg";
import designOne from "../../assets/images/tip-design-one.png";
import designTwo from "../../assets/images/tip-design-two.png";
import designThree from "../../assets/images/tip-design-three.png";
import back from "../../assets/images/back.svg";
import avatar from "../../assets/images/avatar1.svg";
import warning from "../../assets/images/warning.svg";
import dragImg from "../../assets/images/drag.svg";
import pen from "../../assets/images/pen.svg";
import reviewlogo from "../../assets/images/drag-drop.svg";
import dragdrop from "../../assets/images/drag-drop.svg";
import reviewSkl from "../../assets/images/review-skl.svg";
import reviewBg from "../../assets/images/review-bg.svg";


import {
  BUSINESS_CLASSIFICATION_LLC,
  PAGINATION_NUM_ROWS,
  PHONEINPUT_COUNTRIES,
  PHONEINPUT_COUNTRYCODE_EDITABLE,
  ROOM_NAME_LABEL,
  ROOM_NAME_REMOVE_LABEL,
  ROOM_NAME_REMOVE_VALUE,
  ROOM_NAME_VALUE,
  ROOM_NUMBER_LABEL,
  ROOM_NUMBER_VALUE,
  ROUTE_ACCOUNTS_LIST,
  ROUTE_ACCOUNT_GROUP,
  ROUTE_HOTEL_USERS,
  ROUTE_STAFF_LIST,
  ROUTE_TIPS_LIST,
  US_STATES_ARRAY,
  TIP_DESIGN_1,
  TIP_DESIGN_2,
  TIP_DESIGN_3,
  TIP_FACT_BODY_MAX_LENGTH,
  TIP_THEME_DEFAULT_COLOR,
  TIP_THEME_DEFAULT_TEXT_COLOR,
  REVIEW_PLATFORM_DEFAULT_BG_COLOR,
  REVIEW_PLATFORM_DEFAULT_TEXT_COLOR,
  REVIEW_HEADER_MAX_LENGTH,
  REVIEW_BODY_MAX_LENGTH,
  REVIEW_PLATFORM_NAME_MAX_LENGTH,
  TIP_CONFIRM_MSG_MAX_LENGTH,
  ACCOUNT_MIN_COMMISSION,
  PAGENAME_ACCOUNT_DETAILS,
  USER_ROLE_ACCOUNT_MANAGER,
  USER_ROLE_ADMIN_SUPERADMIN
} from "../../config/env";
import { ROUTE_QRCODES } from "../../config/env_qrcodes";

import {
  activateAccount,
  createNewGroup,
  deactivateAccount,
  fetchAccountDetails,
  removeAccountLogo,
  updateAccountDetail,
  updateBankAccountDetail,
  uploadAccountLogo,
  updateAccTipSettings,
  uploadAccountTipLogo,
  uploadAccountTipImage,
  removeAccountTipLogo,
  removeAccountTipImage,
  updateAccReviewTitleBody,
  updateAccReviewSettings,
  updateTipConfirmationMessage,
  uploadReviewPlatformLinkLogo,
  updateReviewPlatformsOrder,
  modifyAccPrimaryUser,
} from "../../services/accountDetailsServices";
import {
  getAdminUserRole,
  getFilterAccountId,
  setLastActivePageAPIData,
} from "../../services/localStorage";

import {
  IAccountDetails,
  IAccountDetailsReq,
  IAccountReviewLink,
  IAccountReviewOrderReset,
  IAccountTipSettings,
  INewAccountGroup,
  IReviewLogoUpload,
  IAccountPrimaryUser
} from "../../models/accountDetailsInterfaces";

import { useAppDispatch, useAppSelector } from "../../helpers/hook";

import { selectAccountDetailsSlice, resetLoading } from "./accountDetailsSlice";

import { ButtonPrimary } from "../../components/ButtonGroups/ButtonPrimary";
import { ButtonInLine } from "../../components/ButtonGroups/ButtonInLine";
import { ButtonModal } from "../../components/ButtonGroups/ButtonModal";
import { Textfield } from "../../components/TextField";
import { SelectField } from "../../components/SelectField";
import { GROUP_NAME_EXISTS } from "../../config/apiResponseCodes";
import { setNotification } from "../../services/apiService";

import {
  selectCommonSlice,
  resetReloadGroupSubMenu,
} from "../CommonSlice/commonSlice";
import {
  PERM_ACTIVATE_DEACTIVATE_ACCOUNT,
  PERM_ADD_NEW_GROUP,
  PERM_EDIT_ACCOUNT_COMMISSION,
  PERM_EDIT_ACCOUNT_DETAILS,
  PERM_QRCODE_ORDER,
  PERM_STAFF_MANAGE,
  PERM_VIEW_ACCOUNT_USERS,
  checkActionPermission,
} from "../../config/permissions";
import {
  filterDigitsOnly,
  getStateNameFromCode,
} from "../../config/common_functions";
import dayjs from "dayjs";
import ImageBox from "../../components/ImageBox/ImageBox";
import TipTemplateTwo from "../../components/CustomTipDesign/TipTemplateTwo";
import TipTemplateOne from "../../components/CustomTipDesign/TipTemplateOne";
import TipTemplateThree from "../../components/CustomTipDesign/TipTemplateThree";
import { isValidFileExtension } from "../../helpers/validators";

interface CustomizedState {
  prevPage: number;
  prevSearch: string;
  prevActive: string;
  prevFromDate: string;
  prevToDate: string;
  prevDateFilterType: string;
  prevSort: string;
  prevNumRowsPerPage: number;
}

function AccountDetail() {
  const pageName = PAGENAME_ACCOUNT_DETAILS;
  const accountId = getFilterAccountId();
  const adminUserRole = getAdminUserRole();
  const { accountDetails, loading, accountLogo, reviewLinkLogoURL } =
    useAppSelector(selectAccountDetailsSlice);
  const { reloadGroupSubMenu } = useAppSelector(selectCommonSlice);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location.state as CustomizedState; // Type Casting, then you can get the params passed via router

  const canEditAccountDetails = checkActionPermission(adminUserRole, PERM_EDIT_ACCOUNT_DETAILS);

  const prevPage = stateData && stateData.prevPage ? stateData.prevPage : 1;
  const prevSearch =
    stateData && stateData.prevSearch ? stateData.prevSearch : "";
  const prevActive =
    stateData && stateData.prevActive ? stateData.prevActive : "";
  const prevFromDate =
    stateData && stateData.prevFromDate ? stateData.prevFromDate : "";
  const prevToDate =
    stateData && stateData.prevToDate ? stateData.prevToDate : "";
  const prevDateFilterType =
    stateData && stateData.prevDateFilterType
      ? stateData.prevDateFilterType
      : "";
  const prevSort = stateData && stateData.prevSort ? stateData.prevSort : "";
  const prevNumRowsPerPage =
    stateData && stateData.prevNumRowsPerPage
      ? stateData.prevNumRowsPerPage
      : PAGINATION_NUM_ROWS;

  const style = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "13px",
    boxShadow: 24,
  };
  const style1 = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 460,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "13px",
    boxShadow: 24,
  };
  const styleLogoPopup = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "auto",
    bgcolor: 'background.paper',
    border: '1px solid white',
    borderRadius: 1,
    boxShadow: 24,
    p: 3,
  };
  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 52,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(26px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#2CC56F",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const [selectDesign, setSelectDesign] = useState<Number>(TIP_DESIGN_1);
  const [editBgColor, setEditBgColor] = useState<any>(TIP_THEME_DEFAULT_COLOR);
  const [editTextColor, setEditTextColor] = useState<any>(TIP_THEME_DEFAULT_TEXT_COLOR);
  const [selectedTip, setSelectedTip] = useState<Number>(1);
  const [selectedTipPrev, setSelectedTipPrev] = useState<Number>(1);
  const [editTipDetails, setEditTipDetails] = useState<boolean>(false);
  const [tip_1, setTip_1] = useState(0);
  const [tip_2, setTip_2] = useState(0);
  const [tip_3, setTip_3] = useState(0);
  const [tip_4, setTip_4] = useState(0);

  const [tippingFactBody_1, setTippingFactBody_1] = useState("");
  const [tippingFactBody_2, setTippingFactBody_2] = useState("");
  const [tippingFactBody_3, setTippingFactBody_3] = useState("");
  const [tippingFactBody_4, setTippingFactBody_4] = useState("");
  const [roomLabel, setRoomLabel] = useState(1);
  const [roomLabelMandatory, setRoomLabelMandatory] = useState(false);
  const [staffLabelForGroups, setStaffLabelForGroups] = useState(false);
  const [showCustomOnly, setShowCustomOnly] = useState(false);
  const [showCustomOnlyPrev, setShowCustomOnlyPrev] = useState(false);
  const [tipIncludeProcessFee, setTipIncludeProcessFee] = useState(true);

  const [tipLogo, setTipLogo] = useState<any>(null);
  const [tipBackgroungImg, setTipBackgroungImg] = useState<any>(null);
  const [resetTipLogo, setResetTipLogo] = useState<number>(0);
  const [resetTipImage, setResetTipImage] = useState<number>(0);

  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [prevConfirmationMessage, setPrevConfirmationMessage] = useState("");

  const [showReviewItemDetails, setShowReviewItemDetails] = useState(0);
  const [reviewTitle, setReviewTitle] = useState("");
  const [reviewDescription, setReviewDescription] = useState("");
  const [prevReviewTitle, setPrevReviewTitle] = useState("");
  const [prevReviewDescription, setPrevReviewDescription] = useState("");

  const [reviewPlatforms, setReviewPlatforms] = useState<IAccountReviewLink[]>(
    []
  );
  const [prevReviewPlatforms, setPrevReviewPlatforms] = useState<
    IAccountReviewLink[]
  >([]);
  const [lastEditedReviewPlatformId, setLastEditedReviewPlatformId] =
    useState<number>(0);
  const [lastReviewImageReviewId, setLastReviewImageReviewId] =
    useState<number>(0);

  const [reviewActiveItemCount, setReviewActiveItemCount] = useState<number>(0);

  // SAVE REFERENCE FOR DRAG-ITEM AND DRAGOVER-ITEM
  const dragReviewItemId = React.useRef<any>(null);
  const dragOverReviewItemId = React.useRef<any>(null);

  //const handle drag sorting
  const handleSort = () => {
    if (dragReviewItemId.current != dragOverReviewItemId.current) {
      //duplicate items
      let _reviewPlatforms = [...reviewPlatforms];

      //remove and save the dragged item content
      const draggedItemContent = _reviewPlatforms.splice(
        dragReviewItemId.current,
        1
      )[0];

      //switch the position
      _reviewPlatforms.splice(
        dragOverReviewItemId.current,
        0,
        draggedItemContent
      );

      //reset the position ref
      dragReviewItemId.current = null;
      dragOverReviewItemId.current = null;

      //update the actual array
      setReviewPlatforms(_reviewPlatforms);

      let newOrderIds = _reviewPlatforms.map((item) => {
        return item.id;
      });
      if (newOrderIds && newOrderIds.length > 0) {
        let newOrderIdsObj: IAccountReviewOrderReset = {
          reviewOrder: newOrderIds.join(","),
          userRole: Number(adminUserRole),
        };
        dispatch(updateReviewPlatformsOrder(newOrderIdsObj));
      }
    }
    // console.log('newOrderIds: ', newOrderIds);
  };

  const dummyFunction = () => {
    return false;
  };

  const changeReviewLinkValue = (
    rowOrder: number,
    fieldName: string,
    fieldValue: any
  ) => {
    let newOpts: IAccountReviewLink[] = [];
    reviewPlatforms.map((rItem) => {
      if (rItem.order == rowOrder) {
        if (fieldName == "name") {
          newOpts.push({ ...rItem, name: fieldValue });
        } else if (fieldName == "link") {
          newOpts.push({ ...rItem, link: fieldValue });
        } else if (fieldName == "backgroundColor") {
          newOpts.push({ ...rItem, backgroundColor: fieldValue });
        } else if (fieldName == "titleColor") {
          newOpts.push({ ...rItem, titleColor: fieldValue });
        } else if (fieldName == "isActive") {
          newOpts.push({ ...rItem, isActive: fieldValue, isOpen: true });
        } else if (fieldName == "order") {
          newOpts.push({ ...rItem, order: fieldValue });
        } else if (fieldName == "logo") {
          newOpts.push({ ...rItem, logo: fieldValue });
        } else if (fieldName == "isOpen") {
          newOpts.push({ ...rItem, isOpen: fieldValue });
        }
      } else {
        newOpts.push(rItem);
      }
    });
    setReviewPlatforms(newOpts);
  };

  const resetReviewLink = (reviewId: number) => {
    if (prevReviewPlatforms && prevReviewPlatforms.length) {
      let arrReviewLinks = prevReviewPlatforms.filter(
        (item) => item.id == reviewId
      );
      let arrOptions: IAccountReviewLink[] = [];
      reviewPlatforms.forEach((element) => {
        if (element.id == reviewId) {
          arrOptions.push({
            ...element,
            name: arrReviewLinks[0].name ? arrReviewLinks[0].name : "",
            link: arrReviewLinks[0].link ? arrReviewLinks[0].link : "",
            backgroundColor: arrReviewLinks[0].backgroundColor
              ? arrReviewLinks[0].backgroundColor
              : "",
            titleColor: arrReviewLinks[0].titleColor
              ? arrReviewLinks[0].titleColor
              : "",
            isActive: arrReviewLinks[0].isActive
              ? arrReviewLinks[0].isActive
              : false,
            logo: arrReviewLinks[0].logo ? arrReviewLinks[0].logo : "",
            isOpen: false,
          });
        } else {
          arrOptions.push(element);
        }
      });
      setReviewPlatforms(arrOptions);
    }
  };

  const saveReviewLinkValues = (reviewId: number) => {
    let newOpts: IAccountReviewLink = {};
    let arrReviewLinkValues = reviewPlatforms.filter(
      (item) => item.id == reviewId
    );
    let foundError = false;
    let msgText = "";

    if (
      !arrReviewLinkValues[0].name ||
      arrReviewLinkValues[0].name.trim().length == 0
    ) {
      foundError = true;
      msgText = t("messages.reviewLinkNameError");
    } else if (
      !arrReviewLinkValues[0].link ||
      arrReviewLinkValues[0].link.trim().length < 3
    ) {
      foundError = true;
      msgText = t("messages.reviewLinkLinkError");
    } else if (
      !arrReviewLinkValues[0].backgroundColor ||
      arrReviewLinkValues[0].backgroundColor.trim().length < 3
    ) {
      foundError = true;
      msgText = t("messages.reviewLinkBgError");
    } else if (
      !arrReviewLinkValues[0].titleColor ||
      arrReviewLinkValues[0].titleColor.trim().length < 3
    ) {
      foundError = true;
      msgText = t("messages.reviewLinkTextError");
    }

    if (foundError) {
      setNotification(t("projectName"), msgText, "failed", "addAccount");
      return false;
    } else {
      let reviewLinkData: IAccountReviewLink = {
        id: arrReviewLinkValues[0].id,
        name: arrReviewLinkValues[0].name,
        link: arrReviewLinkValues[0].link,
        backgroundColor: arrReviewLinkValues[0].backgroundColor,
        titleColor: arrReviewLinkValues[0].titleColor,
        isActive: arrReviewLinkValues[0].isActive
          ? arrReviewLinkValues[0].isActive
          : false,
        userRole: Number(adminUserRole),
      };
      setLastEditedReviewPlatformId(
        arrReviewLinkValues[0].id ? arrReviewLinkValues[0].id : 0
      );
      dispatch(updateAccReviewSettings(reviewLinkData));
    }
  };

  const [platformOne, setPlatformOne] = useState({
    set: true,
    platformName: "TripAdvisor",
    link: "",
    logo: "",
    backgroundColor: "",
    textColor: "",
  });
  const [platformTwo, setPlatformTwo] = useState({
    set: true,
    platformName: "Google Review",
    link: "",
    logo: null,
    backgroundColor: "",
    textColor: "",
  });
  const [platformThree, setPlatformThree] = useState({
    set: true,
    platformName: "Custom One",
    link: "",
    logo: null,
    backgroundColor: "",
    textColor: "",
  });
  const [platformFour, setPlatformFour] = useState({
    set: false,
    platformName: "Custom Two",
    link: "",
    logo: null,
    backgroundColor: "",
    textColor: "",
  });

  const [addGroup, setAddGroup] = useState(false);
  const [groupItem, setGroupItem] = useState([{ id: 0, name: "" }]);

  const [statusPopup, setStatusPopup] = useState(false);

  const [editGeneralInformation, setEditGeneralInformation] = useState(false);
  const [generalInformation, setGeneralInformation] = useState({
    hotelName: "",
    accountCode: "",
    companyName: "",
    phone: "",
    mail: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    contactFirstName: "",
    contactLastName: "",
    contactJobTitle: "",
    memberSince: "",
    status: "",
    accPrimaryUserId: 0,
    accPrimaryUserName: ""
  });
  const [accountLogoImg, setAccountLogoImg] = useState(avatar);
  const businessClassList = [
    {
      id: BUSINESS_CLASSIFICATION_LLC,
      value: t("businessDetails.businessClassificationLLC"),
    },
  ];
  const [businessClassification, setBusinessClassification] = useState(
    BUSINESS_CLASSIFICATION_LLC.toString()
  );
  const [numRooms, setNumRooms] = useState("");
  const [numEmployees, setNumEmployees] = useState("");
  const [businessClassificationPrev, setBusinessClassificationPrev] = useState(
    BUSINESS_CLASSIFICATION_LLC.toString()
  );
  const [numRoomsPrev, setNumRoomsPrev] = useState("");
  const [numEmployeesPrev, setNumEmployeesPrev] = useState("");

  const [generalInformationPrev, setGeneralInformationPrev] = useState({
    hotelName: "",
    accountCode: "",
    companyName: "",
    phone: "",
    mail: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    contactFirstName: "",
    contactLastName: "",
    contactJobTitle: "",
    memberSince: "",
    status: "",
    accPrimaryUserId: 0,
    accPrimaryUserName: "",
  });

  const [editPaymentSetting, setEditPaymentSetting] = useState(false);
  const [paymentDetailsPrev, setPaymentDetailsPrev] = useState({
    bankName: "",
    accountName: "",
    accountNumber: "",
    routingNumber: "",
    paymentCommission: 0,
  });
  const [paymentDetails, setPaymentDetails] = useState({
    bankName: "",
    accountName: "",
    accountNumber: "",
    routingNumber: "",
    paymentCommission: 0,
  });

  const [editPaymentWithHold, setEditPaymentWithHold] = useState(false);
  const [paymentWithHoldPrev, setPaymentWithHoldPrev] = useState({
    taxForStaff: false,
    percentage: 0,
  });
  const [paymentWithHold, setPaymentWithHold] = useState({
    taxForStaff: false,
    percentage: "0",
  });
  const [paymentCommissionError, setPaymentCommissionError] = useState("");
  const [paymentWithHoldError, setPaymentWithHoldError] = useState("");

  const [editConfirmMessage, setEditConfirmMessage] = useState(false);
  const [editReviewLinks, setEditReviewLinks] = useState(false);

  const [newGroupName, setNewGroupName] = useState("");
  const [newGroupNameError, setNewGroupNameError] = useState(false);


  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const handleOpen = () => {
    if (accountLogoImg && accountLogoImg.trim().length > 3 && accountLogoImg !== avatar) {
      setOpen(true);
    }
  }

  useEffect(() => {
    fetchAll();
  }, [dispatch]);

  useEffect(() => {
    if (reviewPlatforms) {
      let arrActive = reviewPlatforms.filter((item) => item.isActive == true);
      setReviewActiveItemCount(arrActive ? arrActive.length : 0);
    }
  }, [reviewPlatforms]);

  useEffect(() => {
    if (addGroup) {
      setNewGroupName("");
    }
  }, [addGroup]);

  useEffect(() => {
    if (newGroupName.trim().length > 0) {
      setNewGroupNameError(false);
    }
  }, [newGroupName]);

  useEffect(() => {
    if (loading === "statusChangeSuccess") {
      setGeneralInformation({
        ...generalInformation,
        status:
          generalInformation.status == t("statusFilter.active")
            ? t("statusFilter.inactive")
            : t("statusFilter.active"),
      });
    }
    if (loading === "succeeded") {
      setTipLogo(null);
      setTipBackgroungImg(null);
      setDataLoaded(false);

      let accManager = accountDetails.accountManagers?.filter(item => item.primaryUser === true);

      setGeneralInformation({
        hotelName: accountDetails.hotelName ? accountDetails.hotelName : "",
        accountCode: accountDetails.hotelCodeId
          ? accountDetails.hotelCodeId
          : "",
        companyName: accountDetails.companyName
          ? accountDetails.companyName
          : "",
        phone: accountDetails.mobileNumber ? accountDetails.mobileNumber : "",
        mail: accountDetails.communicationEmail
          ? accountDetails.communicationEmail
          : "",
        address: accountDetails.address ? accountDetails.address : "",
        city: accountDetails.city ? accountDetails.city : "",
        state: accountDetails.state ? accountDetails.state : "",
        zip: accountDetails.zip ? accountDetails.zip : "",

        contactFirstName: accountDetails.firstName
          ? accountDetails.firstName
          : "",
        contactLastName: accountDetails.lastName ? accountDetails.lastName : "",
        contactJobTitle: accountDetails.jobTitle ? accountDetails.jobTitle : "",
        memberSince: accountDetails.memberSince
          ? accountDetails.memberSince
          : "",
        accPrimaryUserId: accManager && accManager.length > 0 ? accManager[0].id : 0,
        accPrimaryUserName: accManager && accManager.length > 0 ? accManager[0].name : "",
        status: accountDetails.isActive
          ? accountDetails.isActive && t("statusFilter.active")
          : t("statusFilter.inactive"),
      });

      setNumEmployees(
        accountDetails.numberOfEmployees
          ? accountDetails.numberOfEmployees.toString()
          : ""
      );
      setNumRooms(
        accountDetails.numberOfRooms
          ? accountDetails.numberOfRooms.toString()
          : ""
      );
      setBusinessClassification(
        accountDetails.businessClassification
          ? accountDetails.businessClassification.toString()
          : ""
      );

      setAccountLogoImg(accountDetails.logo ? accountDetails.logo : avatar);
      setGroupItem(
        accountDetails.pools ? accountDetails.pools : [{ id: 0, name: "" }]
      );

      setPaymentDetails({
        bankName: accountDetails.bankDetails?.bankName
          ? accountDetails.bankDetails?.bankName
          : "",
        accountName: accountDetails.bankDetails?.accountHolderName
          ? accountDetails.bankDetails?.accountHolderName
          : "",
        accountNumber: accountDetails.bankDetails?.accountNumber
          ? accountDetails.bankDetails?.accountNumber
          : "",
        routingNumber: accountDetails.bankDetails?.routingNumber
          ? accountDetails.bankDetails?.routingNumber
          : "",
        paymentCommission: accountDetails?.paymentCommission
          ? Number(accountDetails.paymentCommission)
          : 0,
      });

      setPaymentWithHold({
        taxForStaff: accountDetails.taxWithholdForStaff
          ? accountDetails.taxWithholdForStaff
          : false,
        percentage: accountDetails.staffWithholdPercent
          ? accountDetails.staffWithholdPercent.toString()
          : "0",
      });



      setSelectDesign(accountDetails.tipDesignType ? accountDetails.tipDesignType : TIP_DESIGN_1);
      setEditBgColor(accountDetails.tipBgColor ? accountDetails.tipBgColor : TIP_THEME_DEFAULT_COLOR);

      setEditTextColor(accountDetails.tipDesignType === TIP_DESIGN_1 ? accountDetails.headerBgColor : accountDetails.tipFontColor ? accountDetails.tipFontColor : TIP_THEME_DEFAULT_TEXT_COLOR);

      setTip_1(accountDetails.tip_1 ? accountDetails.tip_1 : 0);
      setTip_2(accountDetails.tip_2 ? accountDetails.tip_2 : 0);
      setTip_3(accountDetails.tip_3 ? accountDetails.tip_3 : 0);
      setTip_4(accountDetails.tip_4 ? accountDetails.tip_4 : 0);
      setTippingFactBody_1(
        accountDetails.tipDescription_1 ? accountDetails.tipDescription_1 : ""
      );
      setTippingFactBody_2(
        accountDetails.tipDescription_2 ? accountDetails.tipDescription_2 : ""
      );
      setTippingFactBody_3(
        accountDetails.tipDescription_3 ? accountDetails.tipDescription_3 : ""
      );
      setTippingFactBody_4(
        accountDetails.tipDescription_4 ? accountDetails.tipDescription_4 : ""
      );

      setRoomLabel(
        accountDetails.roomLabel ? accountDetails.roomLabel : ROOM_NUMBER_VALUE
      );
      setRoomLabelMandatory(
        accountDetails.roomLabelMandatory
          ? accountDetails.roomLabelMandatory
          : false
      );

      setStaffLabelForGroups(accountDetails.staffLabelForGroups ? accountDetails.staffLabelForGroups : false);
      
      setShowCustomOnly(
        accountDetails.showCustomTipOnly
          ? accountDetails.showCustomTipOnly
          : false
      );
      setSelectedTip(
        accountDetails.defaultTipPreset
          ? accountDetails.defaultTipPreset
          : 1
      );
      setShowCustomOnlyPrev(
        accountDetails.showCustomTipOnly
          ? accountDetails.showCustomTipOnly
          : false
      );
      setSelectedTipPrev(
        accountDetails.defaultTipPreset
          ? accountDetails.defaultTipPreset
          : 1
      );

      setConfirmationMessage(
        accountDetails.confirmationMessage
          ? accountDetails.confirmationMessage
          : ""
      );
      setPrevConfirmationMessage(
        accountDetails.confirmationMessage
          ? accountDetails.confirmationMessage
          : ""
      );

      setReviewTitle(
        accountDetails.reviewTitle ? accountDetails.reviewTitle : ""
      );
      setReviewDescription(
        accountDetails.reviewBody ? accountDetails.reviewBody : ""
      );
      setPrevReviewTitle(
        accountDetails.reviewTitle ? accountDetails.reviewTitle : ""
      );
      setPrevReviewDescription(
        accountDetails.reviewBody ? accountDetails.reviewBody : ""
      );

      if (accountDetails.reviewLinks && accountDetails.reviewLinks.length) {
        let arrReviewLinks = accountDetails.reviewLinks;
        let arrOptions: IAccountReviewLink[] = [];
        arrReviewLinks.forEach((element) => {
          arrOptions.push({
            ...element,
            isOpen: false,
            backgroundColor:
              !element.backgroundColor ||
                element.backgroundColor.trim().length == 0
                ? REVIEW_PLATFORM_DEFAULT_BG_COLOR
                : element.backgroundColor,
            titleColor:
              !element.titleColor || element.titleColor.trim().length == 0
                ? REVIEW_PLATFORM_DEFAULT_TEXT_COLOR
                : element.titleColor,
          });
        }); // REVIEW_PLATFORM_DEFAULT_TEXT_COLOR
        setReviewPlatforms(arrOptions);
        setPrevReviewPlatforms(arrOptions);
      }

      setTipLogo(accountDetails.tipLogo ? accountDetails.tipLogo : null);
      setTipBackgroungImg(
        accountDetails.tipImage ? accountDetails.tipImage : null
      );

      setTimeout(() => {
        setDataLoaded(true);
      }, 1250);
    }

    if (loading === "uploaded") {
      setAccountLogoImg(accountLogo ? accountLogo : avatar);
    } // 'accTipLogoUploaded' | 'accTipImageUploaded'
    else if (loading === "accTipLogoRemoved") {
      setTipLogo(null);
      setResetTipLogo(resetTipLogo + 1);
    } else if (loading === "accTipImageRemoved") {
      setTipBackgroungImg(null);
      setResetTipImage(resetTipImage + 1);
    } else if (loading === "groupCreated") {
      dispatch(resetLoading());
      setAddGroup(false);
      fetchAll();
      dispatch(resetReloadGroupSubMenu(reloadGroupSubMenu + 1));
    } else if (
      loading === "groupCreationFailed" ||
      loading === GROUP_NAME_EXISTS
    ) {
      dispatch(resetLoading());
    }

    if (loading === "tipSettingsUpdated") {
      setEditTipDetails(false);
      setShowCustomOnlyPrev(showCustomOnly);
      setSelectedTipPrev(selectedTip);
      dispatch(resetLoading());
    } else if (loading === "tipConfirmationMessageUpdated") {
      setPrevConfirmationMessage(confirmationMessage);
      setEditConfirmMessage(false);
    } else if (loading === "reviewTitleBodyUpdated") {
      setPrevReviewTitle(reviewTitle);
      setPrevReviewDescription(reviewDescription);
      setEditReviewLinks(false);
      dispatch(resetLoading());
    } else if (loading === "accountReviewPlatformDataUpdated") {
      setPrevReviewPlatforms(reviewPlatforms);
      if (lastEditedReviewPlatformId && lastEditedReviewPlatformId > 0) {
        let arrOptions: IAccountReviewLink[] = [];
        reviewPlatforms.forEach((element) => {
          if (element.id == lastEditedReviewPlatformId) {
            arrOptions.push({ ...element, isOpen: false });
          } else {
            arrOptions.push(element);
          }
        });
        setReviewPlatforms(arrOptions);
        setLastEditedReviewPlatformId(0);
      }
      dispatch(resetLoading());
    } else if (loading === "accReviewLogoUploaded") {
      if (
        lastReviewImageReviewId &&
        lastReviewImageReviewId > 0 &&
        reviewLinkLogoURL &&
        reviewLinkLogoURL.length > 0
      ) {
        let arrOptions: IAccountReviewLink[] = [];
        reviewPlatforms.forEach((element) => {
          if (element.id == lastReviewImageReviewId) {
            arrOptions.push({ ...element, logo: reviewLinkLogoURL });
          } else {
            arrOptions.push(element);
          }
        });
        setReviewPlatforms(arrOptions);
        // UPDATE LOGO URL IN PREV LINK DETAILS ALSO
        let arrPrevOptions: IAccountReviewLink[] = [];
        prevReviewPlatforms.forEach((element) => {
          if (element.id == lastReviewImageReviewId) {
            arrPrevOptions.push({ ...element, logo: reviewLinkLogoURL });
          } else {
            arrPrevOptions.push(element);
          }
        });
        setPrevReviewPlatforms(arrPrevOptions);
        setLastEditedReviewPlatformId(0);
      }
      dispatch(resetLoading());
    }
  }, [loading]);

  const fetchAll = () => {
    if (Number.isFinite(Number(accountId)) && Number(accountId)) {
      const req: IAccountDetailsReq = {
        accountId: Number(accountId),
        userRole: Number(adminUserRole),
      };
      dispatch(fetchAccountDetails(req));
      setLastActivePageAPIData({ pageName: pageName, apiData: req });
    }
  };

  const numberOnly = (numVal: string, fieldType: string) => {
    let newValue = filterDigitsOnly(numVal);
    if (fieldType == "numRooms") {
      setNumRooms(newValue);
    } else if (fieldType == "numEmployees") {
      setNumEmployees(newValue);
    }
  };

  const editButton = (name): any => {
    const handleEdit = (name) => {
      if (name === "editPaymentSetting") {
        setPaymentDetailsPrev({
          bankName: paymentDetails.bankName,
          accountName: paymentDetails.accountName,
          accountNumber: paymentDetails.accountNumber,
          routingNumber: paymentDetails.routingNumber,
          paymentCommission: paymentDetails.paymentCommission,
        });
        setEditPaymentSetting(true);
      } else if (name === "editGeneralInformation") {
        setGeneralInformationPrev({
          hotelName: generalInformation.hotelName,
          accountCode: generalInformation.accountCode,
          companyName: generalInformation.companyName,
          phone: generalInformation.phone,
          mail: generalInformation.mail,
          address: generalInformation.address,
          city: generalInformation.city,
          state: generalInformation.state,
          zip: generalInformation.zip,
          contactFirstName: generalInformation.contactFirstName,
          contactLastName: generalInformation.contactLastName,
          contactJobTitle: generalInformation.contactJobTitle,
          memberSince: generalInformation.memberSince,
          accPrimaryUserId: generalInformation.accPrimaryUserId,
          accPrimaryUserName: generalInformation.accPrimaryUserName,
          status: generalInformation.status,
        });
        setBusinessClassificationPrev(businessClassification);
        setNumRoomsPrev(numRooms);
        setNumEmployeesPrev(numEmployees);
        setEditGeneralInformation(true);
      } else if (name === "editPaymentWithHold") {
        setPaymentWithHoldPrev({
          taxForStaff: paymentWithHold.taxForStaff,
          percentage: paymentWithHold.percentage,
        });
        setEditPaymentWithHold(true);
      } else if (name === "editReviewLinks") {
        setEditReviewLinks(true);
      } else if (name == "tipSettings") {
        setEditTipDetails(true);
      } else if (name == "tipConfirmMessage") {
        setEditConfirmMessage(true);
      }
    };
    return (
      <ButtonInLine
        type="edit_or_save"
        onClick={(e) => handleEdit(name)}
        buttonLabel={t("common.edit")}
        penIcon={true}
      />
    );
  };


  const saveButton = (name): any => {
    const saveEdit = (name) => {
      if (name === "editPaymentSetting") {
        let pcPerc = paymentDetails.paymentCommission
          ? Number(paymentDetails.paymentCommission)
          : 0;
        if (pcPerc > 100 || pcPerc <= 0) {
          setPaymentCommissionError(" input-error ");
        } else if (Number(paymentDetails.paymentCommission) < Number(ACCOUNT_MIN_COMMISSION)) {
          setPaymentCommissionError(" input-error ");
          setNotification(
            t("messages.appName"),
            t("messages.accCommissionMinValueError", { accCommMinValue: ACCOUNT_MIN_COMMISSION }),
            "failed",
            "updateBankAccountDetail"
          );
        } else {
          setPaymentCommissionError("");
          if (
            (paymentDetails.bankName.length !== 0 &&
              paymentDetails.bankName !== "" &&
              paymentDetails.routingNumber.length !== 0 &&
              paymentDetails.routingNumber !== "" &&
              paymentDetails.accountName.length !== 0 &&
              paymentDetails.accountName !== "" &&
              paymentDetails.accountNumber.length !== 0 &&
              paymentDetails.accountNumber !== "" &&
              paymentDetails.paymentCommission > 0 &&
              paymentDetails.paymentCommission) ||
            (paymentDetails.bankName.length == 0 &&
              paymentDetails.bankName == "" &&
              paymentDetails.routingNumber.length == 0 &&
              paymentDetails.routingNumber == "" &&
              paymentDetails.accountName.length == 0 &&
              paymentDetails.accountName == "" &&
              paymentDetails.accountNumber.length == 0 &&
              paymentDetails.accountNumber == "" &&
              paymentDetails.paymentCommission > 0 &&
              paymentDetails.paymentCommission)
          ) {
            let details: IAccountDetails["bankDetails"] = {
              accountId: Number(accountId),
              country: `${t("defaultCountry")}`,
              currency: `${t("defaultCurrency")}`,
              bankName: paymentDetails.bankName,
              routingNumber: paymentDetails.routingNumber,
              accountHolderName: paymentDetails.accountName,
              accountNumber: paymentDetails.accountNumber,
              userRole: Number(adminUserRole),
              paymentCommission: Number(paymentDetails.paymentCommission),
            };
            dispatch(updateBankAccountDetail(details));
          } else {
            setNotification(
              t("messages.appName"),
              t("messages.paymentAccountValidation"),
              "failed",
              "updateBankAccountDetail"
            );
          }
          setEditPaymentSetting(false);
        }
      } else if (name === "editGeneralInformation") {
        if (generalInformation.phone.trim().length < 9) {
          setNotification(
            t("projectName"),
            t("messages.addAccountPhoneError"),
            "failed",
            "addAccount"
          );
        } else {
          if (
            generalInformation.hotelName.trim().length !== 0 &&
            generalInformation.mail.trim().length !== 0 &&
            generalInformation.contactFirstName.trim().length !== 0 &&
            generalInformation.contactLastName.trim().length !== 0 &&
            generalInformation.contactJobTitle.trim().length !== 0 &&
            generalInformation.address.trim().length !== 0 &&
            generalInformation.city.trim().length !== 0 &&
            generalInformation.state.trim().length !== 0 &&
            generalInformation.zip.trim().length !== 0 &&
            businessClassification.trim().length !== 0 &&
            numRooms.trim().length !== 0 &&
            numEmployees.trim().length !== 0
          ) {
            let details: IAccountDetails = {
              accountId: Number(accountId),
              hotelName: generalInformation.hotelName,
              companyName: generalInformation.companyName,
              businessClassification: businessClassification,
              numberOfRooms: numRooms,
              numberOfEmployees: numEmployees,
              mobileNumber: generalInformation.phone,
              communicationEmail: generalInformation.mail,
              firstName: generalInformation.contactFirstName,
              lastName: generalInformation.contactLastName,
              jobTitle: generalInformation.contactJobTitle,
              address: generalInformation.address,
              city: generalInformation.city,
              state: generalInformation.state,
              zip: generalInformation.zip,
              userRole: Number(adminUserRole),
            };
            dispatch(updateAccountDetail(details));
            setEditGeneralInformation(false);
          } else {
            setNotification(
              t("messages.appName"),
              t("messages.requiredField"),
              "failed",
              "updateBankAccountDetail"
            );
            setEditGeneralInformation(false);
          }
        }
      } else if (name === "editPaymentWithHold") {
        let pwPerc = paymentWithHold.taxForStaff
          ? Number(paymentWithHold.percentage)
          : 0;
        if (pwPerc > 100) {
          setPaymentWithHoldError(" input-error ");
        } else {
          setPaymentWithHoldError("");
          if (!paymentWithHold.taxForStaff) {
            pwPerc = 0;
            setPaymentWithHold({
              ...paymentWithHold,
              percentage: "0",
            });
          }

          let details: IAccountDetails = {
            accountId: Number(accountId),
            taxWithholdForStaff: paymentWithHold.taxForStaff,
            staffWithholdPercent: pwPerc,
            userRole: Number(adminUserRole),
          };
          dispatch(updateAccountDetail(details));
          setEditPaymentWithHold(false);
        }
      } else if (name === "tipSettings") {
        let foundTipSettinError = false;
        let msgText = "";
        if (
          !tip_1 ||
          Number(tip_1) <= 0 ||
          !tip_2 ||
          Number(tip_2) <= 0 ||
          !tip_3 ||
          Number(tip_3) <= 0 ||
          !tip_4 ||
          Number(tip_4) <= 0 ||
          tip_1 == tip_2 ||
          tip_1 == tip_3 ||
          tip_1 == tip_4 ||
          tip_2 == tip_3 ||
          tip_2 == tip_4 ||
          tip_3 == tip_4
        ) {
          foundTipSettinError = true;
          msgText = t("messages.invalidTipAmount");
        } else if (!editBgColor || editBgColor.trim().length == 0) {
          foundTipSettinError = true;
          msgText =
            selectDesign == TIP_DESIGN_3
              ? t("messages.invalidHexCodeDesign3Bg")
              : t("messages.invalidHexCode");
        } else if (
          selectDesign == TIP_DESIGN_3 &&
          (!editTextColor || editTextColor.trim().length == 0)
        ) {
          foundTipSettinError = true;
          msgText = t("messages.invalidHexCodeDesign3Text");
        }

        if (foundTipSettinError) {
          setNotification(t("messages.appName"), msgText, "failed", "tipError");
        } else {
          let tipSettings: IAccountTipSettings = {
            accountId: Number(accountId),
            tip_1: tip_1,
            tip_2: tip_2,
            tip_3: tip_3,
            tip_4: tip_4,
            tipDescription_1: tippingFactBody_1,
            tipDescription_2: tippingFactBody_2,
            tipDescription_3: tippingFactBody_3,
            tipDescription_4: tippingFactBody_4,
            tipBgColor: editBgColor,
            tipFontColor: editTextColor,
            headerBgColor: editTextColor,
            tipDesignType: Number(selectDesign),
            tipIncludeProcessFee: tipIncludeProcessFee,
            roomLabel: roomLabel,
            roomLabelMandatory: roomLabelMandatory,
            staff_label_for_groups: staffLabelForGroups, 
            showCustomTipOnly: showCustomOnly,
            defaultTipPreset: selectedTip,
            userRole: Number(adminUserRole),
          };
          dispatch(updateAccTipSettings(tipSettings));
        }
      } else if (name === "editReviewLinks") {
        let details: IAccountDetails = {
          accountId: Number(accountId),
          reviewTitle: reviewTitle ? reviewTitle : "",
          reviewBody: reviewDescription ? reviewDescription : "",
          userRole: Number(adminUserRole),
        };
        dispatch(updateAccReviewTitleBody(details));
      } else if (name === "tipConfirmMessage") {
        if (!confirmationMessage || confirmationMessage.trim().length == 0) {
          setNotification(
            t("messages.appName"),
            t("messages.accounTipConfirmMsgError"),
            "failed",
            "updatetipConfirmMessage"
          );
        } else {
          let details: IAccountDetails = {
            accountId: Number(accountId),
            confirmationMessage: confirmationMessage ? confirmationMessage : "",
            userRole: Number(adminUserRole),
          };
          dispatch(updateTipConfirmationMessage(details));
        }
      }
    };

    const cancelEdit = (name) => {
      if (name === "editPaymentSetting") {
        setPaymentDetails({
          bankName: paymentDetailsPrev.bankName,
          accountName: paymentDetailsPrev.accountName,
          accountNumber: paymentDetailsPrev.accountNumber,
          routingNumber: paymentDetailsPrev.routingNumber,
          paymentCommission: paymentDetailsPrev.paymentCommission,
        });
        setEditPaymentSetting(false);
        setPaymentCommissionError("");
      } else if (name === "editGeneralInformation") {
        setGeneralInformation({
          hotelName: generalInformationPrev.hotelName,
          accountCode: generalInformationPrev.accountCode,
          companyName: generalInformationPrev.companyName,
          phone: generalInformationPrev.phone,
          mail: generalInformationPrev.mail,
          address: generalInformationPrev.address,
          city: generalInformationPrev.city,
          state: generalInformationPrev.state,
          zip: generalInformationPrev.zip,
          contactFirstName: generalInformationPrev.contactFirstName,
          contactLastName: generalInformationPrev.contactLastName,
          contactJobTitle: generalInformationPrev.contactJobTitle,
          memberSince: generalInformationPrev.memberSince,
          status: generalInformationPrev.status,
        });
        setBusinessClassification(businessClassificationPrev);
        setNumRooms(numRoomsPrev);
        setNumEmployees(numEmployeesPrev);
        setEditGeneralInformation(false);
      } else if (name === "editPaymentWithHold") {
        setPaymentWithHold({
          taxForStaff: paymentWithHoldPrev.taxForStaff,
          percentage: paymentWithHoldPrev.percentage,
        });
        setEditPaymentWithHold(false);
      } else if (name === "tipSettings") {
        setShowCustomOnly(showCustomOnlyPrev);
        setSelectedTip(selectedTipPrev);
        setEditTipDetails(false);
      } else if (name === "editReviewLinks") {
        setReviewTitle(prevReviewTitle);
        setReviewDescription(prevReviewDescription);
        setEditReviewLinks(false);
      } else if (name == "tipConfirmMessage") {
        setConfirmationMessage(prevConfirmationMessage);
        setEditConfirmMessage(false);
      }
    };

    return (
      <div className="flex gap-4">
        <ButtonInLine
          type="edit_or_save"
          onClick={(e) => cancelEdit(name)}
          buttonLabel={t("common.cancel")}
        />
        <ButtonInLine
          type="edit_or_save"
          onClick={(e) => saveEdit(name)}
          buttonLabel={t("defaultSettings.save")}
        />
      </div>
    );
  };


  const onSetAccountLogo = (e) => {
    const file = e.target.files[0];

    if (!file) {
      return; // If no file is selected, exit the function
    }

    const fileName = file.name;
    if (!isValidFileExtension(fileName)) {
      setNotification(
        t("projectName"),
        t("messages.logoUploadFailed"),
        "failed",
        "uploadAccountLogo"
      );
      return; // Exit the function if the file extension is invalid
    }

    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAccountLogoImg(reader.result);
        const req: IAccountDetails = {
          accountId: Number(accountId),
          logo: reader.result?.toString(),
          userRole: Number(adminUserRole),
        };
        dispatch(uploadAccountLogo(req));
      }
    };
    reader.readAsDataURL(file);
  };


  const onRemoveAccountLogo = () => {
    setAccountLogoImg(avatar);
    const req: IAccountDetails = {
      accountId: Number(accountId),
      userRole: Number(adminUserRole),
    };
    dispatch(removeAccountLogo(req));
  };

  const onDeactivateAccount = () => {
    const req: IAccountDetails = {
      accountId: Number(accountId),
      userRole: Number(adminUserRole),
    };
    dispatch(deactivateAccount(req));
    setStatusPopup(false);
  };

  const onActivateAccount = () => {
    const req: IAccountDetails = {
      accountId: Number(accountId),
      userRole: Number(adminUserRole),
    };
    dispatch(activateAccount(req));
    setStatusPopup(false);
  };

  const updateAccPrimaryUser = (userId: string) => {
    if (!userId || !Number(userId)) {
      setNotification(
        t("projectName"),
        t("messages.accPrimaryUserSelection"),
        "failed",
        "modifyPrimaryUser"
      );
    }
    else {
      setGeneralInformation({
        ...generalInformation,
        accPrimaryUserId: Number(userId),
      });
      const req: IAccountPrimaryUser = {
        hotel_id: Number(accountId),
        user_id: Number(userId),
        userRole: Number(adminUserRole),
      };
      dispatch(modifyAccPrimaryUser(req));
    }
  };

  const handleCopy = (copyText) => {
    navigator.clipboard.writeText(copyText);
    setNotification(
      t("projectName"),
      t("common.linkCopied"),
      "success",
      "handleCopy"
    );
  };

  const onCreateNewGroup = () => {
    if (newGroupName.trim() == "") {
      setNewGroupNameError(true);
    } else if (accountId) {
      setNewGroupNameError(false);
      let newGroup: INewAccountGroup = {
        hotelId: Number(accountId),
        name: newGroupName,
        userRole: Number(adminUserRole),
      };
      dispatch(createNewGroup(newGroup));
    }
  };

  const onClickReturn = () => {
    navigate(ROUTE_ACCOUNTS_LIST, {
      state: {
        calledFrom: "accountDetailsPage",
        prevPage: prevPage,
        prevSearch: prevSearch,
        prevActive: prevActive,
        prevFromDate: prevFromDate,
        prevToDate: prevToDate,
        prevDateFilterType: prevDateFilterType,
        prevSort: prevSort,
        prevNumRowsPerPage: prevNumRowsPerPage,
      },
    });
  };

  const handleCopyValue = (value: string) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setNotification(
          t("messages.appName"),
          t("messages.cpyMessage"),
          "success",
          "uploadAccountLogo"
        );
      })
      .catch((error) => {
        // console.error("Error copying value:", error);
      });
  };

  const handleColorChange = (color: ColorResult) => {
    setEditBgColor(color.hex);
  };

  const setBgColor = () => {
    return { backgroundColor: editBgColor };
  };

  const setTipValue = (tipValue: string, tipNum: number) => {
    let tValue = tipValue.trim() == "" ? 0 : Number(tipValue.substring(0, 5));
    if (tipNum == 1) {
      setTip_1(tValue);
    } else if (tipNum == 2) {
      setTip_2(tValue);
    } else if (tipNum == 3) {
      setTip_3(tValue);
    } else if (tipNum == 4) {
      setTip_4(tValue);
    }
  };

  const uploadReviewImage = (
    e: ChangeEvent<HTMLInputElement>,
    reviewId: number
  ) => {
    setLastReviewImageReviewId(reviewId);
    const file = e.target.files && e.target.files[0];

    if (!file) {
      return; // If no file is selected, exit the function
    }

    const fileName = file.name;
    if (!isValidFileExtension(fileName)) {
      // Invalid file extension, handle the error or show a message to the user
      setNotification(
        t("projectName"),
        t("messages.logoUploadFailed"),
        "failed",
        "uploadAccountLogo"
      );
      return; // Exit the function if the file extension is invalid
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const req: IReviewLogoUpload = {
        reviewId: Number(reviewId),
        logo: reader.result,
        userRole: Number(adminUserRole),
      };
      dispatch(uploadReviewPlatformLinkLogo(req));
    };
    reader.readAsDataURL(file);
  };

  const onSetAccountTipLogo = (e) => {
    if (e && e.trim()) {
      setTipLogo(e);
      const req: IAccountDetails = {
        accountId: Number(accountId),
        logo: e.toString(),
        userRole: Number(adminUserRole),
      };
      dispatch(uploadAccountTipLogo(req));
    }
  };

  const onRemoveAccounTipLogo = () => {
    const req: IAccountDetails = {
      accountId: Number(accountId),
      userRole: Number(adminUserRole),
    };
    dispatch(removeAccountTipLogo(req));
  };

  const onSetAccountTipImage = (e) => {
    if (e && e.trim()) {
      setTipBackgroungImg(e);
      const req: IAccountDetails = {
        accountId: Number(accountId),
        image: e.toString(),
        userRole: Number(adminUserRole),
      };
      dispatch(uploadAccountTipImage(req));
    }
  };

  const onRemoveAccounTipImage = () => {
    const req: IAccountDetails = {
      accountId: Number(accountId),
      userRole: Number(adminUserRole),
    };
    dispatch(removeAccountTipImage(req));
  };

  const onSetSelectDesign = (designNumber: number) => {
    setSelectDesign(designNumber);
    if (canEditAccountDetails && !editTipDetails) {
      setEditTipDetails(true);
    }
  };

  return (
    <div className="box-padding">
      <div className="box">
        <div className="account ">
          <div className="text-left mb-2">
            <div className="returnAcc pointer" onClick={() => onClickReturn()}>
              <img src={back} alt="back" height={"16px"} className="me-1" />
              {t("accountDetails.returntoACClist")}
            </div>
          </div>
          {/* <div>{prevPage+' : '+prevSearch+' : '+prevActive+' : '+prevFromDate+' : '+prevToDate+' : '+prevDateFilterType+' : '+prevSort}</div> */}
          <div className=" space-between mb-4">
            <h1>{accountDetails.hotelName}</h1>
            <div className="flex gap-12px">
              {checkActionPermission(adminUserRole, PERM_ADD_NEW_GROUP) && (
                <ButtonPrimary
                  onClick={(e) => setAddGroup(true)}
                  buttonLabel={t("accountDetails.addGroup")}
                />
              )}
              {checkActionPermission(adminUserRole, PERM_STAFF_MANAGE) && (
                <ButtonPrimary
                  to={ROUTE_STAFF_LIST}
                  link={true}
                  buttonLabel={t("buttons.manageStaff")}
                />
              )}

              {/* QR CODES ORDERING - ONLY ALLOWED FOR SUPERADMIN AND ACCOUNT MANAGER  */}
              {checkActionPermission(adminUserRole, PERM_QRCODE_ORDER) ? (
                <ButtonPrimary
                to={ROUTE_QRCODES}
                link={true}
                buttonLabel={t("buttons.OrderQRCodes")}
              />
              ) : <></>}  
              

              {checkActionPermission(
                adminUserRole,
                PERM_VIEW_ACCOUNT_USERS
              ) && (
                  <ButtonPrimary
                    to={ROUTE_HOTEL_USERS}
                    link={true}
                    buttonLabel={t("buttons.manageUsers")}
                  />
                )}
            </div>
          </div>

          <div className="h-two  mt-3">
            {t("accountDetails.generalInformation")}
            {checkActionPermission(
              adminUserRole,
              PERM_EDIT_ACCOUNT_DETAILS
            ) && (
                <>
                  {editGeneralInformation ? (
                    <div>{saveButton("editGeneralInformation")}</div>
                  ) : (
                    <div>{editButton("editGeneralInformation")}</div>
                  )}
                </>
              )}
          </div>

          <div className="space-between w-100  editBox ">
            <div className="left-center flex-col gap-2 w-100 ml-15px ">
              <div className="w-100 flex center">
                <div className="h-three col-7 my-2">
                  {t("accountDetails.accountNameLabel")}
                </div>
                <div className="col-5">
                  {!editGeneralInformation ? (
                    <Tooltip
                      title={
                        <>
                          {t("common.copy")}
                          <ContentCopyIcon sx={{ fontSize: "12px" }} />
                        </>
                      }
                      placement="right"
                      arrow
                      disableInteractive
                    >
                      <span
                        className="text-data copy-cursor"
                        onClick={(e) =>
                          handleCopyValue(generalInformation.hotelName)
                        }
                      >
                        {generalInformation.hotelName &&
                          generalInformation.hotelName.length
                          ? generalInformation.hotelName
                          : ""}
                      </span>
                    </Tooltip>
                  ) : (
                    <Textfield
                      type="text"
                      className={`${!editGeneralInformation && "border-none bg-transparent"
                        }`}
                      disabled={!editGeneralInformation}
                      value={generalInformation.hotelName}
                      onChange={(e) =>
                        setGeneralInformation({
                          ...generalInformation,
                          hotelName: e.target.value,
                        })
                      }
                    />
                  )}
                </div>
              </div>

              <div className="w-100 flex center">
                <div className="h-three col-7 my-2">
                  {t("accountDetails.accountCodeLabel")}
                </div>
                <div className="col-5">
                  <Tooltip
                    title={
                      <>
                        {t("common.copy")}
                        <ContentCopyIcon sx={{ fontSize: "12px" }} />
                      </>
                    }
                    placement="right"
                    arrow
                    disableInteractive
                  >
                    <span
                      className={`text-data copy-cursor ${editGeneralInformation && " pl-9px "
                        }`}
                      onClick={(e) =>
                        handleCopyValue(generalInformation.accountCode)
                      }
                    >
                      {generalInformation.accountCode &&
                        generalInformation.accountCode.length
                        ? generalInformation.accountCode
                        : ""}
                    </span>
                  </Tooltip>
                </div>
              </div>

              <div className="w-100 flex center">
                <div className="h-three col-7 my-2">
                  {t("businessDetails.parentCompanyLabel")}
                </div>
                <div className="col-5">
                  {!editGeneralInformation ? (
                    <Tooltip
                      title={
                        <>
                          {t("common.copy")}
                          <ContentCopyIcon sx={{ fontSize: "12px" }} />
                        </>
                      }
                      placement="right"
                      arrow
                      disableInteractive
                    >
                      <span
                        className="text-data copy-cursor"
                        onClick={(e) =>
                          handleCopyValue(generalInformation.companyName)
                        }
                      >
                        {generalInformation.companyName &&
                          generalInformation.companyName.length
                          ? generalInformation.companyName
                          : ""}
                      </span>
                    </Tooltip>
                  ) : (
                    <Textfield
                      type="text"
                      className={`${!editGeneralInformation && "border-none bg-transparent"
                        }`}
                      disabled={!editGeneralInformation}
                      value={generalInformation.companyName}
                      onChange={(e) =>
                        setGeneralInformation({
                          ...generalInformation,
                          companyName: e.target.value,
                        })
                      }
                    />
                  )}
                </div>
              </div>

              <div className="w-100 flex center">
                <div className="h-three col-7 my-2">
                  {t("businessDetails.businessClassification")}
                </div>
                <div className="col-5">
                  {!editGeneralInformation ? (
                    <span className="text-data">
                      {businessClassList.map(
                        (item) =>
                          businessClassification === item.id.toString() && (
                            <>{item.value}</>
                          )
                      )}
                    </span>
                  ) : (
                    <SelectField
                      type="inputType"
                      name="AD_sel_businessClass"
                      id="AD_sel_businessClass"
                      className={
                        businessClassification == ""
                          ? " select-placeholder "
                          : ""
                      }
                      value={businessClassification}
                      onChange={(e) =>
                        setBusinessClassification(e.target.value)
                      }
                      required
                      options={
                        businessClassList &&
                        businessClassList.map((item, index) => (
                          <>
                            <option value={item.id.toString()}>
                              {item.value}
                            </option>
                          </>
                        ))
                      }
                    />
                  )}
                </div>
              </div>

              <div className="w-100 flex center">
                <div className="h-three col-7 my-2">
                  {t("businessDetails.approximateroomsLabel")}
                </div>
                <div className="col-5">
                  {!editGeneralInformation ? (
                    <span className="text-data">{numRooms}</span>
                  ) : (
                    <Textfield
                      type="text"
                      className={`${!editGeneralInformation && "border-none bg-transparent"
                        }`}
                      disabled={!editGeneralInformation}
                      required
                      maxLength={5}
                      min={1}
                      name="txt_numRooms"
                      id="txt_numRooms"
                      placeholder={`${t("businessDetails.roomsPlaceHolder")}`}
                      value={numRooms}
                      onChange={(e) => numberOnly(e.target.value, "numRooms")}
                    />
                  )}
                </div>
              </div>

              <div className="w-100 flex center">
                <div className="h-three col-7 my-2">
                  {t("businessDetails.numberEmployeesLabel")}
                </div>
                <div className="col-5">
                  {!editGeneralInformation ? (
                    <span className="text-data">{numEmployees}</span>
                  ) : (
                    <Textfield
                      type="text"
                      required
                      maxLength={5}
                      min={1}
                      name="txt_numEmployees"
                      id="txt_numEmployees"
                      placeholder={`${t(
                        "businessDetails.employeesPlaceHolder"
                      )}`}
                      className={`${!editGeneralInformation && "border-none bg-transparent"
                        }`}
                      disabled={!editGeneralInformation}
                      value={numEmployees}
                      onChange={(e) =>
                        numberOnly(e.target.value, "numEmployees")
                      }
                    />
                  )}
                </div>
              </div>

              <div className="w-100 flex ">
                <div className="h-three col-7 my-2">
                  {t("accountDetails.phoneLabel")}
                </div>
                <Tooltip
                  title={
                    <>
                      {t("common.copy")}
                      <ContentCopyIcon sx={{ fontSize: "12px" }} />
                    </>
                  }
                  placement="right"
                  arrow
                  disableInteractive
                >
                  <div
                    className={`hidesl copy-cursor col-5 ${!editGeneralInformation && "max-width-142px"
                      }`}
                    onClick={(e) => handleCopyValue(generalInformation.phone)}
                  >
                    <PhoneInput
                      inputProps={{
                        name: `mobile`,
                        required: true,
                        autoFocus: false,
                      }}
                      onlyCountries={PHONEINPUT_COUNTRIES}
                      countryCodeEditable={PHONEINPUT_COUNTRYCODE_EDITABLE}
                      country={"us"}
                      prefix={"+"}
                      inputClass={`common-input  ${!editGeneralInformation &&
                        " pl-0px border-none bg-transparent "
                        }`}
                      disabled={!editGeneralInformation}
                      value={generalInformation.phone}
                      onChange={(e) =>
                        setGeneralInformation({
                          ...generalInformation,
                          phone: e,
                        })
                      }
                    />
                  </div>
                </Tooltip>
              </div>

              <div className="w-100 flex center">
                <div className="h-three col-7 my-2">
                  {t("accountDetails.emailLabel")}
                </div>
                <div className="col-5">
                  {!editGeneralInformation ? (
                    <Tooltip
                      title={
                        <>
                          {t("common.copy")}
                          <ContentCopyIcon sx={{ fontSize: "12px" }} />
                        </>
                      }
                      placement="right"
                      arrow
                      disableInteractive
                    >
                      <span
                        className="text-data copy-cursor"
                        onClick={(e) =>
                          handleCopyValue(generalInformation.mail)
                        }
                      >
                        {generalInformation.mail &&
                          generalInformation.mail.length
                          ? generalInformation.mail
                          : ""}
                      </span>
                    </Tooltip>
                  ) : (
                    <Textfield
                      type="text"
                      className={` ${!editGeneralInformation && "border-none bg-transparent"
                        }`}
                      disabled={!editGeneralInformation}
                      value={generalInformation.mail}
                      onChange={(e) =>
                        setGeneralInformation({
                          ...generalInformation,
                          mail: e.target.value,
                        })
                      }
                    />
                  )}
                </div>
              </div>

              <div className="w-100 flex center">
                <div className="h-three col-7 my-2">
                  {t("accountDetails.addressLabel")}
                </div>
                <div className="col-5">
                  {!editGeneralInformation ? (
                    <span className="text-data">
                      {generalInformation.address &&
                        generalInformation.address.length
                        ? generalInformation.address
                        : ""}
                    </span>
                  ) : (
                    <Textfield
                      type="text"
                      className={` ${!editGeneralInformation && "border-none bg-transparent"
                        }`}
                      disabled={!editGeneralInformation}
                      value={generalInformation.address}
                      onChange={(e) =>
                        setGeneralInformation({
                          ...generalInformation,
                          address: e.target.value,
                        })
                      }
                    />
                  )}
                </div>
              </div>

              <div className="w-100 flex center">
                <div className="h-three col-7 my-2">
                  {t("accountDetails.cityLabel")}
                </div>
                <div className="col-5">
                  {!editGeneralInformation ? (
                    <span className="text-data">
                      {generalInformation.city && generalInformation.city.length
                        ? generalInformation.city
                        : ""}
                    </span>
                  ) : (
                    <Textfield
                      type="text"
                      className={` ${!editGeneralInformation && "border-none bg-transparent"
                        }`}
                      disabled={!editGeneralInformation}
                      value={generalInformation.city}
                      onChange={(e) =>
                        setGeneralInformation({
                          ...generalInformation,
                          city: e.target.value,
                        })
                      }
                    />
                  )}
                </div>
              </div>

              <div className="w-100 flex center">
                <div className="h-three col-7 my-2">
                  {t("accountDetails.stateLabel")}
                </div>
                <div className="col-5">
                  {!editGeneralInformation ? (
                    <span className="text-data">
                      {generalInformation.state &&
                        generalInformation.state.length == 2
                        ? getStateNameFromCode(generalInformation.state)
                        : ""}
                    </span>
                  ) : (
                    <SelectField
                      type="inputType"
                      name="txt_state"
                      id="txt_state"
                      className={
                        generalInformation.state == ""
                          ? " select-placeholder "
                          : ""
                      }
                      placeHolder={t("businessDetails.stateLabel")}
                      value={generalInformation.state}
                      onChange={(e) =>
                        setGeneralInformation({
                          ...generalInformation,
                          state: e.target.value,
                        })
                      }
                      required
                      options={
                        US_STATES_ARRAY &&
                        US_STATES_ARRAY.map((item, index) => (
                          <>
                            {index == 0 && (
                              <>
                                <option value="">
                                  {t("businessDetails.stateLabel")}
                                </option>
                              </>
                            )}
                            <option
                              value={item.stateCode}
                              style={{ color: "#000000" }}
                            >
                              {item.stateName}
                            </option>
                          </>
                        ))
                      }
                    />
                  )}
                </div>
              </div>

              <div className="w-100 flex center">
                <div className="h-three col-7 my-2">
                  {t("accountDetails.zipLabel")}
                </div>
                <div className="col-5">
                  {!editGeneralInformation ? (
                    <span className="text-data">
                      {generalInformation.zip && generalInformation.zip.length
                        ? generalInformation.zip
                        : ""}
                    </span>
                  ) : (
                    <Textfield
                      type="text"
                      className={` ${!editGeneralInformation && "border-none bg-transparent"
                        }`}
                      disabled={!editGeneralInformation}
                      value={generalInformation.zip}
                      onChange={(e) =>
                        setGeneralInformation({
                          ...generalInformation,
                          zip: e.target.value,
                        })
                      }
                    />
                  )}
                </div>
              </div>

              <div className="w-100 flex center">
                <div className="h-three col-7 my-2">
                  {t("accountDetails.authorizedFirstName")}
                </div>
                <div className="col-5">
                  {!editGeneralInformation ? (
                    <span className="text-data">
                      {generalInformation.contactFirstName &&
                        generalInformation.contactFirstName.length
                        ? generalInformation.contactFirstName
                        : ""}
                    </span>
                  ) : (
                    <Textfield
                      type="text"
                      className={` ${!editGeneralInformation && "border-none bg-transparent"
                        }`}
                      disabled={!editGeneralInformation}
                      value={generalInformation.contactFirstName}
                      onChange={(e) =>
                        setGeneralInformation({
                          ...generalInformation,
                          contactFirstName: e.target.value,
                        })
                      }
                    />
                  )}
                </div>
              </div>

              <div className="w-100 flex center">
                <div className="h-three col-7 my-2">
                  {t("accountDetails.authorizedLastName")}
                </div>
                <div className="col-5">
                  {!editGeneralInformation ? (
                    <span className="text-data">
                      {generalInformation.contactLastName &&
                        generalInformation.contactLastName.length
                        ? generalInformation.contactLastName
                        : ""}
                    </span>
                  ) : (
                    <Textfield
                      type="text"
                      className={` ${!editGeneralInformation && "border-none bg-transparent"
                        }`}
                      disabled={!editGeneralInformation}
                      value={generalInformation.contactLastName}
                      onChange={(e) =>
                        setGeneralInformation({
                          ...generalInformation,
                          contactLastName: e.target.value,
                        })
                      }
                    />
                  )}
                </div>
              </div>

              <div className="w-100 flex center">
                <div className="h-three col-7 my-2">
                  {t("accountDetails.authorizedJobTitle")}
                </div>
                <div className="col-5">
                  {!editGeneralInformation ? (
                    <span className="text-data">
                      {generalInformation.contactJobTitle &&
                        generalInformation.contactJobTitle.length
                        ? generalInformation.contactJobTitle
                        : ""}
                    </span>
                  ) : (
                    <Textfield
                      type="text"
                      className={` ${!editGeneralInformation && "border-none bg-transparent"
                        }`}
                      disabled={!editGeneralInformation}
                      value={generalInformation.contactJobTitle}
                      onChange={(e) =>
                        setGeneralInformation({
                          ...generalInformation,
                          contactJobTitle: e.target.value,
                        })
                      }
                    />
                  )}
                </div>
              </div>

              <div className="w-100 flex center">
                <div className="h-three col-7 my-2">
                  {t("accountDetails.memberSinceLabel")}
                </div>
                <div className="col-5">
                  <span
                    className={`text-data ${editGeneralInformation && " pl-9px "
                      }`}
                  >
                    {generalInformation.memberSince &&
                      generalInformation.memberSince.length == 10
                      ? dayjs(generalInformation.memberSince).format(
                        `MM/DD/YYYY`
                      )
                      : ""}
                  </span>
                </div>
              </div>

              <div className="w-100 flex ">
                <div className={"h-three col-7 my-2"} >
                  {t("accountDetails.primaryUserLabel")}
                </div>
                <div className={"col-5"} >
                  {!canEditAccountDetails ? (
                    <span className="text-data">
                      {generalInformation.accPrimaryUserName ? generalInformation.accPrimaryUserName
                        : ""}
                    </span>
                  ) : (
                    <div className={"ml-m3px"} >
                      <SelectField
                        type="inputType"
                        name="txt_primaryUser"
                        id="txt_primaryUser"
                        className={
                          generalInformation.accPrimaryUserId == 0
                            ? " select-placeholder "
                            : ""
                        }
                        placeHolder={t("accountDetails.primaryUserLabel")}
                        value={generalInformation.accPrimaryUserId}
                        disabled={!canEditAccountDetails}
                        onChange={(e) => {
                          updateAccPrimaryUser(e.target.value);
                        }
                        }
                        options={
                          accountDetails.accountManagers &&
                          accountDetails.accountManagers.map((item, index) => (
                            <>
                              {index == 0 && (
                                <>
                                  <option value="0">
                                    {t("common.select")}
                                  </option>
                                </>
                              )}
                              <option
                                value={item.id}
                                style={{ color: "#000000" }}
                              >
                                {item.name}
                              </option>
                            </>
                          ))
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="w-100 left-center">
                <div className="h-three col-7 my-2">
                  {t("accountDetails.statusLabel")}
                </div>
                <div className="col-2">
                  <span
                    className={`text-data ${editGeneralInformation && " pl-9px "
                      }`}
                  >
                    {generalInformation.status &&
                      generalInformation.status.length
                      ? generalInformation.status
                      : ""}
                  </span>
                </div>
                {checkActionPermission(
                  adminUserRole,
                  PERM_ACTIVATE_DEACTIVATE_ACCOUNT
                ) && (
                    <>
                      {generalInformation.status.toLowerCase() ===
                        t("statusFilter.active").toLowerCase() ? (
                        <ButtonInLine
                          className="deactive"
                          onClick={(e) => setStatusPopup(true)}
                          buttonLabel={t("accountDetails.deactivateaccountBtn")}
                        />
                      ) : (
                        <ButtonInLine
                          className="acc-active"
                          onClick={(e) => onActivateAccount()}
                          buttonLabel={t("accountDetails.activateaccountBtn")}
                        />
                      )}
                    </>
                  )}
              </div>
            </div>

            <div className="flex-end w-100 mr-15px">
              <div className="flex-col w-100 max-width-336px">
                <div className="avatar-box w-100 bg-white max-width-336px">
                  <img src={accountLogoImg} alt="logo" className={`logoImg ${accountLogoImg &&
                    accountLogoImg.trim().length > 3 &&
                    accountLogoImg != avatar && ' pointer '} `} onClick={handleOpen} />
                  {checkActionPermission(
                    adminUserRole,
                    PERM_EDIT_ACCOUNT_DETAILS
                  ) && (
                      <div className="w-100">
                        <ButtonInLine
                          type="label"
                          htmlFor="upload"
                          buttonLabel={t("uploadLogo.uploadBtn")}
                          uploadIcon={true}
                          inputName="upload"
                          inputId="upload"
                          onChange={(e) => onSetAccountLogo(e)}
                        />
                        {accountLogoImg &&
                          accountLogoImg.trim().length > 3 &&
                          accountLogoImg != avatar && (
                            <ButtonInLine
                              className=" mt-3 "
                              onClick={(e) => onRemoveAccountLogo()}
                              buttonLabel={t("uploadLogo.removeLogo")}
                            />
                          )}
                      </div>
                    )}
                </div>
                <div className="max-width-336px validFont text-end">{t("validImg")}</div>
              </div>
            </div>
          </div>

          <div className="h-two  mt-3">
            {t("accountDetails.groups")}({accountDetails.pools?.length})
          </div>

          <div className="group-container">
            {groupItem.map((item, index) => (
              <div className="groupbox" key={index}>
                <div className="p-2 border-bottom">{item.name}</div>
                <div className="p-2 text-center">
                  <Link
                    to={ROUTE_ACCOUNT_GROUP + "/" + item.id.toString()}
                    className="manageBtn w-100"
                  >
                    {t("accountDetails.manageBtn")}
                  </Link>
                </div>
              </div>
            ))}
          </div>

          <div className="h-two  mt-3">
            {t("accountDetails.PaymentSettings")}
            {checkActionPermission(
              adminUserRole,
              PERM_EDIT_ACCOUNT_DETAILS
            ) && (
                <>
                  {editPaymentSetting ? (
                    <div>{saveButton("editPaymentSetting")}</div>
                  ) : (
                    <div>{editButton("editPaymentSetting")}</div>
                  )}
                </>
              )}
          </div>

          <div className="editBox">
            {checkActionPermission(
              adminUserRole,
              PERM_EDIT_ACCOUNT_COMMISSION
            ) && (
                <>
                  <div className="left-center my-2 ml-15px">
                    <div className="h-three col-2">
                      {t("accountDetails.commissionPercentage")}
                    </div>
                    <div className="col-3">
                      <div className="w-100 percentage-input">
                        <span
                          className={paymentCommissionError + ` px-10px  ${!editPaymentSetting && " border-none bg-transparent"
                            }`}
                        >
                          {t("group.percentageIcon")}
                        </span>
                        <Textfield
                          type="number"
                          max="100"
                          min={"0"}
                          className={
                            paymentCommissionError +
                            `  ${!editPaymentSetting &&
                            " border-none bg-transparent "
                            }`
                          }
                          disabled={!editPaymentSetting}
                          value={paymentDetails.paymentCommission}
                          onChange={(e) => {
                            setPaymentDetails({
                              ...paymentDetails,
                              paymentCommission: e.target.value,
                            });
                            setPaymentCommissionError("");
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-1"></div>
                    <div className="h-three col-2">&nbsp;</div>
                    <div className="col-3">&nbsp;</div>
                  </div>
                </>
              )}

            <div className="left-center my-2 ml-15px">
              <div className="h-three col-2">
                {t("accountDetails.bankName")}
              </div>
              <div className="col-3">
                <Textfield
                  type="text"
                  className={` ${!editPaymentSetting && "border-none bg-transparent"
                    }`}
                  disabled={!editPaymentSetting}
                  value={paymentDetails.bankName}
                  onChange={(e) =>
                    setPaymentDetails({
                      ...paymentDetails,
                      bankName: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-1"></div>
              <div className="h-three col-2">
                {t("accountDetails.accountName")}
              </div>
              <div className="col-3">
                <Textfield
                  type="text"
                  className={` ${!editPaymentSetting && "border-none bg-transparent"
                    }`}
                  disabled={!editPaymentSetting}
                  value={paymentDetails.accountName}
                  onChange={(e) =>
                    setPaymentDetails({
                      ...paymentDetails,
                      accountName: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="left-center my-2 ml-15px">
              <div className="h-three col-2">
                {t("accountDetails.routingNumber")}
              </div>
              <div className="col-3">
                <Textfield
                  type="text"
                  className={`  ${!editPaymentSetting && "border-none bg-transparent"
                    }`}
                  disabled={!editPaymentSetting}
                  value={paymentDetails.routingNumber}
                  onChange={(e) =>
                    setPaymentDetails({
                      ...paymentDetails,
                      routingNumber: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-1"></div>
              <div className="h-three col-2">
                {t("accountDetails.accountNumber")}
              </div>
              <div className="col-3">
                <Textfield
                  type="text"
                  className={`  ${!editPaymentSetting && "border-none bg-transparent"
                    }`}
                  disabled={!editPaymentSetting}
                  value={paymentDetails.accountNumber}
                  onChange={(e) =>
                    setPaymentDetails({
                      ...paymentDetails,
                      accountNumber: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>

          <div className="h-two mt-3">
            {t("accountDetails.paymentWithholdings")}
            {checkActionPermission(
              adminUserRole,
              PERM_EDIT_ACCOUNT_DETAILS
            ) && (
                <>
                  {editPaymentWithHold ? (
                    <div>{saveButton("editPaymentWithHold")}</div>
                  ) : (
                    <div>{editButton("editPaymentWithHold")}</div>
                  )}
                </>
              )}
          </div>

          <div className="editBox mb-3">
            <div className="left-center my-2 ml-15px">
              <div className="h-three col-2">
                {t("accountDetails.taxForStaff")}
              </div>
              <div className="col-3">
                <FormControlLabel
                  control={
                    <IOSSwitch
                      disabled={!editPaymentWithHold}
                      sx={{ m: 1 }}
                      value={paymentWithHold.taxForStaff}
                      checked={paymentWithHold.taxForStaff}
                      onChange={(e) => {
                        setPaymentWithHold({
                          ...paymentWithHold,
                          taxForStaff: e.target.checked,
                        });
                      }}
                    />
                  }
                  label=""
                />
                {paymentWithHold.taxForStaff ? (
                  <span className="font-500">{t("common.yes")}</span>
                ) : (
                  <span className="font-500">{t("common.no")}</span>
                )}
                {/* // TEMPORARILY COMMETED - THIS WILL BE USED IN NEXT PHASE */}
                {/* <Textfield
                type="checkbox"
                className={`w-100  ${!editPaymentWithHold && "border-none bg-transparent"}`}
                disabled={!editPaymentWithHold}
                value={paymentWithHold.taxForStaff}
                onChange={(e) =>
                  setPaymentWithHold({
                    ...paymentWithHold,
                    taxForStaff: e.target.checked,
                  })
                }
              /> */}
              </div>
            </div>
            {paymentWithHold.taxForStaff && (
              <>
                <div className="left-center my-2 ml-15px">
                  <div className="h-three col-2">
                    {t("accountDetails.percentage")}
                  </div>
                  <div className="col-3">
                    <div className="w-100 percentage-input">
                      <span
                        className={`px-10px  ${!editPaymentWithHold && "border-none bg-transparent"
                          }`}
                      >
                        {t("group.percentageIcon")}
                      </span>
                      <Textfield
                        type="number"
                        max="100"
                        min={"0"}
                        className={
                          paymentWithHoldError +
                          `  ${!editPaymentWithHold && "border-none bg-transparent"
                          }`
                        }
                        disabled={!editPaymentWithHold}
                        value={paymentWithHold.percentage}
                        onChange={(e) => {
                          setPaymentWithHold({
                            ...paymentWithHold,
                            percentage: e.target.value,
                          });
                          setPaymentWithHoldError("");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* ******************* DESIGN TEMPLATES - START ******************* */}
          <div className="h-two">{t("group.designTemplateHeading")}</div>
          <div className=" mb-15px  flex gap-3 " style={{ flexWrap: "wrap" }}>
            <div className="flex-col mt-6px editBox pointer" onClick={(e) => onSetSelectDesign(TIP_DESIGN_1)}>
              <div className="h-three-black me-3 mb-3 flex ">
                <input
                  type="radio"
                  name=""
                  id=""
                  onClick={(e) => onSetSelectDesign(TIP_DESIGN_1)}
                  checked={selectDesign === TIP_DESIGN_1 ? true : false}
                  className="mr-8px"
                />
                {t("group.designOne")}
              </div>
              <div>
                <img
                  src={designOne}
                  alt="designOne"
                  className="ml-7px designImg"
                />
              </div>
            </div>
            <div className="flex-col mt-6px editBox pointer" onClick={(e) => onSetSelectDesign(TIP_DESIGN_2)}>
              <div className="h-three-black me-3 flex mb-3">
                <input
                  type="radio"
                  name=""
                  id=""
                  onClick={(e) => onSetSelectDesign(TIP_DESIGN_2)}
                  checked={selectDesign == TIP_DESIGN_2 ? true : false}
                  className="mr-8px"
                />

                {t("group.designTwo")}
              </div>
              <div>
                <img
                  src={designTwo}
                  alt="designTwo"
                  className="ml-7px designImg"
                />
              </div>
            </div>
            <div className="flex-col mt-6px editBox pointer" onClick={(e) => onSetSelectDesign(TIP_DESIGN_3)}>
              <div className="h-three-black me-3 flex mb-3">
                <input
                  type="radio"
                  name=""
                  id=""
                  onClick={(e) => onSetSelectDesign(TIP_DESIGN_3)}
                  checked={selectDesign == TIP_DESIGN_3 ? true : false}
                  className="mr-8px"
                />

                {t("group.designThree")}
              </div>
              <div>
                <img
                  src={designThree}
                  alt="designThree"
                  className="ml-7px designImg"
                />
              </div>
            </div>
          </div>
          {/* ******************* DESIGN TEMPLATES - END ******************* */}

          {/* ******************* PROFILE THEME - START ******************* */}
          <div className="h-two">
            {t("group.profileThemeHeading")}
            {checkActionPermission(
              adminUserRole,
              PERM_EDIT_ACCOUNT_DETAILS
            ) && (
                <>
                  {!editTipDetails
                    ? editButton("tipSettings")
                    : saveButton("tipSettings")}
                </>
              )}
          </div>
          <div className="editBox mb-15px  space-between p-4">
            <div className="flex-col w-75">
              <div className="flex mb-15px">
                <div className="flex-col mt-6px mb-30px me-5 ">
                  <div className="h-three-black mb-12px">
                    {t("accountDetails.logoUpload")}
                  </div>
                  {dataLoaded && (
                    <div>
                      <ImageBox
                        id={"AccTipLogo"}
                        label={""}
                        url={""}
                        defaultImage={
                          tipLogo && tipLogo.trim().length > 3 ? tipLogo : null
                        }
                        onUpload={(e) => onSetAccountTipLogo(e)}
                        hideDeleteOption={false}
                        resetImage={resetTipLogo}
                        checkDisable={!canEditAccountDetails}
                      />
                      {tipLogo &&
                        tipLogo.trim().length > 3 &&
                        canEditAccountDetails && (
                          <ButtonInLine
                            className=" mt-3 "
                            onClick={(e) => onRemoveAccounTipLogo()}
                            buttonLabel={t("accountDetails.removeLogo")}
                          />
                        )}
                    </div>
                  )}
                </div>
                {selectDesign == TIP_DESIGN_2 && (
                  <div className="flex-col mt-6px mb-30px ">
                    <div className="h-three-black mb-12px">
                      {t("accountDetails.imageUpload")}
                    </div>
                    {dataLoaded && (
                      <div>
                        <ImageBox
                          id={"AccTipBackgroundImage"}
                          label={""}
                          url={""}
                          defaultImage={
                            tipBackgroungImg &&
                              tipBackgroungImg.trim().length > 3
                              ? tipBackgroungImg
                              : null
                          }
                          onUpload={(e) => onSetAccountTipImage(e)}
                          hideDeleteOption={false}
                          resetImage={resetTipImage}
                          checkDisable={!canEditAccountDetails}
                        />
                        {tipBackgroungImg &&
                          tipBackgroungImg.trim().length > 3 &&
                          canEditAccountDetails && (
                            <ButtonInLine
                              className=" mt-3 "
                              onClick={(e) => onRemoveAccounTipImage()}
                              buttonLabel={t("accountDetails.removeImage")}
                            />
                          )}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div>
                <div className="h-three-black me-3 flex mb-20px">
                  {t("group.chooseBackgroundColor")}
                </div>
                <div className="flex gap-5">
                  <div className="flex-col">
                    <div className="payout-hfont mb-12px">
                      {selectDesign == TIP_DESIGN_3
                        ? t("accountDetails.hexCodeBg")
                        : t("accountDetails.hexCode")}
                    </div>
                    <div
                      className={
                        editTipDetails
                          ? "hex-code-box"
                          : "hex-code-box-disabled"
                      }
                    >
                      <div
                        className={
                          editTipDetails && editBgColor.trim().length == 0
                            ? "input-error hexcode-edit-icon"
                            : "hexcode-edit-icon"
                        }
                        style={setBgColor()}
                      >
                        <img src={pencil} alt="" />
                      </div>
                      <input
                        type="text"
                        value={editBgColor}
                        onChange={(e) => setEditBgColor(e.target.value)}
                        className={
                          editTipDetails && editBgColor.trim().length == 0
                            ? "input-error hexcode-input"
                            : "hexcode-input"
                        }
                        disabled={!editTipDetails}
                        maxLength={12}
                      />
                      <SketchPicker
                        color={editBgColor}
                        onChange={handleColorChange}
                        className="sketchPicker"
                      />
                    </div>
                  </div>
                  {selectDesign !== TIP_DESIGN_2 && (
                    <div className="flex-col">
                      <div className="payout-hfont mb-12px">
                        {selectDesign == TIP_DESIGN_3
                          ? t("accountDetails.hexCodeText")
                          : t("accountDetails.headerHexCode")}
                      </div>
                      <div
                        className={
                          editTipDetails
                            ? "hex-code-box"
                            : "hex-code-box-disabled"
                        }
                      >
                        <div
                          className={
                            editTipDetails && editTextColor.trim().length == 0
                              ? "input-error hexcode-edit-icon"
                              : "hexcode-edit-icon"
                          }
                          style={{ backgroundColor: editTextColor }}
                        >
                          <img src={pencilText} alt="" />
                        </div>
                        <input
                          type="text"
                          value={editTextColor}
                          onChange={(e) => setEditTextColor(e.target.value)}
                          className={
                            editTipDetails && editTextColor.trim().length == 0
                              ? "input-error hexcode-input"
                              : "hexcode-input"
                          }
                          disabled={!editTipDetails}
                        />
                        <SketchPicker
                          color={editTextColor}
                          onChange={(color: ColorResult) => {
                            setEditTextColor(color.hex);
                          }}
                          className="sketchPicker"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="left-center  mb-3 mt-4">
                <div className="h-three w-200px">
                  {t("group.showCustomOnly")}
                </div>
                <div>
                  <label
                    htmlFor=""
                    className="left-center gap-1 w-200px me-4 "
                  >
                    <input
                      type="radio"
                      name="rad_showCustomOnly"
                      onClick={(e) => setShowCustomOnly(false)}
                      checked={showCustomOnly ? false : true}
                      disabled={!editTipDetails}
                    />
                    <span
                      className="text-normal-color text-data"
                      onClick={(e) => setShowCustomOnly(false)}
                    >
                      {t("group.no")}
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="left-center gap-1 w-200px ">
                    <input
                      type="radio"
                      name="rad_showCustomOnly"
                      onClick={(e) => setShowCustomOnly(true)}
                      checked={!showCustomOnly ? false : true}
                      disabled={!editTipDetails}
                    />
                    <span
                      className="text-normal-color text-data"
                      onClick={(e) => setShowCustomOnly(true)}
                    >
                      {t("group.yes")}
                    </span>
                  </label>
                </div>
              </div>

              {!showCustomOnly && <div>
                <div className="h-three-black me-3 mt-3 flex ">
                  {t("group.tipPresetsHeading")}
                </div>
                <div className="space-between py-3 gap-3 w-100 ">
                  <div
                    className={`flex gap-2  ${selectDesign == TIP_DESIGN_3 && " border-bottom pb-2 "
                      }`}
                  >
                    <div className="mt-2 me-2">
                      <input
                        type="radio"
                        name="rad_Tip"
                        id=""
                        checked={selectedTip == 1}
                        onClick={() => setSelectedTip(1)}
                        disabled={!editTipDetails}
                      />
                    </div>
                    <label className="h-three mt-2 min-width-90px ">
                      {t("group.tipPreset1")}
                    </label>
                    <div className="relative">
                      <div
                        className={`input-dollar ${!editTipDetails && " border-white "
                          } ${editTipDetails &&
                          Number(tip_1) <= 0 &&
                          " input-error "
                          } `}
                      >
                        {t("common.dollar")}
                      </div>
                      <Textfield
                        type="number"
                        value={tip_1}
                        name={"tip_1"}
                        disabled={!editTipDetails}
                        className={`text-left pl-26px max-width-125px w-100 ${!editTipDetails && " border-white "
                          } ${editTipDetails &&
                          Number(tip_1) <= 0 &&
                          " input-error "
                          } `}
                        onChange={(e) => setTipValue(e.target.value, 1)}
                        max="99999"
                      />
                    </div>

                    {selectDesign === TIP_DESIGN_3 && (
                      <>
                        <label className="h-three ms-3 mt-2 min-width-90px ">
                          {t("group.description")}
                        </label>
                        <div className="w-86">
                          <textarea
                            rows={3}
                            cols={40}
                            className={`w-100  ${!editTipDetails && "border-white"
                              }`}
                            value={tippingFactBody_1}
                            disabled={!editTipDetails}
                            onChange={(e) =>
                              setTippingFactBody_1(e.target.value)
                            }
                            maxLength={TIP_FACT_BODY_MAX_LENGTH}
                          />
                          {editTipDetails && (
                            <div className="font-lite mt-2 payout-hfont">
                              {t("common.maxChars", {
                                maxLength: TIP_FACT_BODY_MAX_LENGTH,
                              })}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="space-between py-3 gap-3 w-100 ">
                  <div
                    className={`flex gap-2  ${selectDesign == TIP_DESIGN_3 && " border-bottom pb-2 "
                      }`}
                  >
                    <div className="mt-2 me-2">
                      <input
                        type="radio"
                        name="rad_Tip"
                        id=""
                        checked={selectedTip == 2}
                        onClick={() => setSelectedTip(2)}
                        disabled={!editTipDetails}
                      />
                    </div>
                    <label className="h-three mt-2 min-width-90px ">
                      {t("group.tipPreset2")}
                    </label>
                    <div className="relative">
                      <div
                        className={`input-dollar ${!editTipDetails && " border-white"
                          }  ${editTipDetails &&
                          Number(tip_2) <= 0 &&
                          " input-error "
                          } `}
                      >
                        {t("common.dollar")}
                      </div>
                      <Textfield
                        type="number"
                        value={tip_2}
                        name={"tip_2"}
                        disabled={!editTipDetails}
                        className={`text-left pl-26px  max-width-125px ${!editTipDetails && "border-white"
                          } ${editTipDetails &&
                          Number(tip_2) <= 0 &&
                          " input-error "
                          }  `}
                        onChange={(e) => setTipValue(e.target.value, 2)}
                        max="99999"
                      />
                    </div>
                    {selectDesign === TIP_DESIGN_3 && (
                      <>
                        <label className="h-three ms-3 mt-2 min-width-90px ">
                          {t("group.description")}
                        </label>
                        <div className="w-86">
                          <textarea
                            rows={3}
                            cols={40}
                            className={`w-100  ${!editTipDetails && "border-white"
                              }`}
                            value={tippingFactBody_2}
                            disabled={!editTipDetails}
                            onChange={(e) =>
                              setTippingFactBody_2(e.target.value)
                            }
                            maxLength={TIP_FACT_BODY_MAX_LENGTH}
                          />
                          {editTipDetails && (
                            <div className="font-lite mt-2 payout-hfont">
                              {t("common.maxChars", {
                                maxLength: TIP_FACT_BODY_MAX_LENGTH,
                              })}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="space-between py-3 gap-3 w-100 ">
                  <div
                    className={`flex gap-2  ${selectDesign == TIP_DESIGN_3 && " border-bottom pb-2 "
                      }`}
                  >
                    <div className="mt-2 me-2">
                      <input
                        type="radio"
                        name="rad_Tip"
                        id=""
                        checked={selectedTip == 3}
                        onClick={() => setSelectedTip(3)}
                        disabled={!editTipDetails}
                      />
                    </div>
                    <label className="h-three mt-2 min-width-90px ">
                      {t("group.tipPreset3")}
                    </label>
                    <div className="relative">
                      <div
                        className={`input-dollar ${!editTipDetails && "border-white"
                          }  ${editTipDetails &&
                          Number(tip_3) <= 0 &&
                          " input-error "
                          } `}
                      >
                        {t("common.dollar")}
                      </div>
                      <Textfield
                        type="number"
                        value={tip_3}
                        name={"tip_3"}
                        disabled={!editTipDetails}
                        className={`text-left pl-26px  max-width-125px ${!editTipDetails && "border-white"
                          } ${editTipDetails &&
                          Number(tip_3) <= 0 &&
                          " input-error "
                          } `}
                        onChange={(e) => setTipValue(e.target.value, 3)}
                        max="99999"
                      />
                    </div>
                    {selectDesign === TIP_DESIGN_3 && (
                      <>
                        <label className="h-three ms-3 mt-2 min-width-90px ">
                          {t("group.description")}
                        </label>
                        <div className="w-86">
                          <textarea
                            rows={3}
                            cols={40}
                            className={`w-100  ${!editTipDetails && "border-white"
                              }`}
                            value={tippingFactBody_3}
                            disabled={!editTipDetails}
                            onChange={(e) =>
                              setTippingFactBody_3(e.target.value)
                            }
                            maxLength={TIP_FACT_BODY_MAX_LENGTH}
                          />
                          {editTipDetails && (
                            <div className="font-lite mt-2 payout-hfont">
                              {t("common.maxChars", {
                                maxLength: TIP_FACT_BODY_MAX_LENGTH,
                              })}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="space-between py-3 gap-3 w-100 ">
                  <div
                    className={`flex gap-2  ${selectDesign == TIP_DESIGN_3 && " border-bottom pb-2 "
                      }`}
                  >
                    <div className="mt-2 me-2">
                      <input
                        type="radio"
                        name="rad_Tip"
                        id=""
                        checked={selectedTip == 4}
                        onClick={() => setSelectedTip(4)}
                        disabled={!editTipDetails}
                      />
                    </div>
                    <label className="h-three mt-2 min-width-90px ">
                      {t("group.tipPreset4")}
                    </label>
                    <div className="relative">
                      <div
                        className={`input-dollar ${!editTipDetails && "border-white"
                          }  ${editTipDetails &&
                          Number(tip_4) <= 0 &&
                          " input-error "
                          } `}
                      >
                        {t("common.dollar")}
                      </div>
                      <Textfield
                        type="number"
                        value={tip_4}
                        name={"tip_4"}
                        disabled={!editTipDetails}
                        className={`text-left pl-26px max-width-125px ${!editTipDetails && "border-white"
                          } ${editTipDetails &&
                          Number(tip_4) <= 0 &&
                          " input-error "
                          } `}
                        onChange={(e) => setTipValue(e.target.value, 4)}
                        max="99999"
                      />
                    </div>
                    {selectDesign === TIP_DESIGN_3 && (
                      <>
                        <label className="h-three ms-3 mt-2 min-width-90px ">
                          {t("group.description")}
                        </label>
                        <div className="w-86">
                          <textarea
                            rows={3}
                            cols={40}
                            className={` w-100  ${!editTipDetails && " border-white "
                              }`}
                            value={tippingFactBody_4}
                            disabled={!editTipDetails}
                            onChange={(e) =>
                              setTippingFactBody_4(e.target.value)
                            }
                            maxLength={TIP_FACT_BODY_MAX_LENGTH}
                          />
                          {editTipDetails && (
                            <div className="font-lite mt-2 payout-hfont">
                              {t("common.maxChars", {
                                maxLength: TIP_FACT_BODY_MAX_LENGTH,
                              })}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>

              </div>}

              <div>
                <div className="left-center mb-4 mt-15px ">
                  <div className="h-three w-200px">{t("group.roomlabel")}</div>
                  <div>
                    <label
                      htmlFor=""
                      className="left-center gap-1 w-200px me-4 "
                    >
                      <input
                        type="radio"
                        name="rad_roomLabel"
                        onClick={(e) => setRoomLabel(ROOM_NUMBER_VALUE)}
                        checked={roomLabel == ROOM_NUMBER_VALUE ? true : false}
                        disabled={!editTipDetails}
                      />
                      <span
                        className="text-normal-color text-data"
                        onClick={(e) => setRoomLabel(ROOM_NUMBER_VALUE)}
                      >
                        {ROOM_NUMBER_LABEL}
                      </span>
                    </label>
                  </div>
                  <div>
                    <label htmlFor="" className="left-center gap-1 w-200px ">
                      <input
                        type="radio"
                        name="rad_roomLabel"
                        onClick={(e) => setRoomLabel(ROOM_NAME_VALUE)}
                        checked={roomLabel == ROOM_NAME_VALUE ? true : false}
                        disabled={!editTipDetails}
                      />
                      <span
                        className="text-normal-color text-data"
                        onClick={(e) => setRoomLabel(ROOM_NAME_VALUE)}
                      >
                        {ROOM_NAME_LABEL}
                      </span>
                    </label>
                  </div>
                  <div>
                    <label htmlFor="" className="left-center gap-1 w-200px ">
                      <input
                        type="radio"
                        name="rad_roomLabel"
                        onClick={(e) => {
                          setRoomLabel(ROOM_NAME_REMOVE_VALUE);
                          setRoomLabelMandatory(false);
                        }}
                        checked={
                          roomLabel == ROOM_NAME_REMOVE_VALUE ? true : false
                        }
                        disabled={!editTipDetails}
                      />
                      <span
                        className="text-normal-color text-data"
                        onClick={(e) => {
                          setRoomLabel(ROOM_NAME_REMOVE_VALUE);
                          setRoomLabelMandatory(false);
                        }}
                      >
                        {ROOM_NAME_REMOVE_LABEL}
                      </span>
                    </label>
                  </div>
                </div>

                {roomLabel !== ROOM_NAME_REMOVE_VALUE && (
                  <div className="left-center  mb-4 mt-3">
                    <div className="h-three w-200px">
                      {t("group.labelsMandatory")}
                    </div>
                    <div>
                      <label
                        htmlFor=""
                        className="left-center gap-1 w-200px me-4 "
                      >
                        <input
                          type="radio"
                          name="rad_labelsMandatory"
                          onClick={(e) => setRoomLabelMandatory(false)}
                          checked={roomLabelMandatory ? false : true}
                          disabled={!editTipDetails}
                        />
                        <span
                          className="text-normal-color text-data"
                          onClick={(e) => setRoomLabelMandatory(false)}
                        >
                          {t("group.no")}
                        </span>
                      </label>
                    </div>
                    <div>
                      <label htmlFor="" className="left-center gap-1 w-200px ">
                        <input
                          type="radio"
                          name="rad_labelsMandatory"
                          onClick={(e) => setRoomLabelMandatory(true)}
                          checked={!roomLabelMandatory ? false : true}
                          disabled={!editTipDetails}
                        />
                        <span
                          className="text-normal-color text-data"
                          onClick={(e) => setRoomLabelMandatory(true)}
                        >
                          {t("group.yes")}
                        </span>
                      </label>
                    </div>
                  </div>
                )}

                 {/* STAFF LABEL FOR GROUPS - ADDED ON 13 Dec 2023 AS PER CLIENT REQUEST */}
                 <div className="left-center  mb-4 mt-3">
                    <div className="h-three w-200px">
                      {t("group.staffLabelForGroups")}
                    </div>
                    <div>
                      <label
                        htmlFor=""
                        className="left-center gap-1 w-200px me-4 "
                      >
                        <input
                          type="radio"
                          name="rad_staffLabel"
                          onClick={(e) => setStaffLabelForGroups(false)}
                          checked={staffLabelForGroups ? false : true}
                          disabled={!editTipDetails}
                        />
                        <span
                          className="text-normal-color text-data"
                          onClick={(e) => editTipDetails ? setStaffLabelForGroups(false) : dummyFunction()}
                        >
                          {t("group.no")}
                        </span>
                      </label>
                    </div>
                    <div>
                      <label htmlFor="" className="left-center gap-1 w-200px ">
                        <input
                          type="radio"
                          name="rad_staffLabel"
                          onClick={(e) => setStaffLabelForGroups(true)}
                          checked={!staffLabelForGroups ? false : true}
                          disabled={!editTipDetails}
                        />
                        <span
                          className="text-normal-color text-data"
                          onClick={(e) => editTipDetails ? setStaffLabelForGroups(true) : dummyFunction()}
                        >
                          {t("group.yes")}
                        </span>
                      </label>
                    </div>
                  </div>

              </div>
            </div>
            {/* ******************* PROFILE THEME - PREVIEW - START ******************* */}
            <div className="flex-col">
              <div className="flex-col mt-55px">
                <div className="h-three-black center mb-40px me-3 flex ">
                  {t("accountDetails.pagePreview")}
                </div>
                <div>
                  {selectDesign === TIP_DESIGN_1 && (
                    <div className="relative">
                      <div className="tipLogoBox">
                        <img
                          src={tipLogo ? tipLogo : dragdrop}
                          alt="tip logo"
                          className="tipLogo"
                        />

                      </div>

                      <div className="tip-option">
                        <p className="tip-para" style={{ color: editBgColor }}>
                          {t("accountDetails.tipPreviewStaticHeader")}
                        </p>
                        <span
                          className="tip-value"
                          style={{ color: editBgColor }}
                        >
                          {t("currency")}
                          {showCustomOnly ? "15" :
                            selectedTip === 1
                              ? tip_1
                              : selectedTip === 2
                                ? tip_2
                                : selectedTip === 3
                                  ? tip_3
                                  : tip_4}
                        </span>
                        <p className="tip-para" style={{ color: editBgColor }}>
                          {t("accountDetails.tipPreviewStaticSubText")}
                        </p>
                        <div className="tip-section">
                          {showCustomOnly ? <>
                            <div className="input-icons ">
                              <div className="space-between mb-2">
                                <p className="tip-label">{t("tipPage.tipAmount")}</p>
                                <p className="tip-label pointer" >
                                  {/* {t("tipPage.backToDefault")} */}
                                </p>
                              </div>

                              <label id="search1">
                                <i className="fa fa-dollar" style={{ top: "8px" }}></i>
                                <input
                                  className={`tip-input-tip pt-1px pl-18px `}
                                  disabled={true}
                                  style={{ height: "32px" }}
                                  value={15}
                                />
                              </label>
                            </div>
                          </> : <>
                            <div className="flex" style={{ gap: "6px" }}>
                              <div
                                className="tip-box"
                                style={{
                                  backgroundColor:
                                    selectedTip === 1 ? editBgColor : "#f5f5f5",
                                  color: selectedTip === 1 ? "white" : "black",
                                }}
                              >
                                {t("currency")}
                                {tip_1}
                              </div>
                              <div
                                className="tip-box"
                                style={{
                                  backgroundColor:
                                    selectedTip === 2 ? editBgColor : "#f5f5f5",
                                  color: selectedTip === 2 ? "white" : "black",
                                }}
                              >
                                {t("currency")}
                                {tip_2}
                              </div>
                              <div
                                className="tip-box"
                                style={{
                                  backgroundColor:
                                    selectedTip === 3 ? editBgColor : "#f5f5f5",
                                  color: selectedTip === 3 ? "white" : "black",
                                }}
                              >
                                {t("currency")}
                                {tip_3}
                              </div>
                              <div
                                className="tip-box"
                                style={{
                                  backgroundColor:
                                    selectedTip === 4 ? editBgColor : "#f5f5f5",
                                  color: selectedTip === 4 ? "white" : "black",
                                }}
                              >
                                {t("currency")}
                                {tip_4}
                              </div>
                            </div>
                            <div className="custom_tip-box w-100" >
                              <p className="">{t("tipPage.enterCustomTip")}</p>
                            </div>
                          </>}
                        </div>
                      </div>

                      {roomLabel != ROOM_NAME_REMOVE_VALUE && (
                        <div className="template-room-numLabel">
                          {roomLabel == ROOM_NUMBER_VALUE
                            ? t("tipPage.roomNumberLabel")
                            : roomLabel == ROOM_NAME_VALUE
                              ? t("tipPage.roomNameLabel")
                              : ""}{" "}
                          {roomLabelMandatory ? " *" : ""}
                          <input
                            type="text"
                            value={""}
                            className={"template-room-numLabel-placeholder "}
                            placeholder={
                              roomLabel == ROOM_NUMBER_VALUE &&
                                roomLabelMandatory
                                ? t("tipPage.roomNumber")
                                : roomLabel == ROOM_NUMBER_VALUE &&
                                  !roomLabelMandatory
                                  ? t("tipPage.roomNumberOpt")
                                  : roomLabel == ROOM_NAME_VALUE &&
                                    roomLabelMandatory
                                    ? t("tipPage.roomName")
                                    : roomLabel == ROOM_NAME_VALUE &&
                                      !roomLabelMandatory
                                      ? t("tipPage.roomNameOpt")
                                      : ""
                            }
                            disabled={true}
                          />
                        </div>
                      )}

                      {/* {staffLabelForGroups && (<div className="template-room-numLabel">
                          {t("tipPage.staffNameForGroup")}
                          <input
                            type="text"
                            value={""}
                            className={"template-room-numLabel-placeholder "}
                            placeholder={t("tipPage.staffNameForGroup") }
                            disabled={true}
                          />
                        </div>)} */}

                      <TipTemplateOne
                        accountLogo={tipLogo ? tipLogo : dragdrop}
                        backgroundColor={editBgColor}
                        headerColor={editTextColor}
                      />
                    </div>
                  )}
                  {selectDesign === TIP_DESIGN_3 && (
                    <div className="relative">
                      <div className="tipLogoBoxThree">
                        <img
                          src={tipLogo ? tipLogo : dragdrop}
                          alt="tip logo"
                          className="tipLogoThree"
                        />
                      </div>

                      <div className="tipDescription">
                        <div
                          className="tipDescriptionBox"
                          style={{
                            backgroundColor: editBgColor,
                            color: editTextColor,
                          }}
                        >
                          <p>
                            {selectedTip === 1
                              ? tippingFactBody_1
                              : selectedTip === 2
                                ? tippingFactBody_2
                                : selectedTip === 3
                                  ? tippingFactBody_3
                                  : tippingFactBody_4}
                          </p>
                        </div>
                      </div>

                      <div className="tip-option-three">
                        <p className="tip-para" style={{ color: editBgColor }}>
                          {t("accountDetails.tipPreviewStaticHeader")}
                        </p>
                        <span
                          className="tip-value-three"
                          style={{ color: editBgColor }}
                        >
                          {t("currency")}
                          {showCustomOnly ? "15" : selectedTip === 1
                            ? tip_1
                            : selectedTip === 2
                              ? tip_2
                              : selectedTip === 3
                                ? tip_3
                                : tip_4}
                        </span>
                        <p className="tip-para" style={{ color: editBgColor }}>
                          {t("accountDetails.tipPreviewStaticSubText")}
                        </p>
                        <div className="tip-section-three">

                          {showCustomOnly ? <>
                            <div className="input-icons ">
                              <div className="space-between mb-2">
                                <p className="tip-label">{t("tipPage.tipAmount")}</p>
                                <p className="tip-label pointer" >
                                  {/* {t("tipPage.backToDefault")} */}
                                </p>
                              </div>

                              <label id="search1">
                                <i className="fa fa-dollar" style={{ top: "8px" }}></i>
                                <input
                                  className={`tip-input-tip pt-1px pl-18px `}
                                  disabled={true}
                                  style={{ height: "32px" }}
                                  value={15}
                                />
                              </label>
                            </div>
                          </> : <>
                            <div className="flex" style={{ gap: "6px" }}>
                              <div
                                className="tip-box"
                                style={{
                                  backgroundColor:
                                    selectedTip === 1 ? editBgColor : "#f5f5f5",
                                  color:
                                    selectedTip === 1 ? editTextColor : "black",
                                }}
                              >
                                {t("currency")}
                                {tip_1}
                              </div>
                              <div
                                className="tip-box"
                                style={{
                                  backgroundColor:
                                    selectedTip === 2 ? editBgColor : "#f5f5f5",
                                  color:
                                    selectedTip === 2 ? editTextColor : "black",
                                }}
                              >
                                {t("currency")}
                                {tip_2}
                              </div>
                              <div
                                className="tip-box"
                                style={{
                                  backgroundColor:
                                    selectedTip === 3 ? editBgColor : "#f5f5f5",
                                  color:
                                    selectedTip === 3 ? editTextColor : "black",
                                }}
                              >
                                {t("currency")}
                                {tip_3}
                              </div>
                              <div
                                className="tip-box"
                                style={{
                                  backgroundColor:
                                    selectedTip === 4 ? editBgColor : "#f5f5f5",
                                  color:
                                    selectedTip === 4 ? editTextColor : "black",
                                }}
                              >
                                {t("currency")}
                                {tip_4}
                              </div>
                            </div>
                            <div className="custom_tip-box w-100" >
                              <p className="">{t("tipPage.enterCustomTip")}</p>
                            </div>
                          </>}
                        </div>
                      </div>

                      {roomLabel != ROOM_NAME_REMOVE_VALUE && (
                        <div className="template-room-numLabel-d3">
                          {roomLabel == ROOM_NUMBER_VALUE
                            ? t("tipPage.roomNumberLabel")
                            : roomLabel == ROOM_NAME_VALUE
                              ? t("tipPage.roomNameLabel")
                              : ""}{" "}
                          {roomLabelMandatory ? " *" : ""}
                          <input
                            type="text"
                            value={""}
                            className={"template-room-numLabel-placeholder "}
                            placeholder={
                              roomLabel == ROOM_NUMBER_VALUE &&
                                roomLabelMandatory
                                ? t("tipPage.roomNumber")
                                : roomLabel == ROOM_NUMBER_VALUE &&
                                  !roomLabelMandatory
                                  ? t("tipPage.roomNumberOpt")
                                  : roomLabel == ROOM_NAME_VALUE &&
                                    roomLabelMandatory
                                    ? t("tipPage.roomName")
                                    : roomLabel == ROOM_NAME_VALUE &&
                                      !roomLabelMandatory
                                      ? t("tipPage.roomNameOpt")
                                      : ""
                            }
                            disabled={true}
                          />
                        </div>
                      )}
                      <TipTemplateThree
                        backgroundColor={editBgColor}
                        textColor={editTextColor}
                      />
                    </div>
                  )}
                  {selectDesign === TIP_DESIGN_2 && (
                    <div className="relative center">
                      <div className="tip-option-two">
                        <p className="tip-para">
                          {t("accountDetails.tipPreviewStaticHeader")}
                        </p>
                        <span className="tip-value">
                          {t("currency")}
                          {showCustomOnly ? "15" : selectedTip === 1
                            ? tip_1
                            : selectedTip === 2
                              ? tip_2
                              : selectedTip === 3
                                ? tip_3
                                : tip_4}
                        </span>
                        <p className="tip-para">
                          {t("accountDetails.tipPreviewStaticSubText")}
                        </p>
                      </div>

                      <div className="tip-section-two">

                        {showCustomOnly ? <>
                          <div className="input-icons ">
                            <div className="space-between mb-2">
                              <p className="tip-label">{t("tipPage.tipAmount")}</p>
                              <p className="tip-label pointer" >
                                {/* {t("tipPage.backToDefault")} */}
                              </p>
                            </div>

                            <label id="search1">
                              <i className="fa fa-dollar" style={{ top: "8px" }}></i>
                              <input
                                className={`tip-input-tip pt-1px pl-18px `}
                                disabled={true}
                                style={{ height: "32px" }}
                                value={15}
                              />
                            </label>
                          </div>
                        </> : <>
                          <div className="w-100 flex-start mb-6px">
                            <label htmlFor="" className="tip3-label">
                              {t("tipPage.ChooseTip")}
                            </label>
                          </div>
                          <div className="flex" style={{ gap: "6px" }}>
                            <div
                              className="tip-box"
                              style={{
                                backgroundColor:
                                  selectedTip === 1 ? editBgColor : "#f5f5f5",
                                color: selectedTip === 1 ? "white" : "black",
                              }}
                            >
                              {t("currency")}
                              {tip_1}
                            </div>
                            <div
                              className="tip-box"
                              style={{
                                backgroundColor:
                                  selectedTip === 2 ? editBgColor : "#f5f5f5",
                                color: selectedTip === 2 ? "white" : "black",
                              }}
                            >
                              {t("currency")}
                              {tip_2}
                            </div>
                            <div
                              className="tip-box"
                              style={{
                                backgroundColor:
                                  selectedTip === 3 ? editBgColor : "#f5f5f5",
                                color: selectedTip === 3 ? "white" : "black",
                              }}
                            >
                              {t("currency")}
                              {tip_3}
                            </div>
                            <div
                              className="tip-box"
                              style={{
                                backgroundColor:
                                  selectedTip === 4 ? editBgColor : "#f5f5f5",
                                color: selectedTip === 4 ? "white" : "black",
                              }}
                            >
                              {t("currency")}
                              {tip_4}
                            </div>
                          </div>
                          <div className="custom_tip-box w-100" >
                            <p className="">{t("tipPage.enterCustomTip")}</p>
                          </div>
                        </>}
                      </div>
                      {roomLabel != ROOM_NAME_REMOVE_VALUE && (
                        <div className="template-room-numLabel-d2">
                          {roomLabel == ROOM_NUMBER_VALUE
                            ? t("tipPage.roomNumberLabel")
                            : roomLabel == ROOM_NAME_VALUE
                              ? t("tipPage.roomNameLabel")
                              : ""}
                          <div>{roomLabelMandatory ? " *" : ""}</div>
                          <div className="div-right">
                            <input
                              type="text"
                              value={""}
                              className={
                                "template-room-numLabel-d2-placeholder"
                              }
                              placeholder={
                                roomLabel == ROOM_NUMBER_VALUE &&
                                  roomLabelMandatory
                                  ? t("tipPage.roomNumber")
                                  : roomLabel == ROOM_NUMBER_VALUE &&
                                    !roomLabelMandatory
                                    ? t("tipPage.roomNumberOpt")
                                    : roomLabel == ROOM_NAME_VALUE &&
                                      roomLabelMandatory
                                      ? t("tipPage.roomName")
                                      : roomLabel == ROOM_NAME_VALUE &&
                                        !roomLabelMandatory
                                        ? t("tipPage.roomNameOpt")
                                        : ""
                              }
                              disabled={true}
                            />
                          </div>
                        </div>
                      )}
                      <TipTemplateTwo
                        accountLogo={tipLogo ? tipLogo : dragdrop}
                        backgroundImage={tipBackgroungImg}
                        backgroundColor={editBgColor}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* ******************* PROFILE THEME - PREVIEW - END ******************* */}
          </div>
          {/* ******************* PROFILE THEME - END ******************* */}

          {/* ******************* TIP CONFIRMATION MESSAGE - START ******************* */}
          <div className="h-two mt-3">
            {t("accountDetails.confirmationMessage")}
            {checkActionPermission(
              adminUserRole,
              PERM_EDIT_ACCOUNT_DETAILS
            ) && (
                <>
                  <div>
                    {editConfirmMessage
                      ? saveButton("tipConfirmMessage")
                      : editButton("tipConfirmMessage")}
                  </div>
                </>
              )}
          </div>
          <div className="editBox">
            <div className="space-between">
              <div className="w-75">
                <label htmlFor="" className="flex-col">
                  <textarea
                    rows={3}
                    className={`tip-confirm-msg w-100  ${!editConfirmMessage && " border-white "
                      } ${editConfirmMessage &&
                      (!confirmationMessage ||
                        confirmationMessage.trim().length == 0) &&
                      " input-error "
                      }`}
                    value={confirmationMessage}
                    disabled={!editConfirmMessage}
                    onChange={(e) => setConfirmationMessage(e.target.value)}
                    maxLength={TIP_CONFIRM_MSG_MAX_LENGTH}
                  />
                  {editConfirmMessage && (
                    <div className="font-lite mt-1 ml-1px payout-hfont">
                      {t("common.maxChars", {
                        maxLength: TIP_CONFIRM_MSG_MAX_LENGTH,
                      })}
                    </div>
                  )}
                </label>
              </div>
            </div>
          </div>
          {/* ******************* TIP CONFIRMATION MESSAGE - END ******************* */}

          {/* ******************* REVIEW LINKS - START ******************* */}
          <div className="h-two mt-3">
            {t("accountDetails.reviewLinks")}
            {checkActionPermission(
              adminUserRole,
              PERM_EDIT_ACCOUNT_DETAILS
            ) && (
                <>
                  <div>
                    {editReviewLinks
                      ? saveButton("editReviewLinks")
                      : editButton("editReviewLinks")}
                  </div>
                </>
              )}
          </div>

          <div className="editBox">
            <div className="space-between">
              <div className="w-75">
                <div className="h-three-black mb-4">
                  {t("accountDetails.reviewMessage")}
                </div>
                <label htmlFor="" className="flex-col mb-3">
                  <span className="payout-hfont mb-2">
                    {t("accountDetails.reviewPageTitle")}
                  </span>
                  <Textfield
                    type="text"
                    className={editReviewLinks ? "" : " disabled"}
                    value={reviewTitle}
                    onChange={(e) => setReviewTitle(e.target.value)}
                    disabled={!editReviewLinks}
                    maxLength={REVIEW_HEADER_MAX_LENGTH}
                  />
                  {editReviewLinks && (
                    <div className="font-lite mt-1 ml-1px payout-hfont">
                      {t("common.maxChars", {
                        maxLength: REVIEW_HEADER_MAX_LENGTH,
                      })}
                    </div>
                  )}
                </label>
                <label htmlFor="" className="flex-col mb-3">
                  <span className="payout-hfont mb-2">
                    {t("accountDetails.reviewBodyMessage")}
                  </span>
                  <Textfield
                    type="text"
                    className={editReviewLinks ? "" : " disabled"}
                    value={reviewDescription}
                    onChange={(e) => setReviewDescription(e.target.value)}
                    disabled={!editReviewLinks}
                    maxLength={REVIEW_BODY_MAX_LENGTH}
                  />
                  {editReviewLinks && (
                    <div className="font-lite mt-1 ml-1px payout-hfont">
                      {t("common.maxChars", {
                        maxLength: REVIEW_BODY_MAX_LENGTH,
                      })}
                    </div>
                  )}
                </label>
                <div>
                  <label htmlFor="">
                    <span className="payout-hfont mb-2">
                      {t("accountDetails.reviewPlatforms")}
                    </span>
                  </label>
                  <div className="flex-col gap-2">
                    {reviewPlatforms &&
                      reviewPlatforms.map((reviewItem, index) => (
                        <div
                          className="review-container"
                          draggable
                          key={index}
                          onDragStart={(e) =>
                            (dragReviewItemId.current = index)
                          }
                          onDragEnter={(e) =>
                            (dragOverReviewItemId.current = index)
                          }
                          onDragEnd={
                            canEditAccountDetails ? handleSort : dummyFunction
                          }
                          onDragOver={(e) => e.preventDefault()}
                        >
                          <div className="review-head">
                            <div>
                              <img src={dragImg} alt="drag" className="me-4" />
                              <span className="h-three">{reviewItem.name}</span>
                            </div>
                            <div className="flex gap-3">
                              <input
                                type="checkbox"
                                name=""
                                id=""
                                className="reviewchckbox"
                                checked={reviewItem.isActive}
                                onClick={(e) =>
                                  changeReviewLinkValue(
                                    Number(reviewItem.order),
                                    "isActive",
                                    !reviewItem.isActive
                                  )
                                }
                                disabled={!canEditAccountDetails}
                              />
                              {canEditAccountDetails && reviewItem.isOpen && (
                                <>
                                  <button
                                    className="editbuttonReview"
                                    onClick={(e) => {
                                      changeReviewLinkValue(
                                        Number(reviewItem.order),
                                        "isOpen",
                                        !reviewItem.isOpen
                                      );
                                      resetReviewLink(Number(reviewItem.id));
                                    }}
                                  >
                                    {t("common.cancel")}
                                  </button>
                                  <button
                                    className="editbuttonReview"
                                    onClick={(e) =>
                                      saveReviewLinkValues(
                                        Number(reviewItem.id)
                                      )
                                    }
                                  >
                                    {t("common.save")}
                                  </button>
                                </>
                              )}
                              {canEditAccountDetails && !reviewItem.isOpen && (
                                <button
                                  className="editbuttonReview"
                                  onClick={(e) =>
                                    changeReviewLinkValue(
                                      Number(reviewItem.order),
                                      "isOpen",
                                      !reviewItem.isOpen
                                    )
                                  }
                                >
                                  {t("common.edit")}{" "}
                                  <img src={pen} alt="pen" className="" />
                                </button>
                              )}
                            </div>
                          </div>
                          {reviewItem.isOpen && (
                            <>
                              <div className="review-body">
                                <label htmlFor="" className="flex-col">
                                  <span className="payout-hfont mb-2">
                                    {t("accountDetails.reviewPlatformName")}
                                  </span>
                                  <div className="mb-3">
                                    <Textfield
                                      type="text"
                                      className={
                                        !reviewItem.name ||
                                          reviewItem.name.trim().length == 0
                                          ? " input-error  bg-transparent "
                                          : " bg-transparent"
                                      }
                                      value={reviewItem.name}
                                      onChange={(e) =>
                                        changeReviewLinkValue(
                                          Number(reviewItem.order),
                                          "name",
                                          e.target.value
                                        )
                                      }
                                      maxLength={
                                        REVIEW_PLATFORM_NAME_MAX_LENGTH
                                      }
                                    />
                                    {/* <div style={{ width: "164px" }}> </div>  */}
                                    <div className="font-lite mt-1 ml-1px payout-hfont">
                                      {t("common.maxChars", {
                                        maxLength:
                                          REVIEW_PLATFORM_NAME_MAX_LENGTH,
                                      })}
                                    </div>
                                  </div>
                                </label>
                                <label htmlFor="" className="flex-col">
                                  <span className="payout-hfont mb-2">
                                    {t("accountDetails.reviewLink")}
                                  </span>
                                  <div className="flex gap-3">
                                    <Textfield
                                      type="text"
                                      className={
                                        !reviewItem.link ||
                                          reviewItem?.link.trim().length == 0
                                          ? " input-error mb-3  bg-transparent"
                                          : " mb-3  bg-transparent"
                                      }
                                      value={reviewItem.link}
                                      onChange={(e) =>
                                        changeReviewLinkValue(
                                          Number(reviewItem.order),
                                          "link",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <div>
                                      {reviewItem?.link &&
                                        reviewItem.link.trim().length > 5 && (
                                          <ButtonInLine
                                            className="copybtn-review "
                                            onClick={(e) =>
                                              handleCopy(reviewItem.link)
                                            }
                                            buttonLabel={t("group.copy")}
                                            copyIcon={true}
                                          />
                                        )}
                                    </div>
                                    <div>
                                      {reviewItem?.link &&
                                        reviewItem.link.trim().length > 5 && (
                                          <ButtonInLine
                                            type="link"
                                            className="copybtn-review "
                                            href={reviewItem.link}
                                            target="blank"
                                            openIcon={true}
                                            buttonLabel={t("group.open")}
                                          />
                                        )}
                                    </div>
                                  </div>
                                </label>
                                <div className="flex gap-4">
                                  <div className="flex-col">
                                    <span className="payout-hfont mb-2">
                                      {t("accountDetails.reviewImageUpload")}
                                    </span>
                                    <label className="review-logoBox">
                                      <img
                                        src={reviewlogo}
                                        alt="reviewlogo"
                                        height={"34px"}
                                        width={"36px"}
                                      />
                                      <button className="review-drag-btn">
                                        {t("accountDetails.reviewBrowse")}
                                      </button>
                                      <input
                                        type="file"
                                        className=""
                                        onClick={(e) =>
                                          setLastReviewImageReviewId(
                                            Number(reviewItem.id)
                                          )
                                        }
                                        onChange={(e) =>
                                          uploadReviewImage(
                                            e,
                                            Number(reviewItem.id)
                                          )
                                        }
                                        accept=".png, .jpg, .jpeg"
                                      />
                                      {reviewItem.logo && (
                                        <img
                                          src={`${reviewItem.logo}`}
                                          alt=""
                                          className="review-input-logo-img"
                                        />
                                      )}
                                    </label>
                                    <div className="max-width-336px validFont">{t("validImg")}</div>

                                  </div>

                                  <label htmlFor="" className="flex-col">
                                    <span className="payout-hfont mb-2">
                                      {t("accountDetails.reviewBgHexCode")}
                                    </span>
                                    <div className="hex-code-box">
                                      <div
                                        className={
                                          !reviewItem?.backgroundColor ||
                                            reviewItem?.backgroundColor.trim()
                                              .length == 0
                                            ? " input-error  hexcode-edit-icon "
                                            : " hexcode-edit-icon "
                                        }
                                        style={{
                                          backgroundColor:
                                            reviewItem.backgroundColor,
                                        }}
                                      >
                                        <img src={pencilText} alt="" />
                                      </div>
                                      <input
                                        type="text"
                                        value={reviewItem.backgroundColor}
                                        onChange={(e) =>
                                          changeReviewLinkValue(
                                            Number(reviewItem.order),
                                            "backgroundColor",
                                            e.target.value
                                          )
                                        }
                                        className={
                                          !reviewItem?.backgroundColor ||
                                            reviewItem?.backgroundColor.trim()
                                              .length == 0
                                            ? " input-error hexcode-input "
                                            : " hexcode-input "
                                        }
                                      />
                                      <SketchPicker
                                        color={
                                          reviewItem.backgroundColor
                                            ? reviewItem.backgroundColor
                                            : ""
                                        }
                                        onChange={(color: ColorResult) => {
                                          changeReviewLinkValue(
                                            Number(reviewItem.order),
                                            "backgroundColor",
                                            color.hex
                                          );
                                        }}
                                        className="sketchPicker"
                                      />
                                    </div>
                                  </label>
                                  <label htmlFor="" className="flex-col">
                                    <span className="payout-hfont mb-2">
                                      {t("accountDetails.reviewTitleHexCode")}
                                    </span>
                                    <div className="hex-code-box">
                                      <div
                                        className={
                                          !reviewItem?.titleColor ||
                                            reviewItem?.titleColor.trim()
                                              .length == 0
                                            ? "input-error hexcode-edit-icon"
                                            : "hexcode-edit-icon"
                                        }
                                        style={{
                                          backgroundColor:
                                            reviewItem.titleColor,
                                        }}
                                      >
                                        <img src={pencilText} alt="" />
                                      </div>
                                      <input
                                        type="text"
                                        value={reviewItem.titleColor}
                                        onChange={(e) =>
                                          changeReviewLinkValue(
                                            Number(reviewItem.order),
                                            "titleColor",
                                            e.target.value
                                          )
                                        }
                                        className={
                                          !reviewItem?.titleColor ||
                                            reviewItem?.titleColor.trim()
                                              .length == 0
                                            ? "input-error hexcode-input"
                                            : "hexcode-input"
                                        }
                                      />
                                      <SketchPicker
                                        color={
                                          reviewItem.titleColor
                                            ? reviewItem.titleColor
                                            : ""
                                        }
                                        onChange={(color: ColorResult) => {
                                          changeReviewLinkValue(
                                            Number(reviewItem.order),
                                            "titleColor",
                                            color.hex
                                          );
                                        }}
                                        className="sketchPicker"
                                      />
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              {/* ***************** REVIEW PAGE PREVIEW - START *************************  */}
              <div className="flex-col center ">
                <div className="h-three-black mb-4">
                  {t("accountDetails.pagePreview")}
                </div>
                <div className="review-section">
                  <div className="reviewlogocontainer">
                    <img
                      src={tipLogo ? tipLogo : dragdrop}
                      alt="tip logo"
                      className="reviewlogo"
                    />

                  </div>

                  <div className="review-textBox">
                    <div
                      className={
                        selectDesign === TIP_DESIGN_3
                          ? "review-childTextBoxThree"
                          : "review-childTextBox"
                      }
                      style={{
                        backgroundColor:
                          selectDesign == TIP_DESIGN_3 ? editBgColor : "white ",
                      }}
                    >
                      <p
                        className={`review-title-mob ${selectDesign == TIP_DESIGN_1 && " mt-3 "
                          }`}
                        style={{
                          color:
                            selectDesign == TIP_DESIGN_3
                              ? editTextColor
                              : editBgColor,
                        }}
                      >
                        {reviewTitle}
                      </p>
                      <p
                        className="review-para-mob "
                        style={{
                          color:
                            selectDesign == TIP_DESIGN_3
                              ? editTextColor
                              : "black",
                        }}
                      >
                        {reviewDescription}&nbsp;
                      </p>
                    </div>
                  </div>

                  <div className="reviewItems ">
                    <div className="reviewItemsBox">
                      {reviewPlatforms &&
                        reviewPlatforms
                          .filter((rItem) => rItem.isActive == true)
                          .map((reviewItem) => (
                            <div
                              className={`reviewItem ${reviewActiveItemCount === 4 &&
                                " max-width-85px "
                                } ${reviewActiveItemCount < 4 && " mb-1 "}`}
                              style={{
                                backgroundColor: reviewItem.backgroundColor,
                                color: reviewItem.titleColor,
                              }}
                            >
                              <img
                                src={
                                  reviewItem.logo ? reviewItem.logo : reviewSkl
                                }
                                alt=""
                                className="reviewItemLogo"
                              />
                              <p
                                className={
                                  reviewActiveItemCount < 4
                                    ? "reviewItemPara"
                                    : "reviewItemPara-four"
                                }
                              >
                                {reviewItem.name}
                              </p>
                            </div>
                          ))}
                    </div>
                  </div>

                  <div
                    className="reviewBgcolor"
                    style={{
                      backgroundColor:
                        selectDesign === TIP_DESIGN_1 ? editTextColor : "white",
                    }}
                  ></div>

                  <img src={reviewBg} alt="" className="review_bg" />
                </div>
              </div>
              {/* ***************** REVIEW PAGE PREVIEW - END ***************************  */}
            </div>
          </div>
          {/* ******************* REVIEW LINKS - END ******************* */}
        </div>
      </div>

      <Modal
        open={addGroup}
        onClose={() => setAddGroup(false)}
        aria-labelledby={"Add Group"}
        aria-describedby=""
      >
        <Box sx={style}>
          <div className="w-100  manage-modal">
            <div className="space-between center border-bottom p-3">
              <h1> {t("accountDetails.createGroupModal")}</h1>
              <span onClick={() => setAddGroup(false)}>
                <CloseRounded className="pointer" />
              </span>
            </div>

            <div className="bg-light">
              <div>
                <div className=" border-bottom p-3 ">
                  <span className="sub-text text-center">
                    {t("accountDetails.subTextModal")}
                  </span>
                </div>
              </div>

              <div className="border-bottom px-3 pt-4 pb-2 bg-white">
                <div className=" w-100">
                  <div className="label">
                    {t("accountDetails.nameGroupModal")}
                  </div>
                  <Textfield
                    type="text"
                    value={newGroupName}
                    name="group_name"
                    id="group_name"
                    placeholder={`${t("group.groupName")}`}
                    onChange={(e) => setNewGroupName(e.target.value)}
                    maxLength={100}
                    className={newGroupNameError ? " input-error " : ""}
                  />
                </div>

                <div className=" w-100 my-4">
                  <div className="label">
                    {t("accountDetails.accountModal")}
                  </div>
                  <div className="percentage-input w-100">
                    <SelectField
                      type="inputType"
                      name="sel_group_account"
                      id="sel_group_account"
                      options={
                        <option value={accountId ? accountId.toString() : ""}>
                          {accountDetails.hotelName}
                        </option>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-4">
              <div className="flex w-100 gap-4">
                <ButtonModal
                  type="cancel"
                  onClick={() => setAddGroup(false)}
                  buttonLabel={t("common.cancel")}
                />

                <ButtonModal
                  buttonLabel={t("accountDetails.createGroupModal")}
                  onClick={() => onCreateNewGroup()}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={statusPopup}
        onClose={() => setStatusPopup(false)}
        aria-labelledby={"Add Group"}
        aria-describedby=""
      >
        <Box sx={style1}>
          <div className="idproof-modal div-center text-center relative">
            <div className="page-heading">
              {/* <CloseRounded
                onClick={() => setStatusPopup(false)}
                className="close"
              /> */}
              <img src={warning} alt="" />
            </div>
            <div className="idproof-flex-center div-center">
              <div>
                {t("accountDetails.modalDeactivateConfirm")}
                <br />
                {accountDetails.hotelName}
              </div>
            </div>

            <div className="idproof-margin"></div>
            <div className="flex w-100">
              <ButtonModal
                type="cancel"
                onClick={(e) => setStatusPopup(false)}
                buttonLabel={t("common.cancel")}
              />
              <ButtonModal
                type="delete"
                onClick={(e) => onDeactivateAccount()}
                buttonLabel={t("accountDetails.deactivateAccount")}
                className=" ml-15px"
              />
            </div>
          </div>
        </Box>
      </Modal>


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleLogoPopup} className=" center flex-col">
          <div className="w-100 relative">
            <IconButton onClick={() => handleClose()} aria-label="delete" size="large" sx={{ position: "absolute", right: "-32px", top: "-32px" }}>
              <CloseRounded sx={{ fontSize: "17px" }} />
            </IconButton>
          </div>
          <img src={accountLogoImg} alt="" width={"auto"} height={"auto"} className="max-height-600px min-width-200px max-w-617px mt-1" />
        </Box>
      </Modal>
    </div>
  );
}

export default AccountDetail;
