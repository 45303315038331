import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { ROUTE_TIP_RECEIPT, ROUTE_TIP_SUCCESS, notification_shown_time_failed } from "../../config/env";
import { IReceiptInterface, getReceiptData, sendReceiptMail } from "../../services/tipService";
import Modal from '@mui/material/Modal';
import { Box, IconButton } from "@mui/material";
import iconClose from '../../assets/images/receiptCloseicon.svg'
import { setNotification } from "../../services/apiService";
import { isEmail } from "../../helpers/validators";
import { Store } from "react-notifications-component";
import successIcon from "../../assets/images/storeSuccessIcon.svg";
import failedIcon from "../../assets/images/storeFailedIcon.svg";
import closeIcon from "../../assets/images/storeCloseIcon.svg";

interface CustomizedState {
  design_type: number;
  amount: string;
  intent_id: string;
  hotel_logo: string;
  hotelBg_image: string;
  hotel_data: string;
  tipValue: string;
  accCode: string;
  psCode: string;
  psType: string;
  theme_color: string;
  theme_text_color: string;
  message_title: string;
  message_description: string;
  review_section: any;

  card: string;
  expiry: string;
  lastATMPin: string;
  date: string;
  time: string;
  room: string;
  cardImage: string;
}

const ReceiptPage = (props: any) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '2px solid white',
    borderRadius: 6,
    boxShadow: 24,
    p: 4,
  };

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as CustomizedState;
  const {
    intent_id,
    amount,
    hotel_logo,
    hotelBg_image,
    hotel_data,
    tipValue,
    accCode,
    psCode,
    psType,
    theme_color,
    design_type,
    theme_text_color,
    message_description,
    message_title,
    review_section,
    card,
    expiry,
    lastATMPin,
    cardImage,
    date,
    time,
    room,
  } = state;


  const [open, setOpen] = React.useState(false);
  const [mail, setMail] = useState("")



  const handleEmailTemplate = (data: any) => {
    let content = `<!DOCTYPE html>
    <html lang="en">
    
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>`+ data.receiptPage + `</title>
    </head>
    
    <body
      style="margin: 0; padding: 0; font-family: Arial, sans-serif; background-color: #f5f5f5; display: flex; justify-content: center;align-items: center;">
      <div style="max-width: 400px; width: 100%; margin-left: 10px; margin-top: 10px; ">
        <div style="background-color: white;">
    
          <div id="main">
            <div>
              <div
                style="position: sticky; width: 100%; min-height: 133px; display: flex;justify-content: center; align-items: center; background-color: `+ data.header_color + `;">
                `;
    if (data.hotel_logo && data.hotel_logo.length > 0) {
      content = content +
        `<div style="text-align:center;width:100%; margin-top:30px;">
                      <img src="`+ data.hotel_logo + `" alt=""
                        style="width: 100%; height: auto; max-width: 200px; max-height: 73px; aspect-ratio: 3/2; object-fit: contain; z-index: 1;">
                    </div>`;
    } else {
      content = content +
        `<div style="font-size: larger;
        font-weight: bold;
        text-align: center;
        width:100%;
        margin-top:58px;
        color:`+ data.hotel_head_color + `">
                    `+ data.hotel_name + `
                    </div>`;
    }
    content = content + `

              </div>
            </div>
          </div>
    
          <div style="padding: 0px 12px;">
    
            <div>
              <h1
                style="font-family: Arial; font-size: 24.67px; font-style: normal; font-weight: 700; line-height: normal; margin: 10px 0px 20px;color:`+ data.theme_color + `;">
                `+ data.receipt_title + `</h1>
            </div>
    
            <div style="padding: 0 3px;">
              <label
                style="color: #2F2C2C; font-family: Roboto; font-size: 10.408px; font-style: normal; font-weight: 700; line-height: normal; letter-spacing: 0.624px; text-transform: uppercase;">`+ data.date + `
              </label>
              &nbsp;
              <span
                style="color: #000; font-family: Open Sans, sans-serif; font-size: 10.793px; font-style: normal; font-weight: 400; line-height: normal;">`+ data.date_value + `</span>
            </div>
            <div style="padding: 0 3px;">
              <label
                style="color: #2F2C2C; font-family: Roboto; font-size: 10.408px; font-style: normal; font-weight: 700; line-height: normal; letter-spacing: 0.624px; text-transform: uppercase;">`+ data.room + `
              </label>
              &nbsp;
              <span
                style="color: #000; font-family: Open Sans, sans-serif; font-size: 10.793px; font-style: normal; font-weight: 400; line-height: normal;">`+ data.room_value + `</span>
            </div>
    
            <div style="padding: 0 3px;">
              <label
                style="color: #2F2C2C; font-family: Roboto; font-size: 10.408px; font-style: normal; font-weight: 700; line-height: normal; letter-spacing: 0.624px; text-transform: uppercase;">`+ data.bill_to + `</label>

              <div style="display: flex;width:100%;text-align:center; margin-top:20px;"> 
              
              <div style="margin:0px auto ; text-align:center; display:flex;">`;

    if (data.card_image && data.card_image.length > 0) {
      content = content +
        ` <div style="width:36px;height:33px;object-fit: contain;margin-right:15px;">
                   <img src="`+ data.card_image + `" alt="" style="width:100%;height:100%;"/>
                    </div>`;
    }
    content = content + `
                <div >
                  <div
                    style="display: flex; color: #000; font-family: Arial; font-size: 12px; font-style: normal; font-weight: 700; line-height: normal;margin-bottom:5px;text-transform: capitalize; min-width: 82px; text-align: left; ">
                    `+ data.card_value + ` <span style="width: 100%; text-align: end;">&nbsp;&#183;&#183;&#183;&#183;` + data.last_pin + `</span>
                  </div>
                  <div style="display: flex; min-width: 82px; ">
                    <span
                      style="color: #000; font-family: Arial; font-size: 8px; font-style: normal; font-weight: 400; line-height: normal; 
                      width: 100%;
                      text-align: left;">`+ data.expiry + `</span>
                    <span
                      style="color: #000; font-family: Arial; font-size: 8px; font-style: normal; font-weight: 400; line-height: normal; 
                      width: 100%;
                      text-align: end;">`+ data.time + `</span>
                  </div>
                </div>
                </div>
              </div>

            </div>
    
            <hr style="margin: 20px 0px 29px; color: rgb(230, 230, 230);opacity: .15;">
    
            <div style="display: flex; justify-content: space-between; padding: 0 3px;margin-bottom: 7px;width:100%;">
              <label
                style="color: #2F2C2C; font-family: Roboto; font-size: 10.408px; font-style: normal; font-weight: 700; line-height: normal; letter-spacing: 0.624px; text-transform: uppercase; margin-right:120px;">`+ data.location + ` </label>
              <div
                style="color: #000; font-family: Open Sans, sans-serif; font-size: 10.793px; font-style: normal; font-weight: 400; line-height: normal; max-width:165px"> `+ data.location_value + `</div>
            </div>
            <div style="display: flex; justify-content: space-between; padding: 0 3px;margin-bottom: 7px;width:100%;">
              <label
                style="color: #2F2C2C; font-family: Roboto; font-size: 10.408px; font-style: normal; font-weight: 700; line-height: normal; letter-spacing: 0.624px; text-transform: uppercase; margin-right:141px;">`+ data.payee + ` </label>
              <span
                style="color: #000; font-family: Open Sans, sans-serif; font-size: 10.793px; font-style: normal; font-weight: 400; line-height: normal;"> `+ data.payee_value + `</span>
            </div>
            <div style="display: flex; justify-content: space-between; padding: 0 3px;margin-bottom: 7px;width:100%;">
              <label style="color: #2F2C2C;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: 0.72px;
              text-transform: uppercase;  margin-right:134px;">  `+ data.total + `  </label>
              <span
                style="color: #000; font-family: Arial; font-size: 12px; font-style: normal; font-weight: 700; line-height: normal;">  $`+ data.total_value + `</span>
            </div>
    
            <hr style="margin: 20px 0px 29px; color: rgb(230, 230, 230);opacity: .15;">
    
    
            <div>
              <h1
                style="font-family: Arial; font-size: 24.67px; font-style: normal; font-weight: 700; line-height: normal; margin: 25px 0px 20px; text-align: center; color:`+ data.theme_color + `;">
                `+ data.thank + `</h1>
            </div>
    
            <p style="    font-family: Roboto !important;
          color: rgba(47, 44, 44, 0.24);
          font-size: 10.414px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.625px;
          text-transform: uppercase;text-align: center; padding-bottom: 20px;">`+ data.tAndc1 + ` <a style="color: rgba(47, 44, 44, 0.24) !important; text-decoration: none; ">` + data.tAndc2 + `</a></p>    
          </div>   
    
        </div>
      </div>
    </body>
    
    </html>`;
    return content;
  }

  const onHandleSend = (e) => {
    let data = {
      receiptPage: `${t("thankyou.receiptHead")}`,
      header_color: design_type == 1 ? theme_text_color : "white",
      hotel_head_color: (design_type === 1 && theme_text_color !== "white" && theme_text_color !== "#FFFFFF" && theme_text_color !== "#ffffff") ? 'white' : 'black',
      theme_color: theme_color,
      hotel_logo: hotel_logo,
      hotel_name: hotel_data["name"],
      receipt_title: `${t("thankyou.receiptHead")}`,
      date: `${t("thankyou.date")}`,
      date_value: date,
      room: `${t("thankyou.room")}`,
      room_value: room,
      bill_to: `${t("thankyou.billedTo")}`,
      card_image: cardImage,
      card_value: card,
      last_pin: lastATMPin,
      expiry: expiry,
      time: time,
      location: `${t("thankyou.location")}`,
      location_value: hotel_data["name"],
      payee: `${t("thankyou.payee")}`,
      payee_value: hotel_data["tippingName"],
      total: `${t("thankyou.total")}`,
      total_value: tipValue,
      thank: `${t("thankyou.thankyou")}`,
      tAndc1: `${t("tipPage.poweredBy")}`,
      tAndc2: `${t("tipPage.useTova")}`,
    }
    let page = handleEmailTemplate(data);
    if (isEmail(mail) == "") {
      const req: IReceiptInterface = {
        mail: mail,
        content: page,
      };
      sendReceiptMail(req).then((res: any) => {
        if (res["status"] !== 200) {

          Store.addNotification({
            title: `${t("messages.appName")}`,
            content: (
              <div className="">
                <div className="defaultNotificationMobile">
                  <div>
                    <img
                      src={ failedIcon}
                      alt="icon"
                    />
                  </div>
                  <div className="w-100">
                    <div className="space-between ">
                      <h1>{t("messages.appName")}</h1>
                      <IconButton>
                        <img src={closeIcon} alt="" />
                      </IconButton>
                    </div>
                    <div>
                      <p>{t("thankyou.sendFailed")}</p>
                    </div>
                  </div>
                </div>
              </div>
            ),
            type: "default",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: notification_shown_time_failed,
              // onScreen: true,
              pauseOnHover: true,
            },
          });

        } else {
          Store.addNotification({
            title: `${t("messages.appName")}`,
            content: (
              <div className="">
                <div className=" defaultNotificationMobile">
                  <div>
                    <img
                      src={ successIcon}
                      alt="icon"
                    />
                  </div>
                  <div className="w-100">
                    <div className="space-between ">
                      <h1>{t("messages.appName")}</h1>
                      <IconButton>
                        <img src={closeIcon} alt="" />
                      </IconButton>
                    </div>
                    <div>
                      <p>{t("thankyou.sendSuccessful")}</p>
                    </div>
                  </div>
                </div>
              </div>
            ),
            type: "default",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              // duration: 20000,
              duration: notification_shown_time_failed,
              // onScreen: true,
              pauseOnHover: true,
            },
          });
        }
      });

      setOpen(false);

    } else {

      Store.addNotification({
        title: `${t("messages.appName")}`,
        content: (
          <div className="">
            <div className="defaultNotificationMobile">
              <div>
                <img
                  src={ failedIcon}
                  alt="icon"
                />
              </div>
              <div className="w-100">
                <div className="space-between ">
                  <h1>{t("messages.appName")}</h1>
                  <IconButton>
                    <img src={closeIcon} alt="" />
                  </IconButton>
                </div>
                <div>
                  <p className="pe-4">{t("thankyou.invalidEmail")}</p>
                </div>
              </div>
            </div>
          </div>
        ),
        type: "default",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          // duration: 2000000,
          duration: notification_shown_time_failed,
          // onScreen: true,
          pauseOnHover: true,
        },
      });
    }



  }



  return (
    <>
      <div className="max-width-400 div-center-margin-auto ">
        <div className="bg-white">
          <div id="main">
            <div>
              <div
                className="header-tip"
                style={{
                  backgroundColor: design_type == 1 ? theme_text_color : "white",
                }}
              >



                <div className={`${design_type !== 1 && "mt-4 "}`} >
                  {hotel_logo === "null" || hotel_logo.length == 0 ? (
                    <div className={`hotel_name mt-3px ${design_type == 3 && "mb-3 mt-2"}`} style={{ color: (design_type === 1 && theme_text_color !== "white" && theme_text_color !== "#FFFFFF" && theme_text_color !== "#ffffff") ? 'white' : 'black' }}>
                      {hotel_data["name"]}
                    </div>
                  ) : (
                    <img
                      className="tipLogo"
                      src={hotel_logo}
                      alt={hotel_data["name"]}
                    />
                  )}
                </div>

              </div>
            </div>
          </div>




          <div><h1 className={`receipt-head mb-4 ps-3 mt-2`} style={{
            color: theme_color,
          }}>{t("thankyou.receiptHead")}</h1></div>

          <div className="px-3"><label htmlFor="" className="receiptLabel ">{t("thankyou.date")}</label> &nbsp;<span className="receiptText">{date}</span></div>
          <div className="px-3"><label htmlFor="" className="receiptLabel ">{t("thankyou.room")}</label> &nbsp;<span className="receiptText">{room}</span></div>
          <div className="px-3">
            <label htmlFor="" className="receiptLabel mb-20px">{t("thankyou.billedTo")}</label>
            <div className="cardSection">
              <div style={{ width: '36px', height: "33px", objectFit: "contain", marginRight: "19px" }}>
                <img src={cardImage} alt="" style={{ width: '100%', height: '100%' }} />
              </div>
              <div className="">
                <div className="receiptBold mb-2px">{card} <span className="">&nbsp;&#183;&#183;&#183;&#183;{lastATMPin}</span></div>
                <div className="space-between"><span className="receiptSpan ">{expiry}</span> <span className="receiptSpan ">{time}</span>
                </div>
              </div>
            </div>
          </div>

          <hr style={{ margin: "20px 16px 29px", color: "rgb(204 199 199)" }} />

          <div className="px-3 flex">
            <label htmlFor="" className="receiptLabel mb-10px  mr-114px">{t("thankyou.location")}</label>
            <div className="receiptText max-width-160px">{hotel_data["name"]}</div>
          </div>
          <div className="px-3 flex">
            <label htmlFor="" className="receiptLabel mb-10px  mr-137px">{t("thankyou.payee")}</label>
            <div className="receiptText max-width-160px">{hotel_data["tippingName"]} </div>
          </div>
          <div className="px-3 flex">
            <label htmlFor="" className="receiptTotal mb-10px mr-130px">{t("thankyou.total")}</label>
            <div className="receiptBold max-width-160px">{t("currency")}{tipValue} </div>
          </div>



          <hr style={{ margin: "21px 16px 9px", color: "rgb(204 199 199)" }} />

          <div className="content_container pt-4 tipbg">
            <div><h1 className="receipt-head" style={{
              color: theme_color,
            }}>{t("thankyou.thankyou")}</h1></div>
            <button
              className="btntipContinue mt-4"
              style={{
                backgroundColor: theme_color,
                color: design_type === 3 ? theme_text_color : "white",
                fontStyle: 'italic',
              }}
              onClick={() => {
                setOpen(true)
                setMail("");
              }}
            >
              {t("thankyou.emailReceipt")}
            </button>

            <div className="my-4">
              <p className="fontz">{t("tipPage.poweredBy")} {t("tipPage.useTova")}</p>
            </div>
          </div>
        </div>

        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div >
              <div className="space-between mb-3"><div></div> <h2 className="receiptHeading">{t("thankyou.enterYourEmail")}</h2> <span onClick={() => setOpen(false)} className="pointer"><img src={iconClose} alt="" /></span></div>
              <input type="email" required={true} className="receiptInput" value={mail} onChange={(e) => setMail(e.target.value)} placeholder={`${t("thankyou.enterEmail")}`} />
              <button
                className="btntipContinue mt-3"
                style={{
                  backgroundColor: theme_color,
                  color: design_type === 3 ? theme_text_color : "white",
                  fontStyle: 'italic',
                }}
                type="button"
                onClick={(e) => onHandleSend(e)}
              >
                {t("thankyou.sendReceipt")}
              </button>

            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default ReceiptPage;
