import React, { useState, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { useAppDispatch, useAppSelector } from "../../helpers/hook";

import FilterPayee from "../FilterPayee";
import { Textfield } from "../TextField";
import { SelectField } from "../SelectField";
import { ButtonPrimary } from "../ButtonGroups/ButtonPrimary";

import plus from "../../assets/images/plus.svg";
import searchIcon from "../../assets/images/searchicon.svg";

import { filterDateValues } from "../../utilities/dateFilterCalculation";

import { IStripePayMethod } from "../../models/tipsInterfaces";
import { IAccGroupListReq } from "../../models/groupInterfaces";
import { IAccountGroupsFilterList, IAllAccountList } from "../../models/allAccountListInterface";
import { IGroupIdNames, IStaffIdNames } from "../../models/dashboardInterfaces";

import { filterDigitsDotOnly } from "../../config/common_functions";
import {
  PAYMENTS_FILTER_STATUS_ALL,
  PAY_STATUS_CODE_SUCCESS,
  PAY_STATUS_CODE_PENDING,
  PAY_STATUS_CODE_READY,
  PAY_STATUS_CODE_FAILED,
  PAY_STATUS_CODE_ONHOLD,
  STAFF_STATUS_ACTIVE,
  STAFF_STATUS_INACTIVE,
  STAFF_STATUS_INVITE,
  PAGENAME_ACCOUNTS,
  PAGENAME_TIPS,
  PAGENAME_COMMISSIONS,
  PAGENAME_WITHHOLDINGS,
  PAGENAME_ADMINUSERS,
  PAGENAME_ACCOUNTUSERS,
  PAGENAME_STAFF,
  PAGENAME_PAYOUTS,
  DATEFILTER_TODAY,
  DATEFILTER_THISWEEK,
  DATEFILTER_THISMONTH,
  DATEFILTER_THISYEAR,
  DATEFILTER_ALLDATES,
  AMOUNTFILTER_DEFAULT_FROM_VALUE,
  AMOUNTFILTER_DEFAULT_TO_VALUE,
  PAGENAME_DASHBOARD
} from "../../config/env";
import {
  USER_ROLE_ADMIN_SUPERADMIN,
  USER_ROLE_ADMIN_ADMIN,
  USER_ROLE_ACCOUNT_MANAGER,
  USER_ROLE_ACCOUNT_USER,
} from "../../config/env";

import { setNotification } from "../../services/apiService";
import { fetchAllAccountList } from "../../services/headerService";
import { fetchAccountGroupsList } from "../../services/poolServices";
import { fetchPayeeByNameAccId } from "../../services/groupServices";
import { getAdminUserRole, getFilterAccountId } from "../../services/localStorage";

import { selectGroupDetailsSlice } from "../../pages/GroupSettings/groupDetailsSlice";
import { FilterAllAccountListSlice } from "../FilterAccount/FilterAllAccountListSlice";

import { IGroupStaffIdNames } from "../../models/groupInterfaces";
import { IAccListAllReq } from "../../models/accountsInterfaces";

interface IProps {
  headind?: string;
  filterAccount?: boolean;
  filterGroup?: boolean;
  filterTips?: boolean;
  filterRole?: boolean;
  filterPayee?: boolean;
  filterPayMethod?: boolean;
  filterAmount?: boolean;
  filterStatus?: boolean;
  filterPayStatus?: boolean; // FROM PAYMENTS PAGE
  filterDate?: boolean;
  parentPage?: string;

  buttonLabel?: string;
  addButton?: boolean;
  headerButtonFunction?: (modal: boolean) => void;

  setDateFilterDatesType?: (fromDate: string, toDate: string, dateType: string) => void;
  setStatusFilter?: (status: string) => void;

  clearFilters?: () => void;
  setTextSearchFilter?: (status: string) => void;
  onSetRole?: (value: number) => void;
  setTipsFilterDates?: (
    fromDate: string,
    toDate: string,
    filterValue: string
  ) => void;
  setSelectedAccountIds?: (accoutIds: string) => void;
  setGroupIdsFilter?: (groupIds: string) => void;
  setGroupStaffIdsFilter?: (accountId: string, isAllSelected: boolean, groupIds: string, staffIds: string, groupIdNames: IGroupIdNames[], staffIdNames: IStaffIdNames[]) => void;
  stripePayMethods?: IStripePayMethod[];
  setPaymentMethodFilter?: (payMethod: string) => void;
  setPayeeIds?: (payeeIds: string) => void;
  setPayeeNames?: (payeeNames: string) => void;
  setPayeeTypes?: (payeeTypes: string) => void;
  setAmountFilterFromTo?: (fromAmount: string, toAmount: string) => void;
  searchInput?: boolean;
  searchInputPlaceholder?: string;
  resetDateFilter?: { filterOption: string; calledfor: string };
  resetStatusFilterToAll?: number;
  needDateAllOption?: boolean;

  //"accept..." VARIABLES USED TO SET FILTERS WHEN "RETURN TO" LINK CLICKED FROM ACCOUNT DETAILS AND STAFF DETAILS PAGES OR ON PAGE REFRESH OF ANY PAGE WITH FILTERS 
  acceptSearchText?: string;            // FROM ACCOUNT LIST AND STAFF LIST PAGES
  acceptDateFilterData?: string;        // FROM ACCOUNT, STAFF, TIPS, COMMISSION, PAYMENTS,ACC USERS PAGES
  acceptStatusFilterData?: string;      // FROM ACCOUNT LIST, ADMIN AND ACCOUNT USERS PAGES 
  acceptAccountFilterIds?: string;      // FROM TIPS, COMMISSION, PAYMENTS, USERLIST, STAFF LIST PAGES
  acceptMultiSelectStatusIds?: string;  // FROM STAFF LIST PAGES 
  acceptTipsType?: string;              // FROM STAFF LIST PAGES 
  acceptPayMethodFilterId?: string;     // FROM TIPS, COMMISSION, PAYMENTS PAGES 
  acceptPayeeFilterData?: string;       // FROM TIPS LIST PAGE 
  acceptGroupFilterData?: string;       // FROM TIPS, COMMISSION, PAYMENTS PAGES 
  acceptAmountFilterData?: string;      // FROM TIPS, COMMISSION, PAYMENTS PAGES amtFrom:~~:amtTo
  acceptRoleFilterData?: number;        // FROM ADMIN AND ACCOUNT USERS PAGES 
  acceptAccountIdFromDashboard?: string;// FROM DASHBOARD ONLY 
}

const FilterSection: React.FC<IProps> = (props) => {
  let localStorageIds = getFilterAccountId();

  const adminUserRole = getAdminUserRole();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // ************** SET VARIABLES FOR THISMONTH, TODAY AND ALLDATES - START **************
  // TO SET 'THISMONTH' AS THE DEFAULT DATE-FILTER SELECTION 
  const arrDateThisMonthDefaultPages = [PAGENAME_PAYOUTS];

  // TO SET 'TODAY' AS DEFAULT DATE-FILTER SELECTION 
  const arrDateTodayDefaultPages = [PAGENAME_TIPS];

  // TO SET ALL AS DEFAULT DATE-FILTER SELECTION 
  const arrDateAllDefaultPages = [PAGENAME_ACCOUNTS, PAGENAME_COMMISSIONS, PAGENAME_STAFF, PAGENAME_WITHHOLDINGS, PAGENAME_ACCOUNTUSERS];

  const defaultFilterDateRangeType = (props.parentPage && arrDateTodayDefaultPages.includes(props.parentPage)) ? DATEFILTER_TODAY :
    (props.parentPage && arrDateThisMonthDefaultPages.includes(props.parentPage)) ? DATEFILTER_THISMONTH :
      (props.parentPage && arrDateAllDefaultPages.includes(props.parentPage)) ? DATEFILTER_ALLDATES : "";

  let defaultFromDate = "";
  let defaultToDate = "";

  if (defaultFilterDateRangeType.length > 0) {
    let dateObj = filterDateValues(defaultFilterDateRangeType);
    let fromMM = dateObj.fromDate.getMonth() + 1 < 10 ? "0" + (dateObj.fromDate.getMonth() + 1).toString()
      : (dateObj.fromDate.getMonth() + 1).toString();
    let fromDD = dateObj.fromDate.getDate() < 10 ? "0" + dateObj.fromDate.getDate().toString()
      : dateObj.fromDate.getDate().toString();
    let fromYY = dateObj.fromDate.getFullYear().toString();

    let toMM = dateObj.toDate.getMonth() + 1 < 10 ? "0" + (dateObj.toDate.getMonth() + 1).toString()
      : (dateObj.toDate.getMonth() + 1).toString();
    let toDD = dateObj.toDate.getDate() < 10 ? "0" + dateObj.toDate.getDate().toString()
      : dateObj.toDate.getDate().toString();
    let toYY = dateObj.toDate.getFullYear().toString();

    defaultFromDate = fromYY + "-" + fromMM + "-" + fromDD;
    defaultToDate = toYY + "-" + toMM + "-" + toDD;
  }
  // ************** SET VARIABLES FOR THISMONTH, TODAY AND ALLDATES - END ***************

  const [selectedAccounts, setSelectedAccounts] = useState("");
  const [selectedAccountNames, setSelectedAccountNames] = useState("");
  const [resetFilterAccount, setResetFilterAccount] = useState(false);
  const [filterAccountsList, setFilterAccountsList] = useState<IAllAccountList[]>([]);
  const [accSearch, setAccSearch] = useState("");

  const [filterFromDate, setFilterFromDate] = useState("");
  const [filterToDate, setFilterToDate] = useState("");
  const [filterDateRangeType, setFilterDateRangeType] = useState(defaultFilterDateRangeType);

  const [filterTipsFromDate, setFilterTipsFromDate] = useState("");
  const [filterTipsToDate, setFilterTipsToDate] = useState("");
  const [filterTipsDateRangeType, setFilterTipsDateRangeType] = useState("all");

  const [extraSpace, setExtraSpace] = useState(false);

  const [filterStatus, setFilterStatus] = useState("");

  const [acceptPayeeFilterData, setAcceptPayeeFilterData] = useState("");

  const payStatusInitialValues = [
    {
      name: t("statusFilter.success"),
      value: PAY_STATUS_CODE_SUCCESS.toString(),
      isChecked: true,
    },
    {
      name: t("statusFilter.ready"),
      value: PAY_STATUS_CODE_READY.toString(),
      isChecked: true,
    },
    {
      name: t("statusFilter.onhold"),
      value: PAY_STATUS_CODE_ONHOLD.toString(),
      isChecked: true,
    },
    {
      name: t("statusFilter.pending"),
      value: PAY_STATUS_CODE_PENDING.toString(),
      isChecked: true,
    },
    {
      name: t("statusFilter.failed"),
      value: PAY_STATUS_CODE_FAILED.toString(),
      isChecked: true,
    }
  ];

  const staffStatusInitialValues = [
    {
      name: t("staffStatus.active"),
      value: STAFF_STATUS_ACTIVE.toString(),
      isChecked: true,
    },
    {
      name: t("staffStatus.inactive"),
      value: STAFF_STATUS_INACTIVE.toString(),
      isChecked: true,
    },
    {
      name: t("staffStatus.invited"),
      value: STAFF_STATUS_INVITE.toString(),
      isChecked: true,
    }
  ];

  const [filterPaymentStatus, setFilterPaymentStatus] = useState(props.parentPage == PAGENAME_STAFF ? staffStatusInitialValues : payStatusInitialValues);

  const [filterPayMethod, setFilterPayMethod] = useState("");
  const [filterPayMethodName, setFilterPayMethodName] = useState("");

  const [accountFilterApplied, setAccountFilterApplied] = useState(false);
  const [dateFilterApplied, setDateFilterApplied] = useState(false);
  const [statusFilterApplied, setStatusFilterApplied] = useState(false);
  const [paymentStatusFilterApplied, setpaymentStatusFilterApplied] = useState(false);
  const [tipsDateFilterApplied, setTipsDateFilterApplied] = useState(false);
  const [payMethodFilterApplied, setPayMethodFilterApplied] = useState(false);
  const [payeeFilterApplied, setPayeeFilterApplied] = useState(false);
  const [amountFilterApplied, setAmountFilterApplied] = useState(false);
  const [groupFilterApplied, setGroupFilterApplied] = useState(false);

  const [filterRole, setFilterRole] = useState(0);
  const [filterRoleApplied, setFilterRoleApplied] = useState(false);

  const [showClearFilters, setShowClearFilters] = useState(false);
  const [filterTextSearch, setFilterTextSearch] = useState<string>("");

  const [textSearchFilterApplied, setTextSearchFilterApplied] = useState(false);

  const [filterAmountFrom, setFilterAmountFrom] = useState<string>(AMOUNTFILTER_DEFAULT_FROM_VALUE.toString());
  const [filterAmountTo, setFilterAmountTo] = useState<string>(AMOUNTFILTER_DEFAULT_TO_VALUE.toString());

  const [showGroupFilter, setShowGroupFilter] = useState(false);
  const [filterAccountGroupsList, setFilterAccountGroupsList] = useState<IAccountGroupsFilterList[]>([]);
  const [filterGroupIds, setFilterGroupIds] = useState("");
  const [filterGroupNames, setFilterGroupNames] = useState("");
  const [filterStaffIds, setFilterStaffIds] = useState("");

  const [initialAccIdCall, setInitialAccIdCall] = useState(false);

  let dummyVar = "";

  const { allAccountList, accountGroupsList, accountGroupsLoading } =
    useAppSelector(FilterAllAccountListSlice);

  const { payeeList } = useAppSelector(selectGroupDetailsSlice);
  const [accountStaffList, setAccountStaffList] = useState<IGroupStaffIdNames[]>([]);

  useEffect(() => {
    if (localStorageIds && initialAccIdCall) {
      props?.setSelectedAccountIds && props.setSelectedAccountIds(localStorageIds);
      let accList: IAllAccountList[] = [];
      allAccountList.map((accItem) => {
        if (accItem.value == localStorageIds) {
          accList.push({ ...accItem, isChecked: true });
          setSelectedAccounts(accItem.value.toString());
          setSelectedAccountNames(accItem.label);
        }
        else {
          accList.push({ ...accItem, isChecked: false });
        }
      });
      setFilterAccountsList(accList);
    }
  }, [localStorageIds]);

  useEffect(() => {
    setFromToDates(defaultFilterDateRangeType, "dateFilter");
    setDateFilterApplied(false);
    setFromToDates("all", "tipsDateFilter");
    setTipsDateFilterApplied(false);
    setFilterStatus("");
    setStatusFilterApplied(false);
    setpaymentStatusFilterApplied(false);
    setFilterTextSearch("");
    setShowClearFilters(false);
    setAmountFilterApplied(false);
    setGroupFilterApplied(false);
    let apiData: IAccListAllReq = {
      searchString: "",
      userRole: Number(adminUserRole)
    }
    dispatch(fetchAllAccountList(apiData));
  }, []);

  useEffect(() => {
    if (props.acceptAccountFilterIds) {
      setInitialAccIdCall(false);
      let accArr = props.acceptAccountFilterIds.split(',');
      let accList: IAllAccountList[] = [];
      let selAccIds = "", sepValue = "";
      allAccountList.map((accItem) => {
        if (accArr.includes(accItem.value)) {
          accList.push({ ...accItem, isChecked: true });
          selAccIds += sepValue + (accItem.value).toString();
          sepValue = ",";
        }
        else {
          accList.push({ ...accItem, isChecked: false });
        }
      });

      setSelectedAccounts(selAccIds);
      setFilterAccountsList(accList);

      // IF ALL OPTIONS ARE CHECKED 
      if (selAccIds != localStorageIds) {
        setShowClearFilters(true);
        setAccountFilterApplied(true);
      }
    }
    else if (filterAccountsList.length == 0) {
      let accList: IAllAccountList[] = [];
      // TO HANDLE THE SELECTED ACCOUNTS ON - RETURN TO STAFF LIST FROM STAFF DETAILS PAGE 
      if (props.acceptAccountFilterIds && props.acceptAccountFilterIds.length > 0) {
        let accArr = props.acceptAccountFilterIds.split(',');
        allAccountList.map((accItem) => {
          if (accArr.includes(accItem.value)) {
            accList.push({ ...accItem, isChecked: true });
          }
          else {
            accList.push(accItem);
          }
        });
        setFilterAccountsList(accList);
      }
      else {
        allAccountList.map((accItem) => {
          if (accItem.value == localStorageIds) {
            accList.push({ ...accItem, isChecked: true });
            console.log('useEffect allAccountList 33 :', accItem.value);
            setSelectedAccounts(accItem.value.toString());
            setSelectedAccountNames(accItem.label);
          }
          else {
            accList.push(accItem);
          }
        });
        setFilterAccountsList(accList);
      }

    }
  }, [allAccountList]);

  // TO RELOAD THE NAVBAR WHEN ACC-FILTER CHANGES FOR GROUP DROPDOWN ITEMS
  useEffect(() => {
    if (accountGroupsLoading && accountGroupsLoading == "succeeded") {
      if (accountGroupsList && accountGroupsList.length > 0 && props.acceptGroupFilterData && props.acceptGroupFilterData.length > 0) {
        let groupList: IAccountGroupsFilterList[] = [];
        let acceptedGrpIdsArr = props.acceptGroupFilterData.split(',');
        let selGrpIds = "", selGrpNames = "", sepValue = "";
        let grpCount = 0;
        accountGroupsList.map(gItem => {
          if (acceptedGrpIdsArr.includes(gItem.id.toString())) {
            groupList.push({ ...gItem, isChecked: true });
            selGrpIds += sepValue + gItem.id.toString();
            sepValue = ",";
            if (selGrpNames == "") {
              selGrpNames = gItem.name;
            }
            grpCount++;
          }
          else {
            groupList.push({ ...gItem, isChecked: false });
          }
        })
        setFilterGroupIds(selGrpIds);
        setFilterGroupNames(grpCount > 1 ? selGrpNames + " (+" + (grpCount - 1).toString() + ")" : selGrpNames);
        setFilterAccountGroupsList(groupList);
        setGroupFilterApplied(true);
      }
      else {
        let groupList = accountGroupsList.map((item) => {
          return { ...item, isChecked: true };
        });
        setFilterAccountGroupsList(groupList);
      }
    }
  }, [accountGroupsLoading]);

  useEffect(() => {
    if (
      props.parentPage == PAGENAME_STAFF ||
      props.parentPage == PAGENAME_PAYOUTS ||
      props.parentPage == PAGENAME_TIPS ||
      props.parentPage == PAGENAME_COMMISSIONS ||
      props.parentPage == PAGENAME_WITHHOLDINGS ||
      props.parentPage == PAGENAME_ADMINUSERS ||
      props.parentPage == PAGENAME_ACCOUNTUSERS
    ) {
      if (selectedAccounts.length > 0) {
        let tmpArr = selectedAccounts.split(",");
        if (tmpArr && tmpArr.length > 1) {
          // IF WE CALL "setGroupIdsFilter" WITH ANY VALUE OR BLANK, IT WILL RELOAD THE PARENT PAGE, 
          // HERE WE JUST NEED TO CLEAR THE GROUP IDS SELECTED PREV. SO PASS "clearOnly" TO PARENT, 
          // THIS WILL PREVENT THE PARENT PAGE TO RELOAD AND CALL APIs 
          props?.setGroupIdsFilter ? props.setGroupIdsFilter("clearOnly") : (dummyVar = " ");
          setShowGroupFilter(false);
          setFilterAccountGroupsList([]);
          setFilterGroupIds("");
          setFilterGroupNames("");
        }
        if (!initialAccIdCall) {
          setInitialAccIdCall(true);
          props?.setSelectedAccountIds && props.setSelectedAccountIds(selectedAccounts);
        }

        //WHEN NAVIGATION ACC-FILTER CHANGES , TO SET UP CLEAR FILTER NOT SHOWING
        if (selectedAccounts !== localStorageIds) {
          setShowClearFilters(true);
          setResetFilterAccount(true);
        } else if (selectedAccounts === localStorageIds) {
          setShowClearFilters(false);
          setResetFilterAccount(false);
        }
      }
    }

    // FOR GROUP FILTER IN TIPS, COMMISSION AND PAYOUT PAGES 
    if ((
      props.parentPage == PAGENAME_DASHBOARD ||
      props.parentPage == PAGENAME_PAYOUTS ||
      props.parentPage == PAGENAME_TIPS ||
      props.parentPage == PAGENAME_COMMISSIONS) &&
      selectedAccounts &&
      selectedAccounts.length > 0) {
      let tmpArr = selectedAccounts.split(",");
      if (tmpArr && tmpArr.length == 1) {
        setShowGroupFilter(true);
        const req: IAccGroupListReq = {
          accountId: selectedAccounts,
          userRole: Number(adminUserRole),
        };
        dispatch(fetchAccountGroupsList(req));

        if (props.parentPage == PAGENAME_DASHBOARD) {
          setAccountStaffList([]);
          dispatch(
            fetchPayeeByNameAccId({
              account_id: selectedAccounts.toString(),
              name: "",
            })
          );
        }
      }
      else {
        setShowGroupFilter(false);
        setFilterAccountGroupsList([]);
        setFilterGroupIds("");
        setFilterGroupNames("");
      }
    }
    else {
      setShowGroupFilter(false);
      setFilterAccountGroupsList([]);
      setFilterGroupIds("");
      setFilterGroupNames("");
    }
  }, [selectedAccounts]);

  // USED TO REMOVE ACCOUNT NAME ROW WITH id 0 
  useEffect(() => {
    let newPayeeList = payeeList.filter(item => (item.id && item.id > 0));
    if (newPayeeList.length == 0) {
      setAccountStaffList([]);
    }
    else {
      let arrNew = [];
      newPayeeList.map((item, index) => {
        arrNew.push({
          staffId: item.id,
          staffName: item.fullName,
          isChecked: true
        })
      })
      setAccountStaffList(arrNew);
    }
  }, [payeeList]);

  // USED TO RELOAD PREVIOUS STATUS WHEN "RETURN TO ACOUNTS LIST" CLICKED FROM ACCOUNT DETAILS
  useEffect(() => {
    if (props.acceptAccountIdFromDashboard) {
      let accList: IAllAccountList[] = [];
      allAccountList.map((accItem) => {
        if (Number(accItem.value) == Number(props.acceptAccountIdFromDashboard)) {
          accList.push({ ...accItem, isChecked: true });
          setSelectedAccounts(accItem.value.toString());
          setSelectedAccountNames(accItem.label);
        }
        else {
          accList.push({ ...accItem, isChecked: false });
        }
      });
      setFilterAccountsList(accList);
    }
  }, [props.acceptAccountIdFromDashboard]);

  // USED TO RELOAD PREVIOUS STATUS WHEN "RETURN TO ACOUNTS LIST" CLICKED FROM ACCOUNT DETAILS
  useEffect(() => {
    if (props.resetStatusFilterToAll) {
      setFilterStatus("");
    }
  }, [props.resetStatusFilterToAll]);

  // 'props.accept.....' PROPS ARE USED TO SET FILTERS TO PREVIOUS STATUS ON PAGE REFRESH 
  useEffect(() => {
    if (filterAccountGroupsList && filterAccountGroupsList.length > 0 && props.acceptGroupFilterData && props.acceptGroupFilterData.length > 0) {
      let groupList: IAccountGroupsFilterList[] = [];
      let acceptedGrpIdsArr = props.acceptGroupFilterData.split(',');
      let selGrpIds = "", selGrpNames = "", sepValue = "";
      let grpCount = 0;
      filterAccountGroupsList.map(gItem => {
        if (acceptedGrpIdsArr.includes(gItem.id.toString())) {
          groupList.push({ ...gItem, isChecked: true });
          selGrpIds += sepValue + gItem.id.toString();
          sepValue = ",";
          if (selGrpNames == "") {
            selGrpNames = gItem.name;
          }
          grpCount++;
        }
        else {
          groupList.push({ ...gItem, isChecked: false });
        }
      })
      setFilterGroupIds(selGrpIds);
      setFilterGroupNames(grpCount > 1 ? selGrpNames + " (+" + (grpCount - 1).toString() + ")" : selGrpNames);
      setFilterAccountGroupsList(groupList);
      setGroupFilterApplied(true);
    }
  }, [props.acceptGroupFilterData]);

  // 'props.accept.....' PROPS ARE USED TO SET FILTERS TO PREVIOUS STATUS ON PAGE REFRESH 
  useEffect(() => {
    if (props.acceptDateFilterData) {
      setFromToDates(props.acceptDateFilterData, "dateFilter");
      if (props.acceptDateFilterData == DATEFILTER_THISMONTH && props.parentPage && arrDateThisMonthDefaultPages.includes(props.parentPage)) {
        setDateFilterApplied(false);
      }
      else if (props.acceptDateFilterData == DATEFILTER_TODAY && props.parentPage && arrDateTodayDefaultPages.includes(props.parentPage)) {
        setDateFilterApplied(false);
      }
      else {
        setDateFilterApplied(true);
      }
    }
  }, [props.acceptDateFilterData]);

  // 'props.accept.....' PROPS ARE USED TO SET FILTERS TO PREVIOUS STATUS ON PAGE REFRESH 
  useEffect(() => {
    if (props.acceptMultiSelectStatusIds && props.acceptMultiSelectStatusIds.length > 0) {
      let statArr = props.acceptMultiSelectStatusIds.split(',');
      let selectedStatus = filterPaymentStatus.map((statusItem) =>
        statArr.includes(statusItem.value)
          ? { ...statusItem, isChecked: true }
          : { ...statusItem, isChecked: false }
      );
      setFilterPaymentStatus(selectedStatus);
      if (selectedStatus.every((status) => status.isChecked)) {
        setpaymentStatusFilterApplied(false);
      }
      else {
        setpaymentStatusFilterApplied(true);
        setShowClearFilters(true);
      }
    }
  }, [props.acceptMultiSelectStatusIds]);

  // 'props.accept.....' PROPS ARE USED TO SET FILTERS TO PREVIOUS STATUS ON PAGE REFRESH 
  useEffect(() => {
    console.log('useEffect props.acceptTipsType : ', props.acceptTipsType);
    if (props.acceptTipsType && props.acceptTipsType.length > 0 && props.acceptTipsType != filterTipsDateRangeType) {
      console.log('useEffect props.acceptTipsType IN : ', filterTipsDateRangeType);
      setFromToDates(props.acceptTipsType, "tipsFilter");
      setFilterTipsDateRangeType(props.acceptTipsType);
      setTipsDateFilterApplied(true);
      setShowClearFilters(true);
    }
  }, [props.acceptTipsType]);

  useEffect(() => {
    if (props.acceptSearchText && props.acceptSearchText != filterTextSearch) {
      setFilterTextSearch(props.acceptSearchText);
      if (props.acceptSearchText && props.acceptSearchText.length > 0) {
        setTextSearchFilterApplied(true);
      }
      else {
        setTextSearchFilterApplied(false);
      }
    }
  }, [props.acceptSearchText]);

  // 'props.accept.....' PROPS ARE USED TO SET FILTERS TO PREVIOUS STATUS ON PAGE REFRESH 
  useEffect(() => {
    if (props.acceptPayMethodFilterId && props.acceptPayMethodFilterId.length > 0 && props?.stripePayMethods &&
      props.stripePayMethods.length > 0) {
      props.stripePayMethods.map((pmItem) => {
        if (Number(props.acceptPayMethodFilterId) == Number(pmItem.id)) {
          setFilterPayMethod(pmItem.id.toString());
          setFilterPayMethodName(pmItem.paymentMethod);
          setPayMethodFilterApplied(true);
        }
      })
    }
    else if (!props.acceptPayMethodFilterId || props.acceptPayMethodFilterId == "" || props.acceptPayMethodFilterId.length == 0) {
      setFilterPayMethod("");
      setFilterPayMethodName("");
      setPayMethodFilterApplied(false);
    }
  }, [props.acceptPayMethodFilterId, props.stripePayMethods]);

  // 'props.accept.....' PROPS ARE USED TO SET FILTERS TO PREVIOUS STATUS ON PAGE REFRESH 
  useEffect(() => {
    if (props.acceptPayeeFilterData && props.acceptPayeeFilterData.length > 0) {
      setAcceptPayeeFilterData(props.acceptPayeeFilterData);
    }
  }, [props.acceptPayeeFilterData]);

  useEffect(() => {
    if (props.acceptAmountFilterData && props.acceptAmountFilterData.length > 1) {
      let arrAmountDet = props.acceptAmountFilterData?.split(':~~:');
      if (arrAmountDet && arrAmountDet.length == 2) {
        setFilterAmountFrom(arrAmountDet[0]);
        setFilterAmountTo(arrAmountDet[1]);
        if (Number(arrAmountDet[0]) == AMOUNTFILTER_DEFAULT_FROM_VALUE && Number(arrAmountDet[1]) == AMOUNTFILTER_DEFAULT_TO_VALUE) {
          setAmountFilterApplied(false);
        }
        else {
          setAmountFilterApplied(true);
        }
      }
    }
  }, [props.acceptAmountFilterData]);

  useEffect(() => {
    console.log("props.acceptRoleFilterData: ", props.acceptRoleFilterData)
    if (props.acceptRoleFilterData && props.acceptRoleFilterData > 0) {
      setFilterRole(Number(props.acceptRoleFilterData));
      setFilterRoleApplied(true);
    }
  }, [props.acceptRoleFilterData]);


  useEffect(() => {
    if (props.parentPage == PAGENAME_ACCOUNTS) {
      if (
        !dateFilterApplied &&
        !statusFilterApplied &&
        filterTextSearch == ""
      ) {
        setShowClearFilters(false);
      }
    } else if (props.parentPage == PAGENAME_STAFF) {
      if (
        !dateFilterApplied &&
        !paymentStatusFilterApplied &&
        !tipsDateFilterApplied &&
        filterTextSearch == ""
      ) {
        setShowClearFilters(false);
      }
    } else if (props.parentPage == PAGENAME_COMMISSIONS) {
      if (!dateFilterApplied && !paymentStatusFilterApplied && !groupFilterApplied) {
        setShowClearFilters(false);
      }
    } else if (
      props.parentPage == PAGENAME_PAYOUTS ||
      props.parentPage == PAGENAME_TIPS
    ) {
      if (
        !groupFilterApplied &&
        !dateFilterApplied &&
        !paymentStatusFilterApplied &&
        !payMethodFilterApplied &&
        !payeeFilterApplied &&
        filterTextSearch == ""
      ) {
        setShowClearFilters(false);
      }
    }
  }, [
    dateFilterApplied,
    statusFilterApplied,
    tipsDateFilterApplied,
    payMethodFilterApplied,
    filterTextSearch,
    paymentStatusFilterApplied,
    payeeFilterApplied,
    groupFilterApplied,
  ]);

  // USED TO SET THE SELECTED ACCOUNT NAME(S) DISPLAYED IN THE FILTER
  useEffect(() => {
    if (filterAccountsList.length > 0) {
      let selectedStatus = filterAccountsList;
      let selAccNames = "", selAccIds = "";
      let accCount = 0;
      if (selectedStatus.every((status) => status.isChecked)) {
        setSelectedAccountNames(filterAccountsList.length == 1 ? selectedStatus[0].label : `${t("accountsFilter.allAccounts")}`);
        setShowGroupFilter(false);
      }
      else {
        selectedStatus.map(accItem => {
          if (accItem.isChecked) {
            if (selAccIds == "") {
              selAccIds = accItem.value;
            }
            else {
              selAccIds += "," + accItem.value;
            }
            if (selAccNames == "") {
              selAccNames = accItem.label;
            }
            accCount++;
          }
        })
        setSelectedAccountNames(accCount > 1 ? selAccNames + " (+" + (accCount - 1).toString() + ")" : selAccNames);
        setShowGroupFilter(accCount == 1 ? true : false);
      }
    }
  }, [filterAccountsList]);

  const setPayeeIds = (payeeIds: string) => {
    if (payeeIds != "") {
      setShowClearFilters(true);
      setPayeeFilterApplied(true);
    }
    else {
      setPayeeFilterApplied(false);
    }
    props?.setPayeeIds && props.setPayeeIds(payeeIds);
  };

  const setPayeeNames = (payeeNames: string) => {
    props?.setPayeeNames && props.setPayeeNames(payeeNames);
  }

  const setPayeeTypes = (payeeTypes: string) => {
    props?.setPayeeTypes && props.setPayeeTypes(payeeTypes);
  }

  const getAppliedFiltersCount = () => {
    let filtersCount = 0;
    if (props.parentPage == PAGENAME_ACCOUNTS) {
      if (dateFilterApplied) {
        filtersCount++;
      }
      if (statusFilterApplied) {
        filtersCount++;
      }
      return filtersCount;
    } else if (props.parentPage == PAGENAME_TIPS) {
      if (groupFilterApplied) {
        filtersCount++;
      }
      if (dateFilterApplied) {
        filtersCount++;
      }
      if (payeeFilterApplied) {
        filtersCount++;
      }
      if (payMethodFilterApplied) {
        filtersCount++;
      }
      if (resetFilterAccount) {
        filtersCount++;
      }
      return filtersCount;
    } else if (props.parentPage == PAGENAME_COMMISSIONS) {
      if (groupFilterApplied) {
        filtersCount++;
      }
      if (dateFilterApplied) {
        filtersCount++;
      }
      if (paymentStatusFilterApplied) {
        filtersCount++;
      }
      if (resetFilterAccount) {
        filtersCount++;
      }
      return filtersCount;
    } else if (props.parentPage == PAGENAME_PAYOUTS) {
      if (groupFilterApplied) {
        filtersCount++;
      }
      if (dateFilterApplied) {
        filtersCount++;
      }
      if (paymentStatusFilterApplied) {
        filtersCount++;
      }
      if (payeeFilterApplied) {
        filtersCount++;
      }
      if (amountFilterApplied) {
        filtersCount++;
      }
      if (resetFilterAccount) {
        filtersCount++;
      }
      return filtersCount;
    } else if (props.parentPage == PAGENAME_WITHHOLDINGS) {
      if (dateFilterApplied) {
        filtersCount++;
      }

      if (paymentStatusFilterApplied) {
        filtersCount++;
      }

      if (payeeFilterApplied) {
        filtersCount++;
      }
      if (resetFilterAccount) {
        filtersCount++;
      }
      return filtersCount;
    } else if (props.parentPage == PAGENAME_ADMINUSERS) {
      if (statusFilterApplied) {
        filtersCount++;
      }
      if (resetFilterAccount) {
        filtersCount++;
      }
      if (filterRoleApplied) {
        filtersCount++;
      }
      return filtersCount;
    } else if (props.parentPage == PAGENAME_ACCOUNTUSERS) {
      if (dateFilterApplied) {
        filtersCount++;
      }
      if (resetFilterAccount) {
        filtersCount++;
      }
      if (statusFilterApplied) {
        filtersCount++;
      }
      if (filterRoleApplied) {
        filtersCount++;
      }
      return filtersCount;
    } else if (props.parentPage == PAGENAME_STAFF) {
      if (dateFilterApplied) {
        filtersCount++;
      }
      if (paymentStatusFilterApplied) {
        filtersCount++;
      }
      if (tipsDateFilterApplied) {
        filtersCount++;
      }
      if (resetFilterAccount) {
        filtersCount++;
      }
      return filtersCount;
    } else {
      return filtersCount;
    }
  };

  const onEnterTextFilter = (calledFrom: string) => {
    if (calledFrom == "clearButton") {
      props?.setTextSearchFilter
        ? props.setTextSearchFilter("")
        : (dummyVar = " ");
      setTextSearchFilterApplied(false);
    } else if (filterTextSearch.length >= 3) {
      setShowClearFilters(true);
      props?.setTextSearchFilter
        ? props.setTextSearchFilter(filterTextSearch)
        : (dummyVar = " ");
      setTextSearchFilterApplied(true);
    }
  };

  const onApplyButton = (calledFrom: string) => {

    if (calledFrom == "accountFilter" && selectedAccounts && selectedAccounts.length > 0) {
      if (props?.parentPage && props.parentPage == PAGENAME_DASHBOARD && selectedAccountNames == t("accountsFilter.allAccounts")) {
        // let allAccountsChecked = filterAccountsList.every((accItem) => accItem.isChecked); 
        props?.setSelectedAccountIds("all");
      }
      else {
        props?.setSelectedAccountIds && props.setSelectedAccountIds(selectedAccounts);
      }

      if (selectedAccounts == localStorageIds) {
        setAccountFilterApplied(false);
      }
      else {
        setAccountFilterApplied(true);
      }
    }
    else if (calledFrom == "chartGroupFilter") { // USED IN DASHBOARD PAGE 
      let accountId = selectedAccounts;
      let isAllSelected = (filterAccountGroupsList.every((gItem) => gItem.isChecked) && accountStaffList.every((sItem) => sItem.isChecked));
      let groupIdNames: IGroupIdNames[] = [];
      let groupIds = "", staffIds = "", sepVar = "";
      filterAccountGroupsList.map((gItem) => {
        if (gItem.isChecked) {
          groupIdNames.push({ groupId: (gItem.id).toString(), groupName: gItem.name });
          groupIds += sepVar + (gItem.id).toString();
          sepVar = ",";
        }
      })
      let staffIdNames: IStaffIdNames[] = [];
      sepVar = "";
      accountStaffList.map((sItem) => {
        if (sItem.isChecked) {
          staffIdNames.push({ staffId: (sItem.staffId).toString(), staffName: sItem.staffName })
          staffIds += sepVar + (sItem.staffId).toString();
          sepVar = ",";
        }
      })
      props?.setGroupStaffIdsFilter ? props.setGroupStaffIdsFilter(accountId, isAllSelected, groupIds, staffIds, groupIdNames, staffIdNames) : (dummyVar = " ");
    }
    else if (calledFrom == "groupFilter" || calledFrom == "groupFilterClear") {
      if (calledFrom == "groupFilterClear") {
        setGroupFilterApplied(false);
        props?.setGroupIdsFilter ? props.setGroupIdsFilter("") : (dummyVar = " ");
      }
      else {
        props?.setGroupIdsFilter ? props.setGroupIdsFilter(filterGroupIds) : (dummyVar = " ");
        if (filterGroupIds.length > 0) {
          setGroupFilterApplied(true);
          setShowClearFilters(true);
        } else {
          setGroupFilterApplied(false);
        }
      }
    } else if (calledFrom == "payMethod") {
      props?.setPaymentMethodFilter
        ? props.setPaymentMethodFilter(filterPayMethod)
        : (dummyVar = " ");
      if (filterPayMethod.length > 0) {
        setPayMethodFilterApplied(true);
        setShowClearFilters(true);
      } else {
        setPayMethodFilterApplied(false);
      }
    } else if (calledFrom == "dateFilter") {
      if (filterFromDate.length > 0 && filterToDate.length > 0) {
        setDateFilterApplied(true);
        props?.setDateFilterDatesType ? props.setDateFilterDatesType(filterFromDate, filterToDate, filterDateRangeType) : (dummyVar = " ");
        if (props.acceptDateFilterData == DATEFILTER_THISMONTH && props.parentPage && !arrDateThisMonthDefaultPages.includes(props.parentPage)) {
          setShowClearFilters(true);
        }
        if (props.acceptDateFilterData == DATEFILTER_TODAY && props.parentPage && !arrDateTodayDefaultPages.includes(props.parentPage)) {
          setShowClearFilters(true);
        }
      }
      else if (filterDateRangeType == DATEFILTER_ALLDATES && filterFromDate.length == 0 && filterToDate.length == 0) {
        setDateFilterApplied(false);
        props?.setDateFilterDatesType ? props.setDateFilterDatesType("", "", DATEFILTER_ALLDATES) : (dummyVar = " ");
      }
    } else if (calledFrom == "statusFilter") {
      if (props?.filterPayStatus) {
        console.log("FROM PAYMENTS/STAFF PAGE");

        // FROM PAYMENTS/STAFF PAGE
        if (filterPaymentStatus.every((status) => status.isChecked)) {
          setpaymentStatusFilterApplied(false);
        } else if (
          filterPaymentStatus.every((status) => status.isChecked == false)
        ) {
        } else if (filterPaymentStatus.length > 0) {
          setpaymentStatusFilterApplied(true);
          setShowClearFilters(true);
        }
        const checkedStatusValues = filterPaymentStatus
          .filter((status) => status.isChecked)
          .map((status) => status.value);

        props?.setStatusFilter
          ? props.setStatusFilter(checkedStatusValues.toString())
          : (dummyVar = " ");
      } else {
        if (filterStatus != "" && filterStatus.length > 0) {
          setStatusFilterApplied(true);
          setShowClearFilters(true);
        } else if (filterStatus == "") {
          setStatusFilterApplied(false);
        }
        props?.setStatusFilter
          ? props.setStatusFilter(filterStatus)
          : (dummyVar = " ");
      }
    } else if (calledFrom == "tipsDateFilter") {
      if (
        filterTipsDateRangeType.length > 0 &&
        filterTipsDateRangeType != "all"
      ) {
        setTipsDateFilterApplied(true);
        setShowClearFilters(true);
      } else if (filterTipsDateRangeType == "all") {
        setTipsDateFilterApplied(false);
      }
      props?.setTipsFilterDates
        ? props.setTipsFilterDates(
          filterTipsFromDate,
          filterTipsToDate,
          filterTipsDateRangeType
        )
        : (dummyVar = " ");
    } else if (calledFrom == "role") {
      if (filterRole != 0) {
        setShowClearFilters(true);
        setFilterRoleApplied(true);
        props.onSetRole && props.onSetRole(filterRole);
      }
      else if (filterRole == 0) {
        setFilterRoleApplied(false);
        props.onSetRole && props.onSetRole(filterRole);
      }
    } else if (calledFrom == "amount") {
      if (
        filterAmountFrom.trim().length > 0 &&
        filterAmountTo.trim().length > 0 &&
        filterAmountTo.trim() != "0" &&
        Number(filterAmountTo) >= Number(filterAmountFrom)
      ) {
        if (Number(filterAmountFrom) == AMOUNTFILTER_DEFAULT_FROM_VALUE && Number(filterAmountTo) == AMOUNTFILTER_DEFAULT_TO_VALUE) {
          setAmountFilterApplied(false);
        }
        else {
          setShowClearFilters(true);
          setAmountFilterApplied(true);
        }
        props.setAmountFilterFromTo && props.setAmountFilterFromTo(filterAmountFrom, filterAmountTo);
      } else if (Number(filterAmountTo) < Number(filterAmountFrom)) {
        setNotification(
          t("projectName"),
          t("messages.filterAmountMismatch"),
          "failed",
          "amountError"
        );

      } else if (
        (filterAmountFrom.trim().length == 0 || filterAmountTo.trim() == AMOUNTFILTER_DEFAULT_FROM_VALUE.toString()) &&
        (filterAmountTo.trim().length == 0 || filterAmountTo.trim() == AMOUNTFILTER_DEFAULT_TO_VALUE.toString())
      ) {
        setAmountFilterApplied(false);
        // props.setAmountFilterFromTo && props.setAmountFilterFromTo(filterAmountFrom, filterAmountTo);
      }
    }

    setTimeout(() => {
      divHeight();
    }, 1650);
  };

  // FOR ' today, thisWeek, thisMonth, thisYear ' OPTIONS IN ACCOUNTS LIST PAGE AND 'all, thisWeek, thisMonth' OPTIONS OF TIPS PAGE
  const setFromToDates = (dateFilterType: string, calledFor: string) => {
    if (calledFor == "tipsFilter" && dateFilterType == "all") {
      setFilterTipsDateRangeType(dateFilterType);
      setFilterTipsFromDate("");
      setFilterTipsToDate("");
      return;
    } else {
      if (dateFilterType == DATEFILTER_ALLDATES) {
        setFilterDateRangeType(dateFilterType);
        setFilterFromDate("");
        setFilterToDate("");
      }
      else {
        let dateObj = filterDateValues(dateFilterType);

        let fromMM =
          dateObj.fromDate.getMonth() + 1 < 10
            ? "0" + (dateObj.fromDate.getMonth() + 1).toString()
            : (dateObj.fromDate.getMonth() + 1).toString();
        let fromDD =
          dateObj.fromDate.getDate() < 10
            ? "0" + dateObj.fromDate.getDate().toString()
            : dateObj.fromDate.getDate().toString();
        let fromYY = dateObj.fromDate.getFullYear().toString();
        if (calledFor == "dateFilter") {
          setFilterDateRangeType(dateFilterType);
          setFilterFromDate(fromYY + "-" + fromMM + "-" + fromDD);
        } else if (calledFor == "tipsFilter") {
          setFilterTipsDateRangeType(dateFilterType);
          setFilterTipsFromDate(fromYY + "-" + fromMM + "-" + fromDD);
        }

        let toMM =
          dateObj.toDate.getMonth() + 1 < 10
            ? "0" + (dateObj.toDate.getMonth() + 1).toString()
            : (dateObj.toDate.getMonth() + 1).toString();
        let toDD =
          dateObj.toDate.getDate() < 10
            ? "0" + dateObj.toDate.getDate().toString()
            : dateObj.toDate.getDate().toString();
        let toYY = dateObj.toDate.getFullYear().toString();
        if (calledFor == "dateFilter") {
          setFilterDateRangeType(dateFilterType);
          setFilterToDate(toYY + "-" + toMM + "-" + toDD);
        } else if (calledFor == "tipsFilter") {
          setFilterTipsDateRangeType(dateFilterType);
          setFilterTipsToDate(toYY + "-" + toMM + "-" + toDD);
        }
      }
    }
  };

  const onSetFilterAccounts = (e, accIdNum: number) => {
    const { name, checked } = e.target;
    let selAccIds = "", selAccNames = "", sepValue = "";
    let accCount = 0;
    if (name === "account_all") {
      let selectedStatus = filterAccountsList.map((accItem) => {
        selAccIds += sepValue + (accItem.value).toString();
        sepValue = ",";
        return { ...accItem, isChecked: checked };
      });
      setSelectedAccounts(selAccIds);
      setSelectedAccountNames(`${t("accountsFilter.allAccounts")}`);
      setFilterAccountsList(selectedStatus);
    }
    else {
      let selectedStatus = filterAccountsList.map((accItem) =>
        Number(accItem.value) === accIdNum
          ? { ...accItem, isChecked: checked }
          : accItem
      );
      // IF ALL OPTIONS ARE CHECKED 
      if (selectedStatus.every((status) => status.isChecked)) {
        let selectedStatus = filterAccountsList.map((accItem) => {
          if (accItem.isChecked) {
            selAccIds += sepValue + (accItem.value).toString();
            sepValue = ",";
          }
          return { ...accItem, isChecked: checked };
        });
        setSelectedAccounts(selAccIds);
        setSelectedAccountNames(filterAccountsList.length == 1 ? selectedStatus[0].label : `${t("accountsFilter.allAccounts")}`);
        setFilterAccountsList(selectedStatus);
      }
      else {
        selectedStatus.map(accItem => {
          if (accItem.isChecked) {
            selAccIds += sepValue + (accItem.value).toString();
            sepValue = ",";
            if (selAccNames == "") {
              selAccNames = accItem.label;
            }
            accCount++;
          }
        })
        setSelectedAccounts(selAccIds);
        setSelectedAccountNames(accCount > 1 ? selAccNames + " (+" + (accCount - 1).toString() + ")" : selAccNames);
        setFilterAccountsList(selectedStatus);
      }
    }
  };

  // FUNCTION TO HANDLE GROUP FILTER CHECK BOX CLICK EVENT FOR ALL PAGES OTHER THAN DASHBOARD PAGE 
  const onSetFilterGroup = (e, groupIdNum: number) => {
    const { name, checked } = e.target;
    if (name === "group_all") {
      setFilterGroupIds("");
      setFilterGroupNames("");
      let selectedStatus = filterAccountGroupsList.map((groupItem) => {
        return { ...groupItem, isChecked: checked };
      });
      setFilterAccountGroupsList(selectedStatus);

    } else {
      let selectedStatus = filterAccountGroupsList.map((groupItem) =>
        groupItem.id === groupIdNum
          ? { ...groupItem, isChecked: checked }
          : groupItem
      );
      // IF ALL OPTIONS ARE CHECKED 
      if (selectedStatus.every((status) => status.isChecked)) {
        setFilterGroupIds("");
        setFilterGroupNames("");
        let selectedStatus = filterAccountGroupsList.map((groupItem) => {
          return { ...groupItem, isChecked: checked };
        });
        setFilterAccountGroupsList(selectedStatus);
      }
      else {
        let selGrpIds = "", selGrpNames = "", sepValue = "";
        let grpCount = 0;
        selectedStatus.map(gItem => {
          if (gItem.isChecked) {
            selGrpIds += sepValue + gItem.id.toString();
            sepValue = ",";
            if (selGrpNames == "") {
              selGrpNames = gItem.name;
            }
            grpCount++;
          }
        })
        setFilterGroupIds(selGrpIds);
        setFilterGroupNames(grpCount > 1 ? selGrpNames + " (+" + (grpCount - 1).toString() + ")" : selGrpNames);
        setFilterAccountGroupsList(selectedStatus);
      }
    }
  };

  // FUNCTION TO HANDLE GROUP FILTER CHECK BOX CLICK EVENT FOR ONLY DASHBOARD PAGE 
  const onSelectDashboardGroupCheckbox = (e, groupIdNum: number, clickedFrom: string) => {
    const { name, checked } = e.target;
    //IF SHOW ALL GROUP IS SELECTED 
    if (name === "group_all") {
      let selectedStatus = filterAccountGroupsList.map((groupItem) => {
        return { ...groupItem, isChecked: checked };
      });
      setFilterAccountGroupsList(selectedStatus);
      if (selectedStatus.every((status) => status.isChecked) && accountStaffList.every((staffItem) => staffItem.isChecked)) {
        setFilterGroupIds("");
        setFilterGroupNames("");
        setFilterStaffIds("");
        let selectedStatus = filterAccountGroupsList.map((groupItem) => {
          return { ...groupItem, isChecked: checked };
        });
        setFilterAccountGroupsList(selectedStatus);
      }
      else {
        let selGrpIds = "", selGrpNames = "", sepValue = "";
        let grpCount = 0;
        selectedStatus.map(gItem => {
          if (gItem.isChecked) {
            selGrpIds += sepValue + gItem.id.toString();
            sepValue = ",";
            if (selGrpNames == "") {
              selGrpNames = gItem.name;
            }
            grpCount++;
          }
        })
        setFilterGroupIds(selGrpIds);
        let selCount = selectedStatus.filter(d => d.isChecked).length + accountStaffList.filter(d => d.isChecked).length;
        setFilterGroupNames(selCount.toString() + " " + t("dashboard.selected"));
      }
    }
    //IF SHOW ALL STAFF IS SELECTED 
    else if (name === "staff_all") {
      setFilterStaffIds("");
      let selectedStaffs = accountStaffList.map((staffItem) => {
        return { ...staffItem, isChecked: checked };
      });
      setAccountStaffList(selectedStaffs);
      if (filterAccountGroupsList.every((status) => status.isChecked) && selectedStaffs.every((staffItem) => staffItem.isChecked)) {
        setFilterGroupIds("");
        setFilterGroupNames("");
        setFilterStaffIds("");
        let selectedStaffs = accountStaffList.map((staffItem) => {
          return { ...staffItem, isChecked: checked };
        });
        setAccountStaffList(selectedStaffs);
      } else {
        let selStaffIds = "", selGrpNames = "", sepValue = "";
        let grpCount = 0;
        selectedStaffs.map(sItem => {
          if (sItem.isChecked) {
            selStaffIds += sepValue + sItem.staffId.toString();
            sepValue = ",";
          }
        })
        setAccountStaffList(selectedStaffs);
        let selCount = filterAccountGroupsList.filter(d => d.isChecked).length + selectedStaffs.filter(d => d.isChecked).length;
        setFilterGroupNames(selCount.toString() + " " + t("dashboard.selected"));
      }
    }
    else {
      if (clickedFrom == "group") {
        let selectedStatus = filterAccountGroupsList.map((groupItem) =>
          groupItem.id === groupIdNum
            ? { ...groupItem, isChecked: checked }
            : groupItem
        );
        let selectedStaffs = accountStaffList;
        // IF ALL OPTIONS ARE CHECKED 
        if (selectedStatus.every((status) => status.isChecked) && selectedStaffs.every((staffItem) => staffItem.isChecked)) {
          setFilterGroupIds("");
          setFilterGroupNames("");
          setFilterStaffIds("");
          let selectedStatus = filterAccountGroupsList.map((groupItem) => {
            return { ...groupItem, isChecked: checked };
          });
          setFilterAccountGroupsList(selectedStatus);
        }
        else {
          let selGrpIds = "", selGrpNames = "", sepValue = "";
          let grpCount = 0;
          selectedStatus.map(gItem => {
            if (gItem.isChecked) {
              selGrpIds += sepValue + gItem.id.toString();
              sepValue = ",";
              if (selGrpNames == "") {
                selGrpNames = gItem.name;
              }
              grpCount++;
            }
          })
          setFilterGroupIds(selGrpIds);

          setFilterAccountGroupsList(selectedStatus);
          let selCount = selectedStatus.filter(d => d.isChecked).length + accountStaffList.filter(d => d.isChecked).length;
          setFilterGroupNames(selCount.toString() + " " + t("dashboard.selected"));
        }
      } else if (clickedFrom == "staff") {
        let selectedStaffs = accountStaffList.map((staffItem) =>
          staffItem.staffId === groupIdNum
            ? { ...staffItem, isChecked: checked }
            : staffItem
        );
        let selectedGroups = filterAccountGroupsList;
        // IF ALL OPTIONS ARE CHECKED 
        if (selectedGroups.every((status) => status.isChecked) && selectedStaffs.every((staffItem) => staffItem.isChecked)) {
          setFilterGroupIds("");
          setFilterGroupNames("");
          setFilterStaffIds("");
          let selectedStaffs = accountStaffList.map((staffItem) => {
            return { ...staffItem, isChecked: checked };
          });
          setAccountStaffList(selectedStaffs);
        }
        else {
          let selStaffIds = "", selGrpNames = "", sepValue = "";
          let grpCount = 0;
          selectedStaffs.map(sItem => {
            if (sItem.isChecked) {
              selStaffIds += sepValue + sItem.staffId.toString();
              sepValue = ",";
            }
          })
          setAccountStaffList(selectedStaffs);
          setFilterGroupIds(selStaffIds);
          let selCount = selectedGroups.filter(d => d.isChecked).length + selectedStaffs.filter(d => d.isChecked).length;
          setFilterGroupNames(selCount.toString() + " " + t("dashboard.selected"));
        }
      }
    }
  };

  const clearThisFilter = (filterType: string) => {
    if (filterType == "accounts") {
      setAccSearch("");
      let accList: IAllAccountList[] = [];;
      filterAccountsList.map(accItem => {
        if (accItem.value == localStorageIds) {
          setSelectedAccounts(accItem.value);
          setSelectedAccountNames(accItem.label);
          accList.push({ ...accItem, isChecked: true });
        }
        else {
          accList.push({ ...accItem, isChecked: false });
        }
      })
      setFilterAccountsList(accList);
      // onApplyButton("accountFilter"); 
      props?.setSelectedAccountIds && props.setSelectedAccountIds(localStorageIds);
      setAccountFilterApplied(false);
    }
    else if (filterType == "group") {
      setFilterGroupIds("");
      setFilterGroupNames("");
      setGroupFilterApplied(false);
      onApplyButton("groupFilterClear");
    }
    else if (filterType == "date") {
      setDateFilterApplied(false);
      // IN TIPS PAGE DEFAULT DATE IS 'TODAY' - 'THISMONTH' FOR ALL OTHER PAGES
      setFromToDates(defaultFilterDateRangeType, "dateFilter");
      props?.setDateFilterDatesType ? props.setDateFilterDatesType(defaultFromDate, defaultToDate, defaultFilterDateRangeType) : (dummyVar = " ");
    }
    else if (filterType == "status") {
      setFilterStatus("");
      setStatusFilterApplied(false);
      props?.setStatusFilter ? props.setStatusFilter("") : (dummyVar = " ");
    }
    else if (filterType == 'payMethod') {
      setPayMethodFilterApplied(false);
      setFilterPayMethod("");
      setFilterPayMethodName("");
      onApplyButton("payMethod");
    }
    else if (filterType == 'payStatus') {
      let initialValues = props.parentPage == PAGENAME_STAFF ? staffStatusInitialValues : payStatusInitialValues;
      setFilterPaymentStatus(initialValues);
      setpaymentStatusFilterApplied(false);
      const checkedStatusValues = initialValues
        .map((status) => status.value);
      props?.setStatusFilter
        ? props.setStatusFilter(checkedStatusValues.toString())
        : (dummyVar = " ");
      if ((props.parentPage == PAGENAME_STAFF && !dateFilterApplied && !paymentStatusFilterApplied && !tipsDateFilterApplied) || (props.parentPage != PAGENAME_STAFF && !dateFilterApplied && !paymentStatusFilterApplied)) {
        setShowClearFilters(false);
      }
    }
    else if (filterType == 'amount') {
      setFilterAmountFrom(AMOUNTFILTER_DEFAULT_FROM_VALUE.toString());
      setFilterAmountTo(AMOUNTFILTER_DEFAULT_TO_VALUE.toString());
      setAmountFilterApplied(false);
      props.setAmountFilterFromTo && props.setAmountFilterFromTo(AMOUNTFILTER_DEFAULT_FROM_VALUE.toString(), AMOUNTFILTER_DEFAULT_TO_VALUE.toString());
    }
    else if (filterType == "role") {
      setFilterRole(0);
      setFilterRoleApplied(false);
      props.onSetRole && props.onSetRole(0);
    }
    else if (filterType == "tipsDate") {
      setFilterTipsDateRangeType("all");
      setFilterTipsFromDate("");
      setFilterTipsToDate("");
      setTipsDateFilterApplied(false);
      props?.setTipsFilterDates ? props.setTipsFilterDates("", "", "all") : (dummyVar = " ");
    }

    divHeight();
  }

  const clearAllFilters = () => {
    // COMMENTED FOR LATER USE
    // if (props.parentPage == PAGENAME_ACCOUNTS) {
    // } else if (props.parentPage == PAGENAME_STAFF) {
    // }  else
    if (
      props.parentPage == PAGENAME_PAYOUTS ||
      props.parentPage == PAGENAME_TIPS ||
      props.parentPage == PAGENAME_COMMISSIONS ||
      props.parentPage == PAGENAME_STAFF
    ) {
      // setPayMethodFilterApplied(filterPayMethod.length > 0 ? true : false);
      setGroupFilterApplied(false);
      setFilterGroupIds("");
      setFilterGroupNames("");
      setPayMethodFilterApplied(false);
      setFilterPaymentStatus(props.parentPage == PAGENAME_STAFF ? staffStatusInitialValues : payStatusInitialValues);
    }

    setTipsDateFilterApplied(false);
    setFilterTextSearch("");
    setFilterStatus("");
    setFromToDates(defaultFilterDateRangeType, "dateFilter");
    setFromToDates("all", "tipsFilter");
    setDateFilterApplied(false);
    setShowClearFilters(false);
    setPayeeFilterApplied(false);
    setStatusFilterApplied(false);
    setResetFilterAccount(false);
    setpaymentStatusFilterApplied(false);
    setFilterRole(0);
    setFilterRoleApplied(false);
    setFilterAmountFrom(AMOUNTFILTER_DEFAULT_FROM_VALUE.toString());
    setFilterAmountTo(AMOUNTFILTER_DEFAULT_TO_VALUE.toString());
    setAmountFilterApplied(false);

    let accList: IAllAccountList[] = [];
    allAccountList.map((accItem) => {
      if (accItem.value == localStorageIds) {
        accList.push({ ...accItem, isChecked: true });
        setSelectedAccounts(accItem.value.toString());
        setSelectedAccountNames(accItem.label);
      }
      else {
        accList.push({ ...accItem, isChecked: false });
      }
    });
    setFilterAccountsList(accList);
    props?.setSelectedAccountIds && props.setSelectedAccountIds(localStorageIds);
    setAccountFilterApplied(false);
    props?.clearFilters ? props.clearFilters() : (dummyVar = " ");

    divHeight();
  };

  // FOR ONCHANGE OF DATE FROM AND DATE TO INPUTS
  const checkDate = (dateValue: any, calledFrom: string) => {
    let arrTmp = dateValue.split("-");
    if (arrTmp.length == 3 && arrTmp[0].length > 4) {
      if (calledFrom == "fromDate") {
        setFilterFromDate(
          arrTmp[0].substring(0, 4) + "-" + arrTmp[1] + "-" + arrTmp[2]
        );
      } else if (calledFrom == "toDate") {
        setFilterToDate(
          arrTmp[0].substring(0, 4) + "-" + arrTmp[1] + "-" + arrTmp[2]
        );
      }
    } else {
      if (calledFrom == "fromDate") {
        setFilterFromDate(dateValue);
        // setFilterToDate(dateValue);
      } else if (calledFrom == "toDate") {
        setFilterToDate(dateValue);
      }
    }

    if (extraSpace) {
      setTimeout(() => {
        setExtraSpace(false);
      }, 2000);
    } else {
      setExtraSpace(true);
      setTimeout(() => {
        setExtraSpace(false);
      }, 3000);
    }
  };

  const ApplyButton = (calledFrom: string): any => {
    return (
      <div className=" p-2 border-top ">
        <ButtonPrimary
          className=" applyBtn mt-2px"
          onClick={(e) => onApplyButton(calledFrom)}
          buttonLabel={t("common.apply")}
        />
      </div>
    );
  };

  const onSetFilterPayStatus = (e) => {
    const { name, checked } = e.target;

    if (name === PAYMENTS_FILTER_STATUS_ALL) {
      let selectedStatus = filterPaymentStatus.map((statusItem) => {
        return { ...statusItem, isChecked: checked };
      });
      setFilterPaymentStatus(selectedStatus);
    } else {
      let selectedStatus = filterPaymentStatus.map((statusItem) =>
        statusItem.name === name
          ? { ...statusItem, isChecked: checked }
          : statusItem
      );
      setFilterPaymentStatus(selectedStatus);
    }
  };

  const dummyFunction = () => {
    // do nothing
  };

  const numberOnly = (numVal: string, fieldType: string) => {
    let newValue = filterDigitsDotOnly(numVal);
    if (fieldType == "amountFrom") {
      setFilterAmountFrom(newValue);
    } else if (fieldType == "amountTo") {
      setFilterAmountTo(newValue);
    }
  };

  const divRef = useRef(null);
  const [filterOptionCss, setFilterOptionCss] = useState("");
  const divHeight = () => {
    if ((divRef.current?.offsetHeight && divRef.current.offsetHeight > 45)) {
      setFilterOptionCss("mb-12px");
    }
    else {
      setFilterOptionCss("");
    }
  }

  let content;

  switch (props.parentPage) {
    case PAGENAME_DASHBOARD:
      content = <>
        <div>
          <div className={"filter-box-chart"} onMouseOver={e => (divHeight())}>
            <div className="space-between w-100 align-items-center ">
              <div className="flex gap-3 align-items-center ">
                <div className="flex gap-3 align-items-center "  >

                  <div className="gap-3" ref={divRef}>
                    {/* ************************************************************************** */}
                    {/* *********************** ACCOUNTS FILTER FOR DASHBOARD ******************** */}
                    {/* ************************************************************************** */}
                    {props.filterAccount && (
                      <div className={filterOptionCss + " chart-filter-option"}>
                        <div className="dashBoard-sub-header mb-7px">{t("dashboard.account")}</div>
                        <div
                          className={
                            accountFilterApplied
                              ? "filterApplied chart-filtervalue"
                              : "chart-filtervalue"
                          }
                        >
                          {/*  COMMENTED FOR LATER USE */}
                          {/* <span title={accountFilterApplied ? t("common.clearThisFilter") : ""}  className={accountFilterApplied ? "pointer" : ""} onClick={() =>
                          accountFilterApplied ? clearThisFilter('accounts') : dummyFunction()}>
                          <img src={plus} alt="plus" className={accountFilterApplied ? "img-transform-45" : ""}/>
                          </span>  */}
                          {/* <span>{t("accountsFilter.account")}</span> */}
                          {props.filterAccount && selectedAccountNames != "" && (
                            <div className="mt-2px">
                              {selectedAccountNames == t("accountsFilter.allAccounts") ? t("dashboard.accountShowAll") : selectedAccountNames}
                            </div>
                          )}
                          <div className="div-right"><ArrowDropDownIcon className="icon" fontSize="large" /> </div>
                        </div>

                        {filterAccountsList && filterAccountsList.length > 1 && <> <div className="chart-filter-content-body ">
                          <div className={filterAccountsList && filterAccountsList.length > 2 ? "filter-content min-height-250px" : "filter-content "} >
                            <div className={filterAccountsList && filterAccountsList.length > 2 ? "p-2 multiselect min-height-200px" : "p-2 multiselect "} >
                              <label
                                htmlFor="selFilterStatus"
                                className="link-h m-5px mb-5px"
                              >
                                {t("accountsFilter.filterByAccounts")}
                              </label>

                              {filterAccountsList && filterAccountsList.length > 2 && <><div className="pb-14px border-bottom ">
                                <div className="flex relative ">
                                  <div className="div-searchIcon ">
                                    <img src={searchIcon} alt="" />
                                  </div>
                                  <input
                                    type="text"
                                    placeholder={`${t("accounts.searchAccounts")}`}
                                    className="search w-100"
                                    onChange={(e) => setAccSearch(e.target.value)}
                                    value={accSearch}
                                  />
                                  {accSearch && accSearch.length > 0 && (
                                    <CloseIcon
                                      onClick={(e) => setAccSearch("")}
                                      className="pointer-cursor div-search"
                                      sx={{ fontSize: "medium" }}
                                    />
                                  )}
                                </div>
                              </div></>}

                              {filterAccountsList && filterAccountsList.length > 1 && <> <div className="mt-12px">

                                <label htmlFor="account_all" className={`flex gap-2 multisel-status-filter ${!filterAccountsList.some(
                                  (data) => data.isChecked !== true
                                ) &&
                                  "text-blue"
                                  }`} >
                                  <input
                                    type="checkbox"
                                    name="account_all"
                                    id="account_all"
                                    checked={
                                      !filterAccountsList.some(
                                        (data) => data.isChecked !== true
                                      )
                                    }
                                    onChange={(e) => onSetFilterAccounts(e, 0)}
                                  />
                                  {t("dashboard.accountAllOption")}
                                </label>
                                <div className="border-bottom mb-10px"></div>

                                {filterAccountsList &&
                                  filterAccountsList
                                    .filter((item) => {
                                      if (accSearch === "") {
                                        return item;
                                      } else if (
                                        item.label
                                          .toLowerCase()
                                          .includes(accSearch.toLowerCase())
                                      ) {
                                        return item;
                                      }
                                    }).map((data) => (
                                      <label htmlFor={'account_' + data.value.toString()} className={`flex gap-2 multisel-status-filter`} >
                                        <input
                                          type="checkbox"
                                          id={'account_' + data.value.toString()}
                                          name={'account_' + data.value.toString()}
                                          checked={data.isChecked ? true : false}
                                          onChange={(e) => onSetFilterAccounts(e, Number(data.value))}
                                          className={data.isChecked ? "text-blue" : ""}
                                        />
                                        {data.label}
                                      </label>
                                    ))}
                              </div></>}

                            </div>
                            {selectedAccountNames && selectedAccountNames != "" && ApplyButton("accountFilter")}
                          </div>
                        </div></>}
                      </div>
                    )}


                    {/* ************************************************************************** */}
                    {/* ************************ GROUP FILTER FOR DASHBOARD ********************** */}
                    {/* ************************************************************************** */}
                    {props.filterGroup && (!selectedAccounts || selectedAccounts.split(",").length != 1) && (
                      <div className={filterOptionCss + " chart-filter-option ml-25px"}>
                        <div className="dashBoard-sub-header mb-7px">{t("dashboard.group")}</div>
                        {/* <div className="dashboard-header-text-box">GROUP</div> */}
                        <div className={"inactive-bg chart-filtervalue"}  >
                          <div className="text-grey mt-2px">
                            {(!filterGroupNames || filterGroupNames.trim() == "") ? t("dashboard.groupShowAll") : filterGroupNames}
                          </div>
                          <div className="div-right"><ArrowDropDownIcon className="icon-inactive" fontSize="large" /> </div>
                        </div>
                      </div>)
                    }

                    {props.filterGroup && selectedAccounts && selectedAccounts.split(",").length == 1 && (
                      <div className={filterOptionCss + " chart-filter-option ml-25px"}>
                        <div className="dashBoard-sub-header mb-7px">{t("dashboard.group")}</div>
                        {/* <div className="dashboard-header-text-box">GROUP</div> */}
                        <div
                          className={
                            groupFilterApplied
                              ? "filterApplied chart-filtervalue"
                              : "chart-filtervalue"
                          }
                        >
                          {props.filterGroup && (
                            <div className="mt-2px">
                              {(!filterGroupNames || filterGroupNames.trim() == "") ? t("dashboard.groupShowAll") : filterGroupNames}
                            </div>
                          )}
                          <div className="div-right"><ArrowDropDownIcon className="icon" fontSize="large" /> </div>
                        </div>

                        <div className="chart-filter-content-body ">
                          <div className={filterAccountGroupsList && filterAccountGroupsList.length > 2 ? "filter-content min-height-250px" : "filter-content "} >
                            <div className={filterAccountGroupsList && filterAccountGroupsList.length > 2 ? "p-2 multiselect min-height-200px" : "p-2 multiselect "} >
                              {/* <label
                                htmlFor="selFilterStatus"
                                className="link-h m-5px mb-2"
                              >
                                {t("groupFilter.filterByGroup")}
                              </label> */}
                              <div>

                                <label className="link-h mb-2px" >
                                  {t("dashboard.groupPool")}
                                </label>

                                {filterAccountGroupsList.length > 1 ? <><label htmlFor="group_all" className={` flex gap-2 multisel-status-filter ${!filterAccountGroupsList.some(
                                  (data) => data.isChecked !== true
                                ) &&
                                  "text-blue"
                                  }`} >
                                  <input
                                    type="checkbox"
                                    name="group_all"
                                    id="group_all"
                                    checked={
                                      !filterAccountGroupsList.some(
                                        (data) => data.isChecked !== true
                                      )
                                    }
                                    onChange={(e) => onSelectDashboardGroupCheckbox(e, 0, "")}
                                  />
                                  {t("dashboard.groupPoolShowAll")}
                                </label>
                                <hr className="mt-2 mb-11px"></hr></> : <></>}

                                {filterAccountGroupsList.map((data) => (
                                  <label htmlFor={'group_' + data.id.toString()} className={`flex gap-2 multisel-status-filter`} >
                                    <input
                                      type="checkbox"
                                      id={'group_' + data.id.toString()}
                                      name={'group_' + data.id.toString()}
                                      checked={data.isChecked ? true : false}
                                      onChange={(e) => onSelectDashboardGroupCheckbox(e, data.id, "group")}
                                      className={data.isChecked ? "text-blue" : ""}
                                    />
                                    {data.name}
                                  </label>
                                ))}

                                <hr className="mt-10px mb-7px"></hr>

                                <label className="link-h mb-2px" >
                                  {t("dashboard.individualStaff")}
                                </label>

                                {accountStaffList.length > 1 ? <><label htmlFor="staff_all" className={` flex gap-2 multisel-status-filter ${!filterAccountGroupsList.some(
                                  (data) => data.isChecked !== true
                                ) &&
                                  "text-blue"
                                  }`} >
                                  <input
                                    type="checkbox"
                                    name="staff_all"
                                    id="staff_all"
                                    checked={
                                      !accountStaffList.some(
                                        (data) => data.isChecked !== true
                                      )
                                    }
                                    onChange={(e) => onSelectDashboardGroupCheckbox(e, 0, "")}
                                  />
                                  {t("dashboard.staffPoolShowAll")}
                                </label>
                                <hr className="mt-2 mb-11px"></hr></>:<></>}

                                {accountStaffList.map((data) => (
                                  <label htmlFor={'staff_' + data.staffId.toString()} className={`flex gap-2 multisel-status-filter`} >
                                    <input
                                      type="checkbox"
                                      id={'staff_' + data.staffId.toString()}
                                      name={'staff_' + data.staffId.toString()}
                                      checked={data.isChecked ? true : false}
                                      onChange={(e) => onSelectDashboardGroupCheckbox(e, data.staffId, "staff")}
                                      className={data.isChecked ? "text-blue" : ""}
                                    />
                                    {data.staffName}
                                  </label>
                                ))}


                              </div>
                            </div>
                            {
                              ((filterAccountGroupsList.every(
                                (data) => data.isChecked !== true
                              )) && (accountStaffList.every(
                                (data) => data.isChecked !== true
                              )))
                                ? <></> :
                                <>
                                  {ApplyButton("chartGroupFilter")}
                                </>
                            }

                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>;
      break;
    // *************************************************************************************
    // ************** FILTER SECTION FOR ALL OTHER PAGES OTHER THAN DASHBOARD **************
    // *************************************************************************************
    default:
      content = <>
        <div>
          <div className={"filter-box"} onMouseOver={e => (divHeight())}>
            <div className="space-between w-100 align-items-center ">
              <div className="flex gap-3 align-items-center ">
                <div className="filter-head">{props.headind}</div>

                <div className="flex gap-3 align-items-center "  >

                  <div className="gap-3" ref={divRef}>
                    {/* ************************************************************************** */}
                    {/* *** ACCOUNTS FILTER - STAFF, TIPS, COMM, PAYMENTS, WITHHOLDINGS ********** */}
                    {/* ************************************************************************** */}
                    {props.filterAccount && (
                      <div className={filterOptionCss + " filter-option"}>
                        <div
                          className={
                            accountFilterApplied
                              ? "filterApplied filtervalue"
                              : "filtervalue"
                          }
                        >
                          <span title={accountFilterApplied ? t("common.clearThisFilter") : ""} className={accountFilterApplied ? "pointer" : ""} onClick={() =>
                            accountFilterApplied ? clearThisFilter('accounts') : dummyFunction()}>
                            <img src={plus} alt="plus" className={accountFilterApplied ? "img-transform-45" : ""} />
                          </span>
                          <span>{t("accountsFilter.account")}</span>
                          {props.filterAccount && selectedAccountNames != "" && (
                            <div className="filterAppliedValue">
                              {selectedAccountNames}
                            </div>
                          )}
                        </div>

                        {filterAccountsList && filterAccountsList.length > 1 && <> <div className="filter-content-body ">
                          <div className={filterAccountsList && filterAccountsList.length > 2 ? "filter-content min-height-250px" : "filter-content "} >
                            <div className={filterAccountsList && filterAccountsList.length > 2 ? "p-2 multiselect min-height-200px" : "p-2 multiselect "} >
                              <label
                                htmlFor="selFilterStatus"
                                className="link-h m-5px mb-5px"
                              >
                                {t("accountsFilter.filterByAccounts")}
                              </label>

                              {filterAccountsList && filterAccountsList.length > 2 && <><div className="pb-14px border-bottom ">
                                <div className="flex relative ">
                                  <div className="div-searchIcon ">
                                    <img src={searchIcon} alt="" />
                                  </div>
                                  <input
                                    type="text"
                                    placeholder={`${t("accounts.searchAccounts")}`}
                                    className="search w-100"
                                    onChange={(e) => setAccSearch(e.target.value)}
                                    value={accSearch}
                                  />
                                  {accSearch && accSearch.length > 0 && (
                                    <CloseIcon
                                      onClick={(e) => setAccSearch("")}
                                      className="pointer-cursor div-search"
                                      sx={{ fontSize: "medium" }}
                                    />
                                  )}
                                </div>
                              </div></>}

                              {filterAccountsList && filterAccountsList.length > 1 && <> <div className="mt-12px">
                                <label htmlFor="" className={`flex gap-2 multisel-status-filter ${!filterAccountsList.some(
                                  (data) => data.isChecked !== true
                                ) &&
                                  "text-blue"
                                  }`} >
                                  <input
                                    type="checkbox"
                                    name="account_all"
                                    id="account_all"
                                    checked={
                                      !filterAccountsList.some(
                                        (data) => data.isChecked !== true
                                      )
                                    }
                                    onChange={(e) => onSetFilterAccounts(e, 0)}
                                  />
                                  {t("accountsFilter.all")}
                                </label>

                                {filterAccountsList &&
                                  filterAccountsList
                                    .filter((item) => {
                                      if (accSearch === "") {
                                        return item;
                                      } else if (
                                        item.label
                                          .toLowerCase()
                                          .includes(accSearch.toLowerCase())
                                      ) {
                                        return item;
                                      }
                                    }).map((data) => (
                                      <label htmlFor={'account_' + data.value.toString()} className={`flex gap-2 multisel-status-filter`} >
                                        <input
                                          type="checkbox"
                                          id={'account_' + data.value.toString()}
                                          name={'account_' + data.value.toString()}
                                          checked={data.isChecked ? true : false}
                                          onChange={(e) => onSetFilterAccounts(e, Number(data.value))}
                                          className={data.isChecked ? "text-blue" : ""}
                                        />
                                        {data.label}
                                      </label>
                                    ))}
                              </div></>}

                            </div>
                            {ApplyButton("accountFilter")}
                          </div>
                        </div></>}
                      </div>
                    )}


                    {/* ************************************************************************** */}
                    {/* ******* GROUP FILTER - USED IN TIPS, COMMISSION, PAYMENTS PAGES ********** */}
                    {/* ************************************************************************** */}
                    {props.filterGroup && (showGroupFilter || filterAccountsList.length == 1) && (
                      <div className={filterOptionCss + " filter-option"}>
                        <div
                          className={
                            groupFilterApplied
                              ? "filterApplied filtervalue"
                              : "filtervalue"
                          }
                        >
                          <span title={groupFilterApplied ? t("common.clearThisFilter") : ""} className={groupFilterApplied ? "pointer" : ""} onClick={() =>
                            groupFilterApplied ? clearThisFilter('group') : dummyFunction()}>
                            <img src={plus} alt="plus" className={groupFilterApplied ? "img-transform-45" : ""} />
                          </span>
                          <span>{t("groupFilter.group")}</span>
                          {groupFilterApplied && props.filterGroup && filterGroupNames != "" && (
                            <div className="filterAppliedValue">
                              {filterGroupNames}
                            </div>

                          )}
                        </div>

                        <div className="filter-content-body ">
                          <div className={filterAccountGroupsList && filterAccountGroupsList.length > 2 ? "filter-content min-height-250px" : "filter-content "} >
                            <div className={filterAccountGroupsList && filterAccountGroupsList.length > 2 ? "p-2 multiselect min-height-200px" : "p-2 multiselect "} >
                              <label
                                htmlFor="selFilterStatus"
                                className="link-h m-5px mb-2"
                              >
                                {t("groupFilter.filterByGroup")}
                              </label>

                              <div>
                                <label htmlFor="" className={`flex gap-2 multisel-status-filter ${!filterAccountGroupsList.some(
                                  (data) => data.isChecked !== true
                                ) &&
                                  "text-blue"
                                  }`} >
                                  <input
                                    type="checkbox"
                                    name="group_all"
                                    id="group_all"
                                    checked={
                                      !filterAccountGroupsList.some(
                                        (data) => data.isChecked !== true
                                      )
                                    }
                                    onChange={(e) => onSetFilterGroup(e, 0)}
                                  />
                                  {t("groupFilter.all")}
                                </label>

                                {filterAccountGroupsList.map((data) => (
                                  <label htmlFor={'group_' + data.id.toString()} className={`flex gap-2 multisel-status-filter`} >
                                    <input
                                      type="checkbox"
                                      id={'group_' + data.id.toString()}
                                      name={'group_' + data.id.toString()}
                                      checked={data.isChecked ? true : false}
                                      onChange={(e) => onSetFilterGroup(e, data.id)}
                                      className={data.isChecked ? "text-blue" : ""}
                                    />
                                    {data.name}
                                  </label>
                                ))}
                              </div>
                            </div>
                            {ApplyButton("groupFilter")}
                          </div>
                        </div>
                      </div>
                    )}


                    {/* ******************************************************************************** */}
                    {/* *** DATE FILTER - ACC, STAFF, WITHHOLDINGS, TIPS, COMM., PAYOUTS, ACC USERS **** */}
                    {/* ******************************************************************************** */}
                    {/* <div className="w-100 row">
                      <div>{'defaultFilterDateRangeType: '+defaultFilterDateRangeType+':'}</div>
                      <div>{'filterDateRangeType: '+filterDateRangeType+':'}</div>
                      <div>{'props.parentPage: '+JSON.stringify(props.parentPage)+':'}</div>
                      <div>{'arrDateAllDefaultPages: '+JSON.stringify(arrDateAllDefaultPages)+':'}</div>
                    </div> */}
                    {props.filterDate && (
                      <div className="filter-option" onMouseOver={e => (divHeight())}>
                        <div
                          className={
                            dateFilterApplied
                              ? "filterApplied filtervalue"
                              : "filtervalue"
                          }
                        >
                          <span title={dateFilterApplied ? t("common.clearThisFilter") : ""} className={dateFilterApplied ? "pointer" : ""} onClick={() =>
                            dateFilterApplied ? clearThisFilter('date') : dummyFunction()}>
                            <img src={plus} alt="plus" className={dateFilterApplied ? "img-transform-45" : ""} />
                          </span>
                          <span>{t("dateFilter.date")} </span>
                          <div className="filterAppliedValue">
                            {(filterFromDate.trim().length > 0 && filterToDate.trim().length > 0)
                              ? (<>
                                {filterFromDate == filterToDate ? dayjs(filterFromDate).format(`MM/DD/YYYY`) : (dayjs(filterFromDate).format(`MM/DD/YYYY`) + " to ") + dayjs(filterToDate).format(`MM/DD/YYYY`)}</>) : <>{t("dateFilter.allDates")}</>}
                          </div>

                          {/* {(dateFilterApplied || ( 
                            (filterDateRangeType==DATEFILTER_THISMONTH && props.parentPage && arrDateThisMonthDefaultPages.includes(props.parentPage)) ||
                            (filterDateRangeType==DATEFILTER_TODAY && props.parentPage && arrDateTodayDefaultPages.includes(props.parentPage))
                            )  
                            )
                            && (
                            <div className="filterAppliedValue">
                              {filterFromDate==filterToDate ? dayjs(filterFromDate).format(`MM/DD/YYYY`) : (dayjs(filterFromDate).format(`MM/DD/YYYY`)+ " to ") + dayjs(filterToDate).format(`MM/DD/YYYY`)}
                            </div>
                          )} */}

                        </div>
                        <div
                          className={`filter-content-body ${extraSpace && "min-width-420px min-height-420px"
                            }`}
                        >
                          <div className="filter-content text-dark-blue font-500">
                            <div className="p-2">
                              <label className="mb-0 link-h mt-5px ">
                                {t("dateFilter.filterByDate")}
                              </label>
                              <div>
                                <label
                                  htmlFor="selDateFilter"
                                  className="text-dark-blue mt-10px"
                                >
                                  {t("dateFilter.viewPeriod")}
                                </label>
                                <div className="">
                                  <SelectField
                                    type="inputType"
                                    name=""
                                    id="selDateFilter"
                                    onChange={(e: any) =>
                                      setFromToDates(e.target.value, "dateFilter")
                                    }
                                    value={filterDateRangeType}
                                    options={<>
                                      {props.needDateAllOption && <option value={DATEFILTER_ALLDATES}>{t("dateFilter.allDates")}</option>}
                                      <option value={DATEFILTER_TODAY}> {t("dateFilter.thisDay")}</option>
                                      <option value={DATEFILTER_THISWEEK}>{t("dateFilter.thisWeek")}</option>
                                      <option value={DATEFILTER_THISMONTH}>{t("dateFilter.thisMonth")}</option>
                                      <option value={DATEFILTER_THISYEAR}>{t("dateFilter.thisYear")}</option>
                                    </>}
                                  />
                                </div>
                              </div>

                              {filterDateRangeType && filterDateRangeType.length > 0 && <div className="flex gap-3 mt-1">
                                <div className="">
                                  <label htmlFor="filterFromDate">
                                    {t("dateFilter.startDate")}
                                  </label>
                                  <Textfield
                                    value={filterFromDate}
                                    name="filterFromDate"
                                    id="filterFromDate"
                                    placeholder={`${t("dateFilter.startDate")}`}
                                    type="date"
                                    onChange={(e) =>
                                      checkDate(e.target.value, "fromDate")
                                    }
                                    onClick={() => setExtraSpace(true)}
                                    maxLength={10}
                                  />
                                </div>
                                <span className="center mt-33px text-dark font-bold">
                                  {t("dateFilter.to")}
                                </span>
                                <div className="">
                                  <label htmlFor="filterToDate">
                                    {t("dateFilter.endDate")}
                                  </label>
                                  <Textfield
                                    value={filterToDate}
                                    name="filterToDate"
                                    id="filterToDate"
                                    placeholder={`${t("dateFilter.endDate")}`}
                                    type="date"
                                    onChange={(e) =>
                                      checkDate(e.target.value, "toDate")
                                    }
                                    onClick={() => setExtraSpace(true)}
                                    maxLength={10}
                                  />
                                </div>
                              </div>}
                            </div>

                            {ApplyButton("dateFilter")}
                          </div>
                        </div>
                      </div>
                    )}


                    {/* ******************************************************************************** */}
                    {/* ******** PAYSTATUS FILTER - ACC, STAFF LIST, WITHHOLDINGS, COMM., PAYOUTS ****** */}
                    {/* ******************************************************************************** */}
                    {props.filterPayStatus && (
                      <div className="filter-option" onMouseOver={e => (divHeight())}>
                        <div
                          className={
                            paymentStatusFilterApplied
                              ? "filterApplied filtervalue"
                              : "filtervalue"
                          }
                        >
                          <span title={paymentStatusFilterApplied ? t("common.clearThisFilter") : ""} className={paymentStatusFilterApplied ? "pointer" : ""} onClick={() =>
                            paymentStatusFilterApplied ? clearThisFilter('payStatus') : dummyFunction()}>
                            <img src={plus} alt="plus" className={paymentStatusFilterApplied ? "img-transform-45" : ""} />
                          </span>
                          <span>{t("statusFilter.status")}</span>
                          {paymentStatusFilterApplied && props.filterPayStatus && (
                            <div className="filterAppliedValue">
                              {filterPaymentStatus
                                .filter((status) => status.isChecked)
                                .map((status: any, index: any) =>
                                  index == 0 ? (
                                    <>{status.name}</>
                                  ) : (
                                    <>, {status.name}</>
                                  )
                                )}
                            </div>
                          )}
                        </div>

                        <div className="filter-content-body">
                          <div className="filter-content">
                            <div className="p-2">
                              <label
                                htmlFor="selFilterStatus"
                                className="link-h m-5px mb-2"
                              >
                                {t("statusFilter.filterByStatus")}
                              </label>

                              <div>
                                <label htmlFor="" className={`flex gap-2 multisel-status-filter ${!filterPaymentStatus.some(
                                  (data) => data.isChecked !== true
                                ) &&
                                  "text-blue"
                                  }`} >
                                  <input
                                    type="checkbox"
                                    name={PAYMENTS_FILTER_STATUS_ALL}
                                    id={PAYMENTS_FILTER_STATUS_ALL}
                                    checked={
                                      !filterPaymentStatus.some(
                                        (data) => data.isChecked !== true
                                      )
                                    }
                                    onChange={(e) => onSetFilterPayStatus(e)}
                                  />
                                  {t("statusFilter.all")}
                                </label>

                                {filterPaymentStatus.map((data) => (
                                  <label htmlFor={data.value} className={`flex gap-2 multisel-status-filter`} >
                                    <input
                                      type="checkbox"
                                      name={data.name}
                                      checked={data.isChecked ? true : false}
                                      // checked={true}
                                      onChange={(e) => onSetFilterPayStatus(e)}
                                      id={data.value}
                                      className={data.isChecked ? "text-blue" : ""}
                                    />
                                    {data.name}
                                  </label>
                                ))}
                              </div>
                            </div>
                            {ApplyButton("statusFilter")}
                          </div>
                        </div>
                      </div>
                    )}


                    {/* ********************************************************************************* */}
                    {/* *********** STATUS FILTER - ACCOUNTS, ADMIN USERS, ACCOUNT USERS PAGES ********** */}
                    {/* ********************************************************************************* */}
                    {props.filterStatus && (
                      <div className="filter-option" onMouseOver={e => (divHeight())}>
                        <div
                          className={
                            statusFilterApplied
                              ? "filterApplied filtervalue"
                              : "filtervalue"
                          }
                        >
                          <span className={statusFilterApplied ? "pointer" : ""} onClick={() =>
                            statusFilterApplied ? clearThisFilter('status') : dummyFunction()}>
                            <img src={plus} alt="plus" className={statusFilterApplied ? "img-transform-45" : ""} title={statusFilterApplied ? t("common.clearThisFilter") : ""} />
                          </span>
                          <span>{t("statusFilter.status")}</span>
                          {statusFilterApplied && (
                            <div className="filterAppliedValue">
                              {filterStatus == "" ? (
                                <>{t("statusFilter.all")}</>
                              ) : filterStatus == "Yes" ? (
                                <>{t("statusFilter.active")}</>
                              ) : filterStatus == "No" ? (
                                <>{t("statusFilter.inactive")}</>
                              ) : (
                                <>{t("statusFilter.inactive")}</>
                              )}
                            </div>
                          )}
                        </div>

                        <div className="filter-content-body">
                          <div className="filter-content">
                            <div className="p-2">
                              <label
                                htmlFor="selFilterStatus"
                                className="link-h mt-5px mb-10px"
                              >
                                {t("statusFilter.filterByStatus")}
                              </label>

                              <SelectField
                                type="inputType"
                                name=""
                                id="selFilterStatus"
                                value={filterStatus}
                                onChange={(e: any) => setFilterStatus(e.target.value)}

                                options={<>
                                  <option value="" className="font-500 fontsize-14 ">
                                    {t("statusFilter.all")}
                                  </option>
                                  <option
                                    value="Yes"
                                    className="font-500 fontsize-14 success"
                                  >
                                    {t("statusFilter.active")}
                                  </option>
                                  <option
                                    value="No"
                                    className="font-500 fontsize-14 danger"
                                  >
                                    {t("statusFilter.inactive")}
                                  </option></>}
                              />
                            </div>
                            {ApplyButton("statusFilter")}
                          </div>
                        </div>
                      </div>
                    )}


                    {/* ********************************************************************************* */}
                    {/* ******************* TIPS FILTER - STAFF LIST PAGE ******************************* */}
                    {/* ********************************************************************************* */}
                    {props.filterTips && (
                      <div className="filter-option" onMouseOver={e => (divHeight())}>
                        <div
                          className={
                            tipsDateFilterApplied
                              ? "filterApplied filtervalue"
                              : "filtervalue"
                          }
                        >
                          <span title={tipsDateFilterApplied ? t("common.clearThisFilter") : ""} className={tipsDateFilterApplied ? "pointer" : ""} onClick={() =>
                            tipsDateFilterApplied ? clearThisFilter('tipsDate') : dummyFunction()}>
                            <img src={plus} alt="plus" className={tipsDateFilterApplied ? "img-transform-45" : ""} />
                          </span>
                          <span>{t("tipsFilter.tips")}</span>
                          {tipsDateFilterApplied && (
                            <div className="filterAppliedValue">
                              {filterTipsDateRangeType == "all"
                                ? t("tipsFilter.allTime")
                                : filterTipsDateRangeType == DATEFILTER_THISWEEK
                                  ? t("tipsFilter.thisWeek")
                                  : filterTipsDateRangeType == DATEFILTER_THISMONTH
                                    ? t("tipsFilter.thisMonth")
                                    : t("tipsFilter.allTime")}
                            </div>
                          )}
                        </div>

                        <div className="filter-content-body">
                          <div className="filter-content">
                            <div className="p-2">
                              <label
                                htmlFor="selTipsFilter"
                                className="link-h mt-5px mb-10px"
                              >
                                {t("tipsFilter.filterByTips")}
                              </label>
                              <SelectField
                                type="inputType"
                                name="selTipsFilter"
                                id="selTipsFilter"
                                onChange={(e: any) =>
                                  setFromToDates(e.target.value, "tipsFilter")
                                }
                                value={filterTipsDateRangeType}

                                options={<>
                                  <option value="all">{t("tipsFilter.allTime")}</option>
                                  <option value={DATEFILTER_THISWEEK}>{t("tipsFilter.thisWeek")}</option>
                                  <option value={DATEFILTER_THISMONTH}>{t("tipsFilter.thisMonth")}</option>
                                </>}
                              />
                            </div>
                            {ApplyButton("tipsDateFilter")}
                          </div>
                        </div>
                      </div>
                    )}


                    {/* ********************************************************************************* */}
                    {/* ***************** ROLES FILTER - ADMIN USERS, ACCOUNT USERS PAGES *************** */}
                    {/* ********************************************************************************* */}
                    {props.filterRole && (
                      <div className={`filter-option`} onMouseOver={e => (divHeight())}>
                        <div
                          className={
                            filterRoleApplied
                              ? "filterApplied filtervalue"
                              : "filtervalue"
                          }
                        >
                          <span className={filterRoleApplied ? "pointer" : ""} onClick={() =>
                            filterRoleApplied ? clearThisFilter('role') : dummyFunction()}>
                            <img src={plus} alt="plus" className={filterRoleApplied ? "img-transform-45" : ""} title={filterRoleApplied ? t("common.clearThisFilter") : ""} />
                          </span>
                          <span>{t("common.role")}</span>
                          {filterRoleApplied && filterRole > 0 && (
                            <div className="filterAppliedValue">
                              {filterRole == 0 ? (
                                <>{""}</>
                              ) : filterRole == USER_ROLE_ADMIN_SUPERADMIN ? (
                                <>{t("userRoles.adminSuper")}</>
                              ) : filterRole == USER_ROLE_ADMIN_ADMIN ? (
                                <>{t("userRoles.adminAdmin")}</>
                              ) : filterRole == USER_ROLE_ACCOUNT_MANAGER ? (
                                <>{t("userRoles.accountManager")}</>
                              ) : filterRole == USER_ROLE_ACCOUNT_USER ? (
                                <>{t("userRoles.accountUser")}</>
                              ) : (
                                <></>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="filter-content-body">
                          <div className="filter-content">
                            <div className="p-2">
                              <label
                                htmlFor="frop"
                                className="link-h mt-5px mb-10px"
                              >
                                {t("roleFilter.filterByRole")}
                              </label>

                              <SelectField
                                type="inputType"
                                name=""
                                id="frop"
                                value={filterRole}
                                onChange={(e) =>
                                  setFilterRole(Number(e.target.value))
                                }
                                options={<>
                                  <option value={0}>{"All"}</option>
                                  {props.parentPage == PAGENAME_ADMINUSERS && (
                                    <>
                                      <option value={USER_ROLE_ADMIN_SUPERADMIN}>
                                        {t("userRoles.adminSuper")}
                                      </option>
                                      <option value={USER_ROLE_ADMIN_ADMIN}>
                                        {t("userRoles.adminAdmin")}
                                      </option>
                                    </>
                                  )}
                                  {props.parentPage == PAGENAME_ACCOUNTUSERS && (
                                    <>
                                      <option value={USER_ROLE_ACCOUNT_MANAGER}>
                                        {t("userRoles.accountManager")}
                                      </option>
                                      <option value={USER_ROLE_ACCOUNT_USER}>
                                        {t("userRoles.accountUser")}
                                      </option>
                                    </>
                                  )}</>}
                              />
                            </div>
                            {ApplyButton("role")}
                          </div>
                        </div>
                      </div>
                    )}


                    {/* ********************************************************************************* */}
                    {/* ***************** PAYEE FILTER -  TIPS, WITHHOLDINGS, PAYOUTS ******************* */}
                    {/* ********************************************************************************* */}
                    {props.filterPayee && (
                      <FilterPayee
                        setPayeeTypes={setPayeeTypes}
                        setPayeeIds={setPayeeIds}
                        setPayeeNames={setPayeeNames}
                        resetPayeeFilter={showClearFilters}
                        acceptPayeeFilterData={acceptPayeeFilterData}
                        parentPage={props.parentPage ? props.parentPage : ""}
                        divHeight={divHeight}
                      />
                    )}


                    {/* ********************************************************************************* */}
                    {/* ************* PAYMETHODS FILTER -  TIPS PAGE ************************************ */}
                    {/* ********************************************************************************* */}
                    {props.filterPayMethod && (
                      <div className="filter-option" onMouseOver={e => (divHeight())}>
                        <div
                          className={
                            payMethodFilterApplied
                              ? "filterApplied filtervalue"
                              : "filtervalue"
                          }
                        >
                          <span title={payMethodFilterApplied ? t("common.clearThisFilter") : ""} className={payMethodFilterApplied ? "pointer" : ""} onClick={() =>
                            payMethodFilterApplied ? clearThisFilter('payMethod') : dummyFunction()}>
                            <img src={plus} alt="plus" className={payMethodFilterApplied ? "img-transform-45" : ""} />
                          </span>
                          <span>{t("payMethodsFilter.paymentMethod")}</span>
                          {payMethodFilterApplied && (
                            <div className="filterAppliedValue">
                              {filterPayMethodName}
                            </div>
                          )}
                        </div>

                        <div className="filter-content-body">
                          <div className="filter-content">
                            <div className="p-2">
                              <label
                                htmlFor="selpayMethod"
                                className="link-h mt-5px mb-10px"
                              >
                                {t("payMethodsFilter.filterByPayMethod")}
                              </label>
                              <SelectField
                                type="inputType"
                                name="selpayMethod"
                                id="selpayMethod"
                                className="w-100 p-1"
                                value={filterPayMethod}
                                onChange={(e: any) => {
                                  setFilterPayMethod(e.target.value);
                                  setFilterPayMethodName(
                                    e.target[e.target.selectedIndex].text
                                  );
                                }}
                                options={<>
                                  <option value="">
                                    {t("payMethodsFilter.filterOptionAll")}
                                  </option>
                                  {props?.stripePayMethods &&
                                    props.stripePayMethods.map((pmItem) => (
                                      <option value={pmItem.id.toString()}>
                                        {pmItem.paymentMethod}
                                      </option>
                                    ))}</>}
                              />
                            </div>
                            {ApplyButton("payMethod")}
                          </div>
                        </div>
                      </div>
                    )}


                    {/* ********************************************************************************* */}
                    {/* ************* AMOUNT FILTER -  PAYOUTS PAGE ************************************* */}
                    {/* ********************************************************************************* */}
                    {props.filterAmount && (
                      <div className="filter-option" onMouseOver={e => (divHeight())}>
                        <div
                          className={
                            amountFilterApplied
                              ? "filterApplied filtervalue"
                              : "filtervalue"
                          }
                        >
                          <span title={amountFilterApplied ? t("common.clearThisFilter") : ""} className={amountFilterApplied ? "pointer" : ""} onClick={() =>
                            amountFilterApplied ? clearThisFilter('amount') : dummyFunction()}>
                            <img src={plus} alt="plus" className={amountFilterApplied ? "img-transform-45" : ""} />
                          </span>
                          <span>{t("amountFilter.amount")}</span>
                          {/* amountFilterApplied && */}
                          {filterAmountTo && Number(filterAmountTo) > 0 && (
                            <div className="filterAppliedValue">
                              {filterAmountFrom} {t("common.to")} {filterAmountTo}
                            </div>
                          )}
                        </div>

                        <div className="filter-content-body">
                          <div className="filter-content min-width-250px  text-dark-blue font-500">
                            <div className="p-2">
                              <label className="mb-0 link-h mt-5px ">
                                {t("amountFilter.filterByAmount")}
                              </label>

                              <div className="flex  gap-3">
                                <div className="">
                                  <label htmlFor="filterFromDate">
                                    {t("amountFilter.amountFrom")}
                                  </label>
                                  <Textfield
                                    value={filterAmountFrom}
                                    placeholder={`${t(
                                      "amountFilter.amountFromPlaceHolder"
                                    )}`}
                                    type="text"
                                    onChange={(e) =>
                                      numberOnly(e.target.value, "amountFrom")
                                    }
                                    maxLength="10"
                                  />
                                </div>
                                <span className="center mt-33px text-dark font-bold">
                                  {t("dateFilter.to")}
                                </span>
                                <div className="">
                                  <label htmlFor="filterToDate">
                                    {t("amountFilter.amountTo")}
                                  </label>
                                  <Textfield
                                    type="text"
                                    placeholder={`${t(
                                      "amountFilter.amountToPlaceHolder"
                                    )}`}
                                    value={filterAmountTo}
                                    onChange={(e) =>
                                      numberOnly(e.target.value, "amountTo")
                                    }
                                    maxLength="10"
                                  />
                                </div>
                              </div>
                            </div>

                            {ApplyButton("amount")}
                          </div>
                        </div>
                      </div>
                    )}

                  </div>

                  {/* ******************************** CLEAR FILTER ******************************** */}
                  <div
                    className={
                      "min-width-110px clear-text " +
                      (getAppliedFiltersCount() > 0 ? " pointer-cursor " : "")
                    }
                    onClick={() =>
                      getAppliedFiltersCount() > 0
                        ? clearAllFilters()
                        : dummyFunction()
                    }
                  >
                    <span
                      className={`${getAppliedFiltersCount() > 0 && "text-blue center gap-1"
                        }`}
                    >
                      {t("common.clearFilters")}{" "}
                      {getAppliedFiltersCount() > 0 && (
                        <div className="badgefilter">
                          {getAppliedFiltersCount()}
                        </div>
                      )}
                    </span>
                  </div>
                  {/* ******************************** CLEAR FILTER ******************************** */}
                </div>
              </div>

              <div className="flex gap-4">
                {props.buttonLabel === t("stafflist.inviteStaff") && (
                  <div className="flex gap-4">
                    <ButtonPrimary
                      icon={true}
                      buttonLabel={props.buttonLabel}
                      onClick={(e) => {
                        props.headerButtonFunction &&
                          props.headerButtonFunction(true);
                      }}
                    />
                  </div>
                )}

                {props.searchInput && (
                  <div className="flex-end">
                    <div className="div-right w-100 ">
                      <div className="flex relative">
                        <div className="div-searchIcon ">
                          <img src={searchIcon} alt="" />
                        </div>
                        <Textfield
                          type="text"
                          placeholder={
                            props.searchInputPlaceholder
                              ? props.searchInputPlaceholder
                              : `${t("common.search")}`
                          }
                          className="search"
                          onChange={(e) => setFilterTextSearch(e.target.value)}
                          value={filterTextSearch}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              onEnterTextFilter("input");
                            }
                          }}
                        />
                        {filterTextSearch && filterTextSearch.length > 0 && (
                          <CloseIcon
                            onClick={(e) => {
                              setFilterTextSearch("");
                              onEnterTextFilter("clearButton");
                            }}
                            className="pointer-cursor div-search"
                            sx={{ fontSize: "medium" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {props.addButton && props.buttonLabel === t("accounts.addAccount") && (
                  <>
                    <ButtonPrimary
                      onClick={(e) => {
                        props.headerButtonFunction &&
                          props.headerButtonFunction(true);
                      }}
                      icon={true}
                      buttonLabel={props.buttonLabel}
                    />
                  </>
                )}

                {props.addButton && props.buttonLabel === t("withholdings.distribute") && (
                  <>
                    <ButtonPrimary
                      onClick={(e) => {
                        props.headerButtonFunction &&
                          props.headerButtonFunction(true);
                      }}
                      className=" px-3 "
                      buttonLabel={props.buttonLabel}
                    />
                  </>
                )}

                {props.addButton && props.buttonLabel === t("accountUsersList.addAdmin") && (
                  <>
                    <ButtonPrimary
                      onClick={(e) => {
                        props.headerButtonFunction &&
                          props.headerButtonFunction(true);
                      }}
                      icon={true}
                      buttonLabel={props.buttonLabel}
                    />
                  </>
                )}

                {props.addButton && props.buttonLabel === t("adminUserList.addUser") && (
                  <>
                    <ButtonPrimary
                      onClick={(e) => {
                        props.headerButtonFunction &&
                          props.headerButtonFunction(true);
                      }}
                      icon={true}
                      buttonLabel={props.buttonLabel}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>;
      break;
  }

  return (
    <>{content}</>
  );
};

export default FilterSection;
