import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import logo from "../../assets/images/tovalogo.svg";
// import logo2 from '../../assets/images/tova_stack.png';
// import tovalogo3 from "../../assets/images/tovaicon3.svg";
// import success from "../../assets/images/success_tick.svg";
// import { checkIos } from '../../helpers/ios';

import getSymbolFromCurrency from "currency-symbol-map";
import { getHotelImage } from "../../services/tipService";

import "./style.css";
import "../Tip/style.css";
import { TIP_THEME_DEFAULT_COLOR, TIP_THEME_DEFAULT_TEXT_COLOR } from "../../config/env";
// import logoGoogle from "../../assets/images/google.svg";
// import logoTripadvisor from "../../assets/images/tripadvisor-logo.svg";

// import logoFB from '../../assets/images/facebook.svg';
// import logoInsta from '../../assets/images/instagram.svg';
// import logoTwitter from '../../assets/images/twitter.svg';
// import logoYoutube from '../../assets/images/youtube.svg';
// import logoApplePay from '../../assets/images/apple-pay.svg';

interface CustomizedState {
  designType: number;
  amount: string;
  hotel_logo: string;
  hotel_data: string;
  tipValue: string;
  accCode: string;
  psCode: string;
  psType: string;
  editBgColor: string;
  theme_text_color: string;
  message_title: string;
  message_description: string;
}

export const Success = (props: any) => {
  const { t } = useTranslation();
  const location = useLocation();
  const state = location.state as CustomizedState; // Type Casting, then you can get the params passed via router
  const {
    amount,
    hotel_logo,
    hotel_data,
    tipValue,
    accCode,
    psCode,
    psType,
  } = state; //reading state params
  const [currency, setCurrency] = useState<string>("");
  const [totalTipValue, setTotalTipValue] = useState<string>("");
  const [hotelLogo, setHotelLogo] = useState<string>(hotel_logo); //seting hotel logo
  const [hoteldata, setHoteldata] = useState<any>(hotel_data); //setting hotel data
  const [reviewSection, setReviewSection] = useState([])
  
  const [designType, setDesigntype] = useState(0);
  const [editBgColor, setEditBgColor] = useState<any>("");
  const [editHeaderColor, setEditHeaderColor] = useState<any>("");
  const [editTextColor, setEditTextColor] = useState<any>("");
  const [messageTitle, setMessageTitle] = useState("");
  const [messageDescription, setMessageDescription] = useState("");



   

  const isActiveCount = reviewSection.filter(
    (item) => item.isActive
  ).length;

  

  const [hotelJSONData, setHotelJSONData] = useState<any>();



  useEffect(() => {
    window.scrollTo(0, 0);
    const url: URL = new URL(window.location.href);
    const params: URLSearchParams = url.searchParams;
    console.log("amount: ", JSON.stringify(amount));
    console.log("hotel_logo: ", JSON.stringify(hotel_logo));
    setHotelJSONData(JSON.parse(JSON.stringify(hotel_data)));
    const curFromUrl = params.get("currency");
    let currencySymbol = "";
    if (curFromUrl !== undefined) {
      currencySymbol = getSymbolFromCurrency(curFromUrl || "") || "";
    }

    let amountPaid = amount;
    if (amountPaid != undefined) {
      let amountPaidFloat = parseFloat(amountPaid);
      setCurrency(
        `${String(currencySymbol)}` + `${amountPaidFloat.toFixed(2)}`
      );
    }

    let tipValuePaid = tipValue;
    if (tipValuePaid != undefined) {
      let tipValuePaidFloat = parseFloat(tipValuePaid);
      setTotalTipValue(
        `${String(currencySymbol)}` + `${tipValuePaidFloat.toFixed(2)}`
      );
    }

    let hotel_id = params.get("hotel_id");
    if (accCode != undefined || accCode != null) {
      getHotelImage(accCode, psCode, psType).then((res: any) => {
        if (res["status"] === 200) {
          if (res.data["logo"] != null) {
            setHotelLogo(res.data["logo"]);
          }
          
          setDesigntype(res.data.tipDesignType);
          setEditBgColor(res.data.tipBgColor?res.data.tipBgColor:TIP_THEME_DEFAULT_COLOR);
          setEditHeaderColor(res.data.headerBgColor?res.data.headerBgColor:TIP_THEME_DEFAULT_TEXT_COLOR);
          setEditTextColor(res.data.tipFontColor);
          setMessageTitle(res.data.reviewTitle);
          setMessageDescription(res.data.reviewBody);

          setReviewSection(res.data.reviewLinks.filter(
            item => item.isActive && item.link !== '' 
          ).sort((a, b) => a["order"] - b["order"]));
        }
      });
    }
    console.log("hotelJSONData: ", hotelJSONData);
  }, []);

  return (
    <>
      <div className="max-width-500 div-center-margin-auto ">
        <div className="bg-white">
          <div
            className="header-tip"
            style={{
              backgroundColor: designType == 1 ? editHeaderColor : "white",
            }}
          >
            {/* {hotelLogo === "null" || hotelLogo.length == 0 ? (
                        <div className="hotel_name mt-3px">
                          {hoteldata.name}
                        </div>
                      ) : (
                        <img
                          className="tipLogo"
                          src={hotelLogo}
                          alt={hoteldata.name}
                        />
                      )} */}

            <div className={`${designType !== 1 && "mt-4 "}`}>
            {hotelLogo === "null" || hotelLogo.length == 0 ? (
                        <div className={`hotel_name mt-3px ${designType == 3 && "mb-3 mt-2"}`} style={{color:(designType===1 && editHeaderColor !=="white" && editHeaderColor !=="#FFFFFF" && editHeaderColor !=="#ffffff")?'white':'black'}}>
                           {hoteldata.name}
                        </div>              // {hotelLogo === "null" || hotelLogo.length == 0 ? (
              //   <div className="hotel_name mt-3px">{hoteldata.name}</div>
              ) : (
                <img className="tipLogomain" src={hotelLogo} alt={hoteldata.name} />
              )}
            </div>
          </div>

          {designType !== 3 && (
            <>
              <div className="center w-100 flex-col">
                <h1
                  className="tip-review-heading mt-4"
                  style={{ color: editBgColor }}
                >
                  {messageTitle}
                </h1>
                <p className="tip-factor-text max-width-189px ">
                  {messageDescription}
                </p>
              </div>
            </>
          )}

          {designType == 3 && (
            <>
              <div className="px-3 w-100 mt-2">
                <div
                  className="card-backGround"
                  style={{ backgroundColor: editBgColor }}
                >
                  <h1
                    className="tip-review-heading mt-3"
                    style={{ color: editTextColor }}
                  >
                    {messageTitle}
                  </h1>
                  <p
                    className="tip-factor-text max-width-189px mb-3"
                    style={{ color: editTextColor }}
                  >
                    {messageDescription}
                  </p>
                </div>
              </div>
            </>
          )}

          <div className="px-3 mt-4">
          {/* <div className="content_container tipbg margin-top40 "> */}
            {/* <div className="box-bl margin-top35">
              <div className="box-b2">
                <div className="justify-center">
                  <label>
                    {" "}
                    <span className="amount-paid">
                      {t("tipSuccessPage.amountTipped")}
                    </span>
                  </label>
                </div>
                <div className="justify-center">
                  <label className="didact-30">${currency}</label>
                </div>
                <div className="justify-center">
                  <label>
                    {" "}
                    <span className="amount-paid">
                      {t("tipSuccessPage.amountCharged")} ${totalTipValue}
                    </span>
                  </label>
                </div>
              </div>
            </div> */}

            {/* <div className="mt-5 justify-center ">
              <div className="helv-nue-24">
                {t("tipSuccessPage.thankYou", { accountName: hoteldata.name })}
              </div>
            </div> */}
            {/* <div className="margin-top15 text-center helv-reg-16 div-center-85">
              {/* Your tip goes directly to the staff who clean this room.
              {/* Thank you for using {`${t("projectName")}`}!  
              Thank you for tipping the staff at {hoteldata.name}!
            </div> */}
            {/* <div className="margin-top40 text-center div-center-90">
                <button className="btnbg padding-tb w-90perc mb-1 btn-text  div-center-85">
                Leave us a review 
                {checkIos() != true && <span onClick={() => { window.open('https://play.google.com/store/apps/details?id=com.usetova','_blank')}}><img src={logoGoogle} alt="Google" className="margin-left-20px"/></span> }
                {checkIos() == true && <span onClick={() => { window.open('https://apps.apple.com/us/app/maid-easy/id1610376065','_blank')}}><img src={logoApplePay} alt="Apple Pay" className="margin-left-20px"/></span> }
                </button> 
            </div> */}

            {/* {hotelJSONData?.googleReviewLink &&
              hotelJSONData?.googleReviewLink.trim().length > 2 && (
                <div className="mt-38px text-center div-center-95">
                  <button
                    className="btnbg padding-tb w-95perc mb-1 btn-text review-button px-2 "
                    onClick={() => {
                      window.open(hotelJSONData.googleReviewLink, "_blank");
                    }}
                    title={`${t("tipSuccessPage.Google")}`}
                  >
                    {" "}
                    {t("tipSuccessPage.reviewGoogle")}{" "}
                    <span>
                      <img
                        src={logoGoogle}
                        alt={`${t("tipSuccessPage.Google")}`}
                        className="margin-left-20px"
                      />
                    </span>
                  </button>{" "}
                </div>
              )} */}

            {/* {hotelJSONData?.tripAdvisorLink &&
              hotelJSONData?.tripAdvisorLink.trim().length > 2 && (
                <div className="margin-top40 text-center div-center-95">
                  <button
                    className="btnbg padding-tb w-95perc mb-1 btn-text review-button"
                    onClick={() => {
                      window.open(hotelJSONData.tripAdvisorLink, "_blank");
                    }}
                    title={`${t("tipSuccessPage.Tripadvisor")}`}
                  >
                    {" "}
                    {t("tipSuccessPage.reviewTripadvisor")}{" "}
                    <span>
                      <img
                        src={logoTripadvisor}
                        alt={`${t("tipSuccessPage.Tripadvisor")}`}
                        className="margin-left-5px"
                        height={"40"}
                      />
                    </span>
                  </button>{" "}
                </div>
              )} */}

            <div className="reviewMainItemsBox">
              {reviewSection.map((item) => (
                <React.Fragment key={item['id']}>
                    <div
                     onClick={() => {
                      window.open(item.link, "_blank");
                    }}
                      className={`reviewMainItem ${
                        isActiveCount === 4 && "max-width-165px "
                      } ${isActiveCount === 1 && "mt-5 py-5"}`}
                      style={{
                        backgroundColor: item.backgroundColor,
                        color: item.titleColor,
                      }}
                    >
                      <img src={item['logo']} alt="" className="reviewMainItemLogo" />
                      <p className="reviewMainItemPara">{item['name']}</p>
                    </div>
                </React.Fragment>
              ))}
            </div>
           

            <div className="pt-5 pb-4 center">
              <p className="fontz">{t("tipPage.poweredBy")} {t("tipPage.useTova")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
