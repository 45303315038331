import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';

import { fetchDocumentPreview } from "../../services/qrCodesServices";
import { fetchOrderHistory, fetchSKUNames } from '../../services/qrCodesHistoryServices';

import { selectQRCodesYourOrdersSlice, resetQrCodeOrderHistoryData } from './QRCodesYourOrdersSlice';
import { selectQrcodesDataSlice } from "../QRCodes/QRCodesSlice"; 

import { DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD, DH_TEMPLATE_DESIGN_TYPE_POSTCARD, DH_TEMPLATE_DESIGN_TYPE_TABLETENT, QRCODE_ORDER_STATUS, QRCODE_ORDER_ITEM_STATUS } from '../../config/env_qrcodes';
import { ISKUNames, IVPDocumentPreview } from '../../models/qrCodeInterfaces';
import { getAdminUserRole } from '../../services/localStorage';
import { IAccListAllReq } from '../../models/accountsInterfaces';
import { fetchAllAccountList } from '../../services/headerService';

import { FilterAllAccountListSlice } from "../../components/FilterAccount/FilterAllAccountListSlice";  
import { IAllAccountList } from '../../models/allAccountListInterface';

interface IOrderHistory {
    creationDate: string;
    orderNumber: string;
    cartDetails: {
        cardType: string;
        name: string;
        documentRefUrl: string;
        groupType: string;
        id: number; 
        price: number; 
        projectId: string; 
        quantity: number; 
        sku: string;
        trackingUrl: string;
        vistaPrintStatus: string;
        vistaPrintUpdatedOn: string;
        previewUrl: string;
    }[];
    billAmount: string;
}

interface ICartIdImages {
    cartId: string;
    imageURL: string;
}

const QRCodesYourOrders = () => {
    const adminUserRole = getAdminUserRole();
    const localAccId = localStorage.getItem('filterAccountId');
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { allAccountList } = useAppSelector(FilterAllAccountListSlice);
    const { qrCodeOrderHistoryData, totalSize, skuNamesData, purchaseHistoryloading } = useAppSelector(selectQRCodesYourOrdersSlice);
    const rowsPerPage = 5; //ONLY FIVE ROWS REQUIRED HERE   
    const { documentPreviewRespItem } = useAppSelector(selectQrcodesDataSlice);

    const [stateSkuNamesData, setStateSkuNamesData] = useState<ISKUNames[]> ([]);

    const [stateCartData, setStateCartData] = useState<IOrderHistory[]>([]); 
    const [imageLoadCount, setImageLoadCount] = useState (0); 
    const [imageLoadedCartIds, setImageLoadedCartIds] = useState ([]); 
    const [cartIdImages, setCartIdImages] = useState<ICartIdImages[]> ([]); 

    const [currentPage, setcurrentPage] = useState(1); 
    const [listApiCalled, setListApiCalled] = useState(false); 

    const [accountValue, setAccountValue] = useState(localAccId);
    const [accountLabel, setAccountLabel] = useState("");
    const [accountsList, setAccountsList] = useState<IAllAccountList[]>([]);

   

    useEffect(() => {
        dispatch(fetchSKUNames({})); 
        setcurrentPage(1); 
        dispatch(resetQrCodeOrderHistoryData([])); 
        let apiData: IAccListAllReq = {
            searchString: "",
            userRole: Number(adminUserRole)
          }
          dispatch(fetchAllAccountList(apiData));
    }, []); 

    useEffect(() => {
        setStateSkuNamesData(skuNamesData); 
        // dispatch(fetchOrderHistory({ page: currentPage, numRowsPerPage: rowsPerPage }));
    }, [skuNamesData]); 

    useEffect(() => {
        if(allAccountList && allAccountList.length > 0) {
            setAccountsList(allAccountList); 
            let accObj = allAccountList.find(item => Number(item.value)==Number(localAccId)); 
            if(accObj && accObj?.value && Number(accObj.value) > 0 && accObj?.label) {
                setAccountValue(accObj.value);
                setAccountLabel(accObj.label); 
                dispatch(fetchOrderHistory({ accountId: accObj.value.toString(), page: currentPage, numRowsPerPage: rowsPerPage }));
            }       
        }
    },[allAccountList]); 

    useEffect(() => {
        if(localAccId && localAccId.length > 0) {
            setStateCartData([]); 
            let accObj = accountsList.find(item => Number(item.value)==Number(localAccId)); 
            if(accObj && accObj?.value && Number(accObj.value) > 0 && accObj?.label) {
                setAccountValue(accObj.value);
                setAccountLabel(accObj.label); 
                setcurrentPage(1); 
                dispatch(fetchOrderHistory({ accountId: accObj.value.toString(), page: 1, numRowsPerPage: rowsPerPage }));
            }       
        }
    },[localAccId]); 

    useEffect(() => {
        if(currentPage > 1) {
            dispatch(fetchOrderHistory({ accountId: accountValue, page: currentPage, numRowsPerPage: rowsPerPage }));
        }
    }, [currentPage]);

    const onScroll = () => {
        // Attach a scroll event listener to trigger loading more items when scrolling
        const handleScroll = () => {
            const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
            if (scrollTop + clientHeight >= scrollHeight - 100 && purchaseHistoryloading!="loading") {
                if (parseInt((currentPage*rowsPerPage),10) < totalSize) {
                    setcurrentPage((currentPage + 1)); 
                }
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }

    useEffect(() => {
        if(qrCodeOrderHistoryData && qrCodeOrderHistoryData.length > 0) {
            let currentCartData = stateCartData; 
            qrCodeOrderHistoryData.map(item => {
                let tmpObj = stateCartData.find(sItem => Number(sItem.id) == Number(item.id)); 
                if(tmpObj && Number(tmpObj.id) > 0) {
                    console.log(''); 
                }
                else {
                    currentCartData.push(item); 
                    item.cartDetails.map(cItem =>  {
                        if(cItem.documentRefUrl && cItem.documentRefUrl.length > 1) {
                            let preloadData : IVPDocumentPreview = {
                                cartId: cItem.id, 
                                documentRefUrl: cItem.documentRefUrl, 
                                sku: cItem.sku
                            }
                            dispatch(fetchDocumentPreview(preloadData)); 
                        }
                    })
                }
            });
            setStateCartData(currentCartData); 
            dispatch(resetQrCodeOrderHistoryData([])); 
        }
        
        setListApiCalled(true); 
        onScroll(); 
    }, [qrCodeOrderHistoryData]);

    

    useEffect(() => {
        let cartId = Number(documentPreviewRespItem.cartId);
        let documentUrl = documentPreviewRespItem.documentUrl;
        if(!imageLoadedCartIds.includes(Number(cartId))) {
            let tmpArr = imageLoadedCartIds;
            tmpArr.push(Number(cartId)); 
            let tmpCartImages = cartIdImages; 
            tmpCartImages.push({cartId: cartId, imageUrl: documentUrl}); 
            setImageLoadedCartIds(tmpArr); 
            setCartIdImages(tmpCartImages); 
            setImageLoadCount((imageLoadCount+1)); 
        }
    },[documentPreviewRespItem]);  

    const getImagePreviewURL = (cartId: number, imgCount: number) => {
        let tmpObj = cartIdImages.find(item => Number(item.cartId)==Number(cartId)); 
        if(tmpObj && tmpObj?.imageUrl && tmpObj.imageUrl.length > 1 && imgCount >= 0) {
            return tmpObj.imageUrl; 
        }
        else {
            return ""; 
        }
    }

    const getTemplateType = (templateType: string, quantity: number, itemSku:string) => {
        let dataObj = stateSkuNamesData.find(item => item.skuCode==itemSku);
        if(dataObj && dataObj.skuCode) {
            return (quantity.toString()+" "+dataObj.productName);
        }
        switch (Number(templateType)) {
            case DH_TEMPLATE_DESIGN_TYPE_POSTCARD: return (quantity.toString()+" "+(quantity!=1 ? t("qrcodesPage.postCards") : t("qrcodesPage.postCard")));
                break;
            case DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD: return (quantity.toString()+" "+(quantity!=1 ? t("qrcodesPage.businessCards") : t("qrcodesPage.businessCard"))); 
                break;
            case DH_TEMPLATE_DESIGN_TYPE_TABLETENT: return (quantity.toString()+" "+(quantity!=1 ? t("qrcodesPage.tableTents") : t("qrcodesPage.tableTent")));   
                break;
            default: return templateType;
                break;
        }
    }

    // let stat = 'AwaitingInventoryCheck'; 
    // <div>{QRCODE_ORDER_STATUS[stat]}</div> 

    const dummyFunction = () => {
        console.log('dummyFunction'); 
    }

    return (
        <div className="box-padding select-none">
            <div className="box">
                <div className='qr-checkout-nav'>
                    {accountLabel ? accountLabel+' '+t("QRCodesYourOrders.orders") : t("QRCodesYourOrders.orders")} 
                </div>
                
                {stateCartData.length > 0 ? stateCartData.map((data, index) => <>
                    <div className='space-between border border-8r mt-15px mb-23px'>
                        <div className='w-100'>
                            <div className='pl-37px pb-30px pt-22px flex-col gap-12px'>
                                <div>
                                    <h1 className='text-16px-600-black mb-5px'> {t("QRCodesYourOrders.orderedOn")} {dayjs(data.creationDate).format('DD MMM, YYYY hh:mm a')} </h1>
                                    <h2 className='text-22px-600-black mb-18px'>{t("QRCodesYourOrders.order")} {data.orderNumber}</h2>
                                </div>

                                {data.cartDetails.map(item => <div className='flex'>
                                    <div className="selected-qr-img min-width-90px"> 
                                        {imageLoadCount > 0 && getImagePreviewURL(item.id, imageLoadCount)!="" ? 
                                            <><img src={getImagePreviewURL(item.id, imageLoadCount)} alt="thumb" className="qr-template-img" /></> : <></>}
                                           
                                        {item.previewUrl && item.previewUrl.trim().length > 1 ? <img src={item.previewUrl} alt="thump" className="qr-template-img" /> : <></>}
                                    </div>
                                    <div className='pl-18px flex w-100 min-width-375px '>
                                        <div className='flex-col w-100 '>
                                            <span className='text-16px-600-black pb-14px'>
                                                {getTemplateType(item.cardType, item.quantity, item.sku)}</span>
                                            <span className='text-14px-500-black pb-10px'>{item.name} </span>
                                        </div>
                                    </div>

                                     <div className='pl-18px flex w-100 min-width-375px'>
                                        <div className='w-100'>
                                            <h1 className='text-16px-600-black mb-18px'>
                                                {QRCODE_ORDER_ITEM_STATUS[item.vistaPrintStatus] ? QRCODE_ORDER_ITEM_STATUS[item.vistaPrintStatus] : item.vistaPrintStatus} 
                                            </h1>
                                            <p className='text-14px-600-black mb-17px'>
                                                {item.vistaPrintUpdateOn && item.vistaPrintUpdateOn.length > 2 ? dayjs(item.vistaPrintUpdateOn).format('DD MMM, YYYY  hh:mm a') : ""}
                                            </p>
                                        </div> 
                                    </div>

                                    <div className='pl-18px flex w-100'>
                                        {item.trackingUrl && item.trackingUrl.length > 2 ? <button className=' btn-blueWhite-h36-r6-f16-600 px-15px' onClick={() => dummyFunction()}>
                                            {t("QRCodesYourOrders.trackPackage")}
                                        </button> : <>&nbsp;</>} 
                                    </div>

                                </div>)}

                                {/* <div className='w-100 mt-20px flex align-items-center'>
                                    <button className=' btn-blueWhite-h36-r6-f16-600 min-width-245px '>
                                     {t("QRCodesYourOrders.buyAgain")}
                                </button> </div>*/}
                            </div>
                        </div>

                        {/* <QRCodesYourOrdersAside
                        orderComplete={true}
                        buttonFunction={() => navigate('/qr-receipt')}
                    /> {t("QRCodesYourOrders.delivered")} */}
                        <div className='px-4 pt-21px max-width-292px w-100 border-left '>
                           
                            <div className="py-20px px-5px">
                                <div className=''>
                                    <h2 className='text-22px-600-black mb-19px'> {t("QRCodesPayment.paymentMethod")}</h2>
                                    <div className='flex align-items-center gap-2 mb-17px space-between'>
                                        {data?.paymentDetails?.logo && data.paymentDetails.logo.length > 2 ? <><img src={data.paymentDetails.logo} alt="" width={36} height={33} /> </> : <>{data.paymentDetails.card}</>}  

                                        {/* payment_details: card: date_time: expiry: last4: logo: */}
                                        
                                        <div className='flex-col'>
                                            <span className='text-12px-700-black'>{data.paymentDetails.card} ····{data.paymentDetails.last4} </span>
                                            <div className="space-between text-8px-400-black">
                                                <span>&nbsp;</span>
                                                <span >{data.paymentDetails.expiry}</span>
                                                {/* <span> 1:21 PM</span>
                                                <div>&nbsp;</div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex align-items-center gap-2 mb-17px space-between'>
                                        <p className='text-14px-500-black'>{t("QRCodesYourOrders.paidOn")} {dayjs(data.paymentDetails.dateTime).format('DD MMM, YYYY  hh:mm a')}</p>
                                        <div>&nbsp;</div>
                                    </div>
                                </div>
                                <div className=' flex-col max-width-292px w-100 mt-15px border-top'>

                                    {data.totalCartAmount > 0 || data.totalCartAmount > 0 ? <div className='text-14px-500-black space-between border-bottom pb-3 mt-20px'>
                                        <span>{t("QRCodeRightSidePanelPayment.total")}</span><span>{t("currency")}{data.totalCartAmount}</span>
                                    </div> : <></>}

                                    {data.shippingAmount && data.shippingAmount > 0 ? <div className='text-14px-500-black space-between border-bottom py-3'>
                                        <span>{t("QRCodeRightSidePanelPayment.shipping")}</span><span>{t("currency")}{data.shippingAmount}</span>
                                    </div> : <></>}

                                    {data.taxAmount && data.taxAmount > 0 ? <div className='text-14px-500-black space-between border-bottom py-3'>
                                        <span>{t("QRCodeRightSidePanelPayment.tax")}</span><span>{t("currency")}{data.taxAmount}</span>
                                    </div> : <></>} 

                                    {data.transactionAmount && data.transactionAmount > 0 ? <div className='text-14px-500-black space-between border-bottom py-3'>
                                        <span>{t("QRCodesYourOrders.transactionAmount")}</span><span>{t("currency")}{data.transactionAmount}</span>
                                    </div> : <></>}

                                    <div className='text-16px-600-black space-between  py-3'>
                                        <span>{t("QRCodesYourOrders.orderTotal")}</span><span>{t("currency")}{data.billAmount}</span>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>
                </>
                ) :
                    <div className="center my-4">
                        <div className="h-three col-2">
                            {listApiCalled && (!stateCartData || stateCartData.length==0) ? t("common.noDataAvailable") : "" }
                        </div>
                    </div>}
              

            </div>
        </div>
    )
}

export default QRCodesYourOrders