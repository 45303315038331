import React from 'react'
import { useTranslation } from 'react-i18next';

interface IProps {
    buttonLabelOne: string;
    buttonOneFunction: () => void;
    buttonLabelTwo?: string;
    buttonTwoFunction?: () => void;
}

const QRCodeRightSidePanelPayment: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className='px-4 pt-21px max-width-292px w-100 border-left min-height-66vh'>
            <div className=' flex-col'>
                <h1 className='text-22px-600-black pb-33px'>{t("QRCodeRightSidePanelPayment.orderSummary")}</h1>
                <div className='text-14px-500-black space-between border-bottom pb-3'>
                    <span>{t("QRCodeRightSidePanelPayment.total")}</span><span>$204.36</span>
                </div>
                <div className='text-14px-500-black space-between border-bottom py-3'>
                    <span>{t("QRCodeRightSidePanelPayment.shipping")}</span><span>FREE</span>
                </div>
                <div className='text-14px-500-black space-between border-bottom py-3'>
                    <span>{t("QRCodeRightSidePanelPayment.tax")}</span><span>$8.25</span>
                </div>
                <div className='text-16px-600-black space-between  py-3'>
                    <span>{t("QRCodeRightSidePanelPayment.subTotal")}</span><span>$212.61</span>
                </div>
                <button className='mt-21px btn-blueWhite-h36-r6-f16-600' onClick={props.buttonOneFunction}>
                    {props.buttonLabelOne}
                </button>
                {props.buttonLabelTwo?.length > 0 &&
                    <button onClick={props.buttonTwoFunction} className='mt-19px btn-whiteBlack-h36-r6-f14-500'>
                        {props.buttonLabelTwo}
                    </button>}
            </div>
        </div>
    )
}

export default QRCodeRightSidePanelPayment