import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../helpers/hook";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Store } from "react-notifications-component";
import getSymbolFromCurrency from "currency-symbol-map";

import yelloWarning from "../../assets/images/yellowrm.svg";

import Table from "../../components/Table";
import Header from "../../components/Header";
import Paginations from "../../components/Pagination";
import FilterSection from "../../components/FilterSection";
import { TipsTableHeaders, TipsTableHeadersWithoutTotalCharge } from "../../components/Table/TableHeaders";

import { ITipsRequestData } from "../../models/tipsInterfaces";

import { setNotification } from "../../services/apiService";
import { getAdminUserRole, setLastActivePageAPIData } from "../../services/localStorage";
import {
  fetchTipsSummary,
  fetchTips,
  exportTips,
  fetchStripePayMethods,
  performRefund,
} from "../../services/tipsListServices";

import {
  selectTipsListDataSlice,
  resetTips,
  resetTipsSummary,
  setCurrentPage,
} from "./tipsListDataSlice";

import { DATEFILTER_TODAY, PAGENAME_TIPS, PAGINATION_NUM_ROWS, USER_ROLE_ADMIN_ADMIN, USER_ROLE_ADMIN_SUPERADMIN } from "../../config/env";
import { checkActionPermission, PERM_TIPS_EXPORT, } from "../../config/permissions";
import { ERR_TIP_ALREADY_DISTRIBUTED, ERR_TIP_ALREADY_REFUNDED, } from "../../config/apiResponseCodes";

import { filterDateValues } from "../../utilities/dateFilterCalculation";

function TipsList() {

  // ************** TO SET DATE FILTER TO TODAY BY DEFAULT - START **************
  let dateObj = filterDateValues(DATEFILTER_TODAY);
  let fromMM = dateObj.fromDate.getMonth() + 1 < 10 ? "0" + (dateObj.fromDate.getMonth() + 1).toString()
      : (dateObj.fromDate.getMonth() + 1).toString();
  let fromDD = dateObj.fromDate.getDate() < 10 ? "0" + dateObj.fromDate.getDate().toString()
      : dateObj.fromDate.getDate().toString();
  let fromYY = dateObj.fromDate.getFullYear().toString();

  let toMM = dateObj.toDate.getMonth() + 1 < 10 ? "0" + (dateObj.toDate.getMonth() + 1).toString()
    : (dateObj.toDate.getMonth() + 1).toString();
  let toDD = dateObj.toDate.getDate() < 10 ? "0" + dateObj.toDate.getDate().toString()
      : dateObj.toDate.getDate().toString();
  let toYY = dateObj.toDate.getFullYear().toString();

  let defaultFromDate = fromYY + "-" + fromMM + "-" + fromDD;  
  let defaultToDate = toYY + "-" + toMM + "-" + toDD; 
  // ************** TO SET DATE FILTER TO TODAY BY DEFAULT - END ***************

  const prevAPIDataString = localStorage.getItem('lastActivePageAPIData');
  const prevAPIData = prevAPIDataString ? JSON.parse(prevAPIDataString) : {};
  const pageName = PAGENAME_TIPS; 
  const adminUserRole = getAdminUserRole();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    tipsList,
    totalSize,
    currentPage,
    tipsSummary,
    stripePayMethods,
    refundStatus,
  } = useAppSelector(selectTipsListDataSlice);

  const [bgShade, setBgShade] = useState(false);

  const [selectedAccountIds, setSelectedAccountIds] = useState("");
  const [selectedGroupIds, setSelectedGroupIds] = useState(""); 
  const [sortField, setSortField] = useState("");
  const [dateFilterType, setDateFilterType] = useState(DATEFILTER_TODAY);
  const [startDate, setStartDate] = useState<string>(dayjs(defaultFromDate).format(`YYYY-MM-DDTHH:mm:ssZZ`));
  const [endDate, setEndDate] = useState<string>(dayjs(defaultToDate).format(`YYYY-MM-DDT23:59:59ZZ`)); 
  const [applyFilter, setApplyFilter] = useState<number>(0);
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [selectedPayeeIds, setSelectedPayeeIds] = useState<string>("");
  const [selectedPayeeNames, setSelectedPayeeNames] = useState<string>("");
  const [selectedPayeeTypes, setSelectedPayeeTypes] = useState<string>("");
  const [textSearchFilter, setTextSearchFilter] = useState<string>("");
  const [refundCalled, setRefundCalled] = useState<boolean>(false);
  const [numRowsPerPage, setNumRowsPerPage] = useState(PAGINATION_NUM_ROWS); 

  const [initialAPICalled, setInitialAPICalled] = useState(false);

  // 'accept...' VARIABLES USED TO SET/RESET FILTERS ON REFRESH   
  const [acceptAccountFilterIds, setAcceptAccountFilterIds] = useState("");
  const [acceptGroupFilterData, setAcceptGroupFilterData] = useState(""); 
  const [acceptDateFilterData, setAcceptDateFilterData] = useState(""); 
  const [acceptPayeeFilterData, setAcceptPayeeFilterData] = useState(""); 
  const [acceptPayMethodFilterId, setAcceptPayMethodFilterId] = useState(""); 
  const [acceptSearchText, setAcceptSearchText] = useState(""); 

  React.useEffect(() => {
    setRefundCalled(false);
    dispatch(fetchStripePayMethods(""));
  }, []);

  React.useEffect(() => {
    // IF REFUND CALLED, THE 'refundCalled' VARIABLE SET TO 'error/success' AND THIS VALUE NOT CLEARED WHILE WE REVISIT THIS PAGE, SO REFUND SUCCESS/FAILED MESSAGE SHOWS AGAIN WHILE RELOADING/REVISIT THIS PAGE- TO PREVENT THIS THE STATE VARIABLE 'refundCalled' IS USED
    if (refundCalled) {
      if (refundStatus == "success") {
        Store.removeNotification("refund-modal");

        setNotification(
          t("projectName"),
          t("tipslist.refundSuccess"),
          "success",
          "refundStatus"
        )

        fetchTipsList();
      } else if (
        refundStatus == "error" ||
        refundStatus == ERR_TIP_ALREADY_REFUNDED ||
        refundStatus == ERR_TIP_ALREADY_DISTRIBUTED
      ) {
        Store.removeNotification("refund-modal");
        let msg = refundStatus == ERR_TIP_ALREADY_REFUNDED
          ? t("tipslist.tipAlreadyRefunded")
          : refundStatus == ERR_TIP_ALREADY_DISTRIBUTED
          ? t("tipslist.tipAlreadyDistributed")
          : t("tipslist.refundFailed"); 
        setNotification(
          t("projectName"),
          msg,
          "failed",
          "refundStatus"
        )

      } else {
        console.log("refundLoading:", refundStatus);
      }
    }
  }, [refundStatus]);

  const fetchTipsList = () => {
    if(!initialAPICalled && prevAPIData.pageName && prevAPIData.pageName==pageName && prevAPIData?.apiData?.hotel && prevAPIData.apiData.hotel.length > 0) { 
      dispatch(fetchTips(prevAPIData.apiData));
      dispatch(fetchTipsSummary(prevAPIData.apiData));

      dispatch(setCurrentPage(prevAPIData.apiData.page));
      setSelectedAccountIds(prevAPIData.apiData.hotel);
      setSelectedGroupIds(prevAPIData.apiData.group); 
      setSortField(prevAPIData.apiData.sort);
      setDateFilterType(prevAPIData.apiData.dateFilterType);
      setStartDate(prevAPIData.apiData.start);
      setEndDate(prevAPIData.apiData.end);
      setPaymentMethod(prevAPIData.apiData.paymethod);
      setSelectedPayeeIds(prevAPIData.apiData.payee);
      setSelectedPayeeTypes(prevAPIData.apiData.payeeType); 
      setSelectedPayeeNames(prevAPIData.apiData.payeeNames);
      setTextSearchFilter(prevAPIData.apiData.search);
      setNumRowsPerPage(prevAPIData.apiData.numRowsPerPage); 
      setRefundCalled(false);

      if(prevAPIData.apiData.hotel && prevAPIData.apiData.hotel.trim().length > 0) {
        setAcceptAccountFilterIds(prevAPIData.apiData.hotel); 
      }
      if(prevAPIData.apiData.group && prevAPIData.apiData.group.trim().length > 0) {
        setAcceptGroupFilterData(prevAPIData.apiData.group); 
      }
      if(prevAPIData.apiData.search && prevAPIData.apiData.search.trim().length > 0) {
        setAcceptSearchText(prevAPIData.apiData.search); 
      }
      if(prevAPIData.apiData.dateFilterType && prevAPIData.apiData.dateFilterType.trim().length > 0) {
        setAcceptDateFilterData(prevAPIData.apiData.dateFilterType); 
      }  
      if(prevAPIData.apiData.paymethod && prevAPIData.apiData.paymethod.trim().length > 0) {
        setAcceptPayMethodFilterId(prevAPIData.apiData.paymethod); 
      } 

      setTimeout(() => {
        if(prevAPIData.apiData.payee && prevAPIData.apiData.payee.trim().length > 0 && prevAPIData.apiData.payeeNames && prevAPIData.apiData.payeeNames.trim().length > 0) {
          setAcceptPayeeFilterData(prevAPIData.apiData.payee+':~~:'+prevAPIData.apiData.payeeNames); 
        } 
        setInitialAPICalled(true);
      }, 1250);
      
    }
    else { 
      let accountIds = selectedAccountIds.replace("all,", "");
      if (selectedAccountIds.length > 0) {
        if (currentPage > 0 && accountIds && accountIds.length > 0) {
          const req: ITipsRequestData = {
            page: currentPage,
            numRowsPerPage: numRowsPerPage,
            sort: sortField,
            hotel: accountIds,
            group: selectedGroupIds=="clearOnly"?"":selectedGroupIds, 
            dateFilterType: dateFilterType, 
            start: startDate,
            end: endDate,
            search: textSearchFilter.trim(),
            payee: selectedPayeeIds,
            payeeType: selectedPayeeTypes, 
            payeeNames: selectedPayeeNames,
            paymethod: paymentMethod,
            userRole: Number(adminUserRole), 
          };
          setAcceptDateFilterData(DATEFILTER_TODAY);
          dispatch(fetchTips(req));
          dispatch(fetchTipsSummary(req));
          setLastActivePageAPIData({pageName:pageName, apiData:req}); 
          setInitialAPICalled(true);
        }
      } else if (selectedAccountIds.length == 0) {
        dispatch(
          resetTipsSummary({
            paymentCommission: "",
            total: 0,
            totalCommission: 0,
            totalCommissionCurrency: "",
            totalCurrency: "",
          })
        );
        dispatch(resetTips([]));
      }
    }
  };

  const onExportButton = () => {
    let accountIds = selectedAccountIds.replace("all,", "");
    const req: ITipsRequestData = {
      page: currentPage,
      numRowsPerPage: numRowsPerPage,
      sort: sortField,
      hotel: accountIds,
      group: selectedGroupIds=="clearOnly"?"":selectedGroupIds,  
      dateFilterType: dateFilterType, 
      start: startDate,
      end: endDate,
      search: textSearchFilter.trim(),
      payee: selectedPayeeIds,
      payeeType: selectedPayeeTypes, 
      payeeNames: selectedPayeeNames,
      paymethod: paymentMethod,
      userRole: Number(adminUserRole), 
    };
    dispatch(exportTips(req));
  };

  React.useEffect(() => {
    fetchTipsList();
  }, [
    currentPage,
    sortField
  ]);

  React.useEffect(() => {
    if (currentPage != 1) {
      acceptPage(1);
    } else {
      fetchTipsList();
    }
  }, [selectedAccountIds, textSearchFilter, numRowsPerPage, selectedPayeeIds, applyFilter]); 

  React.useEffect(() => {
    if(selectedGroupIds=="clearOnly") {
      //DO NOTHING 
    }
    else {
      if (currentPage != 1) {
        acceptPage(1);
      } else {
        fetchTipsList();
      }
    }
  }, [selectedGroupIds]);

  const handleSort = (sortField: string) => {
    setSortField(sortField);
  };

  const acceptPage = (e: any) => {
    dispatch(setCurrentPage(e));
  };

  const setPayeeIds = (payeeIds: string) => {
    setSelectedPayeeIds(payeeIds);
  }; 

  const setPayeeTypes = (payeeTypes: string) => {
    setSelectedPayeeTypes(payeeTypes);
  }; 

  const setPayeeNames = (payeeNames: string) => {
    setSelectedPayeeNames(payeeNames);
  };

  const setGroupIdsFilter = (groupIds:string) => {
    setSelectedGroupIds(groupIds);
  }

  const setDateFilterDatesType = (fromDate: string, toDate: string, filterType: string) => {
    if (fromDate.trim().length > 1) {
      setStartDate(dayjs(fromDate).format(`YYYY-MM-DDTHH:mm:ssZZ`));
    } else {
      setStartDate(fromDate);
    }
    if (toDate.trim().length > 1) {
      setEndDate(dayjs(toDate).format(`YYYY-MM-DDT23:59:59ZZ`));
    } else {
      setEndDate(toDate);
    }
    setDateFilterType(filterType); 
    setApplyFilter(applyFilter + 1);
  };

  const setPaymentMethodFilter = (payMethod: string) => {
    setPaymentMethod(payMethod);
    setApplyFilter(applyFilter + 1);
  };

  const clearFilters = () => {
    setSortField("");
    setDateFilterType(DATEFILTER_TODAY);
    setStartDate(dayjs(defaultFromDate).format(`YYYY-MM-DDTHH:mm:ssZZ`));
    setEndDate(dayjs(defaultToDate).format(`YYYY-MM-DDT23:59:59ZZ`));
    acceptPage(1);
    setTextSearchFilter("");
    setSelectedPayeeIds(""); 
    setSelectedPayeeTypes("");
    setSelectedPayeeNames(""); 
    setSelectedGroupIds(""); 
    setNumRowsPerPage(PAGINATION_NUM_ROWS); 
    setApplyFilter(applyFilter + 1);
    // setSelectedAccountIds(localStorageIds ? localStorageIds : '');
  };

  const onClickTableOption = (actionType: string, id: string): void => {
    if (actionType === "refundTip") {
      let gp = tipsList.find((option) => option.id === parseInt(id, 10));
      if (gp != undefined) {
        if (gp.refundable !== false) {
          setBgShade(true);
          Store.addNotification({
            title: "Refund",
            id: "refund-modal",
            content: (
              <div className="idproof-modal div-center text-center w-60perc relative">
                <div className="page-heading">
                  <img src={yelloWarning} alt="Warning" />
                </div>
                <div className="refund-flex-center">
                  {t("tipslist.confirmPerformRefund", {
                    currency: getSymbolFromCurrency(gp.amountCurrency),
                    amount: gp.amount,
                    payer: gp.payer,
                  })}
                </div>
                <div className="refund-margin">&nbsp;</div>
                <div className="flex w-100">
                  <button
                    type="button"
                    className="cancelbtn w-100"
                    onClick={() => {
                      Store.removeNotification("refund-modal");
                      setBgShade(false);
                    }}
                  >
                    {t("common.cancel")}
                  </button>
                  <button
                    type="button"
                    className="cancelbtn bg-blue text-white w-100  ml-20px"
                    onClick={() => {
                      Store.removeNotification("refund-modal");
                      setBgShade(false);
                      setRefundCalled(true);
                      dispatch(performRefund(gp.id));
                    }}
                  >
                    <span className="">{t("tipslist.refundTip")}</span>
                  </button>
                </div>
              </div>
            ),
            type: "default",
            container: "center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 1000000000,
              click: false,
              touch: false,
            },
          });
        }
      }
    }
  };

  return (
    <div>
      <div className={bgShade ? "rnc_bg" : "hide"}></div>
      <div className="box-padding">
        <div className="">
          <Header
            tips={true}
            tipsSummary={tipsSummary}
            addButton={false}
          />
          <FilterSection
            headind={`${t("navigation.payments")}`}
            filterAccount={true}
            filterGroup={true}
            filterDate={true}
            filterStatus={false}
            filterPayee={true}
            filterPayMethod={true}
            setSelectedAccountIds={setSelectedAccountIds}
            setGroupIdsFilter={setGroupIdsFilter}
            setDateFilterDatesType={setDateFilterDatesType}
            parentPage={PAGENAME_TIPS} 
            stripePayMethods={stripePayMethods}
            setPaymentMethodFilter={setPaymentMethodFilter}
            setPayeeTypes={setPayeeTypes} 
            setPayeeIds={setPayeeIds}
            setPayeeNames={setPayeeNames}
            clearFilters={clearFilters} 
            searchInput={true}
            setTextSearchFilter={setTextSearchFilter}
            searchInputPlaceholder={`${t("tipslist.searchTips")}`}

            // 'accept...' VARIABLES USED TO SET/RESET FILTERS ON PAGE REFRESH 
            acceptAccountFilterIds={acceptAccountFilterIds}
            acceptDateFilterData={acceptDateFilterData}
            acceptSearchText={acceptSearchText}
            acceptPayMethodFilterId={acceptPayMethodFilterId} 
            acceptPayeeFilterData={acceptPayeeFilterData} 
            acceptGroupFilterData={acceptGroupFilterData} 
          />


          <div className="table-box">
            <Table
              titles={(Number(adminUserRole) === USER_ROLE_ADMIN_SUPERADMIN ||
                Number(adminUserRole) === USER_ROLE_ADMIN_ADMIN)?TipsTableHeaders():TipsTableHeadersWithoutTotalCharge()}
              data={tipsList}
              sortedFieldName={sortField}
              sortedOrder={"-"}
              handleSort={handleSort}
              onClickTableOption={onClickTableOption}
            />

            {totalSize > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPageCount={
                  totalSize
                    ? totalSize < numRowsPerPage
                      ? 1
                      : totalSize % numRowsPerPage > 0
                      ? parseInt((totalSize / numRowsPerPage).toString(), 10) +
                        1
                      : parseInt((totalSize / numRowsPerPage).toString(), 10)
                    : 0
                }
                totalRows={totalSize}
                isTableLoading={false}
                setCurrentPage={acceptPage}
                setNumRowsPerPage={setNumRowsPerPage}
                recordsTypeName={`${t("common.transactions")}`} 
                exportButton={
                  (checkActionPermission(adminUserRole, PERM_TIPS_EXPORT) &&
                  totalSize > 0) ? true : false }
                onExportButton={onExportButton}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TipsList;
