import React from "react";
import { Col, Row } from "react-bootstrap";
import tova from "../../assets/images/tova-logo-web.png";
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";


const UrlNotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="overflow-hide">
      <Row className="frame">
        <Row className="max-w-1488px center  px-0 ml-10px">
          <Col
            xs={12}
            md={12}
            lg={7}
            className="flex-col center gap-5   ps-0"
          >
            <div className="w-100 center"><img src={tova} alt="tova" height={"32px"} width={"auto"} /></div>
            <h1 className="text-center">{t("pageNotFound")}</h1>
          </Col>
        </Row>
      </Row>
      <div className="mb-1"> &nbsp;</div>
      <Footer />
    </div>
  )
}

export default UrlNotFound