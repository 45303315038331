import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import tovalogo3 from "../../../assets/images/tovaicon3.svg";
import accphone from "../../../assets/images/acc-phone.svg";
import accmail from "../../../assets/images/acc-mail.svg";

function Nav() {
  const { t, i18n } = useTranslation();  

  return (
    <div className="acc-nav">
      <div className="space-between w-100 px-5">
        <img src={tovalogo3} alt={`${t("navigation.tovaLogo")}`} />

        <div className="flex-end gap-3 center ">
          <div className="space-between w-100 center">
            <div className="flex-col gap-1 border-start ps-3">
                <div className="acc-font gap-2"><img src={accphone} alt="" />{t('accNavigation.phoneInfo1')} &nbsp;&nbsp;&nbsp;{t('accNavigation.phoneInfo2')}</div>
                <div className="acc-font gap-2"><img src={accmail} alt="" />{t('accNavigation.mailInfo')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Nav);
