import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import arrow from '../../assets/images/qrDownArrow.svg';
import imgBack from "../../assets/images/back.svg";

import { IAccListAllReq } from '../../models/accountsInterfaces';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { selectQrcodesDataSlice, changeQrCodePageAccountId } from './QRCodesSlice';
import { selectCommonSlice } from "../../pages/CommonSlice/commonSlice"; 

import { getAdminUserRole } from '../../services/localStorage';
import { fetchAccountGroupsListQR, fetchAccountListQR, fetchAccountStaffListQR } from '../../services/qrCodesServices';

import { QRCODE_FOR_GROUP, QRCODE_FOR_STAFF } from "../../config/env_qrcodes";

interface IProps {
    showCartSelection?: boolean; 
    showQrCodes?: () => void;
}

const QRNavbar: React.FC<IProps> = (props) => { 
    const localAccId = localStorage.getItem('filterAccountId');
    const maxWords = 22;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const adminUserRole = getAdminUserRole();
    const { accountList, poolStaffSelection, selectedPoolStaffId, selectedPoolStaffName } = useAppSelector(selectQrcodesDataSlice); 
    const { reloadGroupSubMenu } = useAppSelector(selectCommonSlice);

    const [accountLabel, setAccountLabel] = useState("");
    const [accountValue, setAccountValue] = useState("");
    const [options, setOptions] = useState(accountList);

    const [poolStaff, setPoolStaff] = useState(poolStaffSelection); 
    const [poolStaffName, setPoolStaffName] = useState(selectedPoolStaffName);

    //TO FETCH ALL ACCOUNT
    useEffect(() => {
        let apiData: IAccListAllReq = {
            searchString: "",
            userRole: Number(adminUserRole)
        }
        dispatch(fetchAccountListQR(apiData));
    }, []);

    useEffect(() => {
        if(accountValue && accountValue.trim().length > 0 && Number(localAccId)!=Number(accountValue)) {
            dispatch(changeQrCodePageAccountId(accountValue));
            setPoolStaff(QRCODE_FOR_GROUP); 
            setPoolStaffName(""); 
        }
    },[accountValue]);

    useEffect(() => {
        setPoolStaff(poolStaffSelection);
    },[poolStaffSelection]);

    useEffect(() => {
        if(selectedPoolStaffId=="all") {
            setPoolStaffName(t("statusFilter.all")); 
        }
    }, [selectedPoolStaffId]);

    useEffect(() => {
        setPoolStaffName(selectedPoolStaffName);
    },[selectedPoolStaffName]);

    useEffect(() => {
        if (reloadGroupSubMenu > 0 && accountValue) {
            dispatch(fetchAccountGroupsListQR(
                {
                    accountId: accountValue.toString(),
                    userRole: Number(adminUserRole),
                }
            ));
        }
    }, [reloadGroupSubMenu]);

    useEffect(() => {
        if (accountList && accountList.length > 0 && localAccId && Number(localAccId) > 0) {
            setOptions(accountList);
            const matchingAccount = accountList.find(item => item.value === localAccId);
            // let accTempValue = accountList && accountList[0]?.value && accountList[0].value;
            let accTempValue = matchingAccount ? matchingAccount.value : accountList && accountList[0]?.value && accountList[0].value;
            let accTempLabel = matchingAccount ? matchingAccount.label : accountList && accountList[0]?.label && accountList[0].label;
            handleClick (accTempLabel, accTempValue); 
        }
    }, [accountList, localAccId]);

    const handleClick = (accName, accValue) => {
        setAccountLabel(accName);
        setAccountValue(accValue);

        dispatch(fetchAccountGroupsListQR(
            {
                accountId: accValue.toString(),
                userRole: Number(adminUserRole),
            }
        ));
        dispatch(
            fetchAccountStaffListQR({
                account_id: accValue.toString(),
                name: "",
            }
            ));
    }

    const onClickReturn = () => {
        props?.showQrCodes(); 
    }

    return (
        <>
        <div className='p-4 border-bottom left-center  '>
            <div className=" ">
                {props?.showCartSelection && props.showCartSelection===true && <div className="returnAcc pointer left-center mt-4px min-width-225px" onClick={() => onClickReturn()}>
                    <img src={imgBack} alt="back" height={"16px"} className="me-1" />
                    {t("qrcodesPage.returntoQRCodes")}
                </div>}
                {/* gap-5 min-width-245px <div className='qr-nav-drop-down'>
                    <div className='qr-extra-space'></div>
                    <div className='qr-nav-input pe-2'>
                        {accountLabel.length > maxWords ? (
                            <>
                                {accountLabel.slice(0, maxWords) + '...'}
                            </>
                        ) : (
                            accountLabel
                        )}
                    </div>

                    <div className='dropdown-list'>
                        <ul className=" multiselect  p-0px mb-0 ">
                            {options.map(option => (
                                <li
                                    key={option.value}
                                    className={`${option.value === accountValue && option.label === accountLabel && " text-blue "} qr-dropdown-option`}
                                    onClick={() => handleClick(option.label, option.value)}
                                >
                                    {option.label}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <img src={arrow} alt="arrow" loading='lazy' decoding='async' height={10} width={16} className='pointer' />
                </div> */}
            </div>
            <div className='qr-nav-content'>
                <span className='order-span-text ml-15px'>{t("QRNavbar.orderingCardsFor")} &nbsp; </span> 
                <span className='acc-span-text'>{accountLabel} </span> 
                <span className='acc-span-text'>&nbsp; {poolStaff==QRCODE_FOR_GROUP ? (' / ' + t("qrSidePanel.poolsNormal")) : poolStaff==QRCODE_FOR_STAFF ? (' / ' + t("qrSidePanel.staffNormal")) : "" }</span>
                {poolStaffName && poolStaffName.trim().length > 0 && <span className='pool-span-text'>&nbsp; / {poolStaffName}</span>}
            </div>  
        </div>
        </>
    )
}

export default QRNavbar