import { useTranslation } from "react-i18next";

export const AccountHeadings = () => {
  const { t } = useTranslation();
  return [
    {
      title: 'serialNumber',
      type: 'serial',
      display_title: t("accounts.fieldSerialNumber"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'serial_number',
      onClickable:false,
    },
    {
      title: 'hotelName',
      type: 'text',
      display_title: t("accounts.fieldAccount"),
      sortBy: true,
      sortDirection: 'asc',
      sortTitle: 'hotel_name',
      onClickable:true,
    },
    {
      title: 'tip',
      type: 'money',
      display_title: t("accounts.fieldTip"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'tip',
    },
    {
      title: 'creationDate',
      type: 'dateCenter',
      display_title: t("accounts.fieldDateAdded"),
      dateTimeFormat: 'MM/DD/YYYY',
      sortBy: true,
      sortDirection: 'asc',
      sortTitle: 'creation_date',
    },
    {
      title: 'isActive',
      type: 'status',
      display_title: t("accounts.fieldStatus"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'status',
    },
    {
      title: 'staffCount',
      type: 'staffCountView',
      display_title: t("accounts.fieldStaff"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'staff',
    },
    {
      title: 'actionMenu',
      type: 'accountEdit',
      display_title: "",
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    }
  ];

}

export const StaffTableHeaders = () => {
  const { t } = useTranslation();
  return [
    {
      title: 'staffName',
      type: 'text',
      display_title: t("stafflist.fieldStaff"),
      sortBy: true,
      sortDirection: 'asc',
      sortTitle: 'full_name',      
      onClickable:true,
    },
    {
      title: 'hotelName',
      type: 'text',
      display_title: t("stafflist.fieldAccount"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'staff_hotel',
    },
    {
      title: 'mobileNumber',
      type: 'text',
      display_title: t("accountUsersList.fieldPhone"), 
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'mobile_number', 
    },
    {
      title: 'joiningDate',
      type: 'date',
      display_title: t("stafflist.fieldDateAdded"),
      dateTimeFormat: 'MM/DD/YYYY',
      sortBy: true,
      sortDirection: 'asc',
      sortTitle: 'joining_date',
    }, 
    {
      title: 'documentVerified',
      type: 'status',
      display_title: t("stafflist.fieldDocVerified"),
      sortBy: true,
      sortDirection: 'asc',
      sortTitle: 'document_verified',
    },
    {
      title: 'staffStatus',
      type: 'status',
      display_title: t("stafflist.fieldStatus"),
      sortBy: true,
      sortDirection: 'asc',
      sortTitle: 'staff_status',
    },
    {
      title: 'lastPayout',
      type: 'money',
      display_title: t("stafflist.fieldLastPayout"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'lastPayout',
    },
    {
      title: 'totalEarnings',
      type: 'money',
      display_title: t("stafflist.fieldTips", {tipsFilterType:t("common.allTime")}),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'tip',
    },
    {
      title: 'actionMenu',
      type: 'actionDeleteStaff',
      display_title: ' ',
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    }
  ]
}

export const AccountUsersListTableHeader = () => {
  const { t } = useTranslation();
  return [

    {
      title: 'fullName',
      type: 'text',
      display_title: t("accountUsersList.fieldName"),
      sortBy: true,
      sortDirection: 'asc',
      sortTitle: 'staff_name',
      onClickable:true,
    },
    {
      title: 'email',
      type: 'text',
      display_title: t("accountUsersList.fieldEmail"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'email',
    },
    {
      title: 'mobile',
      type: 'text',
      display_title: t("accountUsersList.fieldPhone"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'mobile',
    },
    {
      title: 'position',
      type: 'text',
      display_title: t("accountUsersList.fieldPosition"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'position',
    },
    { 
      title: 'hotels',
      type: 'text',
      display_title: t("accountUsersList.fieldAccounts"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    },
    {
      title: 'role',
      type: 'text',
      display_title: t("accountUsersList.fieldRole"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'role',
    },
    {
      title: 'lastLogin',
      type: 'date',
      display_title: t("accountUsersList.fieldLastLogin"),
      dateTimeFormat: 'MM/DD/YYYY hh:mm A',
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'last_login',
    },
    {
      title: 'isActive',
      type: 'status',
      display_title: t("accountUsersList.fieldStatus"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'status',
    },
    {
      title: 'actionMenu',
      type: 'actionDeleteUsers',
      display_title: ' ',
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    }
  ];
}

export const AdminUsersTableHeader = () => {
  const { t } = useTranslation();
  return [
    {
      title: 'fullName',
      type: 'text',
      display_title: t("adminUserList.fieldName"),
      sortBy: true,
      sortDirection: 'asc',
      sortTitle: 'full_name',
      onClickable:true,
    },
    {
      title: 'email',
      type: 'text',
      display_title: t("adminUserList.fieldEmail"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'email',
    },
    {
      title: 'mobile',
      type: 'text',
      display_title: t("adminUserList.fieldPhone"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'mobile',
    },
    {
      title: 'hotels',
      type: 'text',
      display_title: t("adminUserList.fieldAccounts"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'account',
    },
    {
      title: 'role',
      type: 'text',
      display_title: t("adminUserList.fieldRole"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'role',
    },
    {
      title: 'position',
      type: 'text',
      display_title: t("adminUserList.fieldPosition"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'position',
    },
    {
      title: 'lastLogin',
      type: 'date',
      display_title: 'Last Login',
      sortBy: false,
      sortDirection: 'asc',
      dateTimeFormat: 'MM/DD/YYYY hh:mm A',
      customClass: '',
    },
    {
      title: 'isActive',
      type: 'status',
      display_title: t("adminUserList.fieldStatus"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'status',
    },
    {
      title: 'actionMenu',
      type: 'actionDeleteAdminUsers',
      display_title: ' ',
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    }
  ];
}

export const TipsTableHeaders = () => {
  const { t } = useTranslation();
  return [
    {
      title: 'transactionDate',
      type: 'date',
      display_title: t("tipslist.fieldDate"),
      dateTimeFormat: 'MM/DD/YYYY, hh:mm A',
      sortBy: true,
      sortDirection: 'asc',
      sortTitle: 'transaction_date',
    },
    {
      title: 'payer',
      type: 'text',
      display_title: t("tipslist.fieldPayer"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'payer',
    },
    {
      title: 'account',
      type: 'text',
      display_title: t("tipslist.fieldAccount"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'account',
    },
    {
      title: 'roomNo',
      type: 'text',
      display_title: t("tipslist.fieldRoomNo"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'roomNo',
    },
    {
      title: 'poolName',
      type: 'text',
      display_title: t("tipslist.fieldGroup"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    },
    {
      title: 'payee',
      type: 'text',
      display_title: t("tipslist.fieldPayee"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'payer',
    },
    {
      title: 'amount',
      type: 'money',
      display_title: t("tipslist.fieldTotalCharged"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'amount',
    },
    {
      title: 'netTip',
      type: 'money',
      display_title: t("tipslist.fieldNetTip"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'net_tip',
    },
    {
      title: 'transactionId',
      type: 'transactionId-btn',
      display_title: t("tipslist.fieldTransactionId"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    },     ,
    {
      title: 'actionMenuRefund',
      type: 'actionRefundTip',
      display_title: "",
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    }
  ]
}

export const TipsTableHeadersWithoutTotalCharge = () => {
  const { t } = useTranslation();
  return [
    {
      title: 'transactionDate',
      type: 'date',
      display_title: t("tipslist.fieldDate"),
      dateTimeFormat: 'MM/DD/YYYY, hh:mm A',
      sortBy: true,
      sortDirection: 'asc',
      sortTitle: 'transaction_date',
    },
    {
      title: 'payer',
      type: 'text',
      display_title: t("tipslist.fieldPayer"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'payer',
    },
    {
      title: 'account',
      type: 'text',
      display_title: t("tipslist.fieldAccount"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'account',
    },
    
    {
      title: 'roomNo',
      type: 'text',
      display_title: t("tipslist.fieldRoomNo"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'roomNo',
    },

    {
      title: 'poolName',
      type: 'text',
      display_title: t("tipslist.fieldGroup"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    },
    {
      title: 'payee',
      type: 'text',
      display_title: t("tipslist.fieldPayee"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'payer',
    },
    {
      title: 'netTip',
      type: 'money',
      display_title: t("tipslist.fieldNetTip"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'net_tip',
    },
    {
      title: 'transactionId',
      type: 'transactionId-btn',
      display_title: t("tipslist.fieldTransactionId"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    },     ,
    {
      title: 'actionMenuRefund',
      type: 'actionRefundTip',
      display_title: "",
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    }
  ]
}

export const PaymentsTableHeaders = () => {
  const { t } = useTranslation();
  return [
    {
      title: '',
      type: 'manageButton',
      display_title: "",
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    },
    {
      title: 'transactionDate',
      type: 'date',
      display_title: t("paymentslist.fieldDate"),
      dateTimeFormat: 'MM/DD/YYYY, hh:mm A',
      sortBy: true,
      sortDirection: 'asc',
      sortTitle: 'transaction_date',
    },
    {
      title: 'payee',
      type: 'text',
      display_title: t("paymentslist.fieldPayee"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'payer',
    },
    {
      title: 'poolName',
      type: 'text',
      display_title: t("paymentslist.fieldGroup"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    },
    {
      title: 'account',
      type: 'text',
      display_title: t("paymentslist.fieldAccount"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'account',
    },
    {
      title: 'payoutAmount',
      type: 'money',
      display_title: t("paymentslist.fieldTotalPayout"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'payer',
    }, 
    {
      title: 'payoutFrequency',
      type: 'text',
      display_title: t("paymentslist.fieldPayoutFrequency"),
      sortBy: false,
      sortDirection: '',
      sortTitle: '',
    },
    {
      title: 'paymentStatus',
      type: 'status',
      display_title: t("paymentslist.fieldStatus"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'payer',
    },
    {
      title: 'transactionId',
      type: 'transactionId-btn',
      display_title: t("paymentslist.fieldTransactionId"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'transaction_id',
    }
  ]
}

export const CommissionsTableHeaders = () => {
  const { t } = useTranslation();
  return [
    {
      title: 'transactionDate',
      type: 'date',
      display_title: t("commissionslist.fieldDateTime"),
      dateTimeFormat: 'MM/DD/YYYY, hh:mm A',
      sortBy: true,
      sortDirection: 'asc',
      sortTitle: 'transaction_date',
    },
    {
      title: 'account',
      type: 'text',
      display_title: t("commissionslist.fieldAccount"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'account',
    },
    {
      title: 'tipReceived',
      type: 'money',
      display_title: t("commissionslist.fieldTipsReceived"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    },
    {
      title: 'poolName',
      type: 'text',
      display_title: t("commissionslist.fieldGroup"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    },
    {
      title: 'paymentStatus',
      type: 'status',
      display_title: t("commissionslist.fieldStatus"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    },
    {
      title: 'commissionPercentage',
      type: 'textCenter',
      display_title: t("commissionslist.fieldCommPercentage"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    },
    {
      title: 'amountDistributed',
      type: 'money',
      display_title: t("commissionslist.fieldAmountDistributed"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    },
    {
      title: 'stripeFee',
      type: 'money',
      display_title: t("commissionslist.totalStripeFee"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    },
    {
      title: 'platformFee',
      type: 'money',
      display_title: t("commissionslist.totalPlatformFee"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    },
    {
      title: 'staffPayout',
      type: 'textCenter',
      display_title: t("commissionslist.totalStaffPayments"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: '',
    }
  ]
}


export const WithholdingsTableHeaders = () => {
  const { t } = useTranslation();
  return [
    {
      title: 'year',
      type: 'text',
      display_title: t("withholdings.fieldYear"),
      dateTimeFormat: 'YYYY',
      sortBy: true,
      sortDirection: 'asc',
      sortTitle: 'year',
    },
    {
      title: 'payee',
      type: 'text',
      display_title: t("withholdings.fieldfullName"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'payee',
    },
    {
      title: 'account',
      type: 'text',
      display_title: t("withholdings.fieldAccount"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'account',
    },
    {
      title: 'amount',
      type: 'money',
      display_title: t("withholdings.fieldWithholdings"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'amount',
    },
    {
      title: 'paymentStatus',
      type: 'status',
      display_title: t("withholdings.fieldStatus"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'status',
    },
    {
      title: 'transactionId',
      type: 'transactionId-btn',
      display_title: t("withholdings.fieldTransactionId"),
      sortBy: false,
      sortDirection: 'asc',
      sortTitle: 'transaction_id',
    },     
    // {
    //   title: 'actionMenuRefund',
    //   type: 'actionRefundTip',
    //   display_title: "",
    //   sortBy: false,
    //   sortDirection: 'asc',
    //   sortTitle: '',
    // }
  ]
}