import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/images/tovalogo1.svg";
import { ROUTE_FORGOT_PASSWORD, ROUTE_ACCOUNT_SIGNUP } from '../../config/env';


function Login() {
  const { t } = useTranslation();
  const navigate  = useNavigate();

  const onHandleSignUp =()=>{
    navigate(`${ROUTE_ACCOUNT_SIGNUP}`)
  }
  return (
    <div className='center mt-101px' >
        <form className='loginbox '>
            <img src={logo} alt="" />
            <h1>{t("accountLoginPage.welcome")}</h1>
            <h2 className='mb-22px'>{t("accountLoginPage.subheading")}</h2>
            <input type="text" className='w-100' placeholder={`${t("accountLoginPage.usernamePlaceHolder")}`}/>
            <input type="text" className='w-100' placeholder={`${t("accountLoginPage.passwordPlaceHolder")}`}/>
            <button className='rounded-2 p-12px text-white border-none mt-18px' style={{backgroundColor:"#3871E0"}}>{t("accountLoginPage.btnLogin")}</button>
            <a href={ROUTE_FORGOT_PASSWORD} >{t("accountLoginPage.forgotPassword")}</a>
            <div className='relative'>
              <div className='or-line'>&nbsp;</div>
              <div className='or'>{t("accountLoginPage.or")}</div>
            </div>
            <button onClick={onHandleSignUp} className='rounded-2 p-12px text-blue font-500 bg-transparent' style={{border:" 1px solid #3871E0"}}>{t("accountLoginPage.btnSignUp")}</button>

        </form>
    </div>
  )
}

export default Login