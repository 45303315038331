// PAGE TO DEFINE API URL CONSTANTS FOR BOTH ADMIN AND ACCOUNT LOGINS  

// API URLS - SUPERADMIN 
export const SA_API_URL_LOGIN             = 'users/admin-token/'; 
export const SA_API_URL_PWD_RESET         = 'hotels/password/reset/'; 

export const SA_API_URL_DASHBOARD         = 'hotels/home/';  
export const SA_API_URL_ALL_ACCOUNTS_LIST = 'hotels/accounts/';  
export const SA_API_URL_ACCOUNTS_LIST     = 'hotels/accounts/'; 
export const SA_API_URL_ACCOUNTS_EXPORT   = 'hotels/accounts/export-csv/'; 
export const SA_API_URL_ACCOUNTS_LIST_ALL = 'hotels/accounts/list/';  

export const SA_API_URL_DASHBOARD_CHART_DATA = 'payments/dashboard/'; 
export const SA_API_URL_DASHBOARD_EXPORT_CHART_DATA = 'payments/dashboard/export-csv/'; 

export const SA_API_URL_ACCOUNT_DETAILS     = 'hotels/accounts/'; 
export const SA_API_URL_ACCOUNT_CREATE      = 'hotels/accounts/create/'; 
export const SA_API_URL_ACCOUNT_LOGO_UPLOAD = 'hotels/account/upload-logo/'; 
export const SA_API_URL_ACCOUNT_LOGO_REMOVE = 'hotels/account/remove-logo/'; 
export const SA_API_URL_ACCOUNT_ACTIVATE    = 'hotels/activate-account/'; 
export const SA_API_URL_ACCOUNT_DEACTIVATE  = 'hotels/deactivate-account/'; 
export const SA_API_URL_ACCOUNT_UPDATE_DETAILS = 'hotels/accounts/'; 
export const SA_API_URL_ACCOUNT_UPDATE_BANK_DETAILS = 'hotels/accounts/bank-details/'; 
export const SA_API_URL_ACCOUNT_TIP_SETTINGS= 'hotels/tip-settings/'; 
export const SA_API_URL_ACCOUNT_TIP_LOGO_UPLOAD  = 'hotels/upload-tip-logo/'; 
export const SA_API_URL_ACCOUNT_TIP_LOGO_REMOVE  = 'hotels/remove-tip-logo/'; 
export const SA_API_URL_ACCOUNT_TIP_IMAGE_UPLOAD = 'hotels/upload-tip-image/'; 
export const SA_API_URL_ACCOUNT_TIP_IMAGE_REMOVE = 'hotels/remove-tip-image/'; 
export const SA_API_URL_ACCOUNT_REVIEW_LINK      = 'hotels/review-link/'; 
export const SA_API_URL_ACCOUNT_REVIEW_LOGO_UPLOAD='hotels/upload-review-logo/'; 
export const SA_API_URL_ACCOUNT_RESET_REVIEW_ORDER='hotels/review-link-order/'; 
export const SA_API_URL_ACCOUNT_PRIMARY_USER_UPDATE='hotel-users/primary-user-update/'; 

export const SA_API_URL_STAFF_LIST          = 'users/staff/'; 
export const SA_API_URL_STAFF_EXPORT        = 'users/staff/export-csv/'; 
export const SA_API_URL_STAFF_STATUS        = 'users/staff/status-change/'; 
export const SA_API_URL_STAFF_DELETE        = 'users/delete-user/'; 
export const SA_API_URL_STAFF_PROFILE       = 'users/staff/profile/';   
export const SA_API_URL_STAFF_WITHHOLDINGS  = 'users/staff/withhold/';   
export const SA_API_URL_STAFF_QRCODE        = 'users/staff/account-information/';   
export const SA_API_URL_STAFF_PROFILE_UPDATE= 'users/staff/profile-update/';   
export const SA_API_URL_STAFF_UPLOAD_AVATAR = 'users/staff/upload-avatar/';    
export const SA_API_URL_STAFF_REMOVE_AVATAR = 'users/staff/remove-avatar/';   
export const SA_API_URL_STAFF_W9_FORM       = 'users/staff/generate-tax-form/';   
export const SA_API_URL_STAFF_UPLOAD_ID_PROOF='users/staff/upload-id-proof/';   
export const SA_API_URL_STAFF_DELETE_ID_PROOF='users/staff/id-proof/';  
export const SA_API_URL_STAFF_REGISTER      = 'users/staff/register/';  
export const SA_API_URL_STAFF_REG_WITHOUT_EMAIL      = 'users/staff/un-invite-register/';  
export const SA_API_URL_STAFF_INVITE        = 'users/staff/invite/';  
export const SA_API_URL_STAFF_REINVITE      = 'users/staff/re-invite/'; 

export const SA_API_URL_TIPS_LIST         = 'payments/tips/'; 
export const SA_API_URL_TIPS_EXPORT       = 'payments/tips/export-csv/'; 
export const SA_API_URL_TIPS_SUMMARY      = 'payments/tips/summary/'; 
export const SA_API_URL_TIPS_STRIPE_PAY_METHOD = 'payments/stripe/payment-method/'; 
export const SA_API_URL_TIPS_REFUND       = 'payments/tips/refund/'; 

export const SA_API_URL_COMMISSION_LIST   = 'payments/payment-commission/'; 
export const SA_API_URL_COMMISSION_EXPORT = 'payments/payment-commission/export-csv/'; 
export const SA_API_URL_COMMISSION_SUMMARY= 'payments/payment-commission/summary/'; 

export const SA_API_URL_PAYOUTS_LIST      = 'payments/payouts/'; 
export const SA_API_URL_PAYOUTS_EXPORT    = 'payments/payouts/export-csv/'; 
export const SA_API_URL_PAYOUTS_SUMMARY   = 'payments/payouts/summary/'; 
export const SA_API_URL_PAYEE_LIST        = 'users/staff/search/'; 
export const SA_API_URL_PAYOUT_MANAGE_PAYMENT = 'payments/manage-payment/'; 
export const SA_API_URL_PAYOUT_RECEIPT    = 'payments/receipt/'; 
export const SA_API_URL_PAYOUT_REPROCESS  = 'payments/reprocess-payment/'; 
export const SA_API_URL_PAYOUT_SAVE_MANAGE_PAYMENT = 'payments/manage-payment/'; 
export const SA_API_URL_PAYOUT_DESTINATION_CHANGE  = 'payments/payout-destination-change/'; 

export const SA_API_URL_ADMIN_USERS         = 'hotels/admin-users/'; 
export const SA_API_URL_ADMIN_USER_CREATE   = 'hotels/user/create/'; 
export const SA_API_URL_ADMIN_USER_PWD_RESET= 'hotels/password/reset/'; 
export const SA_API_URL_ADMIN_USER_DELETE   = 'users/delete-user/'; 
export const SA_API_URL_ADMIN_USER_UPDATE   = 'hotels/user/update/'; 

export const SA_API_URL_ACC_USERS         = 'hotels/all-hotel-users/'; 
export const SA_API_URL_ACC_USER_CREATE   = 'hotel-users/user/create/'; 
export const SA_API_URL_ACC_USER_PWD_RESET= 'hotels/password/reset/'; 
export const SA_API_URL_ACC_USER_PWD_RESET_SUBMIT= 'hotels/password/reset/submit/'; 
export const SA_API_URL_ACC_USER_DELETE   = 'users/delete-user/'; 
export const SA_API_URL_ACC_USER_UPDATE   = 'hotel-users/user/update/'; 
export const SA_API_URL_ACC_USERS_EXPORT_CSV = 'hotels/all-hotel-users/export-csv/'; 
export const SA_API_URL_ACC_USER_STATUS_UPDATE = 'hotel-users/user-status-update/'; 

export const SA_API_URL_ACC_POOL_TIP_DETAILS    = 'hotels/account-tip-details/'; 
export const SA_API_URL_ACC_RECEIPT    = 'payments/tip-receipt?intent_id='; 
export const SA_API_URL_ACC_RECEIPT_SEND_MAIL    = 'users/tip-receipt-mail/'; 
export const SA_API_URL_ACC_GROUPS_LIST         = 'hotels/pools/'; 
export const SA_API_URL_ACC_GROUP_DETAILS       = 'hotels/pool/'; // hotels/pool/2 
export const SA_API_URL_ACC_GROUP_NAME_UPDATE   = 'hotels/pool/name-change/'; // hotels/pool/name-change/2 
export const SA_API_URL_ACC_GROUP_DETAILS_UPDATE= 'hotels/pool/'; // hotels/pool/2 
export const SA_API_URL_ACC_GROUP_CREATE        = 'hotels/pools/';  

export const SA_API_URL_DEFAULT_SETTINGS        = 'users/default-settings/'; 
export const SA_API_URL_DEFAULT_SETTINGS_UPDATE = 'users/default-settings/';  

export const SA_API_URL_STRIPE_TOKEN     = 'payments/token-stripe/';  

export const SA_API_URL_GET_TERMS_AND_C  = "users/terms-and-conditions/"; 
export const SA_API_URL_SAVE_TERMS_AND_C = "users/terms-and-conditions/"; 

export const SA_API_CONTACT_US           = "users/send-contact-mail/"; 
export const SA_API_COUNTRY_STATES       = "users/state-list/"; 

// API URLS - ACCOUNTS LOGIN  
export const ACC_API_URL_DASHBOARD        = 'hotel-users/home/';  
export const ACC_API_URL_LOGIN            = 'users/hotel-token/'; 
export const ACC_API_URL_PWD_RESET        = 'hotel-users/password/reset/';

export const ACC_API_URL_ALL_ACCOUNTS_LIST= 'hotels/accounts/';  
export const ACC_API_URL_PRIMARY_ACC_LIST= 'hotels/primary-user-account/';  
export const ACC_API_URL_ACCOUNTS_LIST    = 'hotel-users/accounts/';  
export const ACC_API_URL_ACCOUNTS_EXPORT  = 'hotel-users/accounts/export-csv/'; 
export const ACC_API_URL_ACCOUNTS_LIST_ALL= 'hotel-users/accounts/list/';  

export const ACC_API_URL_DASHBOARD_CHART_DATA = 'payments/dashboard/';
export const ACC_API_URL_DASHBOARD_EXPORT_CHART_DATA = 'payments/dashboard/export-csv/'; 

export const ACC_API_URL_ACCOUNT_DETAILS     = 'hotel-users/accounts/'; 
export const ACC_API_URL_ACCOUNT_LOGO_UPLOAD = 'hotel-users/account/upload-logo/'; 
export const ACC_API_URL_ACCOUNT_LOGO_REMOVE = 'hotel-users/account/remove-logo/'; 
export const ACC_API_URL_ACCOUNT_ACTIVATE    = 'hotel-users/activate-account/'; 
export const ACC_API_URL_ACCOUNT_DEACTIVATE  = 'hotel-users/deactivate-account/'; 
export const ACC_API_URL_ACCOUNT_UPDATE_DETAILS     = 'hotel-users/accounts/'; 
export const ACC_API_URL_ACCOUNT_UPDATE_BANK_DETAILS= 'hotel-users/accounts/bank-details/';
export const ACC_API_URL_ACCOUNT_TIP_SETTINGS       = 'hotel-users/tip-settings/'; 
export const ACC_API_URL_ACCOUNT_TIP_LOGO_UPLOAD  = 'hotel-users/upload-tip-logo/'; 
export const ACC_API_URL_ACCOUNT_TIP_LOGO_REMOVE  = 'hotel-users/remove-tip-logo/'; 
export const ACC_API_URL_ACCOUNT_TIP_IMAGE_UPLOAD = 'hotel-users/upload-tip-image/'; 
export const ACC_API_URL_ACCOUNT_TIP_IMAGE_REMOVE = 'hotel-users/remove-tip-image/';
export const ACC_API_URL_ACCOUNT_REVIEW_LINK      = 'hotel-users/review-link/'; 
export const ACC_API_URL_ACCOUNT_REVIEW_LOGO_UPLOAD='hotel-users/upload-review-logo/'; 
export const ACC_API_URL_ACCOUNT_RESET_REVIEW_ORDER='hotel-users/review-link-order/';  
export const ACC_API_URL_ACCOUNT_PRIMARY_USER_UPDATE='hotel-users/primary-user-update/'; 

export const ACC_API_URL_ACC_POOL_TIP_DETAILS    = 'hotel-users/account-tip-details/'; 
export const ACC_API_URL_ACC_GROUPS_LIST         = 'hotel-users/pools/'; 
export const ACC_API_URL_ACC_GROUP_DETAILS       = 'hotel-users/pool/'; // hotel-users/pool/2 
export const ACC_API_URL_ACC_GROUP_NAME_UPDATE   = 'hotel-users/pool/name-change/'; //hotel-users/pool/name-change/2 
export const ACC_API_URL_ACC_GROUP_DETAILS_UPDATE= 'hotel-users/pool/'; // hotel-users/pool/2 
export const ACC_API_URL_ACC_GROUP_CREATE        = 'hotel-users/pools/';

export const ACC_API_URL_STAFF_LIST       = 'hotel-users/staff/'; 
export const ACC_API_URL_STAFF_EXPORT     = 'hotel-users/staff/export-csv/'; 
export const ACC_API_URL_STAFF_STATUS     = 'hotel-users/staff/status-change/'; 
export const ACC_API_URL_STAFF_DELETE     = 'hotel-users/delete-user/'; 

export const ACC_API_URL_TIPS_LIST         = 'hotel-users/tips/'; 
export const ACC_API_URL_TIPS_EXPORT       = 'hotel-users/tips/export-csv/'; 
export const ACC_API_URL_TIPS_SUMMARY      = 'hotel-users/tips/summary/'; 
export const ACC_API_URL_TIPS_STRIPE_PAY_METHOD = 'payments/stripe/payment-method/'; 
export const ACC_API_URL_TIPS_REFUND       = 'payments/tips/refund/'; 

export const ACC_API_URL_PAYOUTS_LIST      = 'hotel-users/payouts/'; 
export const ACC_API_URL_PAYOUTS_EXPORT    = 'hotel-users/payouts/export-csv/'; 
export const ACC_API_URL_PAYOUTS_SUMMARY   = 'hotel-users/payouts/summary/'; 
export const ACC_API_URL_PAYOUT_MANAGE_PAYMENT = 'hotel-users/manage-payment/'; 
export const ACC_API_URL_PAYOUT_RECEIPT    = 'hotel-users/receipt/'; 
export const ACC_API_URL_PAYOUT_REPROCESS  = 'hotel-users/reprocess-payment/'; 
export const ACC_API_URL_PAYOUT_SAVE_MANAGE_PAYMENT = 'hotel-users/manage-payment/'; 
export const ACC_API_URL_PAYOUT_DESTINATION_CHANGE  = 'hotel-users/payout-destination-change/';

export const ACC_API_URL_ADMIN_USERS         = 'hotel-users/admin-users/'; 
export const ACC_API_URL_ADMIN_USER_CREATE   = 'hotel-users/user/create/'; 
export const ACC_API_URL_ADMIN_USER_PWD_RESET= 'hotel-users/password/reset/'; 
export const ACC_API_URL_ADMIN_USER_DELETE   = 'users/delete-user/'; 
export const ACC_API_URL_ADMIN_USER_UPDATE   = 'hotel-users/user/update/'; 

export const ACC_API_URL_ACC_USERS         = 'hotel-users/all-hotel-users/'; 
export const ACC_API_URL_ACC_USER_CREATE   = 'hotel-users/user/create/'; 
export const ACC_API_URL_ACC_USER_PWD_RESET= 'hotel-users/password/reset/'; 
export const ACC_API_URL_ACC_USER_DELETE   = 'users/delete-user/'; 
export const ACC_API_URL_ACC_USER_UPDATE   = 'hotel-users/user/update/'; 


export const ACC_API_URL_ACCOUNT_SIGN_UP             = 'hotels/signup/'; 
export const ACC_API_URL_ACCOUNT_SIGN_UP_RESEND_EMAIL= 'hotels/verification-mail/'; 
// export const ACC_API_URL_ACCOUNT_SIGN_UP_RESEND_EMAIL= 'hotels/change-email/'; 
export const ACC_API_URL_ACCOUNT_T_AND_C             = 'users/terms-and-conditions/view/'; 
export const ACC_API_URL_ACCOUNT_SIGN_UP_VERIFY_LINK = 'hotels/verify-new-account-mail/'; 
export const ACC_API_URL_ACCOUNT_BUSINESS_TYPES      = 'hotels/business-types/'; 
export const ACC_API_URL_ACCOUNT_SIGN_UP_NEW_ACCOUNT = 'hotels/new-account/';  
export const ACC_API_URL_ACCOUNT_SIGN_UP_AGREEMENT   = 'users/terms-and-conditions/view/';  
export const ACC_API_URL_ACCOUNT_SIGN_UP_ACCEPT_TERMS= 'users/terms-and-conditions-log/';  
export const ACC_API_URL_ACCOUNT_SIGN_UP_ACH_UPDATE  = 'hotels/account/ach/';  

// API URLS - STAFF LOGIN  
export const API_URL_STAFF_LOGIN          = ''; 

// API URLS - TIP   
export const API_URL_TIP_PAGE             = ''; 

//WITHHOLDING LIST - SAME URL FOR BOTH SA AND ACCOUNT LOGINS 
export const ACC_API_URL_WITHHOLDINGS           = 'payments/staff-withhold/';  
export const ACC_API_URL_WITHHOLDINGS_EXPORT    = 'payments/staff-withhold/export-csv/';  
export const ACC_API_URL_DISTRIBUTE_WITHHOLDINGS= 'payments/distribute-staff-withhold/';  


