import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from '../../store';
import { IGroupDetails, IPayee } from "../../models/groupInterfaces";
import { fetchGroupDetails, updateGroupName, updateGroupDetails, fetchPayeeByNameAccId } from "../../services/groupServices";
import { GROUP_STAFF_DUPLICATED } from "../../config/apiResponseCodes";

export interface DataState {
    groupDetails: IGroupDetails;
    loading: string;
    accountLogo:string;    
    payeeList: IPayee[];
}

const initialState: DataState = {
    groupDetails: {} as IGroupDetails,
    accountLogo:'',
    loading: 'idle',    
    payeeList: [],
}

export const groupDetailsSlice = createSlice({
    name: 'groupDetailsSlice',
    initialState,
    reducers: {
        resetLoading: (state, action: PayloadAction<any>) => {
          state.loading = action.payload;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(fetchGroupDetails.pending, (state) => {
            state.loading = 'loading'
        });
        builder.addCase(fetchGroupDetails.fulfilled, (state, action) => {
            state.groupDetails = action.payload
            state.loading = "dataLoaded"
        });
        builder.addCase(fetchGroupDetails.rejected, (state) => {
            state.loading = 'failed'
        });


        builder.addCase(updateGroupName.pending, (state) => {
            state.loading = 'idle'
        });
        builder.addCase(updateGroupName.fulfilled, (state, action) => {
            state.loading = "groupNameModified";
        });
        builder.addCase(updateGroupName.rejected, (state, action) => {
            state.loading = 'groupNameUpdationFailed';
            if(action?.payload?.response?.data?.message) {
                state.loading = action.payload.response.data.message;
            }
            else {
                state.loading = 'groupNameModificationError';
            }
        }); 

        builder.addCase(updateGroupDetails.pending, (state) => {
          state.loading = 'loading';
        });
        builder.addCase(updateGroupDetails.fulfilled, (state, action) => {
          state.loading = 'groupDetailsModified';
        });
        builder.addCase(updateGroupDetails.rejected, (state, { payload }: any) => {
          state.loading = ''; 
          state.loading = payload?.response?.data?.message==GROUP_STAFF_DUPLICATED ?  GROUP_STAFF_DUPLICATED : 'groupDetailsUpdationFailed'; 
        });


        builder.addCase(fetchPayeeByNameAccId.pending, (state) => {
            state.payeeList = [];
            state.loading = 'idle';
          });
          builder.addCase(fetchPayeeByNameAccId.fulfilled, (state, action) => {
            const payees = action.payload as IPayee[];
            state.payeeList = payees;
      
            state.loading = 'succeeded';
          });
          builder.addCase(fetchPayeeByNameAccId.rejected, (state, { payload }: any) => {
            state.loading = 'error';
          });
      
    }
})

// Action creators are generated for each case reducer function
export const { resetLoading } =  groupDetailsSlice.actions;

export const selectGroupDetailsSlice = (state:RootState)=> state.groupDetailsData;
export const groupDetailsReducer = groupDetailsSlice.reducer;