import { get, getFile, post } from './apiService';
import { ITipsRequestData } from '../models/tipsInterfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SA_API_URL_TIPS_LIST, SA_API_URL_TIPS_EXPORT, SA_API_URL_TIPS_SUMMARY, SA_API_URL_TIPS_STRIPE_PAY_METHOD,SA_API_URL_TIPS_REFUND, ACC_API_URL_TIPS_LIST, ACC_API_URL_TIPS_EXPORT, ACC_API_URL_TIPS_SUMMARY} from '../config/api_urls'; 
import { arrAccountUserIds } from '../config/env'; 


// FUNCTION TO FETCH TIP LIST
export const fetchTips = createAsyncThunk(
  'tips/fetchTips',
  async ({ userRole, page, numRowsPerPage, start, end, hotel, group, search, payee, payeeType, paymethod, sort }: ITipsRequestData, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(userRole) ? ACC_API_URL_TIPS_LIST : SA_API_URL_TIPS_LIST)+'?page=' + page + '&hotel=' + hotel;
      if (group && group !== '') { url = url + '&group=' + group; }
      if (numRowsPerPage) { url = url + '&page_size=' + numRowsPerPage.toString(); }
      if (sort !== '')     { url = url + '&sort=' + sort; }
      if (start && start !== '')    { url = url + '&start=' + encodeURIComponent(start); }
      if (end && end !== '')      { url = url + '&end=' + encodeURIComponent(end); }
      if (search !== '')   { url = url + '&search=' + search.trim(); }
      if (payee !== '')    { url = url + '&payee=' + payee; } 
      if (payeeType !== ''){ url = url + '&payee_type=' + payeeType; } 
      if (paymethod !== ''){ url = url + '&paymethod=' + paymethod; }
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO EXPORT TIP LIST
export const exportTips = createAsyncThunk(
  'tips/exportTips',
  async ({ userRole, page, start, end, hotel, group, search, payee, payeeType, paymethod, sort }: ITipsRequestData, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(userRole) ? ACC_API_URL_TIPS_EXPORT : SA_API_URL_TIPS_EXPORT)+'?page=' + page + '&hotel=' + hotel;
      if (group !== '')    { url = url + '&group=' + group; }
      if (sort !== '')     { url = url + '&sort=' + sort; }
      if (start && start !== '')    { url = url + '&start=' + encodeURIComponent(start); }
      if (end && end !== '')      { url = url + '&end=' + encodeURIComponent(end); }
      if (search !== '')   { url = url + '&search=' + search.trim(); }
      if (payee !== '')    { url = url + '&payee=' + payee; }
      if (payeeType !== ''){ url = url + '&payee_type=' + payeeType; } 
      if (paymethod !== ''){ url = url + '&paymethod=' + paymethod; }
      getFile(url, 'Tips_List_Export');
    } catch (error) {
      // console.log('Tips_List_Export error: ', error); 
    }
  }
);

// FUNCTION TO FETCH TIP SUMMARY
export const fetchTipsSummary = createAsyncThunk(
  'tips/fetchTipsSummary',
  async ({ userRole, page, start, end, hotel, group, search, payee, payeeType, paymethod }: ITipsRequestData, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(userRole) ? ACC_API_URL_TIPS_SUMMARY : SA_API_URL_TIPS_SUMMARY)+'?page=' + page+ '&hotel=' + hotel;
      if (group !== '')    { url = url + '&group=' + group; }
      if (start && start !== '')    { url = url + '&start=' + encodeURIComponent(start); }
      if (end && end !== '')      { url = url + '&end=' + encodeURIComponent(end); }
      if (search !== '')   { url = url + '&search=' + search.trim(); }
      if (payee !== '')    { url = url + '&payee=' + payee; }
      if (payeeType !== ''){ url = url + '&payee_type=' + payeeType; } 
      if (paymethod !== ''){ url = url + '&paymethod=' + paymethod; }
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO FETCH STRIPE PAY METHODS
export const fetchStripePayMethods = createAsyncThunk(
  'tips/fetchStripePayMethods',
  async (search: string, thunkAPI: any) => {
    try {
      let url = SA_API_URL_TIPS_STRIPE_PAY_METHOD; // COMMON FOR BOTH ADMIN AND ACCOUNT USERS
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO GET REFUNDED LIST
export const performRefund = createAsyncThunk(
  'tips/performRefund',
  async (id: number, thunkAPI: any) => {
    try {
      let url = SA_API_URL_TIPS_REFUND+'?tip_id=' + id; ; // REFUND OPTION ONLY FOR SA AND ADMIN USERS  
      const response = post(url, null);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

