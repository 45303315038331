import React from "react";
import { useTranslation } from "react-i18next";

import { ITipsSummary } from "../../models/tipsInterfaces";
import { IPaymentsSummary } from "../../models/paymentsInterfaces";
import { ICommissionsSummary } from "../../models/commissionsInterfaces";
import tik from "../../assets/images/tik.png";
import cross from "../../assets/images/cross.png";
import pig from "../../assets/images/pig.png";
import hand from "../../assets/images/hand.png";
import strip from "../../assets/images/strip.png";
import pfee from "../../assets/images/tovalogo1.svg";

import MoneyField from "../MoneyField";
import { getAdminUserRole } from "../../services/localStorage";
import {
  USER_ROLE_ADMIN_ADMIN,
  USER_ROLE_ADMIN_SUPERADMIN,
} from "../../config/env";

interface IProps {
  transaction?: boolean;
  tips?: boolean;
  tipsSummary?: ITipsSummary;
  payment?: boolean;
  paymentsSummary?: IPaymentsSummary;
  commissions?: boolean;
  commissionsSummary?: ICommissionsSummary;
}

const Transactions: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const adminUserRole = getAdminUserRole();
  return (
    <div className="transaction">
      {props.tips && (
        <>
          <div className="w-100 transaction-box ">
            <div className="">
              <img src={pig} alt="" className="mb-7px me-2" />
              {props?.tipsSummary?.totalTransactionAmount && (
                <MoneyField
                  amountValue={
                    props?.tipsSummary
                      ? Number(
                          props.tipsSummary.totalTransactionAmount
                        ).toFixed(2)
                      : "0"
                  }
                  clickable={false}
                  amountCurrency={
                    props?.tipsSummary
                      ? props.tipsSummary.totalTransactionAmountCurrency
                      : ""
                  }
                />
              )}
            </div>
            <div>
              <p>
                {props?.tipsSummary && props.tipsSummary.totalTransaction == 1
                  ? t("common.totalTransactionsReceived")
                  : t("common.totalTransactionsReceived")}{" "}
                {props?.tipsSummary ? props.tipsSummary.totalTransaction : "0"}{" "}
              </p>
            </div>
          </div>

          <div className="w-100 transaction-box ">
            <div className="">
              <img src={hand} alt="" className="mb-7px me-2" />
              {props?.tipsSummary?.totalPayoutAmount && (
                <MoneyField
                  amountValue={
                    props?.tipsSummary
                      ? Number(props.tipsSummary.totalPayoutAmount).toFixed(2)
                      : "0"
                  }
                  clickable={false}
                  amountCurrency={
                    props?.tipsSummary
                      ? props.tipsSummary.totalPayoutAmountCurrency
                      : ""
                  }
                />
              )}
            </div>
            <div>
              <p>
                {t("common.payoutProcessed")}{" "}
                {props?.tipsSummary ? props.tipsSummary.totalPayout : "0"}{" "}
              </p>
            </div>
          </div>
          {(Number(adminUserRole) == USER_ROLE_ADMIN_SUPERADMIN ||
            Number(adminUserRole) == USER_ROLE_ADMIN_ADMIN) && (
            <>
              <div className="w-100 transaction-box ">
                <div className="">
                  <img src={strip} alt="" className="mb-7px me-2" />
                  {props?.tipsSummary?.stripeFees && (
                    <MoneyField
                      amountValue={
                        props?.tipsSummary
                          ? Number(props.tipsSummary.stripeFees).toFixed(2)
                          : "0"
                      }
                      clickable={false}
                      amountCurrency={
                        props?.tipsSummary
                          ? props.tipsSummary.stripeFeesCurrency
                          : ""
                      }
                    />
                  )}
                </div>
                <div>
                  <p>{t("common.stripFee")} </p>
                </div>
              </div>

              <div className="w-100 transaction-box ">
                <div className="flex">
                  <div
                    className="mb-7px me-2 border border-6r center pb-0px"
                    style={{ width: "28px", height: "28px" }}
                  >
                    <img
                      src={pfee}
                      alt=""
                      style={{ width: "18px", height: "18px" }}
                    />
                  </div>
                  {props?.tipsSummary?.platformFees && (
                    <MoneyField
                      amountValue={
                        props?.tipsSummary
                          ? Number(props.tipsSummary.platformFees).toFixed(2)
                          : "0"
                      }
                      clickable={false}
                      amountCurrency={
                        props?.tipsSummary
                          ? props.tipsSummary.platformFeesCurrency
                          : ""
                      }
                    />
                  )}
                </div>
                <div>
                  <p>{t("common.platformFee")}</p>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {props.payment && (
        <>
          <div className="w-100 transaction-box ">
            <div className="">
              <img src={pig} alt="" className="mb-7px me-2" />
              <span>
                {props?.paymentsSummary?.totalPayoutAmount && (
                  <MoneyField
                    amountValue={
                      props?.paymentsSummary
                        ? Number(
                            props.paymentsSummary.totalPayoutAmount
                          ).toFixed(2)
                        : "0"
                    }
                    clickable={false}
                    amountCurrency={
                      props?.paymentsSummary
                        ? props.paymentsSummary.totalPayoutAmountCurrency
                        : ""
                    }
                  />
                )}
              </span>
            </div>
            <div>
              <p>
                {" "}
                {props?.paymentsSummary &&
                props.paymentsSummary.totalTransaction == 1
                  ? t("common.totalTransactionsReceived")
                  : t("common.totalTransactionsReceived")}
                {props?.paymentsSummary
                  ? props.paymentsSummary.totalTransaction
                  : 0}
              </p>
            </div>
          </div>

          <div className="w-100 transaction-box ">
            <div className="">
              <img src={tik} alt="" className="mb-7px me-2" />
              <span>
                {props?.paymentsSummary
                  ? props.paymentsSummary.totalSucceeded
                  : 0}
              </span>
            </div>
            <div>
              <p>{t("common.suceeded")}</p>
            </div>
          </div>
          <div className="w-100 transaction-box ">
            <div className="">
              <img src={cross} alt="" className="mb-7px me-2" />
              <span>
                {" "}
                {props?.paymentsSummary ? props.paymentsSummary.totalFailed : 0}
              </span>
            </div>
            <div>
              <p>{t("common.failed")} </p>
            </div>
          </div>

          <div className="w-100 transaction-box border-none">&nbsp;</div>
        </>
      )}

      {props.commissions && (
        <>
          <div className="w-100 transaction-box ">
            <div className="">
              <img src={pig} alt="" className="mb-7px me-2" />
              {props?.commissionsSummary?.totalTransactionAmount && (
                <MoneyField
                  amountValue={
                    props?.commissionsSummary
                      ? Number(
                          props.commissionsSummary.totalTransactionAmount
                        ).toFixed(2)
                      : "0"
                  }
                  clickable={false}
                  amountCurrency={
                    props?.commissionsSummary
                      ? props.commissionsSummary.totalTransactionAmountCurrency
                      : ""
                  }
                />
              )}
            </div>
            <div>
              <p>
                {t("common.totalTransactionsReceived")}{" "}
                {props?.commissionsSummary
                  ? props.commissionsSummary.totalTransaction
                  : "0"}{" "}
              </p>
            </div>
          </div>
          <div className="w-100 transaction-box ">
            <div className="">
              <img src={hand} alt="" className="mb-7px me-2" />
              {props?.commissionsSummary?.totalPayoutAmount && (
                <MoneyField
                  amountValue={
                    props?.commissionsSummary
                      ? Number(
                          props.commissionsSummary.totalPayoutAmount
                        ).toFixed(2)
                      : "0"
                  }
                  clickable={false}
                  amountCurrency={
                    props?.commissionsSummary
                      ? props.commissionsSummary.totalPayoutAmountCurrency
                      : ""
                  }
                />
              )}
            </div>
            <div>
              <p>
                {t("common.payoutProcessed")}{" "}
                {props?.commissionsSummary
                  ? props.commissionsSummary.totalPayout
                  : "0"}
              </p>
            </div>
          </div>
          <div className="w-100 transaction-box ">
            <div className="">
              <img src={strip} alt="" className="mb-7px me-2" />
              {props?.commissionsSummary?.stripeFees && (
                <MoneyField
                  amountValue={
                    props?.commissionsSummary
                      ? Number(props.commissionsSummary.stripeFees).toFixed(2)
                      : "0"
                  }
                  clickable={false}
                  amountCurrency={
                    props?.commissionsSummary
                      ? props.commissionsSummary.stripeFeesCurrency
                      : ""
                  }
                />
              )}
            </div>
            <div>
              <p>{t("common.stripFee")}</p>
            </div>
          </div>
          <div className="w-100 transaction-box ">
            <div className="flex">
              <div
                className="mb-7px me-2 border border-6r center pb-0px"
                style={{ width: "28px", height: "28px" }}
              >
                <img
                  src={pfee}
                  alt=""
                  style={{ width: "18px", height: "18px" }}
                />
              </div>
              {props?.commissionsSummary?.platformFees && (
                <MoneyField
                  amountValue={
                    props?.commissionsSummary
                      ? Number(props.commissionsSummary.platformFees).toFixed(2)
                      : "0"
                  }
                  clickable={false}
                  amountCurrency={
                    props?.commissionsSummary
                      ? props.commissionsSummary.platformFeesCurrency
                      : ""
                  }
                />
              )}
            </div>
            <div>
              <p>{t("common.platformFee")}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Transactions;
