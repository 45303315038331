import React, { useEffect, useState } from "react";
import CloseRounded from "@mui/icons-material/CloseRounded";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { Box, Modal } from "@mui/material";

import "react-datepicker/dist/react-datepicker.css";

import pen from "../../assets/images/pen.svg";
import warning from "../../assets/images/yellowrm.svg";

import {
  selectCommonSlice,
  resetReloadGroupSubMenu,
} from "../CommonSlice/commonSlice";

import { useAppDispatch, useAppSelector } from "../../helpers/hook";
import {
  IGroupDetails,
  IGroupModify,
  IGroupNameEdit,
  IGroupStaffDetails,
  IGroupStaffDetailsModify,
} from "../../models/groupInterfaces";

import { setNotification } from "../../services/apiService";
import { getAdminUserRole, setLastActivePageAPIData } from "../../services/localStorage";

import {
  fetchGroupDetails,
  fetchPayeeByNameAccId,
  updateGroupDetails,
  updateGroupName,
} from "../../services/groupServices";

import { GROUP_STAFF_DUPLICATED } from "../../config/apiResponseCodes";
import {
  checkActionPermission,
  PERM_QRCODE_ORDER,
  PERM_EDIT_GROUP_DETAILS,
} from "../../config/permissions";
import {
  ROUTE_HOTEL_USERS,
  ROUTE_STAFF_LIST,
  ARR_PAYOUT_FREQUENCIES,
  GROUP_PAYOUT_FREQ_DIRECT_ID,
  GROUP_PAYOUT_FREQ_DAILY_ID,
  GROUP_PAYOUT_FREQ_WEEKLY_ID,
  GROUP_PAYOUT_FREQ_BI_WEEKLY_ID,
  GROUP_PAYOUT_FREQ_MONTHLY_FIRST_ID,
  GROUP_PAYOUT_FREQ_SEMI_MONTHLY_ID, 
  GROUP_PAYOUT_FREQ_MONTHLY_CUSTOM_ID,
  GROUP_PAYOUT_FREQ_MONTHLY_LAST_ID,
  GROUP_PAYOUT_MODE_AUTO_ID,
  GROUP_PAYOUT_MODE_MANUAL_ID,
  GROUP_TYPE_STAFF_ID,
  PAYOUT_ACCOUNT,
  PAYOUT_ACCOUNT_TEXT,
  PAYOUT_STAFF,
  PAYOUT_STAFF_TEXT,
  GROUP_STAFF_DETAILS_ROWS,
  PAGENAME_GROUP_DETAILS,
} from "../../config/env";
import { ROUTE_QRCODES } from "../../config/env_qrcodes";


import { Textfield } from "../../components/TextField";
import { SelectField } from "../../components/SelectField";
import { ButtonModal } from "../../components/ButtonGroups/ButtonModal";
import { ButtonInLine } from "../../components/ButtonGroups/ButtonInLine";
import { ButtonPrimary } from "../../components/ButtonGroups/ButtonPrimary";

import { selectGroupDetailsSlice, resetLoading } from "./groupDetailsSlice";
import { dateCalc } from "../../config/common_functions";

function GroupSettings() {
  const pageName = PAGENAME_GROUP_DETAILS; 
  const adminUserRole = getAdminUserRole();
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { groupDetails, loading, payeeList } = useAppSelector(
    selectGroupDetailsSlice
  );
  const { reloadGroupSubMenu } = useAppSelector(selectCommonSlice);

  const [allPayeeList, setAllPayeeList] = useState(payeeList);
  const [tempAllPayeeList, setTempAllPayeeList] = useState(payeeList); // USED FOR SELECTION LIST IN EACH ROW WITH FILTER BY TYPING
  const [staffSearchName, setStaffSearchName] = useState("");

  const [groupId, setGroupId] = useState(0);
  const [groupName, setGroupName] = useState("");
  const [groupNameEdit, setGroupNameEdit] = useState("");
  const [groupType, setGroupType] = useState(0); // 1 for Staff 2 for Others
  const [groupAccId, setGroupAccId] = useState(0);
  const [groupAccName, setGroupAccName] = useState("");
  const [selPayoutFrequecy, setSelPayoutFrequecy] = useState("");
  const [upcomingPayoutDateText, setUpcomingPayoutDateText] = useState("");
  const [upcomingPayoutDateValue, setUpcomingPayoutDateValue] = useState("");
  const [showPayoutDateControl, setShowPayoutDateControl] = useState(false);
  const [payPeriodDates, setPayPeriodDates] = useState("");

  let thisDay = new Date();
  let nextDay = thisDay.setDate(thisDay.getDate() + 1);
  const [payoutMinDate, setPayoutMinDate] = useState(
    dayjs(nextDay).format(`YYYY-MM-DD`)
  );
  const [payoutMaxDate, setPayoutMaxDate] = useState(
    dayjs(nextDay).format(`YYYY-MM-DD`)
  );
  const [arrExcludeDates, setArrExcludeDates] = useState([]);

  const [selPayoutMode, setSelPayoutMode] = useState("");
  const [groupStaffDetails, setGroupStaffDetails] = useState<
    IGroupStaffDetails[]
  >([]);
  const [editGroupStaffDetails, setEditGroupStaffDetails] = useState<
    IGroupStaffDetails[]
  >([]);

  const [editGroupName, setEditGroupName] = useState<boolean>(false);
  const [editStaffDetails, setEditStaffDetails] = useState<boolean>(false);
  const [editTipSettings, setEditTipSettings] = useState<boolean>(false);

  
  const [tippingText, setTippingText] = useState("");

  const [qrCodePdf, setQrCodePdf] = useState("");
  const [qrCodePng, setQrCodePng] = useState("");
  const [qrCodeLink, setQrCodeLink] = useState("");

  const [qrcScanCountTotal, setQRCScanCountTotal] = useState("0");
  const [qrcScanCount30Days, setQRCScanCount30Days] = useState("0");

  const [payoutModeConfirmPopup, setPayoutModeConfirmPopup] = useState<boolean>(false);

  const [viewStaffRowsUpTo, setViewStaffRowsUpTo] = useState<number>(
    GROUP_STAFF_DETAILS_ROWS
  );
  
  // FOR MODAL 
  const style1 = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 460,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "13px",
    boxShadow: 24,
  };

  const fetchGroupData = () => {
    if (params.id && Number(params.id) > 0) {
      setGroupId(Number(params.id));
      const req: IGroupDetails = {
        id: Number(params.id),
        userRole: Number(adminUserRole),
      };
      dispatch(fetchGroupDetails(req)); 
      setLastActivePageAPIData({pageName:pageName, apiData:req}); 
    }
  };

  useEffect(() => {
    if (groupId != Number(params.id)) {
      fetchGroupData();
    }
  }, []);

  useEffect(() => {
    fetchGroupData();
  }, [params.id]);

  useEffect(() => {
    let newPayeeList = groupType==GROUP_TYPE_STAFF_ID ? payeeList.filter(item => (item.id && item.id > 0)) : payeeList;  
    if (newPayeeList.length == 0) {
      setAllPayeeList(newPayeeList);
      setTempAllPayeeList(newPayeeList);
    } else {
      // REMOVE THE STAFF WHICH ARE ALREADY ADDED TO THE GROUP FROM SEARCH RESPONSE LIST
      let arrIds = [];
      if (editGroupStaffDetails.length > 0) {
        arrIds = editGroupStaffDetails.map((item) => {
          return item.staffId;
        });
      }
      let filteredPayeeList = newPayeeList.filter(
        (item) => !arrIds.includes(item.id)
      );
      setAllPayeeList(filteredPayeeList);
      setTempAllPayeeList(filteredPayeeList);
    }
  }, [payeeList]);

  // THIS IS TO DISABLE DATES (29,30,31) FROM CALENDAR, IF MONTHLY CUSTOM IS SELECTED
  useEffect(() => {
    let payFreqNum = Number(selPayoutFrequecy); 
    if (payFreqNum == GROUP_PAYOUT_FREQ_MONTHLY_CUSTOM_ID) {
      if (upcomingPayoutDateValue.length == 10) {
        let arrDates = [];
        let monthValue = Number(new Date().getMonth() + 1);
        let yearValue = new Date().getFullYear();
        if (monthValue == 1) {
          arrDates = [
            new Date(yearValue + "-01-29"),
            new Date(yearValue + "-01-30"),
            new Date(yearValue + "-01-31"),
          ];
          if (yearValue % 4 == 0) {
            arrDates.push(new Date(yearValue + "-02-29"));
          }
          setArrExcludeDates(arrDates);
        } else if (monthValue == 2) {
          arrDates = [];
          arrDates = [
            new Date(yearValue + "-03-29"),
            new Date(yearValue + "-03-30"),
            new Date(yearValue + "-03-31"),
          ];
          if (yearValue % 4 == 0) {
            arrDates.push(new Date(yearValue + "-02-29"));
          }
          setArrExcludeDates(arrDates);
        } else if (
          monthValue == 3 ||
          monthValue == 5 ||
          monthValue == 7 ||
          monthValue == 8 ||
          monthValue == 10
        ) {
          arrDates = [
            new Date(yearValue + "-" + monthValue + "-29"),
            new Date(yearValue + "-" + monthValue + "-30"),
            new Date(yearValue + "-" + monthValue + "-31"),
            new Date(yearValue + "-" + (monthValue + 1) + "-29"),
            new Date(yearValue + "-" + (monthValue + 1) + "-30"),
          ];
          if (monthValue == 7) {
            arrDates.push(new Date(yearValue + "-08-31"));
          }
          setArrExcludeDates(arrDates);
        } else if (
          monthValue == 4 ||
          monthValue == 6 ||
          monthValue == 9 ||
          monthValue == 11
        ) {
          setArrExcludeDates([
            new Date(yearValue + "-" + monthValue + "-29"),
            new Date(yearValue + "-" + monthValue + "-30"),
            new Date(yearValue + "-" + (monthValue + 1) + "-29"),
            new Date(yearValue + "-" + (monthValue + 1) + "-30"),
            new Date(yearValue + "-" + (monthValue + 1) + "-31"),
          ]);
        } else if (monthValue == 12) {
          setArrExcludeDates([
            new Date(yearValue + "-" + monthValue + "-29"),
            new Date(yearValue + "-" + monthValue + "-30"),
            new Date(yearValue + "-" + monthValue + "-31"),
            new Date(yearValue + 1 + "-01-29"),
            new Date(yearValue + 1 + "-01-30"),
            new Date(yearValue + 1 + "-01-31"),
          ]);
        }
      }
    } else {
      setArrExcludeDates([]);
    }

    // ALSO SET THE PAYOUT DATE TEXT ALSO  
    if(payFreqNum == GROUP_PAYOUT_FREQ_DIRECT_ID || payFreqNum == GROUP_PAYOUT_FREQ_DAILY_ID || !upcomingPayoutDateValue || upcomingPayoutDateValue.trim().length==0) {
      setPayPeriodDates(""); 
    } else { 
      let newDateVar1 = new Date(upcomingPayoutDateValue+"T00:00:00");
      let newDateVar2 = new Date(upcomingPayoutDateValue+"T00:00:00");
      if(payFreqNum == GROUP_PAYOUT_FREQ_WEEKLY_ID) {
        setPayPeriodDates(dayjs(newDateVar1.setDate((newDateVar1.getDate() - 7))).format(`MMM DD, YYYY`)+ " to " + dayjs(newDateVar2.setDate((newDateVar2.getDate() - 1))).format(`MMM DD, YYYY`)); 
      } else if(payFreqNum == GROUP_PAYOUT_FREQ_BI_WEEKLY_ID) {
        setPayPeriodDates(dayjs(newDateVar1.setDate((newDateVar1.getDate() - 14))).format(`MMM DD, YYYY`)+ " to " + dayjs(newDateVar2.setDate((newDateVar2.getDate() - 1))).format(`MMM DD, YYYY`)); 
      } else if(payFreqNum == GROUP_PAYOUT_FREQ_MONTHLY_FIRST_ID) {
        // CREATE NEW DATE OBJECT FROM SELECTED DATE
        const tempDate = new Date(newDateVar1);
        // GET FIRST DATE OF THE SELECTED MONTH 
        const firstDayOfCurrentMonth = new Date(tempDate.getFullYear(), tempDate.getMonth(), 1);
        // SUBTRACT ONE DAY FROM FIRST DAY OF THE SELECTED MONTH TO GET THE LAST DAY OF THE PREVIOUS MONTH 
        const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
        lastDayOfPreviousMonth.setDate(lastDayOfPreviousMonth.getDate() - 1);
        // GET FIRST DAY OF THE PREVIOUS MONTH BY SUBTRACTING ONE MORE MONTH FROM FIRST DAY OF THE SELECTED MONTH 
        const firstDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
        firstDayOfPreviousMonth.setMonth(firstDayOfPreviousMonth.getMonth() - 1);

        setPayPeriodDates(dayjs(firstDayOfPreviousMonth).format(`MMM DD, YYYY`)+ " to " + dayjs(lastDayOfPreviousMonth).format(`MMM DD, YYYY`)); 
      } else if(payFreqNum == GROUP_PAYOUT_FREQ_MONTHLY_CUSTOM_ID) {
        setPayPeriodDates(dayjs(newDateVar1.setMonth((newDateVar1.getMonth() - 1))).format(`MMM DD, YYYY`)+ " to " + dayjs(newDateVar2.setDate((newDateVar2.getDate() - 1))).format(`MMM DD, YYYY`)); 
      }
      else if(payFreqNum == GROUP_PAYOUT_FREQ_SEMI_MONTHLY_ID) {
        if(newDateVar1.getDate()==1) {
          let tempDate = new Date(newDateVar1.setMonth((newDateVar1.getMonth() - 1)));
          setPayPeriodDates(dayjs(tempDate.setDate(16)).format(`MMM DD, YYYY`)+ " to " + dayjs(newDateVar2.setDate((newDateVar2.getDate() - 1))).format(`MMM DD, YYYY`)); 
        }
        else {
          let tempDate = new Date(newDateVar1.setMonth((newDateVar1.getMonth())));
          setPayPeriodDates(dayjs(newDateVar1.setDate(1)).format(`MMM DD, YYYY`)+ " to " + dayjs(newDateVar2.setDate((newDateVar2.getDate() - 1))).format(`MMM DD, YYYY`)); 
        }
        
      }
    } 
  }, [upcomingPayoutDateValue, selPayoutFrequecy]);


  useEffect(() => {
    if (loading == "dataLoaded") {
      setGroupId(groupDetails?.id ? groupDetails.id : 0);
      setGroupName(groupDetails?.name ? groupDetails.name : "");
      setGroupType(groupDetails?.groupType ? groupDetails.groupType : 0);
      setGroupAccId(groupDetails?.accountId ? groupDetails.accountId : 0);
      setGroupAccName(
        groupDetails?.accountName ? groupDetails.accountName : ""
      );

      setSelPayoutFrequecy(
        groupDetails?.payoutFrequency
          ? groupDetails.payoutFrequency.toString()
          : ""
      );

      if (
        groupDetails?.payoutFrequency &&
        Number(groupDetails.payoutFrequency) == GROUP_PAYOUT_FREQ_DIRECT_ID
      ) {
        setUpcomingPayoutDateText(`${t("group.payoutDirect")}`);
        setUpcomingPayoutDateValue("");
        // IF PAYOUT FREQ. IS DIRECT THEN PAYOUT MODE IS AUTO
        setSelPayoutMode(GROUP_PAYOUT_MODE_AUTO_ID.toString());
        setShowPayoutDateControl(false);
      } else {
        if (groupDetails?.payoutFrequency) {
          onChangePayoutFrequecy(
            groupDetails.payoutFrequency.toString(),
            false
          );
        }
        if (
          groupDetails?.nextPayout &&
          groupDetails.nextPayout.trim().length == 10
        ) {
          let nextDtVar = new Date(groupDetails.nextPayout+"T00:00:00");  
          setUpcomingPayoutDateText(dayjs(nextDtVar).format(`MMM DD, YYYY`));
          setUpcomingPayoutDateValue(dayjs(nextDtVar).format(`YYYY-MM-DD`));
        }
        setSelPayoutMode(
          groupDetails?.payoutMode ? groupDetails.payoutMode.toString() : ""
        ); 
      }

      setTippingText(groupDetails?.tippingText ? groupDetails.tippingText : "") 

      setGroupStaffDetails(
        groupDetails?.staffDetails ? groupDetails.staffDetails : []
      );
      setEditGroupStaffDetails(
        groupDetails?.staffDetails ? groupDetails.staffDetails : []
      );

      setQrCodePdf(groupDetails?.qrCodePdf ? groupDetails.qrCodePdf : "");
      setQrCodePng(groupDetails?.qrCodePng ? groupDetails.qrCodePng : "");
      setQrCodeLink(groupDetails?.qrCodeLink ? groupDetails.qrCodeLink : "");

      setQRCScanCountTotal(groupDetails?.qrScanTotal ? groupDetails.qrScanTotal : "0");
      setQRCScanCount30Days(groupDetails?.qrScan_30Days ? groupDetails.qrScan_30Days : "0");

      setViewStaffRowsUpTo(GROUP_STAFF_DETAILS_ROWS);
      setEditGroupName(false);
      setEditStaffDetails(false);
      setEditTipSettings(false);
    } else if (loading == "groupNameModified") {
      setGroupName(groupNameEdit);

      dispatch(resetReloadGroupSubMenu(reloadGroupSubMenu + 1));

      setNotification(
        t("projectName"),
        t("group.groupNameModifySuccess"),
        "success",
        "groupNameModified"
      );

      dispatch(resetLoading("idle"));
      setEditGroupName(false);
      fetchGroupData();
    } else if (loading == "groupNameUpdationFailed") {
      setNotification(
        t("projectName"),
        t("group.groupNameModifyError"),
        "failed",
        "groupNameUpdationFailed"
      );

      dispatch(resetLoading("idle"));
      setEditGroupName(false);
    } else if (loading == "groupDetailsModified") {
      setGroupName(groupNameEdit);

      setNotification(
        t("projectName"),
        t("group.groupDetailsModifySuccess"),
        "success",
        "groupDetailsModified"
      );

      dispatch(resetLoading("idle"));
      setEditGroupName(false);
      fetchGroupData();
    } else if (loading == "groupDetailsUpdationFailed") {
      setNotification(
        t("projectName"),
        t("group.groupDetailsModifyError"),
        "failed",
        "groupDetailsModified"
      );

      dispatch(resetLoading("idle"));
      setEditGroupName(false);
    } else if (loading == GROUP_STAFF_DUPLICATED) {
      setNotification(
        t("projectName"),
        t("group.duplicateStaffError"),
        "failed",
        "staff_duplicated"
      );
      dispatch(resetLoading("idle"));
      setEditGroupName(false);
    }
  }, [loading]);

  const onChangePayoutFrequecy = (
    pfId: string,
    changeShowPayoutDate: boolean
  ) => {
    setSelPayoutFrequecy(pfId);
    if (Number(pfId) == GROUP_PAYOUT_FREQ_DIRECT_ID) {
      setUpcomingPayoutDateText(`${t("group.payoutDirect")}`);
      setUpcomingPayoutDateValue("");
      // IF PAYOUT FREQ. IS DIRECT THEN PAYOUT MODE IS AUTO
      setSelPayoutMode(GROUP_PAYOUT_MODE_AUTO_ID.toString());
      setShowPayoutDateControl(false);
    } else {
      let nextDtVar = new Date();
      if (Number(pfId) == GROUP_PAYOUT_FREQ_DAILY_ID) {
        nextDtVar = dateCalc("nextDay");
        setUpcomingPayoutDateText(dayjs(nextDtVar).format(`MMM DD, YYYY`));
        setUpcomingPayoutDateValue(dayjs(nextDtVar).format(`YYYY-MM-DD`));
        setShowPayoutDateControl(false);
      } else if (Number(pfId) == GROUP_PAYOUT_FREQ_WEEKLY_ID) {
        if (changeShowPayoutDate) {
          setShowPayoutDateControl(true);
        }
        nextDtVar = dateCalc("nextSunday");
        setUpcomingPayoutDateText(dayjs(nextDtVar).format(`MMM DD, YYYY`));
        setUpcomingPayoutDateValue(dayjs(nextDtVar).format(`YYYY-MM-DD`));
        let thisDay = new Date();
        let nextDt = thisDay.setDate(thisDay.getDate() + 8);
        setPayoutMaxDate(dayjs(nextDt).format(`YYYY-MM-DD`));

        let newDateVar1 = new Date(dayjs(nextDtVar).format(`YYYY-MM-DD`)); 
        let newDateVar2 = new Date(dayjs(nextDtVar).format(`YYYY-MM-DD`)); 
      } else if (Number(pfId) == GROUP_PAYOUT_FREQ_BI_WEEKLY_ID) {
        if (changeShowPayoutDate) {
          setShowPayoutDateControl(true);
        }
        nextDtVar = dateCalc("biWeekSunday");
        setUpcomingPayoutDateText(dayjs(nextDtVar).format(`MMM DD, YYYY`));
        setUpcomingPayoutDateValue(dayjs(nextDtVar).format(`YYYY-MM-DD`));
        let thisDay = new Date();
        let nextDt = thisDay.setDate(thisDay.getDate() + 15);
        setPayoutMaxDate(dayjs(nextDt).format(`YYYY-MM-DD`));
      } else if (Number(pfId) == GROUP_PAYOUT_FREQ_MONTHLY_FIRST_ID) {
        setShowPayoutDateControl(false);
        nextDtVar = dateCalc("firstDateNextMonth");
        setUpcomingPayoutDateText(dayjs(nextDtVar).format(`MMM DD, YYYY`));
        setUpcomingPayoutDateValue(dayjs(nextDtVar).format(`YYYY-MM-DD`));
      } else if (Number(pfId) == GROUP_PAYOUT_FREQ_MONTHLY_LAST_ID) {
        setShowPayoutDateControl(false);
        nextDtVar = dateCalc("lastDateMonth");
        setUpcomingPayoutDateText(dayjs(nextDtVar).format(`MMM DD, YYYY`));
        setUpcomingPayoutDateValue(dayjs(nextDtVar).format(`YYYY-MM-DD`));
      } else if (Number(pfId) == GROUP_PAYOUT_FREQ_MONTHLY_CUSTOM_ID) {
        if (changeShowPayoutDate) {
          setShowPayoutDateControl(true);
        }
        nextDtVar = dateCalc("nextDay");
        setUpcomingPayoutDateText(dayjs(nextDtVar).format(`MMM DD, YYYY`));
        setUpcomingPayoutDateValue(dayjs(nextDtVar).format(`YYYY-MM-DD`));
        let thisDay = new Date();
        let nextDt = thisDay.setDate(thisDay.getDate() + 31);
        setPayoutMaxDate(dayjs(nextDt).format(`YYYY-MM-DD`));
      } else if (Number(pfId) == GROUP_PAYOUT_FREQ_SEMI_MONTHLY_ID) {
        setShowPayoutDateControl(false);
        if((new Date()).getDate() > 15) {
          nextDtVar = dateCalc("firstDateNextMonth");
          setUpcomingPayoutDateText(dayjs(nextDtVar).format(`MMM DD, YYYY`));
          setUpcomingPayoutDateValue(dayjs(nextDtVar).format(`YYYY-MM-DD`));
        }
        else {
          nextDtVar = new Date();
          nextDtVar.setDate(16);
          setUpcomingPayoutDateText(dayjs(nextDtVar).format(`MMM DD, YYYY`));
          setUpcomingPayoutDateValue(dayjs(nextDtVar).format(`YYYY-MM-DD`));
        } 
      } 
    }
  };

  const editButton = (editType: string): any => {
    const handleEdit = (editType2: string) => {
      if (editType2 === "groupName") {
        setGroupNameEdit(groupName);
        setEditGroupName(true);
      } else if (editType2 === "payoutSettings") {
        fetchAllStaffByAccId();
        setEditGroupStaffDetails(groupStaffDetails);
        setEditStaffDetails(true);
        setViewStaffRowsUpTo(editGroupStaffDetails.length);
        if (
          Number(selPayoutFrequecy) == GROUP_PAYOUT_FREQ_WEEKLY_ID ||
          Number(selPayoutFrequecy) == GROUP_PAYOUT_FREQ_BI_WEEKLY_ID ||
          Number(selPayoutFrequecy) == GROUP_PAYOUT_FREQ_MONTHLY_CUSTOM_ID
        ) {
          setShowPayoutDateControl(true); 
        }
      } else if (editType2 == "tipSettings") {
        setEditTipSettings(true);
      }
    };

    return (
      <button
        className={
          "editbutton " +
          (editType === "groupName" ? " px-5px ml-10px" : "")
        }
        onClick={(e) => handleEdit(editType)}
      >
        {editType === "groupName"
          ? t("group.editGroupName")
          : t("common.edit") + " "}
        <img src={pen} alt="pen" className="pb-2px" />
      </button>
    );
  };

  const onModalSave = () => {
    document.getElementById("btnSave_payoutSettings")?.click(); 
  }

  const saveButton = (editType: string): any => {
    const saveEdit = (name: string) => {
      if (name === "groupName") {
        if (groupNameEdit.trim().length > 0) {
          var details: IGroupNameEdit = {
            groupId: groupId,
            groupName: groupNameEdit,
            userRole: Number(adminUserRole),
          };
          dispatch(updateGroupName(details));
        }
      } else if (name === "payoutSettings") {
        // IF GROUP IS "STAFF" (DEFAULT GROUP), THEN SEND DISTRIBUTION PERCENTAGE AS 100 IN EACH ROW
        // OTHERWISE SUM OF DISTRIBUTION PERCENTAGE OF ALL ROWS SHOULD BE EQUAL TO 100
        let sDet: IGroupStaffDetailsModify[] = [];
        let foundError = 0;
        let totPercentage = 0; 

        if(Number(selPayoutMode)==GROUP_PAYOUT_MODE_AUTO_ID) {
          editGroupStaffDetails.map((item) => {
            totPercentage += parseFloat(item.distributionPercentage)
              ? parseFloat(item.distributionPercentage)
              : 0;
            if (item.staffId==null || item.staffId.toString().trim() == "") {
              foundError = 1;
            }
  
            if (
              foundError == 0 &&
              groupType != GROUP_TYPE_STAFF_ID &&
              (!item.distributionPercentage || item.distributionPercentage == 0)
            ) {
              foundError = 3;
            }
  
            sDet.push({
              distribution_percentage:
                groupType == GROUP_TYPE_STAFF_ID
                  ? 100
                  : Number(item.distributionPercentage),
              payout_destination: Number(item.payoutDestination),
              staff_id: Number(item.staffId),
              staff_name: item.staffName,
              staff_bank_acc: item.staffBankAcc,
            });
          });
  
          if (
            foundError == 0 &&
            editGroupStaffDetails.length > 0 &&
            groupType != GROUP_TYPE_STAFF_ID &&
            totPercentage != 100
          ) {
            foundError = 2;
          }
        }

        if (foundError == 0) {
          if(!payoutModeConfirmPopup && Number(selPayoutMode)==GROUP_PAYOUT_MODE_MANUAL_ID) {
            setPayoutModeConfirmPopup(true); 
          }
          else { 
            let saveGroupData: IGroupModify = Number(selPayoutMode)==GROUP_PAYOUT_MODE_AUTO_ID ? 
              {
                id: groupId,
                payout_mode:
                  groupType == GROUP_TYPE_STAFF_ID
                    ? GROUP_PAYOUT_MODE_AUTO_ID
                    : Number(selPayoutMode),
                payout_frequency: Number(selPayoutFrequecy),
                next_payout_date: upcomingPayoutDateValue,
                tipping_text: groupType == GROUP_TYPE_STAFF_ID ? "" : tippingText ? tippingText : '',
                staff_details: sDet,
                userRole: Number(adminUserRole),
              }
              : 
              {
                id: groupId,
                payout_mode: groupType == GROUP_TYPE_STAFF_ID ? GROUP_PAYOUT_MODE_AUTO_ID : Number(selPayoutMode),
                payout_frequency: Number(selPayoutFrequecy),
                next_payout_date: upcomingPayoutDateValue,
                tipping_text: groupType == GROUP_TYPE_STAFF_ID ? "" : tippingText ? tippingText : '',
                userRole: Number(adminUserRole),
              }
            dispatch(updateGroupDetails(saveGroupData));
            setPayoutModeConfirmPopup(false); 
          }
        } else {
          {
            foundError == 1
              ? setNotification(
                  t("projectName"),
                  t("group.selectStaffError"),
                  "failed",
                  "staff_selectStaffError"
                )
              : foundError == 2
              ? setNotification(
                  t("projectName"),
                  t("group.percentageError"),
                  "failed",
                  "staff_percentageError"
                )
              : setNotification(
                  t("projectName"),
                  t("group.percentageValueError"),
                  "failed",
                  "staff_percentageValueError"
                );
          }
        }
      } else if (name == "tipSettings") {
        let saveGroupData: IGroupModify = {
          id: groupId,
          tipping_text: tippingText ? tippingText : '',
          userRole: Number(adminUserRole),
        };
        dispatch(updateGroupDetails(saveGroupData));
      }
    };

    const cancelEdit = (editType3: string) => {
      if (editType3 === "groupName") {
        setGroupNameEdit("");
        setEditGroupName(false);
      } else if (editType3 === "payoutSettings") {
        setEditGroupStaffDetails(groupStaffDetails);
        setEditStaffDetails(false);

        setSelPayoutFrequecy(
          groupDetails?.payoutFrequency
            ? groupDetails.payoutFrequency.toString()
            : ""
        );

        // ************* SET NEXT POOAYOUT DATE ALSO *******************
        if (groupDetails?.payoutFrequency && Number(groupDetails.payoutFrequency) == GROUP_PAYOUT_FREQ_DIRECT_ID) {
          setUpcomingPayoutDateText(`${t("group.payoutDirect")}`);
          setUpcomingPayoutDateValue("");
          // IF PAYOUT FREQ. IS DIRECT THEN PAYOUT MODE IS AUTO
          setSelPayoutMode(GROUP_PAYOUT_MODE_AUTO_ID.toString());
          setShowPayoutDateControl(false);
        } else {
          if (groupDetails?.payoutFrequency) {
            onChangePayoutFrequecy(
              groupDetails.payoutFrequency.toString(),
              false
            );
          }
          if (
            groupDetails?.nextPayout &&
            groupDetails.nextPayout.trim().length == 10
          ) {
            let nextDtVar = new Date(groupDetails.nextPayout+"T00:00:00");  
            setUpcomingPayoutDateText(dayjs(nextDtVar).format(`MMM DD, YYYY`));
            setUpcomingPayoutDateValue(dayjs(nextDtVar).format(`YYYY-MM-DD`));
          }
        }
        // *************************************************************

        setSelPayoutMode(
          groupDetails?.payoutMode ? groupDetails.payoutMode.toString() : ""
        );
        setTippingText(
          groupDetails?.tippingText ? groupDetails.tippingText : ""
        );
      } else if (editType3 == "tipSettings") { 
        setTippingText(
          groupDetails?.tippingText ? groupDetails.tippingText : ""
        );
        setEditTipSettings(false); 
      }
    };

    return (
      <div className="flex gap-4">
        <button
          className={
            "editbutton" + (editType === "groupName" ? " mt-7px " : "")
          }
          style={{ display: "block" }}
          onClick={(e) => cancelEdit(editType)}
        >
          {t("common.cancel")}
        </button>
        <button id={"btnSave_"+editType}
          className={
            "editbutton" + (editType === "groupName" ? " mt-7px " : "")
          }
          style={{ display: "block" }}
          onClick={(e) => saveEdit(editType)}
        >
          {t("defaultSettings.save")}
        </button>
      </div>
    );
  };

  // FUNCTION TO FETCH NAMES USING IDs OF PRE DEFINED CONSTANTS
  const getIdValue = (id: number, idType: string) => {
    if (idType == "payoutFrequency") {
      let tmpArr = ARR_PAYOUT_FREQUENCIES.find((item) => item.id == id);
      if (tmpArr?.name) {
        return tmpArr.name;
      } else {
        return "";
      }
    } else if (idType == "payoutMode") {
      if (id == GROUP_PAYOUT_MODE_AUTO_ID) {
        return t("payoutModes.auto");
      } else if (id == GROUP_PAYOUT_MODE_MANUAL_ID) {
        return t("payoutModes.manual");
      } else {
        return "";
      }
    } else if (idType == "payoutDestination") {
      if (id == PAYOUT_ACCOUNT) {
        return PAYOUT_ACCOUNT_TEXT;
      } else if (id == PAYOUT_STAFF) {
        return PAYOUT_STAFF_TEXT;
      } else {
        return "";
      }
    }
  };

  const removePayeeRow = (remIndex: number) => {
    let temArryObj = [];
    editGroupStaffDetails.map((item, idx) => {
      if (remIndex != idx) {
        temArryObj.push(item);
      }
    });
    setEditGroupStaffDetails(temArryObj);
    // REMOVE THE STAFF WHICH ARE ALREADY ADDED TO THE GROUP FROM SEARCH RESPONSE LIST
    let arrIds = [];
    if (temArryObj.length > 0) {
      arrIds = temArryObj.map((item) => {
        return item.staffId;
      });
    }
    // IF STAFF GROUP - REMOVE THE ACCOUNT ROW WITH STAFFID 0 
    let newPayeeListObj = groupType==GROUP_TYPE_STAFF_ID ? payeeList.filter(item => item.id > 0) : payeeList; 
    let filteredPayeeList = newPayeeListObj.filter(
      (item) => !arrIds.includes(item.id)
    );
    setAllPayeeList(filteredPayeeList);
    setTempAllPayeeList(filteredPayeeList);
  };

  const addPayeeRow = () => {
    setViewStaffRowsUpTo(editGroupStaffDetails.length + 1);
    setEditGroupStaffDetails([
      ...editGroupStaffDetails,
      {
        distributionPercentage: 0,
        payoutDestination: PAYOUT_ACCOUNT,
        staffId: "",
        staffName: "",
        staffBankAcc: "",
        newRow: "yes",
      },
    ]);
  };

  const dummyFunction = () => {
    console.log("dummyFunction");
  };

  const changeGroupStaffDetails = (
    mIndex: number,
    fieldValue: string,
    fieldType: string
  ) => {
    let tmpArrObj = [];
    editGroupStaffDetails.map((item, idx) => {
      if (mIndex == idx) {
        if (fieldType == "dPerc") {
          tmpArrObj.push({
            ...item,
            distributionPercentage: fieldValue.substring(0, 6),
          });
        } else if (fieldType == "pDest") {
          tmpArrObj.push({ ...item, payoutDestination: Number(fieldValue) });
        }
      } else {
        tmpArrObj.push(item);
      }
    });
    setEditGroupStaffDetails(tmpArrObj);
  };

  const fetchAllStaffByAccId = () => {
    setAllPayeeList([]);
    setTempAllPayeeList([]);
    dispatch(
      fetchPayeeByNameAccId({
        account_id: groupAccId.toString(),
        name: "",
      })
    );
  };

  // SEARCH STAFF BY NAME  
  const newStaffSearch = (nameSearch: string, index) => {
    setStaffSearchName(nameSearch);

    let temArryObj = editGroupStaffDetails;
    temArryObj[index].staffName = nameSearch;
    setEditGroupStaffDetails(temArryObj);

    if (nameSearch.trim().length > 0) {
      let tmpArr = allPayeeList.filter((item) =>
        item.fullName.toLowerCase().includes(nameSearch.toLowerCase())
      );
      setTempAllPayeeList(tmpArr);
    } else {
      setTempAllPayeeList(allPayeeList);
    }
  };

  const handleSelect = (payeeId: number, payeeName: string, index: number) => {
    setStaffSearchName(payeeName);

    let temArryObj = editGroupStaffDetails;
    temArryObj[index].staffName = payeeName;
    temArryObj[index].staffId = payeeId;
    temArryObj[index].payoutDestination = payeeId && Number(payeeId) > 0 ? PAYOUT_STAFF : PAYOUT_ACCOUNT; 
    temArryObj[index].newRow = "";
    setEditGroupStaffDetails(temArryObj);
    // REMOVE THE STAFF WHICH ARE ALREADY ADDED TO THE GROUP FROM SEARCH RESPONSE LIST
    let arrIds = [];
    if (temArryObj.length > 0) {
      arrIds = temArryObj.map((item) => {
        return item.staffId;
      });
    }
    let filteredPayeeList = payeeList.filter(
      (item) => !arrIds.includes(item.id)
    );
    setAllPayeeList(filteredPayeeList);
    setTempAllPayeeList(filteredPayeeList);
  };

  const handleCopy = (copyText) => {
    navigator.clipboard.writeText(copyText);

    setNotification(
      t("projectName"),
      t("common.linkCopied"),
      "success",
      "linkCopied"
    );
  };

  const setDateFormatValue = (dateValue: Date) => {
    // let arrTmp = dateValue.split("-");
    let yyyy = dateValue.getFullYear().toString(); // arrTmp[0].substring(0, 4);
    let mm =
      dateValue.getMonth() + 1 < 10
        ? "0" + (dateValue.getMonth() + 1).toString()
        : (dateValue.getMonth() + 1).toString();
    let dd =
      dateValue.getDate() < 10
        ? "0" + dateValue.getDate().toString()
        : dateValue.getDate().toString();
    setUpcomingPayoutDateValue(yyyy + "-" + mm + "-" + dd);
    setUpcomingPayoutDateText("");
  };

  return (
    <div className="box-padding">
      <div className="box pt-4">
        <div className="account mb-4">
          <div className="flex space-between">
            <div className="flex col mr-35px">
              <h1 className="mt-2px">
                {t("group.group")} {!editGroupName ? groupName : ""}
              </h1>
              {editGroupName ? (
                <Textfield
                  type="text"
                  className={
                    "mx-10px max-width-320px" +
                    (groupNameEdit.length == 0 ? " input-error " : "")
                  }
                  value={groupNameEdit}
                  onChange={(e) => setGroupNameEdit(e.target.value)}
                />
              ) : (
                ""
              )}
              {groupType > 0 &&
              groupType != GROUP_TYPE_STAFF_ID &&
              checkActionPermission(adminUserRole, PERM_EDIT_GROUP_DETAILS) ? (
                <div className="ml-10px">
                  {!editGroupName
                    ? editButton("groupName")
                    : saveButton("groupName")}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="flex gap-4">
              <ButtonPrimary
                to={ROUTE_STAFF_LIST}
                link={true}
                buttonLabel={t("buttons.manageStaff")}
              />

              {checkActionPermission(adminUserRole, PERM_QRCODE_ORDER) && (
                <ButtonPrimary
                  to={ROUTE_QRCODES}
                  link={true}
                  buttonLabel={t("buttons.OrderQRCodes")}
                />
              )}

              <ButtonPrimary
                to={ROUTE_HOTEL_USERS}
                link={true}
                buttonLabel={t("buttons.manageUsers")}
              />
            </div>
          </div>
          <div className="text-grey text-left fontsize-16 font-500 mt-2px">
            {groupAccId > 0 ? groupAccName : ""}
          </div>
          <div className="">&nbsp;</div>
          <div className="h-two pb-0">
            {t("group.payoutsettingsHeading")}{" "}
            {checkActionPermission(adminUserRole, PERM_EDIT_GROUP_DETAILS) && (
              <>
                {!editStaffDetails
                  ? editButton("payoutSettings")
                  : saveButton("payoutSettings")}
              </>
            )}{" "}
          </div>
          <div className="left-center min-height-60px mb-15px">
            <div className="h-three me-3">{t("group.payoutFrequency")}</div>
            <div>
              {!editStaffDetails ? (
                <>
                  <span className="text-data">
                    {getIdValue(Number(selPayoutFrequecy), "payoutFrequency")}
                  </span>
                </>
              ) : (
                <>
                  <SelectField
                    value={selPayoutFrequecy}
                    className={"max-width-165px"}
                    onChange={(e) =>
                      onChangePayoutFrequecy(e.target.value, true)
                    }
                    options={
                      ARR_PAYOUT_FREQUENCIES &&
                      ARR_PAYOUT_FREQUENCIES.map((item) => (
                        <>
                          <option value={item.id}>{item.name}</option>
                        </>
                      ))
                    }
                  />
                </>
              )}
            </div>
            <div className="col-1"></div>
            <div>
              <div className={"left-center mt-22px"}>
                <div className="h-three me-3">{t("group.payoutdateUpcoming")}</div>

                <div className="text-data relative">
                  {editStaffDetails && showPayoutDateControl ? (
                    <>
                      <span
                        className="calendar-icon pointer"
                        onClick={(e) =>
                          document.getElementById("payoutDatePicker")?.click()
                        }
                      >
                        <i className={"fa fa-calendar"}></i>
                      </span>
                      <DatePicker
                        id="payoutDatePicker"
                        selected={new Date(upcomingPayoutDateValue+'T00:00:00')}
                        onChange={(date) => setDateFormatValue(date)}
                        minDate={new Date(payoutMinDate)}
                        maxDate={new Date(payoutMaxDate)}
                        excludeDates={arrExcludeDates}
                      />
                      
                    </>
                  ) : (
                    upcomingPayoutDateText
                  )}
                </div>
                {editStaffDetails && showPayoutDateControl ? <div className="ml-10px validFont mt-m1px">{t("common.dateFormat")}</div> : <></>}
              </div>  
              <div className="left-center mt-7px">
                 <><div className="payout-hfont">{payPeriodDates.trim().length > 1 ? (t("group.payPeriod") + ' ' + payPeriodDates) : <span>&nbsp;</span>}</div></>
              </div>
            </div>

            <div className="col-1"></div>
            {groupType > 0 && groupType != GROUP_TYPE_STAFF_ID ? (
              <>
                <div className="h-three me-3">{t("group.payoutMode")}</div>
                <div className="text-data">
                  {!editStaffDetails ? (
                    <>
                      <span className="text-data">
                        {getIdValue(Number(selPayoutMode), "payoutMode")}
                      </span>
                    </>
                  ) : Number(selPayoutFrequecy) ==
                    GROUP_PAYOUT_FREQ_DIRECT_ID ? (
                    <>{t("payoutModes.auto")}</>
                  ) : (
                    <>
                      <SelectField
                        value={selPayoutMode}
                        onChange={(e) => {
                            setSelPayoutMode(e.target.value);
                            if(Number(e.target.value)==GROUP_PAYOUT_MODE_MANUAL_ID) {
                              setEditGroupStaffDetails([]); 
                            } 
                          }
                        }
                        options={
                          <>
                            <option
                              value={GROUP_PAYOUT_MODE_AUTO_ID.toString()}
                            >
                              {t("payoutModes.auto")}
                            </option>
                            <option
                              value={GROUP_PAYOUT_MODE_MANUAL_ID.toString()}
                            >
                              {t("payoutModes.manual")}
                            </option>
                          </>
                        }
                      />
                    </>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          {/* ******************* PAYEE LIST - START ******************* */}
          {Number(selPayoutMode)!=GROUP_PAYOUT_MODE_MANUAL_ID && <><div className="editBox mb-15px">
            <div className="w-100 mt-10px">
              <div className="w-100 flex gap-3 ">
                <div className="w-25 payout-hfont">{t("group.payeeName")}</div>
                {groupType > 0 && groupType != GROUP_TYPE_STAFF_ID ? (
                  <div className="w-25 payout-hfont">
                    {t("group.percentage")}
                  </div>
                ) : (
                  <></>
                )}
                <div className="w-25 payout-hfont">
                  {t("group.payoutDestination")}
                </div>
                <div className="w-25 payout-hfont">&nbsp;</div>
              </div>

              {editGroupStaffDetails && editGroupStaffDetails.length == 0 && (
                <>
                  <div className="w-100 flex mt-2 center red-text text-data">
                    {/* {t("common.noDataAvailable")} */}
                  </div>
                </>
              )}
              {editGroupStaffDetails &&
                editGroupStaffDetails.map((item, index) => {
                  return (
                    <div
                      className={
                        "w-100 flex gap-3 mt-2 " +
                        (viewStaffRowsUpTo < index + 1 ? " hide " : "")
                      }
                    >
                      <div className="w-25 relative">
                        <span className={ " index " + (!editStaffDetails ? " disabled" : "") } >
                          {groupType!=GROUP_TYPE_STAFF_ID && item.staffId!=null && item.staffId==0 ? t("group.account") : t("group.staff")}
                        </span>
                        <Textfield
                          type="text"
                          placeholder={t("group.payeeNamePlaceHolder")}
                          className={
                            (item.staffId==null || item.staffId.toString().trim() == ""
                              ? " input-error "
                              : "") +
                            " w-100 text-data py-8px " +
                            (!editStaffDetails ? " disabled" : "")
                          }
                          value={item.staffName}
                          disabled={
                            item.newRow == "yes" && editStaffDetails
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            item.newRow == "yes"
                              ? newStaffSearch(e.target.value, index)
                              : dummyFunction();
                          }}
                        />

                        {item.newRow == "yes" &&
                          item.staffId == "" &&
                          tempAllPayeeList.length >= 1 && (
                            <div className="group-dropdown">
                              {tempAllPayeeList.map((item) => {
                                if(groupType==GROUP_TYPE_STAFF_ID && Number(item.id)==0) { 
                                  return(""); 
                                }
                                else { 
                                    return (
                                      <span
                                        onClick={(e) =>
                                          handleSelect(item.id, item.fullName, index)
                                        }
                                      >
                                        {item.fullName} 
                                      </span>
                                    )
                                  }
                                }
                              )}
                            </div>
                          )}
                      </div>

                      {groupType > 0 && groupType != GROUP_TYPE_STAFF_ID && (
                        <>
                          <div className="w-25 percentage-input">
                            <span
                              className={
                                "px-10px " +
                                (!editStaffDetails ? " disabled" : "")
                              }
                            >
                              {t("group.percentageIcon")}
                            </span>
                            <Textfield
                              type="number"
                              className={
                                "w-100 text-data py-8px " +
                                (!editStaffDetails ? " disabled" : "")
                              }
                              value={item.distributionPercentage}
                              disabled={!editStaffDetails ? true : false}
                              onChange={(e) =>
                                changeGroupStaffDetails(
                                  index,
                                  e.target.value,
                                  "dPerc"
                                )
                              }
                              max="100"
                            />
                          </div>
                        </>
                      )}

                      <div className="w-25">
                        <div className="percentage-input">
                          <SelectField
                            type="inputType"
                            name=""
                            id=""
                            className={!editStaffDetails ? " disabled" : ""}
                            value={item.payoutDestination}
                            disabled={!editStaffDetails ? true : false}
                            onChange={(e) =>
                              changeGroupStaffDetails(
                                index,
                                e.target.value,
                                "pDest"
                              )
                            }
                            options={ 
                              <>
                                <option value={PAYOUT_ACCOUNT}>
                                  {PAYOUT_ACCOUNT_TEXT}
                                </option>
                                {item.staffId > 0 && <option value={PAYOUT_STAFF}>
                                  {PAYOUT_STAFF_TEXT}
                                </option>}
                              </>
                            }
                          />
                        </div>
                      </div>

                      <div className="w-25">
                        <button
                          className={
                            "border border-6r px-1 py-2px " +
                            (!editStaffDetails
                              ? " disabled cursor-normal disabled-text-color "
                              : " bg-white ")
                          }
                          onClick={(e) =>
                            !editStaffDetails
                              ? dummyFunction()
                              : removePayeeRow(index)
                          }
                        >
                          <CloseRounded />
                        </button>
                      </div>
                    </div>
                  );
                })}

                {/* <div className="w-100 left-center">
                    <div>{payeeList.length+"::"+((payeeList.filter(item => Number(item.id) > 0)).length)+"::"+editGroupStaffDetails.length}
                    </div>
                    <div>{" groupType: "+groupType+" :: GROUP_TYPE_STAFF_ID: "+GROUP_TYPE_STAFF_ID}</div>
                    <div>{JSON.stringify(payeeList)}</div>
                </div> */}
              {/* IF STAFF GROUP DO NOT SHOW THE ACCOUNT PAYEE ROW WITH STAFFID 0 */}
              <div className="w-100 left-center gap-3 mt-2">
                {editStaffDetails &&
                payeeList.length > 0 &&
                (
                  (groupType != GROUP_TYPE_STAFF_ID && payeeList.length > editGroupStaffDetails.length) 
                  ||
                  (groupType == GROUP_TYPE_STAFF_ID && ((payeeList.filter(item => Number(item.id) > 0)).length) > editGroupStaffDetails.length) 
                ) ? (
                  <div>
                    <ButtonInLine
                      className="mt-1 py-2 px-10px text-dark text-no-transform fontsize-14"
                      onClick={(e) => addPayeeRow()}
                      buttonLabel={t("group.addPayee")}
                    />
                  </div>
                ) : (
                  <></>
                )}

                {!editStaffDetails && (
                  <div className="mt-5px ">
                    {editGroupStaffDetails.length > viewStaffRowsUpTo ? (
                      <Link
                        to="# "
                        className="link-p text-blue fontsize-14 fontweight-500"
                      >
                        <div
                          onClick={(e) =>
                            setViewStaffRowsUpTo(editGroupStaffDetails.length)
                          }
                          className=""
                        >
                          {t("group.viewAll")} ({editGroupStaffDetails.length})
                        </div>
                      </Link>
                    ) : editGroupStaffDetails.length > 0 &&
                      editGroupStaffDetails.length > GROUP_STAFF_DETAILS_ROWS &&
                      editGroupStaffDetails.length == viewStaffRowsUpTo ? (
                      <Link to="# " className="link-p text-blue fontsize-14 ">
                        <div
                          onClick={(e) =>
                            setViewStaffRowsUpTo(GROUP_STAFF_DETAILS_ROWS)
                          }
                          className=" fontweight-500 "
                        >
                          {t("group.hide")}{" "}
                        </div>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div></>}
          {/* ******************* PAYEE LIST - END ******************* */}
          
          {groupType > 0 && groupType != GROUP_TYPE_STAFF_ID && <> <div className="editBox ">
            <div className="space-between">
              <div className="w-40">
                <div className="flex-end gap-4 w-100">
                  {qrCodePng && qrCodePng.length > 3 && (
                    <img
                      src={qrCodePng ? qrCodePng : ""}
                      style={{
                        height: "141px",
                        width: "141px",
                        background: "white",
                      }}
                      alt={`${t("staffprofile.QrCode")}`}
                    />
                  )}

                  <div className="flex-col space-between py-1">
                
                    {qrCodePdf && qrCodePdf.length > 3 && (
                      <ButtonInLine
                        type="link"
                        href={qrCodePdf}
                        download={true}
                        buttonLabel={t("staffprofile.DownloadQrcode")}
                      />
                    )}

                    {qrCodeLink && qrCodeLink.length > 3 && (
                      <ButtonInLine
                        onClick={(e) => handleCopy(qrCodeLink)}
                        copyIcon={true}
                        buttonLabel={t("staffprofile.copyQrCodeLink")}
                        className="px-2"
                      />
                    )}

                    {groupType > 0 && groupType != GROUP_TYPE_STAFF_ID && checkActionPermission(adminUserRole, PERM_QRCODE_ORDER) && (
                      <ButtonInLine
                        onClick={e => navigate(ROUTE_QRCODES)}
                        buttonLabel={t("staffprofile.orderQrCards")}
                        className="px-2"
                      />
                    )}
                  </div>

                  <div className="flex-col">
                    <div>
                      <span className="h-three me-3">{t("staffprofile.QRCodeScanTotal")} </span>
                      <span className="text-data relative"> {qrcScanCountTotal}</span>
                    </div>
                    <div>
                      <span className="h-three me-3">{t("staffprofile.QRCodeScan30Days")} </span>
                      <span className="text-data relative"> {qrcScanCount30Days}</span>
                    </div>                    
                  </div>
                </div>
              </div>
              <div className="w-60">&nbsp;</div>
            </div>
          </div>
          </>} 

          {/* ******************* TIP PAGE SETTINGS - START ******************* */}

          {groupType != GROUP_TYPE_STAFF_ID  && <><div className="h-two no-top-border">
            {t("group.tipPageSettings")}{" "}
            {/* {checkActionPermission(adminUserRole, PERM_EDIT_GROUP_DETAILS) && (
              <>
                {!editTipSettings
                  ? editButton("tipSettings")
                  : saveButton("tipSettings")}
              </>
            )} */}
          </div>

          <div className="editBox">
            <div className="space-between gap-5">
              <div className="w-75">
                {/* <div className="h-three-black me-3 flex ">
                  {t("group.tipPageSettings")}
                </div> */}
                <div className="space-between py-3 gap-3 w-100 ">
                  <div className="flex gap-2 ">
                    
                    <label className="h-three mt-2 min-width-90px ">
                      {t("group.tippingToText")}
                    </label>
                    <div className="relative ">
                      <Textfield
                        type="text"
                        value={tippingText}
                        name={"tippingText"}
                        disabled={!editStaffDetails}
                        className={`text-left min-width-350px ${
                          !editStaffDetails && " border-white "
                        }`}
                        onChange={(e) => setTippingText(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          </>}
           
          {/* ******************* TIP PAGE SETTINGS - END ******************* */}
          

        </div>
      </div>

      <Modal
        open={payoutModeConfirmPopup}
        onClose={() => setPayoutModeConfirmPopup(false)}
        aria-labelledby={"confirmSave"} 
        aria-describedby=""
      >
        <Box sx={style1}>
          <div className="idproof-modal div-center text-center relative">
            <div className="page-heading">
              <img src={warning} alt="" />
            </div>
            <div className="idproof-flex-center div-center">
              <div>
                {t("group.payoutModeChangeConfirm")}
                <br />
                {""}
              </div>
            </div>

            <div className="idproof-margin"></div>
            <div className="flex w-100">
              <ButtonModal
                type="cancel"
                onClick={(e) => setPayoutModeConfirmPopup(false)}
                buttonLabel={t("group.no")}
              />
              <ButtonModal
                onClick={(e) => onModalSave()}
                buttonLabel={t("group.yes")}
                className=" ml-15px"
              />
            </div>
          </div>
        </Box>
      </Modal>

    </div>
  );
}

export default GroupSettings;
