import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import tova from "../assets/images/tova-logo-web.png";

import clock from "../assets/images/nav-clock-web.svg";
import mail from "../assets/images/nav-mail-web.svg";
import phone from "../assets/images/nav-phone-web.svg";

import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROUTE_ACCOUNTS_LOGIN, ROUTE_SCHEDULE_DEMO } from "../config/env";

function NavBar() {
  const { t } = useTranslation();
  const [sidebar, setsidebar] = useState(false);

  const toggleSidebar = () => {
    setsidebar((prevState) => !prevState);
  };

  const headerItem = [
    { img: phone, name: t("nav.phone") },
    { img: mail, name: t("nav.mail") },
    { img: clock, name: t("nav.time") },
  ];

  const navigation = [
    { name: t("nav.home"), href: "/" },
    { name: t("nav.about"), href: "/about" },
    { name: t("nav.support"), href: "/support" },
  ];

  return (
    <div className="nav-web">
      <div
        className="w-100 center "
        style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.16)" }}
      >
        <nav className="nav-web-1 py-13px">
          <div className="w-100 flex-end gap-40px">
            {headerItem.map((item) => (
              <div key={item.name} className="pointer">
                <img src={item.img} alt={item.name} className="mr-8px" />{" "}
                {item.name}
              </div>
            ))}
          </div>
        </nav>
      </div>

      <div className="header-web max-w-1400px ">
      <Link  to={"/ "}> <img src={tova} alt="tova" height={"32px"} width={"auto"} /></Link>
        <div className="contents-web">
          <div className="open">
            <IconButton onClick={toggleSidebar}>
              <MenuIcon
                style={{
                  zIndex: 100,
                  color: "white",
                  backgroundColor: "transparent",
                }}
              />
            </IconButton>
          </div>
          <ul className={sidebar ? "items-web  items__open" : "items-web"}>
            <div className="close">
              <IconButton onClick={toggleSidebar}>
                <CloseIcon style={{ zIndex: 100, color: "white" }} />
              </IconButton>
            </div>
            {navigation.map((item) => (
              <li>
                <Link key={item.name} to={item.href}>
                  {item.name}
                </Link>
              </li>
            ))}
            <li>
              <a href={ROUTE_SCHEDULE_DEMO} className="btn-login"
                target="blank" > {t("home.scheduleDemo")}</a>
            </li>
            <li>
              
            <Link to={ROUTE_ACCOUNTS_LOGIN} className="btn-getStart ml-4 w-[82px]" >
                {t("nav.btnLogin")}
              </Link>
              {/* <a href={"https://user.usetova.com"} className="btn-getStart ml-4 w-[82px]" > {t("nav.btnLogin")}  </a> */}
              
            </li>

            {sidebar &&
              headerItem.map((item) => (
                <div key={item.name}>
                  <img src={item.img} alt={item.name} /> {item.name}
                </div>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
