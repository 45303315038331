import { SA_API_CONTACT_US, SA_API_URL_ACC_USER_PWD_RESET_SUBMIT } from "../config/api_urls";
import { postWithoutAuth, setNotification } from "./apiService";
import { t } from "i18next";

export interface IResetPassword {
  f_token: string;
  token: string;
  user_id: string;
  password: string;
  confirm_password: string;
  expiry_time: string;
}

export interface IContactUs {
  first_name: string;
  last_name: string;
  mobile: string;
  mail: string;
  message: string;
  type?: string;
}

// FOR WEBSITE CHANGES
// const client = applyCaseMiddleware(axios.create());

//FUNCTION TO RESET PASSWORD WITHOUT AUTHENTICATION
export const ResetPasswordAction = async (f_token: any, token: any, user_id: any, expiry_time: any, password: string, confirm_password: string) => {
  const auth: IResetPassword = { f_token, token, user_id, expiry_time, password, confirm_password };
  return await (
    await postWithoutAuth(SA_API_URL_ACC_USER_PWD_RESET_SUBMIT, auth)
  ).data;
};

// FOR WEBSITE CHANGES
// export const contactUs = async (first_name: string,
//     last_name: string,
//     mobile: string, 
//     mail: string,
//     message: string,
//     type: string
//     ) => {
//     try {
//       const body: IContactUs = { first_name, last_name, mobile, mail, message,type};
//       const url = 'https://tovademo.com/api/users/send-contact-mail/'; 
//       const response = await client.post(url, body);
//       let code=response.status        
//       if(code===400){
//         setNotification(
//           t("messages.appName"), 
//           t("messages.somethingWentWrong"),
//           "failed",
//           "webContactMessage"
//         )

//       }else{
//         setNotification(
//           t("messages.appName"),  
//           t("messages.webContactUsMessageSuccess"),
//           "success",
//           "webContactMessage"
//         )

//       }
//       return (await response);


//     } catch (error: any) {

//         setNotification(
//           t("messages.appName"),  
//           t("messages.somethingWentWrong"),
//           "failed",
//           "webContactMessage"
//         )

//     }
//   }


//FUNCTION FOR CONTACT US IN WEBPAGE
export const contactUs = async (first_name: string,
  last_name: string,
  mobile: string,
  mail: string,
  message: string) => {
  try {
    const body: IContactUs = { first_name, last_name, mobile, mail, message };
    const url = SA_API_CONTACT_US;
    const response = postWithoutAuth(url, body);
    let code = (await response).data.code

    if (code === 400) {
      setNotification(
        t("messages.appName"),
        t("messages.somethingWentWrong"),
        "failed",
        "webContactMessage"
      )

    } else {
      setNotification(
        t("messages.appName"),
        t("messages.webContactUsMessageSuccess"),
        "success",
        "webContactMessage"
      )

    }
    return (await response).data;


  } catch (error: any) {

    setNotification(
      t("messages.appName"),
      t("messages.somethingWentWrong"),
      "failed",
      "webContactMessage"
    )

  }
}