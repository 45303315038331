import React from "react";
import { useTranslation } from "react-i18next";
import backButton from "../../assets/images/backButton.svg";
import email from "../../assets/images/email.svg";
import { ROUTE_RESET_PASSWORD } from "../../config/env";

function ForgotPassEmailTemplate() {
  const { t } = useTranslation();
  return (
    <div className="center mt-171px">
      <form className="loginbox ">
        <div className="space-between center">
          <div className="pointer-cursor">
            <img src={backButton} alt="backButton" />
          </div>
          <h2 className="mb-0 mt-2px">{t("checkEmail.headingCheckEmail")}</h2>
          <div></div>
        </div>

        <div className="mt-35px">
          <img
            src={email}
            alt="email"
            style={{ height: "77px", width: "77px" }}
          />
        </div>
        <h1 className="mt-18px mb-12px">{t("checkEmail.heading2CheckEmail")}</h1>

        <p>
          {t("checkEmail.para1")}
          <br />
          {t("checkEmail.para2")}{" "}
        </p>

        <p>
          <a href={ROUTE_RESET_PASSWORD} className="text-blue link-p">{t("checkEmail.link")}</a>
          {t("checkEmail.para3")} <br />
          {t("checkEmail.para4")}
        </p>
      </form>
    </div>
  );
}

export default ForgotPassEmailTemplate;
