import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../store';
import { fetchOrderHistory, fetchSKUNames } from '../../services/qrCodesHistoryServices';

export interface FilterState {
    purchaseHistoryloading: 'idle' | 'loading' | 'succeeded' | 'error';
    skuNamesDataloading: 'idle' | 'loading' | 'succeeded' | 'error';
    qrCodeOrderHistoryData: [];
    skuNamesData: [], 
    currentPage: number;
    totalSize: number;
}

const initialState: FilterState = {
    purchaseHistoryloading: 'idle',
    skuNamesDataloading: 'idle', 
    qrCodeOrderHistoryData: [],
    skuNamesData: [],
    currentPage: 1,
    totalSize: 0,
};

export const QRCodesYourOrdersSlice = createSlice({
    name: 'QRCodesYourOrdersSlice',
    initialState,
    reducers: {
        resetQrCodeOrderHistoryData: (state, action: PayloadAction<any>) => {
            state.qrCodeOrderHistoryData = action.payload;
        },
        setCurrentPage: (state, action: PayloadAction<any>) => {
            state.currentPage = action.payload;
          },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrderHistory.pending, (state) => {
            state.qrCodeOrderHistoryData = [];
            state.purchaseHistoryloading = 'loading';
        });
        builder.addCase(fetchOrderHistory.fulfilled, (state, action) => {
            // state.qrCodeOrderHistoryData = [...state.qrCodeOrderHistoryData,...action.payload.results ]as [];
            state.qrCodeOrderHistoryData = action.payload.results;
            state.totalSize=action.payload.count
            state.purchaseHistoryloading = 'succeeded';
        });
        builder.addCase(fetchOrderHistory.rejected, (state, { payload }: any) => {
            state.purchaseHistoryloading = 'error';
            state.totalSize=0;
        });

        
        builder.addCase(fetchSKUNames.pending, (state) => {
            //state.qrCodeOrderHistoryData = [];
            state.skuNamesDataloading = 'loading';
        });
        builder.addCase(fetchSKUNames.fulfilled, (state, action) => {
            state.skuNamesData = action.payload.skuProductName; //sku_product_name
            state.skuNamesDataloading = 'succeeded';
        });
        builder.addCase(fetchSKUNames.rejected, (state, { payload }: any) => {
            state.skuNamesDataloading = 'error';
            state.totalSize=0;
        });


    },
});

// Action creators are generated for each case reducer function
export const { setCurrentPage, resetQrCodeOrderHistoryData } = QRCodesYourOrdersSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectQRCodesYourOrdersSlice = (state: RootState) =>
    state.qrCodesOrdersData;

export const QRCodesYourOrdersSliceReducer = QRCodesYourOrdersSlice.reducer;
