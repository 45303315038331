import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// COMMON PRIMARY BUTTON COMPONENT WITH LINK AND ONCLICK PROPS

interface ButtonPrimaryProps {
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    buttonLabel: string;
    buttonType?: 'submit' | 'button';
    link?: boolean;
    to?: string;
    icon?: boolean;
  }

export const ButtonPrimary: React.FC<ButtonPrimaryProps> = React.memo((props) => {
    const { t } = useTranslation();
    return (<>
        {
            props.link ?
                <Link to={props.to} className='common-btn'>{props.buttonLabel}</Link>
                :
                <button
                    onClick={props.onClick}
                    className={`common-btn ${props.className}`}
                    type={props.buttonType?props.buttonType:'button'}
                >
                    {props.icon && <span>{t("common.plus")}</span>}
                    {props.buttonLabel}
                </button>
        }</>
    )
});


