import { createAsyncThunk } from "@reduxjs/toolkit";
import { IStaffDetails } from "../models/staffProfileInterface";
import { get, post, postFile, put } from "./apiService";
import { SA_API_URL_STAFF_DELETE_ID_PROOF, SA_API_URL_STAFF_PROFILE, SA_API_URL_STAFF_PROFILE_UPDATE, SA_API_URL_STAFF_QRCODE, SA_API_URL_STAFF_REMOVE_AVATAR, SA_API_URL_STAFF_UPLOAD_AVATAR, SA_API_URL_STAFF_UPLOAD_ID_PROOF, SA_API_URL_STAFF_W9_FORM, SA_API_URL_STAFF_WITHHOLDINGS } from "../config/api_urls";

// FUNCTION TO FETCH WITHHOLDING dETAILS
export const fetchWithholdingsDetail = createAsyncThunk(
  'account/fetchWithholdingsDetail',
  async ({ staffId, year }: IStaffDetails, thunkAPI: any) => {
    try {
      const url = SA_API_URL_STAFF_WITHHOLDINGS+`?staff_id=${staffId}&year=${year}`;
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO FETCH STAFF DETAILS
export const fetchStaff = createAsyncThunk(
  'account/fetchStaff',
  async ({ staffId, accountId }: IStaffDetails, thunkAPI: any) => {
    try {
      const url = SA_API_URL_STAFF_PROFILE+`${staffId}/${accountId}/`;
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO GET QRCODE OF STAFF
export const getQrCode = createAsyncThunk(
  'account/getQrCode',
  async ({ staffId, accountId }: IStaffDetails, thunkAPI: any) => {
    try {
      const url = SA_API_URL_STAFF_QRCODE+`?staff_id=${staffId}&hotel_id=${accountId}`; 
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO UPDATE STAFF DETAILS
export const updateStaffDetail = createAsyncThunk(
  'account/updateStaffDetail',
  async (form: IStaffDetails, thunkAPI: any) => {
    try {
      const url = SA_API_URL_STAFF_PROFILE_UPDATE+form.staffId+'/';
      const response = put(url, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO UPLOAD STAFF PROFILE PIC
export const uploadProfilePic = createAsyncThunk(
  'uploadProfilePic',
  async (form: IStaffDetails, thunkAPI: any) => {
    
    try {
      const url = SA_API_URL_STAFF_UPLOAD_AVATAR+form.staffId+'/';
      const response = post(url, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO REMOVE PROFILE PIC
export const removeLogo = createAsyncThunk(
  'removeLogo',
  async (form: IStaffDetails, thunkAPI: any) => {
    try {
      const url = SA_API_URL_STAFF_REMOVE_AVATAR+form.staffId+'/';
      const response = post(url, '');
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO DOWNLOAD-W9
export const downloadW9 = createAsyncThunk(
  'downloadW9',
  async (form: IStaffDetails, thunkAPI: any) => {
    try {
      let url = SA_API_URL_STAFF_W9_FORM+form.staffId+'/?type=pdf';     
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO UPLOAD VERIFICATION DOCUMENT
export const uploadVerificationDocument = createAsyncThunk(
  'uploadVerificationDocument',
  async ({staffId, verificationDocument , documentTypeId }: IStaffDetails, thunkAPI: any) => {
    try {
      let url = SA_API_URL_STAFF_UPLOAD_ID_PROOF+staffId+'/'+documentTypeId+'/';
      const response = postFile(url, verificationDocument);
      return (response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO DELETE VERIFICATION DOCUMENT IMAGE
export const deleteVerificationImg = createAsyncThunk(
  'deleteVerificationImg',
  async (form: IStaffDetails, thunkAPI: any) => {
    try {
      const url = SA_API_URL_STAFF_DELETE_ID_PROOF+form.staffId+'/';
      const response = post(url, '');
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);