import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../helpers/hook";
import { IAccountSettingsForm } from "../../models/settingsInterfaces";
import {
  getAccountsettings,
  updateDefaultSettings,
} from "../../services/settingsServices";
import { selectAccountSettingsFormDataSlice } from "./settingsSlice";
import { ButtonInLine } from "../../components/ButtonGroups/ButtonInLine";
import { Textfield } from "../../components/TextField";
import { setNotification } from "../../services/apiService";
import { setLastActivePageAPIData } from "../../services/localStorage";

import { ACCOUNT_MIN_COMMISSION } from "../../config/env";

function Settings() {
  const pageName = "settings";
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accountSettingsForm, loading } = useAppSelector(
    selectAccountSettingsFormDataSlice
  );
  
  const [showTipPresetUpTo, setShowTipPresetUpTo] = useState(4);
  const [staffEdit, setStaffEdit] = useState(false);
  const [staffEditData, setStaffEditData] = useState({
    welcomeMessage: "",
    defaultPaymentCommission: 0,
  });
  const [tipEdit, setTipEdit] = useState(false);
  const [tipEditData, setTipEditData] = useState({
    tip1: 0,
    tip2: 0,
    tip3: 0,
    tip4: 0,
    tip5: 0,
    tippingFactHeader1: "",
    tippingFactBody1: "",
    tippingFactHeader2: "",
    tippingFactBody2: "",
    tippingFactHeader3: "",
    tippingFactBody3: "",
    tippingFactHeader4: "",
    tippingFactBody4: "",
    tippingFactHeader5: "",
    tippingFactBody5: "",
  });
  const [mediaEdit, setMediaEdit] = useState(false);
  const [mediaEditData, setMediaEditData] = useState({
    facebook: "",
    twitter: "",
    youtube: "",
    instagram: "",
  });

  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [defaultPaymentCommission, setDefaultPaymentCommission] = useState(ACCOUNT_MIN_COMMISSION);
  const [tip1, setTip1] = useState(0);
  const [tip2, setTip2] = useState(0);
  const [tip3, setTip3] = useState(0);
  const [tip4, setTip4] = useState(0);
  const [tip5, setTip5] = useState(0);
  const [tipHeading1, setTipHeading1] = useState("");
  const [tipHeading2, setTipHeading2] = useState("");
  const [tipHeading3, setTipHeading3] = useState("");
  const [tipHeading4, setTipHeading4] = useState("");
  const [tipHeading5, setTipHeading5] = useState("");
  const [tipBody1, setTipBody1] = useState("");
  const [tipBody2, setTipBody2] = useState("");
  const [tipBody3, setTipBody3] = useState("");
  const [tipBody4, setTipBody4] = useState("");
  const [tipBody5, setTipBody5] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const [instagram, setInstagram] = useState("");

  const [paymentCommissionError, setPaymentCommissionError] = useState("");

  React.useEffect(() => {
    dispatch(getAccountsettings(1));
    setLastActivePageAPIData({pageName:pageName, apiData:{accGroupId:"1"}}); 
  }, []);
  React.useEffect(() => {
    if (loading === "updated") {
      dispatch(getAccountsettings(1)); 
      setLastActivePageAPIData({pageName:pageName, apiData:{accGroupId:"1"}}); 
    }
  }, [loading]);

  React.useEffect(() => {
    if (loading === "succeeded" || loading === "updated") {
      setWelcomeMessage(
        accountSettingsForm.welcomeMessage
          ? accountSettingsForm.welcomeMessage
          : ""
      );
      setDefaultPaymentCommission(
        accountSettingsForm.defaultPaymentCommission
          ? accountSettingsForm.defaultPaymentCommission
          : ACCOUNT_MIN_COMMISSION
      );
      setTip1(accountSettingsForm.tip_1 ? accountSettingsForm.tip_1 : 0);
      setTip2(accountSettingsForm.tip_2 ? accountSettingsForm.tip_2 : 0);
      setTip3(accountSettingsForm.tip_3 ? accountSettingsForm.tip_3 : 0);
      setTip4(accountSettingsForm.tip_4 ? accountSettingsForm.tip_4 : 0);
      setTip5(accountSettingsForm.tip_5 ? accountSettingsForm.tip_5 : 0);
      setTipHeading1(
        accountSettingsForm.tippingFactHeader_1
          ? accountSettingsForm.tippingFactHeader_1
          : ""
      );
      setTipHeading2(
        accountSettingsForm.tippingFactHeader_2
          ? accountSettingsForm.tippingFactHeader_2
          : ""
      );
      setTipHeading3(
        accountSettingsForm.tippingFactHeader_3
          ? accountSettingsForm.tippingFactHeader_3
          : ""
      );
      setTipHeading4(
        accountSettingsForm.tippingFactHeader_4
          ? accountSettingsForm.tippingFactHeader_4
          : ""
      );
      setTipHeading5(
        accountSettingsForm.tippingFactHeader_5
          ? accountSettingsForm.tippingFactHeader_5
          : ""
      );
      setTipBody1(
        accountSettingsForm.tippingFactBody_1
          ? accountSettingsForm.tippingFactBody_1
          : ""
      );
      setTipBody2(
        accountSettingsForm.tippingFactBody_2
          ? accountSettingsForm.tippingFactBody_2
          : ""
      );
      setTipBody3(
        accountSettingsForm.tippingFactBody_3
          ? accountSettingsForm.tippingFactBody_3
          : ""
      );
      setTipBody4(
        accountSettingsForm.tippingFactBody_4
          ? accountSettingsForm.tippingFactBody_4
          : ""
      );
      setTipBody5(
        accountSettingsForm.tippingFactBody_5
          ? accountSettingsForm.tippingFactBody_5
          : ""
      );
      setFacebook(
        accountSettingsForm.facebookLink ? accountSettingsForm.facebookLink : ""
      );
      setTwitter(
        accountSettingsForm.twitterLink ? accountSettingsForm.twitterLink : ""
      );
      setYoutube(
        accountSettingsForm.youtubeLink ? accountSettingsForm.youtubeLink : ""
      );
      setInstagram(
        accountSettingsForm.instagramLink
          ? accountSettingsForm.instagramLink
          : ""
      );
    }
  }, [loading]);

  const editButton = (name): any => {
    const handleEdit = (name) => {

      if (name === "staffEdit") {
        setStaffEdit(true);
        // welcomeMessage: welcomeMessage, REMOVED ON 31 MAY 2023
        setStaffEditData({
          ...staffEditData, 
          defaultPaymentCommission: defaultPaymentCommission,
        });
      } else if (name === "tipEdit") {
        setTipEdit(true);
        setTipEditData({
          tip1: tip1,
          tip2: tip2,
          tip3: tip3,
          tip4: tip4,
          tip5: tip5,
          tippingFactHeader1: tipHeading1,
          tippingFactBody1: tipBody1,
          tippingFactHeader2: tipHeading2,
          tippingFactBody2: tipBody2,
          tippingFactHeader3: tipHeading3,
          tippingFactBody3: tipBody3,
          tippingFactHeader4: tipHeading4,
          tippingFactBody4: tipBody4,
          tippingFactHeader5: tipHeading5,
          tippingFactBody5: tipBody5,
        });
      } else if (name === "mediaEdit") {
        setMediaEdit(true);
        setMediaEditData({
          ...mediaEditData,
          facebook: facebook,
          youtube: youtube,
          twitter: twitter,
          instagram: instagram,
        });
      }
    };
    return (
      <>
        <ButtonInLine
          type="edit_or_save"
          onClick={(e) => handleEdit(name)}
          buttonLabel={t("common.edit")}
          penIcon={true}
        />
      </>
    );
  };

  const saveButton = (name): any => {
    const saveEdit = (name) => {
      if (name === "staffEdit") {
        if(Number(defaultPaymentCommission) < Number(ACCOUNT_MIN_COMMISSION)) {
          setPaymentCommissionError(" input-error ");
          setNotification(
            t("messages.appName"),
            t("messages.accCommissionMinValueError", {accCommMinValue:ACCOUNT_MIN_COMMISSION}),
            "failed",
            "updateSettings"
          );
        } else {
          var details: IAccountSettingsForm = {
            welcomeMessage: welcomeMessage,
            defaultPaymentCommission: defaultPaymentCommission,
          };
          dispatch(updateDefaultSettings(details));
          setStaffEdit(false);
          setPaymentCommissionError("");
        } 
      } else if (name === "tipEdit") {
        var details: IAccountSettingsForm = {
          tip_1: tip1,
          tip_2: tip2,
          tip_3: tip3,
          tip_4: tip4,
          tip_5: tip5,
          tippingFactHeader_1: tipHeading1,
          tippingFactBody_1: tipBody1,
          tippingFactHeader_2: tipHeading2,
          tippingFactBody_2: tipBody2,
          tippingFactHeader_3: tipHeading3,
          tippingFactBody_3: tipBody3,
          tippingFactHeader_4: tipHeading4,
          tippingFactBody_4: tipBody4,
          tippingFactHeader_5: tipHeading5,
          tippingFactBody_5: tipBody5,
        };
        dispatch(updateDefaultSettings(details));
        setTipEdit(false);
      } else if (name === "mediaEdit") {
        var details: IAccountSettingsForm = {
          facebookLink: facebook,
          youtubeLink: youtube,
          twitterLink: twitter,
          instagramLink: instagram,
        };
        dispatch(updateDefaultSettings(details));
        setMediaEdit(false);
      }
    };
    const cancelEdit = (name) => {
      if (name === "staffEdit") {
        setWelcomeMessage(staffEditData.welcomeMessage);
        setDefaultPaymentCommission(staffEditData.defaultPaymentCommission);
        setStaffEdit(false);
        setPaymentCommissionError("");
      } else if (name === "tipEdit") {
        setTip1(tipEditData.tip1);
        setTip2(tipEditData.tip2);
        setTip3(tipEditData.tip3);
        setTip4(tipEditData.tip4);
        setTip5(tipEditData.tip5);
        setTipHeading1(tipEditData.tippingFactHeader1);
        setTipHeading2(tipEditData.tippingFactHeader2);
        setTipHeading3(tipEditData.tippingFactHeader3);
        setTipHeading4(tipEditData.tippingFactHeader4);
        setTipHeading5(tipEditData.tippingFactHeader5);
        setTipBody1(tipEditData.tippingFactBody1);
        setTipBody2(tipEditData.tippingFactBody2);
        setTipBody3(tipEditData.tippingFactBody3);
        setTipBody4(tipEditData.tippingFactBody4);
        setTipBody5(tipEditData.tippingFactBody5);
        setTipEdit(false);
      } else if (name === "mediaEdit") {
        setFacebook(mediaEditData.facebook);
        setYoutube(mediaEditData.youtube);
        setTwitter(mediaEditData.twitter);
        setInstagram(mediaEditData.instagram);
        setMediaEdit(false);
      }
    };

    return (
      <div className="flex gap-4">
        <ButtonInLine
          type="edit_or_save"
          onClick={(e) => cancelEdit(name)}
          buttonLabel={t("common.cancel")}
        />
        <ButtonInLine
          type="edit_or_save"
          onClick={(e) => saveEdit(name)}
          buttonLabel={t("defaultSettings.save")}
        />
      </div>
    );
  };

  const handleCopy = (copyText) => {
    navigator.clipboard.writeText(copyText);
   

    setNotification(
      t("projectName"),
      t("common.linkCopied"),
      "success",
      "linkCopiedSettings"
    )
  };

  const moneyFormat = (value) => {
    return value.trim().replace(/[^0-9\.-]/g, "");
  };

  return (
    <div className="box-padding">
      <div className="box ">
        <div className="account mb-4">
          <div className="flex space-between">
            <div className="terms">
              <h1>{t("defaultSettings.settingsHeading")}</h1>
            </div>
            <div></div>
          </div>

          <div className="h-two mt-3">
            {t("defaultSettings.settingsHeading")}
            {staffEdit ? (
              <div>{saveButton("staffEdit")}</div>
            ) : (
              <div>{editButton("staffEdit")}</div>
            )}
          </div>

          <div className="flex mt-4 ">
            {/* <div className="h-three col-2 pt-11px">
              {t("defaultSettings.welcome")}
            </div>
            <div className="col-3 mt-1px">
              <textarea
                cols={1}
                rows={3}
                disabled={!staffEdit}
                className={`w-100 px-3 ${!staffEdit && "border-white"}`}
                value={welcomeMessage}
                onChange={(e) => setWelcomeMessage(e.target.value)}
              />
            </div>
            <div className="col-1"></div> */}
            <div className="h-three col-2 pt-11px">
              {t("defaultSettings.commission")}
            </div>
            <div className="col-3 percentage-input">
              <span
                className={paymentCommissionError + ` px-10px  ${
                  !staffEdit && " border-none bg-transparent"
                }`}
              >
                {t("group.percentageIcon")}
              </span>
              <Textfield
                type="number"
                disabled={!staffEdit}
                className={paymentCommissionError + `w-100  ${!staffEdit && "border-white"}`}
                value={defaultPaymentCommission}
                onChange={(e) => { 
                  setDefaultPaymentCommission(e.target.value);
                  setPaymentCommissionError("");
                  }
                }
              />
            </div>
          </div>

          <div className="h-two mt-3">
            {t("defaultSettings.tippingPagePresetsHeading")}
            {tipEdit ? (
              <div>{saveButton("tipEdit")}</div>
            ) : (
              <div>{editButton("tipEdit")}</div>
            )}
          </div>

          <div className="mt-4">
            <div className="space-between gap-1 left-center  mb-4 col-md-12 col-lg-12">
              <div className="width-82px ">
                <h3 className="h-three mt-2">
                  {t("defaultSettings.tipPreset1")}
                </h3>
              </div>
              <div className="relative">
                <div className={`dollar ${!tipEdit && "border-white"}`}>
                  {t("common.dollar")}
                </div>
                <Textfield
                  type="number"
                  value={tip1}
                  name={"tip1"}
                  disabled={!tipEdit}
                  className={`text-left pl-25px ${!tipEdit && "border-white"}`}
                  onChange={(e) => setTip1(moneyFormat(e.target.value))}
                />
              </div>
              <div className=" col-7 ">
                <div>
                  <Textfield
                    type="text"
                    className={`h-three w-86 py-5px ${!tipEdit && "border-white"}`}
                    value={tipHeading1}
                    disabled={!tipEdit}
                    onChange={(e) => setTipHeading1(e.target.value)}
                  />
                </div>
                <div>
                  <textarea
                    rows={2}
                    className={`w-86 ${!tipEdit && "border-white"}`}
                    value={tipBody1}
                    disabled={!tipEdit}
                    onChange={(e) => setTipBody1(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="space-between gap-1 left-center  mb-4 col-md-12 col-lg-12">
              <div className="width-82px ">
                <h3 className="h-three mt-2">
                  {t("defaultSettings.tipPreset2")}
                </h3>
              </div>
              <div className="relative">
                <div className={`dollar ${!tipEdit && "border-white"}`}>
                  {t("common.dollar")}
                </div>
                <Textfield
                  type="text"
                  value={tip2}
                  name={"tip2"}
                  disabled={!tipEdit}
                  className={`text-left pl-25px ${!tipEdit && "border-white"}`}
                  onChange={(e) => setTip2(moneyFormat(e.target.value))}
                />
              </div>
              <div className=" col-7 ">
                <div>
                  <Textfield
                    type="text"
                    className={`h-three w-86 ${!tipEdit && "border-white"}`}
                    value={tipHeading2}
                    disabled={!tipEdit}
                    onChange={(e) => setTipHeading2(e.target.value)}
                  />
                </div>
                <div>
                  <textarea
                    rows={2}
                    className={`w-86 ${!tipEdit && "border-white"}`}
                    value={tipBody2}
                    disabled={!tipEdit}
                    onChange={(e) => setTipBody2(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="space-between gap-1 left-center  mb-4 col-md-12 col-lg-12">
              <div className="width-82px ">
                <h3 className="h-three mt-2">
                  {t("defaultSettings.tipPreset3")}
                </h3>
              </div>
              <div className="relative">
                <div className={`dollar ${!tipEdit && "border-white"}`}>
                  {t("common.dollar")}
                </div>
                <Textfield
                  type="text"
                  value={tip3}
                  name={"tip3"}
                  disabled={!tipEdit}
                  className={`text-left pl-25px  ${!tipEdit && "border-white"}`}
                  onChange={(e) => setTip3(moneyFormat(e.target.value))}
                />
              </div>
              <div className=" col-7 ">
                <div>
                  <Textfield
                    type="text"
                    className={`h-three w-86 ${!tipEdit && "border-white"}`}
                    value={tipHeading3}
                    disabled={!tipEdit}
                    onChange={(e) => setTipHeading3(e.target.value)}
                  />
                </div>
                <div>
                  <textarea
                    rows={2}
                    className={`w-86 ${!tipEdit && "border-white"}`}
                    value={tipBody3}
                    disabled={!tipEdit}
                    onChange={(e) => setTipBody3(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="space-between gap-1 left-center  mb-4 col-md-12 col-lg-12">
              <div className="width-82px ">
                <h3 className="h-three mt-2">
                  {t("defaultSettings.tipPreset4")}
                </h3>
              </div>
              <div className="relative">
                <div className={`dollar ${!tipEdit && "border-white"}`}>
                  {t("common.dollar")}
                </div>
                <Textfield
                  type="text"
                  value={tip4}
                  name={"tip4"}
                  disabled={!tipEdit}
                  className={`text-left pl-25px  ${!tipEdit && "border-white"}`}
                  onChange={(e) => setTip4(moneyFormat(e.target.value))}
                />
              </div>
              <div className=" col-7 ">
                <div>
                  <Textfield
                    type="text"
                    className={`h-three w-86 ${!tipEdit && "border-white"}`}
                    value={tipHeading4}
                    disabled={!tipEdit}
                    onChange={(e) => setTipHeading4(e.target.value)}
                  />
                </div>
                <div>
                  <textarea
                    rows={2}
                    className={`w-86 ${!tipEdit && "border-white"}`}
                    value={tipBody4}
                    disabled={!tipEdit}
                    onChange={(e) => setTipBody4(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {showTipPresetUpTo > 4 && <div className="space-between gap-1 left-center  mb-3 col-md-12 col-lg-12">
              <div className="width-82px ">
                <h3 className="h-three mt-2">
                  {t("defaultSettings.tipPreset5")}
                </h3>
              </div>
              <div className="relative">
                <div className={`dollar ${!tipEdit && "border-white"}`}>
                  {t("common.dollar")}
                </div>
                <Textfield
                  type="text"
                  value={tip5}
                  name={"tip5"}
                  disabled={!tipEdit}
                  className={`text-left pl-25px  ${!tipEdit && "border-white"}`}
                  onChange={(e) => setTip5(moneyFormat(e.target.value))}
                />
              </div>
              <div className=" col-7 ">
                <div>
                  <Textfield
                    type="text"
                    className={`h-three w-86 ${!tipEdit && "border-white"}`}
                    value={tipHeading5}
                    disabled={!tipEdit}
                    onChange={(e) => setTipHeading5(e.target.value)}
                  />
                </div>
                <div>
                  <textarea
                    rows={2}
                    className={`w-86 ${!tipEdit && "border-white"}`}
                    value={tipBody5}
                    disabled={!tipEdit}
                    onChange={(e) => setTipBody5(e.target.value)}
                  />
                </div>
              </div>
            </div>}
          </div>

          <div className="h-two mb-4">
            {t("defaultSettings.socialMediaHeading")}
            {mediaEdit ? (
              <div>{saveButton("mediaEdit")}</div>
            ) : (
              <div>{editButton("mediaEdit")}</div>
            )}
          </div>

          <div className="left-center mb-4">
            <div className="h-three col-2">
              {" "}
              {t("defaultSettings.facebookLink")}
            </div>
            <div className="flex gap-4 col ">
              <div className="col-4">
                <Textfield
                  className={`w-100 h-28px ${!mediaEdit && "border-white"}`}
                  type="text"
                  disabled={!mediaEdit}
                  value={facebook}
                  onChange={(e) => setFacebook(e.target.value)}
                />
              </div>
              <div>
                {facebook && facebook.trim().length > 0 && (
                  <ButtonInLine
                    className="copybtn "
                    onClick={(e) => (facebook)}
                    buttonLabel={t("group.copy")}
                    copyIcon={true}
                  />
                )}
              </div>
              <div>
                {facebook && facebook.trim().length > 0 && (
                  <ButtonInLine
                    type="link"
                    className="copybtn "
                    href={facebook}
                    target="blank"
                    openIcon={true}
                    buttonLabel={t("group.open")}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="left-center mb-4">
            <div className="h-three col-2">
              {" "}
              {t("defaultSettings.youtubeLink")}
            </div>
            <div className="flex gap-4 col ">
              <div className="col-4">
                <Textfield
                  className={`w-100 h-28px ${!mediaEdit && "border-white"}`}
                  type="text"
                  disabled={!mediaEdit}
                  value={youtube}
                  onChange={(e) => setYoutube(e.target.value)}
                />
              </div>
              <div>
                {youtube && youtube.trim().length > 0 && (
                  <ButtonInLine
                    className="copybtn "
                    onClick={(e) => handleCopy(youtube)}
                    buttonLabel={t("group.copy")}
                    copyIcon={true}
                  />
                )}
              </div>
              <div>
                {youtube && youtube.trim().length > 0 && (
                  <ButtonInLine
                    type="link"
                    className="copybtn "
                    href={youtube}
                    target="blank"
                    openIcon={true}
                    buttonLabel={t("group.open")}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="left-center mb-4">
            <div className="h-three col-2">
              {" "}
              {t("defaultSettings.twitterLink")}
            </div>
            <div className="flex gap-4 col ">
              <div className="col-4">
                <Textfield
                  className={`w-100 h-28px ${!mediaEdit && "border-white"}`}
                  type="text"
                  disabled={!mediaEdit}
                  value={twitter}
                  onChange={(e) => setTwitter(e.target.value)}
                />
              </div>
              <div>
                {twitter && twitter.trim().length > 0 && (
                  <ButtonInLine
                    className="copybtn "
                    onClick={(e) => handleCopy(twitter)}
                    buttonLabel={t("group.copy")}
                    copyIcon={true}
                  />
                )}
              </div>
              <div>
                {twitter && twitter.trim().length > 0 && (
                  <ButtonInLine
                    type="link"
                    className="copybtn "
                    href={twitter}
                    target="blank"
                    openIcon={true}
                    buttonLabel={t("group.open")}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="left-center mb-4">
            <div className="h-three col-2">
              {" "}
              {t("defaultSettings.instagramLink")}
            </div>
            <div className="flex gap-4 col ">
              <div className="col-4">
                <Textfield
                  className={`w-100 h-28px ${!mediaEdit && "border-white"}`}
                  type="text"
                  disabled={!mediaEdit}
                  value={instagram}
                  onChange={(e) => setInstagram(e.target.value)}
                />
              </div>
              <div>
                {instagram && instagram.trim().length > 0 && (
                  <ButtonInLine
                    className="copybtn "
                    onClick={(e) => handleCopy(instagram)}
                    buttonLabel={t("group.copy")}
                    copyIcon={true}
                  />
                )}
              </div>
              <div>
                {instagram && instagram.trim().length > 0 && (
                  <ButtonInLine
                    type="link"
                    className="copybtn "
                    href={instagram}
                    target="blank"
                    openIcon={true}
                    buttonLabel={t("group.open")}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
