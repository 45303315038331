import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IGuestTip } from '../../models/guestPaymentsInterfaces';
import getStripe from '../../helpers/getStripe';

export interface DataState {
  tokenData: IGuestTip;
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
}


const initialState: DataState = {
  tokenData: {} as IGuestTip,
  loading: 'idle',
};

const getToken = async (tokenData: IGuestTip) => {
  await getStripe().then((ss) => {
    if (ss != null) {
      return tokenData
      // console.log(JSON.stringify(tokenData))
      // return { sessionId: (JSON.stringify(tokenData.token))?.id }
      // ss?.redirectToCheckout({ sessionId: JSON.parse(JSON.stringify(tokenData.token))?.id });
      // ss?.confirmCardPayment
    }
  });
};

export const stripeTokenSlice = createSlice({
  name: 'stripeTokenData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(getStripeSecret.pending, (state) => {
    //   state.tokenData = {} as IGuestTip;
    //   state.loading = 'loading';
    // });
    // builder.addCase(getStripeSecret.fulfilled, (state, action) => {
    //   state.tokenData = action.payload as IGuestTip;
    //   state.loading = 'succeeded';
    //   getToken(state.tokenData);
    // });
    // builder.addCase(getStripeSecret.rejected, (state, { payload }: any) => {
    //   state.loading = 'error';
    //   // state.error = action.error.message;
    // });
  },
});

// Action creators are generated for each case reducer function
export const {} = stripeTokenSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectstripeTokenSlice = (state: RootState) => state.stripeTokenData;

export const stripeTokenSliceReducer = stripeTokenSlice.reducer;
