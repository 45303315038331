import React from 'react'
import { useTranslation } from 'react-i18next';
import tempImgOne from '../../assets/images/qr-checkout.png'

const OrderedComponent = () => {    
    const { t } = useTranslation();
  return (
    <div className='pl-37px pb-62px border-bottom pt-22px flex-col gap-12px'>
                            <h1 className='text-22px-600-black mb-5px'>{t("QRCodesCheckOut.orders")}</h1>
                            <div className='flex'>
                                <div className="selected-qr-img">
                                    <img src={tempImgOne} alt="" />
                                </div>
                                <div className='pl-18px pr-21px flex w-100 '>
                                    <div className='flex-col w-100 '>
                                        <span className='text-16px-600-black pb-14px'>{t("QRCodesCheckOut.wideCard")}</span>
                                        <span className='text-14px-500-black pb-10px'>Jodie Foster </span>
                                        <span className='text-14px-500-black'>10 x $0.05</span>
                                    </div>
                                </div>
                            </div>
                            <div className='flex'>
                                <div className="selected-qr-img">
                                    <img src={tempImgOne} alt="" />
                                </div>
                                <div className='pl-18px pr-21px flex w-100 '>
                                    <div className='flex-col w-100 '>
                                        <span className='text-16px-600-black pb-14px'>{t("QRCodesCheckOut.wideCard")}</span>
                                        <span className='text-14px-500-black pb-10px'>Jodie Foster </span>
                                        <span className='text-14px-500-black'>10 x $0.05</span>
                                    </div>
                                </div>
                            </div>
                            <div className='flex'>
                                <div className="selected-qr-img">
                                    <img src={tempImgOne} alt="" />
                                </div>
                                <div className='pl-18px pr-21px flex w-100 '>
                                    <div className='flex-col w-100 '>
                                        <span className='text-16px-600-black pb-14px'>{t("QRCodesCheckOut.wideCard")}</span>
                                        <span className='text-14px-500-black pb-10px'>Jodie Foster </span>
                                        <span className='text-14px-500-black'>10 x $0.05</span>
                                    </div>
                                </div>
                            </div>
                            <div className='flex'>
                                <div className="selected-qr-img">
                                    <img src={tempImgOne} alt="" />
                                </div>
                                <div className='pl-18px pr-21px flex w-100 '>
                                    <div className='flex-col w-100 '>
                                        <span className='text-16px-600-black pb-14px'>{t("QRCodesCheckOut.wideCard")}</span>
                                        <span className='text-14px-500-black pb-10px'>Jodie Foster </span>
                                        <span className='text-14px-500-black'>10 x $0.05</span>
                                    </div>
                                </div>
                            </div>
                        </div>
  )
}

export default OrderedComponent