import { createSlice } from "@reduxjs/toolkit";
import { RootState } from '../../store';
import { IDashboardChartData } from "../../models/dashboardInterfaces";
import { fetchChartData } from "../../services/dashboardServices";

export interface DataState {
    chartDetails: IDashboardChartData;
    loading: 'idle' | 'loading' | 'succeeded' | 'failed' | 'error'; 
}

const initialState: DataState = {
    chartDetails: {} as IDashboardChartData,
    loading: 'idle',
}

export const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState,
    reducers: {
      resetLoading: (state) => {
        state.loading = 'idle';
      } 
    },
    extraReducers: (builder) => {
      builder.addCase(fetchChartData.pending, (state) => {
          state.loading = 'loading'
      });
      builder.addCase(fetchChartData.fulfilled, (state, action) => {
          state.chartDetails = action.payload as IDashboardChartData; 
          state.loading = 'succeeded'
      });
      builder.addCase(fetchChartData.rejected, (state) => {
          state.loading = 'failed'
      });

    }
})

// Action creators are generated for each case reducer function
export const { resetLoading } = dashboardSlice.actions; // NOT USED NOW 

export const selectDashboardSlice = (state:RootState)=> state.chartDetailsData;
export const chartDetailsReducer = dashboardSlice.reducer;