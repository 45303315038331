import { get, getFile, post } from './apiService';
import { IManagePaymentReq, IManagePaymentSaveDataAll, IPaymentsRequestData, IPayoutDestinationChangeReq } from '../models/paymentsInterfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SA_API_URL_PAYOUTS_LIST, SA_API_URL_PAYOUTS_EXPORT, SA_API_URL_PAYOUTS_SUMMARY, ACC_API_URL_PAYOUTS_LIST, ACC_API_URL_PAYOUTS_EXPORT, ACC_API_URL_PAYOUTS_SUMMARY, SA_API_URL_PAYOUT_MANAGE_PAYMENT, SA_API_URL_PAYOUT_RECEIPT, SA_API_URL_PAYOUT_REPROCESS, SA_API_URL_PAYOUT_SAVE_MANAGE_PAYMENT, ACC_API_URL_PAYOUT_MANAGE_PAYMENT, ACC_API_URL_PAYOUT_RECEIPT, ACC_API_URL_PAYOUT_REPROCESS, ACC_API_URL_PAYOUT_SAVE_MANAGE_PAYMENT, ACC_API_URL_PAYOUT_DESTINATION_CHANGE, SA_API_URL_PAYOUT_DESTINATION_CHANGE } from '../config/api_urls'; 
import { arrAccountUserIds } from '../config/env'; 

// FUNCTION TO FECT PAYMENT LIST
export const fetchPayments = createAsyncThunk(
  'payments/fetchPayments',
  async ({ userRole, page, numRowsPerPage, sort, search, hotel, group, start, end, status, payee, payeeType, amount_from, amount_to }: IPaymentsRequestData, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(userRole) ? ACC_API_URL_PAYOUTS_LIST : SA_API_URL_PAYOUTS_LIST)+'?page=' + page + '&hotel=' + hotel;
      if (group !== '')          { url = url + '&group=' + group; }
      if (numRowsPerPage)       { url = url + '&page_size=' + numRowsPerPage.toString(); }
      if (sort !== '')           { url = url + '&sort=' + sort; }
      if (search !== '')         { url = url + '&search=' + search.trim(); }
      if (start && start !== '') { url = url + '&start=' + encodeURIComponent(start); }
      if (end && end !== '')     { url = url + '&end=' + encodeURIComponent(end); }
      if (status !== '')         { url = url + '&status=' + status; }
      if (payee !== '')          { url = url + '&payee=' + payee; }
      if (payeeType !== '')      { url = url + '&payee_type=' + payeeType; }
      if (amount_from && amount_from !== '' && Number(amount_from) >= 0 && Number(amount_to) > 0)    { 
        url = url + '&amount_from=' + amount_from; 
        if (amount_to && amount_to !== '') { url = url + '&amount_to=' + amount_to; }
      }
      
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO EXPPORT PAYMENT LIST
export const exportPayments = createAsyncThunk(
  'payments/exportPayments',
  async ({ userRole, page, sort, search, hotel, group, start, end, status, payee, payeeType, amount_from, amount_to }: IPaymentsRequestData, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(userRole) ? ACC_API_URL_PAYOUTS_EXPORT : SA_API_URL_PAYOUTS_EXPORT)+'?page=' + page + '&hotel=' + hotel;
      if (group !== '')          { url = url + '&group=' + group; }
      if (sort !== '')           { url = url + '&sort=' + sort; }
      if (search !== '')         { url = url + '&search=' + search.trim(); }
      if (start && start !== '') { url = url + '&start=' + encodeURIComponent(start); }
      if (end && end !== '')     { url = url + '&end=' + encodeURIComponent(end); }
      if (status !== '')         { url = url + '&status=' + status; }
      if (payee !== '')          { url = url + '&payee=' + payee; } 
      if (payeeType !== '')      { url = url + '&payee_type=' + payeeType; }
      if (amount_from && amount_from !== '' && Number(amount_from) >= 0 && Number(amount_to) > 0)    { 
        url = url + '&amount_from=' + amount_from; 
        if (amount_to && amount_to !== '') { url = url + '&amount_to=' + amount_to; }
      }
      getFile(url, 'Payments_List_Export');
    } catch (error) {
      // console.log('Payments List Export error: ', error); 
    }
  }
);

// FUNCTION TO FETCH PAYMENT SUMMARY
export const fetchPaymentsSummary = createAsyncThunk(
  'payments/fetchPaymentsSummary',
  async ({ userRole, page, sort, search, hotel, group, start, end, status, payee, payeeType, amount_from, amount_to }: IPaymentsRequestData, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(userRole) ? ACC_API_URL_PAYOUTS_SUMMARY : SA_API_URL_PAYOUTS_SUMMARY)+'?page=' + page + '&hotel=' + hotel;  
      if (group !== '')          { url = url + '&group=' + group; }
      if (sort !== '')           { url = url + '&sort=' + sort; }
      if (search !== '')         { url = url + '&search=' + search.trim(); }
      if (start && start !== '') { url = url + '&start=' + encodeURIComponent(start); }
      if (end && end !== '')     { url = url + '&end=' + encodeURIComponent(end); }
      if (status !== '')         { url = url + '&status=' + status; }
      if (payee !== '')          { url = url + '&payee=' + payee; } 
      if (payeeType !== '')      { url = url + '&payee_type=' + payeeType; }
      if (amount_from && amount_from !== '' && Number(amount_from) >= 0 && Number(amount_to) > 0)    { 
        url = url + '&amount_from=' + amount_from; 
        if (amount_to && amount_to !== '') { url = url + '&amount_to=' + amount_to; }
      }
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
); 

// FUNCTION TO FETCH PAYMENT DETAILS
export const fetchManagePaymentDetails = createAsyncThunk( 
  'payments/fetchManagePaymentDetails',
  async ( payReq: IManagePaymentReq, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(payReq.userRole) ? ACC_API_URL_PAYOUT_MANAGE_PAYMENT : SA_API_URL_PAYOUT_MANAGE_PAYMENT) + payReq.payId +'/'; 
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
); 


// FUNCTION TO FETCH RECEIPT DETAILS
export const fetchReceiptDetails = createAsyncThunk(
  'payments/fetchReceiptDetails',
  async ( payReq: IManagePaymentReq, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(payReq.userRole) ? ACC_API_URL_PAYOUT_RECEIPT : SA_API_URL_PAYOUT_RECEIPT) + payReq.payId +'/'; 
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);  

// FUNCTION TO REPROCESS FAILED PAYOUT
export const reprocessFailedPayout = createAsyncThunk(
  'payments/reprocessFailedPayout',
  async ( payReq: IManagePaymentReq, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(payReq.userRole) ? ACC_API_URL_PAYOUT_REPROCESS : SA_API_URL_PAYOUT_REPROCESS) + payReq.payId +'/'; 
      const response = post(url,"");
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO SAVE PAYMENT DATA
export const saveManagePaymentData = createAsyncThunk(
  'payments/saveManagePaymentData',
  async ( saveData: IManagePaymentSaveDataAll, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(saveData.userRole) ? ACC_API_URL_PAYOUT_SAVE_MANAGE_PAYMENT : SA_API_URL_PAYOUT_SAVE_MANAGE_PAYMENT) + saveData.payId +'/'; 

      let savePayData = {
        paymentDetails: [
          ...saveData.paymentDetails
        ]
      };

      const response = post(url,savePayData);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO CHANGE PAYOUT DESTINATION
export const changePayoutDestination = createAsyncThunk(
  'payments/changePayoutDestination',
  async ( apiData: IPayoutDestinationChangeReq, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(apiData.userRole) ? ACC_API_URL_PAYOUT_DESTINATION_CHANGE : SA_API_URL_PAYOUT_DESTINATION_CHANGE) + apiData.payId +'/'; 

      let savePayData = {
        payout_destination:  apiData.payoutDestination 
      };

      const response = post(url,savePayData);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);




