import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { getTandC, newAccountSignUp, newAccountSignUpResendEmail } from '../../../services/signUpServices';
 
export interface IAuthentication {
  isProcessingRequest: 'idle' | 'loading' | 'succeeded' | 'error';  
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
  signUpData?: {};
  signUpResponseType?: string;
  termsAndCondition?: string;
}

const initialState: IAuthentication = { 
  isProcessingRequest: 'idle', 
  loading: 'idle', 
  signUpData: {},
  signUpResponseType: '',
  termsAndCondition: ''
 };

export const accountSignUpSlice = createSlice({
  name: 'accountSignUpData',
  initialState,
  reducers: { 
    setIsProcessingRequest: (state) => {
      state.isProcessingRequest = 'idle';
    },
    setSignUpResponseType: (state, action: PayloadAction<any>) => {
      state.signUpResponseType = action.payload;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(newAccountSignUp.pending, (state) => {
      console.log('state1',state);
      state.signUpData = '';
      state.isProcessingRequest = 'loading';
    });
    builder.addCase(newAccountSignUp.fulfilled, (state, action) => {
      state.signUpData = action?.payload?.data ?  action.payload.data : 'succeeded';  
      state.isProcessingRequest = 'succeeded';  
      state.signUpResponseType = action?.payload?.data?.message ? action.payload.data.message : 'succeeded'; 
    });
    builder.addCase(newAccountSignUp.rejected, (state, { payload }: any) => { 
      state.signUpData = payload.response.data;
      state.isProcessingRequest = 'error';
      state.signUpResponseType = payload?.response?.data?.message ? payload.response.data.message : 'error'; 
    }); 

    builder.addCase(newAccountSignUpResendEmail.pending, (state) => {
      state.signUpData = '';
      state.isProcessingRequest = 'loading';
    });
    builder.addCase(newAccountSignUpResendEmail.fulfilled, (state, action) => {
      // state.signUpData = action?.payload?.data ?  action.payload.data : 'succeeded';  
      state.isProcessingRequest = 'succeeded';  
      state.signUpResponseType = 'emailResentSuccess'; //action?.payload?.data?.message ? action.payload.data.message : 'succeeded'; 
    });
    builder.addCase(newAccountSignUpResendEmail.rejected, (state, { payload }: any) => { 
      // state.signUpData = payload.response.data;
      // state.isProcessingRequest = 'resendError';
      state.signUpResponseType = payload?.response?.data?.message ? payload.response.data.message : 'emailResentError'; 
    });

    builder.addCase(getTandC.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(getTandC.fulfilled, (state, action) => {
      let m=action?.payload      
      state.termsAndCondition = m.conditionText;  
      state.loading = 'succeeded';  
    });
    builder.addCase(getTandC.rejected, (state, { payload }: any) => { 
      state.loading = 'error';
    }); 

  }
});



// Action creators are generated for each case reducer function
export const { setIsProcessingRequest, setSignUpResponseType } = accountSignUpSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAccountSignUpSlice = (state: RootState) => state.accountSignUpData; 

export const accountSignUpReducer = accountSignUpSlice.reducer;
