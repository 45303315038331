
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { ITips, ITipsSummary, IStripePayMethod } from '../../models/tipsInterfaces';
import { fetchTipsSummary, fetchTips, fetchStripePayMethods, performRefund } from '../../services/tipsListServices';
import { IGenericResponse, IPagination } from '../../models/interfaces';
import { IWithholdingsInterfaces } from '../../models/withholdListInterfaces';
import { fetchWithholdList, handleDistribute } from '../../services/withholdListServices';
import { setNotification } from '../../services/apiService';
import i18n from '../../utilities/i18n';

export interface DataState {
  withholdingList: IWithholdingsInterfaces[];
  loading: 'idle' | 'loading' | 'success' | 'succeeded' | 'error';
  totalSize: number;
  currentPage: number;
  totalPage: number;
}

const initialState: DataState = {
    withholdingList: [],
 
  loading: 'idle',
  totalSize: 0,
  currentPage: 1,
  totalPage: 0,
};


export const withholdingsListDataSlice = createSlice({
  name: 'withholdingsListData',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWithholdList.pending, (state) => {
      state.withholdingList = [];
      state.loading = 'loading';
    });
    builder.addCase(fetchWithholdList.fulfilled, (state, action) => {
      const m = action.payload as IPagination<IWithholdingsInterfaces[]>;
      state.withholdingList = m.results;   
      state.loading = 'succeeded';
      state.totalSize = m.count;
    });
    builder.addCase(fetchWithholdList.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      state.totalSize = 0;
      // state.error = action.error.message;
    });

    builder.addCase(handleDistribute.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(handleDistribute.fulfilled, (state, action) => {
      setNotification(
        i18n.t("messages.appName"),
        i18n.t("messages.TransactionsSuccess"),
        "success",
        "updateAccountDetail"
      );
    });
    builder.addCase(handleDistribute.rejected, (state, { payload }: any) => {
      setNotification(
        i18n.t("messages.appName"),
        i18n.t("messages.TransactionsFailed"),
        "failed",
        "newAccountCreate"
      );
    });

    
  },
});

// Action creators are generated for each case reducer function
export const {  setCurrentPage } = withholdingsListDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const WithholdingsListDataSlice = (state: RootState) => state.withholdingsListData;

export const withholdingsListDataReducer = withholdingsListDataSlice.reducer;
