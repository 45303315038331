import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

interface IProps {
  amountValue: string;
  amountCurrency: string;
  clickable: boolean;
}

const MoneyField: React.FC<IProps> = ({ amountValue, amountCurrency, clickable }) => {
  const currency = getSymbolFromCurrency(amountCurrency);

  return (
    <>
      {clickable === false && (
        <span className="text-center">
          {amountValue !== '0.00' && `${String(currency)!=='undefined'?String(currency):''}${amountValue}`}
        </span>
      )}
      {clickable === true && (
        <a className="text-center" href='# '>
          {amountValue !== '0.00' && `${String(currency)}${amountValue}`}
        </a>
      )}
    </>
  );
};

export default MoneyField;
