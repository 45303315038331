import React, { useEffect } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { Col, Row } from 'react-bootstrap'
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Fab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GOOGLEANALYTICS_SCRIPT, GOOGLEANALYTICS_SOURCE, ZOOMINFO_SCRIPT } from '../config/env';

const Support = () => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = React.useState("");
    const [expand, setExpand] = React.useState("");

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    const handleChange1 = (panel) => (event, isExpanded) => {
        setExpand(isExpanded ? panel : false);
      };
    
    useEffect(() => {
      // *********************************************************************
    // GOOGLE ANALYTICS CODE 
    // *********************************************************************
    if(GOOGLEANALYTICS_SOURCE.length > 1 && GOOGLEANALYTICS_SCRIPT.length > 1) {
      const script1 = document.createElement("script");
      script1.setAttribute("src", GOOGLEANALYTICS_SOURCE);
      document.head.appendChild(script1);
  
      const script2 = document.createElement("script");
      script2.innerHTML = GOOGLEANALYTICS_SCRIPT; 
      document.head.appendChild(script2)
      return () => {
        // clean up the script when the component in unmounted
        document.head.removeChild(script1);
        document.head.removeChild(script2); 
      }
    }
    

    // *********************************************************************
    // ZOOMINFO CODE 
    // *********************************************************************
    if(ZOOMINFO_SCRIPT.length > 0) {
      const script = document.createElement("script");
      script.innerHTML = ZOOMINFO_SCRIPT;
      document.head.appendChild(script)
      return () => {
        // clean up the script when the component in unmounted
        document.head.removeChild(script)
      }
    }
    }, []); 

  return (
    <>
        <Navbar/>
        <div className='hide-overflowX'>
      <Row className="frame">
        <Row className='max-w-1400px justify-content-sm-center justify-content-md-start  px-0 ml-10px'>
          <Col xs={12} md={12} lg={7} className='flex-col space-evenly mt-140px   ps-0'>
            <h1 className='ms-md-2 ms-0 text-md-start text-center'>{t("support.supportHeading")}</h1>
          </Col>
        </Row>
      </Row>

      <Row className='center pt-90px  bg-white'>
        <Row className="support px-0 mb-78px">
          <Col className='px-0 text-start '>
            <Tabs aria-label="Basic tabs" defaultValue={0} sx={{ borderRadius: 'lg' }}>
              <TabList
                variant="plain"
                sx={{
                  width: '100%',
                  maxWidth: 650,
                  mx: 'auto',
                  pt: 2,
                  alignSelf: 'flex-start',
                  [`& .${tabClasses.root}`]: {
                    bgcolor: 'transparent',
                    boxShadow: 'none',
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                    [`&.${tabClasses.selected}`]: {
                      color: 'primary.plainColor',
                      fontWeight: 'lg',
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        zIndex: 1,
                        bottom: '-1px',
                        // left: 'var(--ListItem-paddingLeft)',
                        // right: 'var(--ListItem-paddingRight)',
                        height: '1px',
                        width: "100%",
                        // borderTopLeftRadius: '3px',
                        // borderTopRightRadius: '3px',
                        bgcolor: '#081336',
                      },
                    },
                  },
                }}
              >
                <Row>
                <Col xs={12} md={6} lg={6} className='center'>
                <Tab className='tab-border me-2'><h2>{t("support.questionManagerHeading")}</h2></Tab></Col>
                <Col xs={12} md={6} lg={6} className='center'>
                <Tab className='tab-border ms-2'><h2>{t("support.questionEmployeeHeading")}</h2></Tab></Col>
                </Row>
              </TabList>

              <TabPanel value={0} sx={{mt:5}} className='px-3 px-md-0'>
              <div >

                
              <Accordion expanded={expand === 'panel1'} onChange={handleChange1('panel1')} className='accordion'>
                  <AccordionSummary
                    expandIcon={expand === 'panel1' ?
                    <Fab size="medium"  aria-label="remove" className='accordion bg-black text-white'>
                    <ClearRoundedIcon />
                  </Fab>
                  :  <Fab size="medium"  aria-label="add" className='accordion'><AddIcon />
                </Fab>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                   <h3>{t("01")}<span className='ms-5'>{t("support.questionManager1")}</span></h3>
                  </AccordionSummary>
                  <AccordionDetails>
                      <div className='ml-76px'>
                     <p>{t("support.answerManager1")}</p>
                      </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expand === 'panel2'} onChange={handleChange1('panel2')} className='accordion mt-4'>
                  <AccordionSummary
                    expandIcon={expand === 'panel2' ?
                    <Fab size="medium"  aria-label="remove" className='accordion bg-black text-white'>
                    <ClearRoundedIcon />
                  </Fab>
                  :  <Fab size="medium"  aria-label="add" className='accordion'><AddIcon />
                </Fab>}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  ><h3>{t("02")} <span className='ms-5'>{t("support.questionManager2")}</span></h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='ml-76px'>
                      <p> {t("support.answerManager2")}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expand === 'panel3'} onChange={handleChange1('panel3')} className='accordion mt-4'>
                  <AccordionSummary
                    expandIcon={expand === 'panel3' ?
                    <Fab size="medium"  aria-label="remove" className='accordion bg-black text-white'>
                    <ClearRoundedIcon />
                  </Fab>
                  :  <Fab size="medium"  aria-label="add" className='accordion'><AddIcon />
                </Fab>}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <h3>{t("03")} <span className='ms-5'>{t("support.questionManager3")}</span></h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='ml-76px'>
                      <p> {t("support.answerManager3")}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expand === 'panel4'} onChange={handleChange1('panel4')} className='accordion mt-4'>
                  <AccordionSummary
                    expandIcon={expand === 'panel4' ?
                    <Fab size="medium"  aria-label="remove" className='accordion bg-black text-white'>
                    <ClearRoundedIcon />
                  </Fab>
                  :  <Fab size="medium"  aria-label="add" className='accordion'><AddIcon />
                </Fab>}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <h3>{t("04")} <span className='ms-5'>{t("support.questionManager4")}</span></h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='ml-76px'>
                      <p> {t("support.answerManager4")}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expand === 'panel5'} onChange={handleChange1('panel5')} className='accordion mt-4'>
                  <AccordionSummary
                    expandIcon={expand === 'panel5' ?
                    <Fab size="medium"  aria-label="remove" className='accordion bg-black text-white'>
                    <ClearRoundedIcon />
                  </Fab>
                  :  <Fab size="medium"  aria-label="add" className='accordion'><AddIcon />
                </Fab>}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                  >
                  <h3>{t("05")} <span className='ms-5'>{t("support.questionManager5")}</span></h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='ml-76px'>
                      <p> {t("support.answerManager5")}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expand === 'panel6'} onChange={handleChange1('panel6')} className='accordion mt-4'>
                  <AccordionSummary
                    expandIcon={expand === 'panel6' ?
                    <Fab size="medium"  aria-label="remove" className='accordion bg-black text-white'>
                    <ClearRoundedIcon />
                  </Fab>
                  :  <Fab size="medium"  aria-label="add" className='accordion'><AddIcon />
                </Fab>}
                    aria-controls="panel6bh-content"
                    id="panel6bh-header"
                  >
                  <h3>{t("06")} <span className='ms-5'>{t("support.questionManager6")}</span></h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='ml-76px'>
                      <p> {t("support.answerManager6")}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expand === 'panel7'} onChange={handleChange1('panel7')} className='accordion mt-4'>
                  <AccordionSummary
                    expandIcon={expand === 'panel7' ?
                    <Fab size="medium"  aria-label="remove" className='accordion bg-black text-white'>
                    <ClearRoundedIcon />
                  </Fab>
                  :  <Fab size="medium"  aria-label="add" className='accordion'><AddIcon />
                </Fab>}
                    aria-controls="panel7bh-content"
                    id="panel7bh-header"
                  >
                  <h3>{t("07")} <span className='ms-5'>{t("support.questionManager7")}</span></h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='ml-76px'>
                      <p> {t("support.answerManager7")}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expand === 'panel8'} onChange={handleChange1('panel8')} className='accordion mt-4'>
                  <AccordionSummary
                    expandIcon={expand === 'panel8' ?
                    <Fab size="medium"  aria-label="remove" className='accordion bg-black text-white'>
                    <ClearRoundedIcon />
                  </Fab>
                  :  <Fab size="medium"  aria-label="add" className='accordion'><AddIcon />
                </Fab>}
                    aria-controls="panel8bh-content"
                    id="panel8bh-header"
                  >
                  <h3>{t("08")} <span className='ms-5'>{t("support.questionManager8")}</span></h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='ml-76px'>
                      <p> {t("support.answerManager8")}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand === 'panel9'} onChange={handleChange1('panel9')} className='accordion mt-4'>
                  <AccordionSummary
                    expandIcon={expand === 'panel9' ?
                    <Fab size="medium"  aria-label="remove" className='accordion bg-black text-white'>
                    <ClearRoundedIcon />
                  </Fab>
                  :  <Fab size="medium"  aria-label="add" className='accordion'><AddIcon />
                </Fab>}
                    aria-controls="panel9bh-content"
                    id="panel9bh-header"
                  >
                  <h3>{t("09")} <span className='ms-5'>{t("support.questionManager9")}</span></h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='ml-76px'>
                      <p> {t("support.answerManager9")}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand === 'panel10'} onChange={handleChange1('panel10')} className='accordion mt-4'>
                  <AccordionSummary
                    expandIcon={expand === 'panel10' ?
                    <Fab size="medium"  aria-label="remove" className='accordion bg-black text-white'>
                    <ClearRoundedIcon />
                  </Fab>
                  :  <Fab size="medium"  aria-label="add" className='accordion'><AddIcon />
                </Fab>}
                    aria-controls="panel10bh-content"
                    id="panel10bh-header"
                  >
                  <h3>{t("10")} <span className='ms-5'>{t("support.questionManager10")}</span></h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='ml-76px'>
                      <p> {t("support.answerManager10")}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand === 'panel11'} onChange={handleChange1('panel11')} className='accordion mt-4'>
                  <AccordionSummary
                    expandIcon={expand === 'panel11' ?
                    <Fab size="medium"  aria-label="remove" className='accordion bg-black text-white'>
                    <ClearRoundedIcon />
                  </Fab>
                  :  <Fab size="medium"  aria-label="add" className='accordion'><AddIcon />
                </Fab>}
                    aria-controls="panel11bh-content"
                    id="panel11bh-header"
                  >
                  <h3>{t("11")} <span className='ms-5'>{t("support.questionManager11")}</span></h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='ml-76px'>
                      <p> {t("support.answerManager11")}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand === 'panel12'} onChange={handleChange1('panel12')} className='accordion mt-4'>
                  <AccordionSummary
                    expandIcon={expand === 'panel12' ?
                    <Fab size="medium"  aria-label="remove" className='accordion bg-black text-white'>
                    <ClearRoundedIcon />
                  </Fab>
                  :  <Fab size="medium"  aria-label="add" className='accordion'><AddIcon />
                </Fab>}
                    aria-controls="panel12bh-content"
                    id="panel12bh-header"
                  >
                  <h3>{t("12")} <span className='ms-5'>{t("support.questionManager12")}</span></h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='ml-76px'>
                      <p> {t("support.answerManager12")}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand === 'panel13'} onChange={handleChange1('panel13')} className='accordion mt-4'>
                  <AccordionSummary
                    expandIcon={expand === 'panel13' ?
                    <Fab size="medium"  aria-label="remove" className='accordion bg-black text-white'>
                    <ClearRoundedIcon />
                  </Fab>
                  :  <Fab size="medium"  aria-label="add" className='accordion'><AddIcon />
                </Fab>}
                    aria-controls="panel13bh-content"
                    id="panel13bh-header"
                  >
                  <h3>{t("13")} <span className='ms-5'>{t("support.questionManager13")}</span></h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='ml-76px'>
                      <p> {t("support.answerManager13")}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

              </div>
            </TabPanel>

            <TabPanel value={1} sx={{mt:5}} className='px-3 px-md-0'>

              <div >

                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className='accordion '>
                  <AccordionSummary
                    expandIcon={expanded === 'panel1' ?
                    <Fab size="medium"  aria-label="remove" className='accordion bg-black text-white'>
                    <ClearRoundedIcon />
                  </Fab>
                  :  <Fab size="medium"  aria-label="add" className='accordion'><AddIcon />
                </Fab>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                  <h3>{t("01")} <span className='ms-5'>{t("support.questionEmployee1")}</span></h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='ml-76px'>
                      <p> {t("support.answerEmployee1")}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className='accordion mt-4'>
                  <AccordionSummary
                    expandIcon={expanded === 'panel2' ?
                    <Fab size="medium"  aria-label="remove" className='accordion bg-black text-white'>
                    <ClearRoundedIcon />
                  </Fab>
                  :  <Fab size="medium"  aria-label="add" className='accordion'><AddIcon />
                </Fab>}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                  <h3>{t("02")} <span className='ms-5'>{t("support.questionEmployee2")}</span></h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='ml-76px'>
                      <p> {t("support.answerEmployee2")}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className='accordion mt-4'>
                  <AccordionSummary
                    expandIcon={expanded === 'panel3' ?
                    <Fab size="medium"  aria-label="remove" className='accordion bg-black text-white'>
                    <ClearRoundedIcon />
                  </Fab>
                  :  <Fab size="medium"  aria-label="add" className='accordion'><AddIcon />
                </Fab>}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                  <h3>{t("03")} <span className='ms-5'>{t("support.questionEmployee3")}</span></h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='ml-76px'>
                      <p> {t("support.answerEmployee3")}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className='accordion mt-4'>
                  <AccordionSummary
                    expandIcon={expanded === 'panel4' ?
                    <Fab size="medium"  aria-label="remove" className='accordion bg-black text-white'>
                    <ClearRoundedIcon />
                  </Fab>
                  :  <Fab size="medium"  aria-label="add" className='accordion'><AddIcon />
                </Fab>}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                  <h3>{t("04")} <span className='ms-5'>{t("support.questionEmployee4")}</span></h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='ml-76px'>
                      <p> {t("support.answerEmployee4")}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className='accordion mt-4'>
                  <AccordionSummary
                    expandIcon={expanded === 'panel5' ?
                    <Fab size="medium"  aria-label="remove" className='accordion bg-black text-white'>
                    <ClearRoundedIcon />
                  </Fab>
                  :  <Fab size="medium"  aria-label="add" className='accordion'><AddIcon />
                </Fab>}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                  >
                  <h3>{t("05")} <span className='ms-5'>{t("support.questionEmployee5")}</span></h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='ml-76px'>
                      <p> {t("support.answerEmployee5")}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

              </div>

            </TabPanel>


            </Tabs>
          </Col>
        </Row>
      </Row>
    </div>
        <Footer/>
    </>
  )
}

export default Support