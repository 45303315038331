import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, getFile, post } from "./apiService";
import { ACC_API_URL_DISTRIBUTE_WITHHOLDINGS, ACC_API_URL_WITHHOLDINGS, ACC_API_URL_WITHHOLDINGS_EXPORT } from "../config/api_urls";
import { IWithholdingsInterfaces } from "../models/withholdListInterfaces";

// FUNCTION TO FETCH WITH-HOLDING LIST
export const fetchWithholdList = createAsyncThunk(
  'hotels/fetchWithholdList',
  async ({ page, numRowsPerPage, search, status, start, end, sort, hotel, payee, payeeType }: IWithholdingsInterfaces, thunkAPI: any) => {
    try {
      let url = ACC_API_URL_WITHHOLDINGS + '?hotel=' + hotel;
      if (status) { url = url + '&status=' + status; }
      if (page) { url = url + '&page=' + page; }
      if (numRowsPerPage) { url = url + '&page_size=' + numRowsPerPage.toString(); }
      if (payee !== '') { url = url + '&payee=' + payee; }
      if (payeeType !== '') { url = url + '&payee_type=' + payeeType; }
      if (search !== '') { url = url + '&search=' + search?.trim(); }
      if (start && start !== '') { url = url + '&start=' + encodeURIComponent(start); }
      if (end && end !== '') { url = url + '&end=' + encodeURIComponent(end); }
      if (sort !== '') { url = url + '&sort=' + sort; }
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


// FUNCTION TO EXPORT WITH-HOLDING LIST
export const exportWithholdList = createAsyncThunk(
  'hotels/exportWithholdList',
  async ({ page, numRowsPerPage, search, status, start, end, sort, hotel, payee, payeeType }: IWithholdingsInterfaces, thunkAPI: any) => {
    try {
      let url = ACC_API_URL_WITHHOLDINGS_EXPORT + '?hotel=' + hotel;
      if (status) { url = url + '&status=' + status; }
      if (page) { url = url + '&page=' + page; }
      if (numRowsPerPage) { url = url + '&page_size=' + numRowsPerPage.toString(); }
      if (payee !== '') { url = url + '&payee=' + payee; }
      if (payeeType !== '') { url = url + '&payee_type=' + payeeType; }
      if (search !== '') { url = url + '&search=' + search?.trim(); }
      if (start && start !== '') { url = url + '&start=' + encodeURIComponent(start); }
      if (end && end !== '') { url = url + '&end=' + encodeURIComponent(end); }
      if (sort !== '') { url = url + '&sort=' + sort; }

      getFile(url, 'withholding_List_Export');
    } catch (error) {
      // console.log('withholding List Export error: ', error);
    }
  }
);

// FUNCTION TO HANDLE DISTRIBUTION OF WITH-HOLDING
export const handleDistribute = createAsyncThunk(
  'hotels/handleDistribute',
  async (thunkAPI: any) => {
    try {
      let url = ACC_API_URL_DISTRIBUTE_WITHHOLDINGS;
      const response = post(url, "");
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);