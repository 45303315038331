import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAccountSettingsForm } from "../models/settingsInterfaces";
import { get, put } from "./apiService";
import { SA_API_URL_DEFAULT_SETTINGS, SA_API_URL_DEFAULT_SETTINGS_UPDATE } from "../config/api_urls";

// FUNCTION TO FETCH COMMON SETTINGS FOR AN ACCOUNT
export const getAccountsettings = createAsyncThunk(
    'account/getAccountsettings',
    async (id: number, thunkAPI: any) => {
      try {
        const url = SA_API_URL_DEFAULT_SETTINGS+'1/'; // 1 IS DEFAULT ID FOR SETTINGS 
        const response = get(url);
        return (await response).data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
);
  
// FUNCTION TO UPDATE COMMON SETTINGS FOR AN ACCOUNT
export const updateDefaultSettings = createAsyncThunk(
  'account/updateDefaultSettings',
  async (form: IAccountSettingsForm, thunkAPI: any) => {
    try {
      const url = SA_API_URL_DEFAULT_SETTINGS_UPDATE+'1/'; // 1 IS DEFAULT ID FOR SETTINGS 
      const response = put(url, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);