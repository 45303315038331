import React, { useState, useEffect, useMemo } from 'react';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Modal } from "@mui/material"; 
import { useAppDispatch, useAppSelector } from "../../helpers/hook";
import PhoneInput from 'react-phone-input-2';
import { loadStripe } from "@stripe/stripe-js";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";

import "./style.css";

import { env_var, stripe_token_var, STRIPE_COMMISION, ROUTE_ACCOUNTS_LIST, MIN_MOBILE_NUM_LENGTH, USER_ROLE_ACCOUNT_USER, USER_ROLE_ACCOUNT_MANAGER, } from "../../config/env";  

import successImg from '../../assets/images/success-circle.png'

import useResponsiveFontSize from "./useresponsive";

import { PHONEINPUT_COUNTRIES, PHONEINPUT_COUNTRYCODE_EDITABLE, US_STATES_ARRAY } from "../../config/env";

import { setNotification } from '../../services/apiService';
import { getAdminUserRole, getAccessToken, getFilterAccountId } from "../../services/localStorage";
import { fetchVPPricingOne, fetchVPPricingTwo, fetchVPPricingThree, fetchCartDetails, fetchCartCount, fetchDocumentPreview } from "../../services/qrCodesServices";

import { selectQrcodesDataSlice, changeCartItemModifiedStatus, changeCartItemRemovedStatus } from "../QRCodes/QRCodesSlice"; 

import { ICartCheckoutData, IVPItemPriceData, IvpPricingPayload, IShippingAddress, IVPDocumentPreview, IVPDocumentPreviewRespItem, IQRCPaymentToken, IDeliveryOptions } from '../../models/qrCodeInterfaces';

import { Textfield } from '../../components/TextField';
import { SelectField } from "../../components/SelectField";

import { DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD, DH_TEMPLATE_DESIGN_TYPE_POSTCARD, DH_TEMPLATE_DESIGN_TYPE_TABLETENT, QRCODE_API_URL_PAYMENT_TOKEN, QRCODE_BILL_STRIPE_CHARGE,  VP_PRICING_API_SET_COUNT } from "../../config/env_qrcodes";
import { QRCODE_CART_ITEM_MODIFIED, QRCODE_CART_ITEM_REMOVED } from '../../config/apiResponseCodes';
import { isEmail } from '../../helpers/validators';

//styling options for card element
const useOptions = () => {
  const fontSize = useResponsiveFontSize();

  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

let stripePromise = loadStripe(stripe_token_var);

interface IStateTaxDelShipping {
  taxPercentage: number;
  deliveryOptions: IDeliveryOptions; 
  shippingAddress: IShippingAddress; 
}

const QRCodesPayment = () => {
  const adminUserRole = getAdminUserRole();
  const accountId = getFilterAccountId();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const stateData = location.state as IStateTaxDelShipping; // Type Casting, then you can get the params passed via router

  const { vpPricingDataOne, vpPricingDataTwo, vpPricingDataThree, cartData, cartItemModifiedStatus, cartItemRemovedStatus, documentPreviewRespItem, paymentShippingAddress, paymentDeliveryOption } = useAppSelector(selectQrcodesDataSlice);

  const [tovaAccessToken, setTovaAccessToken] = useState(getAccessToken());
  const [isCardError, setisCardError] = useState<boolean>(false);
  const [carderrormsg, setCardErrorMsg] = useState<any>("");
  const [isCard, setisCard] = useState<boolean>(false);
  const [privacyerr, setPrivacyerr] = useState<boolean>(false);

  const PAGE_STATUS_BILLING  = 1;
  const PAGE_STATUS_PAYMENT = 2; 

  const [pageProgressStatus, setPageProgressStatus] = useState(PAGE_STATUS_BILLING);  

  const [stateCartData, setStateCartData] = useState<ICartCheckoutData[]>([]); 
  const [stateCartDocPreviewData, setStateCartDocPreviewData] = useState<IVPDocumentPreviewRespItem[]>([]); 
  const [docPreviewRespCount, setDocPreviewRespCount] = useState(0); 
  const [modifiedCartId, setModifiedCartId] = useState(0); 
  const [deletedCartId, setDeletedCartId] = useState(0); 
  const [totalCartAmount, setTotalCartAmount] = useState(0); 
  const [shippingAmount, setShippingAmount] = useState(stateData?.deliveryOptions.amount ? stateData.deliveryOptions.amount : 0); 
  const [taxAmount, setTaxAmount] = useState(0); 
  const [taxPercentage, setTaxPercentage] = useState(stateData?.taxPercentage ? stateData.taxPercentage : 0); 
  const [paymentProcessingCharge, setPaymentProcessingCharge] = useState(0); 
  const [amountToStripe, setAmountToStripe] = useState(0); 

  const [statePaymentShippingAddress, setStatePaymentShippingAddress] = useState<IShippingAddress>( stateData?.shippingAddress ? stateData.shippingAddress :
    {
      cartIds: "",  
      firstName: "",  
      lastName: "", 
      address: "", 
      addressTwo: "", 
      city: "", 
      state: "", 
      postalCode: "", 
      country: "",  
      mobile: "", 
      email: "", 
    }
  ); 

  const [statePaymentDeliveryOption, setStatePaymentDeliveryOption] = useState<IDeliveryOptions>( stateData?.deliveryOptions ? stateData.deliveryOptions : 
    {
      deliveryOptionId: "",
      amount: 0,
      currency: "",
      deliveryArriveByDate: "",
      businessDays: 0, 
    }
  );

  const [stateVPPricingDataPostCard, setStateVPPricingDataPostCard] = useState<IVPItemPriceData[]>([]); 
  const [stateVPPricingDataBusinessCard, setStateVPPricingDataBusinessCard] = useState<IVPItemPriceData[]>([]); 
  const [stateVPPricingDataTableTent, setStateVPPricingDataTableTent] = useState<IVPItemPriceData[]>([]); 

  const [loadCartData, setLoadCartData] = useState(0); 

  const [statusPopup, setStatusPopup] = useState(false);
  
  const [billAddressType, setBillAddressType] = useState("");
  const [billFirstName, setBillFirstName]     = useState("");
  const [billLastName, setBillLastName]       = useState("");
  const [billAddress, setBillAddress]         = useState("");
  const [billAddressTwo, setBillAddressTwo]   = useState("");
  const [billCity, setBillCity]               = useState("");
  const [billState, setBillState]             = useState("");
  const [billPostalCode, setBillPostalCode]   = useState("");
  const [billCountry, setBillCountry]         = useState(t("defaultCountry"));
  const [billMobile, setBillMobile]           = useState("");
  const [billEmail, setBillEmail]             = useState("");

  const [pricingAPILoadCount, setPricingAPILoadCount] = useState(0); 

  const [designtype, setDesigntype] = useState(0);
  const [editBgColor, setEditBgColor] = useState<any>("");
  const [editTextColor, setEditTextColor] = useState<any>("");
  const [accCode, setAccCode] = useState<string>("");
  const [psCode, SetPsCode] = useState<string>("");
  const [psType, setPsType] = useState<string>("");
  const [value, setValue] = useState<any>(2);
  const [pstage, setPstage] = useState<any>(1);
  const [isAllowed, setIsAllowed] = useState<"loading" | true | false>(
    "loading"
  );
  const [dataToStripe, setDataToStripe] = useState<IQRCPaymentToken>(
    {
      priceList: [],
      billAmount: 0, 
      cartAmount: 0,
      shippingAmount: 0,
      deliveryOptionId: 0,
      firstName: "",
      lastName: "",
      city: "",
      state: "",
      zipCode: "",
      addressLine1: "",
      addressLine2: "",
      phone: "",
      email: "",
      billFirstName: "",
      billLastName: "",
      billCity: "",
      billState: "",
      billZipCode: "",
      billAddressLine1: "",
      billAddressLine2: "",
      billPhone: "",
      billEmail: ""
    }
  );

  const style1 = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "588px",
    height: "365px",
    bgcolor: 'white',
    border: '1px solid E6E8EB',
    borderRadius: "13px",
    boxShadow: 24,
  };

  useEffect(() => {
    // CALL PRICING FROM VISTA PRINT FOR EACH CARD TYPE  
    let pricingPayloadOne : IvpPricingPayload = { cartType: DH_TEMPLATE_DESIGN_TYPE_POSTCARD.toString()} 
    dispatch(fetchVPPricingOne(pricingPayloadOne)); 

    // setTimeout(() => {
    //   dispatch(fetchCartDetails({ accountId: accountId ? accountId.toString() : "" }));
    // }, 1000);
  },[]); 

  useEffect(() => {
    // PRICING FOR POST CARD - STORE IN STATE VARIABLE 
    if(vpPricingDataOne && vpPricingDataOne.length > 0) {
      setPricingAPILoadCount((pricingAPILoadCount+1)); 
      setStateVPPricingDataPostCard(vpPricingDataOne); 

      let pricingPayloadTwo : IvpPricingPayload = { cartType: DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD.toString()} 
      dispatch(fetchVPPricingTwo(pricingPayloadTwo)); 
    }
    else {
      setStateVPPricingDataPostCard([]); 
    }
     
  },[vpPricingDataOne]); 

  useEffect(() => {
    // PRICING FOR BUSINESS CARD - STORE IN STATE VARIABLE 
    if(vpPricingDataTwo && vpPricingDataTwo.length > 0) {
      setPricingAPILoadCount((pricingAPILoadCount+1)); 
      setStateVPPricingDataBusinessCard(vpPricingDataTwo); 

      let pricingPayloadThree : IvpPricingPayload = { cartType: DH_TEMPLATE_DESIGN_TYPE_TABLETENT.toString()} 
      dispatch(fetchVPPricingThree(pricingPayloadThree)); 
    }
    else {
      setStateVPPricingDataBusinessCard([]); 
    }
  },[vpPricingDataTwo]); 

  useEffect(() => {
    // PRICING FOR TABLE TENT - STORE IN STATE VARIABLE 
    if(vpPricingDataThree && vpPricingDataThree.length > 0) {
      setPricingAPILoadCount((pricingAPILoadCount+1)); 
      setStateVPPricingDataTableTent(vpPricingDataThree); 
    }
    else {
      setStateVPPricingDataTableTent([]); 
    }
  },[vpPricingDataThree]); 

  useEffect(() => {
    // AFTER LOADING ALL PRICING RESPONSES, CALL CART DETAILS 
    if(pricingAPILoadCount >= VP_PRICING_API_SET_COUNT) {
      dispatch(fetchCartDetails({ accountId: accountId ? accountId.toString() : "" }));
    }
  },[pricingAPILoadCount]);

  useEffect(() => {
    if(cartData && cartData.length > 0) {
      let arrNew:ICartCheckoutData[] = []; 
      cartData.map(cartItem => {
        let arrPricing = Number(cartItem.cardType)==Number(DH_TEMPLATE_DESIGN_TYPE_POSTCARD) ? stateVPPricingDataPostCard : 
  Number(cartItem.cardType)==Number(DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD) ? stateVPPricingDataBusinessCard : 
  Number(cartItem.cardType)==Number(DH_TEMPLATE_DESIGN_TYPE_TABLETENT) ? stateVPPricingDataTableTent : []; 
        let priceValue = 0; 
        let selValue = ""; 
        arrPricing.map((pItem, pIndex) => {
          if(pItem.sku==cartItem.sku && Number(pItem.quantity)==Number(cartItem.quantity)) {
            priceValue = pItem.price; 
            selValue = pIndex.toString(); 
          }
        }); 
        arrNew.push({...cartItem, price: priceValue, selectedValue:selValue, locked:true}); 
      });
      setStateCartData(arrNew); 
    }
  },[loadCartData]); 


  const loadImagePreviews = () => {
    if(stateCartData && stateCartData.length > 0) {
      stateCartData.map(item => {
        if((!item.previewUrl || item.previewUrl.length==0) && item.documentRefUrl && item.documentRefUrl.length > 0 && item.sku && item.sku.length > 0) {
          let preloadData : IVPDocumentPreview = {
            cartId: item.id, 
            documentRefUrl: item.documentRefUrl, 
            sku: item.sku
          }
          dispatch(fetchDocumentPreview(preloadData)); 
        }
      }); 
    }
  }

  useEffect(() => {
    setDocPreviewRespCount((docPreviewRespCount+1)); 
    let cartId = documentPreviewRespItem.cartId;
    let documentUrl = documentPreviewRespItem.documentUrl;
    let arrNew:IVPDocumentPreviewRespItem[] = []; 
    let arrTmp = stateCartDocPreviewData.filter(item => Number(item.cartId)==Number(cartId)); 
    if(!arrTmp || arrTmp.length==0) {
      arrNew.push(documentPreviewRespItem); 
      setStateCartDocPreviewData(arrNew); 
    } 

    if(stateCartData && stateCartData.length > 0) {
      let arrCartNew:ICartCheckoutData[] = []; 
      // SET VARIABLES FOR FOOTER 
      stateCartData.map(item => { 
        if(Number(cartId) == Number(item.id)) {
          arrCartNew.push({...item, previewUrl: documentUrl}); 
        }
        else{
          arrCartNew.push(item); 
        }
      }); 
      setStateCartData(arrCartNew); 
    }
  },[documentPreviewRespItem]);

  useEffect(() => {
    let totQuantity = 0, totAmount = 0;
    if(stateCartData && stateCartData.length > 0) {
      // SET VARIABLES FOR FOOTER 
      stateCartData.map(item => { 
        if(item.id && item.id > 0) {
          totQuantity += item.quantity ? item.quantity : 0;
          totAmount   += item.price ? item.price : 0;  
        }
      }); 
      setTotalCartAmount(totAmount); 
      loadImagePreviews(); 
    }
    else {
      setTotalCartAmount(totAmount); 
    }
  },[stateCartData]);

   
  useEffect(() => {
    if(totalCartAmount && totalCartAmount > 0) {
      let taxAmt = (totalCartAmount+shippingAmount) * (taxPercentage/100); 
      setTaxAmount(taxAmt); 
      let amtStripe = Number((((totalCartAmount+shippingAmount+taxAmt) + STRIPE_COMMISION) / (1 - (QRCODE_BILL_STRIPE_CHARGE / 100))).toFixed(2)) 
      setAmountToStripe(amtStripe);  
      setPaymentProcessingCharge(amtStripe-(totalCartAmount+shippingAmount+taxAmt)); 
    }
  },[totalCartAmount]);

  useEffect(() => {
    if(cartData && cartData.length > 0) {
      setLoadCartData(loadCartData+2); 
    }
  },[cartData]); 

  useEffect (() => {
    if(cartItemModifiedStatus && cartItemModifiedStatus==QRCODE_CART_ITEM_MODIFIED && modifiedCartId && modifiedCartId > 0) {
      setNotification(
        t("messages.appName"),  
        t("QRCodesCheckOut.msgCartModificationSuccess"),
        "success",
        "templateTypeMessage"
      ); 
      dispatch(changeCartItemModifiedStatus('')); 
      setModifiedCartId(0); 
    }
    else if(cartItemModifiedStatus && cartItemModifiedStatus=='modifyCartItemError' && modifiedCartId && modifiedCartId > 0) {
      setNotification(
        t("messages.appName"),  
        t("QRCodesCheckOut.msgCartModificationFailed"),
        "failed",
        "templateTypeMessage"
      ); 
      dispatch(changeCartItemModifiedStatus('')); 
      setModifiedCartId(0); 
    }
  },[cartItemModifiedStatus]);

  // CART ITEMS NUMBER ONLY FOR ACCOUNT LOGINS
  const callCartCount = () => {
    if(Number(adminUserRole) === Number(USER_ROLE_ACCOUNT_MANAGER) || Number(adminUserRole) === Number(USER_ROLE_ACCOUNT_USER)) {
      dispatch(fetchCartCount({ accountId: "" })); // ACCOUNT ID NOT REQUIRED NOW
    }
  }

  useEffect (() => {
    callCartCount(); 
  },[statusPopup]);

  useEffect (() => {
    if(cartItemRemovedStatus && cartItemRemovedStatus==QRCODE_CART_ITEM_REMOVED && deletedCartId && deletedCartId > 0) {
      setNotification(
        t("messages.appName"),  
        t("QRCodesCheckOut.msgCartItemRemovalSuccess"),
        "success",
        "templateTypeMessage"
      ); 
      dispatch(changeCartItemRemovedStatus('')); 
      setDeletedCartId(0); 
      dispatch(fetchCartDetails({ accountId: accountId ? accountId.toString() : "" }));
      callCartCount(); 
    }
    else if(cartItemRemovedStatus && cartItemRemovedStatus=='removeCartItemError' && deletedCartId && deletedCartId > 0) {
      setNotification(
        t("messages.appName"),  
        t("QRCodesCheckOut.msgCartItemRemovalFailed"),
        "failed",
        "templateTypeMessage"
      ); 
      dispatch(changeCartItemRemovedStatus('')); 
      setDeletedCartId(0); 
    }
  },[cartItemRemovedStatus]);

  useEffect (() => {
    if(stateCartData.length==docPreviewRespCount) {

    }
  },[stateCartDocPreviewData]);

  const getTemplateType = (templateType:string) => {
    switch(Number(templateType)) { 
      case DH_TEMPLATE_DESIGN_TYPE_POSTCARD : return t("qrcodesPage.postCard"); 
        break;
      case DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD : return t("qrcodesPage.businessCard"); 
        break;  
      case DH_TEMPLATE_DESIGN_TYPE_TABLETENT : return t("qrcodesPage.tableTent"); 
        break;  
      default: return templateType; 
        break; 
    }
  }

  const setBillingSameAsShipping = (typeValue:string) => {
    if(typeValue=="shipping") {
      setBillFirstName(statePaymentShippingAddress?.firstName ? statePaymentShippingAddress.firstName : "");
      setBillLastName(statePaymentShippingAddress?.lastName ? statePaymentShippingAddress.lastName : "");
      setBillAddress(statePaymentShippingAddress?.address ? statePaymentShippingAddress.address : "");
      setBillAddressTwo(statePaymentShippingAddress?.addressTwo ? statePaymentShippingAddress.addressTwo : "");
      setBillCity(statePaymentShippingAddress?.city ? statePaymentShippingAddress.city : "");
      setBillState(statePaymentShippingAddress?.state ? statePaymentShippingAddress.state : "");
      setBillPostalCode(statePaymentShippingAddress?.postalCode ? statePaymentShippingAddress.postalCode : "");
      setBillCountry(statePaymentShippingAddress?.country ? statePaymentShippingAddress.country : "");
      setBillMobile(statePaymentShippingAddress?.mobile ? statePaymentShippingAddress.mobile : "");
      setBillEmail(statePaymentShippingAddress?.email ? statePaymentShippingAddress.email : "");
    }
    else if(typeValue=="new") {
      setBillFirstName("");
      setBillLastName("");
      setBillAddress("");
      setBillAddressTwo("");
      setBillCity("");
      setBillState("");
      setBillPostalCode("");
      setBillCountry(t("defaultCountry"));
      setBillMobile("");
      setBillEmail("");
    }
    setBillAddressType(typeValue); 
    setPageProgressStatus(PAGE_STATUS_BILLING); 
  }

  const setBillingAddress = () => {
    if(billFirstName.trim().length==0 || billLastName.trim().length==0 || billAddress.trim().length==0 || billCity.trim().length==0 || billState.trim().length==0 || billPostalCode.trim().length==0 || billMobile.trim().length < MIN_MOBILE_NUM_LENGTH || billEmail.trim().length==0) { 
      setPageProgressStatus(PAGE_STATUS_BILLING); 
      return false; 
    }
    else if(isEmail(billEmail)!='') {
      setPageProgressStatus(PAGE_STATUS_BILLING); 
      return false; 
    }
    else {
      
      let pricingObj : any = []; //  {} pricingObj
      stateCartData.map(item => { 
        pricingObj.push({cart_id: item.id, price:item.price});  
      });
      let tokenObj : IQRCPaymentToken = {
        priceList: pricingObj, 
        billAmount: amountToStripe, 
        cartAmount: totalCartAmount, 
        shippingAmount: shippingAmount,
        taxAmount: taxAmount,
        deliveryOptionId: Number(statePaymentDeliveryOption.deliveryOptionId), 
        firstName:  statePaymentShippingAddress.firstName,
        lastName: statePaymentShippingAddress.lastName,
        city: statePaymentShippingAddress.city,
        state: statePaymentShippingAddress.state,
        zipCode: statePaymentShippingAddress.postalCode,
        addressLine1: statePaymentShippingAddress.address,
        addressLine2: statePaymentShippingAddress.addressTwo,
        phone: billMobile,
        email: billEmail,
        billFirstName: billFirstName,
        billLastName: billLastName,
        billCity: billCity,
        billState: billState,
        billZipCode: billPostalCode,
        billAddressLine1: billAddress,
        billAddressLine2: billAddressTwo,
        billPhone: billMobile,
        billEmail: billEmail 
      }
      setDataToStripe(tokenObj); 
      setPageProgressStatus(PAGE_STATUS_PAYMENT); 
      scrollToSection("div-payment"); 
    }
  }

  const scrollToSection = (elementId:string) => {
    const element = document.getElementById(elementId); 
    if (element) {
      const offsetTop = element.offsetTop;
      window.scrollTo({
        top: (offsetTop-75),
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="box-padding select-none">
      <div className="box">
        <div className='qr-checkout-nav'>
          <div>
            <div className='w-100'>{t("QRCodesPayment.QRCodesPayment")}</div>
          </div>
        </div>
        
        <div className='space-between'>
          <div className='w-100'>
            {/* 
            ********************************************************************
            ******************** CARTLISTING TABLE HEADINGS ******************** 
            ********************************************************************
            */}
            <div className='qr-checkout-head'>
              <div className='w-100'>
                <div className='gap-12px text-14px-500-black'>
                  <div className='flex'>
                    <div className="" style={{ width: "110px" }}>
                    </div>
                    <div className='pl-18px pr-21px flex w-100 align-items-center'>
                      <div className='flex-col w-100 '>
                        <span>{t("QRCodesCheckOut.item")}</span>
                      </div>
                      <div className="w-100 center ">
                        <span>{t("QRCodesCheckOut.quantity")}</span>
                      </div>
                      {/* <div className='w-100  flex-end pr-21px '>{t("QRCodesCheckOut.indivPrice")}</div> */}
                      <div className='w-100  flex-end pr-21px '>{t("QRCodesCheckOut.price")}</div>
                      {/* <div style={{ width: "30px", minWidth: "30px"}}>&nbsp;</div> */}
                      {/* <div style={{ width: "35px", minWidth: "35px"}}>&nbsp;</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 
            *******************************************************
            ******************** CART DATA LISTING **************** 
            *******************************************************
            */}
            <div className='px-4 pt-17px flex-col gap-12px'>
              {stateCartData && stateCartData.length > 0 && <>
                {stateCartData.map((item, index) => {
                  return(
                    <> 
                    <div className='selected-qr-card'>
                      <div className="selected-qr-img">
                        {/* {previewImgUrl!="" ? <><img src={previewImgUrl} alt="thump" className="qr-template-img" /></>:<><div>&nbsp;</div></>} */}
                        {item.previewUrl && item.previewUrl.trim().length > 1 ? <img src={item.previewUrl} alt="thump" className="qr-template-img" /> : <div>&nbsp;</div>}
                        {/* <input type="text" value={previewImgUrl} /> */}
                      </div>
                      <div className='pl-18px pr-21px flex w-100 align-items-center'>
                        <div className='flex-col w-100 '>
                          <span className='text-14px-600-black pb-12px'>{getTemplateType(item.cardType.toString())}</span>
                          <span className='text-12px-600-grey'>{item.name}</span>
                        </div>
                        <div className="w-100">
                        <div className=''>
                          {item.cardType && Number(item.cardType)==Number(DH_TEMPLATE_DESIGN_TYPE_POSTCARD) && stateVPPricingDataPostCard && stateVPPricingDataPostCard.map((prItem, prIndex) => {
                            if(Number(item.selectedValue)==prIndex) {
                                return (prItem.quantity.toString() + ' - ' +prItem.productName.replace(/^\"(.+)\"$/,"$1").replace('""','"').replace('""','"'));
                            }
                              })}

                              {item.cardType && Number(item.cardType)==Number(DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD) && stateVPPricingDataBusinessCard && stateVPPricingDataBusinessCard.map((prItem, prIndex) => {
                                if(Number(item.selectedValue)==prIndex) {
                                  return (prItem.quantity.toString() + ' - ' +prItem.productName.replace(/^\"(.+)\"$/,"$1").replace('""','"').replace('""','"'));
                                }
                              })}

                              {item.cardType && Number(item.cardType)==Number(DH_TEMPLATE_DESIGN_TYPE_TABLETENT) && stateVPPricingDataTableTent && stateVPPricingDataTableTent.map((prItem, prIndex) => {
                                if(Number(item.selectedValue)==prIndex) {
                                  return (prItem.quantity.toString() + ' - ' +prItem.productName.replace(/^\"(.+)\"$/,"$1").replace('""','"').replace('""','"')); 
                                }
                              })}
                        </div>
                      </div>
                      {/* <div className='w-100 flex-end pr-21px'>{t("currency")+(Number(item.quantity) > 0 ? ((item.price/item.quantity)?.toFixed(2))?.toString() : "0.00")}</div> */}
                      <div className='w-100 flex-end pr-21px'>{t("currency")+(item.price?.toFixed(2))?.toString()}</div>
                      {/* <div style={{ width: "35px", minWidth: "35px"}}>&nbsp;</div> */}
                      {/*<div className='flex-end'>
                         <img src={deleteIcon} alt="" height={32} width={32} className='pointer' onClick={e => onDeleteCartItem(item.id)} /> 
                      </div>*/}
                    </div>
                  </div>
                  </>
                  )
                } )}
                </>}

                {/* <div>&nbsp;</div> */}

                <div className=''>
                  <div className='space-between'>

                    {/* *****************************************************************
                    ************************ BILLING ADDRESS ************************
                    ***************************************************************** */}
                    <div className='w-100 mt-20px'>
                      <h2 className='text-22px-600-black mb-4'> {t("QRCodesPayment.billingAddress")}</h2>

                      <div className="flex gap-2 mb-10px ml-13px">
                        <div className='pt-5px'>
                          <input type="radio" name="rad_address" id="rad_address" checked={billAddressType=="shipping" ? true : false} onClick={() => setBillingSameAsShipping('shipping')} /> </div>
                        <div className='flex-col text-12px-600 mt-5px' style={{ width: "311px" }}  >
                            <span className='mb-2' onClick={() => setBillingSameAsShipping('shipping')}>{t("QRCodesPayment.sameShippingAddress")}</span>
                            {billAddressType=="shipping" && <> 
                            <span className='text-14px-400'>{billFirstName+" "+billLastName}</span>
                            <span className='text-14px-400'>{billAddress}</span>
                            {billAddressTwo && billAddressTwo.trim().length > 0 && <span className='text-14px-400'>{billAddressTwo}</span>}
                            <span className='text-14px-400'>{billCity}</span>
                            <span className='text-14px-400'>{billState+" "+billPostalCode}</span>
                            <div className='text-14px-400 flex'>
                              <span className='mr-5px'>{t("QRCodesPayment.phone")+" "}</span>
                               <PhoneInput
                                disabled={true}
                                buttonClass="hide"
                                containerClass="border-none tel-staff"
                                inputClass="border-none tel-staff text-14px-400"
                                value={billMobile}
                                placeholder={""}
                              />
                            </div>
                            <div className='text-14px-400 flex'>
                              <span className='mr-8px'>{t("QRCodesPayment.email")+" "}</span> {billEmail}
                            </div>
                             
                            {pageProgressStatus < PAGE_STATUS_PAYMENT && <div className="mt-10px w-100 space-between flex">
                              <div className="label  text-7D7E7E" onClick={(e) => e.stopPropagation()}>&nbsp; </div>
                                <button className='btn-blueWhite-h36-r6-f16-600 mt-10px px-27px' onClick={() => setBillingAddress()} >
                                    {t("QRCodesShipping.submit")} 
                                  </button> 
                              </div>}
                            </>}
                            {/* billCountry billMobile billEmail */}
                        </div>
                      </div>   
      

                      <div className="flex gap-2 mb-10px ml-13px">
                        <div className='pt-5px'>
                          <input type="radio" name="rad_address" id="rad_address" checked={billAddressType=="new" ? true : false} onClick={() => setBillingSameAsShipping('new')} /> </div>
                        <div className='flex-col text-12px-600 mt-5px' style={{ width: "500px" }} onClick={() => setBillingSameAsShipping('new')}>
                          <span className='mb-2'>{t("QRCodesPayment.useDifferentBillingAddress")}</span> 
                          {billAddressType=="new" && <> <div onClick={(e) => e.stopPropagation()}>
                            <div className="business w-100 p-0px mt-12px">
                              <div className="flex gap-4 mb-3">
                                <div className="w-100">
                                  <div className="label  text-7D7E7E">
                                    {t("QRCodesShipping.firstName")}
                                  </div>
                                  <Textfield 
                                      type="text"
                                      required
                                      maxLength={150}
                                      name="txt_firstName"
                                      id="txt_firstName"
                                      placeholder={`${t("QRCodesShipping.firstName")}`}
                                      value={billFirstName} 
                                      onChange={e => setBillFirstName(e.target.value)}
                                      className={billFirstName.trim().length==0 ? (" input-error ") : "" }
                                  />
                                </div>
                                <div className="w-100">
                                  <div className="label  text-7D7E7E">
                                    {t("QRCodesShipping.lastName")}
                                  </div>
                                  <Textfield 
                                    type="text"
                                    maxLength={150}
                                    name="txt_lastName"
                                    id="txt_lastName"
                                    placeholder={`${t("QRCodesShipping.lastName")}`}
                                    value={billLastName} 
                                    onChange={e => setBillLastName(e.target.value)} 
                                    className={billLastName.trim().length==0 ? (" input-error ") : ""}
                                  />
                                </div>
                              </div> 

                              <div className="mb-3">
                                <div className="label  text-7D7E7E">
                                  {t("QRCodesShipping.streetAddress")}
                                </div>
                                <Textfield
                                  type="text"
                                  required
                                  maxLength={250}
                                  name="txt_address"
                                  id="txt_address"
                                  placeholder={`${t("QRCodesShipping.streetAddress")}`} 
                                  value={billAddress} 
                                  onChange={e => setBillAddress(e.target.value)}
                                  className={billAddress.trim().length==0 ? (" input-error ") : ""}
                                />
                              </div>
                              
                              <div className="mb-3">
                                <div className="label  text-7D7E7E">
                                  {t("QRCodesShipping.apartmentInformation")}
                                </div>
                                <div className='w-100 relative'>
                                  <span className='qr-optional-text'>{t("QRCodesShipping.optional")}</span>
                                  <Textfield
                                    className={" pr-60px "}
                                    type="text"
                                    required
                                    maxLength={250}
                                    name="txt_addressTwo"
                                    id="txt_addressTwo"
                                    placeholder={`${t("QRCodesShipping.apartmentInformation")}`} 
                                    value={billAddressTwo} 
                                    onChange={e => setBillAddressTwo(e.target.value)}    
                                  />
                                </div>
                              </div>

                              <div className="flex gap-4 mb-3">
                                <div className="w-100">
                                  <div className="label  text-7D7E7E">
                                    {t("QRCodesShipping.city")}
                                  </div>
                                  <Textfield
                                    type="text"
                                    required
                                    min={1}
                                    maxLength={150}
                                    placeholder={`${t("QRCodesShipping.city")}`} 
                                    value={billCity} 
                                    onChange={e => setBillCity(e.target.value)} 
                                    className={billCity.trim().length==0 ? (" input-error ") : ""} 
                                  />
                                </div>
                                  
                                <div className="w-100">
                                  <div className="label  text-7D7E7E">{t("QRCodesShipping.state")}</div>
                                  <SelectField 
                                    type="inputType"
                                    name="txt_state"
                                    id="txt_state"
                                    placeHolder={t("QRCodesShipping.state")}
                                    value={billState}
                                    onChange={(e) => setBillState(e.target.value)}
                                    required
                                    className={billState.trim().length==0 ? (" input-error ") : ""} 
                                    options={
                                      US_STATES_ARRAY && US_STATES_ARRAY.map((item, index) => (
                                        <>
                                        {index == 0 && (
                                            <><option value="">{t("QRCodesShipping.state")}</option></>
                                        )}
                                        <option value={item.stateCode} style={{ color: "#000000" }} >
                                            {item.stateName}
                                        </option>
                                        </>
                                        ))
                                      }
                                  />
                                </div>
                              </div>

                              <div className="flex gap-4 mb-3">
                                <div className="w-100">
                                  <div className="label  text-7D7E7E">
                                      {t("QRCodesShipping.postalCode")}
                                  </div>
                                  <Textfield
                                    type="text"
                                    required
                                    maxLength={10}
                                    name="txt_postalCode"
                                    id="txt_postalCode"
                                    placeholder={`${t("QRCodesShipping.postalCode")}`} 
                                    value={billPostalCode} 
                                    onChange={e => setBillPostalCode(e.target.value)}
                                    className={billPostalCode.trim().length==0 ? (" input-error ") : ""} 
                                  />
                                </div>
                                
                                <div className="w-100">
                                  <div className="label  text-7D7E7E">
                                    {t("QRCodesShipping.country")}
                                  </div>
                                  <Textfield 
                                    type="text"
                                    name="txt_country"
                                    id="txt_country"
                                    required
                                    placeholder={`${t("QRCodesShipping.country")}`}
                                    value={billCountry}
                                    disabled={true}
                                    onChange={e => setBillCountry(e.target.value)}
                                  />
                                </div>
                              </div>  

                              <div className="mb-3 hidesl">
                                <div className="label  text-7D7E7E">
                                  {t("QRCodesShipping.cellPhone")}
                                </div>
                                <PhoneInput
                                  inputProps={{
                                      name: `mobile`,
                                      required: true,
                                      autoFocus: false,
                                  }}
                                  inputClass={billMobile.trim().length < MIN_MOBILE_NUM_LENGTH ? (" common-input input-error") : (" common-input ")} 
                                  onlyCountries={PHONEINPUT_COUNTRIES}
                                  countryCodeEditable={
                                      PHONEINPUT_COUNTRYCODE_EDITABLE
                                  }
                                  country={"us"}
                                  // specialLabel={`${t("adminUserList.mobileModal")}`}
                                  value={billMobile}
                                  onChange={(code) => setBillMobile(code)}
                                  prefix={"+"}
                                />
                              </div>  

                              <div className="">
                                <div className="label  text-7D7E7E">
                                    {t("QRCodesShipping.emailAddress")}
                                </div>
                                <Textfield
                                  type="text"
                                  required
                                  maxLength={250}
                                  name="txt_email"
                                  id="txt_email"
                                  placeholder={`${t("QRCodesShipping.emailAddress")}`} 
                                  value={billEmail}
                                  onChange={e => setBillEmail(e.target.value)} 
                                  className={isEmail(billEmail)=='' ? ""  : (" input-error ")}
                                />
                              </div>

                              {pageProgressStatus < PAGE_STATUS_PAYMENT && <div className="mt-20px w-100 space-between flex">
                                <div className="label  text-7D7E7E">&nbsp;</div>
                                <button className='btn-blueWhite-h36-r6-f16-600 mt-10px px-27px' onClick={() => setBillingAddress()} >
                                  {t("QRCodesShipping.submit")} 
                                </button> 
                              </div>}
                            </div>
                          </div></>}
                        </div>
                      </div>  

                    </div> 


                  </div> 
                </div> 
                
                {/* *****************************************************************
                *************************** STRIPE PAYMENT **************************
                ***************************************************************** */}
                <div id="div-payment">&nbsp;</div> 
                {pageProgressStatus >= PAGE_STATUS_PAYMENT && <div className=''>
                  <div className='space-between'>
                    <div className='w-100 mt-20px'>
                      <h2 className='text-22px-600-black mb-4'> {t("QRCodesPayment.payment")}</h2>
                      <div className='ml-13px'>
                      <h6 className='mb-4'> {t("QRCodesPayment.totalAmount")+" "+t("currency")+((amountToStripe).toFixed(2)).toString()}</h6>
                      </div>
                      <Elements stripe={stripePromise}>
                      <div className="mt-2 text-left">
                        <ApplePayForm />
                        {isCard ? (
                          <div className="product">
                            {pstage > 0 && <CheckoutForm />}
                          </div>
                        ) : null}
                      </div>
                    </Elements>

                    </div>
                  </div>
                </div>}
            </div>
          </div>
           
          <div className='px-4 pt-21px max-width-292px w-100 border-left min-height-66vh'>
            <div className=' flex-col'>
                <h1 className='text-22px-600-black pb-33px'>{t("QRCodesCheckOut.orderSummary")}</h1>
                <div className='text-14px-500-black space-between border-bottom pb-3'>
                    <span>{t("QRCodesCheckOut.total")}</span><span>{t("currency")+(totalCartAmount.toFixed(2)).toString()}</span>
                </div>
                <div className='text-14px-500-black space-between border-bottom py-3'>
                    <span>{t("QRCodesCheckOut.shipping")}</span><span>{t("currency")+(shippingAmount.toFixed(2)).toString()}</span>
                </div>
                {taxAmount > 0 && <div className='text-14px-500-black space-between border-bottom py-3'>
                    <span>{t("QRCodesCheckOut.tax")}</span><span>{t("currency")+(taxAmount.toFixed(2)).toString()}</span>
                </div>}
                {paymentProcessingCharge > 0 && <div className='text-14px-500-black space-between border-bottom py-3'>
                    <span>{t("QRCodesPayment.paymentProcessingCharge")}</span><span>{t("currency")+(paymentProcessingCharge.toFixed(2)).toString()}</span>
                </div>}
                <div className='text-16px-600-black space-between  py-3'>
                    <span>{t("QRCodesCheckOut.subTotal")}</span><span>{t("currency")+((amountToStripe).toFixed(2)).toString()}</span>
                </div>
            </div>
          </div>
        </div>

        <div className='px-37px'>
          <div className='space-between'>

          
          </div> 
        </div> 

        <div>&nbsp;</div>
        <div> </div>
      </div>

      <Modal
        open={statusPopup}
        onClose={() => {
            setStatusPopup(false);
            navigate(ROUTE_ACCOUNTS_LIST); 
          }
        }
        aria-labelledby={"successPopup"}
        aria-describedby=""
      >
        <Box sx={style1}>
          <div className='text-center relative flex-col center'>
              <img src={successImg} alt="" height={82} width={82} className='mt-38px mb-34px'/>
              <h5 className='text-22px-600-black mb-35px'>{t("QRCodesPayment.ThankYouForYourOrder")}</h5>
              <p className='text-14px-500-black mb-40px max-width-443px '>{t("QRCodesPayment.ThankYouForYourOrderMatter")}</p>
              <button className='qr-continue-btn' onClick={() => {
                setStatusPopup(false);
                callCartCount(); 
                navigate(ROUTE_ACCOUNTS_LIST); 
              }}>{t("QRCodesPayment.returnHome")}</button>
          </div>
        </Box>
      </Modal>
    </div>
  )

  //Card element
  function CheckoutForm() {
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    const [cardname, setCardname] = useState<string>("");
    const [paymentRequest, setPaymentRequest] = useState<any>(null);
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const onCardNameChange = (e: any) => {
      e.preventDefault();
      setCardname(e.target.value);
      if (e.target.value.trim().length == 0) {
        // setNameOnCardError(true);
        document.getElementById("txt-cardname")?.classList.add("input-error");
      } else {
        // setNameOnCardError(false);
        document
          .getElementById("txt-cardname")
          ?.classList.remove("input-error");
      }
    };

    useEffect(() => {
      if (!stripe || !elements) {
        return;
      }
      let amtToStrip = parseInt((amountToStripe * 100).toFixed(0), 10);
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Total amount",
          amount: amtToStrip,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      pr.on("paymentmethod", async (e) => {
      
        const bodyParameters =  {
          price_list: dataToStripe.priceList,
          bill_amount: dataToStripe.billAmount, 
          cart_amount: dataToStripe.cartAmount,
          shipping_amount: dataToStripe.shippingAmount,
          tax_amount: Number(dataToStripe.taxAmount.toFixed(2)),
          delivery_option_id: dataToStripe.deliveryOptionId,
          first_name: dataToStripe.firstName,
          last_name: dataToStripe.lastName,
          city: dataToStripe.city,
          state: dataToStripe.state,
          zip_code: dataToStripe.zipCode,
          address_line_1: dataToStripe.addressLine1,
          address_line_2: dataToStripe.addressLine2,
          phone: dataToStripe.phone,
          email: dataToStripe.email,
          bill_first_name: dataToStripe.billFirstName,
          bill_last_name: dataToStripe.billLastName,
          bill_city: dataToStripe.billCity,
          bill_state: dataToStripe.billState,
          bill_zip_code: dataToStripe.billZipCode,
          bill_address_line_1: dataToStripe.billAddressLine1,
          bill_address_line_2: dataToStripe.billAddressLine2,
          bill_phone: dataToStripe.billPhone,
          bill_email: dataToStripe.billEmail
        };
        const tokenURL = env_var.API_URL+QRCODE_API_URL_PAYMENT_TOKEN;
        axios.post( 
          tokenURL,
          bodyParameters, 
          {headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tovaAccessToken}`,
          }}
        ).then((res) => {
          console.log('Stripe Res11: ',res); 
          const persons = res.data;
          const paymentResult = stripe
            .confirmCardPayment(
              persons.token.client_secret,
              {
                payment_method: e.paymentMethod.id,
              },
              { handleActions: false }
            )
            .then((paymentResult) => {
              const dataz = paymentResult.paymentIntent;
              if (dataz && dataz.status === "succeeded") {
                console.log(dataz.id, "dataz");

                callCartCount(); 

                setPaymentLoading(false);
                setPstage(0);
                setisCard(false);
                
                setStatusPopup(true); 
              }
              else {
                console.log('paymentResult: ',paymentResult); 
                setPaymentLoading(false);
              }
            });
        }).catch(console.log);

      });
    }, [stripe, elements]);

    // card payment initiation
    const payMoney = async (e: any) => {
      e.preventDefault();
      // if (!isPrivacyChecked) {
      //   setPrivacyerr(true);
      //   return;
      // }

      if (cardname.trim().length == 0) {
        // setNameOnCardError(true);
        document.getElementById("txt-cardname")?.classList.add("input-error");
        return;
      }

      //checking is stripe is loaded for card element
      if (!stripe || !elements) {
        return;
      }
      //checking card name is entered
      if (cardname == "") {
        return;
      }
      setPaymentLoading(true);
      setisCardError(false);

      //requesting stripe payment intent
      let tipAmount = amountToStripe;
      
      const bodyParameters =  {
        price_list: dataToStripe.priceList,
        bill_amount: dataToStripe.billAmount, 
        cart_amount: dataToStripe.cartAmount,
        shipping_amount: dataToStripe.shippingAmount,
        tax_amount: Number(dataToStripe.taxAmount.toFixed(2)), 
        delivery_option_id: dataToStripe.deliveryOptionId,
        first_name: dataToStripe.firstName,
        last_name: dataToStripe.lastName,
        city: dataToStripe.city,
        state: dataToStripe.state,
        zip_code: dataToStripe.zipCode,
        address_line_1: dataToStripe.addressLine1,
        address_line_2: dataToStripe.addressLine2,
        phone: dataToStripe.phone,
        email: dataToStripe.email,
        bill_first_name: dataToStripe.billFirstName,
        bill_last_name: dataToStripe.billLastName,
        bill_city: dataToStripe.billCity,
        bill_state: dataToStripe.billState,
        bill_zip_code: dataToStripe.billZipCode,
        bill_address_line_1: dataToStripe.billAddressLine1,
        bill_address_line_2: dataToStripe.billAddressLine2,
        bill_phone: dataToStripe.billPhone,
        bill_email: dataToStripe.billEmail
      };
      const tokenURL = env_var.API_URL+QRCODE_API_URL_PAYMENT_TOKEN; 
      axios.post( 
        tokenURL,
        bodyParameters, 
        {headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tovaAccessToken}`,
        }}
      ).then((res) => {
          console.log('Stripe Res22: ',res); 
          const persons = res.data;

          //confirming payment intent
          const paymentResult = stripe
            .confirmCardPayment(persons.token.client_secret, {
              payment_method: {
                card: elements.getElement(CardNumberElement)!,
                billing_details: {
                  name: cardname,
                },
              },
            })
            .then((paymentResult) => {
              const dataz = paymentResult.paymentIntent;
              if (dataz && dataz.status === "succeeded") {

                console.log(dataz.id, "dataz");
                setPaymentLoading(false);

                callCartCount(); 

                setPstage(0);
                setisCard(false);
                setStatusPopup(true); 
              } else {
                //error handling
                console.log('paymentResult22:',paymentResult); 
                setCardErrorMsg(paymentResult?.error?.message);
                setPaymentLoading(false);
                setisCardError(true);
              }
            })
            .catch((err) => alert(err.message));
        }).catch((err) => {
          setPaymentLoading(false);
          console.log('axios.post error: ',err)
        });
    };

    return (
      <div
        style={{
          padding: "2rem",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            maxWidth: "500px",
            margin: "0 auto",
          }}
        >
            <form
              style={{
                display: "block",
                width: "100%",
              }}
              onSubmit={(e) => payMoney(e)}
            >
              <p className="tip-label text-left mb-3">
                {t("tipPage.cardInformation")}
              </p>
              <div className="Demo">
                <label className="topCardnumber">
                  <CardNumberElement
                    options={options}
                    onReady={() => {
                      console.log("CardNumberElement [ready]");
                    }}
                    onChange={(event) => {
                      console.log("CardNumberElement [change]", event);
                    }}
                    onBlur={() => {
                      console.log("CardNumberElement [blur]");
                    }}
                    onFocus={() => {
                      console.log("CardNumberElement [focus]");
                    }}
                  />
                </label>

                <label style={{ display: "flex" }}>
                  <CardExpiryElement
                    options={options}
                    onReady={() => {
                      console.log("CardNumberElement [ready]");
                    }}
                    onChange={(event) => {
                      console.log("CardNumberElement [change]", event);
                    }}
                    onBlur={() => {
                      console.log("CardNumberElement [blur]");
                    }}
                    onFocus={() => {
                      console.log("CardNumberElement [focus]");
                    }}
                  />

                  <CardCvcElement
                    options={options}
                    onReady={() => {
                      console.log("CardNumberElement [ready]");
                    }}
                    onChange={(event) => {
                      console.log("CardNumberElement [change]", event);
                    }}
                    onBlur={() => {
                      console.log("CardNumberElement [blur]");
                    }}
                    onFocus={() => {
                      console.log("CardNumberElement [focus]");
                    }}
                  />
                </label>
              </div>

              <p className="tip-label text-left mb-3">
                {t("tipPage.nameOnCard")}
              </p>
              <div className="Demo b0">

                <input
                  type="text"
                  id="txt-cardname"
                  className={"tip-input-tip"}
                  value={cardname}
                  onChange={(e: any) => {
                    e.preventDefault();
                    onCardNameChange(e);
                  }}
                />
              </div>

              {isCardError ? (
                <p className="m0 text-center text-danger">{carderrormsg}</p>
              ) : null}

              <button
                disabled={isPaymentLoading}
                type="submit"
                className="btntipContinue-qrc mt-4 btn-blueWhite-h36-r6-f16-600"
              >
                {isPaymentLoading
                  ? `${t("tipPage.processing")}`
                  : `${t("tipPage.pay")}`}
              </button>
            </form>
        </div>
      </div>
    );
  }

  // apple pay and google pay element component
  function ApplePayForm() {
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    const [cardname, setCardname] = useState<string>("");
    const [paymentRequest, setPaymentRequest] = useState<any>("");
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    useEffect(() => {
      // checking is stripe is loaded
      if (!stripe || !elements) {
        return;
      }

      if(pageProgressStatus==PAGE_STATUS_PAYMENT) {

        // Creating payment request for applepay and google pay
        let amtToStripe = parseInt((amountToStripe * 100).toFixed(0), 10);
        const pr = stripe.paymentRequest({
          country: "US",
          currency: "usd",
          total: {
            label: t("projectName"),
            amount: amtToStripe,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });

        // Check the availability of the Payment Request API.
        pr.canMakePayment().then((result) => {
          if (result) {
            setPaymentRequest(pr);
          }
        });

        pr.on("paymentmethod", async (e) => {

          const bodyParameters =  {
            price_list: dataToStripe.priceList,
            bill_amount: dataToStripe.billAmount, 
            cart_amount: dataToStripe.cartAmount,
            shipping_amount: dataToStripe.shippingAmount,
            tax_amount:  Number(dataToStripe.taxAmount.toFixed(2)), 
            delivery_option_id: dataToStripe.deliveryOptionId,
            first_name: dataToStripe.firstName,
            last_name: dataToStripe.lastName,
            city: dataToStripe.city,
            state: dataToStripe.state,
            zip_code: dataToStripe.zipCode,
            address_line_1: dataToStripe.addressLine1,
            address_line_2: dataToStripe.addressLine2,
            phone: dataToStripe.phone,
            email: dataToStripe.email,
            bill_first_name: dataToStripe.billFirstName,
            bill_last_name: dataToStripe.billLastName,
            bill_city: dataToStripe.billCity,
            bill_state: dataToStripe.billState,
            bill_zip_code: dataToStripe.billZipCode,
            bill_address_line_1: dataToStripe.billAddressLine1,
            bill_address_line_2: dataToStripe.billAddressLine2,
            bill_phone: dataToStripe.billPhone,
            bill_email: dataToStripe.billEmail
          }
          
          const tokenURL = env_var.API_URL+QRCODE_API_URL_PAYMENT_TOKEN; 
        
          axios.post( 
            tokenURL,
            bodyParameters, 
            {headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${tovaAccessToken}`,
            }}
          ).then((res) => {
            console.log('Stripe Res33: ',res); 
            const persons = res.data; 
            const paymentResult = stripe
              .confirmCardPayment(
                persons.token.client_secret,
                {
                  payment_method: e.paymentMethod.id,
                },
                { handleActions: false }
              )
              .then((paymentResult) => {
                const dataz = paymentResult.paymentIntent;

                if (dataz && dataz.status === "succeeded") {
                  console.log(dataz.id, "dataz");
                  e.complete("success");

                  callCartCount(); 

                  setPaymentLoading(false);
                  setPstage(0);
                  setisCard(false);
                  setStatusPopup(true); 
                  
                  console.log(paymentResult);
                } else {
                  //fail handling
                  e.complete("fail");
                  console.log('paymentResult33: ',paymentResult); 
                  setPaymentLoading(false);
                }
              });
          }).catch(console.log);

        })
      }
    }, [stripe, elements, pageProgressStatus]);

    return (
      <>
        {paymentRequest != null ? (
          pstage == 0 ? (
            <button
              onClick={() => {
                setPstage(1);
              }}
              className="btntipContinue-qrc mt-4 btn-blueWhite-h36-r6-f16-600"
            >
              {t("tipPage.continue")}
            </button>
          ) : null
        ) : pstage == 0 && !isCard ? (
          <div className="pay-with-card-div">
            <button
              onClick={() => {
                setisCard(true);
                // if (hoteldata.tipIncludeProcessFee && amountChanged) {
                //   handleClickOpen();
                // }
              }}
              type="button"
              className="btntipContinue-qrc-pay-card mt-4 btn-blueWhite-h36-r6-f16-600"
            >
              {t("tipPage.payWithCard")}
            </button>
          </div>
        ) : null}

        {pstage == 1 && !isCard ? (
          <>
            {paymentRequest && (
              <>
                <PaymentRequestButtonElement options={{ paymentRequest }} />
                <p className="text-center helvfont mt-3 w-45">{t("tipPage.or")}</p>
              </>
            )}
            <div className="pay-with-card-div">
              <button
                onClick={() => {
                  setisCard(true);
                }}
                className="btntipContinue-qrc mt-4 btn-blueWhite-h36-r6-f16-600"
              >
                {t("tipPage.payWithCard")}
              </button>
            </div>
          </>
        ) : null}
      </>
    );
  }
}

export default QRCodesPayment; 
