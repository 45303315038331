import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

import {
  PAY_STATUS_CODE_PENDING, PAY_STATUS_CODE_SUCCESS, PAY_STATUS_CODE_FAILED, PAY_STATUS_CODE_READY,PAY_STATUS_CODE_ONHOLD, PAY_STATUS_CODE_PAYOUT_START, PAY_STATUS_CODE_REPROCESSING, STAFF_STATUS_ACTIVE, STAFF_STATUS_INACTIVE, STAFF_STATUS_INVITE, STAFF_STATUS_DELETE 
} from "../../config/env";
import { GetPayoutStatusText, getStaffStausById } from "../../config/common_functions";

interface IProps {
  fieldName: string;
  value: string;
  toolTipData: string;
  status: number;
  boldFont: boolean;
  customClass: string;
}

const StatusField: React.FC<IProps> = ({
  fieldName,
  value,
  status,
  boldFont,
  customClass,
  toolTipData,
}) => {
  const { t } = useTranslation();
  // STATUS 2 IS FOR READY STATUS IN PAYMENTS SCREEN 
  let statusClass =
  status === 2 ? "ready-status" : status === 1 ? "success-status" : status === -1 ? "pending-status" : "danger-status";

  const boldClass = boldFont ? "font-bold" : "";
  let valToDisplay = value;

  if(fieldName=='staffStatus') { // FOR STAFF STATUS FIELD 
    valToDisplay = getStaffStausById(Number(value)); 
    statusClass = Number(value)==STAFF_STATUS_ACTIVE ? "success-status" : Number(value) === STAFF_STATUS_INVITE ? "pending-status" : (Number(value)==STAFF_STATUS_INACTIVE || Number(value)==STAFF_STATUS_DELETE) ? "danger-status" : "" ;
  } else if(fieldName=='documentVerified') {
    valToDisplay = value === "true" ? t("common.yes") : value === "false" ? t("common.no") : value;
  }
  else if (value === "true") {
    valToDisplay = t("common.active");
  } else if (value === "false") {
    valToDisplay = t("common.inActive");
  } else if (value === "invited") {
    valToDisplay = t("common.invited");
  } 
  else if(Number(value) === PAY_STATUS_CODE_SUCCESS || Number(value) === PAY_STATUS_CODE_FAILED || Number(value) === PAY_STATUS_CODE_PENDING || Number(value) === PAY_STATUS_CODE_READY || Number(value) === PAY_STATUS_CODE_ONHOLD || Number(value) === PAY_STATUS_CODE_PAYOUT_START || Number(value) === PAY_STATUS_CODE_REPROCESSING) {
    valToDisplay = GetPayoutStatusText(Number(value)); 
  }
  
  return (
    <>
      <span
        data-tip={toolTipData}
        className={`${statusClass}  ${customClass}`}
        style={{ textTransform: "capitalize" }}
      >
        {valToDisplay !== undefined && valToDisplay.toString()}
      </span>
      <ReactTooltip />
    </>
  );
};

export default StatusField;
