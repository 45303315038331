
// PAGE TO DEFINE CONSTANTS FOR AVAILABLE API ERRORS OR WARNINGS RESPONSE CODES  

export const API_RESP_USER_ADDED:string                  = 'user_added'; 
export const ERR_TIP_ALREADY_REFUNDED:string             = 'tip_already_refunded'; 
export const ERR_TIP_ALREADY_DISTRIBUTED:string          = 'tip_already_distributed'; 
export const ERR_USER_NOT_REGISTERED                     = 'user_not_registered'; 
export const ERR_USER_NOT_REGISTERED2                    = 'user_not_registered.'; 
export const ERR_USER_ALREADY_MAPPED                     = 'user_already_mapped'; 
export const ERR_USER_ALREADY_EXISTS                     = 'username_already_exists'; 
export const ERR_EMAIL_ALREADY_EXISTS                    = 'email_already_exists'; 
export const ERR_USER_ALREADY_REGISTERED                 = 'user_already_registered'; 
export const ERR_INVALID_HEADINGS                        = 'invalid_headings'; 
export const ERR_UNAPPROVED_STAFF                        = 'un_approved_maid'; 
export const ERR_IMAGE_SIZE_EXCEEDED                     = 'image_size_exceeded'; 
export const ERR_STRIPE_SERVICE_UNAVAILABLE              = 'service_unavailable'; 
export const ERR_PENDING_REQUIRED_FIELDS_STAFF           = 'pending_required_fields'; 
export const ERR_NEED_USER_MERGE                         = 'need_user_merge'; 
export const ERR_INVALID_TOKEN                           = 'invalid_token'; 
export const ERR_INVALID_USERID_EXPITYTIME               = 'invalid_userid_expirytime'; 
export const ERR_INVALID_USER                            = 'invalid_user'; 
export const ERR_USER_DOES_NOT_EXIST                     = 'user_does_not_exist'; 
export const ERR_INVALID_DATE                            = 'invalid_date'; 
export const ERR_LINK_TIME_EXPIRED                       = 'link_time_expired'; 
export const ERR_PHONE_NUMBER_EXISTS                     = 'phone_number_already_exists'; 
export const ERR_USER_EXISTS_IN_ACCOUNT                  = 'user_already_added_in_current_hotel'; 
export const ERR_TERMS_NOT_ACCEPTED                      = 'terms_and_conditions_not_accepted'; 
export const ERR_VERIFY_FAILED                           = 'verify_failed'; 
export const ERR_INVALID_OTP                             = 'invalid_otp'; 
export const ERR_NO_ACTIVE_ACCOUNT                       = 'no_active_hotel'; 
export const ERR_ACC_NAME_EXIST                          = 'active_hotel_name_already_exist'; 
export const ERR_STAFF_ALREADY_INVITED                   = 'already_invited'; 
export const ERR_PHONE_ALREADY_EXISTS                    = 'phone_already_exists'; 


export const ERR_ACC_SIGN_UP_EMAIL_ALREADY_VERIFIED      = 'email_already_verified'; 
export const ERR_ACC_SIGN_UP_LINK_INVALID_TOKEN          = 'invalid_token'; 
export const ACC_SIGN_UP_EMAIL_VERIFIED                  = 'email_verified'; 
export const GROUP_NAME_EXISTS                           = 'name_already_exists'; 
export const GROUP_STAFF_DUPLICATED                      = 'staff_duplicated'; 

export const ERR_PAYOUT_DETAILS_INVALID_STATUS           = 'invalid_status'; 

export const PAYOUT_REPROCESS_FAILED                     = 're_process_failed'; 
export const PAYOUT_REPROCESS_DISTRIBUTED                = 'already_distributed'; 
export const PAYOUT_REPROCESS_SUCCESS                    = 're_processed_successfully'; 
export const PAYOUT_REPROCESS_INITIATED                  = 're_process_already_initiated'; 

export const STAFF_INVITE_INVALID_PHONE                  = 'invalid_phone'; 
export const STAFF_INVITE_MSG_SEND_FAILED                = 'message_send_failed'; 

export const USER_DOES_NOT_EXIST                         = 'user_does_not_exist'; 
export const USER_ALREADY_MAPPED_TO_HOTEL                = 'user_already_mapped_to_hotel'; 

export const QRCODE_GENERATION_FAILED                    = 'qr_generation_failed'; 
export const STAFF_DELETE_ERROR_GROUP_MAP                = 'user_mapped_in_groups'; 

export const ACC_USER_ALREADY_ADDED                      = 'user_already_added_in_hotel'; 
export const USER_SELF_DELETE                            = 'self_delete'; 

export const STAFF_BANK_ACCOUNT_MISSING                  = 'staff_bank_account_missing'; 
export const ACCOUNT_BANK_ACCOUNT_MISSING                = 'hotel_bank_account_missing'; 

export const QRCODE_CART_ITEM_MODIFIED                   = 'qty_updated'; 
export const QRCODE_CART_ITEM_REMOVED                    = 'product_removed_from_cart';







