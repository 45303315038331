import { createAsyncThunk } from "@reduxjs/toolkit";
import { ACC_API_URL_ACC_USERS, ACC_API_URL_ACC_USER_CREATE, ACC_API_URL_ACC_USER_DELETE, ACC_API_URL_ACC_USER_PWD_RESET, ACC_API_URL_ACC_USER_UPDATE, SA_API_URL_ACC_USERS, SA_API_URL_ACC_USERS_EXPORT_CSV, SA_API_URL_ACC_USER_CREATE, SA_API_URL_ACC_USER_DELETE,  SA_API_URL_ACC_USER_STATUS_UPDATE, SA_API_URL_ACC_USER_UPDATE } from "../config/api_urls";
import { arrAccountUserIds } from "../config/env";
import { IAccountUserDetails, IUserDeleteReq, IUserPwdResetReq, IUsersRequestData } from "../models/usersListInterfaces";
import { deleteApi, get, getFile, post } from "./apiService";

// FUNCTION TO FETCH ACCOUNT USERS LIST 
export const fetchUsersList = createAsyncThunk( 
  'staff/fetchUsersList',
  async ({ userRole, page, numRowsPerPage, search, hotel, role, document_verified, joining_from_date, joining_to_date, sort, active }: IUsersRequestData, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(userRole) ? ACC_API_URL_ACC_USERS : SA_API_URL_ACC_USERS) + '?page=' + page + '&hotel=' + hotel;
      if (numRowsPerPage)         { url = url + '&page_size=' + numRowsPerPage.toString(); }
      if (search !== '')           { url = url + '&search=' + search.trim(); }
      if (document_verified !== ''){ url = url + '&document_verified=' + document_verified; }
      if (joining_from_date !== '')  { url = url + '&joining_from_date=' + joining_from_date; }
      if (joining_to_date !== '')    { url = url + '&joining_to_date=' + joining_to_date; }
      if (sort !== '')             { url = url + '&sort=' + sort; }
      if (active !== '')           { url = url + '&active=' + active; }
      if (role >= 0)              { url = url + '&role=' + role; }
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO EXPORT ACCOUNT USERS LIST
export const exportUsersList = ({ userRole, page, search, hotel, role, document_verified, joining_from_date, joining_to_date, sort, active }: IUsersRequestData): void => {
  try {
    let url = SA_API_URL_ACC_USERS_EXPORT_CSV + '?page=' + page + '&hotel=' + hotel;
    if (search !== '')           { url = url + '&search=' + search.trim(); }
    if (document_verified !== ''){ url = url + '&document_verified=' + document_verified; }
    if (joining_from_date !== '')  { url = url + '&joining_from_date=' + joining_from_date; }
    if (joining_to_date !== '')    { url = url + '&joining_to_date=' + joining_to_date; }
    if (sort !== '')             { url = url + '&sort=' + sort; }
    if (active !== '')           { url = url + '&active=' + active; }
    if (role >= 0)              { url = url + '&role=' + role; }

    getFile(url, 'Users_list');
  } catch (error) {
  }
};

// FUNCTION TO RE-SET ACCOUNT USER LIST PASSWORDD
export const resetUserListPassword = createAsyncThunk(
  'associates/resetAssocUserPassword',
  async (userDetails: IUserPwdResetReq, thunkAPI: any) => {
    try {
      // console.log('userDetails:',userDetails); 
      let url =  ACC_API_URL_ACC_USER_PWD_RESET  + '?username=' + userDetails.username;
      const response = post(url, "");
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// FUNCTION TO DELETE ACCOUNT USER
export const deleteUser = createAsyncThunk(
  'accountusersList/deleteUser',
  async (reqData: IUserDeleteReq, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(reqData.userRole) ? ACC_API_URL_ACC_USER_DELETE : SA_API_URL_ACC_USER_DELETE) + reqData.userId + '/';
      const response = deleteApi(url);
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// FUNCTION TO UPDATE ACCOUNT USERS
export const statusUpdateAccountUsers = createAsyncThunk(
  'hotels/statusUpdateAccountUsers',
  async (user_id: number, thunkAPI: any) => {
    try {
      const url = SA_API_URL_ACC_USER_STATUS_UPDATE + user_id + '/';
      const response = post(url, {});
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// FUNCTION TO CREATE ACCOUNT USER
export const createAccountUsers = createAsyncThunk(
  'hotels/createHotelAssociate',
  async (newAssoc: IAccountUserDetails, thunkAPI: any) => {
    try {
      const url = (arrAccountUserIds.includes(newAssoc.userRole) ? ACC_API_URL_ACC_USER_CREATE : SA_API_URL_ACC_USER_CREATE);
      var userData: IAccountUserDetails = {
        firstName: newAssoc.firstName,
        lastName: newAssoc.lastName,
        mobile: newAssoc.mobile,
        username: newAssoc.username,
        position: newAssoc.position,
        isActive: newAssoc.isActive,
        role: newAssoc.role,
        hotelList: newAssoc.hotelList,
      };
      const response = post(url, userData);
      return (await response).data;
    } catch (error: any) {
      // console.log('createAccountUsers: ', error); 
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// FUNCTION TO UPDATE ACCOUNT USER
export const updateAccountUsers = createAsyncThunk(
  'hotels/updateAccountUsers',
  async (userData: IAccountUserDetails, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(userData.userRole) ? ACC_API_URL_ACC_USER_UPDATE : SA_API_URL_ACC_USER_UPDATE) + userData.userId + '/';
      var newData : IAccountUserDetails = {
        userId: userData.userId,
        firstName: userData.firstName,
        lastName: userData.lastName,
        mobile: userData.mobile,
        username: userData.username,
        position: userData.position,
        isActive: userData.isActive,
        role: userData.role,
        hotelList: userData.hotelList,
      };
      const response = post(url, newData);
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);