import { get, postWithoutAuth } from './apiService';
import { SA_API_URL_ACC_POOL_TIP_DETAILS, SA_API_URL_ACC_RECEIPT, SA_API_URL_ACC_RECEIPT_SEND_MAIL } from '../config/api_urls';

export interface IReceiptInterface {
  mail:string;
  content:string;  
}

// Function to retrieve hotel images based on account code, pool/staff code, and type identifier.
export const getHotelImage = (accCode: string, psCode: string, psType: string): any => {
  try {
    {/* accCode: Account Code, psCode: PoolCode OR StaffCode, psType: Used to Identify 'psCode' is Pool OR Staff */}
    let url = SA_API_URL_ACC_POOL_TIP_DETAILS+accCode+'/'+psCode+'/'+psType;
    return get(url);
  } catch (error) {
    throw error;
  }
};

// Function to retrieve receipt data based on a specific identifier.
export const getReceiptData = (id: string): any => {
  try {
    {/* accCode: Account Code, psCode: PoolCode OR StaffCode, psType: Used to Identify 'psCode' is Pool OR Staff */}
    let url = SA_API_URL_ACC_RECEIPT+id;
    return get(url);
  } catch (error) {
    throw error;
  }
};

// Function to send receipt mail using provided data.
export const sendReceiptMail = (data: IReceiptInterface): any => {
  try {
    {/* accCode: Account Code, psCode: PoolCode OR StaffCode, psType: Used to Identify 'psCode' is Pool OR Staff */}
    let url = SA_API_URL_ACC_RECEIPT_SEND_MAIL;
    return postWithoutAuth(url,data);
  } catch (error) {
    throw error;
  }
};
