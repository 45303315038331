import React from 'react'

import upload from "../../assets/images/upload.svg";
import copy from "../../assets/images/copy.svg";
import uploadcloud from "../../assets/images/uploadcloud.svg";
import open from "../../assets/images/open.svg";
import pen from "../../assets/images/pen.svg";

// COMMON INLINE BUTTON COMPONENT 

interface ButtonInLineProps {
    type?: string;
    htmlFor?: string;
    className?: string;
    uploadcloudIcon?: boolean;
    buttonLabel: string;
    uploadIcon?: boolean;
    inputName?: string;
    inputId?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    href?: string;
    download?: string;
    target?: string;
    openIcon?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    penIcon?: boolean;
    copyIcon?: boolean;
  }


export const ButtonInLine: React.FC<ButtonInLineProps> = React.memo((props) => { 
    let content;
    switch (props.type) {
        case 'label':
            content = <label
                htmlFor={props.htmlFor}
                className={'inline-btn ' + props.className}
            >
                {props.uploadcloudIcon && <img src={uploadcloud} alt="" />}
                <span className={`${props.uploadcloudIcon && 'mt-2px'}`}>{props.buttonLabel}</span>
                {props.uploadIcon && <img src={upload} alt="" />}
                <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    name={props.inputName}
                    id={props.inputId}
                    onChange={props.onChange}
                    style={{ display: "none" }}
                    disabled={props.disabled ? props.disabled : false}
                />
            </label>;
            break;
        case 'link':
            content =
                <a
                    href={props.href}
                    download={props.download}
                    target={props.target}
                    className={"inline-btn " + props.className}
                >
                    {props.buttonLabel} {props.openIcon && <img src={open} alt="open" />}
                </a>
                ;
            break;
        case 'edit_or_save':
            content = <button className="editbutton" onClick={props.onClick}>
                {props.buttonLabel} {props.penIcon && <img src={pen} alt="pen" className="pb-2px " />}
            </button>;
            break;
        case 'action':
            content = <button className={`${"editbutton " + (props?.className ? props.className : "")}`}  onClick={props.onClick}>
                {props.buttonLabel} 
            </button>;
            break;    
        default:
            content = <button
                className={"inline-btn " + props.className}
                onClick={props.onClick}
            >
                <span> {props.buttonLabel} </span>
                {props.copyIcon && <img src={copy} alt="copy" />}
            </button>;
            break;
    }

    return (
        <>{content}</>
    )
});
