import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IPayments, IPaymentsSummary, IManagePaymentDetails, IPaymentReceiptDetails } from '../../models/paymentsInterfaces';
import { IStripePayMethod } from '../../models/tipsInterfaces';
import { setNotification } from '../../services/apiService';
import { t } from 'i18next';

import { fetchPaymentsSummary, fetchPayments, fetchManagePaymentDetails, fetchReceiptDetails, reprocessFailedPayout, saveManagePaymentData, changePayoutDestination } from '../../services/paymentsListServices';
import { fetchStripePayMethods } from '../../services/tipsListServices';
import { IPagination } from '../../models/interfaces';
import { PAYOUT_STAFF } from '../../config/env';
import { PAYOUT_REPROCESS_SUCCESS } from '../../config/apiResponseCodes';

export interface DataState {
  paymentsList: IPayments[];
  paymentsSummary: IPaymentsSummary; 
  paymentsListLoading: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
  totalSize: number;
  currentPage: number;
  totalPage: number;
  stripePayMethods: IStripePayMethod[];
  managePayReceiptDataStatus: string, 
  managePaymentDetails: IManagePaymentDetails,
  paymentReceiptDetails: IPaymentReceiptDetails, 
  reprocessStatus: string, 
  managePaymentDataSaveStatus: string,
  changePayoutDestinationStatus: string,
}

const initialState: DataState = {
  paymentsList: [],
  paymentsSummary: { totalTransaction: 0,
    totalPayoutAmount: '', 
    totalPayoutAmountCurrency: '', 
    totalSucceeded: 0,
    totalFailed: 0,
  },
  paymentsListLoading: 'idle',
  error: '',
  totalSize: 0,
  currentPage: 0,
  totalPage: 0,
  stripePayMethods: [],
  managePayReceiptDataStatus: "", 
  managePaymentDetails: {
    accountName: "",
    group: "",
    status: "",
    amountToDistribute: 0, 
    amountToDistributeCurrency: '', 
    startDate: "",
    endDate: "",
    staffDetails: [
      {
        staffId: 0,
        payoutDestination: PAYOUT_STAFF,
        distributionPercentage: 0, 
        staffName: "",
      }
    ]
  },
  paymentReceiptDetails: {
    accountName: "", 
    group: "", 
    startDate: "", 
    endDate: "", 
    status: "", 
    amountToDistribute: 0,  
    amountToDistributeCurrency: "",  
    subTotal: 0,  
    subTotalCurrency: "",  
    fees: 0,  
    feesCurrency: "",  
    total: 0,  
    totalCurrency: "",  
    staffDetails: []
  },
  reprocessStatus: "", 
};

export const paymentsListDataSlice = createSlice({
  name: 'paymentsListData',
  initialState,
  reducers: {
    resetManagePaymentDataSaveStatus: (state) => {
      state.managePaymentDataSaveStatus = ""; 
    },
    resetReprocessStatus: (state) => {
      state.reprocessStatus = "";
    },
    resetManagePayReceiptDataStatus: (state) => {
      state.managePayReceiptDataStatus = "";
    },
    setPaymentsList: (state, action: PayloadAction<any>) => {
      state.paymentsList = action.payload;
    },
    setPaymentsDataLoading: (state) => {
      state.paymentsListLoading = 'loading';
    },
    setCurrentPage: (state, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    resetPaymentsSummary: (state, action: PayloadAction<any>) => {
      state.paymentsSummary = action.payload;
    },
    resetPayments: (state, action: PayloadAction<any>) => {
      state.paymentsList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPayments.pending, (state) => {
      state.paymentsList = [];
      state.paymentsListLoading = 'loading';
    });
    builder.addCase(fetchPayments.fulfilled, (state, action) => {
      const m = action.payload as IPagination<IPayments[]>;
      state.paymentsList = m.results;
      state.paymentsListLoading = 'succeeded';
      state.totalSize = m.count;
    });
    builder.addCase(fetchPayments.rejected, (state, { payload }: any) => {
      state.paymentsListLoading = 'error';
      state.totalSize = 0;
      // state.error = action.error.message;
    });

    builder.addCase(fetchPaymentsSummary.pending, (state) => {
      state.paymentsSummary = { totalTransaction: 0,
        totalPayoutAmount: '', 
        totalPayoutAmountCurrency: '', 
        totalSucceeded: 0,
        totalFailed: 0,
      };
      // state.paymentsListLoading = 'loading';
    });
    builder.addCase(fetchPaymentsSummary.fulfilled, (state, action) => {
      const m = action.payload as IPaymentsSummary;
      state.paymentsSummary = m;
      // state.paymentsListLoading = 'succeeded';
    });
    builder.addCase(fetchPaymentsSummary.rejected, (state, { payload }: any) => {
      state.paymentsSummary = { totalTransaction: 0,
        totalPayoutAmount: '', 
        totalPayoutAmountCurrency: '', 
        totalSucceeded: 0,
        totalFailed: 0,
      };
    }); 

    builder.addCase(fetchStripePayMethods.pending, (state) => {
      state.stripePayMethods = [];
    });
    builder.addCase(fetchStripePayMethods.fulfilled, (state, action) => {
      const m = action.payload as IStripePayMethod[];
      console.log('fetchStripePayMethods: ',m); 
      state.stripePayMethods = m;
    });
    builder.addCase(fetchStripePayMethods.rejected, (state, { payload }: any) => {
      state.stripePayMethods = []; 
    });


    builder.addCase(fetchManagePaymentDetails.pending, (state) => {
      state.managePaymentDetails = {
        accountName: "",
        group: "",
        status: "",
        amountToDistribute: 0, 
        amountToDistributeCurrency: '', 
        startDate: "",
        endDate: "",
        staffDetails: [
          {
            staffId: 0,
            payoutDestination: PAYOUT_STAFF,
            distributionPercentage: 0, 
            staffName: "",
          }
        ]
      }
    });
    builder.addCase(fetchManagePaymentDetails.fulfilled, (state, action) => {
      const m = action.payload as IManagePaymentDetails;
      state.managePaymentDetails = m;
    });
    builder.addCase(fetchManagePaymentDetails.rejected, (state, { payload }: any) => {
      state.managePayReceiptDataStatus = payload?.response?.data?.message ? payload.response.data.message : 'error';  
      state.managePaymentDetails = {
        accountName: "",
        group: "",
        status: "",
        amountToDistribute: 0, 
        amountToDistributeCurrency: '', 
        startDate: "",
        endDate: "",
        staffDetails: [
          {
            staffId: 0,
            payoutDestination: PAYOUT_STAFF,
            distributionPercentage: 0, 
            staffName: "",
          }
        ]
      }
    });


    builder.addCase(fetchReceiptDetails.pending, (state) => {
      state.paymentReceiptDetails = {
        accountName: "", 
        group: "", 
        startDate: "", 
        endDate: "", 
        status: "", 
        amountToDistribute: 0,  
        amountToDistributeCurrency: "",  
        subTotal: 0,  
        subTotalCurrency: "",  
        fees: 0,  
        feesCurrency: "",  
        total: 0,  
        totalCurrency: "",  
        staffDetails: []
      }
    });
    builder.addCase(fetchReceiptDetails.fulfilled, (state, action) => {
      const m = action.payload as IPaymentReceiptDetails;
      state.paymentReceiptDetails = m;
    });
    builder.addCase(fetchReceiptDetails.rejected, (state, { payload }: any) => {
      state.managePayReceiptDataStatus = payload?.response?.data?.message ? payload.response.data.message : 'error';  
      state.paymentReceiptDetails = {
        accountName: "", 
        group: "", 
        startDate: "", 
        endDate: "", 
        status: "", 
        amountToDistribute: 0,  
        amountToDistributeCurrency: "",  
        subTotal: 0,  
        subTotalCurrency: "",  
        fees: 0,  
        feesCurrency: "",  
        total: 0,  
        totalCurrency: "",  
        staffDetails: []
      }; 
    });


    builder.addCase(reprocessFailedPayout.pending, (state) => {
      state.reprocessStatus = "loading";
    });
    builder.addCase(reprocessFailedPayout.fulfilled, (state, action) => {
      const m = action;
      state.reprocessStatus = PAYOUT_REPROCESS_SUCCESS;
      console.log('reprocess success: ',m); 
    });
    builder.addCase(reprocessFailedPayout.rejected, (state, { payload }: any) => {
      state.reprocessStatus = payload?.response?.data?.message ? payload.response.data.message : 'error';  
      console.log('reprocess rejected: ',state.reprocessStatus); 
    });


    builder.addCase(saveManagePaymentData.pending, (state) => {
      state.managePaymentDataSaveStatus = "loading";      
      state.paymentsListLoading = 'loading';
    });
    builder.addCase(saveManagePaymentData.fulfilled, (state, action) => {
      const m = action;
      state.managePaymentDataSaveStatus = 'success';      
      state.paymentsListLoading = 'succeeded';
      console.log('reprocess success: ',m); 
    });
    builder.addCase(saveManagePaymentData.rejected, (state, { payload }: any) => {
      state.managePaymentDataSaveStatus = payload?.response?.data?.message ? payload.response.data.message : 'error';  
      console.log('reprocess rejected: ',state.managePaymentDataSaveStatus); 
           
      state.paymentsListLoading = 'error';
    });

    builder.addCase(changePayoutDestination.pending, (state) => {
      state.changePayoutDestinationStatus = "loading";      
    });
    builder.addCase(changePayoutDestination.fulfilled, (state, action) => {
      state.changePayoutDestinationStatus = 'success';      
      setNotification(
        t("projectName"),
        t("messages.payoutDestinationChanged"),
        "success",
        "updateStaffDetail"
      )
    }); 
    builder.addCase(changePayoutDestination.rejected, (state, { payload }: any) => {
      // state.changePayoutDestinationStatus = payload?.response?.data?.message ? payload.response.data.message : 'error';  
      setNotification(
        t("projectName"),
        t("messages.payoutDestinationChangeFailed"),
        "success",
        "updateStaffDetail"
      );
  
    });

    
  },
});

// Action creators are generated for each case reducer function
export const { setPaymentsList, setPaymentsDataLoading, resetPayments, resetPaymentsSummary, setCurrentPage, resetManagePayReceiptDataStatus, resetReprocessStatus, resetManagePaymentDataSaveStatus } = paymentsListDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectPaymentsListDataSlice = (state: RootState) => state.paymentsListData;

export const paymentsListDataReducer = paymentsListDataSlice.reducer;
