import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, getFile } from './apiService';
import { IDashboardChartRequestData } from '../models/dashboardInterfaces';
import { SA_API_URL_DASHBOARD_CHART_DATA, ACC_API_URL_DASHBOARD_CHART_DATA, ACC_API_URL_DASHBOARD_EXPORT_CHART_DATA, SA_API_URL_DASHBOARD_EXPORT_CHART_DATA } from '../config/api_urls'; 
import { arrAccountUserIds } from '../config/env'; 

// FUNCTION TO FETCH LIST OF ACCOUNTS WITH FILTERS 
export const fetchChartData = createAsyncThunk(
  'accounts/fetchChartData',
  async ({ userRole, chartFor, chartPeriod, chartPeriodFrom, chartPeriodTo, chartPrevPeriodFrom, chartPrevPeriodTo, accountIds,  groupIds, staffIds}: IDashboardChartRequestData, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(parseInt(userRole,10)) ? ACC_API_URL_DASHBOARD_CHART_DATA : SA_API_URL_DASHBOARD_CHART_DATA)+'?chart_for=' + chartFor+'&chart_period=' + chartPeriod + '&chart_period_from=' + encodeURIComponent(chartPeriodFrom) + '&chart_period_to=' + encodeURIComponent(chartPeriodTo) + '&chart_prev_period_from=' + encodeURIComponent(chartPrevPeriodFrom) + '&chart_prev_period_to=' + encodeURIComponent(chartPrevPeriodTo) + '&account_ids=' + accountIds+ '&group_ids=' + groupIds + '&staff_ids=' + staffIds;  
      
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO EXPORT CHART DATA
export const exportChartData = ({ userRole, chartFor, chartPeriod, chartPeriodFrom, chartPeriodTo, chartPrevPeriodFrom, chartPrevPeriodTo, accountIds,  groupIds, staffIds}: IDashboardChartRequestData): void => {
  try {
    let url = (arrAccountUserIds.includes(parseInt(userRole,10)) ? ACC_API_URL_DASHBOARD_EXPORT_CHART_DATA  : SA_API_URL_DASHBOARD_EXPORT_CHART_DATA)+'?chart_for=' + chartFor+'&chart_period=' + chartPeriod + '&chart_period_from=' + encodeURIComponent(chartPeriodFrom) + '&chart_period_to=' + encodeURIComponent(chartPeriodTo) + '&chart_prev_period_from=' + encodeURIComponent(chartPrevPeriodFrom) + '&chart_prev_period_to=' + encodeURIComponent(chartPrevPeriodTo) + '&account_ids=' + accountIds+ '&group_ids=' + groupIds + '&staff_ids=' + staffIds;  

    getFile(url, 'Account_List_Export');
  } catch (error) {
  }
};



