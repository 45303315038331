import React, { useEffect, useState, useRef } from 'react';
// import { useAppDispatch, useAppSelector } from '../../helpers/hook';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { ITips, ITipsSummary, IStripePayMethod } from '../../models/tipsInterfaces';
import { fetchTipsSummary, fetchTips, fetchStripePayMethods, performRefund } from '../../services/tipsListServices';
import { IGenericResponse, IPagination } from '../../models/interfaces';
import { ERR_TIP_ALREADY_DISTRIBUTED, ERR_TIP_ALREADY_REFUNDED } from "../../config/apiResponseCodes";

export interface DataState {
  tipsList: ITips[];
  tipsSummary: ITipsSummary; 
  tipsListLoading: 'idle' | 'loading' | 'success' | 'succeeded' | 'error';
  refundStatus: 'idle' | 'loading' | 'success' | 'error' | {ERR_TIP_ALREADY_REFUNDED} | {ERR_TIP_ALREADY_DISTRIBUTED};
  refundIds: Array<string>;
  error: string | undefined;
  totalSize: number;
  currentPage: number;
  totalPage: number;
  stripePayMethods: IStripePayMethod[];
}

const initialState: DataState = {
  tipsList: [],
  tipsSummary: { platformFees: 0, 
    platformFeesCurrency: '',
    stripeFees: 0,
    stripeFeesCurrency: '',
    totalPayout: 0,
    totalPayoutAmount: 0,
    totalPayoutAmountCurrency: '',
    totalTransaction: 0,
    totalTransactionAmount: 0,
    totalTransactionAmountCurrency: '',
  },
  tipsListLoading: 'idle',
  refundStatus: 'idle', 
  refundIds: [], 
  error: '',
  totalSize: 0,
  currentPage: 0,
  totalPage: 0,
  stripePayMethods: [],
};


export const tipsListDataSlice = createSlice({
  name: 'tipsListData',
  initialState,
  reducers: {
    setTipsList: (state, action: PayloadAction<any>) => {
      state.tipsList = action.payload;
    },
    setTipsDataLoading: (state) => {
      state.tipsListLoading = 'loading';
    },
    setCurrentPage: (state, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    resetTipsSummary: (state, action: PayloadAction<any>) => {
      state.tipsSummary = action.payload;
    },
    resetTips: (state, action: PayloadAction<any>) => {
      state.tipsList = action.payload;
    },
    setRefundStatus: (state, action: PayloadAction<any>) => { 
      state.refundStatus = action?.payload ? action.payload : 'idle'; 
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTips.pending, (state) => {
      state.tipsList = [];
      state.tipsListLoading = 'loading';
    });
    builder.addCase(fetchTips.fulfilled, (state, action) => {
      const m = action.payload as IPagination<ITips[]>;
      state.tipsList = m.results;
      state.tipsListLoading = 'succeeded';
      state.totalSize = m.count;
    });
    builder.addCase(fetchTips.rejected, (state, { payload }: any) => {
      state.tipsListLoading = 'error';
      state.totalSize = 0;
      // state.error = action.error.message;
    });

    builder.addCase(fetchTipsSummary.pending, (state) => {
      state.tipsSummary = { platformFees: 0, 
        platformFeesCurrency: '',
        stripeFees: 0,
        stripeFeesCurrency: '',
        totalPayout: 0,
        totalPayoutAmount: 0,
        totalPayoutAmountCurrency: '',
        totalTransaction: 0,
        totalTransactionAmount: 0,
        totalTransactionAmountCurrency: '',
      };
      // state.tipsListLoading = 'loading';
    });
    builder.addCase(fetchTipsSummary.fulfilled, (state, action) => {
      const m = action.payload as ITipsSummary;
      state.tipsSummary = m;
      // state.tipsListLoading = 'succeeded';
    });
    builder.addCase(fetchTipsSummary.rejected, (state, { payload }: any) => {
      state.tipsSummary = { platformFees: 0, 
        platformFeesCurrency: '',
        stripeFees: 0,
        stripeFeesCurrency: '',
        totalPayout: 0,
        totalPayoutAmount: 0,
        totalPayoutAmountCurrency: '',
        totalTransaction: 0,
        totalTransactionAmount: 0,
        totalTransactionAmountCurrency: '',
      };
      // state.tipsListLoading = 'error';
      // state.error = action.error.message;
    }); 

    builder.addCase(fetchStripePayMethods.pending, (state) => {
      state.stripePayMethods = [];
    });
    builder.addCase(fetchStripePayMethods.fulfilled, (state, action) => {
      const m = action.payload as IStripePayMethod[];
      console.log('fetchStripePayMethods: ',m); 
      state.stripePayMethods = m;
    });
    builder.addCase(fetchStripePayMethods.rejected, (state, { payload }: any) => {
      state.stripePayMethods = []; 
    });

    builder.addCase(performRefund.pending, (state) => {
      state.refundStatus = 'loading';
    });
    builder.addCase(performRefund.fulfilled, (state, action) => {
      state.refundStatus = 'success'; 
    });
    builder.addCase(performRefund.rejected, (state, { payload }: any) => {
      // console.log('payload: ',payload.response.data); 
      state.refundStatus = payload?.response?.data?.message ? payload.response.data.message : 'error';
    });
    
    // builder.addCase(fetchFilterAccounts.pending, (state) => {
    //   state.accounts = [];
    //   state.tipsListLoading = 'loading';
    // });
    // builder.addCase(fetchFilterAccounts.fulfilled, (state, action) => {
    //   const m = action.payload as IPagination<ITips[]>;
    //   state.accounts = m.results;
    //   state.loading = 'succeeded';
    //   state.totalSize = m.count;
    // });
    // builder.addCase(fetchFilterAccounts.rejected, (state, { payload }: any) => {
    //   state.loading = 'error';
    //   // state.error = action.error.message;
    // });
  },
});

// Action creators are generated for each case reducer function
export const { setTipsList, setTipsDataLoading, resetTips, resetTipsSummary, setCurrentPage,setRefundStatus } = tipsListDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectTipsListDataSlice = (state: RootState) => state.tipsListData;

export const tipsListDataReducer = tipsListDataSlice.reducer;
