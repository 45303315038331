import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Modal } from "@mui/material";
import { Store } from "react-notifications-component";
import warning from "../../assets/images/warning.svg";
import yellowrn from "../../assets/images/yellowrm.svg";
import PhoneInput from "react-phone-input-2";
import { CloseRounded } from "@mui/icons-material";

import Table from "../../components/Table";
import Paginations from "../../components/Pagination";
import { Textfield } from "../../components/TextField";
import FilterAccount from "../../components/FilterAccount";
import FilterSection from "../../components/FilterSection";
import { SelectField } from "../../components/SelectField";
import { ButtonModal } from "../../components/ButtonGroups/ButtonModal";
import { AdminUsersTableHeader } from "../../components/Table/TableHeaders";

import { showDeleteActivateOption } from "../../config/common_functions";
import { checkActionPermission, PERM_ADD_ADMIN_USER, PERM_EDIT_ADMIN_USER, } from "../../config/permissions";
import {
  PAGENAME_ADMINUSERS,
  PAGINATION_NUM_ROWS,
  PHONEINPUT_COUNTRIES,
  PHONEINPUT_COUNTRYCODE_EDITABLE,
  USER_ROLE_ADMIN_ADMIN,
  USER_ROLE_ADMIN_SUPERADMIN,
} from "../../config/env"; 

import { useAppDispatch, useAppSelector } from "../../helpers/hook";
import { IAccountAdminUserDetails, IAdminUsersListRequestData, } from "../../models/adminUsersListInterfaces";

import { setNotification } from '../../services/apiService'; 
import { getAdminUserRole, getAdminUserId, getFilterAccountId, setLastActivePageAPIData } from "../../services/localStorage";
import {
  createAdminUser,
  deleteAdminUser,
  fetchAdminUsers,
  resetAdminUserPassword,
  updateAdminUser,
} from "../../services/adminUserServices";

import {
  selectadminUsersListDataSlice,
  setCurrentPage,
} from "./adminUserSlice";


function AdminUsers() {
  const prevAPIDataString = localStorage.getItem('lastActivePageAPIData');
  const prevAPIData = prevAPIDataString ? JSON.parse(prevAPIDataString) : {};
  const pageName = PAGENAME_ADMINUSERS; 
  const adminUserRole = getAdminUserRole();
  const adminUserId = getAdminUserId();
  
  const localStorageAccId = getFilterAccountId();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const style = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 805,
    maxHeight: 785,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "13px",
    boxShadow: 24,
  };

  const [bgShade, setBgShade] = useState(false);

  const [selectedHotels, setSelectedHotels] = useState("");
  const [sortField, setSortField] = useState("full_name");
  const [status, setStatus] = useState("");
  const [role, setRole] = useState(0);
  const [search, setSearch] = useState("");

  const [modalHeading, setModalHeading] = useState(
    `${t("adminUserList.modalEditAccountAdminUserHeading")}`
  );

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isEmailV, setIsisEmailV] = useState<boolean>(false);
  const [mobile, setMobile] = useState("");
  const [position, setPosition] = useState("");
  const [editRole, setEditRole] = useState<number>();
  const [updateMultiAccount, setUpdateMultiAccount] = useState("");
  const [multiAccount, setMultiAccount] = useState([]);
  const [popup, setPopup] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [numRowsPerPage, setNumRowsPerPage] = useState(PAGINATION_NUM_ROWS);
  const [resetStatusFilterToAll, setResetStatusFilterToAll] = useState(0); 

  const [initialAPICalled, setInitialAPICalled] = useState(false);

  // 'accept...' VARIABLES USED TO SET/RESET FILTERS ON REFRESH   
  const [acceptAccountFilterIds, setAcceptAccountFilterIds] = useState("");
  const [acceptStatusFilterData, setAcceptStatusFilterData] = useState(""); 
  const [acceptRoleFilterData, setAcceptRoleFilterData] = useState(0); 
  const [acceptSearchText, setAcceptSearchText] = useState(""); 

  const { adminUsersList, totalSize, currentPage, loading } = useAppSelector(
    selectadminUsersListDataSlice
  );

  const handleSort = (sortField: string) => {
    setSortField(sortField);
  };

  const onResendEmail = (username: string) => {
    if (username) {
      dispatch(resetAdminUserPassword(encodeURIComponent(username)));
    }
  };

  const fetchall = (): void => {
    if(!initialAPICalled && prevAPIData.pageName && prevAPIData.pageName==pageName && prevAPIData?.apiData?.hotel && prevAPIData.apiData.hotel.length > 0) { 
      dispatch(fetchAdminUsers(prevAPIData.apiData)); 

      dispatch(setCurrentPage(prevAPIData.apiData.page));

      setSelectedHotels(prevAPIData.apiData.hotel);
      setSortField(prevAPIData.apiData.sort);
      setStatus(prevAPIData.apiData.active);
      setRole(prevAPIData.apiData.role); 
      setSearch(prevAPIData.apiData.search);
      setNumRowsPerPage(prevAPIData.apiData.numRowsPerPage); 
    
      if(prevAPIData.apiData.hotel && prevAPIData.apiData.hotel.trim().length > 0) {
        setAcceptAccountFilterIds(prevAPIData.apiData.hotel); 
      }
      if(prevAPIData.apiData.active && prevAPIData.apiData.active.trim().length > 0) {
        setAcceptStatusFilterData(prevAPIData.apiData.active); 
      } 
      if(prevAPIData.apiData.role >= 0) {
        setAcceptRoleFilterData(prevAPIData.apiData.role); 
      }
      if(prevAPIData.apiData.search && prevAPIData.apiData.search.trim().length > 0) {
        setAcceptSearchText(prevAPIData.apiData.search); 
      } 

      setTimeout(() => {
        setInitialAPICalled(true);
      }, 1250);
      
    }
    else { 
      const req: IAdminUsersListRequestData = {
        page: currentPage,
        hotel: selectedHotels
          ? selectedHotels
          : localStorageAccId
          ? localStorageAccId
          : "",
        sort: sortField,
        active: status,
        role: role,
        search: search,
        numRowsPerPage: numRowsPerPage,
      };
      dispatch(fetchAdminUsers(req)); 
      setLastActivePageAPIData({pageName:pageName, apiData:req}); 
      // TO PREVENT THE OTHER USEEFFECTS ON INITIAL LOADING OF THIS PAGE 
      setTimeout(() => {
        setInitialAPICalled(true);
      }, 1000);
    }
  };

  useEffect(() => {
    if(!initialAPICalled) {
      fetchall();
    }
  }, []);

  useEffect(() => {
    if (
      loading == "deleted" ||
      loading == "statusUpdated" ||
      loading == "userCreated" ||
      loading == "userUpdated" || 
      loading == "resetUserPasswordSuccess" 
    ) {
      if(loading == "userCreated" || loading == "userUpdated") {
        setPopup(false); 
        // IF INACTIVE USER LIST IS THERE, RESET STATUS TO ACTIVE TO VIEW THE NEWLY ADDED USER
        if(loading == "userCreated" && status.toLowerCase()=="no") { 
          setStatus(""); 
          setResetStatusFilterToAll(resetStatusFilterToAll+1);
        }
        else {
          fetchall();
        }
      }
      else {
        fetchall(); 
      }
    }
  }, [loading]);

  useEffect(() => {
    if(initialAPICalled) {
      fetchall();
    }
  }, [
    currentPage,
    sortField
  ]);


  useEffect(() => {
    if(initialAPICalled) {
      if (currentPage !== 1) {
        acceptPage(1);
      }
      else {
        fetchall();
      }
    }
  }, [selectedHotels, search, numRowsPerPage, role, status]);

  const acceptPage = (e: any): void => {
    dispatch(setCurrentPage(e));
  };

  const clearFilters = () => {
    setSortField("full_name");
    setStatus("");
    setRole(0);
    acceptPage(1);
    setSearch("");
  };

  const setStatusFilter = (status: string) => {
    setStatus(status);
  };

  const onClickTableOption = (
    actionType: string,
    staffId: string,
    accountId: string,
    staffName: string,
    accountName: string,
    isActive: string
  ) => {
    confirmDeleteStaff(staffId, staffName);
  };

  const confirmDeleteStaff = (id: string, staffName: string) => {
    setBgShade(true);
    Store.addNotification({
      title: t("adminUserList.modalDeleteHeader"),
      id: "maid-modal",
      content: (
        <div className="idproof-modal div-center w-60perc relative">
          <div className="page-heading">
            <img src={warning} alt="" />
          </div>
          <div className="idproof-flex-center div-center">
            <div>
              {t("adminUserList.modalDeleteConfirm", {
                staff: staffName,
                projectname: t("projectName"),
              })}
            </div>
          </div>
          <div className="idproof-margin"></div>
          <div className="flex w-100 gap-4">
            <ButtonModal
              type="cancel"
              onClick={() => {
                setBgShade(false);
                Store.removeNotification("maid-modal");
              }}
              buttonLabel={t("common.cancel")}
            />
            <ButtonModal
              type="delete"
              onClick={() => {
                setBgShade(false);
                Store.removeNotification("maid-modal");
                dispatch(deleteAdminUser(id));
              }}
              buttonLabel={t("common.delete")}
            />
          </div>
        </div>
      ),
      type: "default",
      container: "center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 1000000000,
        click: false,
        touch: false,
      },
    });
  };

  const onSetUserName = (value) => {
    setUsername(value);
    if (value && value.length > 0) {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (value.match(validRegex)) {
        setUsernameError(false);
      } else {
        setUsernameError(true);
      }
    } else {
      setUsernameError(false);
    }
  };

  const onResetEmail = (username: string) => {
    if (username && username.length > 0) {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (username.match(validRegex)) {
        setBgShade(true);
        Store.addNotification({
          title: "Reset Password",
          id: "resetpwd-modal",
          content: (
            <div className="idproof-modal div-center text-center w-60perc relative">
              <div className="page-heading">
                <img src={yellowrn} alt="" />
              </div>
              <div className="resetpwd-flex-center">
                {t("messages.resetPasswordConfirm",{userName:username})}
              </div>
              <div className="resetpwd-margin">&nbsp;</div>
              <div className="flex w-100 gap-4">
                <ButtonModal
                  type="cancel"
                  onClick={() => {
                    Store.removeNotification("resetpwd-modal");
                    setBgShade(false);
                  }}
                  buttonLabel={t("common.cancel")}
                />
                <ButtonModal
                  onClick={() => {
                    Store.removeNotification("resetpwd-modal");
                    setBgShade(false);
                    dispatch(
                      resetAdminUserPassword(encodeURIComponent(username))
                    );
                  }}
                  buttonLabel={t("common.resetPassword")}
                />
              </div>
            </div>
          ),
          type: "default",
          container: "center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 1000000000,
            click: false,
            touch: false,
          },
        });
      }
    }
  };

  const onUsersSaveSubmitted = () => {
    if (
      firstName &&
      firstName.trim().length > 0 &&
      lastName &&
      lastName.trim().length > 0 &&
      username &&
      username.trim().length > 0
    ) {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (username.match(validRegex)) {
        if (userId == 0) {
          if(Number(editRole)!=USER_ROLE_ADMIN_SUPERADMIN && updateMultiAccount.trim().length==0) {
            setNotification(
              t("projectName"),
              t("adminUserList.accountSelectError"),
              "failed",
              "createAccountUsers"
            );
          }
          else {
            var userData: IAccountAdminUserDetails = {
              firstName: firstName,
              lastName: lastName,
              mobile: (!mobile || mobile=="") ? "" : (mobile.trim()=="1" || mobile.trim()=="+1") ? "" : 
                mobile && mobile.trim().length > 0
                  ? mobile.search("\\+") < 0
                    ? "+" + mobile
                    : mobile
                  : "",
              username: username,
              position: position,
              isActive: isActive,
              role: Number(editRole),
              hotelList: Number(editRole)==USER_ROLE_ADMIN_SUPERADMIN ? "" : updateMultiAccount,
            };
            dispatch(createAdminUser(userData));
          }
        } else {
          if(Number(editRole)!=USER_ROLE_ADMIN_SUPERADMIN && updateMultiAccount.trim().length==0) {
            setNotification(
              t("projectName"),
              t("adminUserList.accountSelectError"),
              "failed",
              "createAccountUsers"
            );
          }
          else {
            var userData: IAccountAdminUserDetails = {
              userId: Number(userId),
              firstName: firstName,
              lastName: lastName,
              mobile: (!mobile || mobile=="") ? "" : (mobile.trim()=="1" || mobile.trim()=="+1") ? "" : 
                mobile && mobile.trim().length > 0
                  ? mobile.search("\\+") < 0
                    ? "+" + mobile
                    : mobile
                  : "",
              username: username,
              position: position,
              isActive: isActive,
              role: Number(editRole),
              hotelList: Number(editRole)==USER_ROLE_ADMIN_SUPERADMIN ? "" : updateMultiAccount,
            };
            dispatch(updateAdminUser(userData));
          }
        }
      }
    } else {
      if (username.trim().length <= 0) {
        setUsernameError(true);
      }
      if (firstName.trim().length <= 0) {
        setFirstNameError(true);
      }
      if (lastName.trim().length <= 0) {
        setLastNameError(true);
      }
    }
  };

  const createUser = (e) => {
    let locAccId = getFilterAccountId(); 
    if(locAccId) {
      setMultiAccount([Number(locAccId)]);
      setUpdateMultiAccount(locAccId.toString()); 
    }
    else {
      setMultiAccount([]);
      setUpdateMultiAccount(""); 
    } 
    
    setUsername("");
    setUserId(0);
    setIsActive(true);
    setIsisEmailV(false);
    setMobile("");
    setFirstName("");
    setLastName("");
    setPosition("");
    setEditRole(USER_ROLE_ADMIN_ADMIN);
    setModalHeading(`${t("adminUserList.modalCreateAccountAdminUserHeading")}`);
    setFirstNameError(false);
    setLastNameError(false);
    setUsernameError(false);
    setPopup(true);
  };

  const onClickTableData = (actionType: string, data: {}) => {
    let arrMultiAccIds = []; 
    arrMultiAccIds = data.userHotels.map((item) => item.id); 
    setMultiAccount(arrMultiAccIds);
    setUpdateMultiAccount(arrMultiAccIds.join()); 

    setUsername(data.email);
    setUserId(data.id);
    setIsActive(data.isActive);
    setIsisEmailV(data.emailVerified);
    setMobile(data.mobile);
    setFirstName(data.firstName);
    setLastName(data.lastName);
    setPosition(data.position);
    setEditRole(Number(data.role));
    setModalHeading(`${t("adminUserList.modalEditAccountAdminUserHeading")}`);
    setFirstNameError(false);
    setLastNameError(false);
    setUsernameError(false);
    setPopup(true);
  };

  return (
    <div>
      <div className={bgShade ? "rnc_bg" : "hide"}></div>
      <div className="box-padding">
        <div className="">
          <FilterSection
            headind={t("projectName")+" "+t("navigation.adminUsers")}
            filterAccount={true}
            filterRole={true}
            filterStatus={true}
            setSelectedAccountIds={setSelectedHotels}
            setStatusFilter={setStatusFilter}
            parentPage={PAGENAME_ADMINUSERS}
            onSetRole={setRole}
            clearFilters={clearFilters}
            searchInput={true}
            setTextSearchFilter={setSearch}
            headerButtonFunction={createUser}
            buttonLabel={`${t("adminUserList.addUser")}`}
            addButton={
              checkActionPermission(adminUserRole, PERM_ADD_ADMIN_USER)
                ? true
                : false
            }
            resetStatusFilterToAll={resetStatusFilterToAll} 

            // 'accept...' VARIABLES USED TO SET/RESET FILTERS ON PAGE REFRESH 
            acceptAccountFilterIds={acceptAccountFilterIds} 
            acceptStatusFilterData={acceptStatusFilterData} 
            acceptRoleFilterData={acceptRoleFilterData}  
            acceptSearchText={acceptSearchText}
          />

          <div className="table-box">
            <Table
              titles={AdminUsersTableHeader()}
              data={adminUsersList}
              sortedFieldName={sortField}
              handleSort={handleSort}
              onResendEmail={onResendEmail}
              onClickTableOption={onClickTableOption}
              onClickTableData={onClickTableData}
              parentPage={PAGENAME_ADMINUSERS} 
            />

            {totalSize > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPageCount={
                  totalSize
                    ? totalSize < numRowsPerPage
                      ? 1
                      : totalSize % numRowsPerPage > 0
                      ? parseInt((totalSize / numRowsPerPage).toString(), 10) +
                        1
                      : parseInt((totalSize / numRowsPerPage).toString(), 10)
                    : 0
                }
                totalRows={totalSize}
                isTableLoading={false}
                setCurrentPage={acceptPage}
                setNumRowsPerPage={setNumRowsPerPage}
                recordsTypeName={t("common.users")}
              />
            )}

            <Modal
              open={popup}
              onClose={() => setPopup(false)}
              aria-labelledby={"Edit Account Admin User"}
              aria-describedby=""
            >
              <Box sx={style}>
                <div className="w-100 p-1">
                  <div className="space-between center border-bottom p-3">
                    <h1 className="fontsize-18 font-bold mb-0">
                      {" "}
                      {modalHeading}
                    </h1>
                    <span onClick={() => setPopup(false)} className="pointer">
                      <CloseRounded />
                    </span>
                  </div>

                  <div className="bg-light">
                    <div className="  pt-3 bg-white">
                      <div className="acc-form ">
                        <div className="px-3">
                          <div className="business w-100 p-0px ">
                            <div className="flex gap-4 mb-12px">
                              <div className="w-100 ">
                                <div className="label">
                                  {t("adminUserList.userNameModal")}
                                </div>
                                <Textfield
                                  type="text"
                                  maxLength={150}
                                  required
                                  className={usernameError ? "red-border " : ""}
                                  value={username}
                                  onChange={(e) =>
                                    onSetUserName(e.target.value)
                                  }
                                />
                                <sup
                                  className="flex-end w-100 mt-3 pointer-cursor table-link"
                                  onClick={(e) => onResetEmail(username)}
                                >
                                  {t("adminUserList.resetPasswordModal")}
                                </sup>
                              </div>
                              <div className="w-100 hidesl">
                                <div className="label">
                                  {t("adminUserList.mobileModal")}
                                </div>
                                <PhoneInput
                                  inputProps={{
                                    name: `mobile`,
                                    required: true,
                                    autoFocus: false,
                                  }}
                                  inputClass={"common-input"}
                                  onlyCountries={PHONEINPUT_COUNTRIES} 
                                  countryCodeEditable={PHONEINPUT_COUNTRYCODE_EDITABLE} 
                                  country={"us"}                                  
                                  prefix={'+'}
                                  // specialLabel={`${t("adminUserList.mobileModal")}`}
                                  value={mobile}
                                  onChange={(code) => setMobile(code)}
                                />
                              </div>
                            </div>
                            <div className="flex gap-4 mb-12px">
                              <div className="w-100">
                                <div className="label">
                                  {t("adminUserList.firstNameModal")}
                                </div>
                                <Textfield
                                  type="text"
                                  required
                                  maxLength={150}
                                  value={firstName}
                                  className={
                                    (firstName.trim().length==0 && firstNameError) ? " red-border " : ""
                                  }
                                  onChange={(e) => setFirstName(e.target.value)}
                                />
                              </div>
                              <div className="w-100">
                                <div className="label">
                                  {t("adminUserList.lastNameModal")}
                                </div>
                                <Textfield
                                  required
                                  maxLength={150}
                                  type="text"
                                  value={lastName}
                                  className={
                                    (lastName.trim().length==0 && lastNameError) ? " red-border w-100 " : "w-100"
                                  }
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                              </div>
                            </div>

                            <div className="flex gap-4 mb-12px ">
                              <div className="w-100">
                                <div className="label">
                                  {t("adminUserList.positionModal")}
                                </div>
                                <Textfield
                                  type="text"
                                  required
                                  maxLength={150}
                                  className="w-100"
                                  value={position}
                                  onChange={(e) => setPosition(e.target.value)}
                                />
                              </div>
                              <div className="w-100">
                                <div className="label">
                                  {t("adminUserList.roleModal")}
                                </div>
                                <SelectField
                                  type="inputType"
                                  name="editRole"
                                  id="editRole"
                                  value={editRole}
                                  onChange={(e) =>
                                    setEditRole(Number(e.target.value))
                                  }
                                  defaultValue={USER_ROLE_ADMIN_ADMIN}
                                  required
                                  options={
                                    <>
                                      <option value={USER_ROLE_ADMIN_ADMIN}>
                                        {t("userRoles.adminAdmin")}{" "}
                                      </option>
                                      <option
                                        value={USER_ROLE_ADMIN_SUPERADMIN}
                                      >
                                        {t("userRoles.adminSuper")}
                                      </option>
                                    </>
                                  }
                                />
                              </div>
                            </div>

                            <div className="flex gap-4 mb-3">
                              <div className="w-100 max-width-49perc">
                                {editRole == USER_ROLE_ADMIN_ADMIN && (
                                  <>
                                    <div className="label">
                                      {t("adminUserList.accountListModal")}
                                    </div>
                                    <FilterAccount
                                      setUserAccounts={true}
                                      multiValue={true}
                                      setMultiValue={multiAccount}
                                      setAccountIds={setUpdateMultiAccount}
                                    />
                                  </>
                                )}
                              </div>

                              {isEmailV && checkActionPermission(adminUserRole, PERM_EDIT_ADMIN_USER) && showDeleteActivateOption(Number(adminUserId), Number(adminUserRole), Number(userId),Number(editRole)) && (
                                  <div className="w-100">
                                    <div>&nbsp;</div>

                                    <div className="label align-items-center flex-start gap-2  mt-5px">
                                      <input
                                        type="checkbox"
                                        checked={isActive}
                                        onChange={(e) =>
                                          setIsActive(e.target.checked)
                                        }
                                        className="m-0 max-width-18px"
                                        style={{width:"22px"}}
                                      />{" "}
                                      {t("adminUserList.active")}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="center border-bottom"></div>

                        <div className="px-3 py-3">
                          <div className="flex w-100 gap-4">
                            <ButtonModal
                              type="cancel"
                              onClick={() => setPopup(false)}
                              buttonLabel={t("common.cancel")}
                            />
                            <ButtonModal
                              onClick={onUsersSaveSubmitted}
                              buttonLabel={t("common.save")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminUsers;
