import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { ROUTE_TIP_RECEIPT, ROUTE_TIP_SUCCESS } from "../../config/env";
import { getReceiptData } from "../../services/tipService";
import dayjs from "dayjs";

interface CustomizedState {
  design_type: number;
  amount: string;
  intent_id: string;
  hotel_logo: string;
  hotelBg_image: string;
  hotel_data: string;
  tipValue: string;
  accCode: string;
  psCode: string;
  psType: string;
  theme_color: string;
  theme_text_color: string;
  message_title: string;
  message_description: string;
  review_section: any;
}

const ThankYou = (props: any) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as CustomizedState;
  const {
    intent_id,
    amount,
    hotel_logo,
    hotelBg_image,
    hotel_data,
    tipValue,
    accCode,
    psCode,
    psType,
    theme_color,
    design_type,
    theme_text_color,
    message_description,
    message_title,
    review_section,
  } = state;


  const [card, setCard] = useState("")
  const [expiry, setExpiry] = useState("")
  const [lastATMPin, setLastATMPin] = useState("")
  const [date, setDate] = useState("")
  const [time, setTime] = useState("")
  const [room, setRoom] = useState("")
  const [cardImage, setCardImage] = useState("")

  useEffect(() => {
    if (intent_id != undefined || intent_id != null) {
      getReceiptData(intent_id).then((res: any) => {
        if (res["status"] === 200) {

          setCard(res.data.card);
          setExpiry(res.data.expiry);
          setLastATMPin(res.data.last4);
          setDate(dayjs(res.data.dateTime).format('dddd, MMMM D, YYYY'));
          setTime(dayjs(res.data.dateTime).format('hh:mm A'));
          setRoom(res.data.room);
          setCardImage(res.data.logo);
        }
      });
    }

  }, [])


  return (
    <>
      <div className="max-width-400 div-center-margin-auto ">
        <div className="bg-white">
          <div id="main">
            <div>
              <div
                className="header-tip"
                style={{
                  backgroundColor: design_type == 1 ? theme_text_color : "white",
                  boxShadow: design_type == 3 ? "0px 10px 13px -13px rgba(0,0,0,0.12)" : 'none'
                }}
              >
                {design_type === 2 && (
                  <div
                    className="header-box-tip3"
                    style={{
                      backgroundImage: `url(${hotelBg_image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="circle-bottom"></div>
                      <div className={`circle-border ${(hotelBg_image!==null && hotelBg_image.length >0)&&"mt-100px"}`}>
                      <div className="circle-body">


                        {hotel_logo === "null" || hotel_logo.length == 0 ? (
                          <div className="hotel_name mt-3px">
                            {hotel_data["name"]}
                          </div>
                        ) : (
                          <img
                            src={hotel_logo}
                            alt="tipLogo"
                            className="circle-image"
                          />
                        )}

                      </div>
                    </div>
                  </div>
                )}

                {design_type !== 2 && (
                  <div className={`${design_type == 3 && "mt-4 "}`} >
                    {hotel_logo === "null" || hotel_logo.length == 0 ? (
                      <div className={`hotel_name mt-3px ${design_type == 3 && "mb-3 mt-2"}`} style={{ color: (design_type === 1 && theme_text_color !== "white" && theme_text_color !== "#FFFFFF" && theme_text_color !== "#ffffff") ? 'white' : 'black' }}>
                        {hotel_data["name"]}
                      </div>
                      // {hotel_logo === "null" || hotel_logo.length == 0 ? (
                      //   <div className="hotel_name mt-3px">
                      //     {hotel_data["name"]}
                      //   </div>
                    ) : (
                      <img
                        className="tipLogo"
                        src={hotel_logo}
                        alt={hotel_data["name"]}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* style={{height:"94px",width:"94px"}} */}
          <div className={`center ${design_type == 2 ? "mb-4" : "my-5"}`}>
            <div className="relative center" style={{ height: "95px", width: "95px" }}>
              <div
                className="tipSuccessCircle"
                style={{
                  backgroundColor: theme_color,
                  color: design_type === 3 ? theme_text_color : "white",
                }}
              >
                <DoneRoundedIcon sx={{ fontSize: "62px", color: design_type === 3 ? theme_text_color : "white" }} />
              </div>
              <div className="tipSuccessParentCircle" style={{ backgroundColor: theme_color, border: `2px solid ${theme_color}` }}>
              </div>


            </div>
          </div>

          <div><h1 className="tipThank-heading center" style={{
            color:
              design_type === 1
                ? theme_color
                : design_type === 3
                  ? theme_color
                  : "black",
          }}>{t("thankyou.thankyou")}</h1></div>
          <div><p className={`tipThank-para center ${design_type == 3 && 'mb-30px'}`}>{t("thankyou.paymentSuccessful")}</p></div>
          <hr style={{ margin: "21px 24px 29px 24px", color: design_type !== 2 ? "rgb(204 199 199)" : "white" }} />

          <div className="content_container pt-4 tipbg">
            <div
              className="tip-factor-header "
              style={{
                marginTop: design_type === 2 ? "-15px" : "-15px",
                marginBottom: design_type == 2 ? "10px" : "10px",
              }}
            >
              <p
                className="tipThank-para font-bold"
              // style={{
              //   color:
              //     design_type === 1
              //       ? theme_color
              //       : design_type === 3
              //       ? theme_color
              //       : "black",
              // }}
              >
                {t("thankyou.amount")}
              </p>
              <span
                className="tip-value"
                style={{
                  color:
                    design_type === 1
                      ? theme_color
                      : design_type === 3
                        ? theme_color
                        : "black",
                }}
              >
                {t("currency")}
                {amount}
              </span>
              <p
                className="tipThank-para"
              // style={{
              //   color:
              //     design_type === 1
              //       ? theme_color
              //       : design_type === 3
              //       ? theme_color
              //       : "black",
              // }}
              >
                {t("thankyou.charged")}{t("currency")}{tipValue}
              </p>
            </div>


           {(card!==null && card.length > 0 && card !==undefined) && <button
              onClick={() => {
                navigate(ROUTE_TIP_RECEIPT, {
                  state: {
                    intent_id: intent_id,
                    design_type: design_type,
                    hotel_logo: hotel_logo,
                    theme_color: theme_color,
                    theme_text_color: theme_text_color,
                    message_title: message_title,
                    message_description: message_description,
                    review_section: review_section,

                    amount: amount,
                    hotel_data: hotel_data,
                    tipValue: tipValue,
                    accCode: accCode,
                    psCode: psCode,
                    psType: psType,
                    card: card,
                    expiry: expiry,
                    lastATMPin: lastATMPin,
                    date: date,
                    time: time,
                    room: room,
                    cardImage: cardImage,
                  },
                });

              }}
              className="btntipReceipt mt-4"
              style={{
                border: `1px solid ${theme_color}`,
                color: theme_color,
              }}
            >
              {t("thankyou.receipt")}
            </button>}

            {(review_section.length > 0) && <button
              onClick={() => {
                navigate(ROUTE_TIP_SUCCESS, {
                  state: {
                    design_type: design_type,
                    hotel_logo: hotel_logo,
                    theme_color: theme_color,
                    theme_text_color: theme_text_color,
                    message_title: message_title,
                    message_description: message_description,
                    review_section: review_section,

                    amount: amount,
                    hotel_data: hotel_data,
                    tipValue: tipValue,
                    accCode: accCode,
                    psCode: psCode,
                    psType: psType,
                  },
                });

              }}
              className="btntipContinue mt-4"
              style={{
                backgroundColor: theme_color,
                color: design_type === 3 ? theme_text_color : "white",
                fontStyle:'italic'
              }}
            >
              {t("tipPage.continue")}
            </button>}

            <div className="my-4">
              <p className="fontz">{t("tipPage.poweredBy")} {t("tipPage.useTova")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
