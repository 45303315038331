import React from "react";

export const SelectField = (props) => {
  let content;

  switch (props.type) {
    case "inputType":
      content = (
        <select
          name={props.name}
          id={props.id}
          className={"common-select select-input " + props.className}
          value={props.value}
          onClick={props.onClick}
          onChange={props.onChange}
          disabled={props.disabled}
          required={props.required}
        >
          {props.options}
        </select>
      );
      break;
  
    default:
      content = (
        <select
          name={props.name}
          id={props.id}
          className={"common-select " + props.className}
          value={props.value}
          onClick={props.onClick} 
          onChange={props.onChange}
          disabled={props.disabled}
          required={props.required}
        >
          {props.options}
        </select>
      );
      break;
  }

  return <>{content}</>;
};
