import { get } from './apiService';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SA_API_URL_PAYEE_LIST } from '../config/api_urls';
import { ISearchStaffOnly } from '../models/groupInterfaces';

// FUNCTION TO FETCH PAYEE BY NAME USING STAFF ONLY
export const fetchPayeeByNameStaffOnly = createAsyncThunk(
  'tips/fetchPayeeByNameStaffOnly',
  async (dataObj: ISearchStaffOnly, thunkAPI: any) => {
    try {
      let url = SA_API_URL_PAYEE_LIST+'?name=' + dataObj.name; 
      if (dataObj.staff_only != '') { url = url + '&staff_only=' + dataObj.staff_only; }
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

