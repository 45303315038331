import { createAsyncThunk } from '@reduxjs/toolkit';
import { ACC_API_URL_ACCOUNT_BUSINESS_TYPES, ACC_API_URL_ACCOUNT_SIGN_UP, ACC_API_URL_ACCOUNT_SIGN_UP_ACCEPT_TERMS, ACC_API_URL_ACCOUNT_SIGN_UP_ACH_UPDATE, ACC_API_URL_ACCOUNT_SIGN_UP_AGREEMENT, ACC_API_URL_ACCOUNT_SIGN_UP_NEW_ACCOUNT, ACC_API_URL_ACCOUNT_SIGN_UP_RESEND_EMAIL, ACC_API_URL_ACCOUNT_SIGN_UP_VERIFY_LINK, ACC_API_URL_ACCOUNT_T_AND_C } from '../config/api_urls';
import { get, getWithoutAuth, postWithoutAuth } from './apiService';
import { IAcceptTerms, IACHData, ICreateNewAccount, INewSignUpRequestData, INewSignUpResendEmail, INewSignUpVerifyEmailLink } from '../models/signUpInterfaces'; 

// FUNCTION FOR NEW ACCOUNT SIGN UP
export const newAccountSignUp = createAsyncThunk(
  'newAccountSignUp',
  async ({ firstName, lastName, birthDate, username, password }: INewSignUpRequestData, thunkAPI: any) => {
    try {
      const auth: INewSignUpRequestData = { firstName, lastName, birthDate, username, password };
      const response = postWithoutAuth(ACC_API_URL_ACCOUNT_SIGN_UP, auth);
      return await (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION FOR NEW ACCOUNT SIGN UP , RESEND EMAIL
export const newAccountSignUpResendEmail = createAsyncThunk(
  'newAccountSignUpResendEmail',
  async ({ oldEmail, newEmail }: INewSignUpResendEmail, thunkAPI: any) => {
    try {
      const auth: INewSignUpResendEmail = { oldEmail, newEmail };
      const response = postWithoutAuth(ACC_API_URL_ACCOUNT_SIGN_UP_RESEND_EMAIL, auth);
      return await (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION FOR NEW ACCOUNT SIGN UP, VERIFY EMAIL
export const newAccountVerifyEmailLink = createAsyncThunk(
  'newAccountSignUpResendEmail',
  async ({ f_token, user_id, token, expiry_time }: INewSignUpVerifyEmailLink, thunkAPI: any) => {
    try {
      const auth: INewSignUpVerifyEmailLink = { f_token, user_id, token, expiry_time };
      const response = postWithoutAuth(ACC_API_URL_ACCOUNT_SIGN_UP_VERIFY_LINK, auth);
      return await (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION FOR NEW ACCOUNT SIGN UP,FETCH BUSINESS TYPES
export const fetchBusinessTypes = createAsyncThunk(
  'fetchBusinessTypes',
  async (hotelId: string, thunkAPI: any) => {
    try {
      const response = getWithoutAuth(ACC_API_URL_ACCOUNT_BUSINESS_TYPES);
      return await (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION FOR NEW ACCOUNT SIGN UP , GET TERMS AND CONDITIONS
export const getTandC = createAsyncThunk(
  'getTandC',
  async (id: number, thunkAPI: any) => {
    try {
      const url = ACC_API_URL_ACCOUNT_T_AND_C + '?group=' + id;
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO CREATE NEW ACCOUNT 
export const newAccountSignUpCreate = createAsyncThunk(
  'newAccountSignUpCreate',
  async ({ f_token, user_id, token, expiry_time, business_type_id, business_classification, hotel_name, company_name, number_of_rooms, number_of_employees, mobile_number, address, city, state, zip, first_name, last_name, communication_email , job_title }: ICreateNewAccount, thunkAPI: any) => {
    try {
      const auth: ICreateNewAccount = { f_token, user_id, token, expiry_time, business_type_id, business_classification, hotel_name, company_name, number_of_rooms, number_of_employees, mobile_number, address, city, state, zip, first_name, last_name, communication_email , job_title };
      const response = postWithoutAuth(ACC_API_URL_ACCOUNT_SIGN_UP_NEW_ACCOUNT, auth);
      return await (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO FETCH TERMS AND CONDITIONS WITHOUT AUTHENTICATION
export const fetchTerms = createAsyncThunk( 
  'fetchTerms',
  async (groupId:string,thunkAPI: any) => {
    try {
      const response = getWithoutAuth(ACC_API_URL_ACCOUNT_SIGN_UP_AGREEMENT+'?group='+groupId);
      return await (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
); 

// FUNCTION TO ACCEPT T&C
export const acceptTerms = createAsyncThunk( 
  'acceptTerms',
  async ({username,terms_id, secret_id}: IAcceptTerms,thunkAPI: any) => {
    try {
      const auth: IAcceptTerms = { username, terms_id, secret_id };
      const response = postWithoutAuth(ACC_API_URL_ACCOUNT_SIGN_UP_ACCEPT_TERMS, auth);
      return await (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
); 

// FUNCTION TO UPDATE ACH
export const achUpdate = createAsyncThunk( 
  'achUpdate',
  async ({country, currency, account_holder_name, account_number, routing_number, bank_name, account_type, hotel_id, secret_id}: IACHData,thunkAPI: any) => {
    try {
      const auth: IACHData = { country, currency, account_holder_name, account_number, routing_number, bank_name, account_type,hotel_id, secret_id };
      const response = postWithoutAuth(ACC_API_URL_ACCOUNT_SIGN_UP_ACH_UPDATE, auth);
      return await (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
); 

  


