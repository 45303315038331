import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Store } from "react-notifications-component";
import { Box, Modal } from "@mui/material";
import warning from "../../assets/images/warning.svg";
import yellowrn from "../../assets/images/yellowrm.svg";
import { setNotification } from '../../services/apiService';
import PhoneInput from "react-phone-input-2";
import { CloseRounded } from "@mui/icons-material";

import Table from "../../components/Table";
import Paginations from "../../components/Pagination";
import { AccountUsersListTableHeader } from "../../components/Table/TableHeaders";

import { showDeleteActivateOption } from "../../config/common_functions";
import { PERM_ADD_ACCOUNT_USER, PERM_EDIT_ACCOUNT_USER, checkActionPermission } from "../../config/permissions";
import { DATEFILTER_ALLDATES, PAGENAME_ACCOUNTUSERS, PAGINATION_NUM_ROWS, PHONEINPUT_COUNTRIES, PHONEINPUT_COUNTRYCODE_EDITABLE, USER_ROLE_ACCOUNT_MANAGER, USER_ROLE_ACCOUNT_USER, } from "../../config/env";

import { useAppDispatch, useAppSelector } from "../../helpers/hook";
import {
  IAccountUserDetails,
  IUserDeleteReq,
  IUserPwdResetReq,
  IUsersRequestData,
} from "../../models/usersListInterfaces";

import { getAdminUserRole, getAdminUserId, getFilterAccountId, setLastActivePageAPIData } from "../../services/localStorage";
import {
  createAccountUsers,
  deleteUser,
  exportUsersList,
  fetchUsersList,
  resetUserListPassword,
  statusUpdateAccountUsers,
  updateAccountUsers,
} from "../../services/usersListServices";

import { selectUsersListDataSlice, setCurrentPage } from "./accountUsersListSlice";

import { Textfield } from "../../components/TextField";
import FilterAccount from "../../components/FilterAccount";
import FilterSection from "../../components/FilterSection";
import { SelectField } from "../../components/SelectField";
import { ButtonModal } from "../../components/ButtonGroups/ButtonModal";
import { ButtonInLine } from "../../components/ButtonGroups/ButtonInLine";

function AccountUsersList() {
  const prevAPIDataString = localStorage.getItem('lastActivePageAPIData');
  const prevAPIData = prevAPIDataString ? JSON.parse(prevAPIDataString) : {};
  const pageName = PAGENAME_ACCOUNTUSERS;
  const { t } = useTranslation();
  const adminUserRole = getAdminUserRole();
  const adminUserId = getAdminUserId();

  const dispatch = useAppDispatch();
  const localStorageAccId = getFilterAccountId();

  const [bgShade, setBgShade] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState(0);
  const [userRole, setUserRole] = useState(0);

  const [isActive, setIsActive] = useState<boolean>(false);
  const [isEmailV, setIsisEmailV] = useState<boolean>(false);
  const [mobile, setMobile] = useState("");
  const [position, setPosition] = useState("");
  const [editRole, setEditRole] = useState<number>();
  const [primaryAccount, setPrimaryAccount] = useState([]);


  const [updateMultiAccount, setUpdateMultiAccount] = useState("");
  const [multiAccount, setMultiAccount] = useState([]);

  const [popup, setPopup] = useState(false);
  const [modalHeading, setModalHeading] = useState(
    `${t("accountUsersList.modalEditAccountUserHeading")}`
  );
  const [usernameError, setUsernameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);

  const [selectedHotels, setSelectedHotels] = useState("");
  const [sortField, setSortField] = useState("staff_name");
  const [role, setRole] = useState(0);
  const [status, setStatus] = useState("");
  const [searchText, setSearchText] = useState("");
  const [dateFilterType, setDateFilterType] = useState("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const [initialAPICalled, setInitialAPICalled] = useState(false);

  // 'accept...' VARIABLES USED TO SET/RESET FILTERS ON REFRESH   
  const [acceptAccountFilterIds, setAcceptAccountFilterIds] = useState("");
  const [acceptDateFilterData, setAcceptDateFilterData] = useState("");
  const [acceptStatusFilterData, setAcceptStatusFilterData] = useState("");
  const [acceptRoleFilterData, setAcceptRoleFilterData] = useState(0);
  const [acceptSearchText, setAcceptSearchText] = useState("");

  const { usersList, totalSize, currentPage, loading } = useAppSelector(
    selectUsersListDataSlice
  );
  const [numRowsPerPage, setNumRowsPerPage] = useState(PAGINATION_NUM_ROWS);

  const style = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 805,
    maxHeight: 785,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "13px",
    boxShadow: 24,
  };

  const handleSort = (sortField: string) => {
    console.log(sortField);
    setSortField(
      sortField === "hotel_code"
        ? "hotel_code_id"
        : sortField === "-hotel_code"
          ? "-hotel_code_id"
          : sortField
    );
  };

  const fetchall = (): void => {
    if (!initialAPICalled && prevAPIData.pageName && prevAPIData.pageName == pageName && prevAPIData?.apiData?.hotel && prevAPIData.apiData.hotel.length > 0) {
      dispatch(fetchUsersList(prevAPIData.apiData));

      dispatch(setCurrentPage(prevAPIData.apiData.page));

      setSelectedHotels(prevAPIData.apiData.hotel);
      setSortField(prevAPIData.apiData.sort);
      setStatus(prevAPIData.apiData.active);
      setRole(prevAPIData.apiData.role);
      setSearchText(prevAPIData.apiData.search);
      setDateFilterType(prevAPIData.apiData.dateFilterType);
      setStartDate(prevAPIData.apiData.joining_from_date);
      setEndDate(prevAPIData.apiData.joining_to_date)
      setNumRowsPerPage(prevAPIData.apiData.numRowsPerPage);

      if (prevAPIData.apiData.hotel && prevAPIData.apiData.hotel.trim().length > 0) {
        setAcceptAccountFilterIds(prevAPIData.apiData.hotel);
      }
      if (prevAPIData.apiData.active && prevAPIData.apiData.active.trim().length > 0) {
        setAcceptStatusFilterData(prevAPIData.apiData.active);
      }
      if (prevAPIData.apiData.dateFilterType && prevAPIData.apiData.dateFilterType.trim().length > 0) {
        setAcceptDateFilterData(prevAPIData.apiData.dateFilterType);
      }
      if (prevAPIData.apiData.role >= 0) {
        setAcceptRoleFilterData(prevAPIData.apiData.role);
      }
      if (prevAPIData.apiData.search && prevAPIData.apiData.search.trim().length > 0) {
        setAcceptSearchText(prevAPIData.apiData.search);
      }

      setTimeout(() => {
        setInitialAPICalled(true);
      }, 1250);

    }
    else {
      const req: IUsersRequestData = {
        page: currentPage,
        numRowsPerPage: numRowsPerPage,
        hotel: selectedHotels
          ? selectedHotels
          : localStorageAccId
            ? localStorageAccId
            : "",
        role: role,
        document_verified: "",
        search: searchText.trim(),
        active: status,
        dateFilterType: dateFilterType,
        joining_from_date: startDate,
        joining_to_date: endDate,
        sort: sortField,
        userRole: Number(adminUserRole),
      };
      setAcceptDateFilterData(DATEFILTER_ALLDATES);
      dispatch(fetchUsersList(req));
      setLastActivePageAPIData({ pageName: pageName, apiData: req });
      // TO PREVENT THE OTHER USEEFFECTS ON INITIAL LOADING OF THIS PAGE 
      setTimeout(() => {
        setInitialAPICalled(true);
      }, 1000);
    }
  };

  React.useEffect(() => {
    if (!initialAPICalled) {
      fetchall();
    }
  }, []);

  React.useEffect(() => {
    if (initialAPICalled) {
      fetchall();
    }
  }, [currentPage, sortField]);

  React.useEffect(() => {
    if (initialAPICalled) {
      if (currentPage !== 1) {
        acceptPage(1);
      }
      else {
        fetchall();
      }
    }
  }, [
    role,
    startDate,
    endDate,
    status,
    searchText,
    numRowsPerPage,
    selectedHotels,
  ]);

  React.useEffect(() => {
    if (
      loading == "deleted" || loading == "created" || loading == "updated" ||
      loading == "statusUpdated" || loading == "resetUserPasswordSuccess"
    ) {
      setPopup(false);
      fetchall();
    }
  }, [loading]);

  React.useEffect(() => {
    if (currentPage !== 1) {
      acceptPage(1);
    }
  }, [selectedHotels]);

  const acceptPage = (e: any): void => {
    dispatch(setCurrentPage(e));
  };

  const onResendEmail = (username: string) => {
    if (username) {
      let userDet: IUserPwdResetReq = {
        username: encodeURIComponent(username),
        userRole: Number(adminUserRole)
      }
      dispatch(resetUserListPassword(userDet));
    }
  };

  const setDateFilterDatesType = (fromDate: string, toDate: string, filterType: string) => {
    setStartDate(fromDate);
    setEndDate(toDate);
    setDateFilterType(filterType);
  };

  const setStatusFilter = (status: string) => {
    setStatus(status);
  };

  const clearFilters = () => {
    setSortField("full_name");
    setStatus("");
    setRole(0);
    setStartDate("");
    setEndDate("");
    setDateFilterType("");
    setSearchText("");
    setNumRowsPerPage(PAGINATION_NUM_ROWS);
    if (currentPage !== 1) {
      acceptPage(1);
    }
    else {
      fetchall();
    }
  };

  const onExportButton = () => {
    const req: IUsersRequestData = {
      page: currentPage,
      numRowsPerPage: numRowsPerPage,
      hotel: selectedHotels ? selectedHotels : localStorageAccId ? localStorageAccId : "",
      role: role,
      document_verified: "",
      search: searchText.trim(),
      active: status,
      joining_from_date: startDate,
      joining_to_date: endDate,
      sort: sortField,
      userRole: Number(adminUserRole),
    };
    exportUsersList(req);
  };

  const onClickTableOption = (
    actionType: string,
    staffId: string,
    accountId: string,
    staffName: string,
    accountName: string,
    isActive: string
  ) => {
    setBgShade(true);
    Store.addNotification({
      title: t("accountUsersList.modalDeleteHeader"),
      id: "maid-modal",
      content: (
        <div className="idproof-modal div-center text-center w-60perc relative">
          <div className="page-heading">
            <img src={warning} alt="" />
          </div>
          <div className="idproof-flex-center div-center">
            <div>
              {t("accountUsersList.modalDeleteConfirm", {
                staff: staffName,
                projectname: t("projectName"),
              })}
            </div>
          </div>
          <div className="idproof-margin"></div>

          <div className="flex w-100 gap-4">
            <ButtonModal
              type="cancel"
              onClick={() => {
                Store.removeNotification("maid-modal");
                setBgShade(false);
              }}
              buttonLabel={t("common.cancel")}
            />
            <ButtonModal
              type="delete"
              onClick={() => {
                setBgShade(false);
                Store.removeNotification("maid-modal");
                let reqData: IUserDeleteReq = {
                  userId: staffId.toString(),
                  userRole: Number(adminUserRole),
                }
                dispatch(deleteUser(reqData));
              }}
              buttonLabel={t("common.delete")}
            />
          </div>
        </div>
      ),
      type: "default",
      container: "center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 1000000000,
        click: false,
        touch: false,
      },
    });
  };

  const onSetUserName = (value) => {
    setUsername(value);
    if (value && value.length > 0) {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (value.match(validRegex)) {
        setUsernameError(false);
      } else {
        setUsernameError(true);
      }
    } else {
      setUsernameError(false);
    }
  };

  const onResetEmail = (username: string) => {
    if (username && username.length > 0) {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (username.match(validRegex)) {
        setBgShade(true);
        Store.addNotification({
          title: "Reset Password",
          id: "resetpwd-modal",
          content: (
            <div className="idproof-modal div-center text-center w-60perc relative">
              <div className="page-heading">
                <img src={yellowrn} alt="" />
              </div>
              <div className="resetpwd-flex-center">
                {t("messages.resetPasswordConfirm", { userName: username })}
              </div>
              <div className="resetpwd-margin">&nbsp;</div>
              <div className="flex w-100 gap-4">
                <ButtonModal
                  type="cancel"
                  onClick={() => {
                    Store.removeNotification("resetpwd-modal");
                    setBgShade(false);
                  }}
                  buttonLabel={t("common.cancel")}
                />
                <ButtonModal
                  onClick={() => {
                    Store.removeNotification("resetpwd-modal");
                    setBgShade(false);
                    onResendEmail(username);
                  }}
                  buttonLabel={t("common.resetPassword")}
                />
              </div>
            </div>
          ),
          type: "default",
          container: "center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 1000000000,
            click: false,
            touch: false,
          },
        });
      }
    }
  };

  const handleUserStatus = (actionStr: string) => {
    if (actionStr == "deactivate") {
      Store.addNotification({
        title: t("accountUsersList.modalDeleteHeader"),
        id: "deactivate-modal",
        content: (
          <div className="idproof-modal div-center text-center w-60perc relative">
            <div className="page-heading">
              <img src={warning} alt="" />
            </div>
            <div className="idproof-flex-center flex-col div-center">
              <h6>{t("messages.deactivateConfirm")}</h6>
              <div>{t("messages.deactivateConfirmPara")}</div>
            </div>
            <div className="idproof-margin"></div>

            <div className="flex w-100 gap-4">
              <ButtonModal
                type="cancel"
                onClick={() => {
                  Store.removeNotification("deactivate-modal");
                }}
                buttonLabel={t("common.no")}
              />
              <ButtonModal
                onClick={() => {
                  dispatch(statusUpdateAccountUsers(Number(userId)));
                  Store.removeNotification("deactivate-modal");
                  setPopup(false);
                }}
                buttonLabel={t("common.yes")}
              />
            </div>
          </div>
        ),
        type: "default",
        container: "center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 1000000000,
          click: false,
          touch: false,
        },
      });
    } else if (actionStr == "activate") {
      dispatch(statusUpdateAccountUsers(Number(userId)));
      setPopup(false);
    }
  };

  const createUser = (e) => {
    let locAccId = getFilterAccountId();
    if (locAccId) {
      setMultiAccount([Number(locAccId)]);
      setUpdateMultiAccount(locAccId.toString());
    }
    else {
      setMultiAccount([]);
      setUpdateMultiAccount("");
    }

    setModalHeading(`${t("accountUsersList.modalCreateAccountUserHeading")}`);

    setUsername("");
    setUserId(0);
    setUserRole(0);
    setIsActive(true);
    setIsisEmailV(false);
    setMobile("");
    setFirstName("");
    setLastName("");
    setPosition("");
    setPrimaryAccount([]);
    setEditRole(USER_ROLE_ACCOUNT_MANAGER);
    setFirstNameError(false);
    setLastNameError(false);
    setUsernameError(false);
    // setAccounts("account");
    setPopup(true);
  };

  const onUsersSaveSubmitted = () => {
    if (
      firstName &&
      firstName.trim().length > 0 &&
      lastName &&
      lastName.trim().length > 0 &&
      username &&
      username.trim().length > 0
    ) {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (username.match(validRegex)) {
        if (userId == 0) {
          if (updateMultiAccount.trim().length == 0) {
            setNotification(
              t("projectName"),
              t("accountUsersList.accountSelectError"),
              "failed",
              "createAccountUsers"
            );
          }
          else {
            var userData: IAccountUserDetails = {
              firstName: firstName,
              lastName: lastName,
              mobile: (!mobile || mobile == "") ? "" : (mobile.trim() == "1" || mobile.trim() == "+1") ? "" :
                mobile && mobile.trim().length > 0
                  ? mobile.search("\\+") < 0
                    ? "+" + mobile
                    : mobile
                  : "",
              username: username,
              position: position,
              isActive: isActive,
              role: Number(editRole),
              hotelList: updateMultiAccount,
              userRole: Number(adminUserRole)
            };
            dispatch(createAccountUsers(userData));
          }
        } else {
          if (updateMultiAccount.trim().length == 0) {
            setNotification(
              t("projectName"),
              t("accountUsersList.accountSelectError"),
              "failed",
              "createAccountUsers"
            );
          }
          else {
            var userData: IAccountUserDetails = {
              userId: userId,
              firstName: firstName,
              lastName: lastName,
              mobile: (!mobile || mobile == "") ? "" : (mobile.trim() == "1" || mobile.trim() == "+1") ? "" :
                mobile && mobile.trim().length > 0
                  ? mobile.search("\\+") < 0
                    ? "+" + mobile
                    : mobile
                  : "",
              username: username,
              position: position,
              isActive: isActive,
              role: Number(editRole),
              hotelList: updateMultiAccount,
              userRole: Number(adminUserRole)
            };
            dispatch(updateAccountUsers(userData));
          }
        }
      }
    } else {
      if (username.trim().length <= 0) {
        setUsernameError(true);
      }
      if (firstName.trim().length <= 0) {
        setFirstNameError(true);
      }
      if (lastName.trim().length <= 0) {
        setLastNameError(true);
      }
    }
  };

  const onClickTableData = (actionType: string, data: {}) => {
    let arrMultiAccIds = [];
    arrMultiAccIds = data.userHotels.map((item) => item.id);
    setMultiAccount(arrMultiAccIds);
    setUpdateMultiAccount(arrMultiAccIds.join());

    setUsername(data.email);
    setUserId(data.id);
    setUserRole(data.role);
    setIsActive(data.isActive);
    setIsisEmailV(data.emailVerified);
    setMobile(data.mobile);
    setFirstName(data.firstName);
    setLastName(data.lastName);
    setPosition(data.position);
    setPrimaryAccount(data.primaryHotels)
    setEditRole(Number(data.role));
    setFirstNameError(false);
    setLastNameError(false);
    setUsernameError(false);
    setPopup(true);
    setModalHeading(`${t("accountUsersList.modalEditAccountUserHeading")}`);
  };

  return (
    <div>
      <div className={bgShade ? "rnc_bg" : "hide"}></div>
      <div className="box-padding">
        <div className="">
          <FilterSection
            headind={`${t("navigation.accountUsers")}`}
            filterAccount={true}
            filterRole={true}
            filterStatus={true}
            filterDate={true}
            onSetRole={setRole}
            parentPage={PAGENAME_ACCOUNTUSERS}
            clearFilters={clearFilters}
            setStatusFilter={setStatusFilter}
            setDateFilterDatesType={setDateFilterDatesType}
            setSelectedAccountIds={setSelectedHotels}
            searchInput={true}
            setTextSearchFilter={setSearchText}
            headerButtonFunction={createUser}
            buttonLabel={`${t("accountUsersList.addAdmin")}`}
            addButton={checkActionPermission(adminUserRole, PERM_ADD_ACCOUNT_USER) ? true : false}
            needDateAllOption={true}

            // 'accept...' VARIABLES USED TO SET/RESET FILTERS ON PAGE REFRESH 
            acceptAccountFilterIds={acceptAccountFilterIds}
            acceptDateFilterData={acceptDateFilterData}
            acceptStatusFilterData={acceptStatusFilterData}
            acceptRoleFilterData={acceptRoleFilterData}
            acceptSearchText={acceptSearchText}
          />

          <div className="table-box">
            <Table
              titles={AccountUsersListTableHeader()}
              data={usersList}
              sortedFieldName={sortField}
              handleSort={handleSort}
              onResendEmail={onResendEmail}
              onClickTableOption={onClickTableOption}
              onClickTableData={onClickTableData}
              parentPage={PAGENAME_ACCOUNTUSERS}
            />

            {totalSize > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPageCount={
                  totalSize
                    ? totalSize < numRowsPerPage
                      ? 1
                      : totalSize % numRowsPerPage > 0
                        ? parseInt((totalSize / numRowsPerPage).toString(), 10) +
                        1
                        : parseInt((totalSize / numRowsPerPage).toString(), 10)
                    : 0
                }
                totalRows={totalSize}
                isTableLoading={false}
                setCurrentPage={acceptPage}
                setNumRowsPerPage={setNumRowsPerPage}
                recordsTypeName={t("common.users")}
                exportButton={totalSize > 0 ? true : false}
                onExportButton={onExportButton}
              />
            )}

            <Modal
              open={popup}
              onClose={() => setPopup(false)}
              aria-labelledby={"Edit Account Admin User"}
              aria-describedby=""
            >
              <Box sx={style}>
                <div className="w-100 p-1">
                  <div className="space-between center border-bottom p-3">
                    <h1 className="fontsize-18 font-bold mb-0">
                      {modalHeading}
                    </h1>
                    <span onClick={() => setPopup(false)} className="pointer">
                      <CloseRounded />
                    </span>
                  </div>

                  <div className="bg-light">
                    <div className="  pt-3 bg-white">
                      <div className="acc-form ">
                        <div className="px-3">
                          <div className="business w-100 p-0px ">
                            <div className="flex gap-4 mb-12px">
                              <div className="w-100 ">
                                <div className="label">
                                  {t("accountUsersList.userNameModal")}
                                </div>
                                <Textfield
                                  type="text"
                                  required
                                  className={usernameError ? "red-border " : ""}
                                  value={username}
                                  onChange={(e) =>
                                    onSetUserName(e.target.value)
                                  }
                                />
                                {userId && userId > 0 ? <sup
                                  className="flex-end w-100 mt-3 pointer-cursor table-link"
                                  onClick={(e) => onResetEmail(username)}
                                >
                                  {t("adminUserList.resetPasswordModal")}
                                </sup> : <></>}
                              </div>
                              <div className="w-100 hidesl">
                                <div className="label">
                                  {t("adminUserList.mobileModal")}
                                </div>
                                <PhoneInput
                                  inputProps={{
                                    name: `mobile`,
                                    required: true,
                                    autoFocus: false,
                                  }}
                                  inputClass={"common-input"}
                                  onlyCountries={PHONEINPUT_COUNTRIES}
                                  countryCodeEditable={PHONEINPUT_COUNTRYCODE_EDITABLE}
                                  country={"us"}
                                  prefix={'+'}
                                  // specialLabel={`${t("adminUserList.mobileModal")}`}
                                  value={mobile}
                                  onChange={(code) => setMobile(code)}
                                />
                              </div>
                            </div>
                            <div className="flex gap-4 mb-12px">
                              <div className="w-100">
                                <div className="label">
                                  {t("adminUserList.firstNameModal")}
                                </div>
                                <Textfield
                                  type="text"
                                  required
                                  className={
                                    (firstName.trim().length == 0 && firstNameError) ? "red-border " : ""
                                  }
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                />
                              </div>
                              <div className="w-100">
                                <div className="label">
                                  {t("adminUserList.lastNameModal")}
                                </div>
                                <Textfield
                                  required
                                  type="text"
                                  className={
                                    (lastName.trim().length == 0 && lastNameError) ? "red-border w-100" : "w-100"
                                  }
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                              </div>
                            </div>

                            <div className="flex gap-4  mb-12px">
                              <div className="w-100">
                                <div className="label">
                                  {t("adminUserList.positionModal")}
                                </div>
                                <Textfield
                                  required
                                  type="text"
                                  className="w-100"
                                  value={position}
                                  onChange={(e) => setPosition(e.target.value)}
                                />
                              </div>
                              <div className="w-100">
                                <div className="label">
                                  {t("adminUserList.roleModal")}
                                </div>

                                <SelectField
                                  type="inputType"
                                  name="editRole"
                                  id="editRole"
                                  value={editRole}
                                  onChange={(e) =>
                                    setEditRole(Number(e.target.value))
                                  }
                                  required
                                  disabled={primaryAccount.length > 0 ? true : false}
                                  options={<>
                                    <option value={USER_ROLE_ACCOUNT_MANAGER}>
                                      {t("userRoles.accountManager")}
                                    </option>
                                    <option value={USER_ROLE_ACCOUNT_USER}>
                                      {t("userRoles.accountUser")}
                                    </option></>}
                                />
                              </div>
                            </div>

                            <div className="flex gap-4 mb-3">
                              <div className="w-100 ">
                                <div className="label">
                                  {t("adminUserList.accountListModal")}
                                </div>
                                <FilterAccount
                                  setUserAccounts={true}
                                  parent='AccountUserList'
                                  primaryAccount={primaryAccount}
                                  multiValue={true}
                                  setMultiValue={multiAccount}
                                  setAccountIds={setUpdateMultiAccount}
                                />
                              </div>
                              <div className="w-100"></div>
                            </div>
                          </div>
                        </div>

                        <div className="center border-bottom"></div>

                        <div className="px-3 py-3">
                          <div className="space-between w-100 ">
                            {checkActionPermission(adminUserRole, PERM_EDIT_ACCOUNT_USER) && showDeleteActivateOption(Number(adminUserId), Number(adminUserRole), Number(userId), Number(userRole)) ? <>
                              {isEmailV && userId > 0 && isActive && (
                                <ButtonInLine
                                  className="deactive"
                                  onClick={(e) => handleUserStatus("deactivate")}
                                  buttonLabel={t("accountUsersList.deactivateButtonModal")}
                                />
                              )}
                              {isEmailV && userId > 0 && !isActive && (
                                <ButtonInLine
                                  className="acc-active"
                                  onClick={(e) => handleUserStatus("activate")}
                                  buttonLabel={t("accountUsersList.activateButtonModal")}
                                />
                              )}
                            </> : <div>&nbsp;</div>}

                            <div className={`flex gap-4 pt-6px  ${isEmailV && userId > 0 && (isActive || !isActive) ? "" : 'w-100'}`}>
                              <ButtonModal
                                type="cancel"
                                className="min-width-125px"
                                onClick={() => setPopup(false)}
                                buttonLabel={t("common.cancel")}
                              />
                              <ButtonModal
                                onClick={() => onUsersSaveSubmitted()}
                                buttonLabel={t("common.save")}
                                className="min-width-125px"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountUsersList;
