import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../store';
import { downloadQRTemplate, fetchAccountGroupsListQR, fetchAccountListQR, fetchAccountStaffListQR, publishNewDHTemplate } from '../../services/qrCodesServices';
import { fetchDHUserAccessToken, fetchDHAppAccessToken, fetchDHBaseTemplates, createNewDHProject, fetchDHAccountProjects, fetchDHAccountTemplates, fetchVPPricingOne, fetchVPPricingTwo, fetchVPPricingThree, fetchCartDetails, addCartItem, modifyCartItem, fetchCartCount, removeCartItem, fetchDocumentPreview, fetchDeliveryOptions, deleteDHProject, deleteDHPreMadeTemplate, createNewDHTemplateProject, applyTemplateToProjects } from "../../services/qrCodesServices"; 
import { IAccountDetails, IAccountGroupsList, IAccountListQR, IDeliveryOptions, IDHBaseTemplates, IDHTemplatesCardType, IPreMadeTemplateData, IShippingAddress, IStaff, IViewCartItemData, IVPDocumentPreviewRespItem, IVPItemPriceData } from '../../models/qrCodeInterfaces';
import { dh_setAppAccessToken, dh_setUserAccessToken } from '../../services/localStorage';

export interface FilterState {
    qrCodePageAccountId: string;
    accountList: IAccountListQR[];
    accountStaffList: IStaff[];
    poolStaffSelection: string; 
    selectedPoolStaffId: string; 
    selectedPoolStaffName: string;
    loading: 'idle' | 'loading' | 'succeeded' | 'error' | 'DHTokenError' | 'DHBaseTemplatesError' | 'createNewDHProjectError' | 'createNewDHTemplateProjectError' | 'fetchDHAccountProjectsError' | 'fetchDHAccountTemplatesError' | 'fetchVPPricingOneError' | 'fetchVPPricingTwoError' | 'fetchVPPricingThreeError' | 'fetchCartCountError' | 'fetchCartDetailsError' | 'addCartItemError' | 'modifyCartItemError' | 'removeCartItemError'; 
    accountGroupsList: IAccountGroupsList[];
    DHUserAccessToken: string; 
    DHAppAccessToken: string;
    DHBaseTemplates: IDHBaseTemplates[]; 
    DHAccountProjects: IDHBaseTemplates[]; 
    DHAccountTemplates: IDHBaseTemplates[];
    DHTemplatesCardTypes: IDHTemplatesCardType[];
    DHNewProjectId: string;
    DHNewTemplateId: string;
    DHNewTemplateProjectId: string;
    vpPricingDataOne: IVPItemPriceData[],
    vpPricingDataTwo: IVPItemPriceData[],
    vpPricingDataThree: IVPItemPriceData[],
    cartData: IViewCartItemData[],
    cartAddedItemData: IViewCartItemData,   
    cartItemModifiedStatus: string,
    cartItemRemovedStatus: string,
    cartCount: number,
    documentPreviewRespItem: IVPDocumentPreviewRespItem,
    deliveryOptionsData: IDeliveryOptions[],
    tovaTaxPercentage: number, 
    paymentShippingAddress: IShippingAddress, 
    paymentDeliveryOption: IDeliveryOptions, 
    groupStaffLoading: string,
    templatesLoading: string,
    pricingLoading: string,
    cartDataLoading: string, 
    deleteDHProjectStatus: string,
    deliveryOptionsStatus: string, 
    downloadQRImage: string,
    imageLoading: 'idle' | 'loading' | 'succeeded' | 'error' ,
}

const initialState: FilterState = {
    loading: 'idle',
    imageLoading: 'idle'  ,
    qrCodePageAccountId: "", 
    poolStaffSelection: '', 
    selectedPoolStaffId: '',
    selectedPoolStaffName: '', 
    accountList: [],
    accountGroupsList: [],
    accountStaffList: [],
    DHUserAccessToken: "",
    DHAppAccessToken: "",
    DHBaseTemplates: [],
    DHAccountProjects: [],
    DHAccountTemplates: [], 
    DHTemplatesCardTypes: [], 
    DHNewProjectId: "",
    DHNewTemplateId: "", 
    DHNewTemplateProjectId: "",
    vpPricingDataOne: [],
    vpPricingDataTwo: [],
    vpPricingDataThree: [],
    cartData: [],
    cartAddedItemData : {
        id: 0,
        projectId: "",
        cardType: 0,
        groupType: "", 
        documentRefUrl: "", 
        quantity: 0,
        sku: "" 
    },
    cartItemModifiedStatus: '',
    cartItemRemovedStatus: '', 
    cartCount: 0,
    documentPreviewRespItem: {
        cartId: 0,
        documentUrl: ""
    },
    deliveryOptionsData: [],
    tovaTaxPercentage: 0,
    paymentShippingAddress: {
        cartIds: "",  
        firstName: "",  
        lastName: "", 
        address: "", 
        addressTwo: "", 
        city: "", 
        state: "", 
        postalCode: "", 
        country: "",  
        mobile: "", 
        email: "", 
    }, 
    paymentDeliveryOption: { 
        deliveryOptionId: "",
        amount: 0,
        currency: "",
        deliveryArriveByDate: "",
        businessDays: 0, 
    },
    groupStaffLoading: "",
    templatesLoading: "",
    pricingLoading: "",
    cartDataLoading: "",
    deleteDHProjectStatus: "",
    deliveryOptionsStatus: "", 
    downloadQRImage: ""
};

export const qrcodesDataSlice = createSlice({
    name: 'qrcodesData',
    initialState,
    reducers: {
        changeAccountData: (state, action: PayloadAction<any>) => {
            state.accountList = action.payload; 
        },
        changePoolStaffSelection: (state, action: PayloadAction<any>) => {
            state.poolStaffSelection = action.payload;
        },
        changeSelectedPoolStaffId: (state, action: PayloadAction<any>) => {
            state.selectedPoolStaffId = action.payload;
        },
        changeSelectedPoolStaffName: (state, action: PayloadAction<any>) => {
            state.selectedPoolStaffName = action.payload;
        },
        changeQrCodePageAccountId: (state, action: PayloadAction<any>) => {
            state.qrCodePageAccountId = action.payload;
        },
        changeCartItemModifiedStatus: (state, action: PayloadAction<any>) => {
            state.cartItemModifiedStatus = action.payload;
        },
        changeCartItemRemovedStatus: (state, action: PayloadAction<any>) => {
            state.cartItemRemovedStatus = action.payload;
        },
        changePaymentShippingAddress: (state, action: PayloadAction<any>) => {
            state.paymentShippingAddress = action.payload;
        },
        changePaymentDeliveryOption: (state, action: PayloadAction<any>) => {
            state.paymentDeliveryOption = action.payload;
        }, 
        changeDeleteDHProjectStatus: (state, action: PayloadAction<any>) => {
            state.deleteDHProjectStatus = action.payload;
        }, 
        changeTemplatesLoading: (state, action: PayloadAction<any>) => {
            state.templatesLoading = action.payload;
        },
        changeDHNewTemplateProjectId: (state, action: PayloadAction<any>) => {
            state.DHNewTemplateProjectId = action.payload;
        }, 
        changeDownloadQRImage: (state, action: PayloadAction<any>) => {
            state.downloadQRImage = action.payload;
        }, 
        changeDHNewProjectId: (state, action: PayloadAction<any>) => {
            state.DHNewProjectId = action.payload;
        }, 
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAccountListQR.pending, (state) => {
            state.accountList = [];
            state.groupStaffLoading = 'loading';
        });
        builder.addCase(fetchAccountListQR.fulfilled, (state, action) => {
            const hotels = action.payload as IAccountDetails[];
            let opts: IAccountListQR[] = [];
            hotels.map((hotelNameId) => {
                opts.push({ value: hotelNameId.id.toString(), label: hotelNameId.hotelName, active: hotelNameId.isActive ? true : false, payout: hotelNameId.payout, isChecked: false });
            });
            state.accountList = opts;
            state.groupStaffLoading = 'succeeded';    
        });
        builder.addCase(fetchAccountListQR.rejected, (state, { payload }: any) => {
            state.groupStaffLoading = 'error';
        });

        builder.addCase(fetchAccountGroupsListQR.pending, (state) => {
            state.accountGroupsList = [];
            state.groupStaffLoading = 'loading';
        });
        builder.addCase(fetchAccountGroupsListQR.fulfilled, (state, action) => {
            state.accountGroupsList = action.payload as IAccountGroupsList[];
            state.groupStaffLoading = 'succeeded';
        });
        builder.addCase(fetchAccountGroupsListQR.rejected, (state, { payload }: any) => {
            state.groupStaffLoading = 'error';
        }); 

        builder.addCase(fetchAccountStaffListQR.pending, (state) => {
            state.accountStaffList = [];
            state.groupStaffLoading = 'loading';
        });
        builder.addCase(fetchAccountStaffListQR.fulfilled, (state, action) => {
            state.accountStaffList = action.payload as IStaff[];
            state.groupStaffLoading = 'succeeded';
        });
        builder.addCase(fetchAccountStaffListQR.rejected, (state, { payload }: any) => {
            state.groupStaffLoading = 'error';
        });

        // ******************** FETCH DESIGN HUDDLE USER ACCESS TOKEN *************************
        builder.addCase(fetchDHUserAccessToken.pending, (state) => {
            // state.accountStaffList = [];
            state.loading = 'loading';
        });
        builder.addCase(fetchDHUserAccessToken.fulfilled, (state, action) => { 
            state.DHUserAccessToken = action.payload.token; 
            dh_setUserAccessToken({userAccessToken:action.payload.token}); 
        });
        builder.addCase(fetchDHUserAccessToken.rejected, (state, { payload }: any) => {
            state.loading = 'DHTokenError';
        });

        // ******************** FETCH DESIGN HUDDLE APP ACCESS TOKEN *************************
        builder.addCase(fetchDHAppAccessToken.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(fetchDHAppAccessToken.fulfilled, (state, action) => { 
            state.DHAppAccessToken = action.payload.token; 
            dh_setAppAccessToken({appAccessToken:action.payload.token}); 
        });
        builder.addCase(fetchDHAppAccessToken.rejected, (state, { payload }: any) => {
            state.loading = 'DHTokenError';
        }); 

        // ******************** FETCH DETAILS OF BASE TEMPLATES **************************
        builder.addCase(fetchDHBaseTemplates.pending, (state) => {
            state.templatesLoading = 'loading';
        });
        builder.addCase(fetchDHBaseTemplates.fulfilled, (state, action) => { 
            state.templatesLoading = 'succeeded';
            state.DHBaseTemplates = action.payload.baseTemplate as IDHBaseTemplates[]; 
        });
        builder.addCase(fetchDHBaseTemplates.rejected, (state, { payload }: any) => {
            state.templatesLoading = 'DHBaseTemplatesError';
        }); 

        // ******************** CREATE NEW DESIGN HUDDLE PROJECT *************************
        builder.addCase(createNewDHProject.pending, (state) => {
            state.templatesLoading = 'loading';
        });
        builder.addCase(createNewDHProject.fulfilled, (state, action) => { 
            state.templatesLoading = 'succeeded';
            state.DHNewProjectId = action.payload.projectId; 
        });
        builder.addCase(createNewDHProject.rejected, (state, { payload }: any) => {
            state.templatesLoading = 'createNewDHProjectError';
        });  

        // *************** CREATE NEW DESIGN HUDDLE PROJECT FRO COMMON TEMPLATE *******************
        builder.addCase(createNewDHTemplateProject.pending, (state) => {
            state.templatesLoading = 'loading';
        });
        builder.addCase(createNewDHTemplateProject.fulfilled, (state, action) => { 
            state.templatesLoading = 'succeeded';
            state.DHNewTemplateProjectId = action.payload.projectId; 
        });
        builder.addCase(createNewDHTemplateProject.rejected, (state, { payload }: any) => {
            state.templatesLoading = 'createNewDHTemplateProjectError';
        }); 

        // *************** PUBLISH NEW DH TEMPLATE TO LIST IN PRE MADE TEMPLATES *******************
        builder.addCase(publishNewDHTemplate.pending, (state) => {
            state.templatesLoading = 'loading';
        });
        builder.addCase(publishNewDHTemplate.fulfilled, (state, action) => { 
            state.templatesLoading = 'publishNewDHTemplateSucceeded';
            state.DHNewTemplateId = action.payload.templateId; 
        });
        builder.addCase(publishNewDHTemplate.rejected, (state, { payload }: any) => {
            state.templatesLoading = 'publishNewDHTemplateError';
        });  

        // *************** APPLY PRE MADE TEMPLATE TO PROJECTS  *******************
        builder.addCase(applyTemplateToProjects.pending, (state) => {
            state.templatesLoading = 'loading';
        });
        builder.addCase(applyTemplateToProjects.fulfilled, (state, action) => { 
            state.templatesLoading = 'applyTemplateToProjectsSucceeded';
            // state.DHNewTemplateProjectId = action.payload.projectId; 
        });
        builder.addCase(applyTemplateToProjects.rejected, (state, { payload }: any) => {
            state.templatesLoading = 'applyTemplateToProjectsError';
        });

        // ******************** LIST DESIGN HUDDLE PROJECTS OF AN ACCOUNT *************************
        builder.addCase(fetchDHAccountProjects.pending, (state) => {
            state.templatesLoading = 'loading';
        });
        builder.addCase(fetchDHAccountProjects.fulfilled, (state, action) => { 
            state.templatesLoading = 'succeeded';
            if(action.payload?.templates?.data?.items) {
                state.DHAccountProjects = action.payload.templates.data.items as IDHBaseTemplates[]; 
            }
            else {
                state.DHAccountProjects = []; 
            }
        });
        builder.addCase(fetchDHAccountProjects.rejected, (state, { payload }: any) => {
            state.templatesLoading = 'fetchDHAccountProjectsError';
        }); 

        // ******************** LIST DESIGN HUDDLE TEMPLATES OF AN ACCOUNT *************************
        builder.addCase(fetchDHAccountTemplates.pending, (state) => {
            state.templatesLoading = 'loading';
        });
        builder.addCase(fetchDHAccountTemplates.fulfilled, (state, action) => { 
            state.templatesLoading = 'succeeded';
            if(action.payload?.cardTypes) {
                state.DHTemplatesCardTypes = action.payload.cardTypes as IDHTemplatesCardType[]; 
            }
            else {
                state.DHTemplatesCardTypes = []; 
            }

            console.log('action.payload: ',action.payload); 
            if(action.payload?.template?.data?.items) {
                state.DHAccountTemplates = action.payload.template.data.items as IPreMadeTemplateData[]; 
            }
            else {
                state.DHAccountTemplates = []; 
            }
        });
        builder.addCase(fetchDHAccountTemplates.rejected, (state, { payload }: any) => {
            state.templatesLoading = 'fetchDHAccountTemplatesError';
        }); 

        // ******** LIST VISTA PRINT PRICING - CARD TYPE 1 - DH_TEMPLATE_DESIGN_TYPE_POSTCARD **********
        builder.addCase(fetchVPPricingOne.pending, (state) => {
            state.pricingLoading = 'loading';
        });
        builder.addCase(fetchVPPricingOne.fulfilled, (state, action) => { 
            state.vpPricingDataOne = action.payload.data as IVPItemPriceData[]; 
            state.pricingLoading = 'succeeded';
        });
        builder.addCase(fetchVPPricingOne.rejected, (state, { payload }: any) => {
            state.pricingLoading = 'fetchVPPricingOneError';
        });

        // **********  LIST VISTA PRINT PRICING - CARD TYPE 2 - DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD **********
        builder.addCase(fetchVPPricingTwo.pending, (state) => {
            state.pricingLoading = 'loading';
        });
        builder.addCase(fetchVPPricingTwo.fulfilled, (state, action) => { 
            state.vpPricingDataTwo = action.payload.data as IVPItemPriceData[]; 
            state.pricingLoading = 'succeeded';
        });
        builder.addCase(fetchVPPricingTwo.rejected, (state, { payload }: any) => {
            state.pricingLoading = 'fetchVPPricingTwoError';
        }); 

        // ********* LIST VISTA PRINT PRICING - CARD TYPE 3 - DH_TEMPLATE_DESIGN_TYPE_TABLETENT ***********
        builder.addCase(fetchVPPricingThree.pending, (state) => {
            state.pricingLoading = 'loading';
        });
        builder.addCase(fetchVPPricingThree.fulfilled, (state, action) => { 
            state.vpPricingDataThree = action.payload.data as IVPItemPriceData[]; 
            state.pricingLoading = 'succeeded';
        });
        builder.addCase(fetchVPPricingThree.rejected, (state, { payload }: any) => {
            state.pricingLoading = 'fetchVPPricingThreeError';
        }); 

        // ********* LIST CART DATA ***********
        builder.addCase(fetchCartDetails.pending, (state) => {
            state.cartDataLoading = 'loading';
        });
        builder.addCase(fetchCartDetails.fulfilled, (state, action) => { 
            // console.log('fetchCartDetails : ',JSON.stringify(action.payload)); 
            state.cartData = action.payload.cartDetails; //  as IVPItemPriceData[]; 
            state.cartDataLoading = 'succeeded';
        });
        builder.addCase(fetchCartDetails.rejected, (state, { payload }: any) => {
            state.cartDataLoading = 'fetchCartDetailsError';
        }); 

        // ********* FETCH CART ITEMS COUNT ***********
        builder.addCase(fetchCartCount.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(fetchCartCount.fulfilled, (state, action) => { 
            state.cartCount = action.payload.cartCount;
        });
        builder.addCase(fetchCartCount.rejected, (state, { payload }: any) => {
            state.loading = 'fetchCartCountError';
        }); 

        // ********* ADD AN ITEM TO CART ***********
        builder.addCase(addCartItem.pending, (state) => {
            state.cartDataLoading = 'loading';
        });
        builder.addCase(addCartItem.fulfilled, (state, action) => { 
            state.cartAddedItemData = action.payload.data; 
            state.cartDataLoading = 'succeeded';
        });
        builder.addCase(addCartItem.rejected, (state, { payload }: any) => {
            state.cartDataLoading = 'addCartItemError';
        }); 

        // ********* MODIFY A CART ITEM ***********
        builder.addCase(modifyCartItem.pending, (state) => {
            state.cartDataLoading = 'loading';
        });
        builder.addCase(modifyCartItem.fulfilled, (state, action) => { 
            state.cartItemModifiedStatus = action.payload.message;
            state.cartDataLoading = 'succeeded';
        });
        builder.addCase(modifyCartItem.rejected, (state, { payload }: any) => {
            state.cartItemModifiedStatus = 'modifyCartItemError';
            state.cartDataLoading = 'modifyCartItemError'; 
        }); 

        // ********* REMOVE A CART ITEM ***********
        builder.addCase(removeCartItem.pending, (state) => {
            state.cartDataLoading = 'loading';
        });
        builder.addCase(removeCartItem.fulfilled, (state, action) => { 
            state.cartItemRemovedStatus = action.payload.message;
            state.cartDataLoading = 'succeeded';
        });
        builder.addCase(removeCartItem.rejected, (state, { payload }: any) => {
            state.cartItemRemovedStatus = 'removeCartItemError';
            state.cartDataLoading = 'removeCartItemError'; 
        }); 

        // ********* DOCUMENT PREVIEW URL FROM VISTA PRINT ***********
        builder.addCase(fetchDocumentPreview.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(fetchDocumentPreview.fulfilled, (state, action) => { 
            state.documentPreviewRespItem = action.payload;
        });
        builder.addCase(fetchDocumentPreview.rejected, (state, { payload }: any) => {
            // state.cartItemRemovedStatus = 'fetchDocumentPreviewError';
            console.log('fetchDocumentPreviewERROR: ',JSON.stringify(payload)); 
        }); 

        // ********* FETCH DELIVERY OPTIONS AND ITS PRICE FROM VISTA PRINT ***********
        builder.addCase(fetchDeliveryOptions.pending, (state) => {
            state.deliveryOptionsStatus = 'loading';
        });
        builder.addCase(fetchDeliveryOptions.fulfilled, (state, action) => { 
            state.deliveryOptionsStatus = 'succeeded';
            state.deliveryOptionsData = action.payload.deliveryOptions;
            state.tovaTaxPercentage = action.payload?.taxPercentage ? action.payload.taxPercentage : 0; 
        });
        builder.addCase(fetchDeliveryOptions.rejected, (state, { payload }: any) => {
            state.deliveryOptionsStatus = 'fetchDeliveryOptionsError'+(Math.random() * 1000).toString(); 
            console.log('fetchDeliveryOptionsERROR: ',JSON.stringify(payload)); 
        });
        // ********* FETCH TEMPLATE AND DOWNLOAD ***********
        builder.addCase(downloadQRTemplate.pending, (state) => {
            state.imageLoading = 'loading';
        });
        builder.addCase(downloadQRTemplate.fulfilled, (state, action) => {            
            state.downloadQRImage = action.payload.downloadUrl;
            state.imageLoading = 'succeeded';
        });
        builder.addCase(downloadQRTemplate.rejected, (state, { payload }: any) => {
            state.imageLoading = 'error';
                });

        // ********* DELETE DESIGN HUDDLE PROJECT ***********
        builder.addCase(deleteDHProject.pending, (state) => {
            state.deleteDHProjectStatus = 'loading';
        });
        builder.addCase(deleteDHProject.fulfilled, (state, action) => { 
            // state.deliveryOptionsData = action.payload.deliveryOptions;
            state.deleteDHProjectStatus = 'succeeded';
        });
        builder.addCase(deleteDHProject.rejected, (state, { payload }: any) => {
            console.log('deleteDHProjectERROR: ',JSON.stringify(payload)); 
            state.deleteDHProjectStatus = 'deleteDHProjectERROR';
        });  

        // ********* DELETE DESIGN HUDDLE PRE MADE TEMPLATE ***********
        builder.addCase(deleteDHPreMadeTemplate.pending, (state) => {
            state.templatesLoading = 'loading';
        });
        builder.addCase(deleteDHPreMadeTemplate.fulfilled, (state, action) => { 
            // state.deliveryOptionsData = action.payload.deliveryOptions;
            state.templatesLoading = 'deleteDHPreMadeTemplateSucceeded';
        });
        builder.addCase(deleteDHPreMadeTemplate.rejected, (state, { payload }: any) => {
            console.log('deleteDHPreMadeTemplateERROR: ',JSON.stringify(payload)); 
            state.templatesLoading = 'deleteDHPreMadeTemplateERROR';
        });  
        
    },
});

// Action creators are generated for each case reducer function
export const { changeAccountData, changePoolStaffSelection, changeSelectedPoolStaffId, changeSelectedPoolStaffName, changeQrCodePageAccountId, changeCartItemModifiedStatus, changeCartItemRemovedStatus, changePaymentShippingAddress, changePaymentDeliveryOption, changeDeleteDHProjectStatus, changeTemplatesLoading, changeDHNewTemplateProjectId, changeDownloadQRImage, changeDHNewProjectId } = qrcodesDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectQrcodesDataSlice = (state: RootState) => state.qrcodesData;

export const QRCodesSliceReducer = qrcodesDataSlice.reducer;
