import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import { setNotification } from "../../services/apiService";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_ACCOUNTS_LOGIN, ROUTE_SA_LOGIN, USER_ROLE_ACCOUNT_MANAGER, USER_ROLE_ACCOUNT_USER, USER_ROLE_ADMIN_ADMIN, USER_ROLE_ADMIN_SUPERADMIN } from "../../config/env";
import { useAppDispatch, useAppSelector } from "../../helpers/hook";
import { ResetPasswords, selectAuthentication } from "./resetPasswordSlice";

function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const search = useLocation().search;
  const f_token = new URLSearchParams(search).get("f_token");
  const token = new URLSearchParams(search).get("token");
  const user_id = new URLSearchParams(search).get("user_id");
  const expiry_time = new URLSearchParams(search).get("expiry_time");
  const user_role = new URLSearchParams(search).get("user_role");

  const [password, setPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);

  const { isProcessingRequest, isResetPasswordSuccess } =
    useAppSelector(selectAuthentication);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (isResetPasswordSuccess == true) {
      if(user_role===USER_ROLE_ADMIN_SUPERADMIN.toString() || user_role===USER_ROLE_ADMIN_ADMIN.toString()){
        navigate(ROUTE_SA_LOGIN);
      }else if(user_role===USER_ROLE_ACCOUNT_MANAGER.toString() || user_role===USER_ROLE_ACCOUNT_USER.toString()){
      navigate(ROUTE_ACCOUNTS_LOGIN);
    }
    }
  }, [isResetPasswordSuccess]); // displaySearch

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (password === currentPassword) {
      dispatch(
        ResetPasswords(
          f_token,
          token,
          user_id,
          expiry_time,
          password,
          currentPassword
        )
      );
    } else {
      setNotification(
        t("projectName"),
        t("messages.passwordMissMatch"),
        "failed",
        "handleResetPassword"
      );
    }
  };

  return (
    <div className="">
      <div className="center min-height-90vh">
        <form className="loginbox " onSubmit={(e) => handleResetPassword(e)}>
        
          <h1 className="mt-15px mb-30px">
            {t("resetpassword.headingResetyourpassword")}
          </h1>
          <div className="relative">
            <input
              type={passwordShown ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-100"
              required
              placeholder={`${t("resetpassword.passwordPlaceHolder")}`}
            />
            <IconButton
              onClick={(e) => {
                setPasswordShown(!passwordShown);
              }}
              sx={{ top: "11px", right: "5px", position: "absolute" }}
            >
              <i
                className={
                  passwordShown
                    ? "fa fa-eye-slash eye-icon"
                    : "fa fa-eye eye-icon"
                }
              ></i>
            </IconButton>
          </div>
          <div className="relative">
            <input
              type={currentPasswordShown ? "text" : "password"}
              className="w-100"
              placeholder={`${t("resetpassword.reenterPasswordPlaceHolder")}`}
              required
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <IconButton
              onClick={(e) => {
                setCurrentPasswordShown(!currentPasswordShown);
              }}
              sx={{ top: "11px", right: "5px", position: "absolute" }}
            >
              <i
                className={
                  currentPasswordShown
                    ? "fa fa-eye-slash eye-icon"
                    : "fa fa-eye eye-icon"
                }
              ></i>
            </IconButton>
          </div>
          <button
            className="rounded-3 mb-100px p-12px text-white border-none mt-18px"
            style={{ backgroundColor: "#3871E0", letterSpacing: ".5px" }}
          >
            {t("resetpassword.resetButton")}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
