import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { setNotification } from '../../services/apiService';
import { t } from 'i18next';
import { staffRegWithoutEmail } from '../../services/staffRegisterWithoutEmailServices';
import { ERR_EMAIL_ALREADY_EXISTS, ERR_PHONE_ALREADY_EXISTS } from '../../config/apiResponseCodes';

export interface DataState {
  regLoading: 'idle' | 'loading' | 'succeeded' | 'error';
}

const initialState: DataState = {
  regLoading: 'idle',
};

export const StaffRegisterWithoutEmailSlice = createSlice({
  name: 'StaffRegisterWithoutEmailSlice',
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    
    builder.addCase(staffRegWithoutEmail.pending, (state) => {
      state.regLoading = 'loading';
    });
    builder.addCase(staffRegWithoutEmail.fulfilled, (state, action) => {
      state.regLoading = 'succeeded';

      setNotification(
        t("projectName"),
        t("staffRegister.messageSuccess"),
        "success",
        "staffRegWithoutEmail"
      );

    });
    builder.addCase(staffRegWithoutEmail.rejected, (state, { payload }: any) => {
      state.regLoading = 'error'; 
      let errorFromStripe = payload?.response?.data?.error ? payload.response.data.error : "";
      let resp = payload?.response?.data?.message ? (payload.response.data.message).toLowerCase() : "";
      let msgText = t("staffRegister.messageFailed"); 
      if(errorFromStripe && errorFromStripe.trim().length > 1) {
        msgText = errorFromStripe; 
      }
      else {
        switch(resp) {
          case(ERR_EMAIL_ALREADY_EXISTS.toLowerCase()) : msgText = t("messages.emailAlreadyExists"); 
            break;
          case(ERR_PHONE_ALREADY_EXISTS.toLowerCase()) : msgText = t("messages.phoneAlreadyExists"); 
            break;
          default: msgText = t("messages.staffUpdatingFailed"); 
            break; 
        }  
      }
       
      setNotification(
        t("projectName"),
        msgText,
        "failed",
        "staffRegWithoutEmail"
      )

    });
  },
});

// Action creators are generated for each case reducer function
// export const { regLoading } = StaffRegisterWithoutEmailSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const staffRegisterWithoutEmailSlice = (state: RootState) => state.StaffRegisterWithoutEmail;

export const StaffRegisterWithoutEmailReducer = StaffRegisterWithoutEmailSlice.reducer; 
