import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { ITerms } from '../../models/termsInterfaces';
import { getTerms, saveTerms } from '../../services/termsServices';
import { setNotification } from '../../services/apiService';
import { t } from 'i18next';

export interface DataState {
  terms: ITerms;
  termsLoading: 'idle' | 'loading' | 'succeeded' | 'error';
  termsError: string | undefined;
}

const initialState: DataState = {
  terms: {} as ITerms,
  termsLoading: 'idle',
  termsError: '',
};

export const getTermsDataSlice = createSlice({
  name: 'getTermsdata',
  initialState,
  reducers: {
    setTermsData: (state, action: PayloadAction<any>) => {
      state.terms = action.payload;
      console.log('action.payload: ',action.payload); 
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTerms.pending, (state) => {
      state.terms = {} as ITerms;
      state.termsLoading = 'loading';
    });

    builder.addCase(getTerms.fulfilled, (state, action) => {
      const m = action.payload ? action.payload as ITerms : {group: 0,
        conditionText: '',
        currentTerms: false};
      console.log('action.payload: ',action.payload); 
      state.terms = m;
      state.termsLoading = 'succeeded';
    });

    builder.addCase(saveTerms.pending, (state) => {
      state.terms = {} as ITerms;
      state.termsLoading = 'loading';
    });
    
    builder.addCase(saveTerms.fulfilled, (state, action) => {
      const m = action.payload as ITerms;
      state.terms = m;
      state.termsLoading = 'succeeded';
    
      setNotification(
        t("projectName"),
        t("messages.T&CSaved"),
        "success",
        "saveTerms"
      )

    });

    builder.addCase(saveTerms.rejected, (state, { payload }: any) => {
      state.termsLoading = 'error';
     
      setNotification(
        t("projectName"),
        t("messages.T&CSavingFailed"),
        "failed",
        "saveTerms"
      )

      // state.error = action.error.message;
    });

  },
});

// Action creators are generated for each case reducer function
export const { setTermsData } = getTermsDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectGetTermsDataSlice = (state: RootState) => state.getTermsdata; 

export const termsDataReducer = getTermsDataSlice.reducer;
