import { get, getFile } from './apiService';
import { ICommissionsRequestData } from '../models/commissionsInterfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SA_API_URL_COMMISSION_EXPORT, SA_API_URL_COMMISSION_LIST, SA_API_URL_COMMISSION_SUMMARY } from '../config/api_urls';

//FUNCTION TO FETCH COMMISSION LIST
export const fetchCommissions = createAsyncThunk(
  'payments/fetchCommissions',
  async ({ page, numRowsPerPage, sort, search, hotel, group, start, end, status}: ICommissionsRequestData, thunkAPI: any) => {
    try {
      let url = SA_API_URL_COMMISSION_LIST+'?page=' + page+ '&hotel=' + hotel;
      if (numRowsPerPage) {
        url = url + '&page_size=' + numRowsPerPage.toString();
      }
      if (group != '')  { url = url + '&group=' + group; }
      if (sort != '')   { url = url + '&sort=' + sort; }
      if (search != '') { url = url + '&search=' + search.trim(); }
      if (start && start != '')  { url = url + '&start=' + encodeURIComponent(start); }
      if (end && end != '')    { url = url + '&end=' + encodeURIComponent(end); }
      if (status != '') { url = url + '&status=' + status; }

      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
); 

//FUNCTION TO EXPORT COMMISSION LIST
export const exportCommissions = createAsyncThunk(
  'payments/exportCommissions',
  async ({ page, sort, search, hotel, group, start, end, status}: ICommissionsRequestData, thunkAPI: any) => {
    try {
      let url = SA_API_URL_COMMISSION_EXPORT+'?page=' + page+'&hotel=' + hotel;
      if (group != '')  { url = url + '&group=' + group; }
      if (sort != '')   { url = url + '&sort=' + sort; }
      if (search != '') { url = url + '&search=' + search.trim(); }
      if (start && start != '')  { url = url + '&start=' + encodeURIComponent(start); }
      if (end && end != '')    { url = url + '&end=' + encodeURIComponent(end); }
      if (status != '') { url = url + '&status=' + status; }
      getFile(url, 'Commissions_List_Export');
    } catch (error) {
      console.log('Commissions List Exported error: ',error);
    }
  }
); 

//FUNCTION TO FETCH COMMISSION SUMMARY
export const fetchCommissionsSummary = createAsyncThunk( 
  'payments/fetchCommissionsSummary',
  async ({ page, sort, search, hotel, group, start, end, status}: ICommissionsRequestData, thunkAPI: any) => {
    try {
      let url = SA_API_URL_COMMISSION_SUMMARY+'?page=' + page+ '&hotel=' + hotel;
      if (group != '')  { url = url + '&group=' + group; }
      if (sort != '')   { url = url + '&sort=' + sort; }
      if (search != '') { url = url + '&search=' + search.trim(); }
      if (start && start != '')  { url = url + '&start=' + encodeURIComponent(start); }
      if (end && end != '')    { url = url + '&end=' + encodeURIComponent(end); }
      if (status != '') { url = url + '&status=' + status; }      
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
); 


