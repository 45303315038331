import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAccountAdminUserDetails, IAdminUsersListRequestData } from "../models/adminUsersListInterfaces";
import { deleteApi, get, post } from "./apiService";
import { arrAccountUserIds } from '../config/env'; 
import { getAdminUserRole } from "./localStorage";
import { SA_API_URL_ADMIN_USERS, SA_API_URL_ADMIN_USER_CREATE, SA_API_URL_ADMIN_USER_PWD_RESET, SA_API_URL_ADMIN_USER_DELETE, SA_API_URL_ADMIN_USER_UPDATE, ACC_API_URL_ADMIN_USER_CREATE,  ACC_API_URL_ADMIN_USER_DELETE, ACC_API_URL_ADMIN_USER_UPDATE } from "../config/api_urls";

const roleId = getAdminUserRole(); 
const adminUserRole = roleId ? Number(roleId) : 0; 

//TO FETCH ADMIN USERS
export const fetchAdminUsers = createAsyncThunk(
  'hotels/fetchAdminUsers',
  async ({ page, numRowsPerPage, hotel, sort, active, role ,search}: IAdminUsersListRequestData, thunkAPI: any) => {
    try {
      let url = SA_API_URL_ADMIN_USERS+'?page=' + page+ '&hotel=' + hotel;
      if (numRowsPerPage) {
        url = url + '&page_size=' + numRowsPerPage.toString();
      }
      if (sort !== '') {
        url = url + '&sort=' + sort;
      }
      if (search !== '') {
        url = url + '&search=' + search?.trim();
      }
      if (active !== '') {
        url = url + '&active=' + active;
      }
      if (role >=0) {
        url = url + '&role=' + role;
      }
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// TO CREATE NEW ADMIN USER
export const createAdminUser = createAsyncThunk(
  'associates/createAdminUser',
  async (newAssoc: IAccountAdminUserDetails, thunkAPI: any) => {
    try {
      const url = (arrAccountUserIds.includes(adminUserRole) ? ACC_API_URL_ADMIN_USER_CREATE : SA_API_URL_ADMIN_USER_CREATE);
      const response = post(url, newAssoc);
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);  

//TO RESET ADMIN USER PASSWORD
export const resetAdminUserPassword = createAsyncThunk(
  'associates/resetAdminUserPwd',
  async (username: string, thunkAPI: any) => {
    try {
      const url = SA_API_URL_ADMIN_USER_PWD_RESET+'?username=' + username;
      const response = post(url, "");
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//TO DELETE ADMIN USER
export const deleteAdminUser = createAsyncThunk(
  'adminUser/deleteAdminUser',
  async (adminUserId: string, thunkAPI: any) => {
    try {
      const url = (arrAccountUserIds.includes(adminUserRole) ? ACC_API_URL_ADMIN_USER_DELETE : SA_API_URL_ADMIN_USER_DELETE) + adminUserId + '/';
      const response = deleteApi(url);
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);  

//TO UPDATE ADMIN USER DETAILS
export const updateAdminUser= createAsyncThunk(
  'associates/modifyAdminAssociate',
  async (adminUserData: IAccountAdminUserDetails, thunkAPI: any) => {
    try {
      const url = (arrAccountUserIds.includes(adminUserRole) ? ACC_API_URL_ADMIN_USER_UPDATE : SA_API_URL_ADMIN_USER_UPDATE) + adminUserData.userId + '/';
      const response = post(url, adminUserData);
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
