import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';

import selDroparrow from  "../../assets/images/drop-arrow.svg"; 

import { selectQrcodesDataSlice, changePoolStaffSelection, changeSelectedPoolStaffId, changeSelectedPoolStaffName } from './QRCodesSlice';

import { IStaff, IAccountGroupsListMultiSel, IStaffMultiSel } from '../../models/qrCodeInterfaces';

import { Textfield } from "../../components/TextField";
import { SelectField } from "../../components/SelectField";
import { ButtonPrimary } from "../../components/ButtonGroups/ButtonPrimary";

import { GROUP_STAFF_SEL_ALL_VALUE, QRCODE_FOR_GROUP, QRCODE_FOR_STAFF, DH_TEMPLATE_TYPE_BASE, DH_TEMPLATE_TYPE_BLANK, DH_TEMPLATE_TYPE_ACCOUNT } from "../../config/env_qrcodes";


import { setNotification } from '../../services/apiService';
import { getFilterAccountId } from '../../services/localStorage';

interface IProps {
    displayTemplatesType: number;  
    calledFor?: string; 
    applyGroupStaffFilter?: (selGroupStaffType:string, selGroupStaffId:string) => void;
    applyTemplateToGroupStaff?: (selGroupStaffType:string, selGroupStaffId:string) => void;
}

const QRSidePanel: React.FC<IProps> = (props) => { 
    const { t } = useTranslation();
    const { accountGroupsList, accountStaffList, qrCodePageAccountId } = useAppSelector(selectQrcodesDataSlice); 

    const dispatch = useAppDispatch();
    const maxWords = 22;
    const [selPoolStaff, setSelPoolStaff] = useState(QRCODE_FOR_GROUP); 
    const [staffSearchName, setStaffSearchName] = useState(""); 
    const [stateAccountGroupList, setStateAccountGroupList] = useState<IAccountGroupsListMultiSel[]>([]); 
    const [stateAccountStaffList, setStateAccountStaffList] = useState<IStaffMultiSel[]>([]); 
    const [stateTempAccountStaffList, setStateTempAccountStaffList] = useState<IStaff[]>([]); 
    const [groupStaffFilterString, setGroupStaffFilterString] = useState("");  
    const [filterContent, setfilterContent] = useState(true);

    const [groupStaffDataLoaded, setGroupStaffDataLoaded] = useState(false); 

    const divRef = useRef(null);
    const [filterOptionCss, setFilterOptionCss] = useState("");
    const divHeight = () => {
        if ((divRef.current?.offsetHeight && divRef.current.offsetHeight > 45)) {
        setFilterOptionCss("mb-12px");
        }
        else {
        setFilterOptionCss("");
        }
    }

    useEffect(() => {
        if(!groupStaffDataLoaded) {
            dispatch(changePoolStaffSelection(QRCODE_FOR_GROUP)); 
            dispatch(changeSelectedPoolStaffId("")); 
            dispatch(changeSelectedPoolStaffName("")); 
            setGroupStaffDataLoaded(true); 
        }
        else {

        }
        
    },[]);

    useEffect(() => { 
        if(accountGroupsList && accountGroupsList.length > 0) {
            let arrNew  =  [] as IAccountGroupsListMultiSel[]; 
            // REMOVE THE DEFAULT GROUP WITH NAME 'Staff' AND ADD isChecked FIELD
            accountGroupsList.filter(gItem => (gItem.name.toLowerCase() != t("qrcodesPage.defaultStaffGroupName")))
            .map(item => {
                arrNew.push({...item, isChecked: false}); 
            });
            setStateAccountGroupList(arrNew);
        }
        else {
            setStateAccountGroupList([]);
        } 
    }, [accountGroupsList]);

    useEffect(() => { 
        if(accountStaffList && accountStaffList.length > 0) { 
            let arrNew  =  [] as IStaffMultiSel[]; 
            accountStaffList.filter(sItem => Number(sItem.id) > 0).map(item => {
                arrNew.push({...item, isChecked: false}); 
            });
            setStateAccountStaffList(arrNew);
            setStateTempAccountStaffList(arrNew);
        }
        else {
            setStateAccountStaffList([]);
            setStateTempAccountStaffList([]);
        } 
    }, [accountStaffList]);

    useEffect(() => {
        dispatch(changePoolStaffSelection(QRCODE_FOR_GROUP)); 
        dispatch(changeSelectedPoolStaffId("")); 
        dispatch(changeSelectedPoolStaffName("")); 
        setSelPoolStaff(QRCODE_FOR_GROUP); 
        setStaffSearchName(""); 
        setGroupStaffFilterString(""); 
    },[qrCodePageAccountId]); 

    const onChangePoolStaffSelection = (poolStaffString:string) => {
        setSelPoolStaff(poolStaffString); 
        setStaffSearchName(""); 
        setGroupStaffFilterString(""); 
        dispatch(changePoolStaffSelection(poolStaffString)); 
        dispatch(changeSelectedPoolStaffId("")); 
        dispatch(changeSelectedPoolStaffName("")); 
        if(stateAccountGroupList && stateAccountGroupList.length > 0) {
            let selectedStatus = stateAccountGroupList.map((statusItem) => {
                return { ...statusItem, isChecked: false };
              });
            setStateAccountGroupList(selectedStatus);
        }
        if(stateTempAccountStaffList && stateTempAccountStaffList.length > 0) {
            let selectedStatus = stateTempAccountStaffList.map((statusItem) => {
                 return { ...statusItem, isChecked: false };
            });
            setStateTempAccountStaffList(selectedStatus);
        }
    } 

    // SEARCH STAFF BY NAME 
    const newStaffSearch = (nameSearch: string) => {
        setStaffSearchName(nameSearch);
        if (nameSearch.trim().length > 0) {
            let tmpArr = stateAccountStaffList.filter((item) =>
                item.fullName.toLowerCase().includes(nameSearch.toLowerCase())
            );
            setStateTempAccountStaffList(tmpArr);
        } else {
            setStateTempAccountStaffList(stateAccountStaffList);
        }
    };

    const onSetFilterGroup = (e) => {
        const { name, checked } = e.target;
        if (name === "chk_group_all") {
          let selectedStatus = stateAccountGroupList.map((statusItem) => {
            return { ...statusItem, isChecked: checked };
          });
          setStateAccountGroupList(selectedStatus);
        } else {
            let selectedStatus : IAccountGroupsListMultiSel[] = []; 
            stateAccountGroupList.map((statusItem) => {
                if(props?.displayTemplatesType!=DH_TEMPLATE_TYPE_BLANK) {
                    selectedStatus.push(statusItem.name === name ? { ...statusItem, isChecked: checked } : statusItem); 
                }
                else {
                    selectedStatus.push(statusItem.name === name ? { ...statusItem, isChecked: checked } : { ...statusItem, isChecked: false }); 
                }    
            })
            setStateAccountGroupList(selectedStatus);
        } 
    }; 

    const onSetFilterStaff = (e) => {
        const { name, checked } = e.target;
        if (name === "chk_staff_all") {
          let selectedStatus = stateTempAccountStaffList.map((statusItem) => {
            return { ...statusItem, isChecked: checked };
          });
          setStateTempAccountStaffList(selectedStatus);
        } else {
            let selectedStatus : IStaffMultiSel[] = [];
            stateTempAccountStaffList.map((statusItem) => { 
                if(props?.displayTemplatesType!=DH_TEMPLATE_TYPE_BLANK) {
                    selectedStatus.push(statusItem.fullName === name ? { ...statusItem, isChecked: checked } : statusItem); 
                } 
                else {
                    selectedStatus.push(statusItem.fullName === name ? { ...statusItem, isChecked: checked } : { ...statusItem, isChecked: false });
                }
            }); 
            setStateTempAccountStaffList(selectedStatus);   
        }
    }; 

    const ApplyButton = (calledFrom: string): any => {
        return (
          <div className=" p-2 border-top ">
            <ButtonPrimary
              className=" applyBtn mt-2px"
              onClick={(e) => onApplyButton(calledFrom)}
              buttonLabel={props.displayTemplatesType==DH_TEMPLATE_TYPE_BLANK ? t("qrcodesPage.create") : t("common.apply")}
            />
          </div>
        );
    };

    const onApplyButton = (calledFrom: string) => {
        setfilterContent(false);
        setGroupStaffFilterString("");
        let arrTemp = (calledFrom=="groupFilter") ? stateAccountGroupList : stateTempAccountStaffList; 
        if(arrTemp.length > 1 && arrTemp.every((item) => item.isChecked)) {
            setGroupStaffFilterString(t("statusFilter.all")); 
            if(props.calledFor=="applyTemplate") {
                props.applyTemplateToGroupStaff(selPoolStaff,"all"); 
            }
            else {
                dispatch(changeSelectedPoolStaffId("all")); 
                dispatch(changeSelectedPoolStaffName(t("statusFilter.all"))); 
                props?.applyGroupStaffFilter(selPoolStaff,"all"); 
            }
        }
        else {
            let arrSelected = arrTemp.filter(item => item.isChecked===true);
            
            if(arrSelected && arrSelected.length > 0) {
                let arrIds = arrSelected.map(item => { return item.id}); 
                let idsString = arrIds.join(","); 
                let tmpSelString = (calledFrom=="groupFilter") ? arrSelected[0].name : arrSelected[0].fullName; 
                if(arrSelected.length > 1) {
                    tmpSelString += " +"+(arrSelected.length-1).toString(); 
                }
                setGroupStaffFilterString(tmpSelString);
                if(props.calledFor=="applyTemplate") {
                    props.applyTemplateToGroupStaff(selPoolStaff,idsString); 
                }
                else {
                    dispatch(changeSelectedPoolStaffId(idsString)); 
                    dispatch(changeSelectedPoolStaffName(tmpSelString)); 
                    props?.applyGroupStaffFilter(selPoolStaff,idsString); 
                }
            }  
            else {
                setNotification(
                    t("messages.appName"),  
                    t("qrcodesPage.msgSelectPoolStaff"),
                    "failed",
                    "templateTypeMessage"
                )
            }
        }

        setTimeout(() => {
            setfilterContent(true);
        }, 500);
    }

    {/* {props?.calledFor=="applyTemplate" ? 'min-height-55vh py-10px ml-12px ' : 'max-width-292px min-height-66vh py-29px border-right flex-col space-between ' } */}

    return (
        <div className={'min-height-29vh py-10px ml-12px'}>
            <div>
                {/* // *************************************************************************
                // ************************ GROUP / STAFF TYPE SELECTION ************************
                // ************************************************************************* */}
                <div className="px-4">
                    <p className='qrside_label mb-13px'>{t("qrSidePanel.poolsStaff")}</p>
                    <SelectField
                        type="inputType"
                        name="sel_poolsStaff"
                        id="sel_poolsStaff"
                        placeHolder={t("qrSidePanel.poolsStaff")}
                        className={" qr-element pl-21px mb-4 "}
                        value={selPoolStaff}
                        onChange={(e) => onChangePoolStaffSelection(e.target.value)}
                        options={
                            <>
                            <option value={QRCODE_FOR_GROUP}>{t("qrSidePanel.poolsNormal")}</option>
                            <option value={QRCODE_FOR_STAFF}>{t("qrSidePanel.staffNormal")}</option>
                            </>
                        }
                    />
                </div>

                {/* ********************************************************************* */}
                {/* *********************** POOL / STAFF FILTER  ************************ */}
                {/* ********************************************************************* */}
                {selPoolStaff==QRCODE_FOR_GROUP ? <><div className='ps-4'>
                    <p className='qrside_label mb-13px'>{t("qrSidePanel.pools")}</p>  
                    <div className="qr-multi-sel-element filter-option mb-5px " onMouseOver={e => (divHeight())}>
                        <div className={"w-100 space-between"} >
                            {groupStaffFilterString && groupStaffFilterString.length > 0 ? (
                            <div className="filterApplied-no-border pl-21px  pt-6px">
                                {groupStaffFilterString}
                            </div>
                            ) : 
                            <div className="pl-21px pt-6px">
                                {selPoolStaff==QRCODE_FOR_GROUP ? t("qrSidePanel.selectPool") : selPoolStaff==QRCODE_FOR_STAFF ? t("qrSidePanel.selectStaff") : "" 
                                } 
                            </div>} 
                            <div><img src={selDroparrow} alt="" /></div>
                        </div> 
                        <div className="filter-content-body mt-8px ">
                            <div className={filterContent ? "filter-content w-100 ":''}>
                                <div className="p-2 mt-7px">
                                    {/* <label htmlFor="selFilterStatus" className="link-h m-5px mb-2" >
                                        {t("statusFilter.filterByName")}
                                    </label> */}
                                    <div className='multiselect'>
                                        {props.displayTemplatesType!=DH_TEMPLATE_TYPE_BLANK &&  stateAccountGroupList.length > 1 && <label htmlFor="chk_group_all" className={`flex gap-2 multisel-status-filter ${!stateAccountGroupList.some(
                                            (data) => data.isChecked !== true
                                        ) &&
                                            "text-blue"
                                            }`} >
                                            <input
                                            type="checkbox"
                                            name={"chk_group_all"}
                                            id={"chk_group_all"}
                                            value={GROUP_STAFF_SEL_ALL_VALUE}
                                            checked={
                                                !stateAccountGroupList.some(
                                                (data) => data.isChecked !== true
                                                )
                                            }
                                            onChange={(e) => onSetFilterGroup(e)}
                                            />
                                            {t("statusFilter.all")}
                                        </label>}

                                        {stateAccountGroupList.map((data) => (
                                            <label htmlFor={data.id.toString()} className={`flex gap-2 multisel-status-filter`} >
                                            <input
                                                type="checkbox"
                                                name={data.name}
                                                checked={data.isChecked ? true : false}
                                                // checked={true}
                                                onChange={(e) => onSetFilterGroup(e)}
                                                id={data.id.toString()}
                                                className={data.isChecked ? "text-blue" : ""}
                                            />
                                            {data.name}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                                {ApplyButton("groupFilter")}
                                </div>
                            </div>
                        </div>
                    </div> 
                </> 
                :
                <>
                <div className='ps-4'>
                    <p className='qrside_label mb-13px'>{t("qrSidePanel.staff")}</p>  
                    <div className="qr-multi-sel-element filter-option mb-5px" onMouseOver={e => (divHeight())}>
                        <div className={"w-100 space-between"} >
                            {groupStaffFilterString && groupStaffFilterString.length > 0 ? (
                            <div className="filterApplied-no-border pl-21px pt-6px">
                                {groupStaffFilterString}
                            </div>
                            ) : 
                            <div className="pl-21px pt-6px">
                                {selPoolStaff==QRCODE_FOR_GROUP ? t("qrSidePanel.selectPool") : selPoolStaff==QRCODE_FOR_STAFF ? t("qrSidePanel.selectStaff") : "" 
                                } 
                            </div>}
                            <div><img src={selDroparrow} alt="" /></div>
                        </div>
                        <div className="filter-content-body mt-8px">
                            <div className="filter-content">
                                <div className="p-2 multiselect">
                                    {/* <label htmlFor="selFilterStatus" className="link-h m-5px mb-2" >
                                        {t("statusFilter.filterByName")}
                                    </label> */}
                                    <div className={"mb-10px"} ><Textfield
                                        type="text"
                                        placeholder={t("qrSidePanel.staffNormal")}
                                        className={" qr-element-search pl-21px "} 
                                        value={staffSearchName}
                                        disabled={false}
                                        onChange={(e) => { newStaffSearch(e.target.value) }}
                                    /></div> 
                                    <div >
                                        {props.displayTemplatesType!=DH_TEMPLATE_TYPE_BLANK &&  stateAccountStaffList.length > 1 && <label htmlFor={"chk_staff_all"} className={`flex gap-2 multisel-status-filter ${!stateAccountStaffList.some(
                                            (data) => data.isChecked !== true
                                        ) &&
                                            "text-blue"
                                            }`} >
                                            <input
                                            type="checkbox"
                                            name={"chk_staff_all"}
                                            id={"chk_staff_all"}
                                            value={GROUP_STAFF_SEL_ALL_VALUE}
                                            checked={
                                                !stateTempAccountStaffList.some(
                                                (data) => data.isChecked !== true
                                                )
                                            }
                                            onChange={(e) => onSetFilterStaff(e)}
                                            />
                                            {t("statusFilter.all")}
                                        </label>}

                                        {stateTempAccountStaffList.filter(sItem => Number(sItem.id) > 0).map((data) => (
                                            <label htmlFor={data.id.toString()} className={`flex gap-2 multisel-status-filter`} >
                                            <input
                                                type="checkbox"
                                                name={data.fullName}
                                                checked={data.isChecked ? true : false}
                                                // checked={true}
                                                onChange={(e) => onSetFilterStaff(e)}
                                                id={data.id.toString()}
                                                className={data.isChecked ? "text-blue" : ""}
                                            />
                                            {data.fullName}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                                {ApplyButton("staffFilter")}
                                </div>
                            </div>
                        </div>
                    </div>
                </>}

                {/* <div className='ps-4'>
                    <p className='qrside_label mb-13px'>{t("qrSidePanel.pools")}</p>
                    <div className="multiselect max-height-310px">
                        {accountStaffList.map(item => <>
                            <div onClick={() => { dispatch(changeSelectedStaffId(item.id)); dispatch(changeSelectedStaffName(item.fullName)) }} className={selectedStaffId === item.id ? `${item.fullName.length > maxWords && 'show-qrStaff'} qr-element pl-21px mb-29px pointer ` : 'pointer qrside_label pl-21px mb-29px'}> 
                                <>{item.fullName}</>
                            </div>
                        </>)}
                    </div>
                </div> */}

            </div>
        </div>
    )
}

export default QRSidePanel