import { createAsyncThunk } from "@reduxjs/toolkit";
import { IStaffRequestData,IStaffAccountStatus, IStaffInvite, IStafflistDelete, IStaffReInvite } from "../models/staffInterfaces";
import { get, getFile, post, deleteApi } from "./apiService";
import { SA_API_URL_STAFF_LIST, ACC_API_URL_STAFF_LIST, SA_API_URL_STAFF_EXPORT, ACC_API_URL_STAFF_EXPORT, SA_API_URL_STAFF_STATUS, SA_API_URL_STAFF_DELETE, ACC_API_URL_STAFF_STATUS, ACC_API_URL_STAFF_DELETE, SA_API_URL_STAFF_INVITE, SA_API_URL_STAFF_REINVITE } from '../config/api_urls'; 
import { arrAccountUserIds } from '../config/env'; 


// FUNCTION TO FETCH STAFF LIST
export const fetchStaffList = createAsyncThunk(
    'staff/fetchStaffList',
    async ({ adminUserRole, page, numRowsPerPage, search, hotel, document_verified, joining_from_date, joining_to_date,sort, staffStatus, tips_from_date, tips_to_date }: IStaffRequestData, thunkAPI: any) => {
        try {             
            let url = (arrAccountUserIds.includes(adminUserRole) ? ACC_API_URL_STAFF_LIST : SA_API_URL_STAFF_LIST)+'?page=' + page  + '&hotel=' + hotel; 
            if (numRowsPerPage) {
                url = url + '&page_size=' + numRowsPerPage.toString();
            }
            if (search && search !== '') {
                url = url + '&search=' + search.trim();
            }
            if (document_verified !== '') {
                url = url + '&document_verified=' + document_verified;
            }
            if (joining_from_date !== '') {
                url = url + '&joining_from_date=' + joining_from_date;
            }
            if (joining_to_date !== '') {
                url = url + '&joining_to_date=' + joining_to_date;
            }
            if (tips_from_date !== '') {
                url = url + '&tips_from_date=' + tips_from_date;
            }
            if (tips_to_date !== '') {
                url = url + '&tips_to_date=' + tips_to_date;
            }
            if (sort !== '') {
                url = url + '&sort=' + sort;
            }
            if (staffStatus !== '') {
                url = url + '&staff_status=' + staffStatus;
            }
            const response = get(url);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// FUNCTION TO EXPORT STAFF LIST
export const exportStaffList = ({ page, search, hotel, document_verified, joining_from_date, joining_to_date,sort, active, tips_from_date, tips_to_date,adminUserRole }: IStaffRequestData): void => {
    try {
        let url = (arrAccountUserIds.includes(adminUserRole) ? ACC_API_URL_STAFF_EXPORT : SA_API_URL_STAFF_EXPORT)+'?page='+page; 
        if (search && search !== '') {
            url = url + '&search=' + search.trim();
        }
        if (document_verified !== '') {
            url = url + '&document_verified=' + document_verified;
        }
        if (joining_from_date !== '') {
            url = url + '&joining_from_date=' + joining_from_date;
        }
        if (joining_to_date !== '') {
            url = url + '&joining_to_date=' + joining_to_date;
        }
        if (tips_from_date !== '') {
            url = url + '&tips_from_date=' + tips_from_date;
        }
        if (tips_to_date !== '') {
            url = url + '&tips_to_date=' + tips_to_date;
        }
        if (sort !== '') {
            url = url + '&sort=' + sort;
        }
        if (active !== '') {
            url = url + '&active=' + active;
        }
        url = url + '&hotel=' + hotel;
        getFile(url, 'Staff_List_Export');
    } catch (error) {
    }
};

// FUNCTION TO CHANGE STAFF ACCOUNT STATUS
export const changeStaffAccountStatus = createAsyncThunk(
    'staff/staffAccountStatus',
    async (staffHotel: IStaffAccountStatus, thunkAPI: any) => {
        
        try {
            let url = (arrAccountUserIds.includes(Number(staffHotel.adminUserRole)) ? ACC_API_URL_STAFF_STATUS : SA_API_URL_STAFF_STATUS)+staffHotel.staffId+'/'+staffHotel.accountId+'/';
            const response = post(url,null);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// FUNCTION TO DELETE STAFF 
export const deleteStaff = createAsyncThunk(
    'staff/deleteStaff',
    async ({staffId,adminUserRole}: IStafflistDelete, thunkAPI: any) => {
        try {
            let url = (arrAccountUserIds.includes(Number(adminUserRole)) ? ACC_API_URL_STAFF_DELETE : SA_API_URL_STAFF_DELETE) + staffId + '/'; 
            const response = deleteApi(url);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error); 
        }
    }
);

// FUNCTION TO INVITE STAFF 
export const inviteStaff= createAsyncThunk(
  'staff/inviteStaff',
  async (body: IStaffInvite, thunkAPI: any) => {
    try {
      const url = SA_API_URL_STAFF_INVITE;
      const response = post(url, body);
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// FUNCTION TO RE-INVITE STAFF 
export const reInviteStaff= createAsyncThunk(
    'staff/reInviteStaff',
    async (body: IStaffReInvite, thunkAPI: any) => {
      try {
        const url = SA_API_URL_STAFF_REINVITE;
        const response = post(url, body);
        return (await response).data;
      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );
