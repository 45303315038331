import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import QRCodeRightSidePanelPayment from '../../components/QRCodeComponents/QRCodeRightSidePanelPayment';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Modal from '@mui/material/Modal';
import StepLabel from '@mui/material/StepLabel';
import OrderedComponent from './OrderedComponent';

import visa from '../../assets/images/visa-card.png'
import successImg from '../../assets/images/success-circle.png'
import close from '../../assets/images/qr-close.png'
import { Box } from '@mui/material';
import { ROUTE_QRCODES } from '../../config/env_qrcodes';
import { PAGENAME_QRPAYMENT } from '../../config/env';

const steps = [
    'Shipping',
    'Payment',
    'Review',
];

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "588px",
    height: "365px",
    bgcolor: 'white',
    border: '1px solid E6E8EB',
    borderRadius: "13px",
    boxShadow: 24,
};

const QRCodesPlaceOrder = () => {
    const pageName = PAGENAME_QRPAYMENT;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div className="box-padding select-none" style={{}}>
            <div className="box">
                <div className='center border-bottom min-height-127px w-100'>
                    <div className='min-width-350px'>
                        <Stepper activeStep={2} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                </div>
                <div className='space-between'>
                    <div className='w-100'>

                        <OrderedComponent />
                        <div className='px-37px py-40px border-bottom '>
                            <h2 className='text-22px-600-black mb-4'> {t("QRCodesPayment.shippingAddress")}</h2>
                            <div className=" w-100 max-w-617px text-16px-400-black">
                                <div>Full Name</div>
                                <div>Full adress</div>
                                <div>City, Country</div>
                                <div>Postal Code</div>
                                <div>Phone number</div>
                                <div>Email Address</div>
                            </div>
                        </div>

                        <div className='px-37px py-40px flex'>
                            <div className='w-100'>
                                <h2 className='text-22px-600-black mb-4'> {t("QRCodesPayment.paymentMethod")}</h2>
                                <div className='flex align-items-center gap-2'>
                                    <img src={visa} alt="" width={36} height={33} />
                                    <div className='flex-col'>
                                        <span className='text-12px-700-black'>Visa ····1234 </span>
                                        <span className='text-8px-400-black'>7/723  1:21 PM</span>
                                    </div>
                                </div>
                            </div>
                            <div className='w-100'>
                                <h2 className='text-22px-600-black mb-4'> {t("QRCodesPayment.billingAddress")}</h2>
                                <div className='text-16px-400-black'>
                                    <div> Full Name</div>
                                    <div>  Full adress</div>
                                    <div>  City, Country</div>
                                    <div>  Postal Code</div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <QRCodeRightSidePanelPayment
                        buttonLabelOne={`${t("QRCodesPayment.placeOrder")}`}
                        buttonOneFunction={() => handleOpen()}
                    // buttonOneFunction={() => navigate('/qr-payment')}
                    />

                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div className='text-center relative flex-col center'>
                                <img src={close} alt="" height={28} width={28} style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}}  onClick={()=>handleClose()}/>
                                <img src={successImg} alt="" height={82} width={82} className='mt-38px mb-34px'/>
                                <h5 className='text-22px-600-black mb-35px'>{t("QRCodesPayment.ThankYouForYourOrder")}</h5>
                                <p className='text-14px-500-black mb-40px max-width-443px '>{t("QRCodesPayment.ThankYouForYourOrderMatter")}</p>
                                <button className='qr-continue-btn' onClick={() => navigate(ROUTE_QRCODES, { state: { returnedFrom: pageName }})}>{t("QRCodesPayment.returnHome")}</button>
                            </div>
                        </Box>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default QRCodesPlaceOrder