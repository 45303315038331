import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "./apiService";
import { SA_API_URL_ACCOUNTS_LIST_ALL, ACC_API_URL_ACCOUNTS_LIST_ALL, ACC_API_URL_ACC_GROUPS_LIST, SA_API_URL_ACC_GROUPS_LIST, SA_API_URL_PAYEE_LIST } from "../config/api_urls";
import { IAccListAllReq } from "../models/accountsInterfaces";
import { arrAccountUserIds } from "../config/env";
import { ISearchStaff } from "../models/groupInterfaces";
import { DH_API_URL_APPACCESSTOKEN, DH_API_URL_BASE_TEMPLATES, DH_API_URL_CREATE_PROJECT, DH_API_URL_LIST_ACCOUNT_PROJECTS, DH_API_URL_USERACCESSTOKEN, QRCODE_API_URL_ADD_CART_ITEM, QRCODE_API_URL_CART_COUNT, QRCODE_API_URL_DELETE_DH_PROJECT, QRCODE_API_URL_DOCUMENT_PREVIEW, QRCODE_API_URL_MODIFY_CART_ITEM, QRCODE_API_URL_REMOVE_CART_ITEM, QRCODE_API_URL_SHIPPING_ADDRESS, QRCODE_API_URL_VIEW_CART, VP_API_URL_PRICING,DH_API_URL_DOWNLOAD_TEMPLATE, DH_API_URL_CREATE_TEMPLATE_PROJECT, DH_API_URL_PUBLISH_TEMPLATE, DH_API_URL_LIST_ACCOUNT_TEMPLATES, DH_API_URL_APPLY_TEMPLATE_PROJECTS, DH_API_URL_DELETE_PRE_MADE_TEMPLATE } from "../config/env_qrcodes";
import { IdhCreateProjectPayload, IdhListProjectPayload, IAccIdPayload, IvpPricingPayload, IAddCartItem, IModifyCartItem, IRemoveCartItem, IVPDocumentPreview, IShippingAddress, IdhProjectPayload, IdhCreateTemplateProjectPayload, IdhPublishTemplatePayload, IApplyTemplateToProjects, IdhTemplatePayload } from "../models/qrCodeInterfaces";

// FUNCTION TO FETCH ACCOUNT LIST IN ORDERING QR CODE SECTION
export const fetchAccountListQR = createAsyncThunk(
    'qrCode/fetchAccountListQR',
    async (apiData: IAccListAllReq, thunkAPI: any) => {
        try {
            let url = (arrAccountUserIds.includes(apiData?.userRole ? apiData.userRole : 0) ? ACC_API_URL_ACCOUNTS_LIST_ALL : SA_API_URL_ACCOUNTS_LIST_ALL);
            if (apiData?.searchString && apiData.searchString.trim() != '') {
                url = url + '?search=' + apiData.searchString.trim();
            }
            const response = get(url);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// FUNCTION TO FETCH GROUP LIST CORRESPONDING TO ACCOUNT IN ORDERING QR CODE SECTION
export const fetchAccountGroupsListQR = createAsyncThunk(
    'qrCode/fetchAccountGroupsListQR',
    async (accGroupReq: {
        accountId: string,
        userRole: number,
    }, thunkAPI: any) => {
        try {
            const url = (arrAccountUserIds.includes(accGroupReq.userRole) ? ACC_API_URL_ACC_GROUPS_LIST : SA_API_URL_ACC_GROUPS_LIST) + '?hotel_id=' + accGroupReq.accountId;
            const response = get(url);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const fetchAccountStaffListQR = createAsyncThunk(
    'qrCode/fetchAccountStaffListQR',
    async ({ account_id, name }: ISearchStaff, thunkAPI: any) => {
        try {
            let url = SA_API_URL_PAYEE_LIST + '?account_id=' + account_id + '&name=' + name;
            const response = get(url);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


export const fetchDHUserAccessToken = createAsyncThunk(
    'qrCode/fetchDHUserAccessToken',
    async ({ accountId }: IAccIdPayload, thunkAPI: any) => {
        try {
          let url = DH_API_URL_USERACCESSTOKEN+'?account_id=' + accountId;
            const response = get(url);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
  
export const fetchDHAppAccessToken = createAsyncThunk(
    'qrCode/fetchDHAppAccessToken',
    async ({ accountId }: IAccIdPayload, thunkAPI: any) => {
        try {
          let url = DH_API_URL_APPACCESSTOKEN+'?account_id=' + accountId;
            const response = get(url);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
  
export const fetchDHBaseTemplates = createAsyncThunk(
    'qrCode/fetchDHBaseTemplates',
    async ({ accountId }: IAccIdPayload, thunkAPI: any) => {
        try {
          let url = DH_API_URL_BASE_TEMPLATES; 
            const response = get(url);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);   

// API TO CREATE NEW PROJECT FOR ANY GROUP OR STAFF  
export const createNewDHProject = createAsyncThunk(
    'qrCode/createDHProject',
    async ({ accountId, templateType, cardType, groupId, groupType }: IdhCreateProjectPayload, thunkAPI: any) => {
        try {
          let url = DH_API_URL_CREATE_PROJECT; 
          let projData = {
            account_id: accountId, 
            template_type: templateType, 
            card_type: cardType, 
            group_id: groupId,
            group_type: groupType 
          }
          const response = post(url,projData); 
          return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
); 

// API TO CREATE NEW PROJECT FOR NEW PRE-MADE TEMPLATE 
export const createNewDHTemplateProject = createAsyncThunk(
    'qrCode/createNewDHTemplateProject',
    async ({ accountId, cardType }: IdhCreateTemplateProjectPayload, thunkAPI: any) => {
        try {
          let url = DH_API_URL_CREATE_TEMPLATE_PROJECT; 
          let projData = {
            account_id: accountId, 
            card_type: cardType, 
          }
          const response = post(url,projData); 
          return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
); 

// API TO CREATE NEW PROJECT FOR NEW PRE-MADE TEMPLATE 
export const applyTemplateToProjects = createAsyncThunk(
    'qrCode/applyTemplateToProjects',
    async ({ accountId, projectId, templateType, groupType, groupId}: IApplyTemplateToProjects, thunkAPI: any) => {
        try {
          let url = DH_API_URL_APPLY_TEMPLATE_PROJECTS; 
          let projData = {
            account_id: accountId,
            project_id: projectId, 
            template_type: templateType,
            group_type: groupType, 
            group_id : groupId, 
        }
          const response = post(url,projData); 
          return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
); 

// API TO PUBLISH NEW PRE-MADE TEMPLATE 
export const publishNewDHTemplate = createAsyncThunk(
    'qrCode/publishNewDHTemplate',
    async ({ projectId }: IdhPublishTemplatePayload, thunkAPI: any) => {
        try {
          let url = DH_API_URL_PUBLISH_TEMPLATE; 
          let projData = {
            project_id: projectId 
          }
          const response = post(url,projData); 
          return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
); 

// API TO FETCH LIST OF CUSTOM DESIGNED DH PROJECTS 
export const fetchDHAccountProjects = createAsyncThunk(
    'qrCode/fetchDHAccountProjects',
    async ({ accountId, groupStaffType, groupStaffId }: IdhListProjectPayload, thunkAPI: any) => {
        try {
          let url = DH_API_URL_LIST_ACCOUNT_PROJECTS +'?account_id='+accountId+'&group_type='+groupStaffType+'&group_id='+groupStaffId; 
            const response = get(url); 
            return (await response).data; 
        } catch (error) { 
            return thunkAPI.rejectWithValue(error); 
        }
    }
); 

// API TO FETCH LIST OF PRE MADE DH TEMPLATES 
export const fetchDHAccountTemplates = createAsyncThunk(
    'qrCode/fetchDHAccountTemplates',
    async ({ accountId }: IAccIdPayload, thunkAPI: any) => {
        try {
          let url = DH_API_URL_LIST_ACCOUNT_TEMPLATES +'?account_id='+accountId; 
            const response = get(url); 
            return (await response).data; 
        } catch (error) { 
            return thunkAPI.rejectWithValue(error); 
        }
    }
); 

// THE FOLLOWING PRICING APIs ARE SAME - BUT WE NEED TO STORE THE RESPONSE IN DIFFERENT ARRAYS/OBJECTS
// SO SAME API WRITTEN IN THREE NAMES 
// VISTA PRINT API TO FETCH PRICING OF CARD TYPE 1 - DH_TEMPLATE_DESIGN_TYPE_POSTCARD
export const fetchVPPricingOne = createAsyncThunk(
    'qrCode/fetchVPPricingOne',
    async ({ cartType }: IvpPricingPayload, thunkAPI: any) => {
        try {
          let url = VP_API_URL_PRICING +'?card_type='+cartType; 
            const response = get(url); 
            return (await response).data; 
        } catch (error) { 
            return thunkAPI.rejectWithValue(error); 
        }
    }
); 

// VISTA PRINT API TO FETCH PRICING OF CARD TYPE 2 - DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD 
export const fetchVPPricingTwo = createAsyncThunk(
    'qrCode/fetchVPPricingTwo',
    async ({ cartType }: IvpPricingPayload, thunkAPI: any) => {
        try {
          let url = VP_API_URL_PRICING +'?card_type='+cartType; 
            const response = get(url); 
            return (await response).data; 
        } catch (error) { 
            return thunkAPI.rejectWithValue(error); 
        }
    }
); 

// VISTA PRINT API TO FETCH PRICING OF CARD TYPE 3 - DH_TEMPLATE_DESIGN_TYPE_TABLETENT 
export const fetchVPPricingThree = createAsyncThunk(
    'qrCode/fetchVPPricingThree',
    async ({ cartType }: IvpPricingPayload, thunkAPI: any) => {
        try {
          let url = VP_API_URL_PRICING +'?card_type='+cartType; 
            const response = get(url); 
            return (await response).data; 
        } catch (error) { 
            return thunkAPI.rejectWithValue(error); 
        }
    }
); 

// AP TO FETCH CART DETAILS  
export const fetchCartDetails = createAsyncThunk(
    'qrCode/fetchCartDetails',
    async ( { accountId } : IAccIdPayload, thunkAPI: any) => {
        try {
          let url = QRCODE_API_URL_VIEW_CART; // ?account_id=2 ACCOUNT ID NOT REQUIRED NOW 
            const response = get(url); 
            return (await response).data; 
        } catch (error) { 
            return thunkAPI.rejectWithValue(error); 
        }
    }
); 

// API TO GET DOCUMENT URL FROM VISTA PRINT   
export const fetchDocumentPreview = createAsyncThunk(
    'qrCode/fetchDocumentPreview',
    async ( { cartId, documentRefUrl, sku } : IVPDocumentPreview, thunkAPI: any) => {
        try {
          let url = QRCODE_API_URL_DOCUMENT_PREVIEW+'?cart_id='+cartId.toString()+'&sku='+sku+'&document_ref_url='+documentRefUrl;  
            const response = get(url); 
            return (await response).data; 
        } catch (error) { 
            return thunkAPI.rejectWithValue(error); 
        }
    }
); 

// API TO FETCH COUNT OF CART ITEMS   
export const fetchCartCount = createAsyncThunk(
    'qrCode/cartCount',
    async ( { accountId } : IAccIdPayload, thunkAPI: any) => {
        try {
          let url = QRCODE_API_URL_CART_COUNT;  
            const response = get(url); 
            return (await response).data; 
        } catch (error) { 
            return thunkAPI.rejectWithValue(error); 
        }
    }
); 

// API TO ADD ITEMS TO CART  
export const addCartItem = createAsyncThunk(
    'qrCode/addCartItem',
    async ( {accountId, projectId, groupType, groupId, sku, quantity, cardType} : IAddCartItem, thunkAPI: any) => {
        try {
            let url = QRCODE_API_URL_ADD_CART_ITEM;  
            let cartItemData = {
                account_id: accountId, 
                project_id: projectId, 
                group_type: groupType, 
                group_id: groupId,
                sku: sku,
                quantity: quantity, 
                card_type: cardType
            }
            const response = post(url,cartItemData); 
            return (await response).data; 
        } catch (error) { 
            return thunkAPI.rejectWithValue(error); 
        }
    }
); 

// API TO MODIFY CART ITEM  
export const modifyCartItem = createAsyncThunk(
    'qrCode/modifyCartItem',
    async ( {cartId, sku, quantity} : IModifyCartItem, thunkAPI: any) => {
        try {
            let url = QRCODE_API_URL_MODIFY_CART_ITEM;  
            let cartItemData = {
                cart_id: cartId, 
                sku: sku, 
                quantity: quantity, 
            }
            const response = post(url,cartItemData); 
            return (await response).data; 
        } catch (error) { 
            return thunkAPI.rejectWithValue(error); 
        }
    }
); 
 
 // API TO REMOVE CART ITEM  
 export const removeCartItem = createAsyncThunk(
    'qrCode/removeCartItem',
    async ( {cartId} : IRemoveCartItem, thunkAPI: any) => {
        try {
            let url = QRCODE_API_URL_REMOVE_CART_ITEM;  
            let cartItemData = {
                cart_id: cartId
            }
            const response = post(url,cartItemData); 
            return (await response).data; 
        } catch (error) { 
            return thunkAPI.rejectWithValue(error); 
        }
    }
); 

// API TO TAKE DELIVERY OPTIONS AND ITS PRICE FROM VISTA PRINT 
export const fetchDeliveryOptions = createAsyncThunk(
    'qrCode/fetchDeliveryOptions',
    async ( {cartIds, address, addressTwo, city, state, postalCode} : IShippingAddress, thunkAPI: any) => {
        try {
            let url = QRCODE_API_URL_SHIPPING_ADDRESS; 
            let shippingData = {
                cart_id: cartIds, 
                address_line_1: address, 
                address_line_2: addressTwo,
                city: city, 
                state: state, 
                zip_code: postalCode,
            }
            const response = post(url,shippingData); 
            return (await response).data; 
        } catch (error) { 
            return thunkAPI.rejectWithValue(error); 
        }
    }
); 

export const downloadQRTemplate = createAsyncThunk(
    'qrCode/downloadQRTemplate',
    async (project_id:string, thunkAPI: any) => {
        try {
            let url = DH_API_URL_DOWNLOAD_TEMPLATE + project_id +'/';
            const response = get(url);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// API TO DELETE DH PROJECT 
export const deleteDHProject = createAsyncThunk(
    'qrCode/deleteDHProject',
    async ( {projectId} : IdhProjectPayload, thunkAPI: any) => {
        try {
            let url = QRCODE_API_URL_DELETE_DH_PROJECT+projectId+"/"; 
            let objData = { }
            const response = post(url,objData); 
            return (await response).data; 
        } catch (error) { 
            return thunkAPI.rejectWithValue(error); 
        }
    }
); 

// API TO DELETE DH PRE MADE TEMPLATE 
export const deleteDHPreMadeTemplate = createAsyncThunk(
    'qrCode/deleteDHPreMadeTemplate',
    async ( {templateId} : IdhTemplatePayload, thunkAPI: any) => {
        try {
            let url = DH_API_URL_DELETE_PRE_MADE_TEMPLATE+templateId+"/"; 
            let objData = { }
            const response = post(url,objData); 
            return (await response).data; 
        } catch (error) { 
            return thunkAPI.rejectWithValue(error); 
        }
    }
); 


    
     

