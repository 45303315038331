import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAccountSettingsForm } from '../../models/settingsInterfaces';
import { getAccountsettings, updateDefaultSettings } from '../../services/settingsServices';
import { RootState } from '../../store';
import { t } from 'i18next';
import { setNotification } from '../../services/apiService';



export interface DataState {
  accountSettingsForm: IAccountSettingsForm;
  loading: 'idle' | 'loading' | 'succeeded' | 'error'|'updated';
}

const initialState: DataState = {
  accountSettingsForm: {} as IAccountSettingsForm,
  loading: 'idle',
};

export const accountSettingsFormDataSlice = createSlice({
  name: 'hotelFormSettingsData',
  initialState,
  reducers: {
    setAccountSettingsForm: (state, action: PayloadAction<any>) => {
      state.accountSettingsForm = action.payload;
    },
  
  },
  extraReducers: (builder) => {
    // from admin settings
    builder.addCase(getAccountsettings.pending, (state) => {
      state.accountSettingsForm = {} as IAccountSettingsForm;
      state.loading = 'loading';
    });
    builder.addCase(getAccountsettings.fulfilled, (state, action) => {
      const m = action.payload as IAccountSettingsForm;
      state.accountSettingsForm = m;
      state.loading = 'succeeded';
    });
    builder.addCase(getAccountsettings.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      state.accountSettingsForm = {} as IAccountSettingsForm;
    });

    builder.addCase(updateDefaultSettings.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(updateDefaultSettings.fulfilled, (state, action) => {
      console.log('updateDefaultSettings.fulfilled: ');
      state.loading = 'updated';

      setNotification(
        t("projectName"),
        t("messages.settingUpdated"),
        "success",
        "updateDefaultSettings"
      )
    });
    builder.addCase(updateDefaultSettings.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      console.log('payload2: ', payload);
    });
  },
});

// Action creators are generated for each case reducer function
export const { setAccountSettingsForm } =
accountSettingsFormDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAccountSettingsFormDataSlice = (state: RootState) => state.accountSettingsFromData

export const accountSettingsFormDataReducer = accountSettingsFormDataSlice.reducer;
