import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { Badge, Box, IconButton, Modal, radioClasses } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import PhoneInput from "react-phone-input-2";

import Tovalogo from "../../assets/images/tovalogo.svg";
import grocery from "../../assets/images/grocery.svg";
import activetik from "../../assets/images/activetik.svg";
import phoneIcon from "../../assets/images/phoneIcon.svg";
import mailIcon from "../../assets/images/mailIcon.svg";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link } from "react-router-dom";

import { isAuthenticated } from "../../services/authenticationService";
import { setNotification } from "../../services/apiService";
import { newAccountCreate } from "../../services/accountServices";
import {
  createNewGroup,
  fetchAccountDetails,
} from "../../services/accountDetailsServices";
import { fetchCartCount } from '../../services/qrCodesServices';

import {
  getAdminUserRole,
  getFilterAccountId,
  getFilterAccountName,
  removeTokens,
} from "../../services/localStorage";

import { filterDigitsOnly } from "../../config/common_functions";
import { GROUP_NAME_EXISTS } from "../../config/apiResponseCodes";
import {
  ROUTE_DASHBOARD,
  ROUTE_ACCOUNTS_LIST,
  ROUTE_TIPS_LIST,
  ROUTE_PAYMENTS_LIST,
  ROUTE_COMMISSIONS_LIST,
  ROUTE_SETTINGS,
  ROUTE_TERMS,
  ROUTE_SA_USERS,
  ROUTE_HOTEL_USERS,
  ROUTE_SA_LOGIN,
  ROUTE_ACCOUNTS_LOGIN,
  ROUTE_ACCOUNT_GROUP,
  BUSINESS_CLASSIFICATION_LLC,
  ROUTE_ACCOUNT_WITHHOLDINGS,
  PHONEINPUT_COUNTRIES,
  PHONEINPUT_COUNTRYCODE_EDITABLE,
  US_STATES_ARRAY,
  USER_ROLE_ACCOUNT_MANAGER,
  USER_ROLE_ACCOUNT_USER
} from "../../config/env";
import { ROUTE_QRC_CHECKOUT, ROUTE_QRC_PURCHASE_HISTORY } from "../../config/env_qrcodes";
import {
  checkMenuPagePermission,
  checkActionPermission,
  MENU_ACCOUNTS,
  MENU_GROUPS,
  MENU_PAYMENTS,
  MENU_ADMIN,
  PERM_ADD_NEW_GROUP,
  MENU_DASHBOARD,
} from "../../config/permissions";
import {
  USER_ROLE_ADMIN_SUPERADMIN,
  USER_ROLE_ADMIN_ADMIN,
  PAGENAME_TIPS,
  PAGENAME_COMMISSIONS,
  PAGENAME_ACCOUNTUSERS,
  PAGENAME_TERMS,
  PAGENAME_SETTINGS,
  PAGENAME_PAYOUTS,
  PAGENAME_ADMINUSERS,
  arrAccountUserIds
} from "../../config/env";


import { IAccountGroupsList } from "../../models/allAccountListInterface";
import { ICreateNewAccount } from "../../models/accountsInterfaces";
import {
  IAccountDetails,
  INewAccountGroup,
} from "../../models/accountDetailsInterfaces";

import { useAppDispatch, useAppSelector } from "../../helpers/hook";

import { Textfield } from "../TextField";
import FilterAccount from "../FilterAccount";
import { SelectField } from "../SelectField";
import { ButtonModal } from "../ButtonGroups/ButtonModal";
import { ButtonInLine } from "../ButtonGroups/ButtonInLine";

import { selectAccountsListDataSlice } from "../../pages/AccountsList/accountsListDataSlice";
import {
  selectCommonSlice,
  resetReloadGroupSubMenu,
} from "../../pages/CommonSlice/commonSlice";
import {
  selectAccountDetailsSlice,
  resetLoadingForNavbar,
} from "../../pages/AccountDetails/accountDetailsSlice";
import { selectQrcodesDataSlice, changeQrCodePageAccountId } from "../../pages/QRCodes/QRCodesSlice";

export default function Navbar() {
  const adminUserRole = getAdminUserRole();
  const adminUserName = localStorage.getItem("adminUserName");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const accountId = getFilterAccountId();
  const { accountDetails, loadingForNavbar } = useAppSelector(
    selectAccountDetailsSlice
  );

  const { reloadGroupSubMenu } =
    useAppSelector(selectCommonSlice);
  const { loading } = useAppSelector(selectAccountsListDataSlice);
  const { cartCount } = useAppSelector(selectQrcodesDataSlice);

  const [stateCartCount, setStateCartCount] = useState(0);

  useEffect(() => {
    if (loading == "accountCreated") {
      setShowAddAccount(false);
      setaddAccCount((addAccCount + 2));
    }
  }, [loading]);

  useEffect(() => {
    if (cartCount) {
      setStateCartCount(cartCount);
    }
    else {
      setStateCartCount(0);
    }
  }, [cartCount]);

  useEffect(() => {
    if (loadingForNavbar == "groupCreated") {
      dispatch(resetReloadGroupSubMenu(reloadGroupSubMenu + 2));
      dispatch(resetLoadingForNavbar());
    } else if (
      loadingForNavbar === "groupCreationFailed" ||
      loadingForNavbar === GROUP_NAME_EXISTS
    ) {
      dispatch(resetLoadingForNavbar());
    }
  }, [loadingForNavbar]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 805,
    maxHeight: 734,
    // overflow: "scroll",
    height: "100%",
    display: "block",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "13px",
    boxShadow: 24,
  };
  const groupStyle = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "13px",
    boxShadow: 24,
  };

  // COMMENTED FOR LATER USE
  // const arr_NoShowPages = ["/", "/login"];
  // const [pName, setPName] = useState<string>(window.location.pathname);

  const [pathText, setPathText] = useState(
    "/" + window.location.pathname.split("/").pop()
  );

  const [selectedAccounts, setSelectedAccounts] = useState("");
  const [accountGroupsList, setAccountGroupsList] = useState<
    IAccountGroupsList[]
  >([]);


  const [selectedAccountName, setSelectedAccountName] = useState("");

  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [selectedGroupName, setSelectedGroupName] = useState("");

  const [hideDropdown, setHideDropdown] = useState(false);
  const [showAddAccount, setShowAddAccount] = useState(false);
  const [addGroup, setAddGroup] = useState(false);

  // FORM GROUP CREATE VARIABLES
  const [newGroupName, setNewGroupName] = useState("");
  const [newGroupNameError, setNewGroupNameError] = useState(false);

  // FORM ACCOUNT CREATE VARIABLES
  const [businessName, setBusinessName] = useState("");
  const [businessClassification, setBusinessClassification] = useState(
    BUSINESS_CLASSIFICATION_LLC.toString()
  );
  const [companyName, setCompanyName] = useState("");
  const [numRooms, setNumRooms] = useState("");
  const [numEmployees, setNumEmployees] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [formAddress, setFormAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [contactFirstName, setContactFirstName] = useState("");
  const [contactLastName, setContactLastName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactJobTitle, setContactJobTitle] = useState("");
  const [createUser, setCreateUser] = useState(false);

  const [addAccCount, setaddAccCount] = useState(0);
  const [cartCountCalled, setCartCountCalled] = useState(0);

  const businessClassList = [
    {
      id: BUSINESS_CLASSIFICATION_LLC,
      value: t("businessDetails.businessClassificationLLC"),
    },
  ];

  const callCartCount = () => {
    if(Number(adminUserRole) === Number(USER_ROLE_ACCOUNT_MANAGER) || Number(adminUserRole) === Number(USER_ROLE_ACCOUNT_USER)) {
      dispatch(fetchCartCount({ accountId: "" })); // ACCOUNT ID NOT REQUIRED NOW
    }
  }

  useEffect(() => {
    fetchAll();
    callCartCount();  
  }, [dispatch, accountId]);

  useEffect(() => {
    if(selectedAccounts) {
      dispatch(changeQrCodePageAccountId(selectedAccounts));  
    } 
  }, [selectedAccounts]);

  useEffect(() => {
    if (accountGroupsList && accountGroupsList.length > 0) {
      setSelectedGroupId(accountGroupsList[0].id.toString());
      setSelectedGroupName(accountGroupsList[0].name);
      if (cartCountCalled == 0) {
        setCartCountCalled(1);
        callCartCount();  
      }
    }
  }, [accountGroupsList]);

  const onHideDropDown = () => {
    setHideDropdown(true);
    setTimeout(() => {
      setHideDropdown(false);
    }, 500);
  };

  const handleLogout = () => {
    setPathText("");
    if (arrAccountUserIds.includes(Number(adminUserRole))) {
      removeTokens();
      window.location.href = ROUTE_ACCOUNTS_LOGIN;
    }
    else {
      removeTokens();
      window.location.href = ROUTE_SA_LOGIN;
    }
  };

  const setGroupSelection = (groupItem: IAccountGroupsList) => {
    if (groupItem && groupItem.id && groupItem.name) {
      setSelectedGroupId(groupItem.id.toString());
      setSelectedGroupName(groupItem.name);
    }
  };

  const onHandleSave = (e) => {
    e.preventDefault();
    if (businessPhone.trim().length < 9) {
      setNotification(
        t("projectName"),
        t("messages.addAccountPhoneError"),
        "failed",
        "addAccount"
      );
    } else {
      const req: ICreateNewAccount = {
        business_classification: businessClassification,
        hotel_name: businessName,
        company_name: companyName,
        number_of_rooms: numRooms,
        number_of_employees: numEmployees,
        mobile_number: businessPhone,
        address: formAddress,
        city: city,
        state: state,
        zip: zipCode,
        first_name: contactFirstName,
        last_name: contactLastName,
        communication_email: contactEmail,
        job_title: contactJobTitle,
        create_user: createUser,
      };
      //newAccountCreate IS SAME AS ACCOUNT CREATE API IN ACCOUNTLIST PAGE (UPDATE BOTH SECTION, IF ANY CHANGES REQUIRED )
      dispatch(newAccountCreate(req));
    }
  };

  const resetAddAccountForm = () => {
    setBusinessName("");
    setBusinessClassification(BUSINESS_CLASSIFICATION_LLC.toString());
    setCompanyName("");
    setNumRooms("");
    setNumEmployees("");
    setBusinessPhone("");
    setFormAddress("");
    setCity("");
    setState("");
    setZipCode("");
    setContactFirstName("");
    setContactLastName("");
    setContactEmail("");
    setContactJobTitle("");
    setaddAccCount(0);
  };

  const onSetShowAddAccount = () => {
    resetAddAccountForm();
    setShowAddAccount(true);
  };

  const onCreateNewGroup = (e) => {
    e.preventDefault();
    if (newGroupName.trim() == "") {
      setNewGroupNameError(true);
    } else if (accountId) {
      setNewGroupNameError(false);
      let newGroup: INewAccountGroup = {
        hotelId: Number(accountId),
        name: newGroupName,
        userRole: Number(adminUserRole),
      };
      dispatch(createNewGroup(newGroup));
    }
    setAddGroup(false);
  };
  const onSetAddGroup = (e) => {
    setAddGroup(true);
    setNewGroupName("");
  };

  const fetchAll = () => {
    if (Number.isFinite(Number(accountId)) && Number(accountId)) {
      const req: IAccountDetails = {
        accountId: Number(accountId),
        userRole: Number(adminUserRole),
      };
      dispatch(fetchAccountDetails(req));
    }
  };

  useEffect(() => {
    if (reloadGroupSubMenu > 0) {
      fetchAll();
    }
  }, [reloadGroupSubMenu]);

  const numberOnly = (numVal: string, fieldType: string) => {
    let newValue = filterDigitsOnly(numVal);
    if (fieldType == "numRooms") {
      setNumRooms(newValue);
    } else if (fieldType == "numEmployees") {
      setNumEmployees(newValue);
    }
  };

  return isAuthenticated() ? (
    <>
      <div className="nav-header">
        <div>
          <img src={mailIcon} alt="" className="me-1" />{" "}
          {t("accNavigation.mailInfo")}
        </div>
        <div>
          <img src={phoneIcon} alt="" className="mb-2px me-1" />{" "}
          {t("accNavigation.phoneInfo1")}
        </div>
        <div>{t("accNavigation.phoneInfo2")}</div>
      </div>
      <div className="nav">
        <div className="center gap-5">
          <Link to={ROUTE_ACCOUNTS_LIST} className="nav-a" onClick={(e) => setPathText(ROUTE_ACCOUNTS_LIST)}>
            <img
              src={Tovalogo}
              alt={`${t("navigation.tovaLogo")}`}
              height="18.8px"
            />
          </Link>

          <div className="ps-3 pe-2 pointer pt-2px" style={{ display: "flex" }}>
            {checkMenuPagePermission(adminUserRole, MENU_ACCOUNTS) && (
              <>
                <li
                  className={
                    location.pathname === ROUTE_ACCOUNTS_LIST ? "active" : ""
                  }
                >
                  <Link to={ROUTE_ACCOUNTS_LIST} className="nav-a" onClick={(e) => setPathText(ROUTE_ACCOUNTS_LIST)}>
                    {t("navigation.accounts")}
                  </Link>
                </li>
              </>
            )}
          </div>
          <div className="ps-3 pe-2 pointer pt-2px" style={{ display: "flex" }}>
            {checkMenuPagePermission(adminUserRole, MENU_DASHBOARD) && (
              <>
                <li
                  className={
                    location.pathname === ROUTE_DASHBOARD ? "active" : ""
                  }
                >
                  <Link to={ROUTE_DASHBOARD} className="nav-a" onClick={(e) => setPathText(ROUTE_DASHBOARD)}>
                    {t("navigation.dashboard")}
                  </Link>
                </li>
              </>
            )}
          </div>
          <ul className="center pl-50px" style={{ margin: "0px auto" }}>
            <div
              className="menuDropdown ps-3 pe-2 active pointer pt-2px"
              style={{ display: "flex" }}
            >
              <FilterAccount
                setAccountIds={setSelectedAccounts}
                noHeader={true}
                navigation={true}
                setAccountGroupsList={setAccountGroupsList}
                reloadGroupSubMenu={reloadGroupSubMenu}
                addButtonFunction={(e) => onSetShowAddAccount()}
                onAddAccountDispatch={addAccCount}
                changeSelectedAccountName={setSelectedAccountName}
              />
              <span>
                <ArrowDropDownIcon className="icon " />
              </span>
            </div>





            {checkMenuPagePermission(adminUserRole, MENU_GROUPS) && (
              <>
                <li
                  className={
                    location.pathname ===
                      ROUTE_ACCOUNT_GROUP + "/" + selectedGroupId
                      ? "active"
                      : ""
                  }
                >
                  <div className="menuDropdown mt-2px">
                    <div className="nav-a">
                      <span className="ml-10px">
                        {pathText == ROUTE_ACCOUNT_GROUP + "/" + selectedGroupId
                          ? t("navigation.group") + selectedGroupName
                          : t("navigation.groups")}
                      </span>
                      <span>
                        <ArrowDropDownIcon className="icon" />
                      </span>
                    </div>

                    <div
                      className={
                        hideDropdown
                          ? "hide"
                          : "menuDropdown-body border-none pb-5"
                      }
                    >
                      <div
                        className={
                          hideDropdown
                            ? "hide"
                            : "menuDropdown-content border-white menuAccPosition"
                        }
                      >
                        <div className="flex w-100">
                          <div className="p-14px border-right w-100 max-width-189px">
                            <h1 className="fontsize-14 font-500 mb-7px text-black">
                              {t("navigation.groups")}
                            </h1>
                            <div className="flex-col gap-5">
                              <span className="acc-font ">
                                {t("navigation.groupsSubText", {
                                  account: (selectedAccountName && selectedAccountName.length > 1 ? selectedAccountName : getFilterAccountName())
                                })}
                              </span>
                              {checkActionPermission(
                                adminUserRole,
                                PERM_ADD_NEW_GROUP
                              ) && (
                                  <ButtonInLine
                                    buttonLabel={`+ ${t(
                                      "accountDetails.addGroup"
                                    )}`}
                                    className=" filter-add-btn "
                                    onClick={(e) => onSetAddGroup(e)}
                                  />
                                )}
                            </div>
                          </div>

                          <form className="select-container p-14px w-100">
                            <div>
                              <label
                                className={`flex gap-2 link-h ps-2 mb-1`}
                                style={{
                                  letterSpacing: "0px",
                                  textTransform: "uppercase",
                                }}
                              >
                                {t("navigation.groups")} (
                                {accountGroupsList.length})
                              </label>
                            </div>

                            <div className=" ps-2 pe-1">
                              {/* <div className=""> */}
                              {/* In case scrolling is required */}
                              <div className="multiselect max-height-141px">
                                {accountGroupsList.map((groupData) => (
                                  <>
                                    <Link
                                      to={
                                        ROUTE_ACCOUNT_GROUP +
                                        "/" +
                                        groupData.id.toString()
                                      }
                                      onClick={(e) => {
                                        onHideDropDown();
                                        setGroupSelection(groupData);
                                      }}
                                      className={
                                        location.pathname ===
                                          ROUTE_ACCOUNT_GROUP +
                                          "/" +
                                          selectedGroupId &&
                                          selectedGroupId ==
                                          groupData.id.toString()
                                          ? "active-link"
                                          : ""
                                      }
                                    >
                                      <div
                                        onClick={(e) =>
                                          setPathText(
                                            ROUTE_ACCOUNT_GROUP +
                                            "/" +
                                            groupData.id.toString()
                                          )
                                        }
                                        className="flex gap-2"
                                      >
                                        {groupData.name}{" "}
                                        {location.pathname ===
                                          ROUTE_ACCOUNT_GROUP +
                                          "/" +
                                          selectedGroupId &&
                                          selectedGroupId ==
                                          groupData.id.toString() && (
                                            <>
                                              <img src={activetik} alt="tik" />
                                            </>
                                          )}
                                      </div>
                                    </Link>
                                  </>
                                ))}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </>
            )}

            {checkMenuPagePermission(adminUserRole, MENU_PAYMENTS) && (
              <>
                <li
                  className={
                    location.pathname === ROUTE_TIPS_LIST ||
                      location.pathname === ROUTE_PAYMENTS_LIST ||
                      location.pathname === ROUTE_COMMISSIONS_LIST
                      ? "active"
                      : ""
                  }
                >
                  <div className="menuDropdown mt-2px">
                    <div className="nav-a">
                      <span className="ml-10px">
                        {" "}
                        {t("navigation.payments") +
                          (pathText == ROUTE_TIPS_LIST
                            ? ": " + t("navigation.paymentsTips")
                            : pathText == ROUTE_PAYMENTS_LIST
                              ? ": " + t("navigation.paymentsPayouts")
                              : pathText == ROUTE_COMMISSIONS_LIST
                                ? ": " + t("navigation.paymentsCommission")
                                : "")}
                      </span>
                      <span>
                        <ArrowDropDownIcon className="icon" />
                      </span>
                    </div>
                    <div
                      className={
                        hideDropdown
                          ? "hide"
                          : "menuDropdown-body border-none pb-5"
                      }
                    >
                      <div
                        className={
                          hideDropdown
                            ? "hide"
                            : "menuDropdown-content border-white "
                        }
                      >
                        <div className="link-h">
                          {t("navigation.paymentsHeading")}
                        </div>
                        {checkMenuPagePermission(adminUserRole, PAGENAME_TIPS) && (
                          <>
                            <Link
                              to={ROUTE_TIPS_LIST}
                              className={
                                location.pathname === ROUTE_TIPS_LIST
                                  ? "active-link"
                                  : ""
                              }
                              onClick={(e) => onHideDropDown()}
                            >
                              <div
                                onClick={(e) => setPathText(ROUTE_TIPS_LIST)}
                                className="flex gap-2"
                              >
                                {t("navigation.paymentsTips")}
                                {location.pathname === ROUTE_TIPS_LIST && (
                                  <>
                                    <img src={activetik} alt="tik" />
                                  </>
                                )}
                              </div>
                            </Link>
                          </>
                        )}

                        {checkMenuPagePermission(
                          adminUserRole,
                          PAGENAME_COMMISSIONS
                        ) && (
                            <>
                              <Link
                                to={ROUTE_COMMISSIONS_LIST}
                                className={
                                  location.pathname === ROUTE_COMMISSIONS_LIST
                                    ? "active-link"
                                    : ""
                                }
                                onClick={(e) => onHideDropDown()}
                              >
                                <div
                                  onClick={(e) =>
                                    setPathText(ROUTE_COMMISSIONS_LIST)
                                  }
                                  className="flex gap-2"
                                >
                                  {t("navigation.paymentsCommission")}
                                  {location.pathname ===
                                    ROUTE_COMMISSIONS_LIST && (
                                      <>
                                        <img src={activetik} alt="tik" />
                                      </>
                                    )}
                                </div>
                              </Link>
                            </>
                          )}

                        {checkMenuPagePermission(
                          adminUserRole,
                          PAGENAME_PAYOUTS
                        ) && (
                            <>
                              <Link
                                to={ROUTE_PAYMENTS_LIST}
                                className={
                                  location.pathname === ROUTE_PAYMENTS_LIST
                                    ? "active-link"
                                    : ""
                                }
                                onClick={(e) => onHideDropDown()}
                              >
                                <div
                                  onClick={(e) =>
                                    setPathText(ROUTE_PAYMENTS_LIST)
                                  }
                                  className="flex gap-2"
                                >
                                  {t("navigation.paymentsPayouts")}
                                  {location.pathname === ROUTE_PAYMENTS_LIST && (
                                    <>
                                      <img src={activetik} alt="tik" />
                                    </>
                                  )}
                                </div>
                              </Link>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </li>
              </>
            )}

            {checkMenuPagePermission(adminUserRole, MENU_ADMIN) && (
              <>
                <li
                  className={
                    location.pathname === ROUTE_HOTEL_USERS ||
                      location.pathname === ROUTE_SA_USERS ||
                      location.pathname === ROUTE_ACCOUNT_WITHHOLDINGS ||
                      location.pathname === ROUTE_TERMS || 
                      location.pathname === ROUTE_QRC_PURCHASE_HISTORY
                      ? "active"
                      : ""
                  }
                >
                  <div className="menuDropdown mt-2px">
                    <div className="nav-a">
                      <span className="ml-10px">
                        {t("navigation.admin") +
                          (pathText == ROUTE_HOTEL_USERS ? ": " + t("navigation.accountUsers")
                            : pathText == ROUTE_SA_USERS ? ": " + t("navigation.adminUsers") 
                            : pathText == ROUTE_TERMS ? ": " + t("navigation.TermsAndConditions") 
                            : pathText == ROUTE_ACCOUNT_WITHHOLDINGS ? ": " +t("navigation.withholdings")
                            : pathText == ROUTE_QRC_PURCHASE_HISTORY ? ": " +t("navigation.qrCodesOrderHistory")
                            : "")}
                      </span>
                      <span>
                        <ArrowDropDownIcon className="icon" />
                      </span>
                    </div>
                    <div
                      className={
                        hideDropdown
                          ? "hide"
                          : "menuDropdown-body border-none pb-5"
                      }
                    >
                      <div
                        className={
                          hideDropdown
                            ? "hide"
                            : "menuDropdown-content border-white min-width-178px pt-5px"
                        }
                      >
                        <div className="link-h">
                          {t("navigation.adminHeading")}
                        </div>

                        {/* *********************** PURCHASE HISTORY *********************** */}
                        {(Number(adminUserRole) !== Number(USER_ROLE_ACCOUNT_USER)) && (
                          <>
                            <Link
                              to={ROUTE_QRC_PURCHASE_HISTORY}
                              className={
                                location.pathname === ROUTE_QRC_PURCHASE_HISTORY
                                  ? "active-link"
                                  : ""
                              }
                              onClick={(e) => onHideDropDown()}
                            >
                              <div
                                onClick={(e) => setPathText(ROUTE_QRC_PURCHASE_HISTORY)}
                                className="flex gap-2"
                              >
                                {t("navigation.qrCodesOrderHistory")}
                                {location.pathname === ROUTE_QRC_PURCHASE_HISTORY && (
                                  <>
                                    <img src={activetik} alt="tik" />
                                  </>
                                )}
                              </div>
                            </Link>
                          </>
                        )}

                        {/* *********************** ACCOUNT USERS *********************** */}
                        {checkMenuPagePermission(
                          adminUserRole,
                          PAGENAME_ACCOUNTUSERS
                        ) && (
                            <>
                              <Link
                                to={ROUTE_HOTEL_USERS}
                                className={
                                  location.pathname === ROUTE_HOTEL_USERS
                                    ? "active-link"
                                    : ""
                                }
                                onClick={(e) => onHideDropDown()}
                              >
                                <div
                                  onClick={(e) => setPathText(ROUTE_HOTEL_USERS)}
                                  className="flex gap-2"
                                >
                                  {t("navigation.accountUsers")}
                                  {location.pathname === ROUTE_HOTEL_USERS && (
                                    <>
                                      <img src={activetik} alt="tik" />
                                    </>
                                  )}
                                </div>
                              </Link>
                            </>
                          )}

                         {/* *********************** WITHHOLDINGS *********************** */}
                         <>
                          <Link
                            to={ROUTE_ACCOUNT_WITHHOLDINGS}
                            className={
                              location.pathname === ROUTE_ACCOUNT_WITHHOLDINGS
                                ? "active-link"
                                : ""
                            }
                            onClick={(e) => onHideDropDown()}
                          >
                            <div
                              onClick={(e) =>
                                setPathText(ROUTE_ACCOUNT_WITHHOLDINGS)
                              }
                              className="flex gap-2"
                            >
                              {t("navigation.withholdings")}
                              {location.pathname ===
                                ROUTE_ACCOUNT_WITHHOLDINGS && (
                                  <>
                                    <img src={activetik} alt="tik" />
                                  </>
                                )}
                            </div>
                          </Link>
                        </>

                        {Number(adminUserRole)!=USER_ROLE_ACCOUNT_MANAGER && Number(adminUserRole)!=  USER_ROLE_ACCOUNT_USER && <div className="border-bottom mt-10px mb-7px"></div>}

                        {/* *********************** TERMS *********************** */}
                        {checkMenuPagePermission(adminUserRole, PAGENAME_TERMS) && (
                          <>
                            <Link
                              to={ROUTE_TERMS}
                              className={
                                location.pathname === ROUTE_TERMS
                                  ? "active-link"
                                  : ""
                              }
                              onClick={(e) => onHideDropDown()}
                            >
                              <div
                                onClick={(e) => setPathText(ROUTE_TERMS)}
                                className="flex gap-2"
                              >
                                {t("navigation.TermsAndConditions")}
                                {location.pathname === ROUTE_TERMS && (
                                  <>
                                    <img src={activetik} alt="tik" />
                                  </>
                                )}
                              </div>
                            </Link>
                          </>
                        )}

                       
                        
                        {/* *********************** ADMIN USERS *********************** */}
                        {checkMenuPagePermission(
                          adminUserRole,
                          PAGENAME_ADMINUSERS
                        ) && (
                            <>
                              <Link
                                to={ROUTE_SA_USERS}
                                className={
                                  location.pathname === ROUTE_SA_USERS
                                    ? "active-link"
                                    : ""
                                }
                                onClick={(e) => onHideDropDown()}
                              >
                                <div
                                  onClick={(e) => setPathText(ROUTE_SA_USERS)}
                                  className="flex gap-2"
                                >
                                  {t("projectName") + " " + t("navigation.adminUsers")}
                                  {location.pathname === ROUTE_SA_USERS && (
                                    <>
                                      <img src={activetik} alt="tik" />
                                    </>
                                  )}
                                </div>
                              </Link>
                            </>
                          )}


                        
                      </div>
                    </div>
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>

        <div className="flex-end gap-3 center">
          {(Number(adminUserRole) === Number(USER_ROLE_ACCOUNT_MANAGER) || Number(adminUserRole) === Number(USER_ROLE_ACCOUNT_USER)) && <>
            {
              stateCartCount && stateCartCount > 0 ?
                <div onClick={e => navigate(ROUTE_QRC_CHECKOUT)}>
                  <IconButton className="relative">
                    <span className="badgeNotification ">{stateCartCount}</span>
                    <img src={grocery} alt="" />
                  </IconButton></div> :
                <div >
                  <img src={grocery} alt="" className="pointer-default" />
                </div>} </>}
          <button className="menuDropdown active bg-white max-height-26px font-500 fontsize-14 min-width-0px ps-2 pe-1">
            <div className="">
              <span className="" style={{ lineHeight: "25px" }}>
                {adminUserName}
              </span>
              <span>
                <ArrowDropDownIcon
                  className="mb-2px"
                  sx={{ color: " #7D7E7E;" }}
                />
              </span>
            </div>
            <div
              className={
                hideDropdown ? "hide" : "menuDropdown-body border-none pb-5"
              }
            >
              <div
                className={
                  hideDropdown
                    ? "hide"
                    : arrAccountUserIds.includes(Number(adminUserRole))
                      ? "menuDropdown-content border-white logout-dropdown-accoutlogin "
                      : "menuDropdown-content border-white  logout-dropdown "
                }
              >
                {checkMenuPagePermission(adminUserRole, PAGENAME_SETTINGS) && (
                  <>
                    <Link
                      to={ROUTE_SETTINGS}
                      className={
                        location.pathname === ROUTE_SETTINGS
                          ? "active-link"
                          : ""
                      }
                      onClick={(e) => onHideDropDown()}
                    >
                      <div
                        onClick={(e) => setPathText("")}
                        className="flex gap-2"
                      >
                        {t("navigation.settings")}
                        {location.pathname === ROUTE_SETTINGS && (
                          <>
                            <img src={activetik} alt="tik" />
                          </>
                        )}
                      </div>
                    </Link>
                  </>
                )}
                <Link
                  to={
                    Number(adminUserRole) == USER_ROLE_ADMIN_SUPERADMIN ||
                      Number(adminUserRole) == USER_ROLE_ADMIN_ADMIN
                      ? ROUTE_SA_LOGIN
                      : ROUTE_ACCOUNTS_LOGIN
                  }
                  onClick={(e) => handleLogout()}
                >
                  {t("navigation.logout")}
                </Link>
              </div>
            </div>
          </button>
        </div>
      </div>

      {/* // *******************************************************************************
      // ******************************* MODALS - START ********************************
      // ******************************************************************************* */}

      <Modal
        open={addGroup}
        onClose={() => setAddGroup(false)}
        aria-labelledby={"Add Group"}
        aria-describedby=""
      >
        <Box sx={groupStyle}>
          <form
            className="w-100  manage-modal"
            onSubmit={(e) => onCreateNewGroup(e)}
          >
            <div className="space-between center border-bottom p-3">
              <h1> {t("accountDetails.createGroupModal")}</h1>
              <span onClick={() => setAddGroup(false)}>
                <CloseRounded className="pointer" />
              </span>
            </div>

            <div className="bg-light">
              <div>
                <div className=" border-bottom p-3 ">
                  <span className="sub-text text-center">
                    {t("accountDetails.subTextModal")}
                  </span>
                </div>
              </div>

              <div className="border-bottom px-3 pt-4 pb-2 bg-white">
                <div className=" w-100">
                  <div className="label">
                    {t("accountDetails.nameGroupModal")}
                  </div>
                  <Textfield
                    type="text"
                    value={newGroupName}
                    name="group_name"
                    id="group_name"
                    required={true}
                    placeholder={`${t("group.groupName")}`}
                    onChange={(e) => setNewGroupName(e.target.value)}
                    maxLength={100}
                    className={newGroupNameError ? " input-error " : ""}
                  />
                </div>

                <div className=" w-100 my-4">
                  <div className="label">
                    {t("accountDetails.accountModal")}
                  </div>
                  <div className="percentage-input w-100">
                    <SelectField
                      type="inputType"
                      name="sel_group_account"
                      id="sel_group_account"
                      required={true}
                      options={
                        <option value={accountId ? accountId.toString() : ""}>
                          {accountDetails.hotelName}
                        </option>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-4">
              <div className="flex w-100 gap-4">
                <ButtonModal
                  type="cancel"
                  onClick={() => setAddGroup(false)}
                  buttonLabel={t("common.cancel")}
                />

                <ButtonModal
                  buttonType="submit"
                  buttonLabel={t("accountDetails.createGroupModal")}
                />
              </div>
            </div>
          </form>
        </Box>
      </Modal>

      <Modal
        open={showAddAccount}
        onClose={() => setShowAddAccount(false)}
        aria-labelledby={"Edit Account Admin User"}
        aria-describedby=""
      >
        <Box sx={style}>
          <div className="w-100  manage-modal">
            <div className="space-between center border-bottom p-3">
              <h1> {t("accounts.ModalAddAccountHeading")}</h1>
              <span
                onClick={() => setShowAddAccount(false)}
                className="pointer"
              >
                <CloseRounded />
              </span>
            </div>

            <div className="bg-light">
              <div className="  pt-3 bg-white">
                <form
                  className="acc-form "
                  action="onSubmit"
                  onSubmit={(e) => onHandleSave(e)}
                >
                  <div className="px-3">
                    <div className="business w-100 p-0px ">
                      <div className="flex gap-4 mb-18px">
                        <div className="w-100">
                          <div className="label">
                            {t("accounts.businessNameLabel")}
                          </div>
                          <Textfield
                            type="text"
                            required
                            maxLength={150}
                            name="txt_businessName"
                            id="txt_businessName"
                            placeholder={`${t(
                              "businessDetails.businessNamePlaceHolder"
                            )}`}
                            value={businessName}
                            onChange={(e) => setBusinessName(e.target.value)}
                          />
                        </div>
                        <div className="w-100">
                          <div className="label">
                            {t("businessDetails.parentCompanyLabel")}
                          </div>
                          <Textfield
                            type="text"
                            maxLength={150}
                            name="txt_companyName"
                            id="txt_companyName"
                            placeholder={`${t(
                              "businessDetails.companyNamePlaceHolder"
                            )}`}
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="flex gap-4 mb-18px">
                        <div className="w-100">
                          <div className="label">
                            {t("businessDetails.approximateroomsLabel")}
                          </div>
                          <Textfield
                            type="text"
                            required
                            maxLength={5}
                            min={1}
                            name="txt_numRooms"
                            id="txt_numRooms"
                            placeholder={`${t(
                              "businessDetails.roomsPlaceHolder"
                            )}`}
                            value={numRooms}
                            onChange={(e) =>
                              numberOnly(e.target.value, "numRooms")
                            }
                          />
                        </div>
                        <div className="w-100">
                          <div className="label">
                            {t("businessDetails.numberEmployeesLabel")}
                          </div>
                          <Textfield
                            type="text"
                            required
                            maxLength={5}
                            min={1}
                            name="txt_numEmployees"
                            id="txt_numEmployees"
                            placeholder={`${t(
                              "businessDetails.employeesPlaceHolder"
                            )}`}
                            value={numEmployees}
                            onChange={(e) =>
                              numberOnly(e.target.value, "numEmployees")
                            }
                          />
                        </div>
                      </div>
                      <div className="flex gap-4 mb-18px">
                        <div className="w-100 hidesl">
                          <div className="label">
                            {t("businessDetails.businessPhoneLabel")}
                          </div>
                          <PhoneInput
                            inputProps={{
                              name: `mobile`,
                              required: true,
                              autoFocus: false,
                            }}
                            inputClass="common-input"
                            onlyCountries={PHONEINPUT_COUNTRIES}
                            countryCodeEditable={
                              PHONEINPUT_COUNTRYCODE_EDITABLE
                            }
                            country={"us"}
                            // specialLabel={`${t("adminUserList.mobileModal")}`}
                            value={businessPhone}
                            onChange={(code) => setBusinessPhone(code)}
                            prefix={"+"}
                          />
                        </div>
                        <div className="w-100">
                          <div className="label">
                            {t("businessDetails.businessClassification")}
                          </div>
                          <SelectField
                            type="inputType"
                            name="sel_businessClass"
                            id="sel_businessClass"
                            className="w-100 "
                            value={businessClassification}
                            onChange={(e) =>
                              setBusinessClassification(e.target.value)
                            }
                            required
                            options={
                              businessClassList &&
                              businessClassList.map((item, index) => (
                                <>
                                  <option value={item.id.toString()}>
                                    {item.value}
                                  </option>
                                </>
                              ))
                            }
                          />
                        </div>
                      </div>

                      <div className="mb-18px">
                        <div className="label">
                          {t("businessDetails.addressLabel")}
                        </div>
                        <Textfield
                          type="text"
                          required
                          maxLength={250}
                          name="txt_address"
                          id="txt_address"
                          placeholder={`${t(
                            "businessDetails.addressPlaceHolder"
                          )}`}
                          value={formAddress}
                          onChange={(e) => setFormAddress(e.target.value)}
                        />
                      </div>

                      <div className="flex gap-4 mb-18px">
                        <div className="w-25">
                          <div className="label">
                            {t("businessDetails.cityLabel")}
                          </div>
                          <Textfield
                            type="text"
                            required
                            maxLength={150}
                            name="txt_city"
                            id="txt_city"
                            placeholder={`${t(
                              "businessDetails.cityPlaceHolder"
                            )}`}
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </div>
                        <div className="w-25">
                          <div className="label">
                            {t("businessDetails.stateLabel")}
                          </div>
                          <SelectField
                            type="inputType"
                            name="txt_state"
                            id="txt_state"
                            className={
                              state == "" ? " select-placeholder " : ""
                            }
                            placeHolder={t("businessDetails.stateLabel")}
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            required
                            options={
                              US_STATES_ARRAY &&
                              US_STATES_ARRAY.map((item, index) => (
                                <>
                                  {index == 0 && (
                                    <>
                                      <option value="">
                                        {t("businessDetails.stateLabel")}
                                      </option>
                                    </>
                                  )}
                                  <option
                                    value={item.stateCode}
                                    style={{ color: "#000000" }}
                                  >
                                    {item.stateName}
                                  </option>
                                </>
                              ))
                            }
                          />
                        </div>
                        <div className="w-25">
                          <div className="label">
                            {t("businessDetails.zipcodeLabel")}
                          </div>
                          <Textfield
                            type="text"
                            required
                            maxLength={10}
                            name="txt_zipCode"
                            id="txt_zipCode"
                            placeholder={`${t(
                              "businessDetails.zipcodePlaceHolder"
                            )}`}
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                          />
                        </div>
                        <div className="w-25"></div>
                      </div>

                      <h3>{t("businessDetails.contactInformationHeading")}</h3>
                      <div className="flex gap-4 mb-18px">
                        <div className="w-100">
                          <div className="label">
                            {t("businessDetails.firstNameLabel")}
                          </div>
                          <Textfield
                            type="text"
                            required
                            maxLength={150}
                            name="txt_contactFirstName"
                            id="txt_contactFirstName"
                            placeholder={`${t(
                              "businessDetails.lastNameLabel"
                            )}`}
                            value={contactFirstName}
                            onChange={(e) =>
                              setContactFirstName(e.target.value)
                            }
                          />
                        </div>
                        <div className="w-100">
                          <div className="label">
                            {t("businessDetails.lastNameLabel")}
                          </div>
                          <Textfield
                            type="text"
                            required
                            maxLength={150}
                            name="txt_contactLastName"
                            id="txt_contactLastName"
                            placeholder={`${t(
                              "businessDetails.lastNameLabel"
                            )}`}
                            value={contactLastName}
                            onChange={(e) => setContactLastName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="flex gap-4 mb-18px">
                        <div className="w-100">
                          <div className="label">
                            {t("businessDetails.emailLabel")}
                          </div>
                          <Textfield
                            type="email"
                            required
                            maxLength={150}
                            name="txt_contactEmail"
                            id="txt_contactEmail"
                            placeholder={`${t("businessDetails.emailLabel")}`}
                            value={contactEmail}
                            onChange={(e) => setContactEmail(e.target.value)}
                          />
                        </div>
                        <div className="w-100">
                          <div className="label">
                            {t("businessDetails.JobTitleLabel")}
                          </div>
                          <Textfield
                            type="text"
                            required
                            maxLength={150}
                            name="txt_contactJobTitle"
                            id="txt_contactJobTitle"
                            placeholder={`${t(
                              "businessDetails.jobTitlePlaceHolder"
                            )}`}
                            value={contactJobTitle}
                            onChange={(e) => setContactJobTitle(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="flex gap-4 mb-10px">
                        <div className="w-100">
                          <label className="label create-user flex-start gap-2 align-items-center">
                            <input
                              type="checkbox"
                              checked={createUser}
                              onChange={(e) => setCreateUser(e.target.checked)}
                              className="mb-7px"
                            />
                            {t("businessDetails.createUser")}
                          </label>
                        </div>
                        <div className="w-100"></div>
                      </div>
                    </div>
                  </div>

                  <div className="center border-bottom"></div>

                  <div className="px-3 pt-3">
                    <div className="flex w-100 gap-4">
                      <ButtonModal
                        type="cancel"
                        onClick={(e) => setShowAddAccount(false)}
                        buttonLabel={t("common.cancel")}
                      />
                      <ButtonModal
                        buttonLabel={t("accounts.createAccount")}
                        buttonType="submit"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  ) : (
    <></>
  );
}
