import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import tempImgOne from '../../assets/images/qr-checkout.png'
import visa from '../../assets/images/visa-card.png'

const ORCodesOrderReceipt = () => {
    const { t } = useTranslation();

    return (
        <div className="box-padding select-none">
            <div className="box">
                <div className='qr-checkout-nav'>
                    {t("QRCodesYourOrders.order")} #123455667
                </div>
                <div className='w-100'>
                    <div className='pl-37px pb-51px pt-22px flex-col gap-12px'>
                        <h1 className='text-22px-600-black mb-18px'>{t("QRCodesYourOrders.orders")}</h1>
                        <div className='flex'>
                            <div className="selected-qr-img">
                                <img src={tempImgOne} alt="" />
                            </div>
                            <div className='pl-18px pr-21px flex w-100 '>
                                <div className='flex-col w-100 '>
                                    <span className='text-16px-600-black pb-14px'>{t("QRCodesCheckOut.wideCard")}</span>
                                    <span className='text-14px-500-black pb-10px'>Jodie Foster </span>
                                    <span className='text-14px-500-black'>10 x $0.05</span>
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <div className="selected-qr-img">
                                <img src={tempImgOne} alt="" />
                            </div>
                            <div className='pl-18px pr-21px flex w-100 '>
                                <div className='flex-col w-100 '>
                                    <span className='text-16px-600-black pb-14px'>{t("QRCodesCheckOut.wideCard")}</span>
                                    <span className='text-14px-500-black pb-10px'>Jodie Foster </span>
                                    <span className='text-14px-500-black'>10 x $0.05</span>
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <div className="selected-qr-img">
                                <img src={tempImgOne} alt="" />
                            </div>
                            <div className='pl-18px pr-21px flex w-100 '>
                                <div className='flex-col w-100 '>
                                    <span className='text-16px-600-black pb-14px'>{t("QRCodesCheckOut.wideCard")}</span>
                                    <span className='text-14px-500-black pb-10px'>Jodie Foster </span>
                                    <span className='text-14px-500-black'>10 x $0.05</span>
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <div className="selected-qr-img">
                                <img src={tempImgOne} alt="" />
                            </div>
                            <div className='pl-18px pr-21px flex w-100 '>
                                <div className='flex-col w-100 '>
                                    <span className='text-16px-600-black pb-14px'>{t("QRCodesCheckOut.wideCard")}</span>
                                    <span className='text-14px-500-black pb-10px'>Jodie Foster </span>
                                    <span className='text-14px-500-black'>10 x $0.05</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="space-between border-top py-40px px-37px">
                        <div className=''>
                            <h2 className='text-22px-600-black mb-19px'> {t("QRCodesPayment.paymentMethod")}</h2>
                            <div className='flex align-items-center gap-2 mb-17px'>
                                <img src={visa} alt="" width={36} height={33} />
                                <div className='flex-col'>
                                    <span className='text-12px-700-black'>Visa ····1234 </span>
                                    <div className="space-between text-8px-400-black">
                                        <span >7/723 </span><span> 1:21 PM</span>
                                    </div>
                                </div>
                            </div>
                            <p className='text-14px-500-black'>Paid on July 2,  2023</p>
                        </div>
                        <div className=' flex-col max-width-292px w-100'>
                            <div className='text-14px-500-black space-between border-bottom pb-3'>
                                <span>{t("QRCodeRightSidePanelPayment.total")}</span><span>$204.36</span>
                            </div>
                            <div className='text-14px-500-black space-between border-bottom py-3'>
                                <span>{t("QRCodeRightSidePanelPayment.shipping")}</span><span>FREE</span>
                            </div>
                            <div className='text-14px-500-black space-between border-bottom py-3'>
                                <span>{t("QRCodeRightSidePanelPayment.tax")}</span><span>$8.25</span>
                            </div>
                            <div className='text-16px-600-black space-between  py-3'>
                                <span>{t("QRCodeRightSidePanelPayment.subTotal")}</span><span>$212.61</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ORCodesOrderReceipt