import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { Box, Modal } from "@mui/material";

import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { authenticateUser, selectAuthentication, setLoginStatus } from './authenticationSlice';
import { isAuthenticated, resetAdminUserPasswordNoAuth, resetUserPasswordNoAuth } from '../../services/authenticationService';
import logo from './../../assets/images/tovalogo.svg';

import { APP_NAME, ROUTE_ACCOUNTS_LIST } from "../../config/env";

import { setNotification } from '../../services/apiService';
import { CloseRounded } from '@mui/icons-material';
import { removeTokens } from '../../services/localStorage';

export const LoginPage = () => {
  const { t } = useTranslation();
  const navigate  = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);

  const [resetpwdUsername, setResetpwdUsername] = useState('');
  const [usernameError, setUsernameError] = useState('required');
  const [modalHeader, setModalHeader] = useState('');

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  
  // if (isAuthenticated()) {
  //   navigate(ROUTE_ACCOUNTS_LIST); 
  // }

  const { loginStatus } = useAppSelector(selectAuthentication); // isProcessingRequest
  const dispatch = useAppDispatch();

  useEffect(() => {
    if(window.location.hostname === 'pay.usetova.com'){
      navigate('/invalid/not-found');
    }
  },[]); 

  useEffect(() => {
    if(loginStatus === 'ERROR'){
      setNotification(
        t("messages.appName"),  
        t("common.msgInvalidCredentials"),
        "failed",
        "getFormDataHeader"
      )

      dispatch(setLoginStatus('')); 
    }if (loginStatus==='SUCCESS') {
      navigate(ROUTE_ACCOUNTS_LIST); 
    }
  },[loginStatus]); 

  console.log(loginStatus,"loginStatus");
  

  const handleChange = (e: any) => {
    removeTokens();
    e.preventDefault();
    dispatch(authenticateUser(username, password));
  };

  const onSetResetpwdUsername = (emailValue: string) => {
    setResetpwdUsername(emailValue);
  
    const isValidEmail = emailValue.trim().length > 0 && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue);
  
    setUsernameError(isValidEmail ? "" : "required");
  };



  const forgotPasswordButton = () => {
    setModalHeader("Forgot Password");
    setResetpwdUsername("");
    handleOpen();
  };

  const onSaveSubmitted = (e) => {
    
    e.preventDefault();
    if (resetpwdUsername && resetpwdUsername.trim().length > 0) {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (resetpwdUsername.match(validRegex)) { 
        setUsernameError(''); 
        dispatch(resetAdminUserPasswordNoAuth(encodeURIComponent(resetpwdUsername))) 
        setOpen(false)
      }
      
    } else {
      
      setUsernameError("required");
      return false;
    }
  };

  const style = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "13px",
    boxShadow: 24,
  };
  return (
    <>
    
      <div
        className="w-100 center bg-light"
        style={{ margin: "0px auto",height: "100vh" }}
      >
        <form className="bg-white shadow-lg w-45perc" autoComplete="off" onSubmit={handleChange}>
          <div className="flex w-100" >
            <div className="w-100 center mb-35px">
              <div className="login px-4 w-80perc ">
                <div className="w-100 center mt-5 mb-40px">
                  <img className="medium" src={logo} alt={APP_NAME} />
                </div>
                <h1>{t("login.login")}</h1>
                <div>
                  <div >
                    <label>{t("login.username")} </label>
                  </div>
                  <div className='mb-20px'>
                    <input
                      type="text"
                      required
                      name="username"
                      placeholder={`${t("login.usernamePlaceHolder")}`}
                      onChange={(event) => setUsername(event.target.value)}
                      className="w-100"
                    />
                  </div>
                </div>
                <div>
                  <div >
                    <label>{t("login.password")}</label>
                  </div>
                  <div className="relative mb-20px">
                    <span className="password-icon"
                      onClick={(e) => {
                        setPasswordShown(!passwordShown);
                      }}
                    >
                      <i
                        className={
                          passwordShown
                            ? "fa fa-eye-slash eye-icon"
                            : "fa fa-eye eye-icon"
                        }
                      ></i>
                    </span>
                    <input
                      type={passwordShown ? "text" : "password"}
                      required
                      name="password"
                      placeholder={`${t("login.passwordPlaceHolder")} `}
                      onChange={(event) => setPassword(event.target.value)}
                      className="w-100"
                    />
                  </div>
                </div>
                <div >
                  <button type="submit" className="primary w-100 my-4">
                    {t("login.login")}
                  </button>
                </div>
                <div
                  className="flex-end pointer"
                  onClick={(event) => forgotPasswordButton()}
                >
                  {t("login.forgotPassword")}{" "}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
     


      <Modal
       open={open}
       onClose={handleClose}
       aria-labelledby={modalHeader}
       aria-describedby=""
      >
        <Box sx={style}>
          <form
            className="w-100  manage-modal"
            onSubmit={(e) => onSaveSubmitted(e)}
          >
            <div className="space-between center p-3">
              <div>&nbsp;</div>
              <div className='forgotpwd-main-header'> {modalHeader}</div>
              <span onClick={handleClose}>
                <CloseRounded className="pointer" />
              </span>
            </div>

            <div className="center forgotpwd-sub-header">{t("accountLoginPage.forgotPwdHeader1")}
            </div>
            <div className="center forgotpwd-sub-header p-3">{t("accountLoginPage.forgotPwdHeader2",{projectName:t("projectName")})}
            </div>

            <div className="bg-light">
              <div className="px-3 mt-20px bg-white center div-center">
                <div className=" space-between w-80perc center div-center">
                  <input type="text" required maxLength={100} name={"resetpwdUsername"} className={
                      usernameError && usernameError.trim().length > 0
                        ? " input-error w-100 "
                        : " w-100 "
                    } placeholder={`${t("login.forgotPwd.usernamePlaceHolder")}`} value={resetpwdUsername} onChange={(e) => onSetResetpwdUsername(e.target.value)} />
                </div>   
                             
              </div>
              <div className="px-3 space-between center div-center">
                <button type="submit" className='w-80perc rounded-2 p-12px text-white border-none mt-18px w-100' style={{backgroundColor:"#3871E0"}}>{t("accountLoginPage.forgotPwdBtnText")}</button> 
                </div> 
            </div>

            <div className="space-between center p-2">
              <div> &nbsp; </div>
            </div>
            
          </form>
        </Box>
      </Modal>
    </>
  );
};
