import { createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { ACC_API_URL_LOGIN, ACC_API_URL_PWD_RESET, SA_API_URL_LOGIN, SA_API_URL_PWD_RESET } from '../config/api_urls';
import { postWithoutAuth, setNotification } from './apiService';
import { getAccessToken, getAccountAccessToken } from './localStorage';
import { USER_DOES_NOT_EXIST } from '../config/apiResponseCodes';

export interface AuthPayload {
  username: string;
  password: string;
}

//FUNCTION FOR VALIDATE USERNAME AND PASSWORD
export const authenticate = async (username: string, password: string) => {
  const auth: AuthPayload = { username, password };
  return await (
    await postWithoutAuth(SA_API_URL_LOGIN, auth)
  ).data;
};

//FUNCTION TO CHECK USER IS AUTHENTICATED
export const isAuthenticated = (): boolean => {
  return getAccessToken() ? true : false;
};

//FUNCTION TO RESET ADMIN USERS PASSWORD BEFORE LOGIN 
export const resetAdminUserPasswordNoAuth = createAsyncThunk(
  'account/resetUserPassword',
  async (username: String, thunkAPI: any) => {
    try {
      const url = SA_API_URL_PWD_RESET+'?username=' + username; 
      const response = postWithoutAuth(url, "");
      let code=(await response).data.code
      
      if(code===400){
        setNotification(
          t("messages.appName"),  
          t("common.invalidUser"),
          "failed",
          "getFormDataHeader"
        )

      }else{
        setNotification(
          t("messages.appName"),  
          t("accountSignUp.msgEmailResentSuccess"),
          "success",
          "getFormDataHeader"
        )

      }
      return (await response).data;
      
      
    } catch (error: any) {
      
      if(error.response.data.message===USER_DOES_NOT_EXIST){
        setNotification(
          t("messages.appName"),  
          t("messages.invalidMail"),
          "failed",
          "getFormDataHeader"
        )
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//FUNCTION TO RESET USERS PASSWORD BEFORE LOGIN 
export const resetUserPasswordNoAuth = createAsyncThunk(
  'account/resetUserPassword',
  async (username: String, thunkAPI: any) => {
    try {
      const url = ACC_API_URL_PWD_RESET+'?username=' + username; 
      const response = postWithoutAuth(url, "");
      let code=(await response).data.code
      
      if(code===400){
        setNotification(
          t("messages.appName"),  
          t("common.invalidUser"),
          "failed",
          "getFormDataHeader"
        )

      }else{
        setNotification(
          t("messages.appName"),  
          t("accountSignUp.msgEmailResentSuccess"),
          "success",
          "getFormDataHeader"
        )

      }
      return (await response).data;
      
      
    } catch (error: any) {
      
      if(error.response.data.message===USER_DOES_NOT_EXIST){
        setNotification(
          t("messages.appName"),  
          t("messages.invalidMail"),
          "failed",
          "getFormDataHeader"
        )
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//FUNTION FOR VALIDATING ACCOUNT LOGIN PAGE 
export const authenticateAccount = async (username: string, password: string) => {
  const auth: AuthPayload = { username, password };
  return await (
    await postWithoutAuth(ACC_API_URL_LOGIN, auth) 
  ).data;
};

//FUNCTION TO CHECK USER IS AUTHENTICATED FOR ACCOUNT LOGIN PAGE 
export const isAccountAuthenticated = (): boolean => {
  return getAccountAccessToken() ? true : false;
};