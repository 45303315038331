import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CardMedia } from "@mui/material";

import arrow from "../../../assets/images/arrow1.svg";
import mobile from "../../../assets/images/acc-mobile.svg";
import avatar from "../../../assets/images/avatar1.svg";
import upload from "../../../assets/images/upload.svg";

import Nav from "../Nav";
import SignUpLeftPane from "../SignUpLeftPane";
import { ROUTE_ACCOUNT_SIGNUP_AGREEMENT } from "../../../config/env";
import { useAppDispatch } from "../../../helpers/hook";
import { IUploadLogo, uploadLogoImage } from "./uploadLogoSlice";

function UploadLogo() {;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const newAccountSecretId = localStorage.getItem('newAccountSecretId');
  const newAccountHotelId = localStorage.getItem('newAccountHotelId');

  

  const [avatarImg, setAvatartImg] = React.useState<string | ArrayBuffer | null>(avatar)
  
  const selectImage = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatartImg(reader.result)
      }
    }
    reader.readAsDataURL(e.target.files[0])
  }

  const gotoNext = () => {
    const req: IUploadLogo = {
      id: Number(newAccountHotelId),
      secretId: newAccountSecretId?newAccountSecretId:'',
      logo:avatarImg,
};
dispatch(uploadLogoImage(req));

navigate(ROUTE_ACCOUNT_SIGNUP_AGREEMENT);
};

const memorisedSideComponent = useMemo(() => <SignUpLeftPane signUpStep={4} />,[]);

  return (
    <>
      <Nav />
      <div className="w-100 flex">
        <div className="w-30perc ">
          {memorisedSideComponent}
        </div>
        <div className="w-70perc bg-light ">
          {/* <div className="center"> */}
          <form className="">
            <div className="business w-100">
              <h1> {t("uploadLogo.uploadlogoHeading")}</h1>
              <h2>{t("uploadLogo.subHeading")}</h2>

              <div className="flex gap-4">
                <div className="w-100 mt-73px ">
                  <div className="avatar-box">
                    <img src={avatarImg?avatarImg:avatar} alt="logo" className="logoImg"/>
                    
                    <label htmlFor="upload" className="uploadbtn mt-98px w-100">
                      <span>{t("uploadLogo.uploadBtn")}</span>{" "}
                      <img src={upload} alt="" className="ml-15px" />
                      <input
                        type="file"
                        name="upload"
                        id="upload"
                        onChange={e=>selectImage(e)}
                        style={{ display: "none" }}
                      />
                    </label>
                  </div>
                </div>
                <div className="w-100 center ">
                  <div className="relative">
                  <CardMedia
                      component="img"
                      height={"38px"}
                      width={"38px"}
                      sx={{ border: "1px solid #e6e8eb", borderRadius: "8px" ,objectFit:'fill',backgroundColor:'#ffffff',overflow:"hidden",position:"absolute",top:"15%",right:"23%",width:"38px"}}
                      src={avatarImg?avatarImg:avatar}
                      alt="green iguana"
                    />
                  <img src={mobile} alt="" />
                  </div>
               
                </div>
              </div>

              <div className="border-top ">&nbsp;</div>

              <div className="flex-end">
                <button className="mt-0" onClick={(e) => gotoNext()}>
                  {" "}
                  {t("common.continue")} &nbsp;
                  <img src={arrow} alt="" />
                </button>
              </div>
            </div>
          </form>
          {/* </div> */}
        </div>
      </div>
    </>
  );
}

export default UploadLogo;
