import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../helpers/hook";
import ReactQuill from "react-quill";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import continu from "../../../assets/images/arrow1.svg";
import { ROUTE_ACCOUNTS_LOGIN, ROUTE_ACCOUNT_SIGNUP_ACH, USER_GROUP_HOTELS } from "../../../config/env";
import SignUpLeftPane from "../SignUpLeftPane";
import { acceptTerms, fetchTerms,  } from '../../../services/signUpServices';
import { setNotification } from '../../../services/apiService';
import { selectAgreementDataSlice } from "./agreementSlice";
import { IAcceptTerms } from "../../../models/signUpInterfaces";
import Nav from '../Nav';

function Agreement() {
  const { t } = useTranslation();
  const navigate  = useNavigate();
  const dispatch = useAppDispatch();
  
  const newAccountSecretId = localStorage.getItem('newAccountSecretId');
  const newAccountHotelId = localStorage.getItem('newAccountHotelId');
  const newAccountUserEmail = localStorage.getItem('newAccountUserEmail');

  const modules = {
    toolbar: false,
  };
  const [newTermsText, setNewTermsText] = useState("");
  const [signUpStage, setSignUpStage] = useState(5);
  const [showContinue, setShowContinue] = useState(false);
  const [iAgreeChecked, setIAgreeChecked] = useState(false);

  const {
    isProcessingRequest,
    termsData,
    termsAcceptedStatus
  } = useAppSelector(selectAgreementDataSlice);

  React.useEffect(() => {
    if(!newAccountHotelId || newAccountHotelId.trim().length==0 || !newAccountUserEmail || newAccountUserEmail.trim().length==0) {
      setShowContinue(false); 
    }
    else {
        dispatch(fetchTerms(USER_GROUP_HOTELS.toString()));
    }
  }, []); 

  React.useEffect(() => {
    if(termsData && termsData?.conditionText && termsData?.conditionText.length > 0) {
      setNewTermsText(termsData.conditionText); 
      setShowContinue(true);  
    }
  }, [termsData]); 

  React.useEffect(() => {
    if(termsAcceptedStatus && termsAcceptedStatus=='termsAccepted') {
      setNotification(
        t("projectName"),
        t("agreement.termsAccepted"),
        "success",
        "termsAccepted"
      );
      setTimeout(() => {
        // navigate(`${ROUTE_ACCOUNT_SIGNUP_ACH}`);
        navigate(ROUTE_ACCOUNTS_LOGIN)
      }, 1500);
    }
    else if(termsAcceptedStatus && termsAcceptedStatus=='error') {
      setNotification(
        t("projectName"),
        t("agreement.termsAcceptFailed"),
        "failed",
        "addAccount"
      );

    }
  }, [termsAcceptedStatus]);  

  const onHandleSave = () => {
    // {"id": 1, "condition_text": "T&C for Hotel", "group": 1, "current_terms": false}
    let termsId = termsData && termsData?.id ? termsData.id : 0; 
    if(newAccountUserEmail && newAccountUserEmail.trim().length > 0 && termsId) {
      const req: IAcceptTerms = {
        username: newAccountUserEmail,
        terms_id: termsId,
        secret_id: newAccountSecretId
      };
      dispatch(acceptTerms(req));
    }
    
  };

  return (
    <div>
      <Nav/>
      <div className="w-100 flex" >
        <div className="w-30perc ">
          <SignUpLeftPane signUpStep={signUpStage} />
        </div>
        <div className="w-70perc bg-light ">
          <div className="center  ">
            <div className="business w-100 ">
              <div>
                <ReactQuill
                  theme="snow"
                  value={newTermsText}
                  // onChange={handleChange}
                  modules={modules}
                  readOnly={true}
                  className="w-100"
                />
              </div>
              <div className="border-bottom mt-14px">&nbsp;</div>

              <div className="space-between ">
                <div className="flex gap-2 mt-18px">
                  <input type="checkbox" name="chk_agree" id="chk_agree" className="check" checked={iAgreeChecked} onClick={e => setIAgreeChecked(!iAgreeChecked)} />
                  <div className="text-start text-dark para ">
                    {t("agreement.TandC1")}
                    <b>{t("agreement.TandC2")}</b>
                    {t("agreement.TandC3")}
                    <b className="text-blue">
                      {t("agreement.TandC4")}
                      <br />
                      {t("agreement.TandC5")}
                    </b>
                    {t("agreement.TandC6")}
                    <b className="text-blue">{t("agreement.TandC7")}</b>
                    {t("agreement.TandC8")}
                    <b className="text-blue">{t("agreement.TandC9")}</b>
                  </div>
                </div>
                {showContinue && iAgreeChecked && <button className="w-25" onClick={onHandleSave}>
                  {t("agreement.ContinueButton")} <img src={continu} alt="" />
                </button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Agreement;
