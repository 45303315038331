import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import maptova from "../assets/images/web-map-tovaIcon.svg";
import mapind from "../assets/images/web-mapIndication.svg";
import ContactForm from "./ContactForm";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { GOOGLEANALYTICS_SCRIPT, GOOGLEANALYTICS_SOURCE, ZOOMINFO_SCRIPT } from "../config/env";
import { t } from "i18next";

const AnyReactComponent = ({ text, onClick, popupVisible }) => (
  <div className="relative">
    {popupVisible && (
      <div className="relative">
        <div className="web-map-indicator-item">
          <span className="text-14px-500-black mb-2px">{t("about.tova")}</span>
          <span>{t("about.addressSection1")}</span>
          <span>{t("about.addressSection2")}</span>
          <span className="mb-2px">{t("about.addressSection3")}</span>
          <a href={`https://www.google.com/maps?q=34.00459625321775,-118.42063247116391`} rel="noreferrer" target="_blank" className="map-link">{t("about.viewOnGoogleMap")}</a>
          <button onClick={onClick} style={{ position: "absolute", top: "2px", right: "4px", backgroundColor: 'transparent', border: "none", cursor: 'pointer' }}><CloseRoundedIcon sx={{ fontSize: "14px", color: "gray" }} /></button>
        </div>
        <div className="web-map-corner"></div>
      </div>
    )}

    <div onClick={onClick} className="pointer">
      <img src={text} alt="text" width={"auto"} />
    </div>
  </div>
);

const About = () => {
  const { t } = useTranslation();

  const [popupVisible, setPopupVisible] = useState(false);

  const handleMarkerClick = () => {
    setPopupVisible(!popupVisible);
  };

  const mapOptions = {
    zoomControl: false,
    fullscreenControl: false,
  };

  const defaultProps = {
    center: {
      lat: 34.00459625321775,
      lng: -118.42063247116391,
    },
    zoom: 11,
  };

  useEffect(() => {

    // *********************************************************************
    // GOOGLE ANALYTICS CODE 
    // *********************************************************************
    if (GOOGLEANALYTICS_SOURCE.length > 1 && GOOGLEANALYTICS_SCRIPT.length > 1) {
      const script1 = document.createElement("script");
      script1.setAttribute("src", GOOGLEANALYTICS_SOURCE);
      document.head.appendChild(script1);

      const script2 = document.createElement("script");
      script2.innerHTML = GOOGLEANALYTICS_SCRIPT;
      document.head.appendChild(script2)
      return () => {
        // clean up the script when the component in unmounted
        document.head.removeChild(script1);
        document.head.removeChild(script2);
      }
    }


    // *********************************************************************
    // ZOOMINFO CODE 
    // *********************************************************************
    if (ZOOMINFO_SCRIPT.length > 0) {
      const script = document.createElement("script");
      script.innerHTML = ZOOMINFO_SCRIPT;
      document.head.appendChild(script)
      return () => {
        // clean up the script when the component in unmounted
        document.head.removeChild(script)
      }
    }
  }, []);

  return (
    <>
      <Navbar />
      <div className="hide-overflowX bg-white">
        <Row className="frame">
          <Row className="max-w-1488px justify-content-sm-center justify-content-md-start  px-0 ml-10px">
            <Col
              xs={12}
              md={12}
              lg={7}
              className="flex-col space-evenly mt-140px   ps-0"
            >
              <h1 className="ms-md-5 ms-0 text-md-start text-center">{t("about.aboutHeading")}</h1>
            </Col>
          </Row>
        </Row>

        <div className=" w-100 center ">
          <div className="max-w-1488px w-full about ">
            <h2 className="">{t("about.ourMissionHeading")}</h2>
            <p>{t("about.ourMissionPara")}</p>
          </div>
        </div>



        <Row className="w-100 center mx-0 mb-5 pb-md-5">
          <Row className="max-w-1400px  space-between px-0 relative  overflow-hidden p-2 border-32r">
            <div className='map-frame'></div>
            <Col xs={12} md={6} lg={6} className="ps-1 ps-md-4" style={{ zIndex: "1" }}>
              <div className=" text-start flex flex-col gap-40px p-40px">
                <div className="map-h1 flex gap-1">
                  <img src={maptova} alt="tova" />
                  {t("about.headquatersHeading")}
                </div>
                <div>
                  <div className="flex flex-col map-p mb-15px">
                    <span>{t("about.tova")}</span>
                    <span>{t("about.addressSection1")}</span>
                    <span>{t("about.addressSection2")}</span>
                    <span>{t("about.addressSection3")}</span>
                  </div>
                  <div className="map-blue-text">
                    {t("about.addressContact")}
                  </div>
                </div>
                <div>
                  {/* <button className="btn-lite-blue ">
                    {t("about.addressContactBtn")}
                  </button> */}
                  <ContactForm className="btn-lite-blue" label={`${t("about.addressContactBtn")}`} />
                </div>
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={6}
              className="center px-0 "
            >
              <div className=" text-start  w-full map-b overflow-hidden">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyAcaEaNXydIuFDAyDao7z4Q6hE8UhuNpic",
                  }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  options={mapOptions}
                >

                  <AnyReactComponent
                    lat={34.00459625321775}
                    lng={-118.42063247116391}
                    text={mapind}
                    popupVisible={popupVisible}
                    onClick={() => handleMarkerClick()}
                  />
                </GoogleMapReact>
              </div>
            </Col>
          </Row>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default About;
