import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../helpers/hook";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import Table from "../../components/Table";
import Header from "../../components/Header";
import Paginations from "../../components/Pagination";
import FilterSection from "../../components/FilterSection";
import { CommissionsTableHeaders } from "../../components/Table/TableHeaders";

// import { filterDateValues } from "../../utilities/dateFilterCalculation";

import { DATEFILTER_ALLDATES, PAGENAME_COMMISSIONS, PAGINATION_NUM_ROWS } from "../../config/env";
import { checkActionPermission, PERM_COMMISSION_EXPORT } from "../../config/permissions";

import { ICommissionsRequestData } from "../../models/commissionsInterfaces";

import { getAdminUserRole, setLastActivePageAPIData } from "../../services/localStorage";
import {
  fetchCommissions,
  exportCommissions,
  fetchCommissionsSummary,
} from "../../services/commissionsListServices";
import {
  selectCommissionsListDataSlice,
  resetCommissions,
  resetCommissionsSummary,
  setCurrentPage,
} from "./commissionsListDataSlice";

function CommissionsList() {

  // ************** TO SET DATE FILTER TO THISMONTH BY DEFAULT - START **************
  // let dateObj = filterDateValues(DATEFILTER_THISMONTH);
  // let fromMM = dateObj.fromDate.getMonth() + 1 < 10 ? "0" + (dateObj.fromDate.getMonth() + 1).toString()
  //     : (dateObj.fromDate.getMonth() + 1).toString();
  // let fromDD = dateObj.fromDate.getDate() < 10 ? "0" + dateObj.fromDate.getDate().toString()
  //     : dateObj.fromDate.getDate().toString();
  // let fromYY = dateObj.fromDate.getFullYear().toString();

  // let toMM = dateObj.toDate.getMonth() + 1 < 10 ? "0" + (dateObj.toDate.getMonth() + 1).toString()
  //   : (dateObj.toDate.getMonth() + 1).toString();
  // let toDD = dateObj.toDate.getDate() < 10 ? "0" + dateObj.toDate.getDate().toString()
  //     : dateObj.toDate.getDate().toString();
  // let toYY = dateObj.toDate.getFullYear().toString();

  // let defaultFromDate = fromYY + "-" + fromMM + "-" + fromDD;  
  // let defaultToDate = toYY + "-" + toMM + "-" + toDD;
  let defaultDateFilterType = DATEFILTER_ALLDATES;  
  let defaultToDate = "";
  let defaultFromDate = "";
  
  // ************** TO SET DATE FILTER TO THISMONTH BY DEFAULT - END ***************

  const prevAPIDataString = localStorage.getItem('lastActivePageAPIData');
  const prevAPIData = prevAPIDataString ? JSON.parse(prevAPIDataString) : {};
  const pageName = PAGENAME_COMMISSIONS; 
  const adminUserRole = getAdminUserRole(); 
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    commissionsList,
    totalSize,
    commissionsListLoading,
    currentPage,
    commissionsSummary,
  } = useAppSelector(selectCommissionsListDataSlice);

  const [sortField, setSortField] = useState("");
  const [selectedAccountIds, setSelectedAccountIds] = useState(""); 
  const [selectedGroupIds, setSelectedGroupIds] = useState(""); 
  const [status, setStatus] = useState("");
  const [dateFilterType, setDateFilterType] = useState(defaultDateFilterType);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>(""); 
  const [applyFilter, setApplyFilter] = useState<number>(0);
  const [textSearchFilter, setTextSearchFilter] = useState<string>("");
  const [numRowsPerPage, setNumRowsPerPage] = useState(PAGINATION_NUM_ROWS); 

  const [initialAPICalled, setInitialAPICalled] = useState(false);

  // 'accept...' VARIABLES USED TO SET/RESET FILTERS ON REFRESH   
  const [acceptAccountFilterIds, setAcceptAccountFilterIds] = useState("");
  const [acceptGroupFilterData, setAcceptGroupFilterData] = useState(""); 
  const [acceptDateFilterData, setAcceptDateFilterData] = useState(""); 
  const [acceptMultiSelectStatusIds, setAcceptMultiSelectStatusIds] = useState(""); 

  const fetchCommissionsList = () => {
    if(!initialAPICalled && prevAPIData.pageName && prevAPIData.pageName==pageName && prevAPIData?.apiData?.hotel && prevAPIData.apiData.hotel.length > 0) { 
      dispatch(fetchCommissions(prevAPIData.apiData));
      dispatch(fetchCommissionsSummary(prevAPIData.apiData));
    
      dispatch(setCurrentPage(prevAPIData.apiData.page));
      setSelectedAccountIds(prevAPIData.apiData.hotel);
      setSelectedGroupIds(prevAPIData.apiData.group); 
      setSortField(prevAPIData.apiData.sort);
      setDateFilterType(prevAPIData.apiData.dateFilterType);
      setStartDate(prevAPIData.apiData.start);
      setEndDate(prevAPIData.apiData.end);
      setTextSearchFilter(prevAPIData.apiData.search);
      setNumRowsPerPage(prevAPIData.apiData.numRowsPerPage); 
      setStatus(prevAPIData.apiData.status); 

      if(prevAPIData.apiData.hotel && prevAPIData.apiData.hotel.trim().length > 0) {
        setAcceptAccountFilterIds(prevAPIData.apiData.hotel); 
      }
      if(prevAPIData.apiData.group && prevAPIData.apiData.group.trim().length > 0) {
        setAcceptGroupFilterData(prevAPIData.apiData.group); 
      }
      if(prevAPIData.apiData.dateFilterType && prevAPIData.apiData.dateFilterType.trim().length > 0) {
        setAcceptDateFilterData(prevAPIData.apiData.dateFilterType); 
      }  
      if(prevAPIData.apiData.status && prevAPIData.apiData.status.length > 0) {
        setAcceptMultiSelectStatusIds(prevAPIData.apiData.status); 
      }
      
      setTimeout(() => {
        setInitialAPICalled(true);
      }, 1250);
      
    }
    else { 
      let accountIds = selectedAccountIds.replace("all,", "");
      if (selectedAccountIds.length > 0) {
        if (currentPage > 0 && accountIds && accountIds.length > 0) {
          const req: ICommissionsRequestData = {
            page: currentPage,
            sort: sortField,
            search: textSearchFilter.trim(),
            hotel: accountIds,
            group: selectedGroupIds=="clearOnly"?"":selectedGroupIds, 
            dateFilterType: dateFilterType, 
            start: startDate,
            end: endDate,
            status: status,
            numRowsPerPage:numRowsPerPage
          };
          setAcceptDateFilterData(defaultDateFilterType);
          dispatch(fetchCommissions(req));
          dispatch(fetchCommissionsSummary(req));
          setLastActivePageAPIData({pageName:pageName, apiData:req}); 
          setInitialAPICalled(true);
          console.log(req);
        }
      } else if (selectedAccountIds.length == 0) {
        dispatch(
          resetCommissionsSummary({
            total_transaction: 0,
            total_payout_amount: "",
            total_payout_amount_currency: "",
            total_succeeded: 0,
            total_failed: 0,
          })
        );
        dispatch(resetCommissions([]));
      }
    }
  };

  const onExportButton = () => {
    let accountIds = selectedAccountIds.replace("all,", "");
    const req: ICommissionsRequestData = {
      page: currentPage,
      sort: sortField,
      search: textSearchFilter.trim(),
      hotel: accountIds,
      group: selectedGroupIds=="clearOnly"?"":selectedGroupIds,  
      start: startDate,
      end: endDate,
      status: status,
      numRowsPerPage:numRowsPerPage
    };
    dispatch(exportCommissions(req));
  };

  React.useEffect(() => {
    fetchCommissionsList();
  }, [
    currentPage,
    sortField,
  ]);

  React.useEffect(() => {
    if(selectedGroupIds=="clearOnly") {
      //DO NOTHING 
    }
    else {
      if (currentPage != 1) {
        acceptPage(1);
      }
      else {
        fetchCommissionsList();
      }
    }
  }, [selectedGroupIds]);

  React.useEffect(() => {
    if (currentPage != 1) {
      acceptPage(1);
    }
    else {
      fetchCommissionsList();
    }
  }, [selectedAccountIds, status, applyFilter, startDate, endDate, textSearchFilter, numRowsPerPage]);

  const handleSort = (sortField: string) => {
    setSortField(sortField);
  };

  const acceptPage = (e: any) => {
    dispatch(setCurrentPage(e));
  };

  const setDateFilterDatesType = (fromDate: string, toDate: string, filterType: string) => {
    if (fromDate.trim().length > 1) {
      setStartDate(dayjs(fromDate).format(`YYYY-MM-DDTHH:mm:ssZZ`));
    } else {
      setStartDate(fromDate);
    }
    if (toDate.trim().length > 1) {
      setEndDate(dayjs(toDate).format(`YYYY-MM-DDT23:59:59ZZ`));
    } else {
      setEndDate(toDate);
    }
    setDateFilterType(filterType);
    setApplyFilter(applyFilter + 1);
  };

  const setStatusFilter = (status: string) => {
    setStatus(status);
  };

  const setGroupIdsFilter = (groupIds:string) => {
    setSelectedGroupIds(groupIds);
  }

  const clearFilters = () => {
    setSortField("");
    setStatus("");
    setDateFilterType(defaultDateFilterType);
    setStartDate(defaultFromDate.trim().length > 1 ? dayjs(defaultFromDate).format(`YYYY-MM-DDTHH:mm:ssZZ`) : "");
    setEndDate(defaultToDate.trim().length > 1 ? dayjs(defaultToDate).format(`YYYY-MM-DDT23:59:59ZZ`) : "");
    acceptPage(1);
    setTextSearchFilter("");
    setSelectedGroupIds(""); 
    setApplyFilter(applyFilter + 1);
    setNumRowsPerPage(PAGINATION_NUM_ROWS); 
  };

  return (
    <div className="box-padding">
      <div className="">
        {/* <Transactions
          commissions={true}
          commissionsSummary={commissionsSummary}
          transaction={true}
        /> */}

        <Header
          addButton={false}
          commissions={true}
          commissionsSummary={commissionsSummary}
          transaction={true}
        />
        <FilterSection
          headind={`${t("navigation.payments")}`}
          filterAccount={true}
          filterGroup={true}
          filterDate={true}
          filterStatus={false}
          filterPayStatus={true}
          filterPayee={false}
          setSelectedAccountIds={setSelectedAccountIds}
          setGroupIdsFilter={setGroupIdsFilter}
          setDateFilterDatesType={setDateFilterDatesType}
          parentPage={PAGENAME_COMMISSIONS}
          setStatusFilter={setStatusFilter}
          clearFilters={clearFilters}
          searchInput={false}
          setTextSearchFilter={setTextSearchFilter}
          searchInputPlaceholder={`${t("commissionslist.searchCommissions")}`} 
          needDateAllOption={true}

          // 'accept....' VARIABLES USED TO SET/RESET FILTERS ON PAGE REFRESH 
          acceptAccountFilterIds={acceptAccountFilterIds}
          acceptGroupFilterData={acceptGroupFilterData}
          acceptDateFilterData={acceptDateFilterData} 
          acceptMultiSelectStatusIds={acceptMultiSelectStatusIds}
        />
        <div className="table-box">
          <Table
            titles={CommissionsTableHeaders()}
            data={commissionsList}
            sortedFieldName={sortField}
            handleSort={handleSort}
          />

          <Paginations
            currentPage={currentPage}
            totalPageCount={
              totalSize
                ? totalSize < numRowsPerPage
                  ? 1
                  : totalSize % numRowsPerPage > 0
                  ? parseInt((totalSize / numRowsPerPage).toString(), 10) +
                    1
                  : parseInt((totalSize / numRowsPerPage).toString(), 10)
                : 0
            }
            totalRows={totalSize}
            isTableLoading={false}
            setCurrentPage={acceptPage}
            setNumRowsPerPage={setNumRowsPerPage} 
            recordsTypeName={`${t("common.transactions")}`} 
            exportButton={(checkActionPermission(adminUserRole, PERM_COMMISSION_EXPORT) && totalSize > 0) ? true : false}
            onExportButton={onExportButton}
          />
        </div>
      </div>
    </div>
  );
}

export default CommissionsList;
