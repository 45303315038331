import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IPagination } from '../../models/interfaces';
import { IUsers } from '../../models/usersListInterfaces';
import { createAccountUsers, deleteUser, fetchUsersList, resetUserListPassword, statusUpdateAccountUsers, updateAccountUsers } from '../../services/usersListServices';
import { t } from 'i18next';
import { setNotification } from '../../services/apiService';
import { ACC_USER_ALREADY_ADDED, ERR_NEED_USER_MERGE, ERR_USER_ALREADY_EXISTS, USER_SELF_DELETE } from '../../config/apiResponseCodes';

export interface DataState {
  usersList: IUsers[];
  currentPage: number;
  loading: 'idle' | 'loading' | 'succeeded' | 'error' | 'statusUpdated' | 'statusUpdateFailed' | 'created' | 'updated' | 'deleted' | 'resetUserPasswordSuccess';
  // addEditLoading: 'idle' | 'loading' | 'succeeded' | 'statusUpdated' | 'statusUpdateFailed' | 'error';
  // deleteUserLoading: 'idle' | 'loading' | 'deleted' | 'error';
  totalSize: number;
}

const initialState: DataState = {
  usersList: [],
  currentPage: 1,
  loading: 'idle',
  // addEditLoading: 'idle',
  // deleteUserLoading: 'idle',
  totalSize: 0,
};

export const usersListDataSlice = createSlice({
  name: 'usersListData',
  initialState,
  reducers: {
    setUsersList: (state, action: PayloadAction<any>) => {
      state.usersList = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    setLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(fetchUsersList.pending, (state) => {
      state.usersList = [];
      state.loading = 'loading';
    });
    builder.addCase(fetchUsersList.fulfilled, (state, action) => {
      const m = action.payload as IPagination<IUsers[]>;
      state.usersList = m.results;
      state.loading = 'succeeded';
      state.totalSize = m.count;
    });
    builder.addCase(fetchUsersList.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      state.totalSize = 0;
    });

    builder.addCase(createAccountUsers.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(createAccountUsers.fulfilled, (state, action: any) => {
      // const m = action.payload as IHotelAssociate[];
      state.loading = 'created';
      // console.log('action: ',action); 
      setNotification(
        t("projectName"),
        t("messages.userCreated"),
        "success",
        "createAccountUsers"
      );
    });
    builder.addCase(createAccountUsers.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      let resp = payload?.message ? (payload.message).toLowerCase() : "";
      let msgText = ""; 
      switch(resp) {
        case(ERR_USER_ALREADY_EXISTS.toLowerCase()) : msgText = t("messages.alreadyAssociated"); 
          break;
        case(ACC_USER_ALREADY_ADDED.toLowerCase()) : msgText = t("messages.userAccountAssociated",{accountNames:payload.hotels}); 
          break;  
        default: msgText = t("messages.userCreationFailed"); 
          break; 
      }  
      setNotification(
        t("projectName"),
        msgText,
        "failed",
        "createAccountUsers"
      );
    });

    builder.addCase(resetUserListPassword.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(resetUserListPassword.fulfilled, (state, action) => {
      // const m = action.payload as IAdminAssociate[];
      console.log('resetUserListPassword.fulfilled: ');
      state.loading = 'resetUserPasswordSuccess';
      setNotification(
        t("projectName"),
        t("messages.verificationEmail"),
        "success",
        "resetUserListPassword"
      );
    });
    builder.addCase(resetUserListPassword.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
      console.log('payload: ', payload);
      setNotification(
        t("projectName"),
        t("messages.verificationEmailFailed"),
        "failed",
        "resetUserListPassword"
      );
    });

    builder.addCase(deleteUser.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.loading = action?.payload?.message ? 'error' : 'deleted';
      {
        action?.payload?.message == 'primary_user_delete' ?
        setNotification(
          t("projectName"),
          t("messages.alreadyAssociated"),
          "failed",
          "deleteUser"
        ) :
        setNotification(
          t("projectName"),
          t("messages.userDeleted"),
          "success",
          "deleteUser"
        )
      }
    });
    builder.addCase(deleteUser.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      let msgText = payload.message.toLowerCase() == USER_SELF_DELETE ? t("messages.userSelfDeletionFailed")  : t("messages.userDeletedFailed"); 
      setNotification(
        t("projectName"),
        msgText,
        "failed",
        "deleteUser"
      ) 
    });

    builder.addCase(statusUpdateAccountUsers.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(statusUpdateAccountUsers.fulfilled, (state, action) => {
      // const m = action.payload as IHotelAssociate[];
      console.log('statusUpdateAccountUsers.fulfilled: ');
      state.loading = 'statusUpdated';
      setNotification(
        t("projectName"),
        t("messages.userStatusUpdated"),
        "success",
        "statusUpdateAccountUsers"
      )
    });
    builder.addCase(statusUpdateAccountUsers.rejected, (state, { payload }: any) => {
      state.loading = 'statusUpdateFailed';
      // state.error = action.error.message;
      setNotification(
        t("projectName"),
        t("messages.userStatusUpdatingFailed"),
        "failed",
        "statusUpdateAccountUsers"
      )
    });

    builder.addCase(updateAccountUsers.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(updateAccountUsers.fulfilled, (state, action) => {
      console.log('updateAccountUsers.fulfilled: ');
      state.loading = 'updated';
      setNotification(
        t("projectName"),
        t("messages.userUpdated"),
        "success",
        "updateAccountUsers"
      )
    });
    builder.addCase(updateAccountUsers.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      let resp = payload?.message ? (payload.message).toLowerCase() : "";
      let msgText = ""; 
      switch(resp) {
        case(ERR_NEED_USER_MERGE.toLowerCase()) : msgText = t("messages.userAlreadyExists"); 
          break;
        default: msgText = t("messages.userUpdationFailed"); 
          break; 
      }  
      setNotification(
        t("projectName"),
        msgText,
        "failed",
        "createAccountUsers"
      );
    });


  },
});

// Action creators are generated for each case reducer function
export const { setUsersList, setLoading, setCurrentPage } = usersListDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectUsersListDataSlice = (state: RootState) => state.usersListData;

export const usersListDataReducer = usersListDataSlice.reducer;
