import React, { useState, useEffect, useRef, useCallback, memo } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import dragdrop from "../../assets/images/drag-drop.svg";
import CloseRounded from "@mui/icons-material/CloseRounded";

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
} from "@mui/material";
import ReactCrop, { PixelCrop, centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { ButtonModal } from "../ButtonGroups/ButtonModal";
import { useTranslation } from "react-i18next";
import { isValidFileExtension } from "../../helpers/validators";
import { setNotification } from "../../services/apiService";

interface IProps {
  onUpload: (e: any) => void;
  defaultImage?: string | ArrayBuffer | null;
  id: string;
  label: string;
  url: string;
  required?: boolean;
  checkDisable?: boolean;
  hideDeleteOption?: boolean;
  resetImage?: number; 
}

const ImageBox: React.FC<IProps> = memo(({
  defaultImage,
  onUpload,
  required,
  checkDisable,
  id,
  hideDeleteOption,
  resetImage
}) => {
  
  const { t } = useTranslation();
  const wrapperRef = useRef<any | never>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const [currentimg, setCurrentimg] = useState<string | ArrayBuffer | null>("");
  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState<string | ArrayBuffer | null>(""); // Renamed state variable from selectfile to setSrc
  const [crop, setCrop] = useState<any>({ aspect: 1 / 1 });

  useEffect(() => {
    if (defaultImage !== undefined) {
      setCurrentimg(defaultImage);
    }
  }, []); // Removed defaultImage from the dependency array

  useEffect(() => {
    if (resetImage && resetImage > 0) {
      fileRemove(); 
    }
  }, [resetImage]); 

  //INORDER TO SET aspect RATIO
  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }
  const onImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      const { width, height } = e.currentTarget;
      setCrop({
        x: width / 35,
        y: height / 25,
        width: width - 2 * (width / 35),
        height: height - 2 * (height / 25),
        unit: "px",        
      });
      // setCrop(centerAspectCrop(width, height, 16/9))
    },
    []
  );

  const onDragEnter = useCallback(() => {
    wrapperRef.current?.classList.add("dragover");
  }, []);

  const onDragLeave = useCallback(() => {
    wrapperRef.current?.classList.remove("dragover");
  }, []);

  const onDrop = useCallback(() => {
    wrapperRef.current?.classList.remove("dragover");
  }, []);

  const onInputClick = useCallback((event: any) => {
    event.target.value = "";
  }, []);

  const dragImage = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) {
      return; // If no file is selected, exit the function
    }

    const fileName = file.name;
    if (!isValidFileExtension(fileName)) {
      // Invalid file extension, handle the error or show a message to the user
      setNotification(
        t("projectName"),
        t("messages.logoUploadFailed"),
        "failed",
        "uploadAccountLogo"
      );
      return; // Exit the function if the file extension is invalid
    }

    if (file) {
      setOpen(true);
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          setSrc(event.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
    e.target.value = "";
  }, []);

  const fileRemove = useCallback(() => {
    setCurrentimg("");
    onUpload("");
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "13px",
    boxShadow: 24,
  };

  const canvasPreview = useCallback(
    (image: HTMLImageElement, canvas: HTMLCanvasElement, crop: PixelCrop) => {
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        throw new Error("No 2d context");
      }

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const pixelRatio = window.devicePixelRatio;

      canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
      canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

      ctx.scale(pixelRatio, pixelRatio);
      ctx.imageSmoothingQuality = "high";

      const cropX = crop.x * scaleX;
      const cropY = crop.y * scaleY;

      const centerX = image.naturalWidth / 2;
      const centerY = image.naturalHeight / 2;

      ctx.save();

      ctx.translate(-cropX, -cropY);
      ctx.translate(centerX, centerY);
      ctx.translate(-centerX, -centerY);
      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight
      );

      ctx.restore();

      const dataURL = canvas.toDataURL("image/png");
      // console.log(dataURL, "dataURL");

      setCurrentimg(dataURL);
      onUpload(dataURL);
      setOpen(false);
    },
    []
  );

  const handleCrop = useCallback(() => {
    if (
      crop?.width &&
      crop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      canvasPreview(imgRef.current, previewCanvasRef.current, crop);
    }
  }, [canvasPreview, crop]);

  return (
    <>
      <label
        className="drop-file-input "
        ref={wrapperRef}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        htmlFor={id}
      >
        {!currentimg ? 
          <>
            <img src={dragdrop} alt="dragImage" />
            <p className="mt-15px mb-12px">{t("common.dragAndDropFilesHere")}</p>
            <p>{t("common.or")}</p>
            <button className="drag-btn">{t("common.browse")}</button>
          </>
          :
          <>
          <div className="drop-file " ref={wrapperRef}>
            <div className="drop-file-preview">
              <img
                src={`${currentimg}`}
                alt=""
                className="drop-file-preview_img"
                ref={wrapperRef}
              />
              {hideDeleteOption || checkDisable ? (
                <></>
              ) : (
                <span
                  className="drop-file-preview-item-del"
                  onClick={fileRemove}
                >
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                </span>
              )}
            </div>
          </div>
          </>
        }

        <input
          id={id}
          required={required}
          type="file"
          accept=".png, .jpg, .jpeg"
          onChange={dragImage}
          onClick={onInputClick}
          disabled={checkDisable}
        />
        
      </label>

      <div className="max-width-336px validFont text-end">{t("validImg")}</div>

      <Modal        
        open={open}
        onClose={() => setOpen(false)}
        aria-describedby="Crop image"
      >
        <Box sx={style}>
          <div className="w-100  manage-modal">
            <div className="space-between center border-bottom p-3">
              <h1> Crop</h1>
              <span onClick={() => setOpen(false)}>
                <CloseRounded className="pointer" />
              </span>
            </div>

            
            
            <CardContent className="cropbody">
              {src && (
                <ReactCrop
                  crop={crop}
                  onChange={(c, percentCrop) => setCrop(c)}
                  // aspect={16/9}
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={`${src}`}
                    onLoad={onImageLoad}
                    className="cropimage"
                  />
                </ReactCrop>
              )}
            </CardContent>
            <canvas ref={previewCanvasRef} className="cropcanvas" />

            <div className="px-3 py-4">
              <div className="flex w-100 gap-4">
                <ButtonModal
                  type="cancel"
                  onClick={() => setOpen(false)}
                  buttonLabel={t("common.cancel")}
                />

                <ButtonModal
                  buttonLabel=" Crop Image"
                  onClick={handleCrop}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
});

export default ImageBox;
