import React from "react";
import axios from "axios";
import applyCaseMiddleware from "axios-case-converter";
import { Store } from "react-notifications-component";
import { IconButton } from "@mui/material";
import { t } from "i18next";

import closeIcon from "../assets/images/storeCloseIcon.svg";
import warningIcon from "../assets/images/storeWarningIcon.svg";
import successIcon from "../assets/images/storeSuccessIcon.svg";
import failedIcon from "../assets/images/storeFailedIcon.svg";
import {
  ROUTE_ACCOUNTS_LOGIN,
  ROUTE_SA_LOGIN,
  USER_ROLE_ACCOUNT_MANAGER,
  USER_ROLE_ACCOUNT_USER,
  USER_ROLE_ADMIN_ADMIN,
  USER_ROLE_ADMIN_SUPERADMIN,
  env_var,
  notification_shown_time_failed,
} from "../config/env";
import { getFormDataHeader, getHeaderInfo } from "../helpers/tokenCreator";
import { removeTokens } from "./localStorage";

const role = localStorage.getItem('adminUserRole'); 
const client = applyCaseMiddleware(axios.create());

export const setNotification = (title, content, type, id) => {
  return Store.addNotification({
    title: title,
    content: (
      <div className="relative w-100">
        <div className="defaultNotification">
          <div>
            <img
              src={
                type === "warning"
                  ? warningIcon
                  : type === "success"
                  ? successIcon
                  : type === "failed"
                  ? failedIcon
                  : failedIcon
              }
              alt="icon"
            />
          </div>
          <div className="w-100">
            <div className="space-between ">
              <h1>{title}</h1>
              <IconButton>
                <img src={closeIcon} alt="" />
              </IconButton>
            </div>
            <div>
              <p>{content}</p>
            </div>
          </div>
        </div>
      </div>
    ),
    type: "default",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: notification_shown_time_failed,
      // onScreen: true, 
      pauseOnHover: true,
    },
  });
};

// Function to make a GET request with authentication headers.
export const get = async function (url: any, params: any = {}) {
  const header = await getHeaderInfo();
  const resp = await client.get(env_var.API_URL + url, { ...header, params });
  return resp;
};

// Function to make a GET request without authentication headers.
export const getWithoutAuth = async function (url: any, params: any = {}) {
  const resp = await client.get(env_var.API_URL + url, { ...params });
  return resp;
};

// Function to make a GET request with authentication headers and expecting an image (arraybuffer) response.
export const getAuthImg = async function (url: any, params: any = {}) {
  const header = await getHeaderInfo();
  const resp = await client.get(env_var.API_URL + url, {
    ...header,
    responseType: "arraybuffer",
    params,
  });
  return resp;
};

// Function to make a POST request with authentication headers.
export const post = async function (url: string, body: any) {
  const header = await getHeaderInfo();
  const resp = await client.post(env_var.API_URL + url, body, header);
  return resp;
};

// Function to make a POST request without authentication headers.
export const postWithoutAuth = async function (url: string, body: any) {
  const resp = await client.post(env_var.API_URL + url, body);
  return resp;
};

// Function to make a PATCH request with authentication headers.
export const patch = async function (url: string, body: any) {
  const header = await getHeaderInfo();
  const resp = await client.patch(env_var.API_URL + url, body, header);
  return resp;
};

// Function to make a PUT request with authentication headers.
export const put = async function (url: any, body: any) {
  const header = await getHeaderInfo();
  const resp = await client.put(env_var.API_URL + url, body, header);
  return resp;
};

// Function to make a DELETE request with authentication headers.
export const deleteApi = async function (url: any) {
  const header = await getHeaderInfo();
  const resp = await client.delete(env_var.API_URL + url, header);
  return resp;
};

// Function to upload a file using a POST request with multipart/form-data content type.
export const postFile = async function (url: string, body: any) {
  const header = await getFormDataHeader();
  const formData = new FormData();
  formData.append("file", body);
  try {
    const resp = await client.post(env_var.API_URL + url, formData, header);
    return resp;
  } catch (err) {

    setNotification(
      t("messages.fileFormatError"),  
      t("messages.invalidFileFormat"),
      "failed",
      "getFormDataHeader"
    )

  }
};

// Function to download a file from a URL with an optional file name.
export const getFile = async function (
  url: string,
  name: string,
  params: any = {}
) {
  const header = await getHeaderInfo();
  const response = await client.get(env_var.API_URL + url, {
    ...header,
    params,
  });
  const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = urlBlob;
  if (name) {
    link.setAttribute("download", name + ".csv");
  } else {
    link.setAttribute(
      "download",
      response.headers["contentDisposition"] + ".csv"
    );
  }
  document.body.appendChild(link);
  link.click();
};

// Function to download a PNG image file from a URL with a specified name.
export const getPnGFile = async function (url: string, name: string) {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL(this.response);
    var tag = document.createElement("a");
    tag.href = imageUrl;
    tag.download = name;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.send();
};


// Function to download a PDF file from a URL with a specified name.
export const getPdfFile = async function (
  url: string,
  name: string,
  params: any = {}
) {
  const response = await client.get(url);
  const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = urlBlob;
  link.setAttribute("download", name + ".pdf");
  document.body.appendChild(link);
  link.click();
};



// FUNCTION TO RE-DIRECT TO LOGIN PAGIN, IF TOKEN IS EXPIRED OR LOGOIN IS UNAUTHORISED
export const setIntercept = (): void => {
  client.interceptors.response.use(
      (response) => {
          return response;
      },
      (error) => { 
        let userRole =  role ? role : localStorage.getItem('adminUserRole'); 
        let reqURL = error?.response?.config?.url; 
        if(reqURL && (reqURL.search("/admin-token/") > 0 || reqURL.search("/hotel-token/") > 0)) { // FROM LOGIN PAGE 
          return error;
        }
        else if (Number(error.response.status) === 401) { // UNAUTHORISED  
          if((reqURL && reqURL.search("/hotel-users/") > 0)) { // FROM ACCOUNTS LOGIN SECTION 
            removeTokens();
            window.location.href = ROUTE_ACCOUNTS_LOGIN; 
          }
          else {
            setTimeout(() => {
              if((Number(userRole) === USER_ROLE_ADMIN_SUPERADMIN || Number(userRole) === USER_ROLE_ADMIN_ADMIN)) {  
                removeTokens();
                window.location.href = ROUTE_SA_LOGIN; 
              }
              else if((Number(userRole) === USER_ROLE_ACCOUNT_MANAGER || Number(userRole) === USER_ROLE_ACCOUNT_USER)) {  
                removeTokens();
                window.location.href = ROUTE_ACCOUNTS_LOGIN; 
              }
              else {
                removeTokens();
                window.location.href = ROUTE_ACCOUNTS_LOGIN; 
              }
            }, 2500);
          }
        }
        else { 
          return Promise.reject(error);
        }
      }
  );
};