import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "./apiService";
import { QRCODE_API_URL_ORDERS_HISTORY, QRCODE_API_URL_SKU_PRODUCT_NAMES } from "../config/env_qrcodes";
import { IAccIdPayload } from "../models/qrCodeInterfaces";

export const fetchOrderHistory = createAsyncThunk(
    'qrCode/fetchOrderHistory',
    async (data: { accountId: string, page: number, numRowsPerPage: number }, thunkAPI: any) => {
        try {
            let url = QRCODE_API_URL_ORDERS_HISTORY + '?account_id=' + data.accountId.toString();
            if (data.page) { url = url + '&page=' + data.page.toString(); }
            if (data.numRowsPerPage) { url = url + '&page_size=' + data.numRowsPerPage.toString(); }
            const response = get(url);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const fetchSKUNames = createAsyncThunk(
    'qrCode/fetchSKUNames',
    async (data: {}, thunkAPI: any) => {
        try {
            let url = QRCODE_API_URL_SKU_PRODUCT_NAMES;
            const response = get(url);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


