import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../helpers/hook";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import continu from "../../../assets/images/arrow1.svg";
import {
  ROUTE_ACCOUNTS_LOGIN,
  BANK_ACC_TYPE_ID_CHECKING,
  BANK_ACC_TYPE_ID_SAVING,
} from "../../../config/env";
import SignUpLeftPane from "../SignUpLeftPane";

import { achUpdate } from "../../../services/signUpServices";
import { setNotification } from '../../../services/apiService';

import { IACHData } from "../../../models/signUpInterfaces";
import { selectAchDataSlice } from "./achSlice";
import { SelectField } from "../../../components/SelectField";
import { Textfield } from "../../../components/TextField";

function ACHSignUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const newAccountSecretId = localStorage.getItem("newAccountSecretId");
  const newAccountUserFirstName = localStorage.getItem(
    "newAccountUserFirstName"
  );
  const newAccountUserLastName = localStorage.getItem("newAccountUserLastName");
  const newAccountHotelId = localStorage.getItem("newAccountHotelId");
  const newAccountUserEmail = localStorage.getItem("newAccountUserEmail");

  const [signUpStage, setSignUpStage] = useState(6);
  const arrBankAccTypes = [
    { id: BANK_ACC_TYPE_ID_CHECKING, value: t("ach.bankAccountChecking") },
    { id: BANK_ACC_TYPE_ID_SAVING, value: t("ach.bankAccountSavings") },
  ];

  const [bankAccTypeId, setBankAccTypeId] = useState(
    BANK_ACC_TYPE_ID_CHECKING.toString()
  );
  const [bankName, setBankName] = useState("");
  const [bankAccNumber, setBankAccNumber] = useState("");
  const [bankAccNumberConfirm, setNankAccNumberConfirm] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accHolderName, setAccHolderName] = useState(
    newAccountUserFirstName + " " + newAccountUserLastName
  );
  const [accountsMismatchClass, setAccountsMismatchClass] = useState("");
  const [validRoutingNumClass, setValidRoutingNumClass] = useState("");

  const { achUpdateStatus } = useAppSelector(selectAchDataSlice);

  
  React.useEffect(() => {
    if (bankAccNumber.length > 0 && bankAccNumberConfirm.length > 0) {
      setAccountsMismatchClass(
        bankAccNumber == bankAccNumberConfirm ? "" : "red-border"
      );
    }
  }, [bankAccNumber, bankAccNumberConfirm]);

  React.useEffect(() => {
    if (routingNumber.toString().length > 0) {
      setValidRoutingNumClass(
        routingNumber.toString().length == 9 ? "" : "red-border"
      );
      if (routingNumber.toString().length > 9) {
        setRoutingNumber(routingNumber.toString().substring(0, 9));
      }
    }
  }, [routingNumber]);

  const onHandleSave = (e) => {
    e.preventDefault();
    let foundError = 0;
    if (bankAccNumber.trim() == "" || bankAccNumber != bankAccNumberConfirm) {
      foundError = 1;
      setAccountsMismatchClass("red-border");
    }
    if (routingNumber.toString().length != 9) {
      foundError = 1;
      setValidRoutingNumClass("red-border");
    }

    if (foundError > 0) {
      return false;
    } else {
      const req: IACHData = {
        country: t("defaultCountry"),
        currency: t("defaultCurrency"),
        account_holder_name: accHolderName,
        account_number: bankAccNumber,
        routing_number: routingNumber,
        bank_name: bankName,
        account_type: Number(bankAccTypeId),
        hotel_id: Number(newAccountHotelId),
        secret_id: newAccountSecretId ? newAccountSecretId : "",
      };
      dispatch(achUpdate(req));
    }
  };

  const gotoLogin = () => {
    navigate(`${ROUTE_ACCOUNTS_LOGIN}`);
  };

  React.useEffect(() => {
    if (achUpdateStatus == "succeeded") {
      setNotification(
        t("projectName"),
        t("ach.achAuthorizationSuccess"),
        "success",
        "achAuthorizationSuccess"
      );
      setTimeout(() => {
        navigate(`${ROUTE_ACCOUNTS_LOGIN}`);
      }, 1500);
    } else if (achUpdateStatus == "error") {
      setNotification(
        t("projectName"),
        t("ach.achAuthorizationFailed"),
        "failed",
        "achAuthorizationFailed"
      );
    }
  }, [achUpdateStatus]);

  return (
    <div>
      <div className="w-100 flex">
        <div className="w-30perc ">
          <SignUpLeftPane signUpStep={signUpStage} />
        </div>
        <div className="w-70perc bg-light ">
          <div className="center  ">
            <form className="" onSubmit={(e) => onHandleSave(e)}>
              <div className="business w-100">
                <h1>{t("ach.ACHHeadingn")}</h1>
                <h2>{t("ach.subHeading")}</h2>
                <div>
                  <div className="label">{t("ach.accountTypeLabel")}</div>
                  <SelectField
                    name="sel_accType"
                    id="sel_accType"
                    className="rounded-pill bg-white mb-18px"
                    value={bankAccTypeId}
                    onChange={(e) => setBankAccTypeId(e.target.value)}
                    required                  
                    options={arrBankAccTypes &&
                      arrBankAccTypes.map((item, index) => (
                        <>
                          <option value={item.id.toString()}>
                            {item.value}
                          </option>
                        </>
                      ))}
                  />
                </div>

                <div className="flex gap-4 mt-2">
                  <div className="w-100">
                    <div className="label div-left">
                      {t("ach.accountNumberLabel")}
                    </div>
                    <Textfield
                      type="text"
                      name="txt_bankAccNumber"
                      id="txt_bankAccNumber"
                      placeholder={`${t("ach.accountNumberPlaceHolder")}`}
                      required
                      maxLength={150}
                      value={bankAccNumber}
                      onChange={(e) => setBankAccNumber(e.target.value)}
                    />
                  </div>
                  <div className="w-100">
                    <div className="label div-left">
                      {t("ach.confirmAccountNumberLabel")}
                    </div>
                    <Textfield
                      type="text"
                      name="txt_bankAccNumberConfirm"
                      id="txt_bankAccNumberConfirm"
                      placeholder={`${t("ach.confirmAcNoPlaceHolder")}`}
                      required
                      maxLength={150}
                      value={bankAccNumberConfirm}
                      onChange={(e) => setNankAccNumberConfirm(e.target.value)}
                      className={accountsMismatchClass}
                    />
                  </div>
                </div>

                <div className="flex gap-4 mt-2">
                  <div className="w-100">
                    <div className="label div-left">
                      {t("ach.routingNumberLabel")}
                    </div>
                    <Textfield
                      type="number"
                      name="txt_routingNumber"
                      id="txt_routingNumber"
                      placeholder={`${t("ach.routingNumberPlaceHolder")}`}
                      required
                      maxLength={9}
                      value={routingNumber}
                      onChange={(e) => setRoutingNumber(e.target.value)}
                      className={validRoutingNumClass}
                    />
                  </div>
                  <div className="w-100">
                    <div className="label div-left">
                      {t("ach.accountHoldersNameLabel")}
                    </div>
                    <Textfield
                      type="text"
                      name="txt_accHolderName"
                      id="txt_accHolderName"
                      placeholder={`${t("ach.accountHolderPlaceHolder")}`}
                      required
                      maxLength={150}
                      value={accHolderName}
                      onChange={(e) => setAccHolderName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="border-bottom mt-101px">&nbsp;</div>

                <div className="flex-end">
                  <div className="w-100 flex-end">
                    <button type="submit" className="">
                      {t("ach.ContinueButon")} &nbsp;{" "}
                      <img src={continu} alt="" />
                    </button>
                  </div>
                </div>

                <div className="flex gap-4 mt-2 ">
                  <div className="w-100"></div>
                  <div className="w-100 center">
                    <p className="font-500 text-grey">
                      {" "}
                      <a
                        href="#"
                        onClick={(e) => gotoLogin()}
                        className="link-blue link-p"
                      >
                        {t("ach.para1")}
                      </a>
                      {t("ach.para2")}
                    </p>
                  </div>
                </div>

                <div className="flex-end "></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ACHSignUp;
