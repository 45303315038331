import React from "react";
import { Col, Row } from "react-bootstrap";

import footerBg from "../assets/images/web-footerBg.png";
import tova from "../assets/images/tova-logo-web.png";
import inneed from "../assets/images/web-indeed.svg";
import clock from "../assets/images/nav-clock-web.svg";
import mail from "../assets/images/nav-mail-web.svg";
import phone from "../assets/images/nav-phone-web.svg";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTE_SCHEDULE_DEMO } from "../config/env";
import ContactForm from "./ContactForm";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="web-footer  overflow-hidden p-3 p-md-0">
      <div className="relative">
        <img src={footerBg} alt="footer board" className="footerBg" />

        <Row className="w-full max-w-1400px m-auto py-5">
          <Col className="footer-section1 w-full">
            <div>
              <h5>
                {t("footer.try")}
                <span className="text-info">{t("footer.tova")}</span>{" "}
                {t("footer.out")}
              </h5>
              <div className="flex gap-4">
                <a href={ROUTE_SCHEDULE_DEMO}
                  target="blank"
                  className="signup-button"
                >
                  {t("footer.scheduleDemo")}
                </a>
                {/* <button className="contact-btn">
                  {t("footer.contactBtn")}
                </button> */}
                <ContactForm className={'contact-btn'} label={`${t("footer.contactBtn")}`}/>
              </div>
            </div>
            <div>
              <p>{t("footer.tryTovaPara")}</p>
            </div>
          </Col>
        </Row>
      </div>

      <div className="footer-section2 pt-md-4">
        <Row className="w-full center  flex gap-5 py-5 mb-md-5">
          <div
            className="max-w-1400px space-between "
            style={{ flexWrap: "wrap" }}
          >
            <Col xs={12} md={6} lg={5} className="text-start me-5">
              <img src={tova} alt={`${t("footer.tova")}`} className="mb-4" />
              <div className="footer-section2Box">{t("footer.sayThanks")}</div>
            </Col>

            <Col
              xs={12}
              md={3}
              lg={1}
              className="flex-col text-start gap-1 me-3 pt-3 pt-lg-0"
            >
              <h5>{t("footer.company")}</h5>
              <div><a href="/">{t("footer.home")}</a></div>
              <div><a href="/about">{t("footer.about")}</a></div>
              <div><a href="/support">{t("footer.help")}</a></div>
            </Col>

            <Col
              xs={12}
              md={3}
              lg={2}
              className="flex-col text-start gap-1 me-3 pt-3 pt-lg-0"
            >
              <h5>{t("footer.support")}</h5>
              <div className="center flex-start gap-3 cursor-pointer">
                <img src={mail} alt={`${t("footer.tova")}`} />
                {t("footer.mail")}
              </div>
              <div className="center flex-start gap-3 cursor-pointer">
                <img src={phone} alt={`${t("footer.tova")}`} />
                {t("footer.phone")}
              </div>
              <div className="center flex-start gap-3">
                <img src={clock} alt={`${t("footer.tova")}`} />
                {t("footer.time")}
              </div>
            </Col>
          </div>
        </Row>

        <div className="w-100 mt-md-5">
          <Row className="max-w-1400px py-4  mt-161px flex-center  border-top-liteblue mx-auto">
            <Col xs={12} md={2} className="border-right-liteblue  px-0 pointer">
              {t("footer.web-copyright")}
            </Col>
            <Col xs={12} md={1} className="border-right-liteblue ps-md-3 px-0 pointer">
              {t("footer.web-privacy")}
            </Col>
            <Col xs={12} md={2} className="ps-md-3 px-0 pointer">
              {t("footer.t&c")}
            </Col>
            <Col className="flex justify-content-start justify-content-md-end   gap-2 center  px-0">
              <a
                href="https://www.linkedin.com/company/tovatipping"
                target="blank"
              >
                <img src={inneed} alt={`${t("footer.icon")}`} />
              </a>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Footer;
