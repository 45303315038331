import React from 'react'
import { useTranslation } from 'react-i18next';
import backButton from '../../assets/images/backButton.svg'
import { ROUTE_FORGOT_PASSEMAIL_TEMPLATE } from '../../config/env';
function ForgotPassword() {
    const { t } = useTranslation();
  return (
    <div className='center mt-171px' >
    <form className='loginbox '>
        <div className='space-between center'>
            <div className='pointer-cursor'><img src={backButton} alt="backButton" /></div>
        <h2 className='mb-0 mt-2px'>{t("forgotPassword.headingForgotpassword")}</h2>
        <div></div>
        </div>
       
        <h1 className='mt-8px'>{t("forgotPassword.headingEnterYourEmail")}</h1>
        <h1 className='mb-30px mt-7px mx-18px'>{t("forgotPassword.headingEnterYourEmail2")}</h1>
        <input type="text" className='w-100' placeholder={`${t("common.email")}`}/>
        <button className='rounded-3 p-12px text-white border-none mt-18px' style={{backgroundColor:"#3871E0"}}>{t("accountLoginPage.btnLogin")}</button>
        <a href={ROUTE_FORGOT_PASSEMAIL_TEMPLATE}>{t("forgotPassword.forgotEmail")}</a>
    </form>
</div>
  )
}

export default ForgotPassword