// PAFE TO DEFINE COMMON VALIDATOR FUNCTIONS 

// MANDATORY VALUE VALIDATOR
export const isRequired = (value: any, min = 1, max = 100) => {
  if (!value) return 'Required!';
  let error = '';
  if (!value && value.length) error = 'Required';
  if (min && !value && value.length < min) error = `Min Length is ${min}`;
  if (max && !value && value.length > max) error = `Max Length is ${max}`;
  return error;
};

// MATCH CHECKING VALIDATOR
export const isMatch = (a: any, b: any) => {
  let error = '';
  if (!a.length) error = 'Required';
  if (a.trim() !== b.trim()) error = 'No Match Found';
  return error;
};

// VALID EMAIL VALIDATOR
export const isEmail = (value: any) => {
  let errors = '';
  var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (value=='' || !value) {
    errors = 'Required!';
  } else if (!value.match(mailformat)) {
    errors = 'Invalid email address!';
  }
  return errors;
};

// VALID PHONE NUMBER VALIDATOR
export const isPhone = (value: any) => {
  let errors = '';
  if (!value) {
    errors = 'Required!';
  } else if (!/^[6-9]\d{9}$/.test(value)) {
    errors = 'Invalid phone number!';
  }
  return errors;
};

// PASSWORD VALIDATOR
export const isPassword = (value: any) => {
  let errors = '';
  if (!value) {
    errors = 'Required!';
  } else if (value && value.length < 9) {
    errors = 'Password must be 9 characters long';
  }
  return errors;
};

// USERNAME VALIDATOR
export const isUsername = (value: any) => {
  let errors = '';
  if (!value) {
    errors = 'Required!';
  } else if (value && value.length < 9) {
    errors = 'Username must be 9 characters long';
  }
  return errors;
};

// IMAGE VALIDATOR
export const isImage = (data: any, size: any) => {
  if (!data) return 'Please Select An  Image !';
  if (typeof data === 'object') {
    if (Object.keys(data).length) {
      if (size) {
        if (data.size * 1000 >= size) {
          return `Please Select a File Below ${size} kb`;
        }
        return '';
      }
    } else {
      return 'Invalid Image';
    }
  } else if (typeof data === 'string') {
    if (!data.length) {
      return 'Please provide image !';
    }
    return '';
  } else {
    return 'Please provide image !';
  }
  return '';
};

// VALID IMAGETYPE VALIDATOR
export function isValidFileExtension(fileName) {
  let allowedExtensions = ["png", "jpg", "jpeg"];
  const fileExtension = fileName.split(".").pop().toLowerCase();
  return allowedExtensions.includes(fileExtension);
}
