import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Col } from "react-bootstrap";
import closeIcon from "../assets/images/web-modal-close.svg";
import { IconButton } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import {
  GOOGLEANALYTICS_SCRIPT,
  GOOGLEANALYTICS_SOURCE,
  PHONEINPUT_COUNTRIES,
  PHONEINPUT_COUNTRYCODE_EDITABLE,
  ZOOMINFO_SCRIPT,
} from "../config/env";
import { useAppDispatch } from "../helpers/hook";
import {  contactUs } from "../services/commonServices";
import { setNotification } from "../services/apiService";

const style = {
  bgcolor: "white",
  border: "1px solid white",
  borderRadius: "32px",
  overFlow: "auto",
  // boxShadow: 24,
  // p: 4,

  position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "333px",
    maxWidth:"1125px",
    maxHeight: "789px",
    // overflow: "scroll",
    width: "100%",
    height: "100%",
    display: "block",
    

};

const ContactForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setFirstName("");
    setLastName("");
    setMobile("");
    setEmail("");
    setMessage("");
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleContact = (e) => {
    e.preventDefault();
    if(formatPhoneNumber(mobile)===0){
      setNotification(
        t("projectName"),
        t("messages.webinvalidMobile"), 
        "failed",
        "vaildMobileNumber"
      )
    }else{
      setOpen(false)
      // dispatch(contactUs( firstName, lastName,formatPhoneNumber(mobile),email,message,'production'));
      dispatch(contactUs( firstName, lastName,formatPhoneNumber(mobile),email,message) );
    }
  };
  const formatPhoneNumber = (number) => {
    const cleanedNumber = number.replace(/[^\d]/g, '');
    let formattedNumber = cleanedNumber;

    if (cleanedNumber.startsWith('1')) {
      formattedNumber = cleanedNumber.slice(1);
    }

    if (formattedNumber.length === 10) {
      const match = formattedNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      }
    }
    return 0;
  };

  useEffect(() => {
    // *********************************************************************
    // GOOGLE ANALYTICS CODE 
    // *********************************************************************
    if(GOOGLEANALYTICS_SOURCE.length > 1 && GOOGLEANALYTICS_SCRIPT.length > 1) {
      const script1 = document.createElement("script");
      script1.setAttribute("src", GOOGLEANALYTICS_SOURCE);
      document.head.appendChild(script1);
  
      const script2 = document.createElement("script");
      script2.innerHTML = GOOGLEANALYTICS_SCRIPT; 
      document.head.appendChild(script2)
      return () => {
        // clean up the script when the component in unmounted
        document.head.removeChild(script1);
        document.head.removeChild(script2); 
      }
    }
    

    // *********************************************************************
    // ZOOMINFO CODE 
    // *********************************************************************
    if(ZOOMINFO_SCRIPT.length > 0) {
      const script = document.createElement("script");
      script.innerHTML = ZOOMINFO_SCRIPT;
      document.head.appendChild(script)
      return () => {
        // clean up the script when the component in unmounted
        document.head.removeChild(script)
      }
    }
  }, []); 
  
  return (
    <>
      <button className={props.className} onClick={handleOpen}>
        {props.label}
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="web-modal px-4 py-5 px-md-5" >
          <form action="submit" onSubmit={(e) => handleContact(e)}>
            <IconButton
              sx={{ position: "absolute", right: "15px", top: "15px" }}
              // sx={{ position: "absolute", top: "31px", right: "31px" }}
              className="mt-0 mt-md-4 me-0 me-md-4 "
              onClick={handleClose}
            >
              <img src={closeIcon} alt="" />
            </IconButton>
            <h1 className="mb-5 mt-3">{t("home.getInTouchHeading")}</h1>
            <div className="flex mb-4" style={{ flexWrap: "wrap" }}>
              <Col xs={12} md={6} lg={6}>
                <label htmlFor="firstName">{t("home.modalFirstName")}</label>
                <input
                  type="text"
                  id="firstName"
                  placeholder={`${t("home.modalFirstNamePlaceholder")}`}
                  required
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Col>
              <Col xs={12} md={6} lg={6} className="ps-0 ps-md-3">
                <label htmlFor="lastName">{t("home.modalLastName")}</label>
                <input
                  type="text"
                  id="lastName"
                  placeholder={`${t("home.modalLastNamePlaceholder")}`}
                  required
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Col>
            </div>
            <div className="flex mb-4" style={{ flexWrap: "wrap" }}>
              <Col xs={12} md={6} lg={6} className="hidesl">
                <label htmlFor="">{t("home.modalMobile")}</label>
                {/* <input type="text" id="firstName" placeholder={`${t("home.modalMobilePlaceholder")}`} value={mobile} onChange={(e)=>setMobile(e.target.value)}/> */}
                <PhoneInput
                  inputProps={{
                    name: `mobile`,
                    required: true,
                    autoFocus: false,
                  }}
                  onlyCountries={PHONEINPUT_COUNTRIES}
                  countryCodeEditable={PHONEINPUT_COUNTRYCODE_EDITABLE}
                  country={"us"}
                  prefix={"+"}
                  placeholder={`${t("home.modalMobilePlaceholder")}`}
                  value={mobile}
                  onChange={(e) => setMobile(e)}
                />
              </Col>
              <Col xs={12} md={6} lg={6} className="ps-0 ps-md-3">
                <label htmlFor="mail">{t("home.modalEmail")}</label>
                <input
                  type="email"
                  id="mail"
                  placeholder={`${t("home.modalEmailPlaceholder")}`}
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
            </div>
            <Col xs={12} md={12} lg={12}>
              <label htmlFor="message">{t("home.modalMessage")}</label>
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="5"
                className="max-w-1400px"
                placeholder={`${t("home.modalMessagePlaceholder")}`}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </Col>
            <div className="flex justify-content-start justify-content-md-end mt-4">
              <button className="btn-lite-blue " type="submit">
                {t("home.modalSendButton")}
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default ContactForm;
