import { get, post } from './apiService';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SA_API_URL_GET_TERMS_AND_C, SA_API_URL_SAVE_TERMS_AND_C } from '../config/api_urls';

// FUNCTION TO GET T&C
export const getTerms = createAsyncThunk(
    'terms/getTerms',
    async (termsGroupId: string, thunkAPI: any) => {
        try {
            let url = SA_API_URL_GET_TERMS_AND_C+'?group=' + termsGroupId;
            const response = get(url);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// FUNCTION TO UPDATE T&C
export const saveTerms = createAsyncThunk(
    'terms/saveTerms',
    async (data: any, thunkAPI: any) => {
        try {
            let url = SA_API_URL_SAVE_TERMS_AND_C; 
            const response = post(url, data);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

