import { createAsyncThunk } from "@reduxjs/toolkit";
import {  post } from "../../../services/apiService";
import { SA_API_URL_ACCOUNT_LOGO_UPLOAD } from "../../../config/api_urls";

export interface IUploadLogo {
    id: number;
    secretId: string;
    logo:unknown;
  }

export const uploadLogoImage = createAsyncThunk(
    'uploadLogoImage',
    async (data: IUploadLogo, thunkAPI: any) => {
      try {
        const url = SA_API_URL_ACCOUNT_LOGO_UPLOAD+data.id+'/';
        const response = post(url, data);
        return (await response).data;
      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );
  