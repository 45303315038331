import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../helpers/hook";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Store } from "react-notifications-component";

import yelloWarning from "../../assets/images/yellowrm.svg";

import Table from "../../components/Table";
import Paginations from "../../components/Pagination";
import FilterSection from "../../components/FilterSection";
import { ButtonModal } from "../../components/ButtonGroups/ButtonModal";
import {WithholdingsTableHeaders} from "../../components/Table/TableHeaders";

import { getAdminUserRole, getFilterAccountId, setLastActivePageAPIData, } from "../../services/localStorage"; 
import { exportWithholdList, fetchWithholdList, handleDistribute } from "../../services/withholdListServices";

import { DATEFILTER_ALLDATES, PAGENAME_WITHHOLDINGS, PAGINATION_NUM_ROWS, } from "../../config/env";
import { PERM_PAYOUTS_EXPORT, PERM_PROCESS_WITHHOLDINGS, checkActionPermission, } from "../../config/permissions";

import { IWithholdingsInterfaces } from "../../models/withholdListInterfaces";
import { WithholdingsListDataSlice, setCurrentPage } from "./withholdingsSlice";


const Withholdings = () => { 

  // ************** TO SET DATE FILTER TO THISMONTH BY DEFAULT - START **************
  let defaultFromDate = "";  
  let defaultToDate = ""; 
  // ************** TO SET DATE FILTER TO THISMONTH BY DEFAULT - END ***************

  const prevAPIDataString = localStorage.getItem('lastActivePageAPIData');
  const prevAPIData = prevAPIDataString ? JSON.parse(prevAPIDataString) : {};
  const pageName = PAGENAME_WITHHOLDINGS; 
  const adminUserRole = getAdminUserRole();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const localAccId = getFilterAccountId();

  const [bgShade, setBgShade] = useState(false);
 
  const { withholdingList,totalSize,currentPage } = useAppSelector(WithholdingsListDataSlice);

  const [selectedAccountIds, setSelectedAccountIds] = useState("");

  const [textSearchFilter, setTextSearchFilter] = useState<string>("");
  const [status, setStatus] = useState("");
  
  const [fromDate, setfromDate] = useState<string>(defaultFromDate);
  const [toDate, setToDate] =  useState<string>(defaultToDate); 
  const [dateFilterType, setDateFilterType] = useState(DATEFILTER_ALLDATES);

  const [applyFilter, setApplyFilter] = useState<number>(0);
  const [sortField, setSortField] = useState("year");
  const [selectedPayeeIds, setSelectedPayeeIds] = useState<string>("");
  const [selectedPayeeNames, setSelectedPayeeNames] = useState<string>("");
  const [selectedPayeeTypes, setSelectedPayeeTypes] = useState<string>("");
  const [numRowsPerPage, setNumRowsPerPage] = useState(PAGINATION_NUM_ROWS);
  const [initialAPICalled, setInitialAPICalled] = useState(false);

  // 'accept...' VARIABLES USED TO SET/RESET FILTERS ON REFRESH   
  const [acceptAccountFilterIds, setAcceptAccountFilterIds] = useState("");
  const [acceptDateFilterData, setAcceptDateFilterData] = useState(""); 
  const [acceptMultiSelectStatusIds, setAcceptMultiSelectStatusIds] = useState(""); 
  const [acceptPayeeFilterData, setAcceptPayeeFilterData] = useState(""); 
  const [acceptSearchText, setAcceptSearchText] = useState(""); 

  const fetchall = (): void => {
    
    if(!initialAPICalled && prevAPIData.pageName && prevAPIData.pageName==pageName && prevAPIData?.apiData?.hotel && prevAPIData.apiData.hotel.length > 0) { 
      dispatch(fetchWithholdList(prevAPIData.apiData));
      
      dispatch(setCurrentPage(prevAPIData.apiData.page));
      setNumRowsPerPage(prevAPIData.apiData.numRowsPerPage);  
      setTextSearchFilter(prevAPIData.apiData.search);
      setStatus(prevAPIData.apiData.status);
      setfromDate(prevAPIData.apiData.start);
      setToDate(prevAPIData.apiData.end);
      setDateFilterType(prevAPIData.apiData.dateFilterType);
      setSortField(prevAPIData.apiData.sort);
      setSelectedPayeeIds(prevAPIData.apiData.payee); 
      setSelectedPayeeTypes(prevAPIData.apiData.payeeType); 
      setSelectedPayeeNames(prevAPIData.apiData.payeeNames);
      setSelectedAccountIds(prevAPIData.apiData.hotel);         

      if(prevAPIData.apiData.hotel && prevAPIData.apiData.hotel.trim().length > 0) {
        setAcceptAccountFilterIds(prevAPIData.apiData.hotel); 
      }
      if(prevAPIData.apiData.search && prevAPIData.apiData.search.trim().length > 0) {
        setAcceptSearchText(prevAPIData.apiData.search); 
      }
      if(prevAPIData.apiData.dateFilterType && prevAPIData.apiData.dateFilterType.trim().length > 0) {
        setAcceptDateFilterData(prevAPIData.apiData.dateFilterType); 
      }
      if(prevAPIData.apiData.status && prevAPIData.apiData.status.trim().length > 0) {
        setAcceptMultiSelectStatusIds(prevAPIData.apiData.status); 
      }

      setTimeout(() => {
        if(prevAPIData.apiData.payee && prevAPIData.apiData.payee.trim().length > 0 && prevAPIData.apiData.payeeNames && prevAPIData.apiData.payeeNames.trim().length > 0) {
          setAcceptPayeeFilterData(prevAPIData.apiData.payee+':~~:'+prevAPIData.apiData.payeeNames); 
        } 
        setInitialAPICalled(true);
      }, 1250);
    }
    else if(selectedAccountIds.length>0){ 
      const req: IWithholdingsInterfaces = {
        page: currentPage,
        numRowsPerPage: numRowsPerPage,
        search: textSearchFilter.trim(),
        status: status,
        start: fromDate,
        end: toDate,
        sort: sortField,
        payee: selectedPayeeIds,
        payeeType: selectedPayeeTypes, 
        payeeNames: selectedPayeeNames,
        hotel: selectedAccountIds ?? localAccId,
      };
      setAcceptDateFilterData(DATEFILTER_ALLDATES);
      dispatch(fetchWithholdList(req));  
      let saveData = {...req, dateFilterType:dateFilterType}
      setLastActivePageAPIData({pageName:pageName, apiData:saveData});   
      setInitialAPICalled(true);
    }
  };

  useEffect(() => {
    fetchall();
  }, [
    currentPage,
    sortField,
  ]);

  useEffect(() => {
    if (currentPage != 1) {
      acceptPage(1);
    } else {
      fetchall();
    }
  }, [selectedAccountIds, applyFilter, status, selectedPayeeIds, textSearchFilter, numRowsPerPage]);

  const handleSort = (sortField: string) => {
    setSortField(sortField);
  };

  const acceptPage = (e: any): void => {
    dispatch(setCurrentPage(e));    
  };

  const clearFilters = () => {
    setSelectedAccountIds(localAccId ? localAccId : "");
    setDateFilterType(DATEFILTER_ALLDATES); 
    setfromDate(defaultFromDate);
    setToDate(defaultToDate);
    setStatus("");
    acceptPage(1);
    setTextSearchFilter("");
    setSelectedPayeeIds("");
    setSelectedPayeeNames(""); 
    setSelectedPayeeTypes("");
    setNumRowsPerPage(PAGINATION_NUM_ROWS);
    setApplyFilter(applyFilter + 1);
  };

  const setDateFilterDatesType = (fromDate: string, toDate: string, filterType: string) => {
    if (fromDate.trim().length > 1) {
      setfromDate(dayjs(fromDate).format(`YYYY-MM-DDTHH:mm:ssZZ`));
    } else {
      setfromDate(fromDate);
    }
    if (toDate.trim().length > 1) {
      setToDate(dayjs(toDate).format(`YYYY-MM-DDT23:59:59ZZ`));
    } else {
      setToDate(toDate);
    } 
    setDateFilterType(filterType); 
    setApplyFilter(applyFilter + 1);
  };

  const setStatusFilter = (status: string) => {
    setStatus(status);
  };

  const setPayeeIds = (payeeIds: string) => {
    setSelectedPayeeIds(payeeIds);
  };

  const setPayeeTypes = (payeeTypes: string) => {
    setSelectedPayeeTypes(payeeTypes);
  }; 

  const setPayeeNames = (payeeNames: string) => {
    setSelectedPayeeNames(payeeNames);
  };

  const onExportButton = () => {
    const req: IWithholdingsInterfaces = {
      page: currentPage,
      numRowsPerPage: numRowsPerPage,
      search: textSearchFilter.trim(),
      status: status,
      start: fromDate,
      end: toDate,
      sort: sortField,
      payee: selectedPayeeIds,
      payeeType: selectedPayeeTypes, 
      hotel: selectedAccountIds ?? localAccId,
    };
    dispatch(exportWithholdList(req));
  };
 
  const confirmProcessWithholdings = () => {
    setBgShade(true);
    Store.addNotification({
      title: t("stafflist.modalDeleteHeader", {
        projectname: t("projectName"),
      }),
      id: "maid-modal",
      content: (
        <div className="idproof-modal div-center w-60perc relative">
          <div className="page-heading">
            <img src={yelloWarning} alt="Warning" />
          </div>
          <div className="idproof-flex-center div-center text-center">
            <div>
              {t("withholdings.confirmMessage")}
            </div>
          </div>
          <div className="idproof-margin"></div>
          <div className="flex w-100 gap-4">
            <ButtonModal
              type="cancel"
              onClick={() => {
                setBgShade(false);
                Store.removeNotification("maid-modal");
              }}
              buttonLabel={t("common.cancel")}
            />
            <ButtonModal
              onClick={() => {
                setBgShade(false);
                dispatch(handleDistribute(""));
                Store.removeNotification("maid-modal");
                
              }}
              buttonLabel={t("common.confirm")}
            />
          </div>
        </div>
      ),
      type: "default",
      container: "center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 1000000000,
        click: false,
        touch: false,
      },
    });
  };


  return (
    <>
      <div className="box-padding">
      <div className={bgShade ? "rnc_bg" : "hide"}></div>
        <div className="">
          <FilterSection
            headind={`${t("navigation.withholdings")}`}
            filterAccount={true}
            filterDate={true}
            filterPayee={true}
            filterPayStatus={true}
            searchInput={true}
            parentPage={PAGENAME_WITHHOLDINGS}
            setStatusFilter={setStatusFilter}
            setSelectedAccountIds={setSelectedAccountIds}
            setDateFilterDatesType={setDateFilterDatesType}
            setPayeeTypes={setPayeeTypes} 
            setPayeeIds={setPayeeIds}
            setPayeeNames={setPayeeNames} 
            clearFilters={clearFilters}
            setTextSearchFilter={setTextSearchFilter}
            searchInputPlaceholder={`${t("paymentslist.searchPayments")}`}
            needDateAllOption={true} 

            buttonLabel={`${t("withholdings.distribute")}`}
            addButton={checkActionPermission(adminUserRole, PERM_PROCESS_WITHHOLDINGS) ? true : false}
            headerButtonFunction={e=>confirmProcessWithholdings()} 

            acceptAccountFilterIds={acceptAccountFilterIds}
            acceptDateFilterData={acceptDateFilterData}
            acceptMultiSelectStatusIds={acceptMultiSelectStatusIds} 
            acceptPayeeFilterData={acceptPayeeFilterData} 
            acceptSearchText={acceptSearchText} 
          />

          <div className="table-box">
            <Table
              titles={WithholdingsTableHeaders()}
              data={withholdingList}
              sortedFieldName={sortField}
              handleSort={handleSort}
            />

            {totalSize > 0 && (
              <Paginations
              currentPage={currentPage}
              totalPageCount={
                totalSize
                  ? totalSize < numRowsPerPage
                    ? 1
                    : totalSize % numRowsPerPage > 0
                    ? parseInt((totalSize / numRowsPerPage).toString(), 10) + 1
                    : parseInt((totalSize / numRowsPerPage).toString(), 10)
                  : 0
              }
              totalRows={totalSize}
              isTableLoading={false}
              setCurrentPage={acceptPage}
              setNumRowsPerPage={setNumRowsPerPage}
              recordsTypeName={`${t("common.transactions")}`}
              exportButton={
                checkActionPermission(adminUserRole, PERM_PAYOUTS_EXPORT) &&
                totalSize > 0
                  ? true
                  : false
              }
              onExportButton={onExportButton}
            />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Withholdings;
