import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IStripePayMethod, ITipsSummary } from "../../models/tipsInterfaces";
import { IPaymentsSummary } from "../../models/paymentsInterfaces";
import { ICommissionsSummary } from "../../models/commissionsInterfaces";
import Transactions from "../Transactions";

interface IProps {
  buttonLabel?: string;
  addButton?: boolean;
  exportButton?: boolean;
  parentPage?: string;
  searchInput?: boolean;
  searchInputPlaceholder?: string;
  headerButtonFunction?: (modal: boolean) => void;
  onExportButton?: () => void;
  setTextSearchFilter?: (status: string) => void;
  stripePayMethods?: IStripePayMethod[];

  transaction?: boolean;
  tips?: boolean;
  tipsSummary?: ITipsSummary;
  payment?: boolean;
  paymentsSummary?: IPaymentsSummary;
  commissions?: boolean;
  commissionsSummary?: ICommissionsSummary;
}

const Header: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const [filterPayeeSearchString, setFilterPayeeSearchString] = useState("");

  let dummyVar = "";

  useEffect(() => {
    // COMMNTED FOR LATER USE
    if (filterPayeeSearchString.trim().length > 2) {
      console.log("filterPayeeSearchString: ", filterPayeeSearchString);
      setFilterPayeeSearchString(""); 
      // props?.setSelectedAccountIds &&
      //   props.setSelectedAccountIds(selectedAccounts);
    }
  }, [filterPayeeSearchString]);

  return (
    <div className="header  bg-light">
      <div className="box  mb-18px p-4">
        <div className="flex-end gap-4 center ">
          {props.addButton && (
            <>
              {props.buttonLabel === t("accounts.addAccount") && (
                <>
                  <button
                     onClick={(e) => {
                      props.headerButtonFunction &&
                        props.headerButtonFunction(true);
                    }}
                    className="common-btn bg-blue rounded-2 shadow-none "
                  >
                    {props.buttonLabel}
                    <span>
                      <AddIcon className="icon" />
                    </span>
                  </button>
                </>
              )}
              {props.buttonLabel === t("accountUsersList.addAdmin") && (
                <>
                  <button
                    className="common-btn bg-blue rounded-2 shadow-none "
                    onClick={(e) => {
                      props.headerButtonFunction &&
                        props.headerButtonFunction(true);
                    }}
                  >
                    {props.buttonLabel}
                    <span>
                      <AddIcon className="icon" />
                    </span>
                  </button>
                </>
              )}
              {props.buttonLabel === t("adminUserList.addUser") && (
                <>
                  <button
                    className="common-btn bg-blue rounded-2 shadow-none "
                    onClick={(e) => {
                      props.headerButtonFunction &&
                        props.headerButtonFunction(true);
                    }}
                  >
                    {props.buttonLabel}
                    <span>
                      <AddIcon className="icon" />
                    </span>
                  </button>
                </>
              )}
              {props.buttonLabel === t("stafflist.inviteStaff") && (
                <div className="flex gap-4">
                  <Link
                    to={"/account"}
                    className="common-btn"
                  >
                    <span>
                      <AddIcon className="icon" />
                    </span>
                    {props.buttonLabel}
                  </Link>
                  
                </div>
              )}
            </>
          )}
        </div>
        {(props.tips || props.commissions || props.payment) && (
          <div className="">
            <Transactions
              commissions={props.commissions}
              commissionsSummary={props.commissionsSummary}
              transaction={props.transaction}
              tips={props.tips}
              tipsSummary={props.tipsSummary}
              payment={props.payment}
              paymentsSummary={props.paymentsSummary}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
