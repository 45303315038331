import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPayee } from '../../models/tipsInterfaces';
import { fetchPayeeByNameStaffOnly } from '../../services/payeeServices';
import { RootState } from '../../store';

export interface FilterState {
  payeeList: IPayee[];
  payeeLoading: 'idle' | 'loading' | 'succeeded' | 'error';
}

const initialState: FilterState = {
  payeeLoading: 'idle',
  payeeList: []
};

export const filterPayeeListSlice = createSlice({
  name: 'filterPayeeListSlice',
  initialState,
  reducers: {
    clearPayees: (state, action: PayloadAction<any>) => {
      state.payeeList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPayeeByNameStaffOnly.pending, (state) => {
      state.payeeList = [];
      state.payeeLoading = 'idle';
    });
    builder.addCase(fetchPayeeByNameStaffOnly.fulfilled, (state, action) => {
      const payees = action.payload as IPayee[];
      state.payeeList = payees;

      state.payeeLoading = 'succeeded';
    });
    builder.addCase(fetchPayeeByNameStaffOnly.rejected, (state, { payload }: any) => {
      state.payeeLoading = 'error';
    });
  },
});

// Action creators are generated for each case reducer function
export const { clearPayees } = filterPayeeListSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const FilterPayeeListSlice = (state: RootState) =>
  state.filterPayeeList;

export const filterPayeeListSliceReducer = filterPayeeListSlice.reducer;
