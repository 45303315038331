import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import CloseRounded from "@mui/icons-material/CloseRounded";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, IconButton, Modal } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../helpers/hook";

import QRSidePanel from "./QRSidePanel";
import QRNavbar from "./QRNavbar";
import imageOne from '../../assets/images/startTemplate.svg'
import imageTwo from '../../assets/images/createTemplate.svg'
import downloadIcon from '../../assets/images/qr-addtoCart-icon.svg'
import editIcon from '../../assets/images/qr-edit-icon.svg'
import eyeIcon from '../../assets/images/qr-eye-icon.svg'
import cartIcon from '../../assets/images/qr-cart-icon.svg'
import warning from "../../assets/images/yellowrm.svg";
import redwarning from "../../assets/images/warning.svg";
import checkIcon from "../../assets/images/check_icon.svg"; 

import { QRCODE_CART_ITEM_MODIFIED } from "../../config/apiResponseCodes";
import { PAGENAME_QRCODES, USER_ROLE_ACCOUNT_MANAGER, USER_ROLE_ACCOUNT_USER } from "../../config/env";
import { GROUP_STAFF_SEL_ALL_VALUE, QRCODE_DH_IFRAME_URL, QRCODE_FOR_GROUP, QRCODE_FOR_STAFF } from "../../config/env_qrcodes";

import { DH_TEMPLATE_DESIGN_TYPE_POSTCARD, DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD, DH_TEMPLATE_DESIGN_TYPE_TABLETENT, DH_MEASURE_UNIT_INCH, DH_TEMPLATE_TYPE_BASE, DH_TEMPLATE_TYPE_BLANK, DH_TEMPLATE_TYPE_ACCOUNT, ROUTE_QRC_CHECKOUT } from "../../config/env_qrcodes";

import { dh_setUserAccessToken, dh_removeTokens, getFilterAccountId, setLastActivePageAPIData, getAdminUserRole } from "../../services/localStorage";
import { setNotification } from '../../services/apiService';
import { fetchDHUserAccessToken, fetchDHBaseTemplates, createNewDHProject, fetchDHAccountProjects, fetchDHAccountTemplates, fetchVPPricingOne, fetchVPPricingTwo, fetchVPPricingThree, fetchCartDetails, addCartItem, modifyCartItem, fetchCartCount, deleteDHProject, downloadQRTemplate, createNewDHTemplateProject, publishNewDHTemplate, applyTemplateToProjects, deleteDHPreMadeTemplate} from "../../services/qrCodesServices"; // fetchDHAppAccessToken

import { selectQrcodesDataSlice, changePoolStaffSelection, changeSelectedPoolStaffId, changeSelectedPoolStaffName, changeCartItemModifiedStatus, changeDeleteDHProjectStatus, changeTemplatesLoading, changeDHNewTemplateProjectId, changeDownloadQRImage, changeDHNewProjectId, changeVPPricingDataOne, changeVPPricingDataTwo, changeVPPricingDataThree } from "./QRCodesSlice";

import { ITemplateData, IPreMadeTemplateData, IAccIdPayload, IAddCartItem, IModifyCartItem, IVPItemPriceData, IdhCreateProjectPayload, IdhListProjectPayload, IvpPricingPayload, IdhProjectPayload, IdhCreateTemplateProjectPayload, IdhPublishTemplatePayload, IApplyTemplateToProjects, IdhTemplatePayload } from '../../models/qrCodeInterfaces';

import { SelectField } from "../../components/SelectField";
import { ButtonInLine } from "../../components/ButtonGroups/ButtonInLine";
import { ButtonModal } from "../../components/ButtonGroups/ButtonModal";

declare global {
  interface Window {
    DSHDEditorLib: any; // Replace 'any' with proper types if available
  }
}

interface CustomizedState {
  returnedFrom: string,
}

interface IPrevAPIPayload {
  prevAccountId: string,
  prevDisplayTemplatesType: string,
  prevSelPoolStaffType: string,
  prevSelPoolStaffId: string,
  prevSelPoolStaffName: string,
  prevMyDesignsShowCart: boolean
}

function QRCodes() {
  const dispatch = useAppDispatch();
  // const prevAPIPayLoad = localStorage.getItem('qrCodePageLastAPIData');
  // State to hold the object
  const [prevAPIPayLoad, setPrevAPIPayLoad] = useState<IPrevAPIPayload>(() => {
  // Read the object from local storage or provide a default value
  const storedObject = localStorage.getItem('qrCodePageLastAPIData');
  return storedObject
    ? JSON.parse(storedObject)
    : { prevAccountId: '', prevDisplayTemplatesType: '', prevSelPoolStaffType: '', prevSelPoolStaffId: '', prevSelPoolStaffName: '', prevMyDesignsShowCart: '' };
});

  const pageName = PAGENAME_QRCODES;
  const accountId = getFilterAccountId(); 
  const adminUserRole = getAdminUserRole();
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const params = useParams();
  const location = useLocation();
  const stateData = location.state as CustomizedState; // Type Casting, then you can get the params passed via router

  const { DHUserAccessToken, DHAppAccessToken, DHBaseTemplates, DHAccountTemplates, DHTemplatesCardTypes, DHAccountProjects,  poolStaffSelection, selectedPoolStaffId, selectedPoolStaffName, DHNewProjectId, DHNewTemplateProjectId, vpPricingDataOne, vpPricingDataTwo, vpPricingDataThree, cartData, cartAddedItemData, cartItemModifiedStatus, deleteDHProjectStatus, downloadQRImage, qrCodePageAccountId, templatesLoading } = useAppSelector(selectQrcodesDataSlice);

  const returnedFrom = stateData && stateData.returnedFrom ? stateData.returnedFrom : "";
  
  const [showCartSelection, setShowCartSelection] = useState(false);  

  const [selBlankDesignType, setSelBlankDesignType] = useState(DH_TEMPLATE_DESIGN_TYPE_POSTCARD.toString());
  const [displayTemplatesType, setDisplayTemplatesType] = useState(0);

  const [selNewTemplateType, setSelNewTemplateType] = useState(DH_TEMPLATE_DESIGN_TYPE_POSTCARD.toString());
  const [templateStatusPopup, setTemplateStatusPopup] = useState(false);

  const [exitProjectDesignPopUp, setExitProjectDesignPopUp] = useState(false);

  const [baseTemplatesData, setBaseTemplatesData] = useState<ITemplateData[]>([]);
  const [preMadeTemplatesData, setPreMadeTemplatesData] = useState<ITemplateData[]>([]);

  const [accountProjectsData, setAccountProjectsData] = useState<ITemplateData[]>([]);
  const [previewImage, setPreviewImage] = useState("");
  const [selPoolStaffType, setSelPoolStaffType] = useState(QRCODE_FOR_GROUP);
  const [selPoolStaffId, setSelPoolStaffId] = useState("");
  const [selPoolStaffName, setSelPoolStaffName] = useState("");
  const [showIframe, setShowIframe] = useState(false); 
  const [showTemplateIframe, setShowTemplateIframe] = useState(false);
  const [modifiedCartId, setModifiedCartId] = useState(0);
  const [loadCartData, setLoadCartData] = useState(0);

  const [totalGroups, setTotalGroups] = useState(0);
  const [totalStaff, setTotalStaff] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [stateVPPricingDataPostCard, setStateVPPricingDataPostCard] = useState<IVPItemPriceData[]>([]);
  const [stateVPPricingDataBusinessCard, setStateVPPricingDataBusinessCard] = useState<IVPItemPriceData[]>([]);
  const [stateVPPricingDataTableTent, setStateVPPricingDataTableTent] = useState<IVPItemPriceData[]>([]);

  const [iframeProjectId, setIframeProjectId] = useState(params?.projectId ? params.projectId : '');
  const [iframeTemplateProjectId, setIframeTemplateProjectId] = useState('');
  const [statusPopup, setStatusPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false); 
  const [deleteId, setDeleteId] = useState(""); 
  const [deleteType, setDeleteType] = useState(""); // "project"/"template"
  const [deleteDesignType, setDeleteDesignType] = useState(""); 

  const [internalProjectId, setInternalProjectId] = useState(""); 
  const [internalTemplateType, setInternalTemplateType] = useState(0); 
  
  const [selectGroupStaffPopup, setSelectGroupStaffPopup] = useState(false); 
  const [applyTemplatePopup, setApplyTemplatePopup] = useState(false); 
  const [createTemplatePopup, setCreateTemplatePopup] = useState(false); 

  const [initialAPICalled, setInitialAPICalled] = useState(false); 

  const [previewOpen, setPreviewOpen] = React.useState(false);
  const handlePreviewClose = () => setPreviewOpen(false);

  const handlePreviewOpen = (imageURL: string) => {
    if (imageURL && imageURL.trim().length > 3) {
      setPreviewImage(imageURL);
      setPreviewOpen(true);
    }
  }

  const styleLogoPopup = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "auto",
    bgcolor: 'background.paper',
    border: '1px solid white',
    borderRadius: 1,
    boxShadow: 24,
    p: 3,
  };

  const style1 = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 460,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "13px",
    boxShadow: 24,
  };

  const resetIframes = () =>  {
    setShowIframe(false); 
    setIframeProjectId(''); 
    dispatch(changeDHNewProjectId('')); 

    setShowTemplateIframe(false);
    setIframeTemplateProjectId(''); 
    dispatch(changeDHNewTemplateProjectId('')); 
  }

  const callSKUPricing = () => {
    if(Number(adminUserRole) === Number(USER_ROLE_ACCOUNT_MANAGER) || Number(adminUserRole) === Number(USER_ROLE_ACCOUNT_USER)) {
      // CALL PRICING FROM VISTA PRINT FOR EACH CARD TYPE  
      let pricingPayloadOne: IvpPricingPayload = { cartType: DH_TEMPLATE_DESIGN_TYPE_POSTCARD.toString() }
      dispatch(fetchVPPricingOne(pricingPayloadOne));
    }
  }

  useEffect(() => {
    dispatch(changeSelectedPoolStaffId("")); 
    dispatch(changeSelectedPoolStaffName("")); 
    setDisplayTemplatesType(0);
    dispatch(changePoolStaffSelection(QRCODE_FOR_GROUP)); 
    setShowCartSelection(false); 
    setAccountProjectsData([]); 

    resetIframes(); 

    // IF THIS PAGE IS CALLED FROM CHECKOUT OR SHIPPING, LOAD THE PREVIOUS SCREEN 
    if(returnedFrom && returnedFrom.trim().length > 1 && prevAPIPayLoad && prevAPIPayLoad.prevAccountId.trim().length > 0) {
      let apiPayload = prevAPIPayLoad; // JSON.parse(prevAPIPayLoad); 
      if(apiPayload?.prevDisplayTemplatesType && Number(apiPayload.prevDisplayTemplatesType)==Number(DH_TEMPLATE_TYPE_ACCOUNT) && Number(accountId)==Number(apiPayload.prevAccountId)) {
        setDisplayTemplatesType(DH_TEMPLATE_TYPE_ACCOUNT);
        setSelPoolStaffType(apiPayload.prevSelPoolStaffType); 
        setSelPoolStaffId(apiPayload.prevSelPoolStaffId); 
        let listProjectData: IdhListProjectPayload = {
          accountId: Number(apiPayload.prevAccountId),
          groupStaffType: apiPayload.prevSelPoolStaffType, 
          groupStaffId: apiPayload.prevSelPoolStaffId
        }
        dispatch(fetchDHAccountProjects(listProjectData));

        if (apiPayload.prevMyDesignsShowCart) {
          setShowCartSelection(apiPayload.prevMyDesignsShowCart); 
        }
        setInitialAPICalled(true); 
        
        setTimeout(() => {
          dispatch(changePoolStaffSelection(apiPayload.prevSelPoolStaffType)); 
          dispatch(changeSelectedPoolStaffId(apiPayload.prevSelPoolStaffId)); 
          dispatch(changeSelectedPoolStaffName(apiPayload.prevSelPoolStaffName)); 
        }, 2500);
        

      } else if(apiPayload?.prevDisplayTemplatesType && Number(apiPayload.prevDisplayTemplatesType)==Number(DH_TEMPLATE_TYPE_BASE)) {
        showBaseTemplates(); 
      }
    }
  }, []);

  useEffect(() => {
    // PRICING FOR POST CARD - STORE IN STATE VARIABLE 
    if (vpPricingDataOne && vpPricingDataOne.length > 0 && initialAPICalled) {
      setStateVPPricingDataPostCard(vpPricingDataOne);
      let pricingPayloadTwo: IvpPricingPayload = { cartType: DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD.toString() }
      dispatch(fetchVPPricingTwo(pricingPayloadTwo));
    }
    else {
      setStateVPPricingDataPostCard([]);
    }
    
  }, [vpPricingDataOne]);

  useEffect(() => {
    // PRICING FOR BUSINESS CARD - STORE IN STATE VARIABLE 
    if (vpPricingDataTwo && vpPricingDataTwo.length > 0 && initialAPICalled) {
      setStateVPPricingDataBusinessCard(vpPricingDataTwo);
      let pricingPayloadThree: IvpPricingPayload = { cartType: DH_TEMPLATE_DESIGN_TYPE_TABLETENT.toString() }
      dispatch(fetchVPPricingThree(pricingPayloadThree));
    }
    else {
      setStateVPPricingDataBusinessCard([]);
    }
  }, [vpPricingDataTwo]);

  useEffect(() => {
    // PRICING FOR TABLE TENT - STORE IN STATE VARIABLE 
    if (vpPricingDataThree && vpPricingDataThree.length > 0) {
      setStateVPPricingDataTableTent(vpPricingDataThree);
      dispatch(fetchCartDetails({ accountId: accountId ? accountId.toString() : "" }));
    }
    else {
      setStateVPPricingDataTableTent([]);
    }
  }, [vpPricingDataThree]);

  const updateLocalStorageData = (fieldType: string, fieldValue:any) => {
    // STORE THE LAST API PAYLOAD IN LOCAL STORAGE 
    if(prevAPIPayLoad) {
      let tmpObject = prevAPIPayLoad; 
      if(fieldType=='prevAccountId') {
        tmpObject.prevAccountId = fieldValue; 
      } else if(fieldType=='prevDisplayTemplatesType') {
        tmpObject.prevDisplayTemplatesType = fieldValue; 
      } else if(fieldType=='prevSelPoolStaffType') {
        tmpObject.prevSelPoolStaffType = fieldValue; 
      } else if(fieldType=='prevSelPoolStaffId') {
        tmpObject.prevSelPoolStaffId = fieldValue; 
      } else if(fieldType=='prevSelPoolStaffName') {
        tmpObject.prevSelPoolStaffName = fieldValue; 
      } else if(fieldType=='prevMyDesignsShowCart') {
        tmpObject.prevMyDesignsShowCart = fieldValue; 
      }
      // setPrevAPIPayLoad((prevObject) => ({
      //   ...prevObject,
      //   prevAccountId: fieldValue
      // }));
      setPrevAPIPayLoad(tmpObject);
      localStorage.setItem('qrCodePageLastAPIData', JSON.stringify(tmpObject));
    }
  }

  useEffect(() => {
    setSelPoolStaffType(poolStaffSelection);
  }, [poolStaffSelection]);

  useEffect(() => {
    setSelPoolStaffId(selectedPoolStaffId);
    updateLocalStorageData('prevSelPoolStaffId', selectedPoolStaffId.toString()); 
  }, [selectedPoolStaffId]);

  useEffect(() => {
    setSelPoolStaffName(selectedPoolStaffName);
    if(selectedPoolStaffName) {
      updateLocalStorageData('prevSelPoolStaffName', selectedPoolStaffName); 
    }
  }, [selectedPoolStaffName]);  

  useEffect(() => {
    if (DHNewProjectId && DHNewProjectId.length > 1) {
      setDisplayTemplatesType(DH_TEMPLATE_TYPE_BLANK);
      setShowIframe(true);
      setIframeProjectId(DHNewProjectId);
      setApplyTemplatePopup(false);
    }
    else {
      setShowIframe(false);
      setIframeProjectId("");
    }
  }, [DHNewProjectId]); 

  useEffect(() => {
    if (DHNewTemplateProjectId && DHNewTemplateProjectId.length > 1) {
      setShowTemplateIframe(true);
      setIframeTemplateProjectId(DHNewTemplateProjectId);
      scrollToSection("div-createNewTemplate"); 
    }
    else {
      resetIframes(); 
    }
  }, [DHNewTemplateProjectId]); 

  useEffect(() => {
    dh_removeTokens();
    if (accountId) {
      dispatch(fetchDHUserAccessToken({ accountId: accountId }));
      setShowCartSelection(false); 
      
      if (initialAPICalled) { 
        setDisplayTemplatesType(0);
        dispatch(changePoolStaffSelection(QRCODE_FOR_GROUP)); 
        dispatch(changeSelectedPoolStaffId("")); 
        dispatch(changeSelectedPoolStaffName("")); 
        setAccountProjectsData([]); 
      }
    }
  }, [accountId]);


  useEffect(() => {
    if (showCartSelection===true) {
      callSKUPricing(); 

      // STORE THE LAST API PAYLOAD IN LOCAL STORAGE 
      let payloadObj: IPrevAPIPayload = {
        prevAccountId: accountId.toString(), 
        prevDisplayTemplatesType: DH_TEMPLATE_TYPE_ACCOUNT.toString(),
        prevSelPoolStaffType: selPoolStaffType,
        prevSelPoolStaffId: selPoolStaffId,
        prevSelPoolStaffName: selPoolStaffName,
        prevMyDesignsShowCart: showCartSelection
      }
      localStorage.setItem('qrCodePageLastAPIData', JSON.stringify(payloadObj));
    }
  }, [showCartSelection]);

  const applyGroupStaffFilter = (selGroupStaffType: string, selGroupStaffId: string) => {
    setSelectGroupStaffPopup(false); 
    setSelPoolStaffType(selGroupStaffType);
    setSelPoolStaffId(selGroupStaffId);
    setApplyTemplatePopup(false);
    setCreateTemplatePopup(false); 
    setShowIframe(false);
    setIframeProjectId("");
    if(displayTemplatesType==DH_TEMPLATE_TYPE_BLANK) {
      let newProjectData: IdhCreateProjectPayload = {
        accountId: Number(accountId),
        templateType: DH_TEMPLATE_TYPE_BLANK,
        cardType: Number(selBlankDesignType),
        groupType: selGroupStaffType,
        groupId: Number(selGroupStaffId),
      }
      dispatch(createNewDHProject(newProjectData));
    }
    else if(displayTemplatesType==DH_TEMPLATE_TYPE_ACCOUNT) {
      
      if (accountId && selGroupStaffId && selGroupStaffId.trim().length > 0) {
        let listProjectData: IdhListProjectPayload = {
          accountId: Number(accountId),
          groupStaffType: selGroupStaffType,
          groupStaffId: selGroupStaffId
        }
        dispatch(fetchDHAccountProjects(listProjectData));
        setInitialAPICalled(true); 
        // STORE THE LAST API PAYLOAD IN LOCAL STORAGE 
        let payloadObj: IPrevAPIPayload = {
          prevAccountId: accountId.toString(), 
          prevDisplayTemplatesType: displayTemplatesType.toString(),
          prevSelPoolStaffType: selGroupStaffType,
          prevSelPoolStaffId: selGroupStaffId,
          prevSelPoolStaffName: selPoolStaffName,
          prevMyDesignsShowCart: showCartSelection
        }
        localStorage.setItem('qrCodePageLastAPIData', JSON.stringify(payloadObj));

        if (showCartSelection) {
          dispatch(fetchCartDetails({ accountId: accountId ? accountId.toString() : "" }));
        }
      }
    }
  }

  useEffect(() => {
    if (DHUserAccessToken) {
      dh_setUserAccessToken({ userAccessToken: DHUserAccessToken });
    }
  }, [DHUserAccessToken, DHAppAccessToken]);

  useEffect(() => {
    if (DHBaseTemplates && DHBaseTemplates.length > 0) {
      let tmpObj = [] as ITemplateData[];
      DHBaseTemplates.map((item, index) => {
        tmpObj.push({
          projectId: item?.template?.data?.projectId ? item.template.data.projectId : "",
          projectTitle: item?.template?.data?.projectTitle ? item.template.data.projectTitle : "",
          templateType: item.type,
          width: item?.template?.data?.dimensions?.width ? item.template.data.dimensions?.width : "",
          height: item?.template?.data?.dimensions?.height ? item.template.data.dimensions?.height : "",
          measurementUnit: item?.template?.data?.dimensions?.unitType?.unitTypeId ? item.template.data.dimensions?.unitType?.unitTypeId : "",
          templateURL: item?.template?.data?.thumbnailUrl ? item.template.data.thumbnailUrl : "",
          startUnitQuantity: "",
          startPricePerUnit: "",
          endUnitQuantity: "",
          endPricePerUnit: "",
        });
      });
      setBaseTemplatesData(tmpObj);
    }
  }, [DHBaseTemplates]); 

  useEffect(() => {
    let tmpObj = [] as IPreMadeTemplateData[];

    if (DHAccountTemplates && DHAccountTemplates.length > 0 && DHTemplatesCardTypes && DHTemplatesCardTypes.length > 0) {
      DHAccountTemplates.map((item, index) => {
        let tmpCartType = DHTemplatesCardTypes.find(ctItem => Number(ctItem.templateId)==Number(item.templateId)); 
        tmpObj.push({
          templateId: item?.templateId ? item.templateId : "",
          templateTitle: item?.templateTitle ? item.templateTitle : "",
          templateType: tmpCartType?.cardType ? Number(tmpCartType.cardType) : 0,
          projectId: tmpCartType?.projectId ? tmpCartType.projectId : "",
          internalProjectId: item.internalProjectId, 
          width: item?.dimensions?.width ? item.dimensions?.width : "",
          height: item?.dimensions?.height ? item.dimensions?.height : "",
          measurementUnit: item?.dimensions?.unitType?.unitTypeId ? item.dimensions?.unitType?.unitTypeId : "",
          templateURL: item?.previewUrl ? item.previewUrl : "",
          startUnitQuantity: "",
          startPricePerUnit: "",
          endUnitQuantity: "",
          endPricePerUnit: "",
        });
      });
      setPreMadeTemplatesData(tmpObj);
    }
    else {
      setPreMadeTemplatesData(tmpObj);
    }
  }, [DHAccountTemplates, DHTemplatesCardTypes]); // primary_template_category_item : {template_category_item_id: 1, item_name: "Business Cards - 3.5" x 2""}


  useEffect(() => {
    if(deleteDHProjectStatus=="succeeded") {
      dispatch(changeDeleteDHProjectStatus("")); 
      onMyDesignsClick(); 
      setNotification(
        t("messages.appName"),  
        t("qrcodesPage.msgDHProjectDeleted",{designType:getTemplateType(deleteDesignType)}),  
        "success",
        "templateTypeMessage"
      ); 
    }
    else if(deleteDHProjectStatus=="deleteDHProjectERROR") {
      setNotification(
        t("messages.appName"),  
        t("qrcodesPage.msgDHProjectDeleteFailed",{designType:getTemplateType(deleteDesignType)}),
        "failed",
        "templateTypeMessage"
      ); 
    }
  },[deleteDHProjectStatus]); 

  useEffect(() => {
    let tmpObj = [] as ITemplateData[];
    if (DHAccountProjects && DHAccountProjects.length > 0) {
      DHAccountProjects.map((item, index) => {
        tmpObj.push({
          projectId: item?.projectId ? item.projectId : "",
          projectTitle: item?.projectTitle ? item.projectTitle : "",
          templateType: item?.metadata?.cardType ? item?.metadata?.cardType : "",
          width: item?.dimensions?.width ? item.dimensions?.width : "",
          height: item?.dimensions?.height ? item.dimensions?.height : "",
          measurementUnit: item?.dimensions?.unitType?.unitTypeId ? item.dimensions?.unitType?.unitTypeId : "",
          templateURL: item?.thumbnailUrl ? item.thumbnailUrl : "",
          startUnitQuantity: "",
          startPricePerUnit: "",
          endUnitQuantity: "",
          endPricePerUnit: "",
          sku: "",
          quantity: 0,
          price: 0,
          selectedValue: "",
          cartId: 0,
          locked: false,
          groupType: item?.metadata?.groupType ? item?.metadata?.groupType : "",
          groupId: item?.metadata?.groupId ? item?.metadata?.groupId : "",
          name: item?.metadata?.name ? item?.metadata?.name : "",
        });
      });
      setAccountProjectsData(tmpObj);
      if (cartData && cartData.length > 0) {
        setTimeout(() => {
          setLoadCartData(loadCartData + 2);
        }, 550);
      }
    }
    else {
      setAccountProjectsData(tmpObj);
    }
  }, [DHAccountProjects]);

  const setAccProjQtyPrice = (e: any, projectId: string, templateType: string, templateItem: ITemplateData) => {
    let arrNew: ITemplateData[] = [];
    let modifiedItem: ITemplateData = { projectId: "", 
      projectTitle: "", 
      templateType: "", 
      height: "", 
      width: "", 
      measurementUnit: "", 
      templateURL: "", 
      startUnitQuantity: "", 
      startPricePerUnit: "", 
      endUnitQuantity: "", 
      endPricePerUnit: "", 
      sku: "",   
      quantity: 0,    
      price: 0,  
      selectedValue: "", 
      cartId: 0, 
      locked: false,  
      groupType: "",  
      groupId: "", 
      name: ""  };

    let selValue = e.target.value;
    let sku = "", quantity = 0, price = 0;
    if (selValue == "") {
      accountProjectsData.map(item => {
        if (item.projectId == projectId) {
          arrNew.push({ ...item, sku: sku, quantity: quantity, price: price, selectedValue: selValue });
          modifiedItem = { ...item, sku: sku, quantity: quantity, price: price, selectedValue: selValue };  
        }
        else {
          arrNew.push(item);
        }
      });
      setAccountProjectsData(arrNew); 
      setTimeout(() => {
        onAddtoCart(modifiedItem);
      }, 500);
    }
    else {
      let arrPricing = Number(templateType) == Number(DH_TEMPLATE_DESIGN_TYPE_POSTCARD) ? stateVPPricingDataPostCard :
        Number(templateType) == Number(DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD) ? stateVPPricingDataBusinessCard :
          Number(templateType) == Number(DH_TEMPLATE_DESIGN_TYPE_TABLETENT) ? stateVPPricingDataTableTent : [];
      sku = arrPricing[selValue].sku;
      quantity = arrPricing[selValue].quantity;
      price = arrPricing[selValue].price;
      accountProjectsData.map(item => {
        if (item.projectId == projectId) {
          arrNew.push({ ...item, sku: sku, quantity: quantity, price: price, selectedValue: selValue, locked: false });
          modifiedItem = { ...item, sku: sku, quantity: quantity, price: price, selectedValue: selValue, locked: false } 
        }
        else {
          arrNew.push(item);
        }
      });
      setAccountProjectsData(arrNew);
      setTimeout(() => {
        onAddtoCart(modifiedItem);
      }, 500);
    }
  }


  useEffect(() => {
    let arrNew: ITemplateData[] = [];
    if (cartData && cartData.length > 0) {
      accountProjectsData.map(item => {
        let foundItem = 0;
        cartData.map(cartItem => {
          if (item.projectId == cartItem.projectId && Number(item.templateType) == Number(cartItem.cardType)) {
            foundItem = 1;
            let arrPricing = Number(item.templateType) == Number(DH_TEMPLATE_DESIGN_TYPE_POSTCARD) ? stateVPPricingDataPostCard :
              Number(item.templateType) == Number(DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD) ? stateVPPricingDataBusinessCard :
                Number(item.templateType) == Number(DH_TEMPLATE_DESIGN_TYPE_TABLETENT) ? stateVPPricingDataTableTent : [];
            let priceValue = 0;
            let selValue = "";
            arrPricing.map((pItem, pIndex) => {
              if (pItem.sku == cartItem.sku && Number(pItem.quantity) == Number(cartItem.quantity)) {
                priceValue = pItem.price;
                selValue = pIndex.toString();
              }
            });
            arrNew.push({ ...item, sku: cartItem.sku, quantity: cartItem.quantity, price: priceValue, selectedValue: selValue, cartId: cartItem.id, locked: true, groupType: cartItem.groupType });
          }
        });

        if (foundItem === 0) {
          arrNew.push(item);
        }

      });

      setAccountProjectsData(arrNew);

    }
    else {
      accountProjectsData.map(item => {
          arrNew.push({ ...item, sku: "", quantity: 0, price: 0, selectedValue: "", cartId: 0, locked: false});
      });
      setAccountProjectsData(arrNew);
    }
  }, [loadCartData]);

  useEffect(() => {
    let totGroups = 0, totStaff = 0, totQuantity = 0, totAmount = 0;
    let arrGroupIds = [];
    let arrStaffIds = [];
    if (accountProjectsData && accountProjectsData.length > 0) {
      // SET VARIABLES FOR FOOTER 
      accountProjectsData.map(item => {
        if (item.cartId && item.cartId > 0) {
          totQuantity += item.quantity ? item.quantity : 0;
          totAmount += item.price ? item.price : 0;
          if (selPoolStaffType == QRCODE_FOR_GROUP && !arrGroupIds.includes(Number(item.groupId))) {
            arrGroupIds.push(Number(item.groupId));
            totGroups++;
          } else if (selPoolStaffType == QRCODE_FOR_STAFF && !arrStaffIds.includes(Number(item.groupId))) {
            arrStaffIds.push(Number(item.groupId));
            totStaff++;
          }
        }
      });
      setTotalGroups(totGroups);
      setTotalStaff(totStaff);
      setTotalQuantity(totQuantity);
      setTotalAmount(totAmount);
    }
    else {
      setTotalGroups(totGroups);
      setTotalStaff(totStaff);
      setTotalQuantity(totQuantity);
      setTotalAmount(totAmount);
    }
  }, [accountProjectsData]);

  useEffect(() => {
     setLoadCartData(loadCartData + 2); 
  }, [cartData]);

  // CART ITEMS NUMBER ONLY FOR ACCOUNT LOGINS
  const callCartCount = () => {
    if(Number(adminUserRole) === Number(USER_ROLE_ACCOUNT_MANAGER) || Number(adminUserRole) === Number(USER_ROLE_ACCOUNT_USER)) {
      dispatch(fetchCartCount({ accountId: "" })); // ACCOUNT ID NOT REQUIRED NOW
    }
  }

  useEffect(() => {
    if (cartAddedItemData && cartAddedItemData.id && cartAddedItemData.id > 0) {
      let arrNew: ITemplateData[] = [];
      accountProjectsData.map(item => {
        if (item.projectId == cartAddedItemData.projectId && Number(item.templateType) == Number(cartAddedItemData.cardType)) {
          arrNew.push({ ...item, cartId: cartAddedItemData.id, locked: true });
        }
        else {
          arrNew.push(item);
        }
      });
      setAccountProjectsData(arrNew);
      callCartCount(); 
    }
  }, [cartAddedItemData]);

  useEffect(() => {
    if (cartItemModifiedStatus && cartItemModifiedStatus == QRCODE_CART_ITEM_MODIFIED && modifiedCartId && modifiedCartId > 0) {
      let arrNew: ITemplateData[] = [];
      accountProjectsData.map(item => {
        if (Number(item.cartId) == Number(modifiedCartId)) {
          arrNew.push({ ...item, locked: true });
        }
        else {
          arrNew.push(item);
        }
      });
      setAccountProjectsData(arrNew);
      dispatch(changeCartItemModifiedStatus(''));
      setModifiedCartId(0);
    }
  }, [cartItemModifiedStatus]);

  const getTemplateType = (templateType: string) => {
    switch (Number(templateType)) {
      case DH_TEMPLATE_DESIGN_TYPE_POSTCARD: return t("qrcodesPage.postCard");
        break;
      case DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD: return t("qrcodesPage.businessCard");
        break;
      case DH_TEMPLATE_DESIGN_TYPE_TABLETENT: return t("qrcodesPage.tableTent");
        break;
      default: return templateType;
        break;
    }
  }

  const getMeasureString = (measureType: string) => {
    switch (measureType) {
      case DH_MEASURE_UNIT_INCH: return t("qrcodesPage.measureInchString");
        break;
      default: return measureType;
        break;
    }
  }

  const onDisplayTemplatesTypeClick = (templateType:number) => {
    let arrTemp = selPoolStaffId.split(','); 
    if(selPoolStaffId==GROUP_STAFF_SEL_ALL_VALUE || (arrTemp.length==1 && arrTemp[0].trim()=="")  || arrTemp.length > 1) {
      setNotification(
        t("messages.appName"),
        t("qrcodesPage.msgSelectPoolStaff"),
        "failed",
        "templateTypeMessage"
      )
    }
    else if (selPoolStaffId && selPoolStaffId.trim().length > 0) {
      // IF displayTemplatesType IS ALREADY SET TO DH_TEMPLATE_TYPE_BLANK, USEEFFECT WILL NOT WORK,  
      // SO CALL CREATE PROJECT SEPARATELY  
      if (displayTemplatesType == DH_TEMPLATE_TYPE_BLANK && templateType == DH_TEMPLATE_TYPE_BLANK) {
        createProject(templateType, Number(selBlankDesignType));
      }
      setDisplayTemplatesType(templateType);
    }
  }

  const createProject = (templateType: number, templateDesignType: number) => {
    let newProjectData: IdhCreateProjectPayload = {
      accountId: Number(accountId),
      templateType: templateType,
      cardType: Number(templateDesignType),
      groupType: selPoolStaffType,
      groupId: Number(selPoolStaffId),
    }
    dispatch(createNewDHProject(newProjectData));
  }

  const onDeleteDHProjectTemplate = (deleteType:string) => {
    if(deleteType=="project") {
      let delProject : IdhProjectPayload = {
        projectId : deleteId
      }
      dispatch(deleteDHProject(delProject)); 
    } else if(deleteType=="template") {
      let delTemplate : IdhTemplatePayload = {
        templateId : deleteId.toString()
      }
      dispatch(deleteDHPreMadeTemplate(delTemplate)); 
    } 
  } 

  const cancelEdit = () => {
    resetIframes();
    onMyDesignsClick(); 
  }

  // FUNCTION TO OVERRIDE PARENT DIVs ONCLIDK EVENT 
  const onSelectDesignTypeClick = (e) => {
    e.stopPropagation()
    // Do other stuff here
  }

  const onEditProject = (DHProjectId: string) => {
    setIframeProjectId(DHProjectId);
    setShowIframe(true);
  }

  const onAddtoCart = (templateItem: ITemplateData) => {
    if (templateItem.cartId && templateItem.cartId > 0) {
      let cartObj: IModifyCartItem = {
        cartId: Number(templateItem.cartId),
        sku: templateItem.sku,
        quantity: Number(templateItem.quantity)
      }
      dispatch(modifyCartItem(cartObj));
      setModifiedCartId(Number(templateItem.cartId));
    }
    else {
      let cartObj: IAddCartItem = {
        accountId: Number(accountId),
        projectId: templateItem.projectId,
        groupType: templateItem.groupType,
        groupId: Number(templateItem.groupId),
        sku: templateItem.sku,
        quantity: Number(templateItem.quantity),
        cardType: Number(templateItem.templateType)
      }
      dispatch(addCartItem(cartObj));
      setModifiedCartId(0);
    }
  }

  const showQrCodes = () => {
    setShowCartSelection(false);
    setDisplayTemplatesType(DH_TEMPLATE_TYPE_ACCOUNT);
    // STORE THE LAST API PAYLOAD IN LOCAL STORAGE 
    let payloadObj: IPrevAPIPayload = {
      prevAccountId: accountId.toString(), 
      prevDisplayTemplatesType: DH_TEMPLATE_TYPE_ACCOUNT.toString(),
      prevSelPoolStaffType: selPoolStaffType,
      prevSelPoolStaffId: selPoolStaffId,
      prevSelPoolStaffName: selPoolStaffName,
      prevMyDesignsShowCart: false
    }
    localStorage.setItem('qrCodePageLastAPIData', JSON.stringify(payloadObj));
  }

  const checkItemsSelected = () => { 
    if(!totalAmount || totalAmount==0) {
      setNotification(
        t("messages.appName"),  
        t("qrcodesPage.msgSelectItems"),
        "failed",
        "templateTypeMessage"
      )
    }
    else {
      let foundIssue = 0; 
      accountProjectsData.map(item => {
        if(item.quantity > 0 && !item.locked) {
          foundIssue = 1; 
          setNotification(
            t("messages.appName"),  
            t("qrcodesPage.msgAddItems"),
            "failed",
            "templateTypeMessage"
          );
          return false; 
        }
      });

      if(foundIssue==0) {
        navigate(ROUTE_QRC_CHECKOUT); 
      }
      else {
        return false; 
      }
      
    }
  }

  const dummyFunction = () => {
    console.log("dummyFunction");
  }

  useEffect(() => {
    if (downloadQRImage) {
      const link = document.createElement('a');
      link.href = downloadQRImage;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(changeDownloadQRImage("")); 
    }
  }, [downloadQRImage]);

  const handleDownloadClick = (projectId) => {
    dispatch(downloadQRTemplate(projectId))
  };

  const setPrevPageData = () => {
    const pageData  = {
      prevAccountId: 0,
      prevGroupType: "", 
      prevGroupIds: "", 
      prevDesignType: "",  
    };
    setLastActivePageAPIData({pageName:pageName, apiData:pageData});
  }

  useEffect(() => {
    if(Number(qrCodePageAccountId) > 0) {
      const pageData  = {
        prevAccountId: qrCodePageAccountId,
        prevGroupType: QRCODE_FOR_GROUP, 
        prevGroupIds: "", 
        prevDisplayTemplatesType: displayTemplatesType, 
      };
      setLastActivePageAPIData({pageName:pageName, apiData:pageData});
    }
  },[qrCodePageAccountId]);

  const onCreatePreMadeTemplateClick = () => {
    setShowIframe(false);
    setTemplateStatusPopup(false); 
    let apiData: IdhCreateTemplateProjectPayload = {
      accountId: (qrCodePageAccountId && Number(qrCodePageAccountId) > 0) ? Number(qrCodePageAccountId) : Number(accountId),
      cardType:  Number(selNewTemplateType)
    } 
    dispatch(createNewDHTemplateProject(apiData)); 
  }

  const onEditPreMadeTemplateClick = (internalProjectId:string) => {
    if(internalProjectId && internalProjectId.trim().length > 1) {
      dispatch(changeDHNewTemplateProjectId(internalProjectId)); 
      setShowTemplateIframe(true);
      setIframeTemplateProjectId(internalProjectId);
      scrollToSection("div-createNewTemplate");
    }
  }

  const cancelTemplateEdit = () => {
    setShowTemplateIframe(false);   
    resetIframes(); 
  }

  const exportTemplate = () => {
    let apiData : IdhPublishTemplatePayload = {
      projectId: iframeTemplateProjectId
    }
    dispatch(publishNewDHTemplate(apiData));   
  }

  useEffect(() => {
    if (templatesLoading=='createNewDHTemplateProjectError' || templatesLoading=='publishNewDHTemplateError') {
      setNotification(
        t("messages.appName"),
        (templatesLoading=='createNewDHTemplateProjectError' ? t("qrcodesPage.msgTemplateCreationFailed") : t("qrcodesPage.msgTemplateExportFailed")),
        "failed",  
        "templateTypeMessage"
      );
      dispatch(changeTemplatesLoading("")); 
    } 
    else if(templatesLoading=='publishNewDHTemplateSucceeded') { 
      setNotification(
        t("messages.appName"),
        t("qrcodesPage.msgTemplateCreated"),  
        "success",  
        "templateTypeMessage"
      );
      resetIframes();  
      let accData: IAccIdPayload = {
        accountId: (qrCodePageAccountId && Number(qrCodePageAccountId) > 0) ? qrCodePageAccountId.toString() : accountId.toString() 
      }
      dispatch(fetchDHAccountTemplates(accData));
      dispatch(changeTemplatesLoading("")); 
      
      // STORE THE LAST API PAYLOAD IN LOCAL STORAGE 
      let payloadObj: IPrevAPIPayload = {
        prevAccountId: accountId.toString(), 
        prevDisplayTemplatesType: DH_TEMPLATE_TYPE_BASE.toString(),
        prevSelPoolStaffType: selPoolStaffType,
        prevSelPoolStaffId: selPoolStaffId,
        prevSelPoolStaffName: selPoolStaffName,
        prevMyDesignsShowCart: showCartSelection
      }
      localStorage.setItem('qrCodePageLastAPIData', JSON.stringify(payloadObj));

    } else if(templatesLoading=='applyTemplateToProjectsSucceeded') {
      setNotification(
        t("messages.appName"),
        t("qrcodesPage.msgApplyTemplateToProjectsSuccess"),  
        "success",  
        "templateTypeMessage"
      );
      dispatch(changeTemplatesLoading("")); 
    } else if(templatesLoading=='applyTemplateToProjectsError') {
      setNotification(
        t("messages.appName"),
        t("qrcodesPage.msgApplyTemplateToProjectsFailed"),
        "failed",  
        "templateTypeMessage"
      );
      dispatch(changeTemplatesLoading("")); 
    } else if(templatesLoading=='deleteDHPreMadeTemplateSucceeded') {
      setNotification(
        t("messages.appName"),
        t("qrcodesPage.msgPreMadeTemplateDeleted"), 
        "success",  
        "templateTypeMessage"
      );
      dispatch(changeTemplatesLoading("")); 
      let accData: IAccIdPayload = {
        accountId: (qrCodePageAccountId && Number(qrCodePageAccountId) > 0) ? qrCodePageAccountId.toString() : accountId.toString() 
      }
      dispatch(fetchDHAccountTemplates(accData));
      // STORE THE LAST API PAYLOAD IN LOCAL STORAGE 
      let payloadObj: IPrevAPIPayload = {
        prevAccountId: accountId.toString(), 
        prevDisplayTemplatesType: DH_TEMPLATE_TYPE_BASE.toString(),
        prevSelPoolStaffType: selPoolStaffType,
        prevSelPoolStaffId: selPoolStaffId,
        prevSelPoolStaffName: selPoolStaffName,
        prevMyDesignsShowCart: showCartSelection
      }
      localStorage.setItem('qrCodePageLastAPIData', JSON.stringify(payloadObj));

    } else if(templatesLoading=='deleteDHPreMadeTemplateERROR') {
      setNotification(
        t("messages.appName"),
        t("qrcodesPage.msgPreMadeTemplateDeletionFailed"),
        "failed",  
        "templateTypeMessage"
      );
      dispatch(changeTemplatesLoading("")); 
    }  
  }, [templatesLoading]);

  const onApplyToClick = (projId: string, templateType: number) => {
    setApplyTemplatePopup(true); 
    setDisplayTemplatesType(DH_TEMPLATE_TYPE_BASE); 
    setInternalProjectId(projId); 
    setInternalTemplateType(templateType); 
    return false; 
  }

  const applyTemplateToGroupStaff = (selGroupStaffType: string, selGroupStaffId: string) => {
    setApplyTemplatePopup(false);
    let applyData : IApplyTemplateToProjects = { 
      accountId: (qrCodePageAccountId && Number(qrCodePageAccountId) > 0) ? Number(qrCodePageAccountId) : Number(accountId), 
      projectId: internalProjectId,  
      templateType: internalTemplateType, 
      groupType: selGroupStaffType, 
      groupId: selGroupStaffId
    }
    dispatch(applyTemplateToProjects(applyData)); 
  }

  const onMyDesignsClick = () => {
    if (selPoolStaffId && selPoolStaffId.trim().length > 0) {
      setDisplayTemplatesType(DH_TEMPLATE_TYPE_ACCOUNT);
      setShowIframe(false);
      let listProjectData: IdhListProjectPayload = {
        accountId: Number(accountId),
        groupStaffType: selPoolStaffType,
        groupStaffId: selPoolStaffId,
      }
      dispatch(fetchDHAccountProjects(listProjectData));

      // STORE THE LAST API PAYLOAD IN LOCAL STORAGE 
      let payloadObj: IPrevAPIPayload = {
        prevAccountId: accountId.toString(), 
        prevDisplayTemplatesType: DH_TEMPLATE_TYPE_ACCOUNT.toString(),
        prevSelPoolStaffType: selPoolStaffType,
        prevSelPoolStaffId: selPoolStaffId,
        prevSelPoolStaffName: selPoolStaffName,
        prevMyDesignsShowCart: showCartSelection
      }
      localStorage.setItem('qrCodePageLastAPIData', JSON.stringify(payloadObj));
    }
  };

  const showBaseTemplates = () => {  
    dispatch(fetchDHBaseTemplates({ accountId: "" }));
    let accData: IAccIdPayload = {
      accountId: (qrCodePageAccountId && Number(qrCodePageAccountId) > 0) ? qrCodePageAccountId.toString() : accountId.toString() 
    }
    dispatch(fetchDHAccountTemplates(accData));
    setDisplayTemplatesType(DH_TEMPLATE_TYPE_BASE);
    resetIframes();  
    // STORE THE LAST API PAYLOAD IN LOCAL STORAGE 
    let payloadObj: IPrevAPIPayload = {
      prevAccountId: accountId.toString(), 
      prevDisplayTemplatesType: DH_TEMPLATE_TYPE_BASE.toString(),
      prevSelPoolStaffType: selPoolStaffType,
      prevSelPoolStaffId: selPoolStaffId,
      prevSelPoolStaffName: selPoolStaffName,
      prevMyDesignsShowCart: showCartSelection
    }
    localStorage.setItem('qrCodePageLastAPIData', JSON.stringify(payloadObj));
  }

  const scrollToSection = (elementId:string) => {
    const element = document.getElementById(elementId); 
    if (element) {
      const offsetTop = element.offsetTop;
      window.scrollTo({
        top: (offsetTop-75),
        behavior: 'smooth',
      });
    }
  };
  
  return (
    <div className="box-padding select-none">
      <div className="box">
        <QRNavbar
          showQrCodes={showQrCodes}
          showCartSelection={showCartSelection}
        />

        <div className="flex ">
          {!showCartSelection ? <>
            <div className="qr-main-body w-100">
              <div className="qr-main-head"> 

                <div onClick={(e) => {
                    setSelectGroupStaffPopup(true);
                    setDisplayTemplatesType(DH_TEMPLATE_TYPE_ACCOUNT); 
                  }
                } className={`$  {displayTemplatesType !== DH_TEMPLATE_TYPE_ACCOUNT ? "qr-tab-inactive qr-tab " : "qr-tab"}`}>
                  <img src={imageOne} alt="imageOne" />
                  <div className="flex-col center">
                    <div className="order-span-text w-100">{t("qrcodesPage.myDesigns")}</div>
                    <ul className="mb-0 ps-4">
                      <li><span className="qr-tab-span">{t("qrcodesPage.myDesignsPointOne")}</span></li>
                      <li><span className="qr-tab-span">{t("qrcodesPage.myDesignsPointTwo")}</span></li>
                      <li><span className="qr-tab-span">{t("qrcodesPage.myDesignsPointThree")}</span></li>
                    </ul>
                  </div>
                </div>

                <div onClick={(e) => showBaseTemplates()} className={`$  {displayTemplatesType !== DH_TEMPLATE_TYPE_BASE ? "qr-tab-inactive qr-tab " : "qr-tab"}`}>
                  <img src={imageOne} alt="imageOne" />
                  <div className="flex-col center">
                    <div className="order-span-text w-100">{t("qrcodesPage.startWithTemplate")}</div>
                    <ul className="mb-0 ps-4">
                      <li><span className="qr-tab-span">{t("qrcodesPage.arelookingInspiration")}</span></li>
                      <li><span className="qr-tab-span">{t("qrcodesPage.wantprofessionalQRCardDesign")}</span></li>
                      <li><span className="qr-tab-span">{t("qrcodesPage.simpleFastEasy")}</span></li>
                    </ul>
                  </div>
                </div>

                <div onClick={(e) => { 
                    setCreateTemplatePopup(true); 
                    setDisplayTemplatesType(DH_TEMPLATE_TYPE_BLANK);
                  }
                } className={`${displayTemplatesType !== DH_TEMPLATE_TYPE_BLANK ? "qr-tab-inactive qr-tab " : "qr-tab"}`}>
                  <img src={imageTwo} alt="imageTwo" />
                  <div className="flex-col center">
                    <div className="flex order-span-text w-100">
                      <div className="mt-4px">{t("qrcodesPage.createNew")}</div>
                      <SelectField
                        type=""
                        name="sel_designType"
                        id="sel_designType"
                        placeHolder={t("qrcodesPage.createNew")}
                        className={"pl-10px ml-10px"}
                        value={selBlankDesignType}
                        onClick={(e) => onSelectDesignTypeClick(e)}
                        onChange={(e) => setSelBlankDesignType(e.target.value)}
                        options={
                          <>
                            <option value={DH_TEMPLATE_DESIGN_TYPE_POSTCARD.toString()}>{getTemplateType(DH_TEMPLATE_DESIGN_TYPE_POSTCARD.toString())}</option>
                            <option value={DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD.toString()}>{getTemplateType(DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD.toString())}</option>
                            <option value={DH_TEMPLATE_DESIGN_TYPE_TABLETENT.toString()}>{getTemplateType(DH_TEMPLATE_DESIGN_TYPE_TABLETENT.toString())}</option>
                          </>
                        }
                      />
                    </div>
                    <ul className="mb-0 ps-4">
                      <li><span className="qr-tab-span">{t("qrcodesPage.knowExactlyWantCreate")}</span></li>
                      <li><span className="qr-tab-span">{t("qrcodesPage.haveOwnDesigner")}</span></li>
                      <li><span className="qr-tab-span">{t("qrcodesPage.wantControlLayout")}</span></li>
                    </ul>
                  </div>
                </div>
                
              </div>
              

              {/* ********************************************************************
              *********************** START WITH A TEMPLATE **************************
              ************************************************************************ */}
              {displayTemplatesType == DH_TEMPLATE_TYPE_BASE &&  <>
                <div className="qr-main-section flex">
                  <div className="w-100 space-between"><div className={displayTemplatesType === DH_TEMPLATE_TYPE_BASE ? "qr-main-span" : "qr-main-span inactive text-liteGrey"}>{t("qrcodesPage.preTemplates")} <ArrowDropDownIcon className="icon " /></div>
                    <div className="space-between">
                      <div className="order-span-text pointer mt-5px" onClick={(e) => setTemplateStatusPopup(true)} >{t("qrcodesPage.createCommonTemplate")}</div>   
                      <SelectField
                        type=""
                        name="sel_templateType"
                        id="sel_templateType"
                        placeHolder={t("qrcodesPage.createNew")}
                        className={"pl-10px ml-10px"}
                        value={selNewTemplateType}
                        onClick={(e) => onSelectDesignTypeClick(e)}
                        onChange={(e) => setSelNewTemplateType(e.target.value)}
                        options={
                          <>
                            <option value={DH_TEMPLATE_DESIGN_TYPE_POSTCARD.toString()}>{getTemplateType(DH_TEMPLATE_DESIGN_TYPE_POSTCARD.toString())}</option>
                            <option value={DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD.toString()}>{getTemplateType(DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD.toString())}</option>
                            <option value={DH_TEMPLATE_DESIGN_TYPE_TABLETENT.toString()}>{getTemplateType(DH_TEMPLATE_DESIGN_TYPE_TABLETENT.toString())}</option>
                          </>
                        }
                      />  
                    </div>
                  </div> 
                </div>
                <div className="pt-50px pb-99px ps-4 gap-18px flex " style={{ flexWrap: "wrap" }}>

                  {displayTemplatesType == DH_TEMPLATE_TYPE_BASE && (!baseTemplatesData || baseTemplatesData.length == 0) && <>
                    <div>{t("qrcodesPage.notFoundAnyTemplates")}</div>
                  </>}

                  {baseTemplatesData && baseTemplatesData.length > 0 && <>
                    {baseTemplatesData.map((item, index) => <>
                      <div className="qr-template-card">
                        <div className="qr-template-img-section border-bottom">
                          <div className="qr-template-more menuDropdown ">
                            <MoreVertRoundedIcon sx={{ fontSize: "18px" }} className="icon" />
                            <div
                              className={"menuDropdown-body border-none "
                              }
                            >
                              <div className={"menuDropdown-content border-white qr-more-dropdown-content"} >
                                <div className="link-h border-bottom">
                                  {t("qrcodesPage.actions")}
                                </div>
                                <div className=" qr-more-dropdown-text pt-2" onClick={e => handlePreviewOpen(item.templateURL)}>
                                  {t("qrcodesPage.preview")}
                                </div>
                                <div className="qr-more-dropdown-text pt-2" onClick={e => onApplyToClick(item.projectId,DH_TEMPLATE_TYPE_BASE)} >  
                                  {t("qrcodesPage.applyTo")}
                                </div>
                              </div>
                            </div>
                          </div>
                          <img src={item.templateURL} alt="thump" className="qr-template-img" />
                        </div>

                        <div className="flex-col border-bottom p-11px space-between">
                          <div className="flex mt-3px space-between">
                            <div className="order-span-text">{getTemplateType(item.templateType.toString())}</div>
                            <div className="order-span-text">{item.width.toString() + getMeasureString(item.measurementUnit)} x {item.height.toString() + getMeasureString(item.measurementUnit)}</div>
                              {/* <span className="qr-template-span-pay-text">{item.startPricePerUnit}</span> */}
                          </div>
                        </div>
                        <div className="pt-15px px-10px pb-3 flex-col ">
                          <div className="flex space-between">
                            <button className="qr-template-btn" onClick={e => onApplyToClick(item.projectId,DH_TEMPLATE_TYPE_BASE)} >{t("qrcodesPage.applyTo")}  <img src={editIcon} alt="" /></button>
                            <button className="qr-template-btn" onClick={e => handlePreviewOpen(item.templateURL)}>{t("qrcodesPage.preview")}   <img src={eyeIcon} alt="" /></button>
                          </div>
                        </div>                            
                      </div>
                    </>)}
                  </>}

                  {preMadeTemplatesData && preMadeTemplatesData.length > 0 && <>
                    {preMadeTemplatesData.map((item, index) => {
                      return(<>
                        <div className="qr-template-card">
                          <div className="qr-template-img-section border-bottom">
                            <div className="qr-template-more menuDropdown ">
                              <MoreVertRoundedIcon sx={{ fontSize: "18px" }} className="icon" />
                              <div
                                className={"menuDropdown-body border-none "
                                }
                              >
                                <div className={"menuDropdown-content border-white qr-more-dropdown-content"} >
                                  <div className="link-h border-bottom">
                                    {t("qrcodesPage.actions")}
                                  </div>
                                  <div className=" qr-more-dropdown-text pt-2" onClick={e => handlePreviewOpen(item.templateURL)}>
                                    {t("qrcodesPage.preview")}
                                  </div>
                                  <div className="qr-more-dropdown-text pt-2" onClick={e => onApplyToClick(item.projectId,DH_TEMPLATE_TYPE_ACCOUNT)} >
                                    {t("qrcodesPage.applyTo")}
                                  </div>
                                  <div className=" qr-more-dropdown-text pt-2" onClick={e => onEditPreMadeTemplateClick(item.projectId)}>
                                    {t("common.edit")}
                                  </div>
                                  <div className=" qr-more-dropdown-text text-danger pt-2" onClick={e => {
                                      setDeleteType("template");
                                      setDeleteId(item.templateId.toString()); 
                                      setDeleteDesignType(getTemplateType(Number(item.templateType))); 
                                      setDeletePopup(true); 
                                    }
                                  } >
                                    {t("qrcodesPage.delete")}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <img src={item.templateURL} alt="thump" className="qr-template-img" />
                          </div>
                          <div className="flex-col border-bottom p-11px space-between">
                            <div className="flex mt-3px space-between">
                              <div className="order-span-text">{getTemplateType(item.templateType.toString())}</div>
                              <div className="order-span-text">{item.width.toString() + getMeasureString(item.measurementUnit)} x {item.height.toString() + getMeasureString(item.measurementUnit)}</div>
                            </div>
                          </div>
                          <div className="pt-11px px-10px pb-3 flex-col ">
                            <div className="flex space-between">
                              <button className="qr-template-btn" onClick={e => {
                                  e.preventDefault();
                                  onApplyToClick(item.projectId,DH_TEMPLATE_TYPE_ACCOUNT);
                                }
                              } >{t("qrcodesPage.applyTo")}  <img src={editIcon} alt="" /></button>
                              <button className="qr-template-btn" onClick={e => handlePreviewOpen(item.templateURL)}>{t("qrcodesPage.preview")}   <img src={eyeIcon} alt="" /></button>
                            </div>
                          </div>
                        </div>
                      </>)})}
                  </>}

                </div> 

                <div id="div-createNewTemplate">&nbsp;</div>

                {showTemplateIframe &&  <div className="" >
                <div className="div-right mr-20px mb-20px">
                  <div className="flex gap-25px">
                    <ButtonInLine
                        type="action"
                        onClick={(e) => cancelTemplateEdit()}
                        buttonLabel={t("common.cancel")}
                        className="px-20px"
                      /> 
                      <ButtonInLine
                        type="action"
                        onClick={(e) => exportTemplate()}
                        buttonLabel={t("qrcodesPage.savePremadeTemplate")}
                        className="px-20px"
                      />
                  </div>    
                </div>
                <iframe
                  id="editoriframe2"
                  src={QRCODE_DH_IFRAME_URL+"?project_id=" + iframeTemplateProjectId + "&token=" + DHUserAccessToken}
                  width="100%"
                  height="550"
                ></iframe>

              </div>}
                              
                </>}
              {/* **************************************************************************
              *********************** START WITH A TEMPLATE - END **************************
              ****************************************************************************** */}  

              {displayTemplatesType !== 0 && displayTemplatesType !== DH_TEMPLATE_TYPE_BASE && <>
                <div className="qr-main-section flex">

                  {displayTemplatesType === DH_TEMPLATE_TYPE_BLANK && <span className={displayTemplatesType === DH_TEMPLATE_TYPE_BLANK ? "qr-main-span" : "qr-main-span inactive text-liteGrey"}>{t("qrcodesPage.createYourOwn", { designType: getTemplateType(selBlankDesignType.toString()) })} <ArrowDropDownIcon className="icon " /></span>}

                  {displayTemplatesType === DH_TEMPLATE_TYPE_ACCOUNT && <span className={"qr-main-span"} >{t("qrcodesPage.myDesigns")} <ArrowDropDownIcon className="icon " /></span>}
                </div>

                {!showIframe && <div className="pt-50px pb-99px ps-4 gap-18px flex " style={{ flexWrap: "wrap" }}>

                  {displayTemplatesType == DH_TEMPLATE_TYPE_ACCOUNT && (!accountProjectsData || accountProjectsData.length == 0) && <>
                    <div>{t("qrcodesPage.notFoundAnyTemplates")}</div>
                  </>}
                  {displayTemplatesType == DH_TEMPLATE_TYPE_ACCOUNT && accountProjectsData && accountProjectsData.length > 0 && <>
                    {accountProjectsData.map((item, index) => <>
                      <div className="qr-template-card">
                        <div className="qr-template-img-section border-bottom">
                          <div className="qr-template-more menuDropdown ">
                            <MoreVertRoundedIcon sx={{ fontSize: "18px" }} className="icon" />
                            <div
                              className={"menuDropdown-body border-none "
                              }
                            >
                              <div className={"menuDropdown-content border-white qr-more-dropdown-content"} >
                                <div className="link-h border-bottom">
                                  {t("qrcodesPage.actions")}
                                </div>
                                <div className=" qr-more-dropdown-text pt-2" onClick={e => handlePreviewOpen(item.templateURL)}>
                                  {t("qrcodesPage.preview")}
                                </div>
                                <div className="qr-more-dropdown-text pt-2" onClick={e => onEditProject(item.projectId)} >
                                  {t("qrcodesPage.edit")}
                                </div>
                                {/* <div className="qr-more-dropdown-text pt-2">
                                {t("qrcodesPage.downloadPDF")} 
                                </div> */}
                                <div className="qr-more-dropdown-text text-danger pt-2" onClick={() => {
                                  setDeleteType("project");
                                  setDeleteId(item.projectId); 
                                  setDeleteDesignType(item.templateType.toString()); 
                                  setDeletePopup(true); 
                                  }
                                  } >
                                  {t("qrcodesPage.delete")} 
                                </div>  
                              </div>
                            </div>
                          </div>
                          <img src={item.templateURL} alt="thump" className="qr-template-img" />
                        </div>
                        <div className="flex-col border-bottom p-11px">
                          <div className="flex pb-11px space-between">
                            <div className="order-span-text pb-5px mt-4px ">
                              {getTemplateType(item.templateType.toString())}
                            </div>
                            <div className="order-span-text pb-5px mt-4px">{item.width.toString() + getMeasureString(item.measurementUnit)} x {item.height.toString() + getMeasureString(item.measurementUnit)}</div>
                          </div>

                          <div className="flex pt-2px space-between">
                            <div className="qr-template-span-size-text pb-5px mt-5px ">
                            <span className="">{item.name}</span>
                            </div>
                            <div className="qr-template-span-size-text pb-5px">
                              <button className="qr-template-btn" onClick={e => handlePreviewOpen(item.templateURL)}>{t("qrcodesPage.preview")}   <img src={eyeIcon} alt="" /></button>
                            </div>
                          </div>

                        </div>
                        <div className="pt-17px px-10px pb-3 flex-col ">
                          <div className="flex pb-2px space-between">
                          <button className="qr-template-btn" onClick={()=>handleDownloadClick(item.projectId)}>{t("qrcodesPage.download")}   <img src={downloadIcon} alt="" /></button>
                            <button className="qr-template-btn" onClick={e => onEditProject(item.projectId)} >{t("qrcodesPage.edit")}  <img src={editIcon} alt="" /></button>
                            {/* <button className="qr-template-btn" onClick={e => handlePreviewOpen(item.templateURL)}>{t("qrcodesPage.preview")}   <img src={eyeIcon} alt="" /></button> */}
                            {((Number(adminUserRole) == Number(USER_ROLE_ACCOUNT_MANAGER))) ? <button className="qr-template-order-btn" onClick={e => setShowCartSelection(true)}>{t("qrcodesPage.order")} <img src={cartIcon} alt="" /></button> : <></>}
                          </div>
                          {/* <button className="qr-template-add-btn primary" onClick={e => navigate(ROUTE_QRC_CHECKOUT)}>{t("qrcodesPage.addToCart")} <img src={cartIcon} alt="" /></button> */}
                        </div>
                      </div>
                    </>)}
                  </>}
                </div>}

              </>}

              <div>&nbsp;</div>

              {showIframe && selPoolStaffId && selPoolStaffId.trim().length > 0 && <div className="">
                <div className="div-right mr-20px mb-20px">
                  <ButtonInLine
                    type="edit_or_save"
                    onClick={(e) => setExitProjectDesignPopUp(true)}
                    buttonLabel={t("common.close")}
                  />
                </div>
                <iframe
                  id="editoriframe2"
                  src={QRCODE_DH_IFRAME_URL+"?project_id=" + iframeProjectId + "&token=" + DHUserAccessToken}
                  width="100%"
                  height="550"
                ></iframe>

              </div>}

              <div id="div-dhiframe" className=""> </div>

            </div></>
            :
            <>
              <div className="qr-main-body w-100">
                <div className=''>
                  {/* 
                  **********************************************************************
                  ********** CART AND MY PROJECTS DATA LISTING TABLE HEADINGS ********** 
                  **********************************************************************
                  */}
                 
                  <div className='qr-checkout-head'>
                    <div className='w-100'>
                      <div className='selected-qr-card border-none bg-transparent'>
                        <div className="" style={{ width: "110px" }}>

                        </div>
                        <div className='pl-18px pr-21px flex w-100 align-items-center'>
                          <div className='flex-col w-100 '>
                            <span>{t("QRCodesCheckOut.item")}</span>
                          </div>
                          <div className="w-100 center ">
                            <span>{t("QRCodesCheckOut.quantity")}</span>
                          </div>
                          <div className='w-100 flex-end pr-21px'>{t("QRCodesCheckOut.price")}</div>
                          <div className='w-100 flex-end'>
                            <span style={{ width: "35px", minWidth: "35px" }}>&nbsp;</span>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  {/* 
                  *******************************************************
                  ********** CART AND MY PROJECTS DATA LISTING ********** 
                  *******************************************************
                  */}
                  <div className='px-4 pt-17px flex-col gap-12px'>
                    {accountProjectsData && accountProjectsData.length > 0 && <>
                      {accountProjectsData.map((item, index) => <>
                        <div className='selected-qr-card'>
                          <div className="selected-qr-img">
                            <img src={item.templateURL} alt="thump" className="qr-template-img" />
                          </div>
                          <div className='pl-18px pr-21px flex w-100 align-items-center'>
                            <div className='flex-col w-100 '>
                              <span className='text-14px-600-black pb-12px'>{item.name}</span>
                              <span className='text-12px-600-grey'>
                                {getTemplateType(item.templateType.toString()) + " " + item.width.toString() + getMeasureString(item.measurementUnit)} x {item.height.toString() + getMeasureString(item.measurementUnit)}
                              </span>
                            </div>
                            <div className="w-100 center ">
                              <div className=''>
                                <SelectField
                                  type=""
                                  name={"sel_qty_" + index.toString()}
                                  id={"sel_qty_" + index.toString()}
                                  placeHolder={t("qrcodesPage.selectQuantity")}
                                  className={"pl-10px ml-10px max-width-135px "}
                                  value={item.selectedValue}
                                  onChange={(e) => setAccProjQtyPrice(e, item.projectId, item.templateType, item)}
                                  options={
                                    <>
                                      <option value={""}>{t("qrcodesPage.selectQuantity")}</option>

                                      {item.templateType && Number(item.templateType) == Number(DH_TEMPLATE_DESIGN_TYPE_POSTCARD) && stateVPPricingDataPostCard && stateVPPricingDataPostCard.map((prItem, prIndex) => {
                                        return <option value={prIndex.toString()}>{prItem.quantity.toString() + ' - ' + prItem.productName.replace(/^\"(.+)\"$/, "$1").replace('""', '"').replace('""', '"')} &nbsp; &nbsp; </option>
                                      })}

                                      {item.templateType && Number(item.templateType) == Number(DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD) && stateVPPricingDataBusinessCard && stateVPPricingDataBusinessCard.map((prItem, prIndex) => {
                                        return <option value={prIndex.toString()}>{prItem.quantity.toString() + ' - ' + prItem.productName.replace(/^\"(.+)\"$/, "$1").replace('""', '"').replace('""', '"')} &nbsp; &nbsp; </option>
                                      })}

                                      {item.templateType && Number(item.templateType) == Number(DH_TEMPLATE_DESIGN_TYPE_TABLETENT) && stateVPPricingDataTableTent && stateVPPricingDataTableTent.map((prItem, prIndex) => {
                                        return <option value={prIndex.toString()}>{prItem.quantity.toString() + ' - ' + prItem.productName.replace(/^\"(.+)\"$/, "$1").replace('""', '"').replace('""', '"')} &nbsp; &nbsp; </option>
                                      })}

                                    </>
                                  }
                                />
                              </div>
                            </div>
                            <div className='w-100 flex-end pr-21px'>{t("currency") + (item.price?.toFixed(2))?.toString()}</div>
                            <div className='w-100 flex-end'>
                              {item.locked && item.locked === true ? <>
                                <img src={checkIcon} alt="check" className="mr-10px" />
                                <button className={"qr-template-btn px-7px min-width-50px pointer-default"} onClick={e => dummyFunction()} ><span className={" "}>{t("qrcodesPage.added")}</span></button>
                              </>
                                : <>
                                  <button className={Number(item.quantity) > 0 ? "qr-template-btn px-7px min-width-50px primary" : "qr-template-btn px-7px min-width-50px disabled disabled-text-color"} onClick={e => Number(item.quantity) > 0 ? onAddtoCart(item) : dummyFunction()} ><span className={Number(item.quantity) > 0 ? " " : "disabled-text-color"}>{t("qrcodesPage.add")}</span></button>
                                </>}
                            </div>
                          </div>
                        </div>
                      </>)}
                    </>}
                  </div>
                  <div className='w-100 flex-end'>&nbsp;</div>

                  <div className='qr-checkout-footer'>
                    <div className='w-100 space-between flex mt-20px mb-20px'>
                      <div><h1 className='text-22px-600-black pt-3px'>{t("qrcodesPage.orderSummary")}</h1></div>
                      <div className="qr-cart-footer-text mt-5px">
                        <div>
                          {t("qrcodesPage.footerTotalCards", { totalCards: totalQuantity })}
                          {totalStaff > 0 && <><span>{" (" + (totalStaff == 1 ? t("qrcodesPage.footerStaffMember", { staffCount: totalStaff }) : t("qrcodesPage.footerStaffMembers", { staffCount: totalStaff })) + ") "}</span></>}
                          {totalGroups > 0 && <><span>{" (" + (totalGroups == 1 ? t("qrcodesPage.footerTotalGroup", { groupCount: totalGroups }) : t("qrcodesPage.footerTotalGroups", { groupCount: totalGroups })) + ") "}</span></>}
                        </div>
                        <div className="qr-cart-footer-text-grey">{t("qrcodesPage.footerQuantity")}</div>
                      </div>
                      <div className="qr-cart-footer-text  mt-5px">
                        <div>{t("currency") + (totalAmount.toFixed(2)).toString()}</div>
                        <div className="qr-cart-footer-text-grey">{t("qrcodesPage.subTotal")}</div>
                      </div>
                      <div className=""><button className="qr-continue-btn primary" onClick={()=> checkItemsSelected()}>{t("qrcodesPage.continueToCheckout")}</button></div>
                    </div>
                  </div>
                </div>
              </div>
            </>}
          </div>
        </div>

      <Modal
        open={previewOpen}
        onClose={handlePreviewClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleLogoPopup} className=" center flex-col">
          <div className="w-100 relative">
            <IconButton onClick={() => handlePreviewClose()} aria-label="delete" size="large" sx={{ position: "absolute", right: "-32px", top: "-32px" }}>
              <CloseRounded sx={{ fontSize: "17px" }} />
            </IconButton>
          </div>
          {previewImage && previewImage.trim().length > 3 ? <img src={previewImage} alt="" width={"auto"} height={"auto"} className="max-height-600px min-width-200px max-w-617px mt-1" /> : <></>}
        </Box>
      </Modal>

      <Modal
        open={statusPopup}
        onClose={() => setStatusPopup(false)}
        aria-labelledby={"addGroup"}
        aria-describedby=""
      >
        <Box sx={style1}>
          <div className="idproof-modal div-center text-center relative">
            <div className="page-heading">
              <img src={warning} alt="warning" />
            </div>
            <div className="idproof-flex-center div-center">
              <div>
                {t("qrcodesPage.confirmNewBlankProject", { designType: getTemplateType(selBlankDesignType.toString()) })}
              </div>
            </div>
            <div className="idproof-margin"></div>
            <div className="flex w-100">
              <ButtonModal
                type="cancel"
                onClick={(e) => setStatusPopup(false)}
                buttonLabel={t("common.cancel")}
              />
              <ButtonModal
                type={''}
                onClick={(e) => {
                  onDisplayTemplatesTypeClick(DH_TEMPLATE_TYPE_BLANK);
                  setStatusPopup(false);
                }
                }
                buttonLabel={t("common.yes")}
                className=" ml-15px"
              />
            </div>
          </div>
        </Box>
      </Modal> 

      <Modal
        open={templateStatusPopup}
        onClose={() => setTemplateStatusPopup(false)}
        aria-labelledby={"addTemplate"}
        aria-describedby=""
      >
        <Box sx={style1}>
          <div className="idproof-modal div-center text-center relative">
            <div className="page-heading">
              <img src={warning} alt="warning" />
            </div>
            <div className="idproof-flex-center div-center">
              <div>
                {t("qrcodesPage.confirmNewTemplate", { designType: getTemplateType(selNewTemplateType.toString()) })}
              </div>
            </div>
            <div className="idproof-margin"></div>
            <div className="flex w-100">
              <ButtonModal
                type="cancel"
                onClick={(e) => setTemplateStatusPopup(false)}
                buttonLabel={t("common.cancel")}
              />
              <ButtonModal
                type={''}
                onClick={(e) => {
                  onCreatePreMadeTemplateClick();
                  setTemplateStatusPopup(false);
                }
                }
                buttonLabel={t("common.yes")}
                className=" ml-15px"
              />
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={deletePopup}
        onClose={() => {
            setDeletePopup(false);
            setDeleteId("");
            setDeleteType("")
            setDeleteDesignType("");           
          }
        }
        aria-labelledby={"deletePopup"}
        aria-describedby=""
      >
        <Box sx={style1}>
          <div className="idproof-modal div-center text-center relative">
            <div className="page-heading">
              <img src={redwarning} alt="delete" />
            </div>
            <div className="idproof-flex-center div-center">
              <div>
                {deleteType=="template" ? t("qrcodesPage.confirmDeleteDHTemplate",{designType:getTemplateType(deleteDesignType)}) : t("qrcodesPage.confirmDeleteDHProject",{designType:getTemplateType(deleteDesignType)})}
              </div>
            </div>

            <div className="idproof-margin"></div>
            <div className="flex w-100">
              <ButtonModal
                type="cancel"
                onClick={(e) => {
                    setDeletePopup(false);
                    setDeleteId("");
                    setDeleteType("")
                    setDeleteDesignType(""); 
                  }
                }
                buttonLabel={t("common.cancel")}
              />
              <ButtonModal
                type={''}
                onClick={(e) => {
                    onDeleteDHProjectTemplate(deleteType);
                    setDeletePopup(false);
                  }
                }
                buttonLabel={t("common.yes")}
                className=" ml-15px"
              />
            </div>
          </div>
        </Box>
      </Modal>


      {/* ****************************************************************************
      **************** MODAL - CONFIRM EXIT PROJECT DESIGN ***************************
      ****************************************************************************** */}
      <Modal 
        open={exitProjectDesignPopUp}
        onClose={() => setExitProjectDesignPopUp(false)}
        aria-labelledby={"exitProjectDesign"}
        aria-describedby=""
      >
        <Box sx={style1}>
          <div className="idproof-modal div-center text-center relative">
            <div className="page-heading">
              <img src={warning} alt="warning" />
            </div>
            <div className="idproof-flex-center div-center">
              <div>
                {t("qrcodesPage.confirmExitDesign")}
              </div>
            </div>
            <div className="idproof-margin"></div>
            <div className="flex w-100">
              <ButtonModal
                type="cancel"
                onClick={(e) => setExitProjectDesignPopUp(false)}
                buttonLabel={t("common.cancel")}
              />
              <ButtonModal
                type={''}
                onClick={(e) => {
                  cancelEdit();
                  setExitProjectDesignPopUp(false);
                }
                }
                buttonLabel={t("common.yes")}
                className=" ml-15px"
              />
            </div>
          </div>
        </Box>
      </Modal>


      
      {/* ****************************************************************************
      ************ MODAL - SELECT GROUPS / STAFF TO LIST MY DESIGNS ITEMS ************
      ****************************************************************************** */}
      <Modal
        open={selectGroupStaffPopup}
        onClose={() => setSelectGroupStaffPopup(false)} 
        aria-labelledby={"selectGroupStaffPopup"}
        aria-describedby=""
      >
        <Box sx={style1}>
          <div className="w-100 manage-modal">
            <div className="space-between center border-bottom p-3">
              <h1>{t("qrcodesPage.selectGroupsStaff")}</h1>
              <span onClick={() => setSelectGroupStaffPopup(false)} className="pointer">
              <CloseRounded />
              </span>
            </div>

            <div className="p-3">
              <div className="center">
                <div className="idproof-margin">
                  <QRSidePanel 
                    calledFor={"applyFilter" } 
                    applyGroupStaffFilter={applyGroupStaffFilter}
                    displayTemplatesType={DH_TEMPLATE_TYPE_ACCOUNT} 
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/* ******************************************************************************
      ************** MODAL - APPLY TEMPLATE TO GROUPS / STAFF SELECTION ****************
      ****************************************************************************** */}
      <Modal
        open={applyTemplatePopup}
        onClose={() => {
            setApplyTemplatePopup(false);
            setInternalProjectId("");
            setInternalTemplateType(0); 
          }
        }
        aria-labelledby={"applyTemplatePopup"}
        aria-describedby=""
      >
        <Box sx={style1}>
          <div className="w-100 manage-modal">
            <div className="space-between center border-bottom p-3">
              <h1>{t("qrcodesPage.applyTemplateTo")}</h1>
              <span
                onClick={() => {
                  setApplyTemplatePopup(false);
                  setInternalProjectId("");
                  setInternalTemplateType(0); 
                  }
                } 
                className="pointer"
              >
              <CloseRounded />
              </span>
            </div>

            <div className="border-bottom p-3">
              <div className="center">
                <div className="idproof-margin">
                  <QRSidePanel 
                    calledFor={"applyTemplate"} 
                    applyTemplateToGroupStaff={applyTemplateToGroupStaff} 
                    displayTemplatesType={DH_TEMPLATE_TYPE_BASE} 
                  />
                </div>
              </div>
            </div>
            
          </div>
        </Box>
      </Modal>

      {/* ******************************************************************************
      ************** MODAL - CREATE NEW TEMPLATE TO GROUPS / STAFF SELECTION ***********
      ****************************************************************************** */}
      <Modal 
        open={createTemplatePopup}
        onClose={() => setCreateTemplatePopup(false)}
        aria-labelledby={"createTemplatePopup"}
        aria-describedby=""
      >
        <Box sx={style1}>
          <div className="w-100 manage-modal">
            <div className="space-between center border-bottom p-3">
              <h1>{t("qrcodesPage.createYourOwnFor", { designType: getTemplateType(selBlankDesignType.toString()) })}</h1>
              <span
                onClick={() => setCreateTemplatePopup(false)} 
                className="pointer"
              >
              <CloseRounded />
              </span>
            </div>

            <div className="border-bottom p-3">
              <div className="center">
                <div className="idproof-margin">
                  <QRSidePanel 
                    calledFor={"applyFilter" } 
                    applyGroupStaffFilter={applyGroupStaffFilter}
                    displayTemplatesType={DH_TEMPLATE_TYPE_BLANK} 
                  /> 
                </div>
              </div>
            </div>
            
          </div>
        </Box>
      </Modal>

    </div>
  );
}

export default QRCodes;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}

