import React from 'react'

// COMMON BUTTON COMPONENT FOR MODALS

interface ButtonModalProps {
  type?: 'cancel' | 'delete' | '';
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  buttonLabel: string;
  buttonType?: 'submit' | 'button';
}

export const ButtonModal: React.FC<ButtonModalProps> = React.memo((props) => {
  let content;
  switch (props.type) {
    case 'cancel':
      content = <button
        className={"cancelbtn w-100 text-dark " + props.className}
        onClick={props.onClick}
      >
        {props.buttonLabel}
      </button>;
      break;
    case 'delete':
      content = <button
        className={"cancelbtn w-100 bg-danger " + props.className}
        onClick={props.onClick}
      >
        {props.buttonLabel}
      </button>;
      break;

    default:
      content = <button
        className={"cancelbtn  bg-blue text-white w-100 " + props.className}
        onClick={props.onClick}
        type={props.buttonType ? props.buttonType : 'button'}
      >
        {props.buttonLabel}
      </button>;
      break;
  }

  return (
    <>{content}</>
  )
});
