import { Outlet, Navigate } from 'react-router-dom'
import { isAuthenticated } from '../../services/authenticationService';
import { ROUTE_ACCOUNTS_LOGIN } from '../../config/env';
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

// USED IN APP.tsx
const ProtectedRoutes = () => {
    return(
        isAuthenticated() ? <><Navbar /><Outlet/><Footer /></> : <Navigate to={ROUTE_ACCOUNTS_LOGIN} />
    )
}

export default ProtectedRoutes

