import React, { useEffect, useState, useRef } from 'react';
// import { useAppDispatch, useAppSelector } from '../../helpers/hook';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { ICommissions, ICommissionsSummary } from '../../models/commissionsInterfaces';
import { IStripePayMethod } from '../../models/tipsInterfaces';

import { fetchCommissionsSummary, fetchCommissions } from '../../services/commissionsListServices';

import { IGenericResponse, IPagination } from '../../models/interfaces';

export interface DataState {
  commissionsList: ICommissions[];
  commissionsSummary: ICommissionsSummary; 
  commissionsListLoading: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
  totalSize: number;
  currentPage: number;
  totalPage: number;
}

const initialState: DataState = {
  commissionsList: [],
  commissionsSummary: { totalTransaction: 0,
    totalTransactionAmount: 0,
    totalTransactionAmount_currency: '', 
    totalPayout: 0,
    totalPayoutAmount: 0,
    totalPayoutAmount_currency: '',
    stripeFees: 0,
    stripeFees_currency: '',
    platformFees: 0,
    platformFees_currency: ''
  },
  commissionsListLoading: 'idle',
  error: '',
  totalSize: 0,
  currentPage: 0,
  totalPage: 0
};

export const commissionsListDataSlice = createSlice({
  name: 'commissionsListData',
  initialState,
  reducers: {
    setCommissionsList: (state, action: PayloadAction<any>) => {
      state.commissionsList = action.payload;
    },
    setCommissionsDataLoading: (state) => {
      state.commissionsListLoading = 'loading';
    },
    setCurrentPage: (state, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    resetCommissionsSummary: (state, action: PayloadAction<any>) => {
      state.commissionsSummary = action.payload;
    },
    resetCommissions: (state, action: PayloadAction<any>) => {
      state.commissionsList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCommissions.pending, (state) => {
      state.commissionsList = [];
      state.commissionsListLoading = 'loading';
    });
    builder.addCase(fetchCommissions.fulfilled, (state, action) => {
      const m = action.payload as IPagination<ICommissions[]>;
      state.commissionsList = m.results;
      state.commissionsListLoading = 'succeeded';
      state.totalSize = m.count;
    });
    builder.addCase(fetchCommissions.rejected, (state, { payload }: any) => {
      state.commissionsListLoading = 'error';
      state.totalSize = 0;
      // state.error = action.error.message;
    });

    builder.addCase(fetchCommissionsSummary.pending, (state) => {
      state.commissionsSummary = { totalTransaction: 0,
        totalTransactionAmount: 0,
        totalTransactionAmount_currency: '',
        totalPayout: 0,
        totalPayoutAmount: 0,
        totalPayoutAmount_currency: '',
        stripeFees: 0,
        stripeFees_currency: '',
        platformFees: 0,
        platformFees_currency: ''
      };
      // state.commissionsListLoading = 'loading';
    });
    builder.addCase(fetchCommissionsSummary.fulfilled, (state, action) => {
      const m = action.payload as ICommissionsSummary;
      state.commissionsSummary = m;
      // state.commissionsListLoading = 'succeeded';
    });
    builder.addCase(fetchCommissionsSummary.rejected, (state, { payload }: any) => {
      state.commissionsSummary = { totalTransaction: 0,
        totalTransactionAmount: 0,
        totalTransactionAmount_currency: '',
        totalPayout: 0,
        totalPayoutAmount: 0,
        totalPayoutAmount_currency: '',
        stripeFees: 0,
        stripeFees_currency: '',
        platformFees: 0,
        platformFees_currency: ''
      };
      // state.commissionsListLoading = 'error';
      // state.error = action.error.message;
    }); 
  },
});

// Action creators are generated for each case reducer function
export const { setCommissionsList, setCommissionsDataLoading, resetCommissions, resetCommissionsSummary, setCurrentPage } = commissionsListDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCommissionsListDataSlice = (state: RootState) => state.commissionsListData;

export const commissionsListDataReducer = commissionsListDataSlice.reducer;
