import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { newAccountVerifyEmailLink, fetchBusinessTypes, newAccountSignUpCreate } from '../../../services/signUpServices';
import { IBusinessTypes } from '../../../models/signUpInterfaces';
import { ERR_ACC_NAME_EXIST, USER_ALREADY_MAPPED_TO_HOTEL } from '../../../config/apiResponseCodes';
import i18n from "../../../utilities/i18n";
import { setNotification } from '../../../services/apiService';

export interface IAuthentication {
  isProcessingRequest: 'idle' | 'loading' | 'succeeded' | 'error';
  signUpData?: {};
  businessDetailsResponseType?: string;
  businessTypesList: IBusinessTypes[];
  createAccountStatus?: string;
  newAccountHotelId?: number;
  newAccountUserEmail?: string;
  newAccountUserFirstName?: string;
  newAccountUserLastName?: string;
  newAccountSecretId?: string;
}

const initialState: IAuthentication = {
  isProcessingRequest: 'idle',
  signUpData: {},
  businessDetailsResponseType: '',
  businessTypesList: [],
  createAccountStatus: '',
  newAccountHotelId: 0,
  newAccountUserEmail: '',
  newAccountUserFirstName: '',
  newAccountUserLastName: '',
  newAccountSecretId: ''
};

export const businessDetailsSlice = createSlice({
  name: 'businessDetailsData',
  initialState,
  reducers: {
    setIsProcessingRequest: (state) => {
      state.isProcessingRequest = 'idle';
    },
    setBusinessDetailsResponseType: (state, action: PayloadAction<any>) => {
      state.businessDetailsResponseType = action.payload;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(newAccountVerifyEmailLink.pending, (state) => {
      console.log('state', state);
      state.signUpData = '';
      state.isProcessingRequest = 'loading';
    });
    builder.addCase(newAccountVerifyEmailLink.fulfilled, (state, action) => {
      console.log('action: ', action);
      state.signUpData = action?.payload?.message ? action.payload.message : 'succeeded';
      state.isProcessingRequest = 'succeeded';
      state.newAccountUserFirstName = action?.payload?.firstName ? action.payload.firstName : '';
      state.newAccountUserLastName = action?.payload?.lastName ? action.payload.lastName : '';
      state.newAccountUserEmail = action?.payload?.username ? action.payload.username : '';

      state.businessDetailsResponseType = action?.payload?.message ? action.payload.message : 'succeeded';

    });
    builder.addCase(newAccountVerifyEmailLink.rejected, (state, { payload }: any) => {
      console.log('payload: ', payload);
      state.signUpData = payload.response.data;
      state.isProcessingRequest = 'error';
      state.businessDetailsResponseType = payload?.response?.data?.message ? payload.response.data.message : 'error';
    });

    builder.addCase(fetchBusinessTypes.pending, (state) => {
      console.log('state11', state);
      state.businessTypesList = [];
      state.isProcessingRequest = 'loading';
    });
    builder.addCase(fetchBusinessTypes.fulfilled, (state, action) => {
      console.log('action22: ', action);
      state.businessTypesList = action?.payload ? action.payload : [];
      // state.isProcessingRequest = 'succeeded';  
      // state.businessDetailsResponseType = action?.payload?.data?.message ? action.payload.data.message : 'succeeded'; 
    });
    builder.addCase(fetchBusinessTypes.rejected, (state, { payload }: any) => {
      console.log('payload33: ', payload);
      state.businessTypesList = [];
      // state.signUpData = payload.response.data;
      // state.isProcessingRequest = 'error';
      // state.businessDetailsResponseType = payload?.response?.data?.message ? payload.response.data.message : 'error'; 
    });

    builder.addCase(newAccountSignUpCreate.pending, (state) => {
      console.log('state111', state);
      state.createAccountStatus = 'loading';
    });
    builder.addCase(newAccountSignUpCreate.fulfilled, (state, action) => {
      console.log('action222: ', action);
      state.newAccountHotelId = action?.payload?.hotelId ? action.payload.hotelId : 0;
      state.newAccountUserEmail = action?.payload?.username ? action.payload.username : '';
      state.newAccountSecretId = action?.payload?.secretId ? action.payload.secretId : '';

      state.createAccountStatus = 'succeeded';
      // state.businessDetailsResponseType = action?.payload?.data?.message ? action.payload.data.message : 'succeeded'; 
    });
    builder.addCase(newAccountSignUpCreate.rejected, (state, { payload }: any) => {
      state.newAccountHotelId = 0;
      state.newAccountUserEmail = '';
      state.newAccountSecretId = '';
      state.createAccountStatus = payload?.response?.data?.message ? payload.response.data.message : 'error'; 
      let errMsg = i18n.t("messages.updationFailed"); 
      if(payload?.response?.data?.message===ERR_ACC_NAME_EXIST) {
        errMsg = i18n.t("messages.accountNameExist");
      }
      else if(payload?.response?.data?.message===USER_ALREADY_MAPPED_TO_HOTEL) {
        errMsg = i18n.t("messages.useralreadymapped");
      }
      setNotification(
        i18n.t("projectName"),
        errMsg, 
        "failed",
        "newAccountSignUpCreate"
      ); 
    });
  }
});

// Action creators are generated for each case reducer function
export const { setIsProcessingRequest, setBusinessDetailsResponseType } = businessDetailsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectBusinessDetailsSlice = (state: RootState) => state.businessDetailsData;

export const businessDetailsReducer = businessDetailsSlice.reducer;
