import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IPagination } from '../../models/interfaces';
import { IAdminUsers } from '../../models/adminUsersListInterfaces';
import { createAdminUser, deleteAdminUser, fetchAdminUsers, resetAdminUserPassword, updateAdminUser } from '../../services/adminUserServices';
import { setNotification } from '../../services/apiService';
import { t } from 'i18next';
import { ERR_USER_ALREADY_EXISTS, ERR_NEED_USER_MERGE, USER_SELF_DELETE } from '../../config/apiResponseCodes';

export interface DataState {
  adminUsersList: IAdminUsers[];
  currentPage: number;
  loading: 'idle' | 'loading' | 'succeeded' | 'error' | 'statusUpdated' | 'statusUpdateFailed' | 'userCreated' | 'userUpdated' | 'deleted' | 'resetUserPasswordSuccess';
  totalSize: number;
}

const initialState: DataState = {
  adminUsersList: [],
  currentPage: 1,
  loading: 'idle',
  totalSize: 0,
};

export const adminUsersListDataSlice = createSlice({
  name: 'staffListData',
  initialState,
  reducers: {
    setadminUsersList: (state, action: PayloadAction<any>) => {
      state.adminUsersList = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    setLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminUsers.pending, (state) => {
      state.adminUsersList = [];
      state.loading = 'loading';
    });
    builder.addCase(fetchAdminUsers.fulfilled, (state, action) => {
      const m = action.payload as IPagination<IAdminUsers[]>;
      state.adminUsersList = m.results;
      state.loading = 'succeeded';
      state.totalSize = m.count;
    });
    builder.addCase(fetchAdminUsers.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      state.totalSize = 0;
    });

    builder.addCase(createAdminUser.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(createAdminUser.fulfilled, (state, action) => {
      // const m = action.payload as IAdminAssociate[];
      console.log('createAdminUser.fulfilled: ');
      state.loading = 'userCreated';
      setNotification(
        t("projectName"),
        t("messages.userCreated"),
        "success",
        "createAdminUser"
      );
    });
    builder.addCase(createAdminUser.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      let resp = payload?.message ? (payload.message).toLowerCase() : "";
      let msgText = ""; 
      switch(resp) {
        case(ERR_USER_ALREADY_EXISTS.toLowerCase()) : msgText = t("messages.userAlreadyExists"); 
          break;
        default: msgText = t("messages.userCreationFailed"); 
          break; 
      }  
      
      setNotification(
        t("projectName"),
        msgText,
        "failed",
        "createAccountUsers"
      );
    });


    builder.addCase(resetAdminUserPassword.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(resetAdminUserPassword.fulfilled, (state, action) => {
      state.loading = 'resetUserPasswordSuccess';
      setNotification(
        t("projectName"),
        t("messages.verificationEmail"),
        "success",
        "resetAdminUserPassword"
      );
    });
    builder.addCase(resetAdminUserPassword.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      setNotification(
        t("projectName"),
        t("messages.verificationEmailFailed"),
        "failed",
        "resetAdminUserPassword"
      );
    });

    builder.addCase(deleteAdminUser.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(deleteAdminUser.fulfilled, (state, action) => {
      state.loading = action?.payload?.message ? 'error' : 'deleted';
      {
        action?.payload?.message == 'primary_user_delete' ?
        setNotification(
          t("projectName"),
          t("messages.alreadyAssociated"),
          "failed",
          "deleteAdminUser"
        ) :
        setNotification(
          t("projectName"),
          t("messages.userDeleted"),
          "success",
          "deleteAdminUser"
        )
      }
    });

    builder.addCase(deleteAdminUser.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      let msgText = payload.message.toLowerCase() == USER_SELF_DELETE ? t("messages.userSelfDeletionFailed")  : t("messages.userDeletedFailed"); 
      setNotification(
        t("projectName"),
        msgText,
        "failed",
        "deleteAdminUserError"
      ) 
    });

    builder.addCase(updateAdminUser.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(updateAdminUser.fulfilled, (state, action) => {
      console.log('updateAdminUser.fulfilled: ');
      state.loading = 'userUpdated';
      setNotification(
        t("projectName"),
        t("messages.userUpdated"),
        "success",
        "updateAdminUser"
      )
    });
    builder.addCase(updateAdminUser.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      let resp = payload?.message ? (payload.message).toLowerCase() : "";
      let msgText = ""; 
      switch(resp) {
        case(ERR_NEED_USER_MERGE.toLowerCase()) : msgText = t("messages.userAlreadyExists"); 
          break;
        default: msgText = t("messages.userUpdationFailed"); 
          break; 
      }  
      setNotification(
        t("projectName"),
        msgText,
        "failed",
        "createAccountUsers"
      );
    });
  },
});

// Action creators are generated for each case reducer function
export const { setadminUsersList, setLoading, setCurrentPage } = adminUsersListDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectadminUsersListDataSlice = (state: RootState) => state.adminUsersListData;

export const adminUsersListDataReducer = adminUsersListDataSlice.reducer;
