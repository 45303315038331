import React, { useEffect, useState, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import ReactQuill from "react-quill"; // , { Quill }
import "react-quill/dist/quill.snow.css";
import { loadStripe } from "@stripe/stripe-js";

import phoneIcon from "../../assets/images/phoneIcon.svg";
import mailIcon from "../../assets/images/mailIcon.svg";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from '@mui/material/DialogTitle';

import "./style.css";

import { useAppDispatch, useAppSelector } from "../../helpers/hook";
import {
  selectTipSlice,
  setTip,
  setHotel,
  TipState,
  setTipWithoutCommission,
} from "./tipSlice";

import { fetchTerms } from "../../services/signUpServices";

// import { selectStaffFormSlice } from '../StaffRegister/staffRegisterSlice';
import { selectAgreementDataSlice } from "../AccountSignUp/Agreement/agreementSlice";

// import { selectHotelFormDataSlice } from '../HotelForm/hotelFormDataSlice';
import { getHotelImage } from "../../services/tipService";
// import DiscreteSlider from '../../components/Slider/DiscreetSlideBar';
import {
  env_var,
  stripe_token_var,
  USER_GROUP_GUESTS,
  STRIPE_COMMISION,
  ROUTE_TIP_THANKYOU,
  ROOM_NAME_REMOVE_VALUE,
  TIP_THEME_DEFAULT_COLOR,
} from "../../config/env";

import logo from "../../assets/images/tovalogo.svg";
// import logo2 from '../../assets/images/tova_stack.png';
// THESE LOGOS MAY BE USE IN FUTURE - SO COMMENT
// import logoFB from '../../assets/images/facebook.svg';
// import logoInsta from '../../assets/images/instagram.svg';
// import logoTwitter from '../../assets/images/twitter.svg';
// import logoYoutube from '../../assets/images/youtube.svg';

import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";

import useResponsiveFontSize from "./useresponsive";

//styling options for card element
const useOptions = () => {
  const fontSize = useResponsiveFontSize();

  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

// declare const stripe: {
//   loadStripe: typeof loadStripe;
// };

let stripePromise = loadStripe(stripe_token_var);

export const TipForm = (props: any) => {
  const { t } = useTranslation();
  const params = useParams();
  const refz = useRef<any>(null);
  const navigate = useNavigate();
  const { termsData } = useAppSelector(selectAgreementDataSlice);

  const { tipValue, hotelValue, tipValueWithoutCommission } =
    useAppSelector(selectTipSlice);

  // const { tokenData, loading } = useAppSelector(selectstripeTokenSlice);
  const dispatch = useAppDispatch();
  const [tipfactors, setTipFactors] = useState<any>([]);
  const [hoteldata, setHoteldata] = useState<any>({});
  const [isCardError, setisCardError] = useState<boolean>(false);
  const [cstomtipval, setCstomTipval] = useState<number>();
  const [carderrormsg, setCardErrorMsg] = useState<any>("");

  const [modalopen, setModalOpen] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);

  const [value, setValue] = useState<any>(2);
  const [pstage, setPstage] = useState<any>(0);
  const [isAllowed, setIsAllowed] = useState<"loading" | true | false>(
    "loading"
  );
  const [isCustomTip, setCustomTip] = useState<boolean>(false);
  const [isPrivacyChecked, setPrivacyChecked] = useState<boolean>(false);
  const [isRoomVisible, setRoomvisible] = useState<boolean>(true);
  const [isCard, setisCard] = useState<boolean>(false);
  const [privacyerr, setPrivacyerr] = useState<boolean>(false);
  const [tipzero, setLessZero] = useState<boolean>(true);
  const [room_number, setRoomnumber] = useState<string>("");
  const [staffNameForGroup, setStaffNameForGroup] = useState<string>("");
  // const [nameOnCardError, setNameOnCardError] = useState<boolean>(false);
  const [amountChanged, setAmountChanged] = useState<boolean>(false);

  const [designtype, setDesigntype] = useState(0);
  const [editBgColor, setEditBgColor] = useState<any>("");
  const [editTextColor, setEditTextColor] = useState<any>("");
  const [hotelLogo, setHotelLogo] = useState<string>("");
  const [tipBackgroungImg, setTipBackgroungImg] = useState("");
  const [messageTitle, setMessageTitle] = useState("");
  const [messageDescription, setMessageDescription] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [message, setMessage] = useState("");
  const [reviewSection, setReviewSection] = useState([]);

  const [accCode, setAccCode] = useState<string>("");
  const [psCode, SetPsCode] = useState<string>("");
  const [psType, setPsType] = useState<string>("");

  const numericPattern = /^[0-9\b]+$/;

  // const [paymentRequest, setPaymentRequest] = useState<any>(null);
  // NOW PROCESSING FEE SHOULD TAKEN BY DEFAULT - SO USING THIS VARIABLE TO HANDLE IT NO MATTER WHAT VALUE COMES FROM THE API RESPONSE

  // const stripe = useStripe();
  // const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (amountChanged) {
      setPstage(0);
    }
  }, [amountChanged]);

  const handleClickOpen = () => {
    setModalOpen(true);

    let prevTipValue = Number(((tipValue + STRIPE_COMMISION) / (1 - (hoteldata.paymentCommission / 100))).toFixed(2))
    // (x+0.30)/(1-0.029)
    // let prevTipValue = Number(
    //   (
    //     tipValue +
    //     tipValue * (hoteldata.paymentCommission / 100) +
    //     STRIPE_COMMISION
    //   ).toFixed(2)
    // )

    setConfirmMessage(
      message
        .replace("<<$$>>", `$${prevTipValue}`)
        .replace("<<$$$>>", `$${tipValue}`)
    );
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleTermsClose = () => {
    setTermsModalOpen(false);
  };

  const addProcessFee = () => {
    dispatch(setTipWithoutCommission(Number(tipValue.toFixed(2))));
    // dispatch(setTip(Number(((tipValue/(1-(hoteldata.paymentCommission/100)))+STRIPE_COMMISION).toFixed(2))));
    // CHANGED CALCULATION ON 06Jan2023
    dispatch(
      setTip(Number(((tipValue + STRIPE_COMMISION) / (1 - (hoteldata.paymentCommission / 100))).toFixed(2)))
    );
    setAmountChanged(false);
  };

  React.useEffect(() => {
    console.log("params", params); // accCode/:psCode/:psType // hotelCode/PoolORStaffID/PoolType
    if (
      params.accCode !== undefined &&
      params.psCode !== undefined &&
      params.psType !== undefined
    ) {
      // (props.match.params.id)
      // ios fix
      const accCode = params.accCode;
      const psCode = params.psCode;
      const psType = params.psType;

      setAccCode(params.accCode);
      SetPsCode(params.psCode);
      setPsType(params.psType);

      dispatch(setHotel(accCode));
      setValue(2);

      getHotelImage(accCode, psCode, psType).then((res: any) => {
        console.log("res:status:", res["status"]);
        if (res["status"] === 200) {
          console.log(res.data);
          if (res.data.tipLogo != null) {
            console.log("value--", res);
            setHotelLogo(res.data.tipLogo);
          }
          setIsAllowed(res.data["isActive"] ? res.data["isActive"] : false);
          setHoteldata(res.data);
          setCustomTip(res.data.showCustomTipOnly ? res.data.showCustomTipOnly : false);
          setTipFactors([
            {
              tip_value: res.data.tip_1,
              tip_facts: res.data.tipDescription_1,
            },
            {
              tip_value: res.data.tip_2,
              tip_facts: res.data.tipDescription_2,
            },
            {
              tip_value: res.data.tip_3,
              tip_facts: res.data.tipDescription_3,
            },
            {
              tip_value: res.data.tip_4,
              tip_facts: res.data.tipDescription_4,
            },
          ]);

          setValue((res.data.defaultTipPreset-1));

          dispatch(setTip(res.data.showCustomTipOnly ? 0 : res.data.defaultTipPreset === 1 ? parseFloat(res.data.tip_1) : res.data.defaultTipPreset === 2 ? parseFloat(res.data.tip_2) : res.data.defaultTipPreset === 4 ? parseFloat(res.data.tip_4) : parseFloat(res.data.tip_3)));

          dispatch(setTipWithoutCommission(parseFloat(res.data.tip_3)));
          setAmountChanged(true);

          setDesigntype(res.data.tipDesignType);
          setEditBgColor(res.data.tipBgColor ? res.data.tipBgColor : TIP_THEME_DEFAULT_COLOR);
          setEditTextColor(res.data.tipDesignType === 1 ? res.data.headerBgColor : res.data.tipFontColor);
          setTipBackgroungImg(res.data.tipImage);
          setMessageTitle(res.data.reviewTitle);
          setMessageDescription(res.data.reviewBody);

          setReviewSection(res.data.reviewLinks.filter(
            item => item.isActive && item.link !== ''
          ));

          setMessage(res.data.confirmationMessage);
        }
      });
    }
  }, []);

  function valuetext(value: number) {
    console.log(value);
    return `${value}°C`;
  }

  function dummyFunction(field: any) {
    return "";
  }

  console.log(value, "aa");

  const handleClickOnTipValue = (newValue: number) => {
    setValue(newValue);
    dispatch(setTip(parseFloat(tipfactors[newValue]?.tip_value)));
    dispatch(
      setTipWithoutCommission(parseFloat(tipfactors[newValue]?.tip_value))
    );
    setAmountChanged(true);

    console.log(tipfactors[newValue]?.tip_value);
  };

  const modules = {
    toolbar: false,
  };

  const handleTermsChange = () => {
    return;
  };

  return (
    <>
      <div className="max-width-400 div-center-margin-auto ">
        {isAllowed === true && (
          <div className="bg-white">
            <div id="main">
              <div>
                <div
                  className="header-tip"
                  style={{
                    backgroundColor: designtype == 1 ? editTextColor : "white",
                  }}
                >
                  {designtype === 2 && (
                    <div
                      className="header-box-tip3"
                      style={{
                        backgroundImage: `url(${tipBackgroungImg})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <div className="circle-bottom"></div>
                      <div className={`circle-border ${(tipBackgroungImg !== null && tipBackgroungImg.length > 0) && "mt-100px"}`}>
                        <div className="circle-body">

                          {hotelLogo === "null" || hotelLogo.length == 0 ? (
                            <div className="hotel_name mt-3px" >
                              {hoteldata.name}
                            </div>
                          ) : (
                            <img
                              src={hotelLogo}
                              alt="tipLogo"
                              className="circle-image"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {designtype !== 2 && (
                    <div className={`${designtype == 3 && "mt-4 "}`}>
                      {hotelLogo === "null" || hotelLogo.length == 0 ? (
                        <div className={`hotel_name mt-3px ${designtype == 3 && "mb-3 mt-2"}`} style={{ color: (designtype === 1 && editTextColor !== "white" && editTextColor !== "#FFFFFF" && editTextColor !== "#ffffff") ? 'white' : 'black' }}>
                          {hoteldata.name}
                        </div>
                      ) : (
                        <img
                          className="tipLogo"
                          src={hotelLogo}
                          alt={hoteldata.name}
                        />
                      )}
                    </div>
                  )}

                  {designtype == 3 && (
                    <>
                      <div className="px-3 w-100 mt-2">
                        <div
                          className="card-backGround"
                          style={{ backgroundColor: editBgColor }}
                        >
                          <p
                            className="tip-factor-text"
                            style={{ color: editTextColor }}
                          >
                            {tipfactors[value]?.tip_facts}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <Dialog
              open={modalopen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <div className="tip-processfee-text mt-3 ">{confirmMessage}</div>

                  <div className="justify-center mb-3 ">
                    <button
                      className="btntipContinue mt-4 max-width-125px"
                      style={{
                        backgroundColor: editBgColor,
                        color: designtype === 3 ? editTextColor : "white",
                        fontStyle: designtype === 2 ? 'normal' : 'italic'
                      }}
                      onClick={() => {
                        handleClose();
                        setPstage(1);
                        addProcessFee();
                      }}
                      autoFocus
                    >
                      {t("buttons.ok")}
                    </button>
                  </div>
                </DialogContentText>
              </DialogContent>
            </Dialog>

            <Dialog
              open={termsModalOpen}
              onClose={handleTermsClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              {/* <DialogTitle id="alert-dialog-title">
              {"Would you like to cover the platform fee?"}
            </DialogTitle> */}
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <div className="mb-10px">
                    {/* <div className="mb-2">Terms & Conditions</div> */}
                    <ReactQuill
                      theme="snow"
                      value={termsData.conditionText}
                      onChange={handleTermsChange}
                      modules={modules}
                      readOnly={true}
                    />
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <div className="mr-35px mb-10px">
                  {/* <Button className='primary' onClick={()=>{handleTermsClose();}}>I Accept</Button> */}
                  <button
                    type="button"
                    className="btntipContinue mt-4"
                    style={{
                      backgroundColor: editBgColor,
                      color: designtype === 3 ? editTextColor : "white",
                      fontStyle: designtype === 2 ? 'normal' : 'italic'
                    }}
                    onClick={() => {
                      handleTermsClose();
                    }}
                  >
                    {t("tipPage.iAccept")}
                  </button>
                </div>
              </DialogActions>
            </Dialog>

            <div className="content_container pt-4 tipbg">
              <div
                className="tip-factor-header"
                style={{
                  marginTop: designtype === 2 ? "-15px" : "15px",
                  marginBottom: designtype == 2 ? "10px" : "35px",
                }}
              >
                <p
                  className="tip-para"
                  style={{
                    color:
                      designtype === 1
                        ? editBgColor
                        : designtype === 3
                          ? editBgColor
                          : "black",
                  }}
                >
                  {(hoteldata.tippingText !== "" && hoteldata.tippingText !== null && hoteldata.tippingText !== undefined) ? <>
                    {hoteldata.tippingText}</> : <>
                    {t("tipPage.tipping")}
                    {hoteldata.tippingName}</>}

                </p>
                <span
                  className="tip-value"
                  style={{
                    color:
                      designtype === 1
                        ? editBgColor
                        : designtype === 3
                          ? editBgColor
                          : "black",
                  }}
                >
                  {t("currency")}
                  {tipValue}
                </span>
                <p
                  className="tip-para"
                  style={{
                    color:
                      designtype === 1
                        ? editBgColor
                        : designtype === 3
                          ? editBgColor
                          : "black",
                  }}
                >
                  {t("tipPage.receive")}
                </p>
              </div>

              {(isRoomVisible && designtype === 2 && hoteldata.roomLabel !== ROOM_NAME_REMOVE_VALUE) ? (
                <>
                  <div className="margin-top25 w-100 text-start space-between align-items-center border-top border-bottom py-3 mb-3">
                    <label htmlFor="" className="tip3-label">
                      {hoteldata.roomLabel == 1 ? (
                        hoteldata.roomLabelMandatory
                          ? `${t("tipPage.roomNumberLabel")}`
                          : `${t("tipPage.roomNumberOptLabel")}`) :
                        (
                          hoteldata.roomLabelMandatory
                            ? `${t("tipPage.roomNameLabel")}`
                            : `${t("tipPage.roomNameOptLabel")}`
                        )
                      }
                    </label>
                    {hoteldata.roomLabel == 1 ? (
                      <>
                        <input
                          type={"text"}
                          maxLength={100}
                          className={
                            "tip-input-tip3" +
                            (hoteldata.roomLabelMandatory &&
                              room_number.trim().length == 0
                              ? " input-error "
                              : "")
                          }
                          placeholder={
                            hoteldata.roomLabelMandatory
                              ? `${t("tipPage.roomNumber")}`
                              : `${t("tipPage.roomNumberOpt")}`
                          }
                          value={room_number}
                          onChange={(e: any) => {
                            e.preventDefault();
                            setRoomnumber(e.target.value);
                          }}
                        />{" "}
                      </>
                    ) : (
                      <>
                        <input
                          type={"text"}
                          maxLength={100}
                          className={
                            "tip-input-tip3" +
                            (hoteldata.roomLabelMandatory &&
                              room_number.trim().length == 0
                              ? " input-error "
                              : "")
                          }
                          placeholder={
                            hoteldata.roomLabelMandatory
                              ? `${t("tipPage.roomName")}`
                              : `${t("tipPage.roomNameOpt")}`
                          }
                          value={room_number}
                          onChange={(e: any) => {
                            e.preventDefault();
                            setRoomnumber(e.target.value);
                          }}
                        />{" "}
                      </>
                    )}
                  </div>
                </>
              ) : null}

              <div className="slider-parent">
                {!isCustomTip && designtype === 2 && (
                  <div className="w-100 flex-start mb-3">
                    <label htmlFor="" className="tip3-label">
                      {t("tipPage.ChooseTip")}
                    </label>
                  </div>
                )}

                <div className="center gap-6px mb-6px">
                  {!isCustomTip ? (
                    <>
                      <div
                        className={" pointer custom_tip-box "}
                        style={{
                          backgroundColor:
                            tipfactors[0]?.tip_value === tipValue
                              ? editBgColor
                              : "#f5f5f5",
                          color:
                            tipfactors[0]?.tip_value === tipValue &&
                              designtype == 3
                              ? editTextColor
                              : tipfactors[0]?.tip_value === tipValue
                                ? "white"
                                : "black",
                        }}
                        onClick={(e) => handleClickOnTipValue(0)}
                      >
                        ${tipfactors[0]?.tip_value}
                      </div>
                      <div
                        className={" pointer custom_tip-box "}
                        style={{
                          backgroundColor:
                            tipfactors[1]?.tip_value === tipValue
                              ? editBgColor
                              : "#f5f5f5",
                          color:
                            tipfactors[1]?.tip_value === tipValue &&
                              designtype == 3
                              ? editTextColor
                              : tipfactors[1]?.tip_value === tipValue
                                ? "white"
                                : "black",
                        }}
                        onClick={(e) => handleClickOnTipValue(1)}
                      >
                        ${tipfactors[1]?.tip_value}
                      </div>
                      <div
                        className={" pointer custom_tip-box "}
                        style={{
                          backgroundColor:
                            tipfactors[2]?.tip_value === tipValue
                              ? editBgColor
                              : "#f5f5f5",
                          color:
                            tipfactors[2]?.tip_value === tipValue &&
                              designtype == 3
                              ? editTextColor
                              : tipfactors[2]?.tip_value === tipValue
                                ? "white"
                                : "black",
                        }}
                        onClick={(e) => handleClickOnTipValue(2)}
                      >
                        ${tipfactors[2]?.tip_value}
                      </div>
                      <div
                        className={" pointer custom_tip-box "}
                        style={{
                          backgroundColor:
                            tipfactors[3]?.tip_value === tipValue
                              ? editBgColor
                              : "#f5f5f5",
                          color:
                            tipfactors[3]?.tip_value === tipValue &&
                              designtype == 3
                              ? editTextColor
                              : tipfactors[3]?.tip_value === tipValue
                                ? "white"
                                : "black",
                        }}
                        onClick={(e) => handleClickOnTipValue(3)}
                      >
                        ${tipfactors[3]?.tip_value}
                      </div>
                    </>
                  ) : null}
                </div>

                {!isCustomTip ? (
                  <div
                    className="custom_tip-box w-100"
                    onClick={() => {
                      if (cstomtipval && cstomtipval > 0) {
                        dispatch(setTip(cstomtipval));
                        dispatch(setTipWithoutCommission(cstomtipval));
                        setAmountChanged(true);
                      }
                      setCustomTip(!isCustomTip);
                      refz.current.focus();
                    }}
                  >
                    <p className="">{t("tipPage.enterCustomTip")}</p>{" "}
                  </div>
                ) : null}

                {isCustomTip ? (
                  <div className="input-icons ">
                    <div className="space-between mb-2">
                      <p className="tip-label">{t("tipPage.tipAmount")}</p>
                      <span></span>
                      {!hoteldata.showCustomTipOnly &&
                        <p
                          className="tip-label pointer"
                          onClick={() => {
                            setCustomTip(false);
                            dispatch(
                              setTip(parseFloat(tipfactors[value]?.tip_value))
                            );
                            setAmountChanged(true);
                            dispatch(
                              setTipWithoutCommission(
                                parseFloat(tipfactors[value]?.tip_value)
                              )
                            );
                          }}
                        >
                          {t("tipPage.backToDefault")}
                        </p>}
                    </div>

                    <label id="search1">
                      <i className="fa fa-dollar"></i>
                      <input
                        ref={refz}
                        autoFocus
                        id="search-icon1"
                        className={`tip-input-tip pt-3px ${tipzero ? "redbrdr" : ""}`}
                        type="number"
                        pattern="\d*"
                        min="1"
                        step="1"
                        maxLength={10}
                        max={999999}
                        value={cstomtipval}
                        onChange={(e: any) => {
                          e.preventDefault();
                          if (
                            parseFloat(e.target.value) < 1 ||
                            e.target.value == ""
                          ) {
                            setLessZero(true);
                            setCstomTipval(e.target.value);
                          } else {
                            setLessZero(false);
                            setCstomTipval(e.target.value);
                            dispatch(setTip(parseFloat(e.target.value)));
                            dispatch(
                              setTipWithoutCommission(
                                parseFloat(e.target.value)
                              )
                            );
                            setAmountChanged(true);
                          }
                        }}
                      />
                    </label>
                  </div>
                ) : null}

                {(isRoomVisible && designtype !== 2 && hoteldata.roomLabel !== ROOM_NAME_REMOVE_VALUE) ? (
                  <>
                    <div className="margin-top25 w-100 text-start">
                      <label htmlFor="" className="tip-label">
                        {hoteldata.roomLabel == 1 ? (
                          hoteldata.roomLabelMandatory
                            ? `${t("tipPage.roomNumberLabel")}`
                            : `${t("tipPage.roomNumberOptLabel")}`) :
                          (
                            hoteldata.roomLabelMandatory
                              ? `${t("tipPage.roomNameLabel")}`
                              : `${t("tipPage.roomNameOptLabel")}`
                          )
                        }
                      </label>
                      {hoteldata.roomLabel == 1 ? (
                        <>
                          <input
                            type={"text"}
                            maxLength={100}
                            className={
                              "tip-input-tip" +
                              (hoteldata.roomLabelMandatory &&
                                room_number.trim().length == 0
                                ? " input-error "
                                : "")
                            }
                            placeholder={
                              hoteldata.roomLabelMandatory
                                ? `${t("tipPage.roomNumber")}`
                                : `${t("tipPage.roomNumberOpt")}`
                            }
                            value={room_number}
                            onChange={(e: any) => {
                              e.preventDefault();
                              setRoomnumber(e.target.value);
                            }}
                          />{" "}
                        </>
                      ) : (
                        <>
                          <input
                            type={"text"}
                            maxLength={100}
                            className={
                              "tip-input-tip" +
                              (hoteldata.roomLabelMandatory &&
                                room_number.trim().length == 0
                                ? " input-error "
                                : "")
                            }
                            placeholder={
                              hoteldata.roomLabelMandatory
                                ? `${t("tipPage.roomName")}`
                                : `${t("tipPage.roomNameOpt")}`
                            }
                            value={room_number}
                            onChange={(e: any) => {
                              e.preventDefault();
                              setRoomnumber(e.target.value);
                            }}
                          />{" "}
                        </>
                      )}
                    </div>
                  </>
                ) : null}
              </div>





              {/* ******************* STAFF NAME FOR GROUP - START ******************  */}
              {hoteldata.staffLabelForGroups ? <div className="margin-top20 w-100 text-start">
                    <label htmlFor="" className="tip3-label"> {t("tipPage.staffNameForGroup")} </label>
                    <input
                          type={"text"}
                          maxLength={100}
                          className={"tip-input-tip" }
                          placeholder={t("tipPage.staffNameForGroup")}
                          value={staffNameForGroup}
                          onChange={(e: any) => {
                            e.preventDefault();
                            setStaffNameForGroup(e.target.value);
                          }}
                          maxLength={150}
                        />
                  </div> : <></>}

              {/* ******************* STAFF NAME FOR GROUP - END ******************* */}









              <Elements stripe={stripePromise}>
                <div className="mt-2 text-center w-100">
                  <ApplePayForm />
                  {isCard ? (
                    <div className="product">
                      {pstage > 0 && <CheckoutForm />}
                    </div>
                  ) : null}
                </div>
              </Elements>

              <div className="my-4">
                <p className="fontz">{t("tipPage.poweredBy")} {t("tipPage.useTova")}</p>
              </div>
            </div>
          </div>
        )}
        {isAllowed === false && (
          <div className="bg-white">
            <div id="main tipbg">
              <div>
                <div className="header-tip justify-space-between">
                  <div className="">&nbsp;</div>
                  <div className="justify-left">
                    <div className="div-left">
                      <div>
                        <img
                          className="small"
                          src={logo}
                          alt={`${t("projectName")}`}
                        />
                      </div>
                    </div>
                  </div>
                  {hotelLogo && hotelLogo.trim().length > 2 && hotelLogo !== "null" && <div className="">&nbsp;</div>}
                  <div className="div-right">
                    {(!hotelLogo || hotelLogo.trim().length == 0 || hotelLogo === "null") && (
                      <span className="none"><h5>
                        {hoteldata.name}
                        {/* <img
                          className="small"
                          src={logo}
                          alt={`${t("projectName")}`}
                        />  */}
                      </h5></span>
                    )}
                    {hotelLogo && hotelLogo.trim().length > 2 && hotelLogo !== "null" && (
                      <span className="none"><img
                        className="small"
                        src={hotelLogo}
                        alt={hoteldata.name}
                      /></span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="content_container mb-40px">
              <div className="rounded-20px inactive-hotel margin-top20 mb-40px">
                <div>
                  {t("tipPage.invalidRequest")}
                </div>
                <div className="mt-8px">
                  <img src={phoneIcon} alt="Phone: " className="me-1" />{" "}
                  <a href={"tel:" + t("tipPage.contactPhone")} target="_blank">{t("tipPage.contactPhone")}</a>
                </div>
                <div className="mt-5px">
                  <img src={mailIcon} alt="Email: " className="me-1" />{" "}
                  <a href={"mailto:" + t("tipPage.contactEmail")} target="_blank">{t("tipPage.contactEmail")}</a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );

  //Card element
  function CheckoutForm() {
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    const [cardname, setCardname] = useState<string>("");
    const [paymentRequest, setPaymentRequest] = useState<any>(null);
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const onCardNameChange = (e: any) => {
      e.preventDefault();
      setCardname(e.target.value);
      if (e.target.value.trim().length == 0) {
        // setNameOnCardError(true);
        document.getElementById("txt-cardname")?.classList.add("input-error");
      } else {
        // setNameOnCardError(false);
        document
          .getElementById("txt-cardname")
          ?.classList.remove("input-error");
      }
    };

    useEffect(() => {
      if (!stripe || !elements) {
        return;
      }
      let amountToStrip = parseInt((tipValue * 100).toFixed(0), 10);
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Demo total",
          amount: amountToStrip,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      pr.on("paymentmethod", async (e) => {
        let tipAmount = tipValueWithoutCommission;
        if (!hoteldata.tipIncludeProcessFee) {
          tipAmount = Number(
            (
              tipValue -
              STRIPE_COMMISION -
              (tipValue - STRIPE_COMMISION) *
              (hoteldata.paymentCommission / 100)
            ).toFixed(2)
          );
        }
        axios
          .get(
            `${env_var.API_URL}payments/token-stripe/?amount=${tipValue}&tip_amount=${tipAmount}&commission=${hoteldata.paymentCommission}&hotel=${accCode}&room=${room_number}&staff=${psCode}&tip_type=${psType}&group_staff_name=${staffNameForGroup ? staffNameForGroup : ""}`
          )
          .then((res) => { 
            const persons = res.data;
            // console.log(persons.)
            const paymentResult = stripe
              .confirmCardPayment(
                persons.token.client_secret,
                {
                  payment_method: e.paymentMethod.id,
                },
                { handleActions: false }
              )
              .then((paymentResult) => {
                const dataz = paymentResult.paymentIntent;
                if (dataz && dataz.status === "succeeded") {
                  console.log(dataz.id, "dataz");

                  setPaymentLoading(false);
                  setPstage(0);
                  setisCard(false);
                  // REDIRECT TO SUCCESS PAGE
                  navigate(ROUTE_TIP_THANKYOU, {
                    state: {
                      intent_id: dataz.id,
                      design_type: designtype,
                      hotel_logo: hotelLogo,
                      hotelBg_image: tipBackgroungImg,
                      theme_color: editBgColor,
                      theme_text_color: editTextColor,
                      message_title: messageTitle,
                      message_description: messageDescription,
                      review_section: reviewSection,

                      amount: tipValueWithoutCommission,
                      hotel_data: hoteldata,
                      tipValue: tipValue,
                      accCode: accCode,
                      psCode: psCode,
                      psType: psType,
                    },
                  });
                }
              });
          });
      });
    }, [stripe, elements]);

    // card payment initiation
    const payMoney = async (e: any) => {
      e.preventDefault();
      if (
        !isPrivacyChecked ||
        (hoteldata.roomLabelMandatory && room_number.trim().length == 0)
      ) {
        if (!isPrivacyChecked) {
          setPrivacyerr(true);
        }
        return;
      }

      if (hoteldata.tipIncludeProcessFee && amountChanged) {
        handleClickOpen();
        return;
      }

      if (cardname.trim().length == 0) {
        // setNameOnCardError(true);
        document.getElementById("txt-cardname")?.classList.add("input-error");
        return;
      }

      //checking is stripe is loaded for card element
      if (!stripe || !elements) {
        return;
      }
      //checking card name is entered
      if (cardname == "") {
        return;
      }
      setPaymentLoading(true);
      setisCardError(false);

      const req: TipState = {
        tipValue: tipValue,
        hotelValue: hotelValue,
        tipValueWithoutCommission: tipValueWithoutCommission,
      };

      //requesting stripe payment intent
      let tipAmount = tipValueWithoutCommission;
      if (!hoteldata.tipIncludeProcessFee) {
        tipAmount = Number(
          (
            tipValue -
            STRIPE_COMMISION -
            (tipValue - STRIPE_COMMISION) * (hoteldata.paymentCommission / 100)
          ).toFixed(2)
        );
      }

      axios
        .get(
          `${env_var.API_URL}payments/token-stripe/?amount=${tipValue}&tip_amount=${tipAmount}&commission=${hoteldata.paymentCommission}&hotel=${accCode}&room=${room_number}&staff=${psCode}&tip_type=${psType}&group_staff_name=${staffNameForGroup ? staffNameForGroup : ""}`
        )
        .then((res) => {
          const persons = res.data;

          //confirming payment intent
          const paymentResult = stripe
            .confirmCardPayment(persons.token.client_secret, {
              payment_method: {
                card: elements.getElement(CardNumberElement)!,
                billing_details: {
                  name: cardname,
                },
              },
            })
            .then((paymentResult) => {
              const dataz = paymentResult.paymentIntent;
              if (dataz && dataz.status === "succeeded") {

                console.log(dataz.id, "dataz");
                setPaymentLoading(false);
                setPstage(0);
                setisCard(false);
                // REDIRECT TO SUCCESS PAGE
                navigate(ROUTE_TIP_THANKYOU, {
                  state: {
                    intent_id: dataz.id,
                    design_type: designtype,
                    theme_color: editBgColor,
                    theme_text_color: editTextColor,
                    message_title: messageTitle,
                    message_description: messageDescription,
                    review_section: reviewSection,

                    amount: tipValueWithoutCommission,
                    hotel_logo: hotelLogo,
                    hotelBg_image: tipBackgroungImg,
                    hotel_data: hoteldata,
                    tipValue: tipValue,
                    accCode: accCode,
                    psCode: psCode,
                    psType: psType,
                  },
                });
              } else {
                //error handling
                setCardErrorMsg(paymentResult?.error?.message);
                setPaymentLoading(false);
                setisCardError(true);
              }
            })
            .catch((err) => alert(err.message));
        });
    };

    return (
      <div
        style={{
          padding: "2rem",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            maxWidth: "500px",
            margin: "0 auto",
          }}
        >
          {isPrivacyChecked == true && (
            <form
              style={{
                display: "block",
                width: "100%",
              }}
              onSubmit={(e) => payMoney(e)}
            >
              <p className="tip-label text-left mb-3">
                {t("tipPage.cardInformation")}
              </p>
              <div className="Demo">
                <label className="topCardnumber">
                  <CardNumberElement
                    options={options}
                    onReady={() => {
                      console.log("CardNumberElement [ready]");
                    }}
                    onChange={(event) => {
                      console.log("CardNumberElement [change]", event);
                    }}
                    onBlur={() => {
                      console.log("CardNumberElement [blur]");
                    }}
                    onFocus={() => {
                      console.log("CardNumberElement [focus]");
                    }}
                  />
                </label>

                <label style={{ display: "flex" }}>
                  <CardExpiryElement
                    options={options}
                    onReady={() => {
                      console.log("CardNumberElement [ready]");
                    }}
                    onChange={(event) => {
                      console.log("CardNumberElement [change]", event);
                    }}
                    onBlur={() => {
                      console.log("CardNumberElement [blur]");
                    }}
                    onFocus={() => {
                      console.log("CardNumberElement [focus]");
                    }}
                  />

                  <CardCvcElement
                    options={options}
                    onReady={() => {
                      console.log("CardNumberElement [ready]");
                    }}
                    onChange={(event) => {
                      console.log("CardNumberElement [change]", event);
                    }}
                    onBlur={() => {
                      console.log("CardNumberElement [blur]");
                    }}
                    onFocus={() => {
                      console.log("CardNumberElement [focus]");
                    }}
                  />
                </label>
              </div>

              <p className="tip-label text-left mb-3">
                {t("tipPage.nameOnCard")}
              </p>
              <div className="Demo b0">

                <input
                  type="text"
                  id="txt-cardname"
                  className={"tip-input-tip"}
                  value={cardname}
                  onChange={(e: any) => {
                    e.preventDefault();
                    onCardNameChange(e);
                  }}
                />
              </div>

              {isCardError ? (
                <p className="m0 text-center text-danger">{carderrormsg}</p>
              ) : null}

              <button
                disabled={isPaymentLoading}
                type="submit"
                className="btntipContinue mt-4"
                style={{
                  backgroundColor: editBgColor,
                  color: designtype === 3 ? editTextColor : "white",
                  fontStyle: designtype === 2 ? 'normal' : 'italic'
                }}
              >
                {isPaymentLoading
                  ? `${t("tipPage.processing")}`
                  : `${t("tipPage.pay")}`}
              </button>
            </form>
          )}
        </div>
      </div>
    );
  }

  function showTerms() {
    dispatch(fetchTerms(USER_GROUP_GUESTS.toString()));
    setTermsModalOpen(true);
  }

  // apple pay and google pay element component
  function ApplePayForm() {
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    const [cardname, setCardname] = useState<string>("");
    const [paymentRequest, setPaymentRequest] = useState<any>("");
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    useEffect(() => {
      // checking is stripe is loaded
      if (!stripe || !elements) {
        return;
      }

      // Creating payment request for applepay and google pay
      let amountToStrip = parseInt((tipValue * 100).toFixed(0), 10);
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: t("projectName"),
          amount: amountToStrip,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      pr.on("paymentmethod", async (e) => {
        //requesting stripe payment intent
        let tipAmount = tipValueWithoutCommission;
        if (!hoteldata.tipIncludeProcessFee) {
          tipAmount = Number(
            (
              tipValue -
              STRIPE_COMMISION -
              (tipValue - STRIPE_COMMISION) *
              (hoteldata.paymentCommission / 100)
            ).toFixed(2)
          );
        }
        // making payment intent in stripe and made easy server
        axios
          .get(
            `${env_var.API_URL
            }payments/token-stripe/?amount=${tipValue}&tip_amount=${tipAmount}&commission=${hoteldata.paymentCommission
            }&hotel=${accCode}&room=${room_number ? room_number : ""
            }&staff=${psCode}&tip_type=${psType}&group_staff_name=${staffNameForGroup ? staffNameForGroup : ""}`
          )
          .then((res) => {
            const persons = res.data;

            const paymentResult = stripe
              .confirmCardPayment(
                persons.token.client_secret,
                {
                  payment_method: e.paymentMethod.id,
                },
                { handleActions: false }
              )
              .then((paymentResult) => {
                const dataz = paymentResult.paymentIntent;

                if (dataz && dataz.status === "succeeded") {

                  console.log(dataz.id, "dataz");
                  e.complete("success");
                  setPaymentLoading(false);
                  setPstage(0);
                  setisCard(false);
                  console.log(paymentResult);
                  // REDIRECT TO SUCCESS PAGE
                  navigate(ROUTE_TIP_THANKYOU, {
                    state: {
                      intent_id: dataz.id,
                      design_type: designtype,
                      theme_color: editBgColor,
                      theme_text_color: editTextColor,
                      message_title: messageTitle,
                      message_description: messageDescription,
                      review_section: reviewSection,

                      amount: tipValueWithoutCommission,
                      hotel_logo: hotelLogo,
                      hotelBg_image: tipBackgroungImg,
                      hotel_data: hoteldata,
                      tipValue: tipValue,
                      accCode: accCode,
                      psCode: psCode,
                      psType: psType,
                    },
                  });
                } else {
                  //fail handling
                  e.complete("fail");
                }
              });
          });
      });
    }, [stripe, elements, tipValue]);

    return (
      <>
        <div
          className={`checkbox-wrapper ${designtype == 2 && "border-top mt-3 pt-3"
            }`}
        >
          {/* style={ privacyerr ? { color:'red'} : {color:'black'}} */}
          <input
            name="chk_terms"
            id="chk_terms"
            type="checkbox"
            style={{ height: 15, width: 15 }}
            checked={isPrivacyChecked}
            onChange={() => {
              setPrivacyerr(isPrivacyChecked);
              setPrivacyChecked(!isPrivacyChecked);
            }}
            className="mt-5px red-text"
          />
          <label
            htmlFor="chk_terms"
            className={
              "tip-terms-checkbox padding-bottom-5px mt-10px ms-2" +
              (privacyerr ? " red-text" : "")
            }
          >
            <span className={privacyerr ? " red-text" : ""}>
              {t("tipPage.iAgreeToThe")}{" "}
              <Link
                to="#"
                onClick={() => showTerms()}
                className={`${designtype == 1
                  ? "text-normal"
                  : designtype == 3
                    ? "text-blue text-normal"
                    : ""
                  }`}
              >
                <span className={privacyerr ? " red-text" : ""}>
                  {" "}
                  {t("tipPage.termsAndConditions")}{" "}
                </span>{" "}
              </Link>
            </span>
          </label>
        </div>

        {paymentRequest != null ? (
          pstage == 0 ? (
            <button
              onClick={() => {
                if (
                  hoteldata.roomLabelMandatory &&
                  room_number.trim().length == 0
                ) {
                  return false;
                }
                if (isPrivacyChecked) {
                  if (hoteldata.tipIncludeProcessFee) {
                    handleClickOpen();
                  } else {
                    setPstage(1);
                  }
                } else {
                  setPrivacyerr(true);
                }
              }}
              disabled={tipzero && isCustomTip ? true : false}
              className="btntipContinue mt-4"
              style={{
                backgroundColor: editBgColor,
                color: designtype === 3 ? editTextColor : "white",
                fontStyle: designtype === 2 ? 'normal' : 'italic'
              }}
            >
              {t("tipPage.continue")}
            </button>
          ) : null
        ) : pstage == 0 && !isCard ? (
          <button
            onClick={() => {
              setisCard(true);
              if (hoteldata.tipIncludeProcessFee && amountChanged) {
                handleClickOpen();
              }
            }}
            type="button"
            className="btntipContinue mt-4"
            style={{
              backgroundColor: editBgColor,
              color: designtype === 3 ? editTextColor : "white",
              fontStyle: designtype === 2 ? 'normal' : 'italic'
            }}
          >
            {t("tipPage.payWithCard")}
          </button>
        ) : null}

        {/* {
          paymentRequest == null && pstage == 0 && !isCard ?
            <button onClick={() => setisCard(true)} type="submit" className="btnbg padding-tb w-90perc mb-1 btn-text margin-top8">
              Pay with Card
            </button> : null
        } */}

        {pstage == 1 && !isCard && isPrivacyChecked == true ? (
          <>
            {paymentRequest && (
              <>
                <PaymentRequestButtonElement options={{ paymentRequest }} />
                <p className="text-center helvfont mt-3">{t("tipPage.or")}</p>
              </>
            )}
            <button
              onClick={() => {
                setisCard(true);
                if (hoteldata.tipIncludeProcessFee && amountChanged) {
                  handleClickOpen();
                }
              }}
              className="btntipContinue mt-4"
              style={{
                backgroundColor: editBgColor,
                color: designtype === 3 ? editTextColor : "white",
                fontStyle: designtype === 2 ? 'normal' : 'italic'
              }}
            >
              {t("tipPage.payWithCard")}
            </button>
          </>
        ) : null}
      </>
    );
  }
};
