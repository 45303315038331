import { Box, Modal } from '@mui/material';
import React,{useState,useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import logo from "../../assets/images/tovalogo1.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { isAuthenticated, resetUserPasswordNoAuth } from '../../services/authenticationService'; // isAccountAuthenticated
import { authenticateAccountUser, selectAccountAuthentication, setLoginStatus } from '../LoginPage/accountAuthenticationSlice';
import { ROUTE_ACCOUNTS_LIST, ROUTE_ACCOUNT_SIGNUP, ROUTE_STAFF_LIST } from "../../config/env";
import { getAccountUserAccountIds, removeTokens } from '../../services/localStorage';
import { setNotification } from '../../services/apiService';
import { CloseRounded } from '@mui/icons-material';
import { Textfield } from '../../components/TextField';
import { ButtonModal } from '../../components/ButtonGroups/ButtonModal';

export const AccountsLoginPage = () => {
  const { t } = useTranslation();
  const navigate  = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');  
  const [showPwd, setShowPwd] = useState(false);
  // const [passwordShown, setPasswordShown] = useState(false);

  const [resetpwdUsername, setResetpwdUsername] = useState('');
  const [usernameError, setUsernameError] = useState('required');
  const [modalHeader, setModalHeader] = useState('');

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false); 

  // if (isAuthenticated()) {
  //   let hotelIds = getAccountUserAccountIds(); 
  //   let arrHotelIds = hotelIds?.split(','); 
  //   if(arrHotelIds && arrHotelIds.length > 1) {
  //     navigate(ROUTE_ACCOUNTS_LIST);
  //   }
  //   else {
  //     navigate(ROUTE_ACCOUNTS_LIST); 
  //   }
  // }

  const { loginStatus } = useAppSelector(selectAccountAuthentication); // isProcessingRequest
  const dispatch = useAppDispatch();

  useEffect(() => {
    if(window.location.hostname === 'pay.usetova.com'){
      navigate('/invalid/not-found');
    }
  });

  useEffect(() => {
    if(loginStatus === 'ERROR'){
      setNotification(
        t("messages.appName"),  
        t("common.msgInvalidCredentials"),
        "failed",
        "getFormDataHeader"
      )
      dispatch(setLoginStatus('')); 
    }
    if(loginStatus === 'SUCCESS'){
      let hotelIds = getAccountUserAccountIds(); 
    let arrHotelIds = hotelIds?.split(','); 
    if(arrHotelIds && arrHotelIds.length > 1) {
      navigate(ROUTE_ACCOUNTS_LIST);
    }
    else {
      navigate(ROUTE_ACCOUNTS_LIST); 
    }
    }
  },[loginStatus]); 


  const handleChange = (e: any) => {
    removeTokens();
    e.preventDefault();
    dispatch(authenticateAccountUser(username, password));
  };

  const onSetResetpwdUsername = (emailValue: string) => {
    setResetpwdUsername(emailValue);
    if (emailValue && emailValue.trim().length > 0) {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (resetpwdUsername.match(validRegex)) {
        setUsernameError("");
      } else {
        setUsernameError("required");
      }
    } else {
      setUsernameError("required");
    }
  };

  const forgotPasswordButton = () => {
    setModalHeader("Forgot Password");
    setResetpwdUsername("");
    handleOpen();
  };
  
  const onSaveSubmitted = (e) => {    
    e.preventDefault();
    if (resetpwdUsername && resetpwdUsername.trim().length > 0) {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (resetpwdUsername.match(validRegex)) { 
        setUsernameError(''); 
        dispatch(resetUserPasswordNoAuth(encodeURIComponent(resetpwdUsername))) 
        setOpen(false)
      }
    } else {
      setUsernameError("required");
      return false;
    }
  };

  const gotoSignUp = () => {
    navigate(ROUTE_ACCOUNT_SIGNUP); 
  }

  const style = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "13px",
    boxShadow: 24,
  };
  return (
   <>   
    <div className='center h-90vh' >
        <form className='loginbox' autoComplete="off" onSubmit={handleChange}>
            <img src={logo} alt="" />
            <h1>{t("accountLoginPage.welcome")}</h1>
            <h2 className='mb-22px'>{t("accountLoginPage.subheading")}</h2>
            <input type="text" className='w-100' required value={username} onChange={(event) => setUsername(event.target.value)}  placeholder={`${t("accountLoginPage.usernamePlaceHolder")}`}/>

            {/* <input type="password" className='w-100' required onChange={(event) => setPassword(event.target.value)} placeholder={`${t("accountLoginPage.passwordPlaceHolder")}`}/> */}

            
            <div className="relative">
                    <input
                      type={showPwd ? "text" : "password"}
                      // className="w-100 mt-11px"
                      className='w-100'
                      placeholder={`${t("accountLoginPage.passwordPlaceHolder")}`}
                      onChange={(event) => setPassword(event.target.value)}
                      value={password}
                      required
                      maxLength={150}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "20px",
                        right: "10px",
                      }}
                      onClick={(e) => setShowPwd(!showPwd)}
                    >
                      {showPwd ? (
                        <VisibilityIcon className="text-grey" />
                      ) : (
                        <VisibilityOffIcon className="text-grey" />
                      )}
                    </div>
                  </div>


            <button className='rounded-2 p-12px text-white border-none mt-18px' style={{backgroundColor:"#3871E0"}}>{t("accountLoginPage.btnLogin")}</button>
            <a href="#" onClick={(event) => forgotPasswordButton()}>{t("accountLoginPage.forgotPassword")}</a>
            <div className='relative'>
              <div className='or-line'>&nbsp;</div>
              <div className='or'>{t("accountLoginPage.or")}</div>
            </div>
            <button type="button" className='rounded-2 p-12px text-blue font-500 bg-transparent' style={{border:" 1px solid #3871E0"}} onClick={(event) => gotoSignUp()} >{t("accountLoginPage.btnSignUp")}</button>

        </form>
    </div>
    <Modal
       open={open}
       onClose={handleClose}
       aria-labelledby={modalHeader}
       aria-describedby=""
      >
        <Box sx={style}>
          <form
            className="w-100  manage-modal"
            onSubmit={(e) => onSaveSubmitted(e)}
          >
            <div className="space-between center p-3">
              <div>&nbsp;</div>
              <div className='forgotpwd-main-header'> {modalHeader}</div>
              <span onClick={handleClose}>
                <CloseRounded className="pointer" />
              </span>
            </div>
            <div className="center forgotpwd-sub-header">{t("accountLoginPage.forgotPwdHeader1")}
            </div>
            <div className="center forgotpwd-sub-header p-3">{t("accountLoginPage.forgotPwdHeader2",{projectName:t("projectName")})}
            </div>
            
            <div className="bg-light">
              <div className="px-3 mt-20px bg-white center div-center">
                <div className=" space-between w-80perc center div-center">
                  <input type="text" required maxLength={100} name={"resetpwdUsername"} className={
                      usernameError && usernameError.trim().length > 0
                        ? " input-error w-100 "
                        : " w-100 "
                    } placeholder={`${t("accountLoginPage.forgotPwdPlaceHolder")}`} value={resetpwdUsername} onChange={(e) => onSetResetpwdUsername(e.target.value)} />
                </div>   
                             
              </div>
              <div className="px-3 space-between center div-center">
                <button type="submit" className='w-80perc rounded-2 p-12px text-white border-none mt-18px w-100' style={{backgroundColor:"#3871E0"}}>{t("accountLoginPage.forgotPwdBtnText")}</button> 
                </div> 
            </div>

            <div className="space-between center p-2">
              <div> &nbsp; </div>
            </div>

            
          </form>
        </Box>
      </Modal>
   </>
  )
}

