import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Modal } from "@mui/material"; 
import { useAppDispatch, useAppSelector } from "../../helpers/hook";

import deleteIcon from '../../assets/images/qr-delete-icon.png'

import warning from "../../assets/images/warning.svg"; // yellowrm.svg
import imgBack from "../../assets/images/back.svg";

import { PAGENAME_QRCHECKOUT, USER_ROLE_ACCOUNT_MANAGER, USER_ROLE_ACCOUNT_USER } from "../../config/env";

import { setNotification } from '../../services/apiService';
import { getAdminUserRole, getFilterAccountId } from "../../services/localStorage";
import { fetchVPPricingOne, fetchVPPricingTwo, fetchVPPricingThree, fetchCartDetails, modifyCartItem, fetchCartCount, removeCartItem, fetchDocumentPreview } from "../../services/qrCodesServices";

import { selectQrcodesDataSlice, changeCartItemModifiedStatus, changeCartItemRemovedStatus } from "../QRCodes/QRCodesSlice"; 

import { ICartCheckoutData, IModifyCartItem, IVPItemPriceData, IvpPricingPayload, IRemoveCartItem, IVPDocumentPreview, IVPDocumentPreviewRespItem } from '../../models/qrCodeInterfaces';

import { SelectField } from "../../components/SelectField";
import { ButtonModal } from "../../components/ButtonGroups/ButtonModal";

import { DH_MEASURE_UNIT_INCH, DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD, DH_TEMPLATE_DESIGN_TYPE_POSTCARD, DH_TEMPLATE_DESIGN_TYPE_TABLETENT, ROUTE_QRCODES, ROUTE_QRC_SHIPPING, VP_PRICING_API_SET_COUNT } from "../../config/env_qrcodes";
import { QRCODE_CART_ITEM_MODIFIED, QRCODE_CART_ITEM_REMOVED } from '../../config/apiResponseCodes';

const QRCodesCheckOut = () => {
  const adminUserRole = getAdminUserRole();
  const pageName = PAGENAME_QRCHECKOUT;
  const accountId = getFilterAccountId();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { vpPricingDataOne, vpPricingDataTwo, vpPricingDataThree, cartData, cartItemModifiedStatus, cartItemRemovedStatus, documentPreviewRespItem } = useAppSelector(selectQrcodesDataSlice);

  const [stateCartData, setStateCartData] = useState<ICartCheckoutData[]>([]); 
  const [stateCartDocPreviewData, setStateCartDocPreviewData] = useState<IVPDocumentPreviewRespItem[]>([]); 
  const [docPreviewRespCount, setDocPreviewRespCount] = useState(0); 
  const [previewImage, setPreviewImage] = useState(""); 
  const [imagesLoaded, setImagesLoaded] = useState(false); 
  const [modifiedCartId, setModifiedCartId] = useState(0); 
  const [deletedCartId, setDeletedCartId] = useState(0); 
  const [totalQuantity, setTotalQuantity] = useState(0); 
  const [totalAmount, setTotalAmount] = useState(0); 
  const [shippingAmount, setShippingAmount] = useState(0); 
  const [taxAmount, setTaxAmount] = useState(0); 

  const [stateVPPricingDataPostCard, setStateVPPricingDataPostCard] = useState<IVPItemPriceData[]>([]); 
  const [stateVPPricingDataBusinessCard, setStateVPPricingDataBusinessCard] = useState<IVPItemPriceData[]>([]); 
  const [stateVPPricingDataTableTent, setStateVPPricingDataTableTent] = useState<IVPItemPriceData[]>([]); 

  const [loadCartData, setLoadCartData] = useState(0); 

  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [statusPopup, setStatusPopup] = useState(false);

  const [pricingAPILoadCount, setPricingAPILoadCount] = useState(0); 

  const style1 = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 460,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "13px",
    boxShadow: 24,
  };

  useEffect(() => {
    // CALL PRICING FROM VISTA PRINT FOR EACH CARD TYPE  
    let pricingPayloadOne : IvpPricingPayload = { cartType: DH_TEMPLATE_DESIGN_TYPE_POSTCARD.toString()} 
    dispatch(fetchVPPricingOne(pricingPayloadOne)); 
  },[]); 

  useEffect(() => {
    // PRICING FOR POST CARD - STORE IN STATE VARIABLE 
    if(vpPricingDataOne && vpPricingDataOne.length > 0) {
      setPricingAPILoadCount((pricingAPILoadCount+1)); 
      setStateVPPricingDataPostCard(vpPricingDataOne); 
      let pricingPayloadTwo : IvpPricingPayload = { cartType: DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD.toString()} 
      dispatch(fetchVPPricingTwo(pricingPayloadTwo)); 
    }
    else {
      setStateVPPricingDataPostCard([]); 
    }
     
  },[vpPricingDataOne]); 

  useEffect(() => {
    // PRICING FOR BUSINESS CARD - STORE IN STATE VARIABLE 
    if(vpPricingDataTwo && vpPricingDataTwo.length > 0) {
      setPricingAPILoadCount((pricingAPILoadCount+1)); 
      setStateVPPricingDataBusinessCard(vpPricingDataTwo); 
      let pricingPayloadThree : IvpPricingPayload = { cartType: DH_TEMPLATE_DESIGN_TYPE_TABLETENT.toString()} 
      dispatch(fetchVPPricingThree(pricingPayloadThree)); 
    }
    else {
      setStateVPPricingDataBusinessCard([]); 
    }
  },[vpPricingDataTwo]); 

  useEffect(() => {
    // PRICING FOR TABLE TENT - STORE IN STATE VARIABLE 
    if(vpPricingDataThree && vpPricingDataThree.length > 0) {
      setPricingAPILoadCount((pricingAPILoadCount+1)); 
      setStateVPPricingDataTableTent(vpPricingDataThree); 
    }
    else {
      setStateVPPricingDataTableTent([]); 
    }
  },[vpPricingDataThree]); 

  useEffect(() => {
    // AFTER LOADING ALL PRICING RESPONSES, CALL CART DETAILS 
    if(pricingAPILoadCount >= VP_PRICING_API_SET_COUNT) {
      dispatch(fetchCartDetails({ accountId: accountId ? accountId.toString() : "" }));
    }
  },[pricingAPILoadCount]);

  useEffect(() => {
    let arrNew:ICartCheckoutData[] = []; 
    if(cartData && cartData.length > 0) {
      cartData.map(cartItem => {
        let arrPricing = Number(cartItem.cardType)==Number(DH_TEMPLATE_DESIGN_TYPE_POSTCARD) ? stateVPPricingDataPostCard : 
  Number(cartItem.cardType)==Number(DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD) ? stateVPPricingDataBusinessCard : 
  Number(cartItem.cardType)==Number(DH_TEMPLATE_DESIGN_TYPE_TABLETENT) ? stateVPPricingDataTableTent : []; 
        let priceValue = 0; 
        let selValue = ""; 
        arrPricing.map((pItem, pIndex) => {
          if(pItem.sku==cartItem.sku && Number(pItem.quantity)==Number(cartItem.quantity)) {
            priceValue = pItem.price; 
            selValue = pIndex.toString(); 
          }
        }); 
        arrNew.push({...cartItem, price: priceValue, selectedValue:selValue, locked:true}); 
      });
      setStateCartData(arrNew); 
    }
    else {
      setStateCartData(arrNew); 
    }
  },[loadCartData]); 

  const setAccProjQtyPrice = (e:any, projectId:string, templateType:string) => {
    let arrNew:ICartCheckoutData[] = []; 
    let selValue = e.target.value; 
    let sku="", quantity=0, price=0, cartId=0; 
    if(selValue=="") {
      setNotification(
        t("messages.appName"),  
        t("QRCodesCheckOut.msgSelectQuantity"),
        "failed",
        "templateTypeMessage"
      ); 
    } 
    else {
      let arrPricing = Number(templateType)==Number(DH_TEMPLATE_DESIGN_TYPE_POSTCARD) ? stateVPPricingDataPostCard : 
      Number(templateType)==Number(DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD) ? stateVPPricingDataBusinessCard : 
      Number(templateType)==Number(DH_TEMPLATE_DESIGN_TYPE_TABLETENT) ? stateVPPricingDataTableTent : []; 
      sku       = arrPricing[selValue].sku; 
      quantity  = arrPricing[selValue].quantity; 
      price     = arrPricing[selValue].price; 
      // groupType = arrPricing[selValue].groupType; 
      // prItem.productName.replace(/^\"(.+)\"$/,"$1").replace('""','"').replace('""','"')
      stateCartData.map(item => {
        if(item.projectId==projectId) {
          cartId = item.id; 
          arrNew.push({...item,  sku: sku, quantity: quantity, price: price, selectedValue:selValue, locked:false }); 
        }
        else {
          arrNew.push(item); 
        }
      });
      setStateCartData(arrNew); 
      if(cartId && cartId > 0) {
        let cartObj : IModifyCartItem = {
          cartId: Number(cartId),  
          sku: sku, 
          quantity: Number(quantity)
        }
        dispatch(modifyCartItem(cartObj));
        setModifiedCartId(Number(cartId)); 
      }
    }
  } 

  const loadImagePreviews = () => {
    if(stateCartData && stateCartData.length > 0) {
      stateCartData.map(item => {
        if((!item.previewUrl || item.previewUrl.length==0) && item.documentRefUrl && item.documentRefUrl.length > 0 && item.sku && item.sku.length > 0) {
          let preloadData : IVPDocumentPreview = {
            cartId: item.id, 
            documentRefUrl: item.documentRefUrl, 
            sku: item.sku
          }
          dispatch(fetchDocumentPreview(preloadData)); 
        }
      }); 
    }
  }

  useEffect(() => {
    setDocPreviewRespCount((docPreviewRespCount+1)); 
    let cartId = documentPreviewRespItem.cartId;
    let documentUrl = documentPreviewRespItem.documentUrl;
    let arrNew:IVPDocumentPreviewRespItem[] = []; 
    let arrTmp = stateCartDocPreviewData.filter(item => Number(item.cartId)==Number(cartId)); 
    if(!arrTmp || arrTmp.length==0) {
      arrNew.push(documentPreviewRespItem); 
      setStateCartDocPreviewData(arrNew); 
    } 

    if(stateCartData && stateCartData.length > 0) {
      let arrCartNew:ICartCheckoutData[] = []; 
      // SET VARIABLES FOR FOOTER 
      stateCartData.map(item => { 
        if(Number(cartId) == Number(item.id)) {
          arrCartNew.push({...item, previewUrl: documentUrl}); 
        }
        else{
          arrCartNew.push(item); 
        }
      }); 
      setStateCartData(arrCartNew); 
    }
  },[documentPreviewRespItem]);

  useEffect(() => {
    let totQuantity = 0, totAmount = 0;
    if(stateCartData && stateCartData.length > 0) {
      // SET VARIABLES FOR FOOTER 
      stateCartData.map(item => { 
        if(item.id && item.id > 0) {
          totQuantity += item.quantity ? item.quantity : 0;
          totAmount   += item.price ? item.price : 0;  
        }
      }); 
      setTotalQuantity(totQuantity); 
      setTotalAmount(totAmount); 
      loadImagePreviews(); 
    }
    else {
      setTotalQuantity(totQuantity); 
      setTotalAmount(totAmount); 
    }
  },[stateCartData]);

  useEffect(() => {
      setLoadCartData(loadCartData+2); 
  },[cartData]); 

  useEffect (() => {
    if(cartItemModifiedStatus && cartItemModifiedStatus==QRCODE_CART_ITEM_MODIFIED && modifiedCartId && modifiedCartId > 0) {
      setNotification(
        t("messages.appName"),  
        t("QRCodesCheckOut.msgCartModificationSuccess"),
        "success",
        "templateTypeMessage"
      ); 
      dispatch(changeCartItemModifiedStatus('')); 
      setModifiedCartId(0); 
    }
    else if(cartItemModifiedStatus && cartItemModifiedStatus=='modifyCartItemError' && modifiedCartId && modifiedCartId > 0) {
      setNotification(
        t("messages.appName"),  
        t("QRCodesCheckOut.msgCartModificationFailed"),
        "failed",
        "templateTypeMessage"
      ); 
      dispatch(changeCartItemModifiedStatus('')); 
      setModifiedCartId(0); 
    }
  },[cartItemModifiedStatus]);

  // CART ITEMS NUMBER ONLY FOR ACCOUNT LOGINS
  const callCartCount = () => {
    if(Number(adminUserRole) === Number(USER_ROLE_ACCOUNT_MANAGER) || Number(adminUserRole) === Number(USER_ROLE_ACCOUNT_USER)) {
      dispatch(fetchCartCount({ accountId: "" })); // ACCOUNT ID NOT REQUIRED NOW
    }
  }

  useEffect (() => {
    if(cartItemRemovedStatus && cartItemRemovedStatus==QRCODE_CART_ITEM_REMOVED && deletedCartId && deletedCartId > 0) {
      setNotification(
        t("messages.appName"),  
        t("QRCodesCheckOut.msgCartItemRemovalSuccess"),
        "success",
        "templateTypeMessage"
      ); 
      dispatch(changeCartItemRemovedStatus('')); 
      setDeletedCartId(0); 
      dispatch(fetchCartDetails({ accountId: accountId ? accountId.toString() : "" }));
      callCartCount(); 
    }
    else if(cartItemRemovedStatus && cartItemRemovedStatus=='removeCartItemError' && deletedCartId && deletedCartId > 0) {
      setNotification(
        t("messages.appName"),  
        t("QRCodesCheckOut.msgCartItemRemovalFailed"),
        "failed",
        "templateTypeMessage"
      ); 
      dispatch(changeCartItemRemovedStatus('')); 
      setDeletedCartId(0); 
    }
  },[cartItemRemovedStatus]);

  useEffect (() => {
    if(stateCartData.length==docPreviewRespCount) {

    }
  },[stateCartDocPreviewData]);

  const getTemplateType = (templateType:string) => {
    switch(Number(templateType)) { 
      case DH_TEMPLATE_DESIGN_TYPE_POSTCARD : return t("qrcodesPage.postCard"); 
        break;
      case DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD : return t("qrcodesPage.businessCard"); 
        break;  
      case DH_TEMPLATE_DESIGN_TYPE_TABLETENT : return t("qrcodesPage.tableTent"); 
        break;  
      default: return templateType; 
        break; 
    }
  } 

  const onDeleteCartItem = (cartId:number) => { 
    setDeletedCartId(cartId); 
    setStatusPopup(true); 
  }

  const deleteCartItem = () => { 
    let remCartdId : IRemoveCartItem = {
      cartId : deletedCartId
    }
    dispatch(removeCartItem(remCartdId));
    setStatusPopup(false); 
  }

  return (
    <div className="box-padding select-none">
      <div className="box">
        <div className='qr-checkout-nav'>
          <div>
            <div className='w-100'>{t("QRCodesCheckOut.QRCodeCheckout")}</div>
            <div className="w-100 returnAcc pointer left-center mt-7px mb-7px" onClick={e => navigate(ROUTE_QRCODES, { state: { returnedFrom: pageName }})}>
              <img src={imgBack} alt="back" height={"16px"} className="me-1" />
              {t("QRCodesCheckOut.returntoQRCodes")}
            </div>
          </div>
        </div>
        
        <div className='space-between'>
          <div className='w-100'>
            {/* 
            ********************************************************************
            ******************** CARTLISTING TABLE HEADINGS ******************** 
            ********************************************************************
            */}
            <div className='qr-checkout-head'>
              <div className='w-100'>
                <div className='gap-12px text-14px-500-black'>
                  <div className='flex'>
                    <div className="" style={{ width: "110px" }}>
                    </div>
                    <div className='pl-18px pr-21px flex w-100 align-items-center'>
                      <div className='flex-col w-100 '>
                        <span>{t("QRCodesCheckOut.item")}</span>
                      </div>
                      <div className="w-100 center ">
                        <span>{t("QRCodesCheckOut.quantity")}</span>
                      </div>
                      {/* <div className='w-100  flex-end pr-21px '>{t("QRCodesCheckOut.indivPrice")}</div> */}
                      <div className='w-100  flex-end pr-21px '>{t("QRCodesCheckOut.price")}</div>
                      <div style={{ width: "30px", minWidth: "30px"}}>&nbsp;</div>
                      <div style={{ width: "35px", minWidth: "35px"}}>&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 
            *******************************************************
            ******************** CART DATA LISTING **************** 
            *******************************************************
            */}
            <div className='px-4 pt-17px flex-col gap-12px'>
              {loadCartData > 0 && (!stateCartData || stateCartData.length == 0) ? <div className='space-between w-100'>
                <div>&nbsp;</div> 
                <div>{t("QRCodesCheckOut.emptyCart")}</div> 
                <div>&nbsp;</div> 
                </div> : <></>}
              {stateCartData && stateCartData.length > 0 && <>
                {stateCartData.map((item, index) => {
                  return(
                    <> 
                    <div className='selected-qr-card'>
                      <div className="selected-qr-img">
                        {item.previewUrl && item.previewUrl.trim().length > 1 ? <img src={item.previewUrl} alt="thump" className="qr-template-img" /> : <div>&nbsp;</div>}
                      </div>
                      <div className='pl-18px pr-21px flex w-100 align-items-center'>
                        <div className='flex-col w-100 '>
                          <span className='text-14px-600-black pb-12px'>{getTemplateType(item.cardType.toString())}</span>
                          <span className='text-12px-600-grey'>{item.name}</span>
                        </div>
                        <div className="w-100 center ">
                        <div className=''>
                          <SelectField
                            type=""
                            name={"sel_qty_"+index.toString()}
                            id={"sel_qty_"+index.toString()}
                            placeHolder={t("qrcodesPage.selectQuantity")}
                            className={"pl-10px ml-10px max-width-135px"}
                            value={item.selectedValue} 
                            onChange={(e) => setAccProjQtyPrice(e, item.projectId, item.cardType)}
                            options={
                              <>
                              <option value={""}>{t("qrcodesPage.selectQuantity")}</option>

                              {item.cardType && Number(item.cardType)==Number(DH_TEMPLATE_DESIGN_TYPE_POSTCARD) && stateVPPricingDataPostCard && stateVPPricingDataPostCard.map((prItem, prIndex) => {
                                return <option value={prIndex.toString()}>{prItem.quantity.toString() + ' - ' +prItem.productName.replace(/^\"(.+)\"$/,"$1").replace('""','"').replace('""','"')} &nbsp; &nbsp; </option>
                              })}

                              {item.cardType && Number(item.cardType)==Number(DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD) && stateVPPricingDataBusinessCard && stateVPPricingDataBusinessCard.map((prItem, prIndex) => {
                                return <option value={prIndex.toString()}>{prItem.quantity.toString() + ' - ' +prItem.productName.replace(/^\"(.+)\"$/,"$1").replace('""','"').replace('""','"')} &nbsp; &nbsp; </option>
                              })}

                              {item.cardType && Number(item.cardType)==Number(DH_TEMPLATE_DESIGN_TYPE_TABLETENT) && stateVPPricingDataTableTent && stateVPPricingDataTableTent.map((prItem, prIndex) => {
                                return <option value={prIndex.toString()}>{prItem.quantity.toString() + ' - ' +prItem.productName.replace(/^\"(.+)\"$/,"$1").replace('""','"').replace('""','"')} &nbsp; &nbsp; </option>
                              })}
                              
                              </>
                            }
                          />
                        </div>
                      </div>
                      <div className='w-100 flex-end pr-21px'>{t("currency")+(item.price?.toFixed(2))?.toString()}</div>
                      <div style={{ width: "35px", minWidth: "35px"}}>&nbsp;</div>
                      <div className='flex-end'>
                        <img src={deleteIcon} alt="" height={32} width={32} className='pointer' onClick={e => onDeleteCartItem(item.id)} />
                      </div>
                    </div>
                  </div>
                  </>
                  )
                } )}
                </>}

                <div>&nbsp;</div>

            </div>
          </div>
           
          <div className='px-4 pt-21px max-width-292px w-100 border-left min-height-66vh'>
            <div className=' flex-col'>
                <h1 className='text-22px-600-black pb-33px'>{t("QRCodesCheckOut.orderSummary")}</h1>
                <div className='text-14px-500-black space-between border-bottom pb-3'>
                    <span>{t("QRCodesCheckOut.total")}</span><span>{t("currency")+(totalAmount.toFixed(2)).toString()}</span>
                </div>
                <div className='text-14px-500-black space-between border-bottom py-3'>
                    <span>{t("QRCodesCheckOut.shipping")}</span><span>{t("currency")+(shippingAmount.toFixed(2)).toString()}</span>
                </div>
                {taxAmount > 0 && <div className='text-14px-500-black space-between border-bottom py-3'>
                    <span>{t("QRCodesCheckOut.tax")}</span><span>{t("currency")+(taxAmount.toFixed(2)).toString()}</span>
                </div>}
                <div className='text-16px-600-black space-between  py-3'>
                    <span>{t("QRCodesCheckOut.subTotal")}</span><span>{t("currency")+((totalAmount+shippingAmount+taxAmount).toFixed(2)).toString()}</span>
                </div>

                {totalAmount && totalAmount > 0 ? <button className='mt-21px btn-blueWhite-h36-r6-f16-600' onClick={() => {
                  navigate(ROUTE_QRC_SHIPPING);
                }} >
                    {t("QRCodesCheckOut.continueToShipping")} 
                </button> : <></>}

                <button onClick={() => navigate(ROUTE_QRCODES, { state: { returnedFrom: pageName }})} className='mt-19px btn-whiteBlack-h36-r6-f14-500'>
                    {t("QRCodesCheckOut.continueShopping")}
                </button> 
            </div>
          </div>
        </div>

        <div> </div>
      </div>

      <Modal
        open={statusPopup}
        onClose={() => setStatusPopup(false)}
        aria-labelledby={"confirmDelete"}
        aria-describedby=""
      >
        <Box sx={style1}>
          <div className="idproof-modal div-center text-center relative">
            <div className="page-heading">
              <img src={warning} alt="" />
            </div>
            <div className="idproof-flex-center div-center">
              <div>
                {t("QRCodesCheckOut.confirmCartItemDelete")}
              </div>
            </div>

            <div className="idproof-margin"></div>
            <div className="flex w-100">
              <ButtonModal
                type="cancel"
                onClick={(e) => setStatusPopup(false)}
                buttonLabel={t("common.cancel")}
              />
              <ButtonModal
                type={''}
                onClick={(e) => deleteCartItem()}
                buttonLabel={t("common.yes")}
                className=" ml-15px"
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default QRCodesCheckOut