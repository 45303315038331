import React from 'react';
import dayjs from 'dayjs';

interface IProps {
  value: string;
  format: string | undefined;
}

const DateTimeTableField: React.FC<IProps> = ({ value, format }) => {
  const dateTime = dayjs(value).format(format);

  return (
    <>
      <span>{dateTime !== 'Invalid Date' && dateTime}</span>
    </>
  );
};

export default DateTimeTableField;
