import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import activesort from "./../../assets/images/activesort.svg";
import defaultsort from "./../../assets/images/defaultsort.svg";
import MoneyField from "../MoneyField";
import DateTimeTableField from "../DateTimeTableField";
import StatusField from "../StatusField";
import UrlTableField from "../UrlTableField";
import {
  USER_ROLE_ADMIN_SUPERADMIN,
  USER_ROLE_ADMIN_ADMIN,
  USER_ROLE_ACCOUNT_MANAGER,
  USER_ROLE_ACCOUNT_USER,
  PAYOUT_TOVA,
  PAYOUT_ACCOUNT,
  PAYOUT_STAFF,
  PAY_STATUS_CODE_PENDING,
  PAY_STATUS_CODE_SUCCESS,
  PAY_STATUS_CODE_FAILED,
  PAY_STATUS_CODE_READY,
  PAY_STATUS_CODE_ONHOLD,
  STAFF_STATUS_ACTIVE,
  STAFF_STATUS_INACTIVE,
  STAFF_STATUS_INVITE,
  PAGENAME_ACCOUNTUSERS,
  PAGENAME_ADMINUSERS,
  PAGENAME_STAFF,
  PAGENAME_PAYOUTS,
  ARR_PAYOUT_FREQUENCIES,
} from "../../config/env";
import {
  checkActionPermission,
  PERM_EDIT_ACCOUNT_USER,
  PERM_EDIT_ADMIN_USER,
  PERM_PAYOUTS_MANAGE_EDIT,
  PERM_PAYOUTS_RECIEPT_VIEW,
  PERM_STAFF_DELETE,
  PERM_STAFF_EDIT,
  PERM_TIPS_REFUND,
} from "../../config/permissions";
import { showDeleteActivateOption } from "../../config/common_functions";

import { getAdminUserRole, getAdminUserId } from "../../services/localStorage";
import { setNotification } from "../../services/apiService";
import { ButtonInLine } from "../ButtonGroups/ButtonInLine";

interface IProps {
  titles: any[];
  data: any[];
  sortedFieldName?: any;
  sortedOrder?: string;
  handleSort?: (sortDetail: any) => void;
  noDataTableClass?: any;
  onClickTableData?: (actionType: string, data: {}) => void;
  onClickTableOption?: (
    actionType: string,
    data1: string,
    data2: string,
    data3: string,
    data4: string,
    data5: string
  ) => void;
  onResendEmail?: (username: string) => void;
  onClickView?: (actionType: string, data1: string, accName: string) => void;
  parentPage?: string;
  currentPage?: number;
  numRowsPerPage?: number;

}

const Table: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const adminUserRole = getAdminUserRole();
  const adminUserId = getAdminUserId();
  const [sortField, setSortField] = useState(
    props?.sortedFieldName ? props.sortedFieldName : ""
  );
  const [sortOrder, setSortOrder] = useState(
    props?.sortedOrder ? props.sortedOrder : ""
  );

  const [showFullHotels, setshowFullHotels] = useState([]);

  const handleSortData = (name: string): void => {
    props.handleSort?.(name);
  };

  const onResendEmail = (id: any): void => {
    props.onResendEmail?.(id);
  };

  const handleSort = (e: any, title: any) => {
    if (sortField === title) {
      let so = sortOrder === "-" ? "" : "-";
      setSortOrder(so);
      handleSortData(so + sortField);
    } else {
      setSortField(title);
      setSortOrder("");
      handleSortData(title);
    }
  };

  const getPayoutString = (payout: any) => {
    return payout == PAYOUT_TOVA
      ? t("payouts.tova")
      : payout == PAYOUT_ACCOUNT
        ? t("payouts.account")
        : payout == PAYOUT_STAFF
          ? t("payouts.staff")
          : "";
  };

  const getPayoutFequencyById = (id: number) => {
    let tmpArr = ARR_PAYOUT_FREQUENCIES.find((item) => item.id == id);
    if (tmpArr?.name) {
      return tmpArr.name;
    } else {
      return "";
    }
  }

  const dummyFunction = (e: any) => {
    return false;
  };

  const tableHeaders = (): any => {
    return (
      <thead>
        <tr>
          {props.titles.map((title, index) => (
            <th
              key={index}
              className={
                (title.display_title == "Active" ||
                  title.display_title == "Action"
                  ? "min-width-80 "
                  : "") + ` text-center  ${title.sortBy ? " pointer " : " "}`
              }
              onClick={(e) =>
                title.sortBy ? handleSort(e, title.sortTitle) : dummyFunction(e)
              }
            >
              <div
                className={
                  title.type === "money" || title.type === "number"
                    ? " div-right "
                    : title.type === "status" ||
                      title.type === "serial" ||
                      title.type === "textCenter" ||
                      title.type === "dateCenter" ||
                      title.type === "transactionId-btn"
                      ? " text-center "
                      : (sortField.length > 0 && sortField === title.sortTitle) ||
                        (props.sortedFieldName.length > 0 &&
                          props.sortedFieldName === title.sortTitle)
                        ? " text-blue left-center "
                        : " left-center"
                }
              >
                <div className="relative ">
                  {title.display_title}
                  {title.sortBy &&
                    (sortField === title.sortTitle ||
                      props.sortedFieldName === title.sortTitle ? (
                      <>
                        <img
                          src={activesort}
                          alt="sort"
                          className="ml-7px mb-1"
                        />
                      </>
                    ) : (
                      <>
                        <img
                          src={defaultsort}
                          alt="sort"
                          className="ml-7px mb-1"
                        />
                      </>
                    ))}
                </div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  const onClickData = (actionType: string, data: {}): void => {
    console.log("actionType: ", actionType);
    console.log(data);

    props.onClickTableData?.(actionType, data);
  };

  const onClickView = (
    actionType: string,
    data1: string,
    accName: string
  ): void => {
    if (actionType == "accountsListStaffView") {
      props.onClickView?.(actionType, data1, accName);
    } else {
      console.log("onClickView: ", actionType);
    }
  };

  const onClickActionMenu = (
    actionType: string,
    data1: string,
    data2: string,
    data3: string,
    data4: string,
    data5: string
  ): void => {
    props.onClickTableOption?.(actionType, data1, data2, data3, data4, data5);
  };


  const tableBody = (): any => {

    return (
      <>
        <tbody>
          {(!props.data || (props.data && props.data.length === 0)) && (
            <>
              <div
                className={
                  props?.noDataTableClass
                    ? props.noDataTableClass
                    : "mt-25px mb-5px no-data-row-table text-data"
                }
              >
                &nbsp; {t("common.noDataAvailable")} &nbsp;
              </div>
              <tr className="no-data-tr-table">
                <td colSpan={props.titles.length}>&nbsp;</td>
              </tr>
            </>
          )}
          {props.data &&
            props.data.map((data, indexOne) => (
              <tr key={indexOne}>
                {props.titles.map((title, indexTwo) => (
                  <td

                    className={
                      title.title === "mobile"
                        ? "tel-staff-container min-width-125px"
                        : title.title === "staffHotel"
                          ? "min-width-250px"
                          : title.title === "hotels"
                            ? "showHotels" + (data?.userHotels.length > 1 ? " pointer" : "")
                            : title.title === "actionMenu"
                              ? "p-more-option"
                              : ""
                    }
                    key={indexTwo}
                    align={
                      title.type === "url"
                        ? "center"
                        : title.title === "actionMenu"
                          ? "center"
                          : title.type === "status" ||
                            title.type === "textCenter" ||
                            title.type === "serial" ||
                            title.type === "dateCenter" ||
                            title.type === "transactionId-btn"
                            ? "center"
                            : title.type === "money"
                              ? "right"
                              : "left"
                    }
                  >
                    {/* // MANAGE BUTTON IN PAYOUTS LIST - CHECK PERMISSION ALSO */}
                    {title.type === "manageButton" && (
                      <>
                        {checkActionPermission(
                          adminUserRole,
                          PERM_PAYOUTS_MANAGE_EDIT
                        ) &&
                          (data["paymentStatus"] == PAY_STATUS_CODE_PENDING ||
                            data["paymentStatus"] == PAY_STATUS_CODE_READY) && (
                            <>
                              <button
                                className="manage-btn"
                                onClick={(e) => onClickData("manage", data)}
                              >
                                {t("paymentslist.buttonManageText")}
                              </button>
                            </>
                          )}

                        {checkActionPermission(
                          adminUserRole,
                          PERM_PAYOUTS_RECIEPT_VIEW
                        ) &&
                          (data["paymentStatus"] == PAY_STATUS_CODE_FAILED ||
                            data["paymentStatus"] == PAY_STATUS_CODE_SUCCESS ||
                            data["paymentStatus"] ==
                            PAY_STATUS_CODE_ONHOLD) && (
                            <>
                              <button
                                className="manage-btn"
                                onClick={(e) => onClickData("manage", data)}
                              >
                                {t("paymentslist.buttonReceiptText")}
                              </button>
                            </>
                          )}
                      </>
                    )}

                    {title.type === "transactionId-btn" &&
                      data["transactionId"] !== null &&
                      data["transactionId"] !== undefined &&
                      data["transactionId"] !== "" &&
                      data["transactionId"].length > 0 && (
                        <>
                          <ButtonInLine
                            className="copybtn max-width-85px"
                            onClick={() => {
                              navigator.clipboard.writeText(data.transactionId);

                              setNotification(
                                t("projectName"),
                                t("common.transIdCopied"),
                                "success",
                                "linkCopiedSettings"
                              );
                            }}
                            buttonLabel={t("group.copy")}
                            copyIcon={true}
                          />
                        </>
                      )}

                    {title.type === "status" && (
                      <StatusField
                        fieldName={title.title}
                        customClass={`${data[title.customClass]}`}
                        key={`status-${data.id}-${indexOne}-${indexTwo}`}
                        value={`${data[title.title]}`}
                        toolTipData={data[title.title + "Reason"]}
                        boldFont
                        status={
                          data[title.title] === true ||
                            data[title.title] === PAY_STATUS_CODE_SUCCESS ||
                            data[title.title] === "VERIFIED" ||
                            data[title.title] === "YES" ||
                            data[title.title] === "True"
                            ? 1
                            : data[title.title] === "	INVITED" ||
                              data[title.title] === "invited" ||
                              data[title.title] === PAY_STATUS_CODE_PENDING
                              ? -1
                              : data[title.title] === PAY_STATUS_CODE_READY
                                ? 2
                                : 0
                        }
                      />
                    )}

                    {title.type === "serial" && (
                      <>
                        {(indexOne + 1) + ((props.currentPage - 1) * props.numRowsPerPage)}
                      </>
                    )}

                    {title.type === "staffCountView" && (
                      <>
                        <span>
                          {data[title.title]}{" "}
                          {data[title.title] > 0 && (
                            <Link
                              to="#"
                              onClick={(e) =>
                                onClickView(
                                  "accountsListStaffView",
                                  data.id.toString(),
                                  data.hotelName
                                )
                              }
                            >
                              {t("accounts.dataStaffView")}
                            </Link>
                          )}
                        </span>
                      </>
                    )}

                    {(title.type === "text" || title.type === "textCenter") && (
                      <>
                        <span
                          key={`text-noclick-${data.id}-${indexOne}-${indexTwo}`}
                        >
                          {title.title === "mobile" ||
                            title.title === "mobileNumber" ? (
                            <div className="">
                              <PhoneInput
                                disabled={true}
                                buttonClass="hide"
                                containerClass="border-none tel-staff"
                                inputClass="border-none tel-staff fontsize-14"
                                value={data[title.title]}
                                // value={data[title.title].split("-del-")[0]}
                                placeholder={""}
                              />
                              {/* <div className="tel-input-staff">&nbsp;</div> */}
                            </div>
                          ) : title.title === "username" ? (
                            data[title.title].split("-del-")[0]
                          ) : title.title === "payout" ? (
                            getPayoutString(data[title.title])
                          ) : title.title === "payoutFrequency" ? (
                            getPayoutFequencyById(Number(data[title.title]))
                          ) : title.title === "fullName" &&
                            title.onClickable ? (
                            <>
                              {(props.parentPage == PAGENAME_ADMINUSERS &&
                                !checkActionPermission(
                                  adminUserRole,
                                  PERM_EDIT_ADMIN_USER
                                )) ||
                                (props.parentPage == PAGENAME_ACCOUNTUSERS &&
                                  !checkActionPermission(
                                    adminUserRole,
                                    PERM_EDIT_ACCOUNT_USER
                                  )) ? (
                                <span>{data[title.title]}</span>
                              ) : (
                                <span
                                  className="table-link pointer"
                                  onClick={(e) =>
                                    onClickData("userDetail", data)
                                  }
                                >
                                  {data[title.title]}
                                </span>
                              )}
                            </>
                          ) : title.title === "staffName" &&
                            title.onClickable &&
                            data.staffStatus != STAFF_STATUS_INVITE ? (
                            <span
                              className="table-link pointer"
                              onClick={(e) => onClickData("staffName", data)}
                            >
                              {data[title.title]}
                            </span>
                          ) : title.title === "hotelName" &&
                            title.onClickable ? (
                            <span
                              className="table-link pointer"
                              onClick={(e) =>
                                onClickData("accountDetails", data)
                              }
                            >
                              {data[title.title]}
                            </span>
                          ) : title.display_title.toLowerCase() === "role" ? (
                            <>
                              <div className="">
                                {data[title.title] ==
                                  USER_ROLE_ADMIN_SUPERADMIN.toString()
                                  ? t("userRoles.adminSuper")
                                  : data[title.title] ==
                                    USER_ROLE_ADMIN_ADMIN.toString()
                                    ? t("userRoles.adminAdmin")
                                    : data[title.title] ==
                                      USER_ROLE_ACCOUNT_MANAGER.toString()
                                      ? t("userRoles.accountManager")
                                      : data[title.title] ==
                                        USER_ROLE_ACCOUNT_USER.toString()
                                        ? t("userRoles.accountUser")
                                        : ""}
                              </div>
                            </>
                          ) : (title.title === "payee" && props.parentPage == PAGENAME_PAYOUTS) ? (
                            <>
                              <div>{data["payee"]} {(data["bankAccountName"] && data["bankAccountName"].trim().length > 0 && data["payee"] != data["bankAccountName"]) ? ' (' + data["bankAccountName"] + ') ' : ''}</div>
                            </>
                          ) : (
                            data[title.title]
                          )}

                          {(title.title === "username" || title.title === "email") && data.isPrimaryUser && (
                            <sup className="ml-5px ">
                              {t("adminUserList.primary")}
                            </sup>
                          )}
                        </span>

                        {(title.title === "username" || title.title === "email") &&
                          !data["emailVerified"] && (
                            <>
                              <div className="space-between mt-3">
                                <div>
                                  <sup className="text-danger">
                                    {" "}
                                    {t("adminUserList.notVerified")}
                                  </sup>
                                </div>
                                <div>
                                  <sup
                                    className="pointer link-p text-dark-blue"
                                    onClick={(e) =>
                                      onResendEmail(data[title.title])
                                    }
                                  >
                                    {t("adminUserList.resendEmail")}
                                  </sup>
                                </div>
                              </div>
                            </>
                          )}
                      </>
                    )}

                    {title.type === "money" && !title.clickable && (
                      <span className={`${title.customClass}`}>
                        <MoneyField
                          key={`money-${data.id}-${indexOne}-${indexTwo}`}
                          amountValue={Number(data[title.title]).toFixed(2)}
                          clickable={false}
                          amountCurrency={data[title.title + "Currency"]}
                        />
                      </span>
                    )}

                    {(title.type === "date" || title.type === "dateCenter") && (
                      <DateTimeTableField
                        key={`dt-${data.id}-${indexOne}-${indexTwo}`}
                        value={data[title.title]}
                        format={title.dateTimeFormat}
                      />
                    )}

                    {title.type === "url" && (
                      <UrlTableField
                        key={`url-${data.id}-${indexOne}-${indexTwo}`}
                        value={`${title.title}/${data.id}/`}
                        title={"view"}
                        storeId={
                          title.title == "staff" ||
                            title.title == "guest-payments" ||
                            title.title == "staff-payments"
                            ? data.id
                            : 0
                        }
                      />
                    )}


                    {title.type === "number" &&
                      title.title === "staffPayout" ? (
                      <>
                        <div className="div-right">{data[title.title]}</div>
                      </>
                    ) : (
                      ""
                    )}


                    {title.title === "hotels" &&
                      data?.userHotels &&
                      data.userHotels.length > 0 && (

                        showFullHotels === data.userHotels ? <div className="flex-col gap-1">
                          {data.userHotels
                            .filter((item: any) => item.id > 0)
                            .map((hitem: any, index: any) =>
                              // index == 0 ? (
                              <span className={hitem.isPrimaryHotel ? 'font-500' : ''}>{hitem.hotelName}</span>
                              // ) : (
                              //   <>, {hitem.hotelName}</>
                              // )
                            )}
                          {data?.userHotels.length > 1 && <span onClick={() => setshowFullHotels([])} className="font-500 text-blue pointer">show less</span>}
                        </div> : <div onClick={
                          (e) => {
                            if (title.title === "hotels") {
                              data?.userHotels &&
                                data.userHotels.length > 0 &&
                                data.userHotels &&
                                setshowFullHotels(data.userHotels)
                            }
                          }
                        }>
                          <span className={data.userHotels[0].isPrimaryHotel ? 'font-500' : ''}> {data.userHotels[0].hotelName}</span> {data.userHotels.length - 1 > 0 && <span className="font-500 ms-2 text-blue">+{data.userHotels.length - 1} {t("more")}</span>}


                          {data.userHotels.length > 1 &&
                            <div className="showHotelList multiselect">
                              {data.userHotels.filter((item: any) => item.id > 0)
                                .map((hitem: any, index: any) =>
                                  // index == 0 ? (
                                  <span className={hitem.isPrimaryHotel ? 'font-500' : ''}>{hitem.hotelName}</span>
                                  // ) : (
                                  //   <>, {hitem.hotelName}</>
                                  // )
                                )}
                            </div>
                          }
                        </div>
                      )
                    }

                    {title.title === "actionMenuRefund" &&
                      data.refundable &&
                      checkActionPermission(
                        adminUserRole,
                        PERM_TIPS_REFUND
                      ) && (
                        <>
                          <div className="menuDropdown">
                            <div className="">
                              <MoreVertIcon />
                            </div>
                            <div className="menuDropdown-content action-menu">
                              {title.type === "actionRefundTip" &&
                                data.refundable && (
                                  <>
                                    <div>
                                      <a
                                        className="pointer-cursor"
                                        onClick={() =>
                                          onClickActionMenu(
                                            "refundTip",
                                            data.id.toString(),
                                            "0",
                                            "",
                                            "",
                                            ""
                                          )
                                        }
                                      >
                                        <span className="text-danger">
                                          {t("tipslist.refundTip")}
                                        </span>
                                      </a>
                                    </div>
                                  </>
                                )}
                            </div>
                          </div>
                        </>
                      )}

                    {/* // USED TO SHOW THE GROUP STAFF NAME ENTERED IN THE TIP GIVING PAGE - UNDER THE GROUP NAME DATA OF TIP LIST PAGE  */}
                    {title.title=="poolName" && data?.groupStaffName && data.groupStaffName.length > 0 && (<div className="fontsize-reduce9em mt-3px">({data.groupStaffName})</div>)}

                    {/* // adminUserRole == USER_ROLE_ACCOUNT_USER.toString() */}

                    {/* ((props.parentPage == PAGENAME_ACCOUNTUSERS || props.parentPage == PAGENAME_STAFF) && 
                             (adminUserRole == USER_ROLE_ACCOUNT_USER.toString() ||
                            (props.parentPage == PAGENAME_ACCOUNTUSERS && Number(adminUserId) == Number(data.id))  
                            )
                              ? " menuDropdown hide "
                              : " menuDropdown "
                          } */}


                    {title.title === "actionMenu" && !data.isPrimaryUser && (
                      <>
                        <div
                          className={
                            (props.parentPage == PAGENAME_ACCOUNTUSERS || props.parentPage == PAGENAME_STAFF) &&
                              (adminUserRole == USER_ROLE_ACCOUNT_USER.toString() ||
                                (props.parentPage == PAGENAME_ACCOUNTUSERS && Number(adminUserId) == Number(data.id))
                              )
                              ? " menuDropdown hide "
                              : " menuDropdown "
                          }
                        >
                          <div className="">
                            {title.type === "actionDeleteAdminUsers" ? (
                              checkActionPermission(
                                adminUserRole,
                                PERM_EDIT_ADMIN_USER
                              ) &&
                                showDeleteActivateOption(
                                  Number(adminUserId),
                                  Number(adminUserRole),
                                  Number(data.id),
                                  Number(data.role)
                                ) ? (
                                <MoreVertIcon />
                              ) : (
                                <></>
                              )
                            ) : (
                              <MoreVertIcon />
                            )}
                          </div>
                          <div className="menuDropdown-content action-menu">
                            {title.type === "accountEdit" && (
                              <>
                                <div>
                                  <a
                                    className="pointer-cursor"
                                    onClick={(e) =>
                                      onClickData("accountDetails", data)
                                    }
                                  >
                                    <span className="">{t("common.edit")}</span>
                                  </a>
                                </div>
                              </>
                            )}

                            {title.type === "actionDeleteStaff" && (
                              <>
                                {checkActionPermission(
                                  adminUserRole,
                                  PERM_STAFF_EDIT
                                ) &&
                                  (data.staffStatus == STAFF_STATUS_ACTIVE ||
                                    data.staffStatus ==
                                    STAFF_STATUS_INACTIVE) && (
                                    <div>
                                      <a
                                        className="pointer-cursor"
                                        onClick={() =>
                                          onClickActionMenu(
                                            "staffAccountRelation",
                                            data.id.toString(),
                                            data.hotelId.toString(),
                                            data.staffName,
                                            data.hotelName,
                                            data.staffStatus.toString()
                                          )
                                        }
                                      >
                                        {data.staffStatus == STAFF_STATUS_ACTIVE
                                          ? t("stafflist.actionStaffDeactivate")
                                          : t("stafflist.actionStaffActivate")}
                                      </a>
                                    </div>
                                  )}
                                {checkActionPermission(
                                  adminUserRole,
                                  PERM_STAFF_DELETE
                                ) && (
                                    <div>
                                      <a
                                        className="pointer-cursor"
                                        onClick={() =>
                                          onClickActionMenu(
                                            "staffDelete",
                                            data.id.toString(),
                                            "0",
                                            data.staffName,
                                            "",
                                            ""
                                          )
                                        }
                                      >
                                        <span className="text-danger">
                                          {t("stafflist.actionStaffDelete")}
                                        </span>
                                      </a>
                                    </div>
                                  )}

                                {data.staffStatus == STAFF_STATUS_INVITE && (
                                  <div>
                                    <a
                                      className="pointer-cursor"
                                      onClick={() =>
                                        onClickActionMenu(
                                          "resendStaffInvite",
                                          data.id.toString(),
                                          data.hotelId.toString(),
                                          data.staffName,
                                          "",
                                          ""
                                        )
                                      }
                                    >
                                      <span className="">
                                        {t("stafflist.actionStaffReInvite")}
                                      </span>
                                    </a>
                                  </div>
                                )}

                              </>
                            )}

                            {checkActionPermission(
                              adminUserRole,
                              PERM_EDIT_ADMIN_USER
                            ) &&
                              title.type === "actionDeleteAdminUsers" &&
                              showDeleteActivateOption(
                                Number(adminUserId),
                                Number(adminUserRole),
                                Number(data.id),
                                Number(data.role)
                              ) && (
                                <>
                                  <div>
                                    <a
                                      className="pointer-cursor"
                                      onClick={() =>
                                        onClickActionMenu(
                                          "AdminUsersDelete",
                                          data.id.toString(),
                                          "0",
                                          data.staffName,
                                          "",
                                          ""
                                        )
                                      }
                                    >
                                      <span className="text-danger">
                                        {t("common.delete")}
                                      </span>
                                    </a>
                                  </div>
                                </>
                              )}

                            {checkActionPermission(
                              adminUserRole,
                              PERM_EDIT_ACCOUNT_USER
                            ) &&
                              title.type === "actionDeleteUsers" &&
                              showDeleteActivateOption(
                                Number(adminUserId),
                                Number(adminUserRole),
                                Number(data.id),
                                Number(data.role)
                              ) && (
                                <>
                                  <div>
                                    <a
                                      className="pointer-cursor"
                                      onClick={() =>
                                        onClickActionMenu(
                                          "userDelete",
                                          data.id.toString(),
                                          "0",
                                          data.staffName,
                                          "",
                                          ""
                                        )
                                      }
                                    >
                                      <span className="text-danger">
                                        {t("stafflist.actionStaffDelete")}
                                      </span>
                                    </a>
                                  </div>
                                </>
                              )}
                          </div>
                        </div>
                      </>
                    )}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </>
    );
  };

  const content = (): JSX.Element => {
    // if (!props.isLoading) {

    return (
      <table className="ctable">
        {tableHeaders()}
        {tableBody()}
      </table>
    );

    // return (
    //   <>
    //     <table className="table">{tableHeaders()}</table>
    //     <div className="loader">
    //       <ReactLoading className="loader-center" color={'#2f427c'} type={'bars'} width={'3vw'} />
    //     </div>
    //   </>
    // );
  };

  return (
    <div className="relative">
      <div className="htable ">
        <div className="minicol d-none d-lg-block">&nbsp;</div>
        <div className="minicol d-none d-lg-block">&nbsp;</div>
      </div>
      <div className="px-27px">{content()}</div>
    </div>
  );
};

export default Table;
