import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { post, setNotification } from '../../services/apiService';
import { RootState } from '../../store';
import { ResetPasswordAction } from '../../services/commonServices';
import { t } from 'i18next';


export interface IResetPassword {
  isProcessingRequest: boolean;
  isResetPasswordSuccess: boolean;  
}

const initialState: IResetPassword = { isProcessingRequest: false, isResetPasswordSuccess: false };

// export const resetPassword = createAsyncThunk(
// 'reset/resetpassword',
// async (thunkAPI:any)=> {
//   try {
//     const url = 'hotels/v1/password/reset/submit/?f_token=';
//     const response = post(url,"");
//     return (await response).data;
//   }catch (error){
//     return thunkAPI.rejectWithValue(error);
//   }
// }
// );

export const ResetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    start: (state) => {
      return {
        ...state,
        isProcessingRequest: true,
      };
    },
    success: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isProcessingRequest: false,
        isResetPasswordSuccess: true, 
      };
    },
    error: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isProcessingRequest: false,
      };
    },
  },

});

export const ResetPasswords = (f_token: any, token: any, user_id: any, expiry_time: any, password: string, confirm_password: string) => async (dispatch: any) => {
  dispatch(start());
  try {
    const authData = await ResetPasswordAction(f_token,token,user_id,expiry_time,password,confirm_password);
    dispatch(success(authData));
    setNotification(
      t("projectName"),
      t("messages.resetPasswordSuccess"),
      "success",
      "ResetPasswordAction"
    )
  } catch (err) {
    dispatch(error(err));    

    setNotification(
      t("projectName"),
      t("messages.resetPasswordFailed"),
      "failed",
      "ResetPasswordAction"
    )
  }
};

// Action creators are generated for each case reducer function
export const { start, success, error } = ResetPasswordSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAuthentication = (state: RootState) => state.resetPassword;

export const resetPasswordReducer = ResetPasswordSlice.reducer;
