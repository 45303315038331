import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import ReactLoading from "react-loading";
import { selectAccountsListDataSlice } from "../../pages/AccountsList/accountsListDataSlice";
import { useAppSelector } from "../../helpers/hook";
import { selectDashboardSlice } from "../../pages/Dashboard/dashboardSlice"; 
import { selectAccountDetailsSlice } from "../../pages/AccountDetails/accountDetailsSlice";
import { selectGroupDetailsSlice } from "../../pages/GroupSettings/groupDetailsSlice";
import { selectStaffListDataSlice } from "../../pages/StaffList/staffListSlice";
import { selectAccountStaffDataSlice } from "../../pages/StaffDetail/staffProfileSlice";
import { selectTipsListDataSlice } from "../../pages/TipsList/tipsListDataSlice";
import { resetManagePaymentDataSaveStatus, selectPaymentsListDataSlice } from "../../pages/PaymentsList/paymentsListDataSlice";
import { selectCommissionsListDataSlice } from "../../pages/CommissionsList/commissionsListDataSlice";
import { selectGetTermsDataSlice } from "../../pages/Terms/termsSlice";
import { selectadminUsersListDataSlice } from "../../pages/AdminUsers/adminUserSlice";
import { selectUsersListDataSlice } from "../../pages/AccountUsersList/accountUsersListSlice";
import { selectAccountSettingsFormDataSlice } from "../../pages/Settings/settingsSlice";
import { selectQrcodesDataSlice } from "../../pages/QRCodes/QRCodesSlice"; 
import { selectQRCodesYourOrdersSlice } from "../../pages/QRCodesYourOrders/QRCodesYourOrdersSlice";

export const SetLoader = () => {
    const accountsDataLoading      = useAppSelector(selectAccountsListDataSlice);
    const dashboardDataLoading     = useAppSelector(selectDashboardSlice);
    const groupAddLoading          = useAppSelector(selectAccountDetailsSlice);
    const groupSettingLoading      = useAppSelector(selectGroupDetailsSlice);
    const staffListLoading         = useAppSelector(selectStaffListDataSlice);
    const staffProfileLoading      = useAppSelector(selectAccountStaffDataSlice);
    const tipListLoading           = useAppSelector(selectTipsListDataSlice);
    const paymentListLoading       = useAppSelector(selectPaymentsListDataSlice);
    const commissionListLoading    = useAppSelector(selectCommissionsListDataSlice);
    const termsAndConditionLoading = useAppSelector(selectGetTermsDataSlice);
    const adminUserListLoading     = useAppSelector(selectadminUsersListDataSlice);
    const accountUsersListLoading  = useAppSelector(selectUsersListDataSlice);
    const settingsLoading          = useAppSelector(selectAccountSettingsFormDataSlice);
    const qrcodesSliceLoading      = useAppSelector(selectQrcodesDataSlice);
    const purchaseHistory          = useAppSelector(selectQRCodesYourOrdersSlice);

    const [show, setshow] = useState(false)

    useEffect(() => {
      if(
        accountsDataLoading.loading==="loading" ||
        dashboardDataLoading.loading==="loading" ||
        groupAddLoading.loading==="loading" ||
        groupSettingLoading.loading==="loading" ||
        staffListLoading.loading==="loading" ||
        staffProfileLoading.loading==="loading" ||
        tipListLoading.tipsListLoading==="loading" ||
        paymentListLoading.paymentsListLoading==="loading" ||
        commissionListLoading.commissionsListLoading==="loading" ||
        termsAndConditionLoading.termsLoading==="loading" ||
        adminUserListLoading.loading==="loading" ||
        accountUsersListLoading.loading==="loading" ||
        qrcodesSliceLoading.templatesLoading==="loading" || 
        qrcodesSliceLoading.groupStaffLoading==="loading" || 
        qrcodesSliceLoading.cartDataLoading==="loading" || 
        qrcodesSliceLoading.pricingLoading==="loading" || 
        qrcodesSliceLoading.deliveryOptionsStatus==="loading" || 
        qrcodesSliceLoading.imageLoading==="loading" || 
        purchaseHistory.purchaseHistoryloading==="loading" || 
        settingsLoading.loading==="loading"){
        setshow(true)
      }else{
        setshow(false)
      }
    }, [accountsDataLoading.loading,dashboardDataLoading.loading,groupAddLoading.loading,groupSettingLoading.loading,staffListLoading.loading,staffProfileLoading.loading,tipListLoading.tipsListLoading,paymentListLoading.paymentsListLoading,commissionListLoading.commissionsListLoading,termsAndConditionLoading.termsLoading,adminUserListLoading.loading,accountUsersListLoading.loading,settingsLoading.loading,qrcodesSliceLoading.templatesLoading,qrcodesSliceLoading.cartDataLoading,qrcodesSliceLoading.groupStaffLoading,qrcodesSliceLoading.pricingLoading, qrcodesSliceLoading.deliveryOptionsStatus,qrcodesSliceLoading.imageLoading,purchaseHistory.purchaseHistoryloading]); 
    
  return (
    <div>
      <Modal
        open={show}
        // onClose={() => setshow(false)}
        aria-labelledby={"Add Group"}
        aria-describedby=""
      >
        <Box className="loader" >
          <div
            className="w-100 center "
          >
            <ReactLoading
              className="loader-center"
                color={"white"}
              type={"bars"}
              width={"3vw"}
            />         
          </div>
        </Box>
      </Modal>
    </div>
  );
};


