import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from '../../store';

export interface DataState {
    reloadGroupSubMenu: number;
    navigationAccountName: string; 
    navigationAccountId: string; 
}

const initialState: DataState = {
    reloadGroupSubMenu: 0,
    navigationAccountName: "", 
    navigationAccountId: ""
}

export const commonSlice = createSlice({
    name: 'commonSlice',
    initialState,
    reducers: {
        resetReloadGroupSubMenu: (state, action: PayloadAction<number>) => {
            state.reloadGroupSubMenu = action.payload;
        },
        setNavigationAccountId: (state, action: PayloadAction<number>) => {
            state.navigationAccountId = action.payload;
        },
        setNavigationAccountName: (state, action: PayloadAction<number>) => {
            state.navigationAccountName = action.payload;
        },
    },
    extraReducers: (builder) => {

    }
})

// Action creators are generated for each case reducer function
export const { resetReloadGroupSubMenu } =  commonSlice.actions;

export const selectCommonSlice = (state:RootState)=> state.commonData; 
export const commonReducer = commonSlice.reducer;