import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import searchIcon from "../../assets/images/searchicon.svg";
import plus from "../../assets/images/plus.svg";
import activetik from "../../assets/images/activetik.svg";
import { useAppDispatch, useAppSelector } from "../../helpers/hook";
import { fetchAllAccountList, fetchPrimaryUserAccountList } from "../../services/headerService";
import { fetchAccountGroupsList } from "../../services/poolServices";
import {
  getAdminUserRole,
  getFilterAccountId,
  setFilterAccountId,
  getFilterAccountName,
  setFilterAccountName,
} from "../../services/localStorage";

import {
  FilterAllAccountListSlice,
  changeOptions,
} from "./FilterAllAccountListSlice";
import { IAccountGroupsList } from "../../models/allAccountListInterface";
import { ButtonInLine } from "../ButtonGroups/ButtonInLine";
import { Textfield } from "../TextField";
import {
  PERM_ADD_NEW_ACCOUNT,
  checkActionPermission,
} from "../../config/permissions";
import { IAccGroupListReq } from "../../models/groupInterfaces";

import {
  selectCommonSlice,
} from "../../pages/CommonSlice/commonSlice";
import { IAccListAllReq } from "../../models/accountsInterfaces";
import { USER_ROLE_ADMIN_ADMIN, USER_ROLE_ADMIN_SUPERADMIN } from "../../config/env";

interface IProps {
  //NAVIGATION
  setAccountIds?: (Ids: string) => void;
  addButtonFunction?: (modal: boolean) => void;
  navigation?: boolean;
  noHeader?: boolean;
  setAccountGroupsList?: (AccountGroupsList: IAccountGroupsList[]) => void;
  reloadGroupSubMenu?: number;
  onAddAccountDispatch?: number;

  //MULTISELECT
  setUserAccounts?: boolean;
  multiValue?: boolean;
  setMultiValue?: any;
  primaryAccount?: any;
  calledFrom?: string;
  parent?: string;

  //RESET VALUE
  resetValue?: boolean;

  changeSelectedAccountName?: (accName: string) => void;
}

const FilterAccount: React.FC<IProps> = (props) => {
  let localStorageIds = getFilterAccountId();
  let localStorageAccountName = getFilterAccountName();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const adminUserRole = getAdminUserRole();
  const { allAccountList, accountGroupsList, accountGroupsLoading, primaryUserAccount } =
    useAppSelector(FilterAllAccountListSlice);

  const [selectedAccountName, setSelectedAccountName] = useState(
    localStorageAccountName ??
    (allAccountList && allAccountList[0]?.label && allAccountList[0].label)
  );
  const [search, setSearch] = useState("");
  const [hideDropdown, setHideDropdown] = useState(false);
  const [resetIcon, setResetIcon] = useState(false);
  const [resetPrimaryUser, setResetPrimaryUser] = useState(0);



  // TO HIDE THE DROPDOWN WHILE CLICK/SELECT ONE ITEM IN DROPDOWN
  const onHideDropDown = () => {
    setHideDropdown(true);
    setTimeout(() => {
      setHideDropdown(false);
    }, 500);
  };

  //TO FETCH ALL ACCOUNT
  useEffect(() => {
    if (!props.setUserAccounts) {
      let apiData: IAccListAllReq = {
        searchString: "",
        userRole: Number(adminUserRole)
      }
      dispatch(fetchAllAccountList(apiData));
    }
    let selAccId = getFilterAccountId();
    if (selAccId && accountGroupsList.length == 0) {
      const req: IAccGroupListReq = {
        accountId: selAccId,
        userRole: Number(adminUserRole),
      };
      dispatch(fetchAccountGroupsList(req));
    }
  }, []);

  // USED TO CHANGE ACCOUNT NAME IN NAVIGATION BAR - GROUP DROPDOWN - TEXT MESSAGE
  useEffect(() => {
    if (selectedAccountName && selectedAccountName.length > 1 && props.changeSelectedAccountName) {
      props.changeSelectedAccountName(selectedAccountName);
    }
  }, [selectedAccountName]);

  useEffect(() => {
    if (
      allAccountList &&
      allAccountList.length > 0 &&
      (!localStorageIds ||
        !Number.isFinite(Number(localStorageIds)) ||
        !localStorageAccountName ||
        localStorageAccountName === undefined ||
        localStorageAccountName === "" ||
        localStorageAccountName === null)
    ) {
      localStorage.setItem(
        "filterAccountId",
        allAccountList[0].value.toString()
      );
      localStorageIds = allAccountList[0].value.toString();
      if (!props.multiValue) {
        setSelectedAccountName(allAccountList[0].label);
      }

    }

  }, [allAccountList]);

  // TO SET UP OTHER FUNCTIONALITY
  useEffect(() => {
    if (allAccountList && allAccountList.length > 0) {
      //TO SET VALUE WHILE CLICKING IN TABLE IN ADMIN AND ACCOUNT USER LIST
      if (props.setUserAccounts && props.setMultiValue.length !== 0) {


        // 22/AUG/2023 - CLIENT ASK TO DISABLE THE VALIDATION WHILE ADDING USER AND SELECTING ACCOUNT THAT CORRESPONDING ACCOUNT LIST IS SHOWING.!!! (NEED FOR LATER USER)

        // if ((adminUserRole === USER_ROLE_ADMIN_SUPERADMIN.toString() || adminUserRole === USER_ROLE_ADMIN_ADMIN.toString()) && props.parent === 'AccountUserList') {
        //   if (resetPrimaryUser === 0) {
        //     const includeArr = props.setMultiValue.map((num) => num.toString());
        //     const primaryList: string[] = primaryUserAccount.map((num) => num.toString());

        //     let tempData = allAccountList.map((data) => ({
        //       ...data,
        //       isChecked: includeArr.includes(data.value),
        //       disable: primaryList.includes(data.value)
        //     }));

        //     dispatch(changeOptions(tempData));


        //     let data = tempData.filter((item) => item.isChecked);
        //     if (data && data[0] && data[0]?.value) {
        //       const req: IAccGroupListReq = {
        //         accountId: data[0].value.toString(),
        //         userRole: Number(adminUserRole),
        //       };
        //       dispatch(fetchAccountGroupsList(req));
        //     }
        //     let selectedName = data && data[0]?.label ? data[0].label : "";
        //     let count = data.length;
        //     setSelectedAccountName(
        //       `${selectedName}  ${count > 1 ? `( +${count - 1} )` : ""} `
        //     );
        //   } else {
        //     const primaryList: string[] = primaryUserAccount.map((num) => num.toString());

        //     let tempData = allAccountList.map((data) => ({
        //       ...data,
        //       disable: primaryList.includes(data.value)
        //     }));
        //     dispatch(changeOptions(tempData));
        //     let data = tempData.filter((item) => item.isChecked && item.disable);

        //     let selectedName = data && data[0]?.label ? data[0].label : "";
        //     let count = data.length;
        //     setSelectedAccountName(
        //       `${selectedName}  ${count > 1 ? `( +${count - 1} ) ` : ""} `
        //     );
        //   }

        // } else {

        const includeArr = props.setMultiValue.map((num) => num.toString());
        let tempData = allAccountList.map((data) =>
          includeArr?.includes(data.value)
            ? { ...data, isChecked: true }
            : { ...data, isChecked: false }
        );

        dispatch(changeOptions(tempData));
        let data = tempData.filter((item) => item.isChecked);
        if (data && data[0] && data[0]?.value) {
          const req: IAccGroupListReq = {
            accountId: data[0].value.toString(),
            userRole: Number(adminUserRole),
          };
          dispatch(fetchAccountGroupsList(req));
        }
        let selectedName = data && data[0]?.label ? data[0].label : "";
        let count = data.length;
        setSelectedAccountName(
          `${selectedName}  ${count > 1 ? `( +${count - 1} )` : ""} `
        );
        // }

      }
      //TO SET VALUE, IF LOCAL ACC-ID IS NOT EMPTY
      else if (localStorageIds && localStorageIds.length > 0) {
        let currentAccount: any = allAccountList.find(
          (item) => item.value === localStorageIds
        );

        if (!props.multiValue || selectedAccountName == "") {
          let tempData = allAccountList.map((data) =>
            data.value.toString() === localStorageIds
              ? { ...data, isChecked: true }
              : { ...data, isChecked: false }
          );

          dispatch(changeOptions(tempData));
          //TO SET DISPLAY NAME
          setSelectedAccountName(
            currentAccount && currentAccount?.label
              ? currentAccount.label
              : localStorageAccountName
          );
        }
        //TO PASS VALUE TO CHILD COMPONENT(PASS ACC-IDS FOR API CALLING)
        props.setAccountIds &&
          props.setAccountIds(
            currentAccount && currentAccount?.value
              ? currentAccount.value.toString()
              : localStorageIds
          );

        if (currentAccount && currentAccount.value) {
          const req: IAccGroupListReq = {
            accountId: currentAccount.value.toString(),
            userRole: Number(adminUserRole),
          };
          dispatch(fetchAccountGroupsList(req));
        }
      }
      //TO SET VALUE, IF LOCAL ACC-ID IS EMPTY
      else {
        if (allAccountList && allAccountList[0]?.value) {
          const req: IAccGroupListReq = {
            accountId: allAccountList[0].value.toString(),
            userRole: Number(adminUserRole),
          };
          dispatch(fetchAccountGroupsList(req));
        }
        //TO SET DISPLAY NAME
        setSelectedAccountName(
          allAccountList && allAccountList[0]?.label && allAccountList[0].label
        );
        //TO SET IN LOCALSTORAGE
        setFilterAccountId(
          allAccountList && allAccountList[0]?.value && allAccountList[0].value
        );
        setFilterAccountName(
          allAccountList && allAccountList[0]?.label && allAccountList[0].label
        );
        //TO SET CHECKED THE FIRST LIST IN DROPDOWN IF LOCALSTORAGE ID IS EMPTY
        let tempData = allAccountList.map((data) =>
          allAccountList &&
            allAccountList[0]?.value &&
            allAccountList[0].value === data.value
            ? { ...data, isChecked: true }
            : { ...data, isChecked: false }
        );
        dispatch(changeOptions(tempData));
      }
    }
  }, [localStorageIds, changeOptions, primaryUserAccount]);

  //TO RESET VALUE IN ACC-FILTER
  useEffect(() => {
    if (props.resetValue) {
      setResetIcon(true);
    }
    if (
      (props.resetValue === false) &&
      allAccountList &&
      allAccountList.length > 0
    ) { onSetResetIcon() }
  }, [props.resetValue]);

  useEffect(() => {
    //TO SET Selected AccountName  WHILE CLICKING IN TABLE IN ADMIN AND ACCOUNT USER LIST
    if (allAccountList && allAccountList.length > 0 && props.setMultiValue && props.setMultiValue.length > 0) {
      // setSelectedAccountName("8");
      let newObj = allAccountList.filter(item => (Number(item.value) == Number(props.setMultiValue[0])));
      if (newObj && newObj.length) {
        setTimeout(() => {
          setSelectedAccountName(
            `${newObj[0].label}  ${props.setMultiValue.length > 1 ? `( +${props.setMultiValue.length - 1} )` : ""} `
          );
        }, 1250);
      }
    }
  }, [props.setMultiValue]);


  //TO FETCH ALL ACCOUNT  WHEN ADD ACCOUNT NAVBAR
  useEffect(() => {
    if (props.onAddAccountDispatch && props.onAddAccountDispatch > 0) {
      let apiData: IAccListAllReq = {
        searchString: "",
        userRole: Number(adminUserRole)
      }
      dispatch(fetchAllAccountList(apiData));
    }
  }, [props.onAddAccountDispatch]);

  // TO RELOAD THE NAVBAR WHEN ACC-FILTER CHANGES FOR GROUP DROPDOWN ITEMS
  useEffect(() => {
    if (
      accountGroupsLoading &&
      accountGroupsLoading == "succeeded" &&
      props.setAccountGroupsList
    ) {
      props.setAccountGroupsList(accountGroupsList);
    }
  }, [accountGroupsLoading]);

  useEffect(() => {
    let selAccId = getFilterAccountId();
    if (props.reloadGroupSubMenu && props.reloadGroupSubMenu > 0 && selAccId) {
      const req: IAccGroupListReq = {
        accountId: selAccId,
        userRole: Number(adminUserRole),
      };
      dispatch(fetchAccountGroupsList(req));
    }
  }, [props.reloadGroupSubMenu]);


  // USED FOR VALIDATING WHILE CREATING ACCOUNTUSER

  // 22/AUG/2023 - CLIENT ASK TO DISABLE THE VALIDATION WHHILE ADDING USER AND SELECTING ACCOUNT THAT CORRESPONDING ACCOUNT LIST IS SHOWING.!!! (NEED FOR LATER USER)
  // useEffect(() => {
  //   if (adminUserRole === USER_ROLE_ADMIN_SUPERADMIN.toString() || adminUserRole === USER_ROLE_ADMIN_ADMIN.toString()) {
  //     if (props.setUserAccounts && props.parent === 'AccountUserList') {
  //       let apiData: IAccListAllReq = {
  //         searchString: props.setMultiValue.join(','),
  //         userRole: Number(adminUserRole)
  //       }
  //       dispatch(fetchPrimaryUserAccountList(apiData));
  //     }
  //   }
  // }, [])

  // useEffect(() => {
  //   if (adminUserRole === USER_ROLE_ADMIN_SUPERADMIN.toString() || adminUserRole === USER_ROLE_ADMIN_ADMIN.toString()) {
  //     if (resetPrimaryUser === 1 && props.parent === 'AccountUserList') {
  //       let tempData = allAccountList.map((data) => ({
  //         ...data,
  //         disable: !props.primaryAccount.includes(Number(data.value)),
  //         isChecked: props.primaryAccount.includes(Number(data.value))
  //       }));
  //       dispatch(changeOptions(tempData));
  //     }
  //   }

  // }, [resetPrimaryUser])





  //THIS IS ONLY USED IN NAVIGATION ACC-FILTER
  const handleClick = (data) => {
    setSelectedAccountName(data.label);

    props.setAccountIds && props.setAccountIds(data.value.toString());

    if (props.navigation) {
      const req: IAccGroupListReq = {
        accountId: data.value.toString(),
        userRole: Number(adminUserRole),
      };
      dispatch(fetchAccountGroupsList(req));
      setFilterAccountId(data.value);
      setFilterAccountName(data.label);

    }
  };

  //USED WHILE CHANGING INPUT(CHECK-BOX)
  const handleChange = (e: any) => {
    const { name, checked, id } = e.target;
    //CONDITION WHILE SELECTING ALL ACCOUNT
    if (id === "selectAll") {
      let tempData = allAccountList.map((user) => {
        return { ...user, isChecked: checked };
      });
      tempData = tempData.map((data) =>
        props.primaryAccount?.includes(Number(data.value)) ? { ...data, isChecked: true } : data
      );
      dispatch(changeOptions(tempData));
      //TO PASS VALUE TO CHILD COMPONENT(PASS ACC-IDS FOR API CALLING)
      props.setAccountIds &&
        props.setAccountIds(
          allAccountList.map((item) => item.value).toString()
        );

      //TO SET DISPLAY NAME
      //TO SET DISPLAY NAME
      let data = tempData.filter((item) => item.isChecked);
      let selectedName = data && data[0]?.label ? data[0].label : "";
      let count = data.length;


      if (tempData.some((item) => item.isChecked !== true)) {
        setSelectedAccountName(
          `${selectedName}  ${count > 1 ? `( +${count - 1} )` : ""} `
        );
      } else {
        setSelectedAccountName(`${t("accountsFilter.allAccounts")}`);
      }

    } else {

      //USED TO CHANGE THE DROPDOWN VALUE WHILE SELECTING THE CHECK-BOX
      let tempData = allAccountList.map((data) =>
        data.value === id ? { ...data, isChecked: checked } : data
      );

      tempData = tempData.map((data) =>
        props.primaryAccount?.includes(Number(data.value)) ? { ...data, isChecked: true } : data
      );

      dispatch(changeOptions(tempData));

      //TO PASS VALUE TO CHILD COMPONENT(PASS ACC-IDS FOR API CALLING)
      props.setAccountIds &&
        props.setAccountIds(
          tempData
            .filter((item) => item.isChecked)
            .map((item) => item.value)
            .toString()
        );

      //TO SET DISPLAY NAME
      let data = tempData.filter((item) => item.isChecked);
      let selectedName = data && data[0]?.label ? data[0].label : "";
      let count = data.length;

      if (allAccountList.some((item) => item.isChecked !== true)) {
        setSelectedAccountName(
          `${selectedName}  ${count > 1 ? `( +${count - 1} )` : ""} `
        );
        // } 
        // else if (props.parent === "AccountUserList") {
        //   setSelectedAccountName(
        //     `${selectedName}  ${count > 1 ? `( +${count - 1} )` : ""} `
        //   );
        // } else if (props.parent !== "AccountUserList") {
      } else {
        setSelectedAccountName(`${t("accountsFilter.allAccounts")}`);
      }

      // 22/AUG/2023 - CLIENT ASK TO DISABLE THE VALIDATION WHHILE ADDING USER AND SELECTING ACCOUNT THAT CORRESPONDING ACCOUNT LIST IS SHOWING.!!! (NEED FOR LATER USER)
      // if ((adminUserRole === USER_ROLE_ADMIN_SUPERADMIN.toString() || adminUserRole === USER_ROLE_ADMIN_ADMIN.toString()) && props.parent === "AccountUserList") {

      //   //   const allCheckedValuesInPrimaryAccount = tempData.every(obj =>
      //   //     obj.isChecked === true ? props.primaryAccount.includes(Number(obj.value)) : true
      //   // );

      //   if (tempData.every(item => !item.isChecked)) {
      //     setResetPrimaryUser(1);
      //   } else if (((tempData.filter(item => item.isChecked === true).length) === 1) || resetPrimaryUser === 1) {

      //     let apiData: IAccListAllReq = {
      //       searchString: tempData.filter(item => item.isChecked === true)
      //         .map(item => item.value)
      //         .join(','),
      //       userRole: Number(adminUserRole)
      //     }
      //     dispatch(fetchPrimaryUserAccountList(apiData));
      //     setResetPrimaryUser(2);
      //   } else {
      //     setResetPrimaryUser(0);
      //   }
      // }
    }
  };


  const onSetResetIcon = () => {

    let tempData = allAccountList.map((data) =>
      localStorageIds === data.value.toString()
        ? // localAccId?.value?.includes(data.value)
        { ...data, isChecked: true }
        : { ...data, isChecked: false }
    );
    dispatch(changeOptions(tempData));

    let data = tempData.filter((item) => item.isChecked);
    if (data && data[0] && data[0]?.value) {
      const req: IAccGroupListReq = {
        accountId: data[0].value.toString(),
        userRole: Number(adminUserRole),
      };
      dispatch(fetchAccountGroupsList(req));
    }
    setSelectedAccountName(localStorageAccountName);
    props.setAccountIds &&
      props.setAccountIds(
        localStorageIds ??
        (allAccountList &&
          allAccountList[0]?.value &&
          allAccountList[0].value)
      );
    setResetIcon(false);
  };



  return (
    <>
      <div
        className={
          props.setUserAccounts
            ? "filter-option w-100"
            : props.navigation
              ? "border-none bg-transparent max-width-200px"
              : "filter-option "
        }
      >
        {/* SECTION TO DISPLAY THE SELECTED VALUE */}
        {props.setUserAccounts ? (
          <>
            <div className={`common-input `} >{selectedAccountName}</div>
          </>
        ) : props.navigation ? (
          <>
            <div className="font-600 fontsize-14" title={selectedAccountName && selectedAccountName.length > 20 ? selectedAccountName : ""}>{selectedAccountName && selectedAccountName.length > 20 ? selectedAccountName.substring(0, 20)+'...' : selectedAccountName}</div>
          </>
        ) : (
          <>
            <div className={"filterApplied filtervalue"}>
              {!props.noHeader && (
                <>
                  <img
                    src={plus}
                    alt="plus"
                    className={resetIcon ? "img-transform-45" : ""}
                    onClick={(e) => onSetResetIcon()}
                  />
                  <span>{t("accountsFilter.account")}</span>
                </>
              )}

              <div className="filterAppliedValue">{selectedAccountName}</div>
            </div>
          </>
        )}

        {/* SECTION TO DISPLAY THE BODY OF DROPDOWN */}
        {props.navigation ? (
          //NAVIGATION SECTION
          <>
            <div
              className={`${props.navigation &&
                (hideDropdown ? "hide" : "menuDropdown-body border-none pb-5")
                }`}
            >
              <div
                className={`filter-content  ${props.navigation &&
                  (hideDropdown
                    ? "hide"
                    : "menuDropdown-content border-white menuAccPosition")
                  }`}
              >
                <div className="flex w-100">
                  <div className="p-14px border-right w-100 max-width-189px">
                    <h1 className="fontsize-14 font-500 mb-7px">
                      {" "}
                      {t("common.accounts")}
                    </h1>
                    <div className="flex-col gap-5">
                      <span className="acc-font ">
                        {t("accounts.subTextdropdown")}
                      </span>
                      {checkActionPermission(
                        adminUserRole,
                        PERM_ADD_NEW_ACCOUNT
                      ) && (
                          <ButtonInLine
                            buttonLabel={`+ ${t("accounts.addAccount")}`}
                            className=" filter-add-btn "
                            onClick={(e) => {
                              props.addButtonFunction &&
                                props.addButtonFunction(true);
                            }}
                          />
                        )}
                    </div>
                  </div>

                  <form className="select-container p-14px w-100">
                    <div className="pb-3 border-bottom">
                      <div className="flex relative ">
                        <div className="div-searchIcon ">
                          <img src={searchIcon} alt="" />
                        </div>
                        <input
                          type="text"
                          placeholder={`${t("accounts.searchAccounts")}`}
                          className="search w-100"
                          onChange={(e) => setSearch(e.target.value)}
                          value={search}
                        />
                        {search && search.length > 0 && (
                          <CloseIcon
                            onClick={(e) => setSearch("")}
                            className="pointer-cursor div-search"
                            sx={{ fontSize: "medium" }}
                          />
                        )}
                      </div>
                    </div>

                    <div>
                      <label
                        className={`flex gap-2 text-grey ps-2 mt-15px`}
                        style={{ letterSpacing: "0px" }}
                      >
                        {t("common.accounts")} ({allAccountList.length})
                      </label>
                    </div>

                    <div className=" ps-2 pe-1">
                      <div className="multiselect max-height-76px">
                        {allAccountList &&
                          allAccountList
                            .filter((item) => {
                              if (search === "") {
                                return item;
                              } else if (
                                item.label
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              ) {
                                return item;
                              }
                            })
                            .map((data) => (
                              <div
                                className="  text-dark "
                                key={data.value}
                                onClick={(e) => {
                                  handleClick(data);
                                  onHideDropDown();
                                }}
                              >
                                <label
                                  htmlFor={data.value}
                                  className={`flex gap-2 acc-filter ${selectedAccountName == data.label &&
                                    "text-blue"
                                    }`}
                                  style={{ letterSpacing: "0px" }}
                                >
                                  {props.setUserAccounts && (
                                    <input
                                      type="checkbox"
                                      name={data.label}
                                      checked={data.isChecked ? true : false}
                                      onChange={(e) => handleChange(e)}
                                      id={data.value}
                                    />
                                  )}
                                  {data.label}
                                  {selectedAccountName == data.label && (
                                    <>
                                      <img src={activetik} alt="tik" />
                                    </>
                                  )}
                                </label>
                              </div>
                            ))}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        ) : (
          //FOR FILTER AND TO SET ACCOUNTS WHILE CREATING USERS IN ADMIN,ACCOUNTS
          <>
            <div className="filter-content-body">
              <div
                className={`filter-content p-0px min-width-264px ${props.setUserAccounts && "multi-input-content"
                  }`}
              >
                {!props.setUserAccounts && !props.noHeader && (
                  <label className="link-h mt-0px px-3 pt-3 mb-0">
                    {t("accountsFilter.filterByAccounts")}
                  </label>
                )}

                <form className="select-container">
                  <div className="p-3 border-bottom">
                    <div className="flex relative ">
                      <div className="div-searchIcon ">
                        <img src={searchIcon} alt="" />
                      </div>
                      <Textfield
                        type="text"
                        placeholder={`${t("accounts.searchAccounts")}`}
                        className="search w-100 mb-0 "
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                      />
                      {search && search.length > 0 && (
                        <CloseIcon
                          onClick={(e) => setSearch("")}
                          className="pointer-cursor div-search"
                          sx={{ fontSize: "medium" }}
                        />
                      )}
                    </div>
                  </div>

                  <div className="py-2 ps-3 pe-1">
                    <div className="multiselect  my-3 ">
                      {
                        // (adminUserRole !==USER_ROLE_ADMIN_SUPERADMIN.toString() || adminUserRole !== USER_ROLE_ADMIN_ADMIN.toString()) &&
                        // (props.parent !== 'AccountUserList') &&
                        <>
                          {allAccountList.filter((item) => {
                            if (search === "") {
                              return item;
                            } else if (
                              item.label
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            ) {
                              return item;
                            }
                          }).length > 1 && (
                              <label
                                className={`flex gap-2 acc-filter ${!allAccountList.some(
                                  (item) => item.isChecked !== true
                                ) && "text-blue"
                                  }`}
                                style={{
                                  letterSpacing: "0px",
                                  cursor: "pointer",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  name={"selectAll"}
                                  checked={
                                    !allAccountList.some(
                                      (item) => item.isChecked !== true
                                    )
                                  }
                                  onChange={(e) => handleChange(e)}
                                  id={"selectAll"}
                                />
                                {t("accountsFilter.allAccounts")}
                              </label>
                            )}</>}

                      {allAccountList.filter((item) => {
                        if (search === "") {
                          return item;
                        } else if (
                          item.label
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        ) {
                          return item;
                        }
                      }).length === 0 && (
                          <div className="text-data text-center me-3">
                            {t("common.noDataAvailable")}
                          </div>
                        )}

                      {allAccountList &&
                        allAccountList
                          .filter((item) => {
                            if (search === "") {
                              return item;
                            } else if (
                              item.label
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            ) {
                              return item;
                            }
                          })
                          .map((data) => (
                            <div
                              className="  text-dark "
                              key={data.value}
                            // onClick={(e) => handleClick(data)}
                            >
                              <label
                                htmlFor={data.value}

                                // 22/AUG/2023 - CLIENT ASK TO DISABLE THE VALIDATION WHHILE ADDING USER AND SELECTING ACCOUNT THAT CORRESPONDING ACCOUNT LIST IS SHOWING.!!! (NEED FOR LATER USER)
                                // className={`flex gap-2 acc-filter ${(selectedAccountName == data.label ||
                                //   data.isChecked) ?
                                //   "text-blue" : (!data.disable && props.parent === 'AccountUserList') && 'text-grey'
                                //   }`}
                                className={`flex gap-2 acc-filter ${(selectedAccountName == data.label ||
                                  data.isChecked) ?
                                  "text-blue" : " "
                                  }`}
                                style={{
                                  letterSpacing: "0px",
                                  cursor: "pointer",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  name={data.label}
                                  checked={data.isChecked ? true : false}
                                  // checked={(data.isChecked || (data.value === localStorageIds)) ? true : false}
                                  // disabled={!data.disable && props.parent === 'AccountUserList'}
                                  onChange={(e) => handleChange(e)}
                                  id={data.value}
                                />
                                {data.label}
                                {/* {selectedAccountName == data.label && (
                                  <>
                                    <img src={activetik} alt="tik" />
                                  </>
                                )} */}
                              </label>
                            </div>
                          ))}
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FilterAccount;
