import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IStaffDetails } from '../../models/staffProfileInterface';
import { deleteVerificationImg, fetchStaff, fetchWithholdingsDetail, getQrCode, removeLogo, updateStaffDetail, uploadProfilePic, uploadVerificationDocument } from '../../services/staffProfileService';
import { t } from 'i18next';
import { setNotification } from '../../services/apiService';
import { ERR_EMAIL_ALREADY_EXISTS, ERR_PHONE_NUMBER_EXISTS } from '../../config/apiResponseCodes';
import { fetchCountryStates } from '../../services/countryStateServices'; 
import { ICountryStates } from '../../models/countryStateInterfaces';

export interface DataState {
  staffDetails: IStaffDetails;
  QRCode:IStaffDetails;
  profilePic:string;
  documentImage:[];
  withholdingsDetails:[];
  loading: 'idle' | 'loading' | 'succeeded' | 'error'|'updated'|'QRfetched'|'uploaded';
  countryStates?: ICountryStates[];
}

const initialState: DataState = {
  staffDetails:{} as IStaffDetails,
  QRCode:{} as IStaffDetails,
  profilePic:'',
  documentImage:[],
  withholdingsDetails:[],
  loading: 'idle',
  countryStates: [],
};

export const accountStaffDataSlice = createSlice({
  name: 'hotelFormSettingsData',
  initialState,
  reducers: {
    setAccountStaff: (state, action: PayloadAction<any>) => {
      state.staffDetails = action.payload;
    }
  },
  extraReducers: (builder) => {
    // from admin settings
    builder.addCase(fetchStaff.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(fetchStaff.fulfilled, (state, action) => {
      state.staffDetails = action.payload;
      state.documentImage=action.payload.documentImages;
      state.withholdingsDetails=action.payload.staffWithold;
      state.loading = 'succeeded';
    });
    builder.addCase(fetchStaff.rejected, (state, { payload }: any) => {
      state.loading = 'error';
    });

    builder.addCase(fetchWithholdingsDetail.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(fetchWithholdingsDetail.fulfilled, (state, action) => {
      state.withholdingsDetails=action.payload;
      state.loading = 'succeeded';
    });
    builder.addCase(fetchWithholdingsDetail.rejected, (state, { payload }: any) => {
      state.loading = 'error';
    });

   
    builder.addCase(getQrCode.pending, (state) => {
      state.loading = 'idle';
    });
    builder.addCase(getQrCode.fulfilled, (state, action) => {
      state.QRCode = action.payload ;
      state.loading = 'QRfetched';
    });
    builder.addCase(getQrCode.rejected, (state, { payload }: any) => {
      state.loading = 'error';
    });

    builder.addCase(updateStaffDetail.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(updateStaffDetail.fulfilled, (state, action) => {
      state.loading = 'updated';
      setNotification(
        t("projectName"),
        t("messages.statusUpdated"),
        "success",
        "updateStaffDetail"
      )
    });
    builder.addCase(updateStaffDetail.rejected, (state, { payload }: any) => {
      state.loading = 'error';

      let errorFromStripe = payload?.response?.data?.error ? payload.response.data.error : "";
      let resp = payload?.response?.data?.message ? (payload.response.data.message).toLowerCase() : "";
      let msgText = ""; 
      if(errorFromStripe && errorFromStripe.trim().length > 1) {
        msgText = errorFromStripe; 
      }
      else {
        switch(resp) {
          case(ERR_EMAIL_ALREADY_EXISTS.toLowerCase()) : msgText = t("messages.emailAlreadyExists"); 
            break;
          case(ERR_PHONE_NUMBER_EXISTS.toLowerCase()) : msgText = t("messages.phoneAlreadyExists"); 
            break;
          default: msgText = t("messages.staffUpdatingFailed"); 
            break; 
        }  
      }
         
      setNotification(
        t("projectName"),
        msgText,
        "failed",
        "updateStaffDetail"
      );
    });
    
   
    builder.addCase(uploadProfilePic.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(uploadProfilePic.fulfilled, (state, action) => {
      state.profilePic = action.payload.profilePic
      state.loading = 'uploaded';
      setNotification(
        t("projectName"),
        t("messages.staffAvatarUpdated"),
        "success",
        "uploadProfilePic"
      ); 
    });
    builder.addCase(uploadProfilePic.rejected, (state, { payload }: any) => {
      state.loading = 'error';
    });
    
    builder.addCase(removeLogo.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(removeLogo.fulfilled, (state, action) => {
      state.loading = 'uploaded';
      state.profilePic ='';
      setNotification(
        t("projectName"),
        t("messages.staffAvatarRemoved"),
        "success",
        "removeLogo"
      )
    });
    builder.addCase(removeLogo.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      console.log('payload2: ', payload);
    });

    builder.addCase(uploadVerificationDocument.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(uploadVerificationDocument.fulfilled, (state, action) => {
      state.documentImage = action.payload.data.data;
      state.loading = 'uploaded';
      
      setNotification(
        t("projectName"),
        t("messages.uploaded"),
        "success",
        "uploadVerificationDocument"
      )
    });
    builder.addCase(uploadVerificationDocument.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      console.log('payload2: ', payload);
    });

    builder.addCase(deleteVerificationImg.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(deleteVerificationImg.fulfilled, (state, action) => {
      state.documentImage=[];
      state.loading = 'uploaded';
      state.profilePic ='';
      
      setNotification(
        t("projectName"),
        t("messages.removed"),
        "success",
        "deleteVerificationImg"
      )
    });
    builder.addCase(deleteVerificationImg.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      console.log('payload2: ', payload);
    });

    builder.addCase(fetchCountryStates.pending, (state) => {
      console.log('fetchCountryStates11', state);
      state.countryStates = [];
    });
    builder.addCase(fetchCountryStates.fulfilled, (state, action) => {
      console.log('fetchCountryStates22: ', action);
      state.countryStates = action?.payload?.result ? action.payload.result : [];
    });
    builder.addCase(fetchCountryStates.rejected, (state, { payload }: any) => {
      console.log('fetchCountryStates33: ', payload);
      state.countryStates = [];
    });

  },
});

// Action creators are generated for each case reducer function
export const { setAccountStaff } =
accountStaffDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAccountStaffDataSlice = (state: RootState) => state.accountStaffData

export const accountStaffDataReducer = accountStaffDataSlice.reducer;
