import React, { useState, useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

import img_export from "../../assets/images/import.svg";

import {
  PAGINATION_NUM_ROWS,
  ARR_ROWS_PER_PAGE_VALUES,
} from "../../config/env";
import { SelectField } from "../SelectField";

interface IProps {
  currentPage: number;
  numRowsPerPage?: number;
  totalPageCount: number;
  totalRows: number;
  isTableLoading: boolean;
  setCurrentPage: (page: number) => void;
  setNumRowsPerPage?: (numRows: number) => void;
  recordsTypeName?: string;
  exportButton?: boolean;
  onExportButton?: () => void;
}

const Paginations: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_NUM_ROWS);

  useEffect(() => {
    if (props.numRowsPerPage && props.numRowsPerPage > 0) {
      setRowsPerPage(props.numRowsPerPage); 
    }
  }, [props.numRowsPerPage]);

  const handlePageChange = (e: any, p: number) => {
    props.setCurrentPage(p);
  };

  const onSetRowsPerPage = (rp: number) => {
    setRowsPerPage(rp);
    if (props?.setNumRowsPerPage) {
      handlePageChange("", 1); // RESET PAGE NUMBER ON NUM OF RECORDS PER PAGE CHANGES
      props.setNumRowsPerPage(rp);
    }
  };

  let rpp = (props?.numRowsPerPage && props.numRowsPerPage > 0) ? props.numRowsPerPage : PAGINATION_NUM_ROWS; 
  let toNum = props.currentPage * rpp;
  let fromNum = toNum - rpp + 1;
  toNum = toNum > props.totalRows ? props.totalRows : toNum;

  const onClickExportButton = () => {
    let dummyVar = "";
    props?.onExportButton ? props.onExportButton() : (dummyVar = " ");
  };

  return (
    <div className="mt-3 space-between px-4">
      {props.totalPageCount > 0 && <div className="text-grey center fontsize-14 ">
        {t("common.view")}
        <SelectField
          className="paginationselect "
          value={rowsPerPage}
          onChange={(e) => onSetRowsPerPage(parseInt(e.target.value, 10))}
        options={<>
          {ARR_ROWS_PER_PAGE_VALUES &&
            ARR_ROWS_PER_PAGE_VALUES.map((item, index) => (
              <>
                <option value={item}>{item}</option>
              </>
            ))}</>}
        />
        {t("common.recordsPerPage", {
          recordsType:
            props?.recordsTypeName && props?.recordsTypeName.length > 0
              ? props.recordsTypeName.toLowerCase()
              : t("common.records"),
        })}
      </div>}

      {props.totalPageCount > 0 && <span className="text-grey center fontsize-14">
        {t("common.paginationRows", {
          fromNum: fromNum,
          toNum: toNum,
          totalNum: props.totalRows,
          resultType:
            props?.recordsTypeName && props?.recordsTypeName.length > 0
              ? props.recordsTypeName.toLowerCase()
              : t("common.results"),
        })}
      </span>}

      {props.exportButton && props.totalPageCount > 0 &&  (
        <div className="exportbtn pointer-cursor" onClick={onClickExportButton}>
          <span>
            <img
              src={img_export}
              alt={`${t("common.export")}`}
              className="icon"
            />
          </span>
          <span className="mt-2px">{t("common.export")}</span>
        </div>
      )}

      <Stack spacing={2}>
        {props.totalPageCount > 0 && <Pagination
          count={props.totalPageCount}
          color="primary"
          className="pagination"
          onChange={handlePageChange}
          page={props.currentPage}
          variant="outlined"
          shape="rounded"
        />}
      </Stack>
    </div>
  );
};

export default Paginations;
