import { createAsyncThunk } from "@reduxjs/toolkit";
import { IGroupDetails, IGroupModify, IGroupNameEdit, ISearchStaff } from "../models/groupInterfaces";
import { get, put } from "./apiService";
import { ACC_API_URL_ACC_GROUP_DETAILS, ACC_API_URL_ACC_GROUP_DETAILS_UPDATE, ACC_API_URL_ACC_GROUP_NAME_UPDATE, SA_API_URL_ACC_GROUP_DETAILS, SA_API_URL_ACC_GROUP_DETAILS_UPDATE, SA_API_URL_ACC_GROUP_NAME_UPDATE, SA_API_URL_PAYEE_LIST } from '../config/api_urls'; 
import { arrAccountUserIds } from "../config/env";

// FUNCTION TO FETCH GROUP DETAILS
export const fetchGroupDetails = createAsyncThunk(
    'hotels/groupDetails',
    async({id, userRole}:IGroupDetails,thunkAPI:any)=>{
        try{
            const url = (arrAccountUserIds.includes(userRole) ? ACC_API_URL_ACC_GROUP_DETAILS : SA_API_URL_ACC_GROUP_DETAILS)+id+'/'; 
            const response =get(url);
            return (await response).data
        }
        catch(error){
            return thunkAPI.rejectWithValue(error)
        }
    }
)

// FUNCTION TO UPDATE GROUP NAME
export const updateGroupName = createAsyncThunk(
    'hotels/groupNameUpdate',
    async(groupDetails:IGroupNameEdit,thunkAPI:any)=>{
        try{
            const url = (arrAccountUserIds.includes(groupDetails.userRole) ? ACC_API_URL_ACC_GROUP_NAME_UPDATE : SA_API_URL_ACC_GROUP_NAME_UPDATE)+groupDetails.groupId+'/'; 
            const response = put(url, {name:groupDetails.groupName});
            return (await response).data
        }
        catch(error){
            return thunkAPI.rejectWithValue(error)
        }
    }
)

// FUNCTION TO UPDATE GROUP DEATILS
export const updateGroupDetails = createAsyncThunk(
    'hotels/groupDetailsUpdate',
    async(groupDetails:IGroupModify,thunkAPI:any)=>{
        try{
            const url = (arrAccountUserIds.includes(groupDetails.userRole) ? ACC_API_URL_ACC_GROUP_DETAILS_UPDATE : SA_API_URL_ACC_GROUP_DETAILS_UPDATE)+groupDetails.id+'/'; 
            const response = put(url, groupDetails);
            return (await response).data
        }
        catch(error){
            return thunkAPI.rejectWithValue(error)
        }
    }
)

// FUNCTION TO FETCH PAYEE BY NAME
// export const fetchPayeeByName123 = createAsyncThunk(
//     'tips/fetchPayeeByName',
//     async (name: string, thunkAPI: any) => {
//         try {
//         let url = SA_API_URL_PAYEE_LIST+'?name=' + name; 
//         const response = get(url);
//         return (await response).data;
//         } catch (error) {
//         return thunkAPI.rejectWithValue(error);
//         }
//     }
// );

// FUNCTION TO FETCH PAYEE BY NAME AND ACCOUNT ID
export const fetchPayeeByNameAccId = createAsyncThunk(
    'tips/fetchPayeeByNameAccId',
    async ({account_id, name} : ISearchStaff, thunkAPI: any) => {
        try {
        let url = SA_API_URL_PAYEE_LIST+'?account_id=' + account_id+'&name='+ name; 
        const response = get(url);
        return (await response).data;
        } catch (error) {
        return thunkAPI.rejectWithValue(error);
        }
    }
);

