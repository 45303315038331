import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, post, getFile } from './apiService';
import { IAccountFilterRequestData, ICreateNewAccount } from '../models/accountsInterfaces';
import { SA_API_URL_ACCOUNTS_LIST, ACC_API_URL_ACCOUNTS_LIST, ACC_API_URL_ACCOUNTS_EXPORT, SA_API_URL_ACCOUNTS_EXPORT, SA_API_URL_ACCOUNT_CREATE } from '../config/api_urls'; 
import { arrAccountUserIds } from '../config/env'; 

// FUNCTION TO FETCH LIST OF ACCOUNTS WITH FILTERS 
export const fetchAccounts = createAsyncThunk(
  'accounts/fetchAccounts',
  async ({ userRole, page, numRowsPerPage, search, active, added_from_date, added_to_date, sort }: IAccountFilterRequestData, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(parseInt(userRole,10)) ? ACC_API_URL_ACCOUNTS_LIST : SA_API_URL_ACCOUNTS_LIST)+'?page=' + page;
      if (numRowsPerPage)     { url = url + '&page_size=' + numRowsPerPage.toString(); }
      if (sort !== '')         { url = url + '&sort=' + sort?.trim(); }
      if (search !== '')       { url = url + '&search=' + search?.trim(); }
      if (active !== '')       { url = url + '&active=' + active; }
      if (added_from_date!==''){ url = url + '&added_from_date=' + added_from_date; }
      if (added_to_date!=='')  { url = url + '&added_to_date=' + added_to_date; }
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// FUNCTION TO FETCH LIST OF ACCOUNTS  
export const fetchFilterAccounts = createAsyncThunk(
  'hotels/fetchFilterAccounts',
  async ({ userRole, page, numRowsPerPage, search, active, added_from_date, added_to_date, sort }: IAccountFilterRequestData, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(parseInt(userRole,10)) ? ACC_API_URL_ACCOUNTS_LIST : SA_API_URL_ACCOUNTS_LIST)+'?page=' + page; 
      if (active) { url = url + '&active=' + active; }
      if (numRowsPerPage) { url = url + '&page_size=' + numRowsPerPage.toString(); }
      if (search !== '') { url = url + '&search=' + search?.trim(); }
      if (added_from_date !== '') { url = url + '&added_from_date=' + added_from_date; }
      if (added_to_date !== '') { url = url + '&added_to_date=' + added_to_date; }
      if (sort !== '') { url = url + '&sort=' + sort; }
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//TO EXPORT/DOWNLOAD ACCOUNT LIST
export const exportAccounts = ({ userRole, page, search, active, added_from_date, added_to_date, sort  }: IAccountFilterRequestData): void => {
  try {
    let url = arrAccountUserIds.includes(parseInt(userRole,10)) ? ACC_API_URL_ACCOUNTS_EXPORT : SA_API_URL_ACCOUNTS_EXPORT; 
    let sepstr = '?';
    if (page) {
      url = url + sepstr + 'page=' + page;
      sepstr = '&';
    }
    if (search !== '') {
      url = url + sepstr + 'search=' + search?.trim();
      sepstr = '&';
    }
    if (active !== '') {
      url = url + sepstr + 'active=' + active;
      sepstr = '&';
    }
    if (added_from_date !== '') {
      url = url + sepstr + 'added_from_date=' + added_from_date;
      sepstr = '&';
    }
    if (added_to_date !== '') {
      url = url + sepstr + 'added_to_date=' + added_to_date;
      sepstr = '&';
    }
    if (sort !== '') {
      url = url + sepstr + 'sort=' + sort;
      sepstr = '&';
    }

    getFile(url, 'Account_List_Export');
  } catch (error) {
  }
};

//TO CREATE NEW ACCOUNT
export const newAccountCreate = createAsyncThunk(
  'newAccountCreate',
  async (form: ICreateNewAccount, thunkAPI: any) => {
    try {
      const url = SA_API_URL_ACCOUNT_CREATE;
      const response = post(url, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

