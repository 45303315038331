import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { achUpdate  } from '../../../services/signUpServices';
 
export interface IAuthentication {
  achUpdateStatus: 'idle' | 'loading' | 'succeeded' | 'error';
} 

const initialState: IAuthentication = { 
  achUpdateStatus: 'idle', 
 };

export const achDataSlice = createSlice({
  name: 'achData',
  initialState,
  reducers: { 
  },
  extraReducers: (builder) => {

    builder.addCase(achUpdate.pending, (state) => {
      state.achUpdateStatus = 'loading';
    });
    builder.addCase(achUpdate.fulfilled, (state, action) => { 
      console.log('action: ',action);
      // state.achUpdateStatus = action?.payload ?  action.payload : '';  
      state.achUpdateStatus = 'succeeded';  
    }); 
    builder.addCase(achUpdate.rejected, (state, { payload }: any) => { 
      console.log('payload: ',payload);
      state.achUpdateStatus = 'error';
    });
  }
});

// Action creators are generated for each case reducer function
// export const { setIsProcessingRequest } = achDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAchDataSlice = (state: RootState) => state.achData; 

export const achDataReducer = achDataSlice.reducer;
