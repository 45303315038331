
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IAccount } from '../../models/accountsInterfaces';
import { fetchFilterAccounts, fetchAccounts, newAccountCreate } from '../../services/accountServices';
import {  IPagination } from '../../models/interfaces';
import {  setNotification } from '../../services/apiService';
import i18n from '../../utilities/i18n';
import { ERR_ACC_NAME_EXIST, ERR_USER_ALREADY_EXISTS } from '../../config/apiResponseCodes';

export interface DataState {
  accounts: IAccount[];
  loading: 'idle' | 'loading' | 'succeeded' | 'error' | 'accountCreated' | 'accountCreateError';
  error: string | undefined;
  totalSize: number;
}

const initialState: DataState = {
  accounts: [],
  loading: 'idle',
  error: '',
  totalSize: 0,
};

export const accountsListDataSlice = createSlice({
  name: 'accountsListData',
  initialState,
  reducers: {
    setAccountsList: (state, action: PayloadAction<any>) => {
      state.accounts = action.payload;
    },
    setDataLoading: (state) => {
      state.loading = 'loading';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccounts.pending, (state) => {
      state.accounts = [];
      state.loading = 'loading';
    });
    builder.addCase(fetchAccounts.fulfilled, (state, action) => {
      const m = action.payload as IPagination<IAccount[]>;
      state.accounts = m.results;
      state.loading = 'succeeded';
      state.totalSize = m.count;
    });
    builder.addCase(fetchAccounts.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
      state.totalSize = 0;
    });
    builder.addCase(fetchFilterAccounts.pending, (state) => {
      state.accounts = [];
      state.loading = 'loading';
    });
    builder.addCase(fetchFilterAccounts.fulfilled, (state, action) => {
      const m = action.payload as IPagination<IAccount[]>;
      state.accounts = m.results;
      state.loading = 'succeeded';
      state.totalSize = m.count;
    });
    builder.addCase(fetchFilterAccounts.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      state.totalSize = 0;
      // state.error = action.error.message;
    });

    builder.addCase(newAccountCreate.pending, (state) => {
      state.loading = 'loading';
    });

    builder.addCase(newAccountCreate.fulfilled, (state, { payload }: any) => {
      console.log(payload.message,"Rain showers");
      
      state.loading = 'accountCreated';

      if(payload.message==="account_and_user_created"){    
        setNotification(
          i18n.t("messages.appName"),
          i18n.t("businessDetails.accountCreatedWithUser"),
          "success",
          "newAccountCreate"
        );

      }else{    
      setNotification(
        i18n.t("messages.appName"),
        i18n.t("messages.accountCreated"),
        "success",
        "newAccountCreate"
      );
      }

    });
    builder.addCase(newAccountCreate.rejected, (state, { payload }: any) => {
      if(payload.response.data.message===ERR_ACC_NAME_EXIST){
        setNotification(
          i18n.t("messages.appName"),
          i18n.t("messages.accountNameExist"),
          "failed",
          "newAccountCreate"
        );
      }else if(payload.response.data.message===ERR_USER_ALREADY_EXISTS){
        setNotification(
          i18n.t("messages.appName"),
          i18n.t("accountSignUp.errorUserExists"),
          "failed",
          "newAccountCreate"
        );
      }
      else{
        setNotification(
          i18n.t("messages.appName"),
          i18n.t("messages.accountCreationFailed"),
          "failed",
          "newAccountCreate"
        );
      }
      
      state.loading = 'error';
     
    });

  },
});

// Action creators are generated for each case reducer function
export const { setAccountsList, setDataLoading } = accountsListDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAccountsListDataSlice = (state: RootState) => state.accountsListData;

export const accountsListDataReducer = accountsListDataSlice.reducer;
