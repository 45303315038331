import { addDays ,subDays, startOfWeek,lastDayOfWeek,subWeeks, startOfMonth,endOfMonth,subMonths, startOfQuarter,endOfQuarter,subQuarters, startOfYear,endOfYear,subYears } from 'date-fns';

import { DATEFILTER_TODAY, DATEFILTER_THISWEEK, DATEFILTER_THISMONTH, DATEFILTER_THISYEAR } from "../config/env";  

// COMMON FUNCTION TO GET DATE VALUES BY DATE TYPE STRINGS LIKE yesterday, lastWeek etc.
export function filterDateValues(filterType) { 
    var dt = new Date(); 
    var dateValue = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate(),0,0,0); 

    if(filterType==DATEFILTER_TODAY) {
        return {"fromDate":dateValue, "toDate":dateValue} 
    }
    else if(filterType=='yesterday') { 
        return {"fromDate":subDays(dateValue,1), "toDate":subDays(dateValue,1)} 
    }
    else if(filterType==DATEFILTER_THISWEEK) {
        return {"fromDate":startOfWeek(dateValue), "toDate":lastDayOfWeek(dateValue)} 
    }
    else if(filterType==DATEFILTER_THISMONTH) {
        return {"fromDate":startOfMonth(dateValue), "toDate":endOfMonth(dateValue)} 
    }
    else if(filterType=='thisQuarter') {
        return {"fromDate":startOfQuarter(dateValue), "toDate":endOfQuarter (dateValue)} 
    }
    else if(filterType=='lastWeek') {
        return {"fromDate":subWeeks(startOfWeek(dateValue),1), "toDate":subWeeks(lastDayOfWeek(dateValue),1)} 
    }
    else if(filterType=='lastMonth') {
        return {"fromDate":subMonths(startOfMonth(dateValue),1), "toDate":subMonths(endOfMonth(dateValue),1)} 
    }
    else if(filterType=='lastQuarter') {
        return {"fromDate":subQuarters(startOfQuarter(dateValue),1), "toDate":subQuarters(endOfQuarter(dateValue),1)} 
    }
    else if(filterType==DATEFILTER_THISYEAR) {
        return {"fromDate":startOfYear(dateValue), "toDate":endOfYear(dateValue)}  
    }
    else if(filterType=='lastYear') {
        return {"fromDate":subYears(startOfYear(dateValue),1), "toDate":subYears(endOfYear(dateValue),1)} 
    }
    else if(filterType=='last7Days') { 
        return {"fromDate":subDays(dateValue,7), "toDate":dateValue} 
    }
    else if(filterType=='last30Days') { 
        return {"fromDate":subDays(dateValue,30), "toDate":dateValue} 
    }
    else if(filterType=='last60Days') { 
        return {"fromDate":subDays(dateValue,60), "toDate":dateValue} 
    }
    else if(filterType=='last90Days') { 
        return {"fromDate":subDays(dateValue,90), "toDate":dateValue} 
    }
    else if(filterType=='last6Months') {
        return {"fromDate":addDays(subMonths(startOfMonth(dateValue),6),dt.getDate()), "toDate":dateValue} 
    }
    else if(filterType=='last12Months') {
        return {"fromDate":addDays(subMonths(startOfMonth(dateValue),12),dt.getDate()), "toDate":dateValue} 
    }
    else {
        return {"fromDate":dateValue, "toDate":dateValue} 
    }
}


