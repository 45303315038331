import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode'; 

import { IStaffRegisterForm, IOtpForm, ITerms } from '../../models/staffRegister';
import { checkMaid, maidOtpRequest, staffRegister, getTermsAndCondition, staffRegisterTemp } from '../../services/staffRegisterServices';
import { RootState } from '../../store';
import { setMaidTokens, setMaidId } from '../../services/localStorage';
import { acceptTermsAndConditions } from '../../services/staffRegisterServices';
import { setNotification } from '../../services/apiService';
import { t } from 'i18next';

export interface DataState {
  staffRegisterForm: IStaffRegisterForm;
  termsAndC : ITerms;
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
  screen: 'register' | 'otp' | 'otp-success' | 'maid-profile' | 'error';
  staffRegTemp: string;
  otpForm: IOtpForm;
  notifyMsgType: '' | 'OTPSentSuccess' | 'OTPSentFailed';
  staffTermsAccepted: 'idle' | 'loading' | 'succeeded' | 'error';
}

const initialState: DataState = {
  staffRegisterForm: {} as IStaffRegisterForm,
  termsAndC : {} as ITerms,
  loading: 'idle',
  screen: 'register',
  staffRegTemp: 'register', 
  otpForm: {} as IOtpForm,
  notifyMsgType: '',
  staffTermsAccepted: 'idle',
};

export const StaffFormSlice = createSlice({
  name: 'StaffFormSlice',
  initialState,
  reducers: {
    setScreen: (state, action: PayloadAction<'register' | 'otp' | 'otp-success'>) => {
      state.screen = action.payload;
    },
    setStaffRegTemp: (state, action: PayloadAction<string>) => {
      state.staffRegTemp = action.payload;
    },
  },
  extraReducers: (builder) => {
    

    builder.addCase(staffRegisterTemp.pending, (state) => {
      state.staffRegTemp = 'register';
    });
    builder.addCase(staffRegisterTemp.fulfilled, (state, action) => {
      state.staffRegTemp = 'success';
    });
    builder.addCase(staffRegisterTemp.rejected, (state, { payload }: any) => {
      state.staffRegTemp = payload?.response?.data ? payload.response.data : 'error'; 
      console.log(payload.response); 
    });

    builder.addCase(staffRegister.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(staffRegister.fulfilled, (state, action) => {
      if(action.payload.access) {
        let dataAccess = action.payload.access;
        let decoded = jwt_decode(dataAccess);

        setMaidId(decoded);
        setMaidTokens(action.payload);
        state.screen = 'maid-profile';
      }
      else {
        state.loading = 'succeeded';
        state.screen = 'otp-success';
      }
      
    });
    builder.addCase(staffRegister.rejected, (state, { payload }: any) => {
      state.loading = 'error';
    });


    builder.addCase(checkMaid.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(checkMaid.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.screen = 'otp';
    });
    builder.addCase(checkMaid.rejected, (state, { payload }: any) => {
      state.loading = 'error';
    });
    builder.addCase(maidOtpRequest.fulfilled, (state, action) => {
      state.notifyMsgType = 'OTPSentSuccess'
      
    });
    builder.addCase(maidOtpRequest.rejected, (state, action) => {
      state.notifyMsgType = 'OTPSentFailed'; 
      
    });
    builder.addCase(getTermsAndCondition.pending, (state) => {
      state.loading = 'loading';
      state.staffTermsAccepted = 'loading'; 
    });
    builder.addCase(getTermsAndCondition.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      let data = action.payload as ITerms
      state.termsAndC = data;
      state.staffTermsAccepted = 'loading'; 
    });
    builder.addCase(getTermsAndCondition.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      state.staffTermsAccepted = 'loading'; 
    });

    builder.addCase(acceptTermsAndConditions.pending, (state) => {
      state.staffTermsAccepted = 'loading';
    });
    builder.addCase(acceptTermsAndConditions.fulfilled, (state, action) => {
      state.staffTermsAccepted = 'succeeded';
    });
    builder.addCase(acceptTermsAndConditions.rejected, (state, { payload }: any) => {
      state.staffTermsAccepted = 'error'; 

      setNotification(
        t("projectName"),
        t("messages.T&CFailed"),
        "failed",
        "acceptTermsAndConditions"
      )

    });
  },
});

// Action creators are generated for each case reducer function
export const { setScreen, setStaffRegTemp } = StaffFormSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectStaffFormSlice = (state: RootState) => state.staffFormData;

export const staffRegisterReducer = StaffFormSlice.reducer; 
