import i18n from "../utilities/i18n";

const dh_server_env = 'staging'; // [production, staging, demo, development]

const dh_env: any = {
  development: {
    API_URL: 'https://usetova.designhuddle.com/' 
  },
  demo: {
    API_URL: 'https://usetova.designhuddle.com/' 
  },
  staging: {
    API_URL: 'https://usetova.designhuddle.com/'
  },
  production: {
    API_URL: 'https://usetova.designhuddle.com/'  
  }
};  

const dh_server_url: any = {
  development: 'https://usetova.designhuddle.com/',
  demo: 'https://usetova.designhuddle.com/',
  staging: 'https://usetova.designhuddle.com/',
  production: 'https://usetova.designhuddle.com/',
};

export const dh_env_var = dh_env[dh_server_env];

export const server_url_var = dh_server_url[dh_server_env];

export const DH_CLIENT_ID     = "9j7vn69ghm0nehwvvqt5"; 
export const DH_CLIENT_SECRET = "ohar2g4c30bj29w2dy1lxuzv3g4hk1chsstbpvhs"; 

// ROUTE CONSTANTS FOR QRCODE PAGES
export const ROUTE_QRCODES                  = '/qrcodes'; 
export const ROUTE_QRC_CHECKOUT             = '/qr-checkout'; 
export const ROUTE_QRC_SHIPPING             = '/qr-shipping'; 
export const ROUTE_QRC_PAYMENT              = '/qr-payment'; 
export const ROUTE_QRC_REVIEW_PAY           = '/qr-review-pay'; 
export const ROUTE_QRC_ORDER                = '/qr-placeorder'; 
export const ROUTE_QRC_PURCHASE_HISTORY     = '/qr-purchase-history'; 
export const ROUTE_QRC_RECEIPT              = '/qr-receipt'; 

export const QRCODE_API_URL_VIEW_CART       = "hotel-users/order-qr/cart/"; 
export const QRCODE_API_URL_CART_COUNT      = "hotel-users/order-qr/cart-count/"; 
export const QRCODE_API_URL_ADD_CART_ITEM   = "hotel-users/order-qr/add-to-cart/"; 
export const QRCODE_API_URL_MODIFY_CART_ITEM= "hotel-users/order-qr/update-cart/"; 
export const QRCODE_API_URL_REMOVE_CART_ITEM= "hotel-users/order-qr/remove-from-cart/"; 
export const QRCODE_API_URL_ORDERS_HISTORY  = "hotel-users/order-qr/orders/"; 
export const QRCODE_API_URL_DOCUMENT_PREVIEW= "hotel-users/vista-print/document-preview/"; // ?document_ref_url=http://...&sku=utc-1134
export const QRCODE_API_URL_SHIPPING_ADDRESS= "hotel-users/vista-print/delivery-options/"; // ITS RESPONSE IS DELIVERY OPTIONS
export const QRCODE_API_URL_PAYMENT_TOKEN   = "hotel-users/vista-print/payment-token/"; 

export const QRCODE_API_URL_DELETE_DH_PROJECT="hotel-users/design-huddle/delete-project/"; // <project_id>/
export const QRCODE_API_URL_SKU_PRODUCT_NAMES="hotel-users/vista-print/sku-product-name/";


// DESIGN HUDDLE API URLS
export const DH_API_URL_APPACCESSTOKEN          = "hotel-users/design-huddle/app-token"; 
export const DH_API_URL_USERACCESSTOKEN         = "hotel-users/design-huddle/user-token"; 
export const DH_API_URL_BASE_TEMPLATES          = "hotel-users/design-huddle/base-template/"; 
export const DH_API_URL_ACCOUNT_TEMPLATES       = "hotel-users/design-huddle/account-template/"; 
export const DH_API_URL_CREATE_PROJECT          = "hotel-users/design-huddle/create-project/"; 
export const DH_API_URL_CREATE_TEMPLATE_PROJECT = "hotel-users/design-huddle/create-account-project/"; 
export const DH_API_URL_LIST_ACCOUNT_PROJECTS   = "hotel-users/design-huddle/account-project/"; 
export const DH_API_URL_LIST_ACCOUNT_TEMPLATES  = "hotel-users/design-huddle/list-template/"; // ?account_id=123 
export const DH_API_URL_DOWNLOAD_TEMPLATE       = "hotel-users/design-huddle/export-project/"; 
export const DH_API_URL_VIEW_CART               = "hotel-users/order-qr/cart/"; 
export const DH_API_URL_ADD_TO_CART             = "hotel-users/order-qr/add-to-cart/";  
export const DH_API_URL_PUBLISH_TEMPLATE        = "hotel-users/design-huddle/publish-template/"; 
export const DH_API_URL_APPLY_TEMPLATE_PROJECTS = "hotel-users/design-huddle/create-template-project/"; 
export const DH_API_URL_DELETE_PRE_MADE_TEMPLATE= "hotel-users/design-huddle/delete-template/"; 

// VISTA PRINT - API URLS
export const VP_API_URL_PRICING             = "hotel-users/vista-print/sku-price/"; // ?card_type=1

export const DH_TEMPLATE_TYPE_BASE          =  1; // "Base"; 
export const DH_TEMPLATE_TYPE_BLANK         =  2; // "Blank"; 
export const DH_TEMPLATE_TYPE_ACCOUNT       =  3; // "Account"; DESIGNS SPECIFIC TO THE ACCOUNT
export const DH_TEMPLATE_TYPE_COMMON        =  4; // COMMON TEMPLATE FOR GROUPS/STAFF

export const DH_TEMPLATE_DESIGN_TYPE_POSTCARD     =  1; // "postcard"; 
export const DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD =  2; // "business_card"; 
export const DH_TEMPLATE_DESIGN_TYPE_TABLETENT    =  3; // "table_tent"; 

export const DH_MEASURE_UNIT_INCH                 =  "in"; 

export const QRCODE_POOL_FILTER_ALL               = i18n.t("env.all"); 
export const QRCODE_STAFF_FILTER_ALL              = i18n.t("env.all"); 

// QR CODE SELECTION TYPES 
export const QRCODE_FOR_GROUP  = "group"; 
export const QRCODE_FOR_STAFF  = "staff"; 

export const GROUP_STAFF_SEL_ALL_VALUE = "all"; 

export const QRCODE_BILL_STRIPE_CHARGE = 2.9; // STRIPE CHARGE FOR QR CODE PAYMENTS

export const QRCODE_ORDER_STATUS = {
  Initialized:              i18n.t("qrCodeVistaPrintOrderStatus.Initialized"), 
  ReadyForFulfillment:      i18n.t("qrCodeVistaPrintOrderStatus.ReadyForFulfillment"), 
  AwaitingFulfillment:      i18n.t("qrCodeVistaPrintOrderStatus.AwaitingFulfillment"), 
  ReadyForDocReview:        i18n.t("qrCodeVistaPrintOrderStatus.ReadyForDocReview"), 
  AwaitingDocReview:        i18n.t("qrCodeVistaPrintOrderStatus.AwaitingDocReview"), 
  AwaitingInventoryCheck:   i18n.t("qrCodeVistaPrintOrderStatus.AwaitingInventoryCheck"),  
  Cancelled:                i18n.t("qrCodeVistaPrintOrderStatus.Cancelled"), 
  PartiallyCancelled:       i18n.t("qrCodeVistaPrintOrderStatus.PartiallyCancelled"), 
  Shipped:                  i18n.t("qrCodeVistaPrintOrderStatus.Shipped"), 
  PartiallyShipped:         i18n.t("qrCodeVistaPrintOrderStatus.PartiallyShipped"),  
  ShippedWithCancellations: i18n.t("qrCodeVistaPrintOrderStatus.ShippedWithCancellations"),  
  Completed:                i18n.t("qrCodeVistaPrintOrderStatus.Completed"), 
}; 

export const QRCODE_ORDER_ITEM_STATUS = {
  AwaitingDocFeedback:        i18n.t("qrCodeVistaPrintOrderItemStatus.AwaitingDocFeedback"), 
  AwaitingDocReview:          i18n.t("qrCodeVistaPrintOrderItemStatus.AwaitingDocReview"), 
  AwaitingFulfillment:        i18n.t("qrCodeVistaPrintOrderItemStatus.AwaitingFulfillment"), 
  Canceled:                   i18n.t("qrCodeVistaPrintOrderItemStatus.Canceled"), 
  ClosedPartialCancel:        i18n.t("qrCodeVistaPrintOrderItemStatus.ClosedPartialCancel"), 
  DocReviewApproved:          i18n.t("qrCodeVistaPrintOrderItemStatus.DocReviewApproved"), 
  FulfillmentStarted:         i18n.t("qrCodeVistaPrintOrderItemStatus.FulfillmentStarted"), 
  InsufficientInventory:      i18n.t("qrCodeVistaPrintOrderItemStatus.InsufficientInventory"), 
  InsufficientInventoryFinal: i18n.t("qrCodeVistaPrintOrderItemStatus.InsufficientInventoryFinal"), 
  Open:                       i18n.t("qrCodeVistaPrintOrderItemStatus.Open"), 
  Shipped:                    i18n.t("qrCodeVistaPrintOrderItemStatus.Shipped"),   
}; 

export const QRCODE_DH_IFRAME_URL = "https://usetova.designhuddle.com/editor"; // ?project_id=iframeProjectId&token=tokenvalue

export const VP_PRICING_API_SET_COUNT = 3; // VISTA PRINT PRICING API SET COUNT 



