import { createAsyncThunk } from '@reduxjs/toolkit';
import {  post } from './apiService';
import {  SA_API_URL_STAFF_REG_WITHOUT_EMAIL } from '../config/api_urls';
import { IStaffRegWithoutEmail } from '../models/staffRegisterWithoutEmailInterfaces';

// FUNCTION FOR STAFF REGISTERATION WITHOUT EMAIL
export const staffRegWithoutEmail = createAsyncThunk(
  'staff/staffRegWithoutEmail',
  async (staffRegForm: IStaffRegWithoutEmail, thunkAPI: any) => {
    try {
      let url = SA_API_URL_STAFF_REG_WITHOUT_EMAIL;
      const response = post(url, staffRegForm);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
