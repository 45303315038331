import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Chart } from "react-google-charts";
import dayjs from "dayjs";

import img_export from "../../assets/images/pajamas_export.svg";
import img_up_arrow from "../../assets/images/icon_arrow_up.svg";
import img_down_arrow from "../../assets/images/icon_arrow_down_red.svg";
import FilterSection from "../../components/FilterSection";
import { FilterAllAccountListSlice } from "../../components/FilterAccount/FilterAllAccountListSlice";

import { PAGENAME_DASHBOARD } from "../../config/env";

import { fetchAllAccountList } from "../../services/headerService";
import { exportChartData, fetchChartData } from "../../services/dashboardServices";
import { getAdminUserRole, getFilterAccountId, setLastActivePageAPIData } from "../../services/localStorage";

import { IDashboardChartRequestData, IFromToDatesPrev, IGroupIdNames, IStaffIdNames } from "../../models/dashboardInterfaces";

import { useAppDispatch, useAppSelector } from "../../helpers/hook";

import { selectDashboardSlice } from "./dashboardSlice";
import { IAccListAllReq } from "../../models/accountsInterfaces";

// USE FOR RELOAD DATA ON PAGE REFRESH - NOT IMPLEMENTED 
// interface CustomizedState {
//   prevPage: number;
//   prevSearch: string;
//   prevActive: string;
//   prevFromDate: string;
//   prevToDate: string;
//   prevDateFilterType: string;
//   prevSort: string;
//   prevNumRowsPerPage: number;
// }

function DashBoard() {
  const pageName = PAGENAME_DASHBOARD;
  const accountId = getFilterAccountId();
  const adminUserRole = getAdminUserRole();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { chartDetails, loading } = useAppSelector(selectDashboardSlice);
  const { allAccountList } = useAppSelector(FilterAllAccountListSlice);

  const CHART_ON_VOLUME = "tipVolume";
  const CHART_ON_COUNT = "tipCount";

  const CHART_TYPE_LINE = "LineChart";
  const CHART_TYPE_COLUMN = "ColumnChart";

  const CHART_PERIOD_WEEK = "week";
  const CHART_PERIOD_QUARTER = "quarter";
  const CHART_PERIOD_3MONTHS = "3months";
  const CHART_PERIOD_BIANNUAL = "biannual";
  const CHART_PERIOD_YEAR = "year";

  const [chartType, setChartType] = useState(CHART_TYPE_LINE);
  const [chartPeriod, setChartPeriod] = useState(CHART_PERIOD_3MONTHS);
  const [chartPeriodCount, setChartPeriodCount] = useState(0);
  const [selectedAccountIds, setSelectedAccountIds] = useState("");
  const [selectedGroupIds, setSelectedGroupIds] = useState("");
  const [selectedStaffIds, setSelectedStaffIds] = useState("");
  const [filterAccGroupStaffAllSelected, setFilterAccGroupStaffAllSelected] = useState(true);
  const [filterGroupIdNames, setFilterGroupIdNames] = useState<IGroupIdNames[]>([]);
  const [filterStaffIdNames, setFilterStaffIdNames] = useState<IStaffIdNames[]>([]);

  const [chartBasedOn, setChartBasedOn] = useState(CHART_ON_VOLUME);
  const [chartTotal, setChartTotal] = useState(0);
  const [chartDiffPercentage, setChartDiffPercentage] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [isLastClickOnNext, setIsLastClickOnNext] = useState(false);

  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [previousDateStart, setPreviousDateStart] = useState('');
  const [previousDateEnd, setPreviousDateEnd] = useState('');

  // USED TO SET ACCOUNT FILTER SELECTION ON CHANGE ACCOUNT IN NAVIGATION BAR  
  const [acceptAccountIdFromDashboard, setAcceptAccountIdFromDashboard] = useState("");

  useEffect(() => {
    if (accountId) {
      setSelectedAccountIds(accountId);
      setAcceptAccountIdFromDashboard(accountId);
    }
  }, [accountId])

  useEffect(() => {
    if (accountId) {
      setSelectedAccountIds(accountId);
    }
    let apiData: IAccListAllReq = {
      searchString: "",
      userRole: Number(adminUserRole)
    }
    dispatch(fetchAllAccountList(apiData));
  }, [dispatch]);

  const getNameById = (idValue: string, idType: string) => {
    if (idType == "account") {
      if (allAccountList) {
        let dataDet = allAccountList.filter(item => Number(item.value) === Number(idValue));
        return (dataDet && dataDet.length && dataDet[0].label ? dataDet[0].label : "");
      }
      else {
        return ("");
      }
    }
    else if (idType == "group") {
      if (filterGroupIdNames) {
        console.log('filterGroupIdNames:', JSON.stringify(filterGroupIdNames));
        console.log('idValue:', JSON.stringify(idValue));
        let dataDet = filterGroupIdNames.filter(item => Number(item.groupId) === Number(idValue));
        console.log('dataDet:', dataDet);
        return (dataDet && dataDet.length && dataDet[0].groupName ? dataDet[0].groupName : "");
      }
      else {
        return ("");
      }
    }
    else if (idType == "staff") {
      if (filterStaffIdNames) {
        let dataDet = filterStaffIdNames.filter(item => Number(item.staffId) === Number(idValue));
        return (dataDet[0].staffName ? dataDet[0].staffName : "");
      }
      else {
        return ("");
      }
    }
  };

  const getFromToDates = (periodType: string, nextPrevCount: number) => {
    const today = new Date();
    // const countValue = Math.abs(nextPrevCount); 
    if (periodType == CHART_PERIOD_WEEK) {
      let datVar = today;
      if (nextPrevCount == 0) {
        const chosenDate = new Date(datVar);
        const startDate = new Date(chosenDate);
        startDate.setDate(chosenDate.getDate() - chosenDate.getDay());
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);

        const prevStartDate = new Date(startDate);
        prevStartDate.setDate(startDate.getDate() - 7);
        const prevEndDate = new Date(endDate);
        prevEndDate.setDate(endDate.getDate() - 7);

        setDateStart(dayjs(startDate).format(`YYYY-MM-DD`));
        setDateEnd(dayjs(endDate).format(`YYYY-MM-DD`));
        setPreviousDateStart(dayjs(prevStartDate).format(`YYYY-MM-DD`));
        setPreviousDateEnd(dayjs(prevEndDate).format(`YYYY-MM-DD`));

        let fromToDates: IFromToDatesPrev = {
          fromDate: dayjs(startDate).format(`YYYY-MM-DD`),
          toDate: dayjs(endDate).format(`YYYY-MM-DD`),
          prevFromDate: dayjs(prevStartDate).format(`YYYY-MM-DD`),
          prevToDate: dayjs(prevEndDate).format(`YYYY-MM-DD`)
        }
        return (fromToDates);
      }
      else if (dateStart != "" && previousDateStart != "") {
        if (isLastClickOnNext) {
          datVar = new Date(dateStart);

          const chosenDate = new Date(datVar);
          const prevStartDate = new Date(chosenDate);
          prevStartDate.setDate(chosenDate.getDate() - chosenDate.getDay());
          const prevEndDate = new Date(prevStartDate);
          prevEndDate.setDate(prevStartDate.getDate() + 6);

          const startDate = new Date(prevStartDate);
          startDate.setDate(prevStartDate.getDate() + 7);
          const endDate = new Date(prevEndDate);
          endDate.setDate(prevEndDate.getDate() + 7);

          setDateStart(dayjs(startDate).format(`YYYY-MM-DD`));
          setDateEnd(dayjs(endDate).format(`YYYY-MM-DD`));
          setPreviousDateStart(dayjs(prevStartDate).format(`YYYY-MM-DD`));
          setPreviousDateEnd(dayjs(prevEndDate).format(`YYYY-MM-DD`));

          let fromToDates: IFromToDatesPrev = {
            fromDate: dayjs(startDate).format(`YYYY-MM-DD`),
            toDate: dayjs(endDate).format(`YYYY-MM-DD`),
            prevFromDate: dayjs(prevStartDate).format(`YYYY-MM-DD`),
            prevToDate: dayjs(prevEndDate).format(`YYYY-MM-DD`)
          }
          return (fromToDates);
        }
        else {
          datVar = new Date(previousDateStart);
          const chosenDate = new Date(datVar);
          const startDate = new Date(chosenDate);
          startDate.setDate(chosenDate.getDate() - chosenDate.getDay());
          const endDate = new Date(startDate);
          endDate.setDate(startDate.getDate() + 6);

          const prevStartDate = new Date(startDate);
          prevStartDate.setDate(startDate.getDate() - 7);
          const prevEndDate = new Date(endDate);
          prevEndDate.setDate(endDate.getDate() - 7);

          setDateStart(dayjs(startDate).format(`YYYY-MM-DD`));
          setDateEnd(dayjs(endDate).format(`YYYY-MM-DD`));
          setPreviousDateStart(dayjs(prevStartDate).format(`YYYY-MM-DD`));
          setPreviousDateEnd(dayjs(prevEndDate).format(`YYYY-MM-DD`));

          let fromToDates: IFromToDatesPrev = {
            fromDate: dayjs(startDate).format(`YYYY-MM-DD`),
            toDate: dayjs(endDate).format(`YYYY-MM-DD`),
            prevFromDate: dayjs(prevStartDate).format(`YYYY-MM-DD`),
            prevToDate: dayjs(prevEndDate).format(`YYYY-MM-DD`)
          }
          return (fromToDates);
        }
      }
    }
    else if (periodType == CHART_PERIOD_3MONTHS) {
      let datVar = today;
      if (nextPrevCount == 0) {
        const endDate = new Date(datVar);
        endDate.setDate(1); // Set the date to the 1st day of the current month
        endDate.setHours(0, 0, 0, 0); // Set time to the beginning of the day

        const startDate = new Date(endDate);
        startDate.setMonth(endDate.getMonth() - 2); // Subtract 3 months
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(0); // Set the date to the last day of the previous month

        const prevDate = new Date(dayjs(startDate).format(`YYYY-MM-DD`));
        prevDate.setMonth(prevDate.getMonth());

        const prevEndDate = new Date(prevDate);
        prevEndDate.setDate(1); // Set the date to the 1st day of the current month
        prevEndDate.setHours(0, 0, 0, 0); // Set time to the beginning of the day

        const prevStartDate = new Date(prevEndDate);
        prevStartDate.setMonth(prevEndDate.getMonth() - 3); // Subtract 3 months
        prevEndDate.setDate(0); // Set the date to the last day of the previous month

        setDateStart(dayjs(startDate).format(`YYYY-MM-DD`));
        setDateEnd(dayjs(endDate).format(`YYYY-MM-DD`));
        setPreviousDateStart(dayjs(prevStartDate).format(`YYYY-MM-DD`));
        setPreviousDateEnd(dayjs(prevEndDate).format(`YYYY-MM-DD`));

        let fromToDates: IFromToDatesPrev = {
          fromDate: dayjs(startDate).format(`YYYY-MM-DD`),
          toDate: dayjs(endDate).format(`YYYY-MM-DD`),
          prevFromDate: dayjs(prevStartDate).format(`YYYY-MM-DD`),
          prevToDate: dayjs(prevEndDate).format(`YYYY-MM-DD`)
        }
        return (fromToDates);
      }
      else if (dateStart != "" && previousDateStart != "") {
        if (isLastClickOnNext) {
          const prevStartDate = new Date(dateStart);
          const prevEndDate = new Date(dateEnd);
          setPreviousDateStart(dayjs(prevStartDate).format(`YYYY-MM-DD`));
          setPreviousDateEnd(dayjs(prevEndDate).format(`YYYY-MM-DD`));

          const nextDate = new Date(dayjs(prevEndDate).format(`YYYY-MM-DD`));
          nextDate.setDate(20);
          nextDate.setMonth(nextDate.getMonth() + 1);
          const startDate = new Date(nextDate);
          startDate.setDate(1); // Set the date to the 1st day of the current month
          startDate.setHours(0, 0, 0, 0); // Set time to the beginning of the day

          const endDate = new Date(startDate);
          endDate.setMonth(endDate.getMonth() + 3); // Subtract 3 months
          endDate.setDate(0); // Set the date to the last day of the previous month

          setDateStart(dayjs(startDate).format(`YYYY-MM-DD`));
          setDateEnd(dayjs(endDate).format(`YYYY-MM-DD`));

          let fromToDates: IFromToDatesPrev = {
            fromDate: dayjs(startDate).format(`YYYY-MM-DD`),
            toDate: dayjs(endDate).format(`YYYY-MM-DD`),
            prevFromDate: dayjs(prevStartDate).format(`YYYY-MM-DD`),
            prevToDate: dayjs(prevEndDate).format(`YYYY-MM-DD`)
          }
          return (fromToDates);

        }
        else {
          const startDate = new Date(previousDateStart);
          const endDate = new Date(previousDateEnd);
          setDateStart(dayjs(startDate).format(`YYYY-MM-DD`));
          setDateEnd(dayjs(endDate).format(`YYYY-MM-DD`));

          const prevDate = new Date(dayjs(previousDateStart).format(`YYYY-MM-DD`));
          prevDate.setMonth(prevDate.getMonth());

          const prevEndDate = new Date(prevDate);
          prevEndDate.setDate(1); // Set the date to the 1st day of the current month
          prevEndDate.setHours(0, 0, 0, 0); // Set time to the beginning of the day

          const prevStartDate = new Date(prevEndDate);
          prevStartDate.setMonth(prevEndDate.getMonth() - 3); // Subtract 3 months
          prevEndDate.setDate(0); // Set the date to the last day of the previous month

          setPreviousDateStart(dayjs(prevStartDate).format(`YYYY-MM-DD`));
          setPreviousDateEnd(dayjs(prevEndDate).format(`YYYY-MM-DD`));

          let fromToDates: IFromToDatesPrev = {
            fromDate: dayjs(startDate).format(`YYYY-MM-DD`),
            toDate: dayjs(endDate).format(`YYYY-MM-DD`),
            prevFromDate: dayjs(prevStartDate).format(`YYYY-MM-DD`),
            prevToDate: dayjs(prevEndDate).format(`YYYY-MM-DD`)
          }
          return (fromToDates);
        }
      }
    }
    else if (periodType == CHART_PERIOD_QUARTER) {
      let datVar = today;
      if (nextPrevCount == 0) {
        // Calculate the current quarter's start and end dates
        const currentQuarter = Math.floor((datVar.getMonth() + 3) / 3);
        const currentQuarterStart = new Date(datVar.getFullYear(), (currentQuarter - 1) * 3, 1);
        const currentQuarterEnd = new Date(datVar.getFullYear(), currentQuarter * 3, 0);

        // Calculate the previous quarter's start and end dates
        const previousQuarter = currentQuarter - 1 > 0 ? currentQuarter - 1 : 4;
        const previousQuarterStart = new Date(datVar.getFullYear(), (previousQuarter - 1) * 3, 1);
        const previousQuarterEnd = new Date(datVar.getFullYear(), previousQuarter * 3, 0);

        setDateStart(dayjs(currentQuarterStart).format(`YYYY-MM-DD`));
        setDateEnd(dayjs(currentQuarterEnd).format(`YYYY-MM-DD`));
        setPreviousDateStart(dayjs(previousQuarterStart).format(`YYYY-MM-DD`));
        setPreviousDateEnd(dayjs(previousQuarterEnd).format(`YYYY-MM-DD`));

        let fromToDates: IFromToDatesPrev = {
          fromDate: dayjs(currentQuarterStart).format(`YYYY-MM-DD`),
          toDate: dayjs(currentQuarterEnd).format(`YYYY-MM-DD`),
          prevFromDate: dayjs(previousQuarterStart).format(`YYYY-MM-DD`),
          prevToDate: dayjs(previousQuarterEnd).format(`YYYY-MM-DD`)
        }
        return (fromToDates);
      }
      else if (dateStart != "" && previousDateStart != "") {
        if (isLastClickOnNext) {
          datVar = new Date(dateStart);

          const quarter = Math.floor((datVar.getMonth() / 3));

          const currentQuarterStart = new Date(datVar.getFullYear(), quarter * 3, 1);
          const currentQuarterEnd = new Date(currentQuarterStart.getFullYear(), currentQuarterStart.getMonth() + 3, 0);

          const nextQuarterStart = new Date(datVar.getFullYear(), quarter * 3 + 3, 1);
          const nextQuarterEnd = new Date(nextQuarterStart.getFullYear(), nextQuarterStart.getMonth() + 3, 0);

          setDateStart(dayjs(nextQuarterStart).format(`YYYY-MM-DD`));
          setDateEnd(dayjs(nextQuarterEnd).format(`YYYY-MM-DD`));
          setPreviousDateStart(dayjs(currentQuarterStart).format(`YYYY-MM-DD`));
          setPreviousDateEnd(dayjs(currentQuarterEnd).format(`YYYY-MM-DD`));

          let fromToDates: IFromToDatesPrev = {
            fromDate: dayjs(nextQuarterStart).format(`YYYY-MM-DD`),
            toDate: dayjs(nextQuarterEnd).format(`YYYY-MM-DD`),
            prevFromDate: dayjs(currentQuarterStart).format(`YYYY-MM-DD`),
            prevToDate: dayjs(currentQuarterEnd).format(`YYYY-MM-DD`)
          }
          return (fromToDates);
        }
        else {
          datVar = new Date(previousDateStart);

          const quarter = Math.floor((datVar.getMonth() / 3));

          const currentQuarterStart = new Date(datVar.getFullYear(), quarter * 3, 1);
          const currentQuarterEnd = new Date(currentQuarterStart.getFullYear(), currentQuarterStart.getMonth() + 3, 0);

          const previousQuarterStart = new Date(datVar.getFullYear(), quarter * 3 - 3, 1);
          const previousQuarterEnd = new Date(previousQuarterStart.getFullYear(), previousQuarterStart.getMonth() + 3, 0);

          setDateStart(dayjs(currentQuarterStart).format(`YYYY-MM-DD`));
          setDateEnd(dayjs(currentQuarterEnd).format(`YYYY-MM-DD`));
          setPreviousDateStart(dayjs(previousQuarterStart).format(`YYYY-MM-DD`));
          setPreviousDateEnd(dayjs(previousQuarterEnd).format(`YYYY-MM-DD`));

          let fromToDates: IFromToDatesPrev = {
            fromDate: dayjs(currentQuarterStart).format(`YYYY-MM-DD`),
            toDate: dayjs(currentQuarterEnd).format(`YYYY-MM-DD`),
            prevFromDate: dayjs(previousQuarterStart).format(`YYYY-MM-DD`),
            prevToDate: dayjs(previousQuarterEnd).format(`YYYY-MM-DD`)
          }
          return (fromToDates);
        }
      }
    }
    else if (periodType == CHART_PERIOD_BIANNUAL) {
      let datVar = today;
      if (nextPrevCount == 0) {
        // const dateParts = givenDate.split('-').map(part => parseInt(part, 10));
        const year = datVar.getFullYear();
        const month = datVar.getMonth();

        let currentHalfYearStart, currentHalfYearEnd, previousHalfYearStart, previousHalfYearEnd;

        if (month <= 5) {
          currentHalfYearStart = new Date(year, 0, 1);
          currentHalfYearEnd = new Date(year, 5, 30);
          previousHalfYearStart = new Date(year - 1, 6, 1);
          previousHalfYearEnd = new Date(year - 1, 11, 31);
        } else {
          currentHalfYearStart = new Date(year, 6, 1);
          currentHalfYearEnd = new Date(year, 11, 31);
          previousHalfYearStart = new Date(year, 0, 1);
          previousHalfYearEnd = new Date(year, 5, 30);
        }

        setDateStart(dayjs(currentHalfYearStart).format(`YYYY-MM-DD`));
        setDateEnd(dayjs(currentHalfYearEnd).format(`YYYY-MM-DD`));
        setPreviousDateStart(dayjs(previousHalfYearStart).format(`YYYY-MM-DD`));
        setPreviousDateEnd(dayjs(previousHalfYearEnd).format(`YYYY-MM-DD`));

        let fromToDates: IFromToDatesPrev = {
          fromDate: dayjs(currentHalfYearStart).format(`YYYY-MM-DD`),
          toDate: dayjs(currentHalfYearEnd).format(`YYYY-MM-DD`),
          prevFromDate: dayjs(previousHalfYearStart).format(`YYYY-MM-DD`),
          prevToDate: dayjs(previousHalfYearEnd).format(`YYYY-MM-DD`)
        }
        return (fromToDates);
      }
      else if (dateStart != "" && previousDateStart != "") {
        if (isLastClickOnNext) {
          datVar = new Date(dateStart);
          const year = datVar.getFullYear();
          const month = datVar.getMonth();

          let currentHalfYearStart, currentHalfYearEnd, previousHalfYearStart, previousHalfYearEnd;

          if (month <= 5) {
            previousHalfYearStart = new Date(year, 0, 1);
            previousHalfYearEnd = new Date(year, 5, 30);
            currentHalfYearStart = new Date(year, 6, 1);
            currentHalfYearEnd = new Date(year, 11, 31);
          } else {
            previousHalfYearStart = new Date(year, 6, 1);
            previousHalfYearEnd = new Date(year, 11, 31);
            currentHalfYearStart = new Date(year + 1, 0, 1);
            currentHalfYearEnd = new Date(year + 1, 5, 30);
          }

          setDateStart(dayjs(currentHalfYearStart).format(`YYYY-MM-DD`));
          setDateEnd(dayjs(currentHalfYearEnd).format(`YYYY-MM-DD`));
          setPreviousDateStart(dayjs(previousHalfYearStart).format(`YYYY-MM-DD`));
          setPreviousDateEnd(dayjs(previousHalfYearEnd).format(`YYYY-MM-DD`));

          let fromToDates: IFromToDatesPrev = {
            fromDate: dayjs(currentHalfYearStart).format(`YYYY-MM-DD`),
            toDate: dayjs(currentHalfYearEnd).format(`YYYY-MM-DD`),
            prevFromDate: dayjs(previousHalfYearStart).format(`YYYY-MM-DD`),
            prevToDate: dayjs(previousHalfYearEnd).format(`YYYY-MM-DD`)
          }
          return (fromToDates);
        }
        else {
          datVar = new Date(previousDateStart);

          const year = datVar.getFullYear();
          const month = datVar.getMonth();

          let currentHalfYearStart, currentHalfYearEnd, previousHalfYearStart, previousHalfYearEnd;

          if (month <= 5) {
            currentHalfYearStart = new Date(year, 0, 1);
            currentHalfYearEnd = new Date(year, 5, 30);
            previousHalfYearStart = new Date(year - 1, 6, 1);
            previousHalfYearEnd = new Date(year - 1, 11, 31);
          } else {
            currentHalfYearStart = new Date(year, 6, 1);
            currentHalfYearEnd = new Date(year, 11, 31);
            previousHalfYearStart = new Date(year, 0, 1);
            previousHalfYearEnd = new Date(year, 5, 30);
          }

          setDateStart(dayjs(currentHalfYearStart).format(`YYYY-MM-DD`));
          setDateEnd(dayjs(currentHalfYearEnd).format(`YYYY-MM-DD`));
          setPreviousDateStart(dayjs(previousHalfYearStart).format(`YYYY-MM-DD`));
          setPreviousDateEnd(dayjs(previousHalfYearEnd).format(`YYYY-MM-DD`));

          let fromToDates: IFromToDatesPrev = {
            fromDate: dayjs(currentHalfYearStart).format(`YYYY-MM-DD`),
            toDate: dayjs(currentHalfYearEnd).format(`YYYY-MM-DD`),
            prevFromDate: dayjs(previousHalfYearStart).format(`YYYY-MM-DD`),
            prevToDate: dayjs(previousHalfYearEnd).format(`YYYY-MM-DD`)
          }
          return (fromToDates);
        }
      }
    }
    else if (periodType == CHART_PERIOD_YEAR) {
      let datVar = today;
      if (nextPrevCount == 0) {
        // const dateParts = givenDate.split('-').map(part => parseInt(part, 10));
        const year = datVar.getFullYear();

        let currentYearStart, currentYearEnd, previousYearStart, previousYearEnd;
        currentYearStart = (year).toString() + "-01-01";
        currentYearEnd = (year).toString() + "-12-31";
        previousYearStart = (year - 1).toString() + "-01-01";
        previousYearEnd = (year - 1).toString() + "-12-31";

        setDateStart(dayjs(currentYearStart).format(`YYYY-MM-DD`));
        setDateEnd(dayjs(currentYearEnd).format(`YYYY-MM-DD`));
        setPreviousDateStart(dayjs(previousYearStart).format(`YYYY-MM-DD`));
        setPreviousDateEnd(dayjs(previousYearEnd).format(`YYYY-MM-DD`));

        let fromToDates: IFromToDatesPrev = {
          fromDate: dayjs(currentYearStart).format(`YYYY-MM-DD`),
          toDate: dayjs(currentYearEnd).format(`YYYY-MM-DD`),
          prevFromDate: dayjs(previousYearStart).format(`YYYY-MM-DD`),
          prevToDate: dayjs(previousYearEnd).format(`YYYY-MM-DD`)
        }
        return (fromToDates);
      }
      else if (dateStart != "" && previousDateStart != "") {
        if (isLastClickOnNext) {
          datVar = new Date(dateStart);
          const year = datVar.getFullYear();
          let currentYearStart, currentYearEnd, previousYearStart, previousYearEnd;
          currentYearStart = (year + 1).toString() + "-01-01";
          currentYearEnd = (year + 1).toString() + "-12-31";
          previousYearStart = (year).toString() + "-01-01";
          previousYearEnd = (year).toString() + "-12-31";

          setDateStart(dayjs(currentYearStart).format(`YYYY-MM-DD`));
          setDateEnd(dayjs(currentYearEnd).format(`YYYY-MM-DD`));
          setPreviousDateStart(dayjs(previousYearStart).format(`YYYY-MM-DD`));
          setPreviousDateEnd(dayjs(previousYearEnd).format(`YYYY-MM-DD`));

          let fromToDates: IFromToDatesPrev = {
            fromDate: dayjs(currentYearStart).format(`YYYY-MM-DD`),
            toDate: dayjs(currentYearEnd).format(`YYYY-MM-DD`),
            prevFromDate: dayjs(previousYearStart).format(`YYYY-MM-DD`),
            prevToDate: dayjs(previousYearEnd).format(`YYYY-MM-DD`)
          }
          return (fromToDates);
        }
        else {
          datVar = new Date(previousDateStart);
          const year = datVar.getFullYear();
          let currentYearStart, currentYearEnd, previousYearStart, previousYearEnd;
          currentYearStart = (year).toString() + "-01-01";
          currentYearEnd = (year).toString() + "-12-31";
          previousYearStart = (year - 1).toString() + "-01-01";
          previousYearEnd = (year - 1).toString() + "-12-31";

          setDateStart(dayjs(currentYearStart).format(`YYYY-MM-DD`));
          setDateEnd(dayjs(currentYearEnd).format(`YYYY-MM-DD`));
          setPreviousDateStart(dayjs(previousYearStart).format(`YYYY-MM-DD`));
          setPreviousDateEnd(dayjs(previousYearEnd).format(`YYYY-MM-DD`));

          let fromToDates: IFromToDatesPrev = {
            fromDate: dayjs(currentYearStart).format(`YYYY-MM-DD`),
            toDate: dayjs(currentYearEnd).format(`YYYY-MM-DD`),
            prevFromDate: dayjs(previousYearStart).format(`YYYY-MM-DD`),
            prevToDate: dayjs(previousYearEnd).format(`YYYY-MM-DD`)
          }
          return (fromToDates);
        }
      }
    }
  }

  const fetchDashboardChartData = () => {
    let startEndDates: IFromToDatesPrev = getFromToDates(chartPeriod, chartPeriodCount);
    console.log('selectedAccountIds:' + selectedAccountIds);
    if (selectedAccountIds.trim().length && startEndDates && startEndDates?.fromDate) {
      console.log('startEndDates: ', startEndDates);
      let grpIds = selectedGroupIds;
      let stfIds = selectedStaffIds;
      let arrAcc = selectedAccountIds.split(",");
      if (filterAccGroupStaffAllSelected || arrAcc.length != 1) {
        grpIds = "";
        stfIds = "";
      }
      const req: IDashboardChartRequestData = {
        userRole: Number(adminUserRole),
        chartFor: chartBasedOn,
        chartPeriod: chartPeriod,
        chartPeriodFrom: dayjs(startEndDates.fromDate).format(`YYYY-MM-DDTHH:mm:ssZZ`),
        chartPeriodTo: dayjs(startEndDates.toDate).format(`YYYY-MM-DDT23:59:59ZZ`),
        chartPrevPeriodFrom: dayjs(startEndDates.prevFromDate).format(`YYYY-MM-DDTHH:mm:ssZZ`),
        chartPrevPeriodTo: dayjs(startEndDates.prevToDate).format(`YYYY-MM-DDT23:59:59ZZ`),
        accountIds: selectedAccountIds,
        groupIds: grpIds,
        staffIds: stfIds
      };
      // groupIds: selectedAccountIds && selectedAccountIds=="all" ? "" : selectedGroupIds,
      // staffIds: selectedAccountIds && selectedAccountIds=="all" ? "" : selectedStaffIds
      dispatch(fetchChartData(req));
      setLastActivePageAPIData({ pageName: pageName, apiData: req });
    }
  };

  const onExportButton = () => {
    let startEndDates: IFromToDatesPrev = getFromToDates(chartPeriod, chartPeriodCount);
    console.log('selectedAccountIds:' + selectedAccountIds);
    if (selectedAccountIds.trim().length && startEndDates && startEndDates?.fromDate) {
      console.log('startEndDates: ', startEndDates);
      let grpIds = selectedGroupIds;
      let stfIds = selectedStaffIds;
      let arrAcc = selectedAccountIds.split(",");
      if (filterAccGroupStaffAllSelected || arrAcc.length != 1) {
        grpIds = "";
        stfIds = "";
      }
      const req: IDashboardChartRequestData = {
        userRole: Number(adminUserRole),
        chartFor: chartBasedOn,
        chartPeriod: chartPeriod,
        chartPeriodFrom: dayjs(startEndDates.fromDate).format(`YYYY-MM-DDTHH:mm:ssZZ`),
        chartPeriodTo: dayjs(startEndDates.toDate).format(`YYYY-MM-DDT23:59:59ZZ`),
        chartPrevPeriodFrom: dayjs(startEndDates.prevFromDate).format(`YYYY-MM-DDTHH:mm:ssZZ`),
        chartPrevPeriodTo: dayjs(startEndDates.prevToDate).format(`YYYY-MM-DDT23:59:59ZZ`),
        accountIds: selectedAccountIds,
        groupIds: grpIds,
        staffIds: stfIds
      };
      // groupIds: selectedAccountIds && selectedAccountIds=="all" ? "" : selectedGroupIds,
      // staffIds: selectedAccountIds && selectedAccountIds=="all" ? "" : selectedStaffIds
      exportChartData(req);
    }

  };

  useEffect(() => {
    if (selectedAccountIds.trim().length) {
      fetchDashboardChartData();
    }
  }, [selectedAccountIds, chartPeriodCount, chartBasedOn, chartPeriod]);

  // CALLED ON 'APPLY BUTTON' OF ACCOUNTS FILTER FROM FilterSection PAGE
  const setSelectedAccountIdsFilter = (accIds: string) => {
    // MAKE GROUP AND STAFF IDS BLANK 
    setSelectedGroupIds("");
    setSelectedStaffIds("");
    setFilterAccGroupStaffAllSelected(true);
    setSelectedAccountIds(accIds);
  }

  // CALLED ON 'APPLY BUTTON' OF ACCOUNTS - GROUP FILTER FROM FilterSection PAGE
  const setGroupStaffIdsFilter = (accountId: string, isAllSelected: boolean, groupIds: string, staffIds: string, groupIdNames: IGroupIdNames[], staffIdNames: IStaffIdNames[]) => {
    setSelectedGroupIds(groupIds);
    setSelectedStaffIds(staffIds);
    setFilterAccGroupStaffAllSelected(isAllSelected);
    setFilterGroupIdNames(groupIdNames);
    setFilterStaffIdNames(staffIdNames);
    setSelectedAccountIds("");
    setTimeout(() => {
      setSelectedAccountIds(accountId);
    }, 500);
  }

  const dummyFunction = () => {
    console.log('dummy');
  }

  // TO FORMAT API DATA TO CHART DATA STRUCTURE 
  useEffect(() => {
    if (loading == "succeeded") {
      setChartTotal(chartDetails.totalTipVolume ? chartDetails.totalTipVolume : 0)
      setChartDiffPercentage(chartDetails.prevDiffPercentage ? chartDetails.prevDiffPercentage : 0);

      let hText = chartPeriod == CHART_PERIOD_WEEK ? t("dashboard.week") :
        chartPeriod == CHART_PERIOD_QUARTER ? t("dashboard.quarter") :
          chartPeriod == CHART_PERIOD_3MONTHS ? t("dashboard.3months") :
            chartPeriod == CHART_PERIOD_BIANNUAL ? t("dashboard.biannual") :
              chartPeriod == CHART_PERIOD_YEAR ? t("dashboard.year") : "";
      let arrHeader = [hText];
      let arrCount = 0;
      let arrayChartData = [];
      let accountsData = chartDetails.accounts;
      let groupsData = chartDetails.groups;
      let staffData = chartDetails.staff;
      if (Object.keys(accountsData).length) {
        for (const accId in accountsData) {
          arrCount++;
          if (accId.toString() == "all") { // ALL ACCOUNTS
            arrHeader.push(t("dashboard.allAccounts"));
          } else {
            arrHeader.push(getNameById(accId.toString(), "account"));
          }
          // console.log(`${accId}: ${accountsData[accId]}`);
          let accChartData = accountsData[accId];
          for (const dateKey in accChartData) {
            let tmpDateStr = "";
            let arrTmp = dateKey.split('_');
            if (chartPeriod == CHART_PERIOD_WEEK) {
              tmpDateStr = dayjs((arrTmp[0] + "-" + arrTmp[1] + "-" + arrTmp[2])).format(`DD MMM YYYY`);
            }
            else if (chartPeriod == CHART_PERIOD_QUARTER || chartPeriod == CHART_PERIOD_3MONTHS || chartPeriod == CHART_PERIOD_BIANNUAL || chartPeriod == CHART_PERIOD_YEAR) {
              tmpDateStr = dayjs((arrTmp[0] + "-" + arrTmp[1] + "-01")).format(`MMM YYYY`);
            }
            if (arrCount == 1) {
              arrayChartData.push([(tmpDateStr ? tmpDateStr : ""), (accChartData[dateKey] ? accChartData[dateKey] : 0)])
            }
            else {
              let tmpArr = arrayChartData;
              tmpArr.map((acItem, index) => {
                if (acItem[0] == tmpDateStr && arrayChartData[index]) {
                  arrayChartData[index].push((accChartData[dateKey] ? accChartData[dateKey] : 0));
                }
              })
            }
          }
        }
      }
      else {
        if (Object.keys(groupsData).length) {
          arrCount = 0;
          for (const groupId in groupsData) {
            arrCount++;
            if (groupId.toString() == "all") { // ALL ACCOUNTS
              arrHeader.push(t("dashboard.allAccounts"));
            } else {
              arrHeader.push(getNameById(groupId.toString(), "group"));
            }
            // console.log(`${groupId}: ${accountsData[groupId]}`);
            let groupChartData = groupsData[groupId];
            for (const dateKey in groupChartData) {
              let tmpDateStr = "";
              let arrTmp = dateKey.split('_');
              if (chartPeriod == CHART_PERIOD_WEEK) {
                tmpDateStr = dayjs((arrTmp[0] + "-" + arrTmp[1] + "-" + arrTmp[2])).format(`DD MMM YYYY`);
              }
              else if (chartPeriod == CHART_PERIOD_QUARTER || chartPeriod == CHART_PERIOD_3MONTHS || chartPeriod == CHART_PERIOD_BIANNUAL || chartPeriod == CHART_PERIOD_YEAR) {
                tmpDateStr = dayjs((arrTmp[0] + "-" + arrTmp[1] + "-01")).format(`MMM YYYY`);
              }
              if (arrCount == 1) {
                arrayChartData.push([(tmpDateStr ? tmpDateStr : ""), (groupChartData[dateKey] ? groupChartData[dateKey] : 0)])
              }
              else {
                let tmpArr = arrayChartData;
                tmpArr.map((acItem, index) => {
                  if (acItem[0] == tmpDateStr && arrayChartData[index]) {
                    arrayChartData[index].push((groupChartData[dateKey] ? groupChartData[dateKey] : 0));
                  }
                })

              }
              // console.log('dateKey:',dateKey+' : '+tmpDateStr);
              // console.log("groupChartData: ",groupChartData[dateKey]);
            }
          }
        }

        if (Object.keys(staffData).length) {
          for (const staffId in staffData) {
            arrCount++;
            if (staffId.toString() == "all") { // ALL ACCOUNTS
              arrHeader.push(t("dashboard.allAccounts"));
            } else {
              arrHeader.push(getNameById(staffId.toString(), "staff"));
            }
            // console.log(`${staffId}: ${accountsData[staffId]}`);
            let staffChartData = staffData[staffId];
            for (const dateKey in staffChartData) {
              let tmpDateStr = "";
              let arrTmp = dateKey.split('_');
              if (chartPeriod == CHART_PERIOD_WEEK) {
                tmpDateStr = dayjs((arrTmp[0] + "-" + arrTmp[1] + "-" + arrTmp[2])).format(`DD MMM YYYY`);
              }
              else if (chartPeriod == CHART_PERIOD_QUARTER || chartPeriod == CHART_PERIOD_3MONTHS || chartPeriod == CHART_PERIOD_BIANNUAL || chartPeriod == CHART_PERIOD_YEAR) {
                tmpDateStr = dayjs((arrTmp[0] + "-" + arrTmp[1] + "-01")).format(`MMM YYYY`);
              }
              if (arrCount == 1) {
                arrayChartData.push([(tmpDateStr ? tmpDateStr : ""), (staffChartData[dateKey] ? staffChartData[dateKey] : 0)])
              }
              else {
                let tmpArr = arrayChartData;
                tmpArr.map((acItem, index) => {
                  if (acItem[0] == tmpDateStr && arrayChartData[index]) {
                    arrayChartData[index].push((staffChartData[dateKey] ? staffChartData[dateKey] : 0));
                  }
                })

              }
              // console.log('dateKey:',dateKey+' : '+tmpDateStr);
              // console.log("groupChartData: ",groupChartData[dateKey]);
            }
          }
        }
      }

      arrayChartData.unshift(arrHeader); // INSERT HEADER ROW AT THE BIGINING OF THE ARRAY 

      setChartData(arrayChartData);

      // CHART DATA FORMAT NEEDED
      // [t("dashboard.quarter"), "VJ Arcade One", "VJ Arcade Two", "VJ Arcade Three"], 
      // ["Jul 2023", 112, 136, 123],
      // ["Aug 2023", 152, 176, 133], 
      // ["Sep 2023", 138, 143, 156],

      // CHART DATA RESPONSE FROM API 
      // {9:  {2023-07: 112, 2023-08: 152, 2023-09: 138}},
      // {11: {2023-07: 136, 2023-08: 176, 2023-09: 143}},
      // {14: {2023-07: 123, 2023-08: 133, 2023-09: 156}}

    }
  }, [loading]);

  // OPTIONS SETTINGS FOR THE CHART // function
  const options = {
    title: "",
    curveType: "",
    legend: { position: "top" },
  };

  return (
    <div className="box-padding">
      <div className="box">
        <div className="account ">

          <div className="space-between mb-5px">
            {/* <h1>
              <div>{t("dashboard.tipVolume")} <span className="mrl-10px inactive-line-light">|</span> <span className="inactive-text-light"> {t("dashboard.transactionVolume")}</span></div>
            </h1> */}
            <div>
              <div className="dashboard-header-box">
                <div className={chartBasedOn == CHART_ON_VOLUME ? "dashboard-header-text-box border-left-box min-width-178px dashboard-header-text-box-selected " : "dashboard-header-text-box border-left-box min-width-178px"} onClick={e => setChartBasedOn(CHART_ON_VOLUME)} >
                  {t("dashboard.tipVolume")}
                </div>
                <div className={chartBasedOn == CHART_ON_COUNT ? "dashboard-header-text-box border-right-box min-width-178px dashboard-header-text-box-selected " : "dashboard-header-text-box border-right-box min-width-178px"} onClick={e => setChartBasedOn(CHART_ON_COUNT)}>
                  {t("dashboard.transactionVolume")}
                </div>
              </div>
            </div>

            <div className="flex gap-5">
              <div className="dashboard-header-box">

                {/* **************** CHART FILTER - WEEK **************** */}
                <div className={chartPeriod == CHART_PERIOD_WEEK ? "dashboard-header-text-box border-left-box px-3 dashboard-header-text-box-selected " : "dashboard-header-text-box border-left-box px-3"} onClick={e => {
                  setChartPeriodCount(chartPeriod == CHART_PERIOD_WEEK ? chartPeriodCount : 0);
                  setChartPeriod(CHART_PERIOD_WEEK);
                }} >
                  {t("dashboard.week")}
                </div>

                {/* **************** CHART FILTER - 3MONTHS **************** */}
                <div className={chartPeriod == CHART_PERIOD_3MONTHS ? "dashboard-header-text-box border-right-0 border-radius-0 px-3 dashboard-header-text-box-selected " : "dashboard-header-text-box border-right-0 border-radius-0 px-3"} onClick={e => {
                  setChartPeriodCount(chartPeriod == CHART_PERIOD_3MONTHS ? chartPeriodCount : 0);
                  setChartPeriod(CHART_PERIOD_3MONTHS);
                }} >
                  {t("dashboard.3months")}
                </div>

                {/* **************** CHART FILTER - BIANNUAL **************** */}
                <div className={chartPeriod == CHART_PERIOD_BIANNUAL ? "dashboard-header-text-box border-right-0 border-radius-0 px-3 dashboard-header-text-box-selected " : "dashboard-header-text-box border-radius-0 border-right-0 px-3"} onClick={e => {
                  setChartPeriodCount(chartPeriod == CHART_PERIOD_BIANNUAL ? chartPeriodCount : 0);
                  setChartPeriod(CHART_PERIOD_BIANNUAL);
                }} >
                  {t("dashboard.biannual")}
                </div>

                {/* **************** CHART FILTER - YEAR **************** */}
                <div className={chartPeriod == CHART_PERIOD_YEAR ? "dashboard-header-text-box border-right-box px-3 dashboard-header-text-box-selected " : "dashboard-header-text-box border-right-box px-3"} onClick={e => {
                  setChartPeriodCount(chartPeriod == CHART_PERIOD_YEAR ? chartPeriodCount : 0);
                  setChartPeriod(CHART_PERIOD_YEAR);
                }} >
                  {t("dashboard.year")}
                </div>
              </div>


              {/* <div className="mr-5px flex dashBoard-chart-filter-text" > 
                <div className={chartPeriod==CHART_PERIOD_WEEK ? "dashBoard-chart-filter-text dashBoard-chart-filter-active" : "dashBoard-chart-filter-text"} onClick={e => {
                  setChartPeriodCount(chartPeriod==CHART_PERIOD_WEEK ? chartPeriodCount : 0); 
                  setChartPeriod(CHART_PERIOD_WEEK);
                }}>
                  {t("dashboard.week")}
                </div>
              </div> 
              <div className="mr-5px flex dashBoard-chart-filter-text" >
                <div className={chartPeriod==CHART_PERIOD_3MONTHS ? "dashBoard-chart-filter-text dashBoard-chart-filter-active" : "dashBoard-chart-filter-text"} onClick={e => {
                  setChartPeriodCount(chartPeriod==CHART_PERIOD_3MONTHS ? chartPeriodCount : 0); 
                  setChartPeriod(CHART_PERIOD_3MONTHS);
                }}>
                  {t("dashboard.3months")}
                </div>
              </div> 
              <div className="mr-5px flex dashBoard-chart-filter-text" >
                <div className={chartPeriod==CHART_PERIOD_BIANNUAL ? "dashBoard-chart-filter-text dashBoard-chart-filter-active" : "dashBoard-chart-filter-text"} onClick={e => { 
                  setChartPeriodCount(chartPeriod==CHART_PERIOD_BIANNUAL ? chartPeriodCount : 0); 
                  setChartPeriod(CHART_PERIOD_BIANNUAL);
                }}>
                  {t("dashboard.biannual")}
                </div>
              </div>  
              <div className="mr-5px flex dashBoard-chart-filter-text" >
                <div className={chartPeriod==CHART_PERIOD_YEAR ? "dashBoard-chart-filter-text dashBoard-chart-filter-active" : "dashBoard-chart-filter-text"} onClick={e => {
                  setChartPeriodCount(chartPeriod==CHART_PERIOD_YEAR ? chartPeriodCount : 0); 
                  setChartPeriod(CHART_PERIOD_YEAR);
                }}>
                  {t("dashboard.year")}
                </div>
              </div> */}

              {/* **************** EXPORT BUTTON **************** */}
              <div>
                <button className="button-export" onClick={e => onExportButton()} >{t("buttons.export")} <img src={img_export} alt="up" className=" " /></button>
              </div>
            </div>
          </div>

          <div className="h-two">
            <div className="dashBoard-data-box">
              <div className="dashBoard-sub-header mt-2px">
                {chartBasedOn == CHART_ON_VOLUME ? t("dashboard.totalPayoutVolume") : t("dashboard.totalTransactionVolume")}
              </div>
              <div className="flex">
                <div className="dashBoard-volume-data mt-15px mb-5px ">
                  {chartBasedOn == CHART_ON_VOLUME && <> {t("common.dollar")}</>}{chartTotal}
                </div>
                <div className="dashBoard-percentage-text mt-12px">
                  <div className="ml-20px">
                    {(chartDiffPercentage && chartDiffPercentage > 0) ? <img src={img_up_arrow} alt="up" className="mb-1 " /> : <></>}
                    {(chartDiffPercentage < 0) && <img src={img_down_arrow} alt="down" className="mb-1" />}
                    <span className={chartDiffPercentage > 0 ? "dashBoard-percentage-green ml-1px" : chartDiffPercentage < 0 ? "dashBoard-percentage-red ml-1px" : " "}>{Math.abs(chartDiffPercentage)}%</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex">
              <div>
                <FilterSection
                  headind={""}
                  filterAccount={true}
                  filterGroup={true}
                  filterDate={false}
                  filterStatus={false}
                  filterPayee={false}
                  filterPayMethod={false}
                  searchInput={false}
                  setSelectedAccountIds={setSelectedAccountIdsFilter}
                  setGroupStaffIdsFilter={setGroupStaffIdsFilter}
                  parentPage={PAGENAME_DASHBOARD}
                  clearFilters={dummyFunction}
                  // USED TO SET ACCOUNT FILTER SELECTION ON CHANGE ACCOUNT IN NAVIGATION BAR 
                  acceptAccountIdFromDashboard={acceptAccountIdFromDashboard}
                />
              </div>
            </div>
            {/* <div className="dashBoard-data-box"></div> */}
          </div>
          <div className="h-two-no-border">

            {/* ***************** CHART TYPE - LINE/BAR ***************** */}
            <div className={"dashBoard-chart-type-box"} >
              <div className={chartType == CHART_TYPE_LINE ? "dashBoard-chart-type-item dashBoard-chart-type-right-border dashBoard-chart-type-box-selected" : "dashBoard-chart-type-item"} onClick={e => setChartType(CHART_TYPE_LINE)}>{t("dashboard.line")}</div>
              <div className={chartType == CHART_TYPE_COLUMN ? "dashBoard-chart-type-item dashBoard-chart-type-left-border dashBoard-chart-type-box-selected" : "dashBoard-chart-type-item"} onClick={e => setChartType(CHART_TYPE_COLUMN)}>
                {t("dashboard.bar")}
              </div>
            </div>

            {/* ***************** CHART NAVIGATION - PREV./NEXT ***************** */}
            {/* dashBoard-chart-type-box-selected */}
            <div className={"dashBoard-chart-type-box min-width-140px"} >
              <div className={isLastClickOnNext ? "dashBoard-chart-type-item min-width-70px" : "dashBoard-chart-type-item min-width-70px dashBoard-chart-type-right-border "} onClick={e => {
                setIsLastClickOnNext(false);
                setChartPeriodCount((chartPeriodCount - 1))
              }}>{t("dashboard.previous")}</div>
              <div className={(isLastClickOnNext ? "dashBoard-chart-type-item min-width-70px  dashBoard-chart-type-left-border" : "dashBoard-chart-type-item min-width-70px ") + (chartPeriodCount >= 0 ? " pointer-default text-inactive " : "")} onClick={e => {
                if (chartPeriodCount) {
                  setIsLastClickOnNext(true);
                  setChartPeriodCount((chartPeriodCount + 1));
                }
                else {
                  dummyFunction();
                }
              }}>
                {t("dashboard.next")}
              </div>
            </div>
          </div>

          <div className="w-100">
            <Chart
              chartType={chartType}
              data={chartData}
              width="100%"
              height="400px"
              legendToggle
              options={options}
            />
          </div>
        </div>
      </div>



    </div>
  );
}

export default DashBoard;
