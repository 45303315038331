import React from "react";

export const Textfield = (props) => {
  return (
    <>
      <input
        type={props.type}
        className={`common-input  ${props.className}`}
        disabled={props.disabled}
        value={props.value}
        name={props.name}
        onKeyDown={props.onKeyDown}
        required={props.required}
        id={props.id}
        min={props.min}
        max={props.max} 
        maxLength={props.maxLength} 
        onChange={props.onChange}
        onClick={props.onClick}
        placeholder={props.placeholder}
      />
    </>
  );
};
