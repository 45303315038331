import { t } from "i18next";

import {
  PAYOUT_TOVA, PAYOUT_ACCOUNT, PAYOUT_STAFF, PAY_STATUS_CODE_SUCCESS, PAY_STATUS_CODE_READY, PAY_STATUS_CODE_PENDING, PAY_STATUS_CODE_FAILED, PAY_STATUS_CODE_ONHOLD, PAY_STATUS_CODE_PAYOUT_START, PAY_STATUS_CODE_REPROCESSING, USER_ROLE_ADMIN_SUPERADMIN, USER_ROLE_ADMIN_ADMIN, USER_ROLE_ACCOUNT_MANAGER, USER_ROLE_ACCOUNT_USER, US_STATES_ARRAY, STAFF_STATUS_ACTIVE, STAFF_STATUS_INACTIVE, STAFF_STATUS_INVITE, STAFF_STATUS_DELETE 
} from "./env";

// FUNCTION TO PAYOUT STRING FROM ITS CODE 
export  const getPayoutString = (payout: any) => {
    return payout == PAYOUT_TOVA
      ? t("payouts.tova")
      : payout == PAYOUT_ACCOUNT
      ? t("payouts.account")
      : payout == PAYOUT_STAFF
      ? t("payouts.staff")
      : "";
};

// FUNCTION TO CALCULATE SOME FUTURE DATES 
export const dateCalc = (dateType) => {
  let dtVar = new Date(); 
  if(dateType=='nextDay') {
    return(dtVar.setDate(dtVar.getDate() + 1));
    // return (dayjs(dtVar).format(`MMM DD, YYYY`)); 
  }
  else if(dateType=='nextSunday') {
    return(dtVar.setDate(dtVar.getDate() + (0 - 1 - dtVar.getDay() + 7) % 7 + 1));
  }
  else if(dateType=='biWeekSunday') {
    dtVar.setDate(dtVar.getDate() + (0 - 1 - dtVar.getDay() + 7) % 7 + 1);
    return(dtVar.setDate(dtVar.getDate() + 7));
  } 
  else if(dateType=='firstDateNextMonth') {
    const now = new Date();
    return(dtVar = now.getMonth() == 11 ? new Date(now.getFullYear()+1, 0, 1) : new Date(now.getFullYear(), now.getMonth() + 1, 1));
  }
  else if(dateType=='lastDateMonth') {
    const now = new Date();
    const lDay = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    if(now.getDate()==lDay) {
      return new Date(now.getFullYear(), now.getMonth() + 2, 0); 
    }
    else {
      return new Date(now.getFullYear(), now.getMonth() + 1, 0); 
    }
  }
  else if(dateType=='firstDateThisMonth') {
    const now = new Date();    
    return  new Date(now.getFullYear(), now.getMonth(), 1); 
  }
}

//FUNCTION TO GET PAYOUT STATUS LABES
export const GetPayoutStatusText = (statusString : number) => {
  // const { t } = useTranslation();
  switch (statusString) {
    case PAY_STATUS_CODE_SUCCESS : return t("payStatusCodes.success"); 
    case PAY_STATUS_CODE_READY : return t("payStatusCodes.ready"); 
    case PAY_STATUS_CODE_PENDING : return t("payStatusCodes.pending"); 
    case PAY_STATUS_CODE_FAILED : return t("payStatusCodes.failed"); 
    case PAY_STATUS_CODE_ONHOLD : return t("payStatusCodes.onHold"); 
    case PAY_STATUS_CODE_PAYOUT_START : return t("payStatusCodes.payoutStart"); 
    case PAY_STATUS_CODE_REPROCESSING : return t("payStatusCodes.reprocessing"); 
    default: return statusString; 
  }
}

// COMMON FUNCTION TO CHECK THE LGINED USER HAS THE PRIVILAGE TO HAVE ACTIVATE OR DELETE OPTIONS 
export const showDeleteActivateOption = (loginUserId:number, loginUserRole:number, userId:number, userRole:number) => {

  if(loginUserId==userId) { // IF USER LOGINED AND USER IN THE ROW ARE SAME, NOT ALLOW TO DELETE
    return false; 
  }
  else if(loginUserRole==USER_ROLE_ADMIN_SUPERADMIN) {
    return true; 
  }
  else if(loginUserRole==USER_ROLE_ADMIN_ADMIN && (userRole==USER_ROLE_ADMIN_ADMIN || userRole==USER_ROLE_ACCOUNT_MANAGER || userRole==USER_ROLE_ACCOUNT_USER)) {
    return true; 
  }
  else if(loginUserRole==USER_ROLE_ACCOUNT_MANAGER && (userRole==USER_ROLE_ACCOUNT_MANAGER || userRole==USER_ROLE_ACCOUNT_USER)) {
    return true; 
  }
  else if(loginUserRole==USER_ROLE_ACCOUNT_USER && userRole==USER_ROLE_ACCOUNT_USER) {
    return true; 
  }
  else {
    return false; 
  }
}

// COMMON FUNCTION TO GET STATE NAME FROM ITS CODE 
export const getStateNameFromCode = (stateCode:string) => {
  if(!stateCode || stateCode.trim().length==0) {
    return "";
  }
  else {
    let tmpArr = US_STATES_ARRAY.find(item => item.stateCode==stateCode); 
    if(tmpArr) {
      return tmpArr.stateName; 
    }
    else {
      return stateCode; 
    }
  }
}

// COMMON FUNCTION TO REMOVE ALL CHARECTERS OTHER THAN DIGITS FROM A STRING 
export const filterDigitsOnly = (numString:string) =>  {
  let newValue = "";  
  for (var i = 0; i < numString.length; i++) {
    if (numString.charAt(i) >= '0' && numString.charAt(i) <= '9') {
      newValue = newValue+numString.charAt(i); 
    }
  }   
  return(newValue); 
};

// COMMON FUNCTION TO REMOVE ALL CHARECTERS OTHER THAN DIGITS AND DOT FROM A STRING 
export const filterDigitsDotOnly = (numString:string) =>  {
  let newValue = "";  
  for (var i = 0; i < numString.length; i++) {
    if (i == 0 && numString.charAt(i) >= '0' && numString.charAt(i) <= '9') {
      newValue = newValue+numString.charAt(i); 
    }
    else if (i > 0 && ((numString.charAt(i) >= '0' && numString.charAt(i) <= '9') || numString.charAt(i) == '.')) {
      newValue = newValue+numString.charAt(i); 
    }
  }   
  return(newValue); 
};

// COMMON FUNCTION TO GET THE STATUS STRING FROM ITS ID
export const getStaffStausById = (statusId:number) =>  {
  switch(statusId) {
    case STAFF_STATUS_ACTIVE:   return t("staffStatus.active")
    case STAFF_STATUS_INACTIVE: return t("staffStatus.inactive")
    case STAFF_STATUS_INVITE:   return t("staffStatus.invited")
    case STAFF_STATUS_DELETE:   return t("staffStatus.deleted")
    default:                    return('');
  }    
};

  