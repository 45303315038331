import React, { memo } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTE_ACCOUNTS_LOGIN } from '../../../config/env';

interface IProps {
  signUpStep: number;
}

const SignUpLeftPane: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate  = useNavigate();

  const signUpStepOneClass = props.signUpStep == 1 ? "bg-blue" : props.signUpStep >= 2 ?  "bg-success" : "bg-grey";
  const signUpStepTwoClass = props.signUpStep == 2 ? "bg-blue" : props.signUpStep >= 3 ?  "bg-success" : "bg-grey";
  const signUpHeadTextClass = props.signUpStep <= 2 ? "" : "text-grey";
  const signUpStepOneTextClass = props.signUpStep == 1 ? " " :  " text-grey";
  const signUpStepTwoTextClass = props.signUpStep == 2 ? " " : " text-grey";


  const createAccStepOneClass = props.signUpStep == 3 ? "bg-blue" : props.signUpStep >= 4 ?  "bg-success" : "bg-grey";
  const createAccStepOneTextClass = props.signUpStep == 3 ? " " :  " text-grey";



  return (
      <div className="stepper pt-5">

        <div className="ml-56px">
          <div className="flex gap-2 ml-25px">
            {/* <div className={"circle "+signUpHeadClass}>{t("common.num1")}</div>  */}
            <h1 className={signUpHeadTextClass}>{t("signUpAside.signup")}</h1>
          </div>
          <div className="relative ml-25px mt-2 mb-5px">
            <div className="flex gap-3 mb-18px pt-5px">
              <div className={"dot "+signUpStepOneClass}> &nbsp;</div>
              <div className={signUpStepOneTextClass}>{t("signUpAside.provideyourdetails")}</div>
            </div>
            <div className="flex gap-3 mb-18px pt-5px">
              <div className={"dot "+signUpStepTwoClass}> &nbsp;</div>
              <div className={signUpStepTwoTextClass}>{t("signUpAside.verifyyouremail")}</div>
            </div>
            <div className="flex gap-3 pt-5px mb-18px pb-5px">
                <div className={"dot "+createAccStepOneClass}> &nbsp;</div>
                <div className={""+createAccStepOneTextClass}>{t("signUpAside.businessdetails")}</div>
            </div>
            
            <div className="bg-box">&nbsp;</div>
          </div>
        </div>

        <div className="mb-5 ml-0 center">
          <button
            className="border-6r px-4 py-10px  text-blue font-500 mb-4 "
            style={{ border: " 1px solid #3871E0" }}
            onClick={(e) => navigate(ROUTE_ACCOUNTS_LOGIN)}
          >
            {t("signUpAside.alreadyhaveanaccountLogin")}
          </button>
        </div>
      </div>
  )
}

export default memo(SignUpLeftPane); 