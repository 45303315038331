import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../helpers/hook";
import { Box, Modal } from "@mui/material";
import CloseRounded from "@mui/icons-material/CloseRounded";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Table from "../../components/Table";
import Header from "../../components/Header";
import MoneyField from "../../components/MoneyField";
import Paginations from "../../components/Pagination";
import { Textfield } from "../../components/TextField";
import StatusField from "../../components/StatusField";
import { SelectField } from "../../components/SelectField";
import FilterSection from "../../components/FilterSection";
import { ButtonInLine } from "../../components/ButtonGroups/ButtonInLine";
import { ButtonModal } from "../../components/ButtonGroups/ButtonModal";
import { PaymentsTableHeaders } from "../../components/Table/TableHeaders";

import { filterDateValues } from "../../utilities/dateFilterCalculation";

import { GetPayoutStatusText } from "../../config/common_functions";
import { PAY_STATUS_CODE_ONHOLD, PAY_STATUS_CODE_REPROCESSING, PAGINATION_NUM_ROWS, PAY_STATUS_CODE_SUCCESS, PAY_STATUS_CODE_READY, PAY_STATUS_CODE_PENDING, PAY_STATUS_CODE_FAILED, PAYOUT_ACCOUNT, PAYOUT_ACCOUNT_TEXT, PAYOUT_STAFF, PAYOUT_STAFF_TEXT, USER_ROLE_ADMIN_SUPERADMIN, USER_ROLE_ADMIN_ADMIN, PAGENAME_PAYOUTS, DATEFILTER_THISMONTH, AMOUNTFILTER_DEFAULT_FROM_VALUE, AMOUNTFILTER_DEFAULT_TO_VALUE } from "../../config/env";
import {
  checkActionPermission,
  PERM_PAYOUTS_EXPORT,
  PERM_PAYOUTS_FAILED_REPROCESS,
  PERM_PAYOUTS_MANAGE_EDIT,
} from "../../config/permissions";
import { ERR_PAYOUT_DETAILS_INVALID_STATUS, PAYOUT_REPROCESS_FAILED, PAYOUT_REPROCESS_DISTRIBUTED,  PAYOUT_REPROCESS_SUCCESS, PAYOUT_REPROCESS_INITIATED, STAFF_BANK_ACCOUNT_MISSING, ACCOUNT_BANK_ACCOUNT_MISSING } from "../../config/apiResponseCodes";

import {
  selectPaymentsListDataSlice,
  resetPayments,
  resetPaymentsSummary,
  setCurrentPage,
  resetManagePayReceiptDataStatus,
  resetReprocessStatus, 
  resetManagePaymentDataSaveStatus
} from "./paymentsListDataSlice";

import { IPaymentsRequestData, IManagePaymentDetails, IPaymentReceiptDetails, IManagePaymentSaveData, IManagePaymentSaveDataAll, IManagePaymentReq, IPayoutDestinationChangeReq } from "../../models/paymentsInterfaces";

import { setNotification } from "../../services/apiService"; 
import { fetchPayeeByNameAccId } from "../../services/groupServices";
import { getAdminUserRole, setLastActivePageAPIData } from "../../services/localStorage";
import {
  fetchPayments,
  fetchPaymentsSummary,
  exportPayments,
  fetchManagePaymentDetails, 
  fetchReceiptDetails,
  reprocessFailedPayout,
  saveManagePaymentData,
  changePayoutDestination
} from "../../services/paymentsListServices";

import { selectGroupDetailsSlice } from "../GroupSettings/groupDetailsSlice";

function PaymentsList() { 

  // ************** TO SET DATE FILTER TO THISMONTH BY DEFAULT - START **************
  let dateObj = filterDateValues(DATEFILTER_THISMONTH);
  let fromMM = dateObj.fromDate.getMonth() + 1 < 10 ? "0" + (dateObj.fromDate.getMonth() + 1).toString()
      : (dateObj.fromDate.getMonth() + 1).toString();
  let fromDD = dateObj.fromDate.getDate() < 10 ? "0" + dateObj.fromDate.getDate().toString()
      : dateObj.fromDate.getDate().toString();
  let fromYY = dateObj.fromDate.getFullYear().toString();

  let toMM = dateObj.toDate.getMonth() + 1 < 10 ? "0" + (dateObj.toDate.getMonth() + 1).toString()
    : (dateObj.toDate.getMonth() + 1).toString();
  let toDD = dateObj.toDate.getDate() < 10 ? "0" + dateObj.toDate.getDate().toString()
      : dateObj.toDate.getDate().toString();
  let toYY = dateObj.toDate.getFullYear().toString();

  let defaultFromDate = fromYY + "-" + fromMM + "-" + fromDD;  
  let defaultToDate = toYY + "-" + toMM + "-" + toDD; 
  // ************** TO SET DATE FILTER TO THISMONTH BY DEFAULT - END ***************

  const prevAPIDataString = localStorage.getItem('lastActivePageAPIData');
  const prevAPIData = prevAPIDataString ? JSON.parse(prevAPIDataString) : {};
  const pageName = PAGENAME_PAYOUTS; 
  const adminUserRole = getAdminUserRole();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { paymentsList, totalSize, currentPage, paymentsSummary, managePaymentDetails, paymentReceiptDetails, managePayReceiptDataStatus, reprocessStatus, managePaymentDataSaveStatus } = useAppSelector(selectPaymentsListDataSlice);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 950,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "13px",
    boxShadow: 24,
  };

  const [sortField, setSortField] = useState("");
  const [selectedAccountIds, setSelectedAccountIds] = useState("");
  const [selectedGroupIds, setSelectedGroupIds] = useState(""); 
  const [status, setStatus] = useState("");

  const [dateFilterType, setDateFilterType] = useState(DATEFILTER_THISMONTH);
  const [startDate, setStartDate] = useState<string>(dayjs(defaultFromDate).format(`YYYY-MM-DDTHH:mm:ssZZ`));
  const [endDate, setEndDate] = useState<string>(dayjs(defaultToDate).format(`YYYY-MM-DDT23:59:59ZZ`)); 
  
  const [applyFilter, setApplyFilter] = useState<number>(0);
  const [textSearchFilter, setTextSearchFilter] = useState<string>("");
  const [selectedPayeeIds, setSelectedPayeeIds] = useState<string>("");
  const [selectedPayeeTypes, setSelectedPayeeTypes] = useState<string>("");
  const [selectedPayeeNames, setSelectedPayeeNames] = useState<string>("");
  const [amountFrom, setAmountFrom] = useState<string>(AMOUNTFILTER_DEFAULT_FROM_VALUE.toString());
  const [amountTo, setAmountTo] = useState<string>(AMOUNTFILTER_DEFAULT_TO_VALUE.toString());
  const [numRowsPerPage, setNumRowsPerPage] = useState(PAGINATION_NUM_ROWS);

  const [showManagePayment, setShowManagePayment] = useState(false);
  const [percentageBalance, setPercentageBalance] = useState(100);
  const [amountBalance, setAmountBalance] = useState(0.00);
  const [distributeViaAmount, setDistributeViaAmount] = useState(false);

  const [initialAPICalled, setInitialAPICalled] = useState(false);

  // 'accept...' VARIABLES USED TO SET/RESET FILTERS ON REFRESH   
  const [acceptAccountFilterIds, setAcceptAccountFilterIds] = useState("");
  const [acceptGroupFilterData, setAcceptGroupFilterData] = useState(""); 
  const [acceptDateFilterData, setAcceptDateFilterData] = useState(""); 
  const [acceptMultiSelectStatusIds, setAcceptMultiSelectStatusIds] = useState("");
  const [acceptPayeeFilterData, setAcceptPayeeFilterData] = useState(""); 
  const [acceptAmountFilterData, setAcceptAmountFilterData] = useState(""); 
  const [acceptSearchText, setAcceptSearchText] = useState(""); 

  const [isManagePayModal, setIsManagePayModal] = useState(false);

  const [managePaymentSaveData, setManagePaymentSaveData] = useState<IManagePaymentSaveData[]>(
    [
      {
        staff: 0,
        payoutDestination: PAYOUT_ACCOUNT,
        staffName: "",
        percentage: 0.00,
        amount: 0.00,
      }
    ]
  );

  const [managePayDetails, setManagePayDetails] = useState<IManagePaymentDetails>({
    accountName: "",
    group: "",
    status: 0,
    amountToDistribute: 0, 
    amountToDistributeCurrency: '', 
    startDate: "",
    endDate: "",
    staffDetails: [
      {
          staffId: 0,
          payoutDestination: PAYOUT_ACCOUNT,
          distributionPercentage: 0.00, 
          staffName: "",
      }
    ]
  });

  const [payReceiptDetails, setPayReceiptDetails] = useState<IPaymentReceiptDetails>({
    accountName: "", 
    group: "", 
    startDate: "", 
    endDate: "", 
    status: "", 
    amountToDistribute: 0,  
    amountToDistributeCurrency: "",  
    subTotal: 0,  
    subTotalCurrency: "",  
    fees: 0,  
    feesCurrency: "",  
    total: 0,  
    totalCurrency: "",  
    staffDetails: [
      {
          id: 0,
          staffId: 0,
          staffName: "",
          payee: "",
          payoutDestination: 0, 
          amount: 0, 
          amountCurrency: "",
          distributionPercentage: 0.00,
          status: "",
          isEdit: false
      }
    ]
  })

  const { payeeList } = useAppSelector(selectGroupDetailsSlice);
  const [allPayeeList, setAllPayeeList] = useState(payeeList);  
  const [tempAllPayeeList, setTempAllPayeeList] = useState(payeeList); // USED FOR SELECTION LIST IN EACH ROW WITH FILTER BY TYPING 
  const [staffSearchName, setStaffSearchName] = useState("");
  const [selAccountId, setSelAccountId] = useState(0);
  const [selPayId, setSelPayId] = useState(0);
  const [statePaymentReceiptDetails, setStatePaymentReceiptDetails] = useState({
    accountName: "",
    group: "",
    startDate: "",
    endDate: "",
    status: "",
    amountToDistribute: 0,  
    amountToDistributeCurrency: "", 
    subTotal: 0,
    subTotalCurrency: "", 
    fees: 0, 
    feesCurrency: "",
    total: 0,
    totalCurrency: "",
    staffDetails: [
      {
          id: 0,
          staffId: 0,
          staffName: "",
          payee: "",
          payoutDestination: 0, 
          amount: 0, 
          amountCurrency: "",
          distributionPercentage: 0,
          status: "",
          isEdit: false
      }
    ]
  }) 

  const fetchPaymentsList = () => {
    if(!initialAPICalled && prevAPIData.pageName && prevAPIData.pageName==pageName && prevAPIData?.apiData?.hotel && prevAPIData.apiData.hotel.length > 0) { 
      dispatch(fetchPayments(prevAPIData.apiData));
      dispatch(fetchPaymentsSummary(prevAPIData.apiData)); 

      dispatch(setCurrentPage(prevAPIData.apiData.page));
      setSelectedAccountIds(prevAPIData.apiData.hotel);
      setSelectedGroupIds(prevAPIData.apiData.group); 
      setSortField(prevAPIData.apiData.sort);
      setDateFilterType(prevAPIData.apiData.dateFilterType);
      setStartDate(prevAPIData.apiData.start);
      setEndDate(prevAPIData.apiData.end);
      setSelectedPayeeIds(prevAPIData.apiData.payee);
      setSelectedPayeeTypes(prevAPIData.apiData.payeeType);
      setSelectedPayeeNames(prevAPIData.apiData.payeeNames);
      setTextSearchFilter(prevAPIData.apiData.search);
      setNumRowsPerPage(prevAPIData.apiData.numRowsPerPage); 
      setStatus(prevAPIData.apiData.status); 
      setAmountFrom(prevAPIData.apiData.amountFrom ? prevAPIData.apiData.amountFrom : AMOUNTFILTER_DEFAULT_FROM_VALUE.toString());
      setAmountTo(prevAPIData.apiData.amountTo ? prevAPIData.apiData.amountTo : AMOUNTFILTER_DEFAULT_TO_VALUE.toString());
      

      if(prevAPIData.apiData.hotel && prevAPIData.apiData.hotel.trim().length > 0) {
        setAcceptAccountFilterIds(prevAPIData.apiData.hotel); 
      }
      if(prevAPIData.apiData.group && prevAPIData.apiData.group.trim().length > 0) {
        setAcceptGroupFilterData(prevAPIData.apiData.group); 
      }
      if(prevAPIData.apiData.search && prevAPIData.apiData.search.trim().length > 0) {
        setAcceptSearchText(prevAPIData.apiData.search); 
      }
      if(prevAPIData.apiData.dateFilterType && prevAPIData.apiData.dateFilterType.trim().length > 0) {
        setAcceptDateFilterData(prevAPIData.apiData.dateFilterType); 
      }  
      if(prevAPIData.apiData.status && prevAPIData.apiData.status.trim().length > 0) {
        setAcceptMultiSelectStatusIds(prevAPIData.apiData.status); 
      } 
      if(prevAPIData.apiData.amount_from && prevAPIData.apiData.amount_from.trim().length > 0 && prevAPIData.apiData.amount_to && prevAPIData.apiData.amount_to.trim().length > 0) {
        setAcceptAmountFilterData(prevAPIData.apiData.amount_from+':~~:'+prevAPIData.apiData.amount_to); 
      }       

      setTimeout(() => {
        if(prevAPIData.apiData.payee && prevAPIData.apiData.payee.trim().length > 0 && prevAPIData.apiData.payeeNames && prevAPIData.apiData.payeeNames.trim().length > 0) {
          setAcceptPayeeFilterData(prevAPIData.apiData.payee+':~~:'+prevAPIData.apiData.payeeNames); 
        } 
        setInitialAPICalled(true);
      }, 1250);
      
    }
    else { 
      let accountIds = selectedAccountIds.replace("all,", "");
      if (selectedAccountIds.length > 0) {
        if (currentPage > 0 && accountIds && accountIds.length > 0) {
          const req: IPaymentsRequestData = {
            page: currentPage,
            numRowsPerPage: numRowsPerPage,
            sort: sortField,
            search: textSearchFilter.trim(),
            hotel: accountIds,
            group: selectedGroupIds=="clearOnly"?"":selectedGroupIds, 
            dateFilterType: dateFilterType,
            start: startDate,
            end: endDate,
            status: status,
            payee: selectedPayeeIds,
            payeeType: selectedPayeeTypes, 
            payeeNames: selectedPayeeNames,
            amount_from: amountFrom,
            amount_to: amountTo,
            userRole: Number(adminUserRole),
          };
          setAcceptDateFilterData(DATEFILTER_THISMONTH); 
          dispatch(fetchPayments(req));
          dispatch(fetchPaymentsSummary(req)); 
          setLastActivePageAPIData({pageName:pageName, apiData:req}); 
          setInitialAPICalled(true);
          console.log(req);
        }
      } else if (selectedAccountIds.length == 0) {
        dispatch(
          resetPaymentsSummary({
            total_transaction: 0,
            total_payout_amount: "",
            total_payout_amount_currency: "",
            total_succeeded: 0,
            total_failed: 0,
          })
        );
        dispatch(resetPayments([]));
      }
    }
  };

  const onExportButton = () => {
    let accountIds = selectedAccountIds.replace("all,", "");
    if (accountIds && accountIds.trim().length > 0) {
      const req: IPaymentsRequestData = {
        page: currentPage,
        numRowsPerPage: numRowsPerPage,
        sort: sortField,
        search: textSearchFilter.trim(),
        hotel: accountIds,
        group: selectedGroupIds=="clearOnly"?"":selectedGroupIds, 
        start: startDate,
        end: endDate,
        status: status,
        payee: selectedPayeeIds,
        payeeType: selectedPayeeTypes, 
        amount_from: amountFrom,
        amount_to: amountTo,
        userRole: Number(adminUserRole),
      };
      dispatch(exportPayments(req));
    }
  };

  useEffect(() => {
    fetchPaymentsList();
  }, [
    currentPage,
    sortField,
  ]);

  useEffect(() => {
    if (currentPage != 1) {
      acceptPage(1);
    } else {
      fetchPaymentsList();
    }
  }, [
    textSearchFilter,
    status,
    applyFilter,
    selectedPayeeIds,
    startDate,
    endDate,
    amountFrom,
    amountTo,
    numRowsPerPage, 
  ]);

  useEffect(() => {
    if(selectedGroupIds=="clearOnly") {
      //DO NOTHING 
    }
    else {
      if (currentPage != 1) {
        acceptPage(1);
      } else {
        fetchPaymentsList();
      }
    }
  }, [selectedGroupIds]);

  // managePayReceiptDataStatus USED TO SHOW ERROR MSG IF ANY ERROR OCCURED WHILE FETCHING PAYOUT DETAILS OF EACH ROW
  useEffect(() => {
    if (managePayReceiptDataStatus && managePayReceiptDataStatus.trim().length > 1) {
      dispatch(resetManagePayReceiptDataStatus()); 
      setNotification(t("projectName"), managePayReceiptDataStatus==ERR_PAYOUT_DETAILS_INVALID_STATUS ? t("managePayment.payoutDataGetErrorMsgInvalid") : t("managePayment.payoutDataGetErrorMsg"), "failed", "payout_payReceiptData");
    }
  }, [managePayReceiptDataStatus]);

  const getReprocessStatusMsg = (statusString:string) => {
    switch (statusString) {
      case PAYOUT_REPROCESS_SUCCESS : return t("managePayment.msgReprocessSuccess"); 
      case PAYOUT_REPROCESS_FAILED : return t("managePayment.msgReprocessFailed"); 
      case PAYOUT_REPROCESS_DISTRIBUTED : return t("managePayment.msgReprocessInitiated"); 
      case PAYOUT_REPROCESS_INITIATED : return t("managePayment.msgReprocessDistributed"); 
      case STAFF_BANK_ACCOUNT_MISSING : return t("managePayment.msgReprocessStaffBankDetMissing"); 
      case ACCOUNT_BANK_ACCOUNT_MISSING : return t("managePayment.msgReprocessAccBankDetMissing"); 
      default: return t("managePayment.msgReprocessFailed"); ; 
    }
  }

  // managePaymentDataSaveStatus USED TO SHOW MESSAGES ON MANAGE PAYMENTS SAVE EVENT  
  useEffect(() => {
    if (managePaymentDataSaveStatus && managePaymentDataSaveStatus.trim().length > 1 && managePaymentDataSaveStatus!="loading") {
      setNotification(
        t("projectName"), 
        managePaymentDataSaveStatus=="success" ? t("managePayment.msgManagePaymentSaveSuccess") : t("managePayment.msgManagePaymentSaveFailed"), 
        managePaymentDataSaveStatus=="success" ? "success" : "failed", 
        "payout_managePaymentDataSave"
      );

      dispatch(resetManagePaymentDataSaveStatus()); 
      if(managePaymentDataSaveStatus=="success") {
        fetchPaymentsList(); 
        setShowManagePayment(false); // CLOSE THE MANAGE PAYMENTS POPUP 
      }
    }
  }, [managePaymentDataSaveStatus]);


  // reprocessStatus USED TO SHOW ERROR MSG IF ANY ERROR OCCURED ON REPROCESSING 'FAILED' PAYMENTS 
  useEffect(() => {
    if (reprocessStatus && reprocessStatus.trim().length > 1 && reprocessStatus!="loading") {
      setNotification(t("projectName"), getReprocessStatusMsg(reprocessStatus), reprocessStatus==PAYOUT_REPROCESS_SUCCESS ? "success" : "failed", "payout_reprocessStatus");
      if(reprocessStatus==PAYOUT_REPROCESS_SUCCESS || reprocessStatus==PAYOUT_REPROCESS_DISTRIBUTED || reprocessStatus==PAYOUT_REPROCESS_INITIATED || reprocessStatus==PAYOUT_REPROCESS_FAILED) {
        fetchPaymentsList(); 
        setShowManagePayment(false);
      }
      dispatch(resetReprocessStatus()); 
    }
  }, [reprocessStatus]);

  useEffect(() => {
    if (currentPage != 1) {
      acceptPage(1);
    } else {
      fetchPaymentsList();
    }
  }, [selectedAccountIds]);

  

  useEffect (() => {
    if(paymentReceiptDetails.staffDetails) { 
      let arrTemp = []; 
      paymentReceiptDetails.staffDetails.map(item => { 
        arrTemp.push({...item, isEdit: false}); 
      });
      setStatePaymentReceiptDetails({...paymentReceiptDetails, staffDetails:arrTemp}); 
      console.log('arrTemp: ',arrTemp);  
    }
  },[paymentReceiptDetails]); 

  useEffect (() => {
    setManagePayDetails(managePaymentDetails);
    let amtBalance = managePaymentDetails.amountToDistribute; 
    let percBalance = 100; 
    let tmpAmount = 0.00; 

    let arrTmp = []; 
    managePaymentDetails.staffDetails.map(item => { 
      tmpAmount = item.distributionPercentage ? parseFloat((managePaymentDetails.amountToDistribute*item.distributionPercentage/100).toFixed(2)) : parseFloat(managePaymentDetails.amountToDistribute); 
      amtBalance -= tmpAmount; 
      percBalance -= item.distributionPercentage; 
      arrTmp.push({
        staff: item.staffId,
        payoutDestination: item.payoutDestination,
        staffName: item.staffName,
        percentage: item.distributionPercentage,
        amount: parseFloat(tmpAmount),
      }); 
    });
    setManagePaymentSaveData(arrTmp); 
    
    setAmountBalance(amtBalance); 
    setPercentageBalance(percBalance); 

  },[managePaymentDetails]); 

  useEffect(() => {
    if (payeeList.length == 0) {
      setAllPayeeList(payeeList);
      setTempAllPayeeList(payeeList); 
    } else {
      // REMOVE THE STAFF WHICH ARE ALREADY ADDED 
      let arrIds = [];
      if (managePaymentSaveData.length > 0) {
        arrIds = managePaymentSaveData.map((item) => {
          return item.staff;
        });
      }
      let filteredPayeeList = payeeList.filter(
        (item) => !arrIds.includes(item.id)
      );
      setAllPayeeList(filteredPayeeList);
      setTempAllPayeeList(filteredPayeeList); 
    }
  }, [payeeList]);

  const addPayeeRow = () => { 
    console.log('payeeList33: ',payeeList); 
    console.log('managePaymentSaveData33: ',managePaymentSaveData); 
    setManagePaymentSaveData([...managePaymentSaveData, {
      staff: "",
      payoutDestination: PAYOUT_ACCOUNT,
      staffName: "",
      percentage: 0.00,
      amount: 0.00,
      newRow: "yes",
    }]);
    // REMOVE THE STAFF WHICH ARE ALREADY ADDED 
    let arrIds = [];
    if (managePaymentSaveData.length > 0) {
      arrIds = managePaymentSaveData.map((item) => {
        return item.staff;
      });
    }
    let filteredPayeeList = payeeList.filter(
      (item) => !arrIds.includes(item.id)
    );
    setAllPayeeList(filteredPayeeList);
    setTempAllPayeeList(filteredPayeeList); 
  } 

  const removePayeeRow = (remIndex: number) => {
    let temArryObj = [];
    managePaymentSaveData.map((item, idx) => {
      if (remIndex != idx) {
        temArryObj.push(item);
      }
    });
    setManagePaymentSaveData(temArryObj);

    // REMOVE THE STAFF WHICH ARE ALREADY ADDED TO THE GROUP FROM SEARCH RESPONSE LIST
    let arrIds = [];
    if (temArryObj.length > 0) {
      arrIds = temArryObj.map((item) => {
        return item.staff;
      });
    }
    let filteredPayeeList = payeeList.filter(
      (item) => !arrIds.includes(item.id)
    );
    setAllPayeeList(filteredPayeeList);
    setTempAllPayeeList(filteredPayeeList); 

  };

  const changeReceiptEdit = (mIndex:number) => {
    let pRDet = statePaymentReceiptDetails; 
    let staffDet = pRDet.staffDetails; 
    let newStaffDet = []; 
    staffDet.map((item,idx) => {
      if (mIndex == idx) { 
        newStaffDet.push({...item, isEdit: true});  
      }
      else {
        newStaffDet.push({...item});  
      }
    }); 
    let newpRDet = {...pRDet, staffDetails:newStaffDet}; 
    setStatePaymentReceiptDetails(newpRDet); 
  }

  const changePayDestination = (mIndex:number,payId:string,payDestId:string) => {
    let pRDet = statePaymentReceiptDetails; 
    let staffDet = pRDet.staffDetails; 
    let newStaffDet = []; 
    staffDet.map((item,idx) => {
      if (mIndex == idx) { 
        newStaffDet.push({...item, payoutDestination: Number(payDestId)});  
      }
      else {
        newStaffDet.push({...item});  
      }
    }); 
    let newpRDet = {...pRDet, staffDetails:newStaffDet}; 
    setStatePaymentReceiptDetails(newpRDet); 
    let apiReqData : IPayoutDestinationChangeReq = {
      payId: payId,
      payoutDestination: Number(payDestId), 
      userRole: Number(adminUserRole),
    }
    dispatch(changePayoutDestination(apiReqData)); 
  }

  const changeGroupStaffDetails = (
    mIndex: number,
    fieldValue: string,
    fieldType: string
  ) => {
    let tmpArrObj = [];
    let tmpValue = 0 ;
    let amtBalance = managePaymentDetails.amountToDistribute; 
    let percBalance = 100; 

    if(fieldType=="isEdit") {
      
    }
    else {
      managePaymentSaveData.map((item, idx) => {
        if (mIndex == idx) { 
          if (fieldType == "dPerc") { 
            // PERCENTAGE CHANGED, CALCULATE AMOUNT USING PERCENTAGE  
            tmpValue = Number(fieldValue)==0 ? 0 : managePayDetails.amountToDistribute*Number(fieldValue)/100;
            tmpArrObj.push({ ...item,  percentage: Number(fieldValue), amount: Number(tmpValue.toFixed(2)) });
            amtBalance -= tmpValue; 
            percBalance -= Number(fieldValue); 
          } else if (fieldType == "amount") {  
            // AMOUNT CHANGED, CALCULATE PERCENTAGE USING AMOUNT
            tmpValue = Number(fieldValue)==0 ? 0 : (Number(fieldValue)*100)/managePayDetails.amountToDistribute;
            tmpArrObj.push({ ...item,  percentage: Number(tmpValue.toFixed(2)), amount: Number(fieldValue) });
            amtBalance -= Number(fieldValue); 
            percBalance -= tmpValue; 
          } else if (fieldType == "pDest") {
            tmpArrObj.push({ ...item,  payoutDestination: Number(fieldValue) });
          } 
  
        } else {
          tmpArrObj.push(item);
          amtBalance -= item.amount; 
          percBalance -= item.percentage; 
        }
      });
      setManagePaymentSaveData(tmpArrObj);
      setAmountBalance(amtBalance); 
      setPercentageBalance(percBalance); 
    }
    
  };

  const onDistributePaymentButton = () => {
    // SUM OF ALL DISTRIBUTION PERCENTAGE SHOULD BE 100
    // SUM OF ALL AMOUNT SHOULD BE EQUAL TO AMOUNT_TO_BE_DISTRIBUTED
    let sDet: IManagePaymentSaveData[] = [];
    let foundError = 0;
    let totAmount = 0;
    let totPercentage = 0;
    if(!managePaymentSaveData || managePaymentSaveData.length==0) {
      foundError = 1;
    }
    else {
      managePaymentSaveData.map((item) => {
        totAmount += Number(item.amount) ? Number(item.amount) : 0;
        totPercentage += Number(item.percentage) ? Number(item.percentage) : 0;
        if (item.staff==null || item.staff.toString().trim() == "") {
          foundError = 2;
        }
        if (foundError == 0 && (!item.amount || item.amount == 0)) {
          foundError = 3;
        }
        if (foundError == 0 && (!item.percentage || item.percentage == 0)) {
          foundError = 4;
        }
      }); 
  
      if(foundError == 0) {
        if(totAmount!=managePayDetails.amountToDistribute) {
          foundError = 5;
        }
        else if(totPercentage!=100) {
          foundError = 6;
        }
      }
    }

    if (foundError == 0) {
      let saveData : IManagePaymentSaveDataAll = {
        payId: selPayId,
        userRole: Number(adminUserRole), 
        paymentDetails: [
          ...managePaymentSaveData
        ]
      }
      dispatch(saveManagePaymentData(saveData));
    } else { 

      let msgString = ""; 

      switch (foundError) {
        case 1 : msgString = t("managePayment.addStaffError"); 
          break;
        case 2 : msgString = t("managePayment.selectStaffError"); 
          break;
        case 3 : msgString = t("managePayment.amountValueError"); 
          break;
        case 4 : msgString = t("managePayment.percentageValueError"); 
          break;
        case 5 : msgString = t("managePayment.amountSumError"); 
          break;
        case 6 : msgString = t("managePayment.percentageSumError"); 
          break;
        default: msgString = ""; 
          break; 
      }
      // managePayment.duplicateStaffError 
      setNotification(t("projectName"), msgString, "failed", "payout_managePayment");
      
    }
  } 

  const handleSort = (sortField: string) => {
    setSortField(sortField);
  };

  const acceptPage = (e: any) => {
    dispatch(setCurrentPage(e));
  };

  const setPayeeIds = (payeeIds: string) => {
    setSelectedPayeeIds(payeeIds);
  };

  const setPayeeTypes = (payeeTypes: string) => {
    setSelectedPayeeTypes(payeeTypes);
  };

  const setPayeeNames = (payeeNames: string) => {
    setSelectedPayeeNames(payeeNames);
  };

  const setDateFilterDatesType = (fromDate: string, toDate: string, filterType: string) => { 
    if (fromDate.trim().length > 1) {
      setStartDate(dayjs(fromDate).format(`YYYY-MM-DDTHH:mm:ssZZ`));
    } else {
      setStartDate(fromDate);
    }
    if (toDate.trim().length > 1) {
      setEndDate(dayjs(toDate).format(`YYYY-MM-DDT23:59:59ZZ`));
    } else {
      setEndDate(toDate);
    }
    setDateFilterType(filterType);
    setApplyFilter(applyFilter + 1);
  };

  const setStatusFilter = (status: string) => {
    console.log(status, "status");
    setStatus(status);
  };

  const setGroupIdsFilter = (groupIds:string) => {
    setSelectedGroupIds(groupIds);
  }

  const clearFilters = () => {
    setSortField("");
    setStatus("");
    setDateFilterType(DATEFILTER_THISMONTH);
    setStartDate(dayjs(defaultFromDate).format(`YYYY-MM-DDTHH:mm:ssZZ`));
    setEndDate(dayjs(defaultToDate).format(`YYYY-MM-DDT23:59:59ZZ`));
    acceptPage(1);
    setTextSearchFilter("");
    setSelectedPayeeIds("");
    setSelectedPayeeTypes("");
    setSelectedPayeeNames(""); 
    setAmountFrom(AMOUNTFILTER_DEFAULT_FROM_VALUE.toString());
    setAmountTo(AMOUNTFILTER_DEFAULT_TO_VALUE.toString());
    setSelectedGroupIds(""); 
    setApplyFilter(applyFilter + 1);
  };

  const setAmountFilterFromTo = (amountFrom: string, amountTo: string) => {
    setAmountFrom(amountFrom);
    setAmountTo(amountTo);
  };

  // ON CLICK "Manage" BUTTON FROM THE PAYMENTS TABLE ROWS 
  const onClickTableData = (actiontype: string, paymentsListItem: {}) => {
    if (actiontype == "manage") {
      let payId =
        paymentsListItem && paymentsListItem?.hotelPaymentId
          ? paymentsListItem.hotelPaymentId
          : 0;
      setSelAccountId(paymentsListItem && paymentsListItem?.accountId
        ? paymentsListItem.accountId
        : 0);
      if (payId) {
        setSelPayId(payId); 
        setShowManagePayment(true);
        
        if(paymentsListItem?.paymentStatus && (paymentsListItem.paymentStatus==PAY_STATUS_CODE_READY || paymentsListItem.paymentStatus==PAY_STATUS_CODE_PENDING)) {
          setIsManagePayModal(true); // IF SET TO true - POPUP OPENS IN EDIT MODE 
          setManagePayDetails({
            accountName: "",
            group: "",
            status: 0,
            amountToDistribute: 0, 
            amountToDistributeCurrency: '', 
            startDate: "",
            endDate: "",
            staffDetails: [
              {
                staffId: "",
                payoutDestination: PAYOUT_ACCOUNT,
                distributionPercentage: 0.00, 
                staffName: "",
              }
            ]
          }); 
          let reqData: IManagePaymentReq = { 
            payId: payId.toString(),
            userRole: Number(adminUserRole), 
          }
          dispatch(fetchManagePaymentDetails(reqData)); 

          // IF PAYMENT STATUS IS READY THEN FETCH STAFF LIST
          if(paymentsListItem && paymentsListItem?.accountId && paymentsListItem.paymentStatus==PAY_STATUS_CODE_READY) {
            fetchAllStaffByAccId(paymentsListItem.accountId.toString()); 
          }
        }
        if(paymentsListItem?.paymentStatus && (paymentsListItem.paymentStatus==PAY_STATUS_CODE_SUCCESS || paymentsListItem.paymentStatus==PAY_STATUS_CODE_FAILED || paymentsListItem.paymentStatus==PAY_STATUS_CODE_ONHOLD)) {
          setIsManagePayModal(false); // IF SET TO false - POPUP OPENS IN READ ONLY MODE 
          setPayReceiptDetails({
            accountName: "", 
            group: "", 
            startDate: "", 
            endDate: "", 
            status: "", 
            amountToDistribute: 0,  
            amountToDistributeCurrency: "",  
            subTotal: 0,  
            subTotalCurrency: "",  
            fees: 0,  
            feesCurrency: "",  
            total: 0,  
            totalCurrency: "",  
            staffDetails: []
          }); 

          let reqData: IManagePaymentReq = { 
            payId: payId.toString(),
            userRole: Number(adminUserRole), 
          }
          dispatch(fetchReceiptDetails(reqData)); 
        }
      }
    }
  };

  const getStatusBgColor = (statusString:number) => {
    switch (statusString) {
      case PAY_STATUS_CODE_SUCCESS : return ' color-success-status-bg '; 
      case PAY_STATUS_CODE_READY : return ' color-ready-status-bg '; 
      case PAY_STATUS_CODE_ONHOLD : return ' color-ready-status-bg '; 
      case PAY_STATUS_CODE_PENDING : return ' color-pending-status-bg '; 
      case PAY_STATUS_CODE_FAILED : return ' color-danger-status-bg '; 
      default: return ''; 
    }
  }

  const getStatusBgColorDiv = (statusString:number) => {
    switch (statusString) {
      case PAY_STATUS_CODE_SUCCESS : return ' bg-success-payment '; 
      case PAY_STATUS_CODE_READY : return ' bg-ready-payment '; 
      case PAY_STATUS_CODE_ONHOLD : return ' bg-ready-payment '; 
      case PAY_STATUS_CODE_PENDING : return ' bg-pending-payment '; 
      case PAY_STATUS_CODE_FAILED : return ' bg-danger-payment '; 
      default: return ''; 
    }
  }

  const getOrdinalSting = (numString:string) => {
    switch (parseInt(numString,10)%10) {
      case 1 : return 'st'; 
      case 2 : return 'nd'; 
      case 3 : return 'rd'; 
      default: return 'th'; 
    }
  }

  const onReprocessFailed = (payId:string) => {
    if(payId) {
      let reqData: IManagePaymentReq = { 
        payId: payId.toString(),
        userRole: Number(adminUserRole), 
      }
      dispatch(reprocessFailedPayout(reqData)); 
    } 
  }

  const fetchAllStaffByAccId = (accId:string) => {
    if(accId && accId.trim().length > 0) {
      setAllPayeeList([]); 
      setTempAllPayeeList([]); 
      dispatch(
        fetchPayeeByNameAccId({
          account_id: accId,
          name: '',
        })
      );
    }
  };

  const newStaffSearch = (nameSearch: string, index) => {
    setStaffSearchName(nameSearch);

    let tempArryObj = managePaymentSaveData;
    tempArryObj[index].staffName = nameSearch;
    setManagePaymentSaveData(tempArryObj);

    if (nameSearch.trim().length > 0) {
      let tmpArr = allPayeeList.filter(item => item.fullName.toLowerCase().includes(nameSearch.toLowerCase()));  
      setTempAllPayeeList(tmpArr); 
    }
    else {
      setTempAllPayeeList(allPayeeList); 
    }
  };

  const handleSelect = (payeeId: number, payeeName: string, index: number) => {
    setStaffSearchName(payeeName);

    let temArryObj = managePaymentSaveData;
    temArryObj[index].staffName = payeeName;
    temArryObj[index].staff = Number(payeeId);
    temArryObj[index].payoutDestination = payeeId && Number(payeeId) > 0 ? PAYOUT_STAFF : PAYOUT_ACCOUNT; 
    temArryObj[index].newRow = "";

    // REMOVE THE STAFF WHICH ARE ALREADY ADDED TO THE GROUP FROM SEARCH RESPONSE LIST
    let arrIds = [];
    if (temArryObj.length > 0) {
      arrIds = temArryObj.map((item) => {
        return item.staff;
      });
    }
    let filteredPayeeList = payeeList.filter(
      (item) => !arrIds.includes(item.id)
    );
    setAllPayeeList(filteredPayeeList);
    setTempAllPayeeList(filteredPayeeList); 
  };

  const dummyFunction = () => {
    console.log("dummyFunction");
  };

  return (
    <div className="box-padding">
      <div className="">
        <Header
          addButton={false}
          payment={true}
          paymentsSummary={paymentsSummary}
        />
        <FilterSection
          headind={`${t("navigation.payments")}`}
          filterAccount={true} 
          filterGroup={true}
          filterDate={true}
          filterStatus={false}
          filterPayStatus={true}
          filterPayee={true}
          setSelectedAccountIds={setSelectedAccountIds} 
          setGroupIdsFilter={setGroupIdsFilter}
          setDateFilterDatesType={setDateFilterDatesType}
          parentPage={PAGENAME_PAYOUTS} 
          setPayeeTypes={setPayeeTypes} 
          setPayeeIds={setPayeeIds} 
          setPayeeNames={setPayeeNames} 
          setStatusFilter={setStatusFilter}
          filterAmount={true}
          setAmountFilterFromTo={setAmountFilterFromTo}
          clearFilters={clearFilters}
          searchInput={true}
          setTextSearchFilter={setTextSearchFilter}
          searchInputPlaceholder={`${t("paymentslist.searchPayments")}`} 

          // 'accept...' VARIABLES USED TO SET/RESET FILTERS ON PAGE REFRESH 
          acceptAccountFilterIds={acceptAccountFilterIds}
          acceptDateFilterData={acceptDateFilterData}
          acceptSearchText={acceptSearchText}
          acceptMultiSelectStatusIds={acceptMultiSelectStatusIds}
          acceptPayeeFilterData={acceptPayeeFilterData} 
          acceptGroupFilterData={acceptGroupFilterData} 
          acceptAmountFilterData={acceptAmountFilterData}  
        />
        <div className="box">
          <div className="table-box">
            <Table
              titles={PaymentsTableHeaders()}
              data={paymentsList}
              sortedFieldName={sortField}
              handleSort={handleSort}
              onClickTableData={onClickTableData}
              parentPage={PAGENAME_PAYOUTS} 
            />

            <Paginations
              currentPage={currentPage}
              totalPageCount={
                totalSize
                  ? totalSize < numRowsPerPage
                    ? 1
                    : totalSize % numRowsPerPage > 0
                    ? parseInt((totalSize / numRowsPerPage).toString(), 10) + 1
                    : parseInt((totalSize / numRowsPerPage).toString(), 10)
                  : 0
              }
              totalRows={totalSize}
              isTableLoading={false}
              setCurrentPage={acceptPage}
              setNumRowsPerPage={setNumRowsPerPage}
              recordsTypeName={`${t("common.transactions")}`}
              exportButton={
                (checkActionPermission(adminUserRole, PERM_PAYOUTS_EXPORT) &&
                totalSize > 0)
                  ? true
                  : false
              }
              onExportButton={onExportButton}
            />
          </div>
        </div>
      </div>

      <Modal
        open={showManagePayment}
        onClose={() => setShowManagePayment(false)}
        aria-labelledby={"ManagePayment"}
        aria-describedby="ManagePayment"
      >
        <Box sx={style}>
          <div className="w-100  manage-modal">
            <div className="space-between center border-bottom p-3">
              <h1>{t("managePayment.managePayment")}</h1>
              <span
                onClick={() => setShowManagePayment(false)}
                className="pointer"
              >
              <CloseRounded />
              </span>
            </div>

            <div className="border-bottom p-3">
              <div className="center">
                <h2>
                  {isManagePayModal ? <MoneyField
                    amountValue={managePayDetails?.amountToDistribute ? Number(managePayDetails.amountToDistribute).toFixed(2) : 0}
                    clickable={false}
                    amountCurrency={managePayDetails?.amountToDistributeCurrency ? managePayDetails.amountToDistributeCurrency : ''}
                  /> : <MoneyField
                  amountValue={paymentReceiptDetails?.amountToDistribute ? Number(paymentReceiptDetails.amountToDistribute).toFixed(2) : 0}
                  clickable={false}
                  amountCurrency={paymentReceiptDetails?.amountToDistributeCurrency ? paymentReceiptDetails.amountToDistributeCurrency : ''}
                />}
                </h2>
              </div>
              <div className="center mb-2px">
                <p className="subhead fontsize-14 font-600 spacing-none">{t("managePayment.amountToDistribute")}</p>
              </div>
              <div className="flex gap-4 ">
                <div className="detail-box">
                  {isManagePayModal ? <div>{managePayDetails?.accountName ? managePayDetails.accountName : ''}</div> : <div>{paymentReceiptDetails?.accountName ? paymentReceiptDetails.accountName : ''}</div>}
                  <div>{t("managePayment.account")}</div>
                </div>
                <div className="detail-box">
                  {isManagePayModal ? <div>{managePayDetails?.group ? managePayDetails.group : ''}</div> : <div>{paymentReceiptDetails?.group ? paymentReceiptDetails.group : ''}</div> } 
                  <div>{t("managePayment.group")}</div>
                </div>

                <div className="detail-box px-0px pt-0px">

                  
                  {isManagePayModal ? 
                   <div className={"flex status-payment " + getStatusBgColorDiv(managePayDetails.status)}>
                      <div className={"circle-10px ml-6px" + getStatusBgColor(managePayDetails.status)} > </div>
                      <span >{GetPayoutStatusText(managePayDetails.status)}</span>
                      </div>
                    : 
                    <div className={"flex status-payment " + getStatusBgColorDiv(paymentReceiptDetails.status)}><div className={"circle-10px ml-6px" + getStatusBgColor(paymentReceiptDetails.status)} > </div>
                      <span>{GetPayoutStatusText(paymentReceiptDetails.status)}</span></div>}
                  

                  <div className="ml-6px">{t("managePayment.status")}</div> 
                </div>

                <div className="detail-box">

                  {isManagePayModal ? <>
                  <div>{managePayDetails.startDate ? dayjs(managePayDetails.startDate).format(`MMM DD`)+getOrdinalSting(dayjs(managePayDetails.startDate).format(`DD`))+' - ' : ''} {managePayDetails.endDate ? dayjs(managePayDetails.endDate).format(`MMM DD`)+getOrdinalSting(dayjs(managePayDetails.endDate).format(`DD`))+dayjs(managePayDetails.endDate).format(` YY`) : ''}</div></> : 
                  <>
                  <div>{paymentReceiptDetails?.startDate ? dayjs(paymentReceiptDetails.startDate).format(`MMM DD`)+getOrdinalSting(dayjs(paymentReceiptDetails.startDate).format(`DD`))+' - ' : ''} {paymentReceiptDetails?.endDate ? dayjs(paymentReceiptDetails.endDate).format(`MMM DD`)+getOrdinalSting(dayjs(paymentReceiptDetails.endDate).format(`DD`))+dayjs(paymentReceiptDetails.endDate).format(` YY`) : ''}</div></>}

                  <div>{t("managePayment.dateRange")}</div>
                </div>
              </div>
            </div>

            <div className="bg-light">
              {isManagePayModal && <div>
                <div className="space-between border-bottom py-3 mx-3">
                  <div>
                    {(checkActionPermission(adminUserRole, PERM_PAYOUTS_MANAGE_EDIT) && managePayDetails.status==PAY_STATUS_CODE_READY && payeeList.length > 0 && payeeList.length > managePaymentSaveData.length) && <ButtonInLine
                      className="mt-20px py-2 px-10px text-dark text-no-transform fontsize-14"
                      buttonLabel={t("group.addPayee")} onClick={e => addPayeeRow()}
                    />}
                  </div> 
                  <div className="flex-col">
                    <div className="fgrey pb-2">{t("managePayment.distributeVia")}</div>
                    <div className="flex">
                      <button className={(distributeViaAmount ? "bg-white" : " blue-border text-blue bg-lite-blue") +" border-start-8r  px-3 py-1 fontsize-14  font-500"} 
                        onClick={e => setDistributeViaAmount(false)}
                      >
                        {t("managePayment.percentageWithSymbol")}
                      </button>
                      <button className={(distributeViaAmount ? " blue-border text-blue bg-lite-blue " : "bg-white")+" border-end-8r  px-3 py-1 fontsize-14  font-500"}
                        onClick={e => setDistributeViaAmount(true)}
                      >
                        {t("managePayment.amountWithDollar")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>}


              {/* ****************** SECTION FOR MANAGE PAYMENTS - START ****************** */}
              {isManagePayModal && <div className={(managePaymentSaveData && managePaymentSaveData.length > 4 ? "  managePayment-scroll " : "")  + " border-bottom px-3 pt-3 pb-2 "}>
                <div className="w-100 space-between gap-4">
                  <div className="w-100 fgrey">{t("managePayment.payeeName")}</div>
                  <div className="w-100 fgrey">{t("managePayment.payoutDestination")}</div>
                  <div className="w-100 fgrey">{!distributeViaAmount ? t("managePayment.percentageAmount") : t("managePayment.amountPercentage")}</div>
                </div>

                {managePaymentSaveData && managePaymentSaveData.length > 0 && managePaymentSaveData.map((item, index) => (<div className="w-100 flex gap-3 my-2 ">
                  <div className="relative w-100">
                    <span className="index">{item.staff!=null && item.staff==0 ? t("group.account") : t("group.staff")}</span>
                    <Textfield
                      type="text"
                      placeholder={t("group.payeeNamePlaceHolder")}
                      className={(item.staff==null || item.staff.toString().trim() == "" ? " input-error "
                          : "") +
                        " w-100 text-data py-8px " +
                        (item.staff ? " disabled" : "")
                      }
                      value={item.staffName}
                      disabled={item.staff.toString().trim().length == 0 ? false : (item.staff==="" ? false : Number(item.staff) >=0 ? true : false) }
                      onChange={(e) => {
                        item.staff == "" 
                          ? newStaffSearch(e.target.value, index)
                          : dummyFunction();
                      }}
                    />

                    {item.newRow == "yes" && item.staff == "" &&
                      tempAllPayeeList.length >= 1 && (
                        <div className="group-dropdown">
                          {tempAllPayeeList.map((item) => (
                            <span
                              onClick={(e) =>
                                handleSelect(item.id, item.fullName, index)
                              }
                            >
                            {item.fullName}
                            </span>
                          ))}
                        </div>
                      )}
                  </div>
                  <div className="percentage-input w-100">
                    <SelectField
                      type="inputType"
                      name=""
                      id="" 
                      value={item.payoutDestination} 
                      disabled={isManagePayModal && managePayDetails.status==PAY_STATUS_CODE_READY ? false : true}
                      onChange={(e) =>
                        changeGroupStaffDetails(
                          index,
                          e.target.value,
                          "pDest"
                        )
                      }
                      options={<>
                        <option value={PAYOUT_ACCOUNT}>
                          {PAYOUT_ACCOUNT_TEXT}
                        </option>
                        {item.staff > 0 && <option value={PAYOUT_STAFF}>
                          {PAYOUT_STAFF_TEXT}
                        </option>}
                        </>}
                    />
                    {/*  */}
                  </div>
                  <div className="w-100 flex gap-2">
                    <div className="percentage-input w-100">
                      <span className="px-10px">  
                        {distributeViaAmount ? t("managePayment.amountIcon") : t("managePayment.percentageIcon")}
                      </span>
                      <Textfield type="number" value={distributeViaAmount ? item.amount : item.percentage } 
                        className={"border border-end-0 rounded-0"}
                        onChange={(e) =>
                          changeGroupStaffDetails(
                            index,
                            e.target.value,
                            distributeViaAmount ? "amount" : "dPerc"
                          )
                        }
                        disabled={isManagePayModal && managePayDetails.status==PAY_STATUS_CODE_READY ? false : true}
                      />
                      <div className="text-data span-right">  
                        {distributeViaAmount ? '' : t("managePayment.amountIcon")}
                        {distributeViaAmount ? ((item?.percentage && Number(item.percentage) > 0) ? Number(item.percentage).toFixed(2) : '0.00') : ((item?.amount && Number(item.amount) > 0) ? Number(item.amount).toFixed(2) : '0.00')  }
                        {distributeViaAmount ? t("managePayment.percentageIcon") : ''}
                      </div>
                    </div>
                    <div className="">
                      {(isManagePayModal && managePayDetails.status==PAY_STATUS_CODE_READY && checkActionPermission(adminUserRole, PERM_PAYOUTS_MANAGE_EDIT)) && <button className="bg-white rounded-3 border px-1 py-2px" onClick={e => removePayeeRow(index)}>
                        <CloseRounded />
                      </button>}
                    </div>
                  </div>
                </div>)
                )}
              </div>}
              {/* ****************** SECTION FOR MANAGE PAYMENTS - START ****************** */} 


              {/* ****************** SECTION FOR RECEIPT DETAILS - START ****************** */}
              {!isManagePayModal && <div className={(statePaymentReceiptDetails.staffDetails && statePaymentReceiptDetails.staffDetails.length > 4 ? " payReceipt-scroll " : "") + " border-bottom px-3 pt-3 pb-2 "}>
                <div className="w-100 space-between gap-4">
                  <div className="w-30perc fgrey">{t("managePayment.payeeName")}</div>
                  <div className="w-20perc fgrey">{t("managePayment.payoutDestination")}</div>
                  <div className="w-20perc fgrey">{t("managePayment.amount")}</div>
                  <div className="w-10perc fgrey">{t("managePayment.percentage")}</div>
                  <div className="w-10perc text-center div-center fgrey">{t("managePayment.status")}</div>
                  <div className="w-5perc fgrey">&nbsp;</div>
                </div>

                {statePaymentReceiptDetails.staffDetails && statePaymentReceiptDetails.staffDetails.length > 0 && statePaymentReceiptDetails.staffDetails.map((item, index) => (
                  <div className="w-100 flex gap-3 my-2 ">
                    <div className="relative w-30perc">
                      <span className="index">{item.staffId!=null && item.staffId==0 ? t("group.account") : t("group.staff")}</span>
                      <Textfield type="text" value={item.staffName} className="text-data" disabled={true} />
                    </div>
                    <div className={item.isEdit ? "w-20perc text-data " : "w-20perc text-data mt-7px "} >

                      {!item.isEdit && <span>{item.payoutDestination==PAYOUT_ACCOUNT ? PAYOUT_ACCOUNT_TEXT : item.payoutDestination==PAYOUT_STAFF ? PAYOUT_STAFF_TEXT : ''}</span>}

                      {item.isEdit && <SelectField
                      type="inputType"
                      name=""
                      id="" 
                      value={item.payoutDestination} 
                      disabled={!item.isEdit}
                      onChange={(e) => changePayDestination(index,item.id,e.target.value) }
                      options={<>
                        <option value={PAYOUT_ACCOUNT}>{PAYOUT_ACCOUNT_TEXT}</option>
                        {item.staffId && item.staffId > 0 && <option value={PAYOUT_STAFF}>{PAYOUT_STAFF_TEXT}</option>}
                        </>}
                    />}
                    </div>
                    <div className="w-20perc div-right text-data mt-6px">
                      <MoneyField
                        amountValue={Number(item.amount).toFixed(2)}
                        clickable={false}
                        amountCurrency={item.amountCurrency} 
                    />
                    </div>
                    <div className="w-10perc  div-right text-data mt-6px">
                      {Number(item.distributionPercentage).toFixed(2)}%
                    </div>
                    <div className="w-10perc text-normal mt-3px">
                    {/* FOR STATUS PAY_STATUS_CODE_FAILED - 0 */}
                    <StatusField
                      fieldName={item?.status ? item?.status : 'sel_payout_status'}
                      value={item?.status ? item.status : 0}
                      status={
                        item.status === PAY_STATUS_CODE_SUCCESS
                          ? 1
                          : (item.status === PAY_STATUS_CODE_PENDING || item.status === PAY_STATUS_CODE_REPROCESSING)
                          ? -1
                          : (item.status === PAY_STATUS_CODE_READY || item.status === PAY_STATUS_CODE_ONHOLD) 
                          ? 2 : 0
                      }
                    />
                    </div>
                    <div className="w-5perc">
                      {(checkActionPermission(adminUserRole, PERM_PAYOUTS_FAILED_REPROCESS) && (item.status === PAY_STATUS_CODE_FAILED || item.status === PAY_STATUS_CODE_ONHOLD)) && <div className="menuDropdown text-normal">
                        <div className="">
                          <MoreVertIcon sx={{color: '#2E2E2E', fontSize: '20px' }} />
                        </div>
                        <div className="min-width-165px menuDropdown-content action-menu-165px text-normal ">
                          {item.status === PAY_STATUS_CODE_ONHOLD && item.staffId!=null && item.staffId > 0 && <div className="min-width-165px">
                            <a className="pointer-cursor text-normal" onClick={(e) => changeReceiptEdit(index)}
                            >
                              <span className="text-normal fontsize-14 ">{t("managePayment.editPayoutDestination")}</span>
                            </a>
                          </div>}
                          <div className="min-width-165px">
                            <a className="pointer-cursor text-normal"
                              onClick={(e) =>
                                onReprocessFailed(item.id.toString())
                              }
                            >
                              <span className="text-normal fontsize-14 ">{t("managePayment.reprocessPayment")}</span>
                            </a>
                          </div>
                        </div>
                      </div>}

                    </div>

                  </div>))
                  }
              </div>}
              {/* ****************** SECTION FOR RECEIPT DETAILS - START ****************** */} 


            </div>

            <div className="px-3 py-4">
              {isManagePayModal && <div className="flex gap-3">
                {" "}
                {/* // COMMENTED FOR FUTURE USE  */}
                {/*<span className="text-danger">*</span>
                <p>
                   {distributeViaAmount ? t("managePayment.notifyTextAmount",{"amountToDistribute":managePayDetails.amountToDistribute.toFixed(2), "amountBalance":amountBalance.toFixed(2)}) : t("managePayment.notifyTextPercentage",{"percentageBalance":percentageBalance.toFixed(2)}) } 
                  {t("managePayment.notifyTextPercentage",{"percentageBalance":percentageBalance.toFixed(2)})}
                </p>*/}
                {percentageBalance && percentageBalance != 0 ? <><span className="text-danger">*</span>
                <p>
                   {t("managePayment.notifyTextPercentage",{"percentageBalance":percentageBalance.toFixed(2)})} 
                </p></> : <></>}
              </div>}

              {!isManagePayModal && <div className="border-bottom px-3 pt-3 pb-2">
                <div className="w-100 space-between gap-4">
                  <div className="w-15perc fgrey payout-subtotal-label">{t("managePayment.subtotal")}</div>
                  {(Number(adminUserRole) == USER_ROLE_ADMIN_SUPERADMIN ||
                      Number(adminUserRole) == USER_ROLE_ADMIN_ADMIN) && <>
                      <div className="w-15perc fgrey payout-subtotal-label">{t("managePayment.fees")}</div>
                  <div className="w-15perc fgrey payout-subtotal-label">{t("managePayment.total")}</div>
                  </>}
                  <div className="w-45perc fgrey">&nbsp;</div>
                </div>
                <div className="w-100 space-between gap-4 mt-15px mb-20px">
                  <div className="w-15perc fgrey payout-subtotal-value">
                    <MoneyField
                      amountValue={paymentReceiptDetails?.subTotal ? Number(paymentReceiptDetails.subTotal).toFixed(2) : 0}
                      clickable={false}
                      amountCurrency={paymentReceiptDetails?.subTotalCurrency ? paymentReceiptDetails.subTotalCurrency : 'USD'}
                    /> 
                  </div>
                  {(Number(adminUserRole) == USER_ROLE_ADMIN_SUPERADMIN ||
                      Number(adminUserRole) == USER_ROLE_ADMIN_ADMIN) && <>
                  <div className="w-15perc fgrey payout-fees-value">
                    <MoneyField
                      amountValue={paymentReceiptDetails?.fees ? Number(paymentReceiptDetails.fees).toFixed(2) : 0}
                      clickable={false}
                      amountCurrency={paymentReceiptDetails?.feesCurrency ? paymentReceiptDetails.feesCurrency : 'USD'}
                    /> 
                  </div>
                  <div className="w-15perc fgrey payout-fees-value">
                    <MoneyField
                      amountValue={paymentReceiptDetails?.total ? Number(paymentReceiptDetails.total).toFixed(2) : 0}
                      clickable={false}
                      amountCurrency={paymentReceiptDetails?.totalCurrency ? paymentReceiptDetails.totalCurrency : 'USD'}
                    /> 
                  </div>
                </>}
                  <div className="w-45perc fgrey">&nbsp;</div>
                </div>
              </div>}

              <div className="flex w-100 gap-4 mt-15px">
                {isManagePayModal && managePayDetails.status==PAY_STATUS_CODE_READY &&  <>
                  <ButtonModal
                    type="cancel"
                    onClick={(e) => setShowManagePayment(false)}
                    buttonLabel={t("common.cancel")}
                  /> 
                  {checkActionPermission(adminUserRole, PERM_PAYOUTS_MANAGE_EDIT) && <ButtonModal
                    buttonLabel={t("managePayment.distributePayment")} 
                    onClick={(e) => onDistributePaymentButton()} 
                    // className={" border-primary bg-lite-blue text-blue"}
                  />} 
                </>}
              </div>
            </div> 
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default PaymentsList;
