import { get } from './apiService';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SA_API_COUNTRY_STATES } from '../config/api_urls';
import { ICountryCode } from '../models/countryStateInterfaces.ts';

// COMMON FUNCTION TO FETCH COUNTRY AND STATES LIST
export const fetchCountryStates = createAsyncThunk(
  'common/fetchCountryStates',
  async (dataObj: ICountryCode, thunkAPI: any) => {
    try {
      let url = SA_API_COUNTRY_STATES;  
      if (dataObj.countryCode != '') { url = url + '?country_code=' + dataObj.countryCode; }
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
