import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface TipState {
  tipValue: number;
  hotelValue: number;
  tipValueWithoutCommission: number;
}

const initialState: TipState = {
  tipValue: 0,
  hotelValue: 0,
  tipValueWithoutCommission: 0,
};

export const tipSlice = createSlice({
  name: 'tipSlice',
  initialState,
  reducers: {
    setTip: (state, action: PayloadAction<number>) => {
      state.tipValue = action.payload;
    },
    setHotel: (state, action: PayloadAction<string>) => {
      state.hotelValue = action.payload;
    },
    setTipWithoutCommission: (state, action: PayloadAction<number>) => {
      state.tipValueWithoutCommission = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTip, setHotel, setTipWithoutCommission } = tipSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectTipSlice = (state: RootState) => state.tipData;

export const tipReducer = tipSlice.reducer;
