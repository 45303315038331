import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { fetchTerms, acceptTerms  } from '../../../services/signUpServices';
import { IBusinessTypes } from '../../../models/signUpInterfaces';
 
export interface IAuthentication {
  isProcessingRequest: 'idle' | 'loading' | 'succeeded' | 'error' | 'termsAccepted';
  termsData: {
    id: number,
    conditionText: string, 
    group: number,
    currentTerms: boolean 
  };
  termsAcceptedStatus?: string; 
} 

const initialState: IAuthentication = { 
  isProcessingRequest: 'idle', 
  termsData: {
    id: 0,
    conditionText: '', 
    group: 0,
    currentTerms: false 
  },
  termsAcceptedStatus: ''
 };

export const agreementDataSlice = createSlice({
  name: 'agreementData',
  initialState,
  reducers: { 
    setIsProcessingRequest: (state) => {
      state.isProcessingRequest = 'idle';
    }
  },
  extraReducers: (builder) => {

    builder.addCase(fetchTerms.pending, (state) => {
      console.log('state',state);
      state.termsData = {
        id: 0,
        conditionText: '', 
        group: 0,
        currentTerms: false 
      };
      state.isProcessingRequest = 'loading';
    });
    builder.addCase(fetchTerms.fulfilled, (state, action) => { 
      console.log('action: ',action);
      state.termsData = action?.payload ?  action.payload : '';  
      state.isProcessingRequest = 'succeeded';  
    }); 
    builder.addCase(fetchTerms.rejected, (state, { payload }: any) => { 
      console.log('payload: ',payload);
      state.termsData = payload.response.data;
      state.isProcessingRequest = 'error';
    });
    builder.addCase(acceptTerms.pending, (state) => {
      console.log('state111',state);
      state.termsAcceptedStatus = 'loading';
    });
    builder.addCase(acceptTerms.fulfilled, (state, action) => { 
      console.log('action222: ',action); 
      state.termsAcceptedStatus = 'termsAccepted'; 
    });
    builder.addCase(acceptTerms.rejected, (state, { payload }: any) => { 
      console.log('payload333: ',payload);
      state.termsAcceptedStatus = 'error'; // payload?.response?.data?.message ? payload.response.data.message : 'error'; 
    }); 
  }
});

// Action creators are generated for each case reducer function
export const { setIsProcessingRequest } = agreementDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAgreementDataSlice = (state: RootState) => state.agreementData; 

export const agreementDataReducer = agreementDataSlice.reducer;
