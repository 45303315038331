import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import tovah from "../assets/images/web-tovah.png";
import image1 from "../assets/images/web-designPattern.svg";
import image2 from "../assets/images/web-mobile.svg";
import image3 from "../assets/images/web-display.svg";
import homeContent from "../assets/images/web-home-content.png";
import homeContentMobile from "../assets/images/web-home-mobile.png";
import mobile from "../assets/images/web-mob.svg";
import tik from "../assets/images/web-tik-b.svg";
import addtik from "../assets/images/web-tik-lite-b.svg";

import sliderlogo1 from "../assets/images/web-slider1.png";
import sliderlogo2 from "../assets/images/web-sliderImg2.jpg";
import sliderlogo3 from "../assets/images/web-sliderImg3.png";
import sliderlogo4 from "../assets/images/web-sliderImg4.png";
import sliderlogo5 from "../assets/images/web-sliderImg5.webp";
import sliderlogo6 from "../assets/images/web-sliderImg6.png";
import sliderlogo7 from "../assets/images/web-sliderImg7.png";
import sliderlogo8 from "../assets/images/web-sliderImg8.png";
import sliderlogo9 from "../assets/images/web-sliderImg9.jpg";
import sliderlogo10 from "../assets/images/web-sliderImg10.png";
import sliderlogo11 from "../assets/images/web-sliderImg11.png";
import sliderlogo12 from "../assets/images/web-sliderImg12.png";
import sliderlogo13 from "../assets/images/web-sliderImg13.jpeg";
import sliderlogo14 from "../assets/images/web-sliderImg14.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GOOGLEANALYTICS_SCRIPT, GOOGLEANALYTICS_SOURCE, ROUTE_SCHEDULE_DEMO, ZOOMINFO_SCRIPT } from "../config/env";
import ContactForm from "./ContactForm";

const HomePage = () => {
  const { t } = useTranslation();

  var settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: 9,
    slidesToScroll: -1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings2 = {
    pauseOnHover: false,
    dots: false,
    infinite: true,
    slidesToShow: 9,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    // *********************************************************************
    // GOOGLE ANALYTICS CODE 
    // *********************************************************************
    if(GOOGLEANALYTICS_SOURCE.length > 1 && GOOGLEANALYTICS_SCRIPT.length > 1) {
      const script1 = document.createElement("script");
      script1.setAttribute("src", GOOGLEANALYTICS_SOURCE);
      document.head.appendChild(script1);
  
      const script2 = document.createElement("script");
      script2.innerHTML = GOOGLEANALYTICS_SCRIPT; 
      document.head.appendChild(script2)
      return () => {
        // clean up the script when the component in unmounted
        document.head.removeChild(script1);
        document.head.removeChild(script2); 
      }
    }
    

    // *********************************************************************
    // ZOOMINFO CODE 
    // *********************************************************************
    if(ZOOMINFO_SCRIPT.length > 0) {
      const script = document.createElement("script");
      script.innerHTML = ZOOMINFO_SCRIPT;
      document.head.appendChild(script)
      return () => {
        // clean up the script when the component in unmounted
        document.head.removeChild(script)
      }
    }
  }, []); 

  return (
    <>
      <Navbar />
      <div className="hide-overflowX bg-white">
        <Row className="home-header">
          <Row className="max-w-1488px responsive-home-section  px-0">
            <Col
              xs={12}
              md={12}
              lg={7}
              className="flex-col space-between mt-140px ps-0"
            >
              <div></div>
              <div className="flex-col justify-content-center align-items-center align-items-md-start text-md-start text-center me-3 me-md-0  gap-40px mb-5 pb-4 ms-md-5 ms-3 ">
                <h1>
                  {t("home.sayThanksHeading")}
                  <img src={tovah} alt="" className="mb-12px ml-15px" />
                </h1>
                <p className="max-w-617px">{t("home.welcomePara")}</p>
                {/* <button className='home-btn-demo'>Request a Demo</button> */}
                <a
                  href={ROUTE_SCHEDULE_DEMO}
                  className="btn-demo"
                  target="blank"
                >
                  {t("home.scheduleDemo")}
                </a>
              </div>
            </Col>

            <Col xs={12} md={12} lg={4} className="center">
              <div className="image-headerBox">
             <div></div>
                <img src={image1} alt="image" className="image1" />
                {/* <img src={image2} alt="image" className="image2" /> */}
                <img
                  src={image3}
                  alt={`${t("tova")}`}
                  className="image3"
                />
                <img
                  src={image2}
                  alt={`${t("tova")}`}
                  className="image2"
                />
              </div>
            </Col>
          </Row>
        </Row>

        <div className="center px-2 px-md-0">
          <div className="what-tova">
            <h2>{t("home.easyCashlessHeading")}</h2>
            <p>{t("home.easyCashlessPara1")}</p>
          </div>
        </div>

        <div className="d-none d-md-flex w-full center overflow-hidden px-3">
          <div className="web-home-contentImg">
            <img
              src={homeContent}
              alt={`${t("tova")}`}
              className="web-home-img"
            />
          </div>
        </div>

        <div className="d-md-none d-sm-flex w-full  px-1 center">
          <img
            src={homeContentMobile}
            alt={`${t("tova")}`}
            className="web-home-img"
          />
        </div>

        <Row
          className="w-100 center mx-0 py-4  mt-121px"
          style={{ backgroundColor: "#F5FCFF" }}
        >
          <Row className="max-w-1400px  px-0 py-100 relative ">
            <img
              src={mobile}
              alt={`${t("tova")}`}
              className="d-none d-lg-block web-mob"
            />
            <div className="w-100 bg-white my-5" style={{ flexWrap: "wrap" }}>
              <Col
                xs={12}
                md={12}
                lg={12}
                className="flex justify-content-between justify-content-md-evenly gap-4"
                style={{ flexWrap: "wrap" }}
              >
                <div className="d-none d-md-block mx-5">&nbsp;</div>
                <div className="home-feature  flex-col gap-3  pt-4 pt-md-5 pb-md-5 ps-0 ps-md-5 ms-0 ms-md-5">
                  <div className="home-feature-h ">
                    {t("home.featureHeading")}
                  </div>
                  <div className="gap-3 flex-start">
                    <img src={tik} alt={`${t("home.tik")}`} className="ms-1" />
                    {t("home.unlimitedTipping")}
                  </div>
                  <div className="gap-3 flex-start">
                    <img src={tik} alt={`${t("home.tik")}`} className="ms-1" />
                    {t("home.unlimitedTip")}
                  </div>
                  <div className="gap-3 flex-start">
                    <img src={tik} alt={`${t("home.tik")}`} className="ms-1" />
                    {t("home.whiteLabeledCard")}
                  </div>
                  <div className="gap-3 flex-start">
                    <img src={tik} alt={`${t("home.tik")}`} className="ms-1" />{" "}
                    {t("home.customizable")}
                  </div>
                  <div className="gap-3 flex-start">
                    <img src={tik} alt={`${t("home.tik")}`} className="ms-1" />{" "}
                    {t("home.customer")}
                  </div>
                </div>
                <div className="home-feature  flex-col gap-3 pb-4 pt-md-5 pb-md-5">
                  <div className="d-none d-sm-block">&nbsp;</div>
                  <div className="gap-3 flex-start">
                    <img src={tik} alt={`${t("home.tik")}`} className="ms-1" />
                    {t("home.flexibleOptions")}
                  </div>
                  <div className="gap-3 flex-start">
                    <img src={tik} alt={`${t("home.tik")}`} className="ms-1" />
                    {t("home.uniqueQR")}
                  </div>
                  <div className="gap-3 flex-start">
                    <img src={tik} alt={`${t("home.tik")}`} className="ms-1" />
                    {t("home.tripAdvisor")}
                  </div>
                  <div className="gap-3 flex-start">
                    <img src={tik} alt={`${t("home.tik")}`} className="ms-1" />
                    {t("home.detailed")}
                  </div>
                  <div className="gap-3 flex-start">
                    <img src={tik} alt={`${t("home.tik")}`} className="ms-1" />{" "}
                    {t("home.directly")}
                  </div>
                </div>
              </Col>
            </div>
          </Row>
        </Row>

        <div className=" w-full center px-2 overflow-hidden py-5 mt-5 mb-3 ">
          <h3 className="home-get-h font-bold">
            {t("home.getInTouchHeading")}
          </h3>
        </div>

        <Row className="w-100 center mx-0 px-2 px-md-0">
          <Row className="max-w-1400px signup-section space-between mx-0">
            <Col xs={12} md={6} lg={6} className="center ">
              <div className="signup-content text-white pt-5 pt-md-0 mb-3 mb-md-0">
                <h4> {t("home.scheduleDemo")}</h4>
                <p>{t("home.scheduleDemoPara1")}</p>
                {/* <button className='signup-button mt-38px'>Schedule a Demo</button> */}
                <a
                  href={ROUTE_SCHEDULE_DEMO}
                  className="btn-demo mt-38px "
                  target="blank"
                >
                  {t("home.scheduleDemo")}
                </a>
              </div>
            </Col>
            <Col xs={12} md={6} lg={6} className="bg-white border-32r center mt-5 mt-md-0 pb-5 pb-md-0">
              <div className="signup-content pt-4 pt-md-0">
                <h4> {t("home.contactUs")}</h4>
                <p>
                  {t("home.getInTouchPara1")}{" "}
                  <span className="text-info">{t("home.getInTouchPara2")}</span>
                  {t("home.getInTouchPara3")}
                </p>
                <ContactForm className="btn-demo border-darkBlue mt-38px"  label={`${t("home.contactUs")}`}/>
              </div>
            </Col>
          </Row>
        </Row>

        <div className=" w-full center px-2 overflow-hidden py-5 mt-5 mb-3 ">
          <h3 className="home-get-h font-600">{t("home.trusted")}</h3>
        </div>

        <div className="web-slider w-full  overflow-hidden  px-1 ">
          <Slider {...settings1}>
            <div>
              <img src={sliderlogo1} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo2} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo3} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo4} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo5} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo6} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo7} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo8} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo9} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo10} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo11} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo12} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo13} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo14} alt={`${t("home.tovaAcc")}`} />
            </div>
          </Slider>
          <Slider {...settings2}>
            <div>
              <img src={sliderlogo14} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo13} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo12} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo11} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo10} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo9} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo8} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo7} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo6} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo5} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo4} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo3} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo2} alt={`${t("home.tovaAcc")}`} />
            </div>
            <div>
              <img src={sliderlogo1} alt={`${t("home.tovaAcc")}`} />
            </div>
          </Slider>
        </div>

        <Row className="w-100 center mx-0 px-2 px-md-0">
          <Row className="additional-section ">
            <Col xs={12} md={5} lg={5} className="">
              <h3 className="home-get-h font-600 mb-4 mt-4 mt-md-0">
                {t("home.additionalHeading")}
              </h3>
            </Col>

            <Row className="center mb-4 mb-md-0">
              <Col
                xs={12}
                md={12}
                lg={12}
                className="gap-3 center"
                style={{ flexWrap: "wrap" }}
              >
                <div className="additional-box w-100 ">
                  <div>
                    <h4>{t("home.additionalAbout")}</h4>
                    <p>{t("home.additionalAboutPara")}</p>
                  </div>
                  <a href="/about" className="btn-add">
                    {t("home.btnAdditional")}
                    <img src={addtik} alt={`${t("home.tik")}`} />
                  </a>
                </div>

                <div className="additional-box w-100 ">
                  <div>
                    <h4>{t("home.additionalSupport")}</h4>
                    <p>{t("home.additionalSupportPara")}</p>
                  </div>
                  <a href="/support" className="btn-add">
                    {t("home.btnAdditionalQ&A")}
                    <img src={addtik} alt={`${t("home.tik")}`} />
                  </a>
                </div>
              </Col>
            </Row>
          </Row>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
