import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAccountDetailsReq, IAccountDetails, INewAccountGroup, IAccountTipSettings, IAccountReviewLink, IReviewLogoUpload, IAccountReviewOrderReset, IAccountPrimaryUser } from "../models/accountDetailsInterfaces";
import { get, post, put } from "./apiService";
import { SA_API_URL_ACCOUNT_DETAILS, SA_API_URL_ACCOUNT_LOGO_UPLOAD, SA_API_URL_ACCOUNT_LOGO_REMOVE, SA_API_URL_ACCOUNT_ACTIVATE, SA_API_URL_ACCOUNT_DEACTIVATE, SA_API_URL_ACCOUNT_UPDATE_DETAILS, SA_API_URL_ACCOUNT_UPDATE_BANK_DETAILS, SA_API_URL_ACC_GROUP_CREATE, ACC_API_URL_ACCOUNT_DETAILS, ACC_API_URL_ACCOUNT_LOGO_UPLOAD, ACC_API_URL_ACCOUNT_LOGO_REMOVE, ACC_API_URL_ACCOUNT_ACTIVATE, ACC_API_URL_ACCOUNT_DEACTIVATE, ACC_API_URL_ACCOUNT_UPDATE_DETAILS, ACC_API_URL_ACCOUNT_UPDATE_BANK_DETAILS, ACC_API_URL_ACC_GROUP_CREATE, ACC_API_URL_ACCOUNT_TIP_SETTINGS, SA_API_URL_ACCOUNT_TIP_SETTINGS, ACC_API_URL_ACCOUNT_REVIEW_LINK, SA_API_URL_ACCOUNT_REVIEW_LINK, SA_API_URL_ACCOUNT_TIP_LOGO_UPLOAD, SA_API_URL_ACCOUNT_TIP_IMAGE_UPLOAD, ACC_API_URL_ACCOUNT_TIP_LOGO_UPLOAD, ACC_API_URL_ACCOUNT_TIP_IMAGE_UPLOAD, ACC_API_URL_ACCOUNT_TIP_LOGO_REMOVE, SA_API_URL_ACCOUNT_TIP_LOGO_REMOVE, ACC_API_URL_ACCOUNT_TIP_IMAGE_REMOVE, SA_API_URL_ACCOUNT_TIP_IMAGE_REMOVE, ACC_API_URL_ACCOUNT_REVIEW_LOGO_UPLOAD, SA_API_URL_ACCOUNT_REVIEW_LOGO_UPLOAD, ACC_API_URL_ACCOUNT_RESET_REVIEW_ORDER, SA_API_URL_ACCOUNT_RESET_REVIEW_ORDER, ACC_API_URL_ACCOUNT_PRIMARY_USER_UPDATE, SA_API_URL_ACCOUNT_PRIMARY_USER_UPDATE } from '../config/api_urls'; 
import { arrAccountUserIds } from "../config/env";

// FUNCTION TO FETCH DETAILS OF AN ACCOUNT - ACCOUNT DETAILS PAGE
export const fetchAccountDetails = createAsyncThunk(
    'account/accountDetails',
    async({accountId, userRole}:IAccountDetailsReq,thunkAPI:any)=>{
        try{
            let url = (arrAccountUserIds.includes(userRole) ? ACC_API_URL_ACCOUNT_DETAILS : SA_API_URL_ACCOUNT_DETAILS)+accountId+'/'; 
            const response =get(url);
            return (await response).data
        }
        catch(error){
            return thunkAPI.rejectWithValue(error)
        }
    }
)

// FUNCTION TO UPLOAD LOGO OF AN ACCOUNT - ACCOUNT DETAILS PAGE
export const uploadAccountLogo = createAsyncThunk(
    'uploadAccountLogo',
    async (form: IAccountDetails, thunkAPI: any) => {
      try {
        let url = (arrAccountUserIds.includes(form.userRole) ? ACC_API_URL_ACCOUNT_LOGO_UPLOAD : SA_API_URL_ACCOUNT_LOGO_UPLOAD)+form.accountId+'/'; 
        const response = post(url, form);
        return (await response).data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  );
  
// FUNCTION TO REMOVE LOGO OF AN ACCOUNT - ACCOUNT DETAILS PAGE
export const removeAccountLogo = createAsyncThunk(
  'removeAccountLogo',
  async (form: IAccountDetails, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(form.userRole) ? ACC_API_URL_ACCOUNT_LOGO_REMOVE : SA_API_URL_ACCOUNT_LOGO_REMOVE)+form.accountId+'/'; 
      const response = post(url, '');
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO MODIFY ACCOUNT PRIMARY USER - ACCOUNT DETAILS PAGE
export const modifyAccPrimaryUser = createAsyncThunk(
  'modifyAccPrimaryUser',
  async (form: IAccountPrimaryUser, thunkAPI: any) => {
    try {
      let url = arrAccountUserIds.includes(form.userRole) ? ACC_API_URL_ACCOUNT_PRIMARY_USER_UPDATE  : SA_API_URL_ACCOUNT_PRIMARY_USER_UPDATE; 
      const response = post(url, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO ACTIVATE AN ACCOUNT - ACCOUNT DETAILS PAGE
export const activateAccount = createAsyncThunk(
  'activateAccount',
  async (form: IAccountDetails, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(form.userRole) ? ACC_API_URL_ACCOUNT_ACTIVATE : SA_API_URL_ACCOUNT_ACTIVATE)+form.accountId+'/'; 
      const response = put(url, '');
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO DEACTIVATE AN ACCOUNT - ACCOUNT DETAILS PAGE
export const deactivateAccount = createAsyncThunk(
  'deactivateAccount',
  async (form: IAccountDetails, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(form.userRole) ? ACC_API_URL_ACCOUNT_DEACTIVATE : SA_API_URL_ACCOUNT_DEACTIVATE)+form.accountId+'/'; 
      const response = put(url, '');
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO UPDATE BANK ACCOUNT DETAILS OF AN ACCOUNT - ACCOUNT DETAILS PAGE
export const updateBankAccountDetail = createAsyncThunk(
  'account/updateBankAccountDetail',
  async (form: IAccountDetails["bankDetails"], thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(form.userRole) ? ACC_API_URL_ACCOUNT_UPDATE_BANK_DETAILS : SA_API_URL_ACCOUNT_UPDATE_BANK_DETAILS)+form.accountId+'/'; 
      const response = post(url, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO MODIFY GENERAL DETAILS OF AN ACCOUNT - ACCOUNT DETAILS PAGE
export const updateAccountDetail = createAsyncThunk(
  'account/updateAccountDetail',
  async (form: IAccountDetails, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(form.userRole) ? ACC_API_URL_ACCOUNT_UPDATE_DETAILS : SA_API_URL_ACCOUNT_UPDATE_DETAILS)+form.accountId+'/'; 
      const response = put(url, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO CREATE NEW GROUP TO ACCOUNT - ACCOUNT DETAILS PAGE
export const createNewGroup = createAsyncThunk(
  'account/createNewGroup',
  async (newGroup: INewAccountGroup, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(newGroup.userRole) ? ACC_API_URL_ACC_GROUP_CREATE : SA_API_URL_ACC_GROUP_CREATE); 
      let newGroupData: INewAccountGroup = {
        hotelId: newGroup.hotelId,
        name: newGroup.name
      };
      const response = post(url, newGroupData);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO MODIFY TIP SETTINGS OF AN ACCOUNT - ACCOUNT DETAILS PAGE
export const updateAccTipSettings = createAsyncThunk(
  'account/updateAccTipSettings',
  async (form: IAccountTipSettings, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(form.userRole) ? ACC_API_URL_ACCOUNT_TIP_SETTINGS : SA_API_URL_ACCOUNT_TIP_SETTINGS)+form.accountId+'/'; 
      const response = put(url, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO MODIFY TIP PAGE CONFIRMATION MESSAGE TEXT OF AN ACCOUNT - ACCOUNT DETAILS PAGE
// SAME AS "updateAccTipSettings" - ADDED TO SHOW A DIFFERENT MESSAGE ON SUCCESS/FAILURE
export const updateTipConfirmationMessage = createAsyncThunk(
  'account/updateTipConfirmationMessage',
  async (form: IAccountTipSettings, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(form.userRole) ? ACC_API_URL_ACCOUNT_TIP_SETTINGS : SA_API_URL_ACCOUNT_TIP_SETTINGS)+form.accountId+'/'; 
      const response = put(url, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO MODIFY REVIEW PAGE TITLE AND BODY TEXT OF AN ACCOUNT - ACCOUNT DETAILS PAGE
// SAME AS "updateAccTipSettings" - ADDED TO SHOW A DIFFERENT MESSAGE ON SUCCESS/FAILURE
export const updateAccReviewTitleBody = createAsyncThunk(
  'account/updateAccReviewTitlBody',
  async (form: IAccountTipSettings, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(form.userRole) ? ACC_API_URL_ACCOUNT_TIP_SETTINGS : SA_API_URL_ACCOUNT_TIP_SETTINGS)+form.accountId+'/'; 
      const response = put(url, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO MODIFY REVIEW DETAILS OF AN ACCOUNT - ACCOUNT DETAILS PAGE
export const updateAccReviewSettings = createAsyncThunk(
  'account/updateAccReviewSettings',
  async (form: IAccountReviewLink, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(form.userRole) ? ACC_API_URL_ACCOUNT_REVIEW_LINK : SA_API_URL_ACCOUNT_REVIEW_LINK)+form.id+'/'; 
      const response = put(url, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO UPLOAD LOGO FOR TIP PAGE OF AN ACCOUNT - ACCOUNT DETAILS PAGE
export const uploadAccountTipLogo = createAsyncThunk(
  'uploadAccountTipLogo',
  async (form: IAccountDetails, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(form.userRole) ? ACC_API_URL_ACCOUNT_TIP_LOGO_UPLOAD : SA_API_URL_ACCOUNT_TIP_LOGO_UPLOAD)+form.accountId+'/'; 
      const response = post(url, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
); 
// FUNCTION TO REMOVE LOGO FOR TIP PAGE OF AN ACCOUNT - ACCOUNT DETAILS PAGE
export const removeAccountTipLogo = createAsyncThunk(
  'removeAccountTipLogo',
  async (form: IAccountDetails, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(form.userRole) ? ACC_API_URL_ACCOUNT_TIP_LOGO_REMOVE : SA_API_URL_ACCOUNT_TIP_LOGO_REMOVE)+form.accountId+'/'; 
      const response = post(url, '');
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TO UPLOAD LOGO FOR TIP PAGE OF AN ACCOUNT - ACCOUNT DETAILS PAGE
export const uploadAccountTipImage = createAsyncThunk(
  'uploadAccountTipImage',
  async (form: IAccountDetails, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(form.userRole) ? ACC_API_URL_ACCOUNT_TIP_IMAGE_UPLOAD : SA_API_URL_ACCOUNT_TIP_IMAGE_UPLOAD)+form.accountId+'/'; 
      const response = post(url, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);   
// FUNCTION TO REMOVE IMAGE FOR TIP PAGE OF AN ACCOUNT - ACCOUNT DETAILS PAGE
export const removeAccountTipImage = createAsyncThunk(
  'removeAccountTipImage',
  async (form: IAccountDetails, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(form.userRole) ? ACC_API_URL_ACCOUNT_TIP_IMAGE_REMOVE : SA_API_URL_ACCOUNT_TIP_IMAGE_REMOVE)+form.accountId+'/'; 
      const response = post(url, '');
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


// FUNCTION TO UPLOAD LOGO FOR REVIEW PLATFORM LINK - ACCOUNT DETAILS PAGE
export const uploadReviewPlatformLinkLogo = createAsyncThunk(
  'uploadReviewPlatformLinkImage',
  async (form: IReviewLogoUpload, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(form.userRole) ? ACC_API_URL_ACCOUNT_REVIEW_LOGO_UPLOAD  : SA_API_URL_ACCOUNT_REVIEW_LOGO_UPLOAD)+form.reviewId+'/';  
      const response = post(url, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);  

// FUNCTION TO RESET THE DISPLAY ORDER OF REVIEW PLATFORMS  
export const updateReviewPlatformsOrder = createAsyncThunk(
  'account/updateReviewPlatformsOrder',
  async (form: IAccountReviewOrderReset, thunkAPI: any) => {
    try {
      let url = (arrAccountUserIds.includes(form.userRole) ? ACC_API_URL_ACCOUNT_RESET_REVIEW_ORDER : SA_API_URL_ACCOUNT_RESET_REVIEW_ORDER); 
      const response = put(url, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
 

