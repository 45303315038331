import React, { useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../helpers/hook";
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Box, Modal } from "@mui/material";
import ReactQuill from "react-quill";

import message from "../../../assets/images/message.svg";
import yellowrm from "../../../assets/images/yellowrm.svg";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import SignUpLeftPane from "../SignUpLeftPane";
import Nav from '../Nav';

import {
  INewSignUpRequestData,
  INewSignUpResendEmail,
} from "../../../models/signUpInterfaces";
import {
  getTandC,
  newAccountSignUp,
  newAccountSignUpResendEmail,
} from "../../../services/signUpServices";
import { selectAccountSignUpSlice, setSignUpResponseType } from "./signUpSlice";
import {
  API_RESP_USER_ADDED,
  ERR_USER_ALREADY_EXISTS,
} from "../../../config/apiResponseCodes";
import { setNotification } from "../../../services/apiService";

function SignUp() {
  const { t } = useTranslation();
  const ref = useRef();
  const tmpDate = new Date();
  const style1 = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 580,
    border: "none",
  };
  const style = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 950,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "8px",
    boxShadow: 24,
    p: 1,
  };

  const dispatch = useAppDispatch();

  const { isProcessingRequest, signUpResponseType, termsAndCondition } =
    useAppSelector(selectAccountSignUpSlice);

  const [signUpStage, setSignUpStage] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [showPwd, setShowPwd] = useState(false);
  const [maxDate, setMaxDate] = useState(
    dayjs(
      new Date(
        tmpDate.getFullYear() -
          18 +
          "-" +
          (tmpDate.getMonth() + 1) +
          "-" +
          tmpDate.getDate()
      )
    ).format("YYYY-MM-DD")
  );
  const [showTandC, setShowTandC] = useState(false);
  
  const [resendEmail, setResendEmail] = useState("");
  const [showVerifyModal, setShowVerifyModal] = useState(false);

  const onHandleSave = (e) => {
    e.preventDefault();
    const req: INewSignUpRequestData = {
      firstName: firstName,
      lastName: lastName,
      birthDate: birthDate,
      username: username,
      password: password,
    };
    dispatch(newAccountSignUp(req));
  } 

  const onResendEmail = (e) => {
    e.preventDefault();
    setResendEmail(username)
    setShowVerifyModal(true);
  };
  
  const onHandleResendEmail = (e) => {
        e.preventDefault();
    const req: INewSignUpResendEmail = {
      oldEmail: username,
      newEmail:resendEmail,
    };
    dispatch(newAccountSignUpResendEmail(req));
    setShowVerifyModal(false)
  };

  const handleTandC = () => {
    setShowTandC(true);
    dispatch(getTandC(2));
  };

  React.useEffect(() => {
    if (
      signUpResponseType == API_RESP_USER_ADDED ||
      signUpResponseType == "succeeded"
    ) {
      setNotification(
        t("projectName"),
        t("accountSignUp.msgUserAdded"),
        "success",
        "accountSignUp"
      );
      dispatch(setSignUpResponseType(""));
      setSignUpStage(2);
    } else if (
      signUpResponseType == ERR_USER_ALREADY_EXISTS ||
      signUpResponseType == "error"
    ) {
      let errMsg =
        signUpResponseType == ERR_USER_ALREADY_EXISTS
          ? t("accountSignUp.errorUserExists")
          : t("accountSignUp.errorSignUpFailed");
        setNotification(
          t("projectName"),
          errMsg, 
          "failed",
          "accountSignUp"
        );
      dispatch(setSignUpResponseType(""));
    } else if (signUpResponseType == "emailResentSuccess") {
      setNotification(
        t("projectName"),
        t("accountSignUp.msgEmailResentSuccess"), 
        "success",
        "accountSignUp"
      );
      dispatch(setSignUpResponseType(""));
    } else if (signUpResponseType=="emailResentError" || signUpResponseType==ERR_USER_ALREADY_EXISTS) {
      setNotification(
        t("projectName"),
        (signUpResponseType==ERR_USER_ALREADY_EXISTS ? t("accountSignUp.errorUserExists") : t("accountSignUp.msgEmailResentFailed")), 
        "failed",
        "accountSignUp"
      );
      dispatch(setSignUpResponseType(""));
    }
  }, [signUpResponseType]);

  const modules = {
    toolbar: false,
  };

  return (
    <div>
      <Nav/>
      <div className="w-100 flex">
        <div className="w-30perc ">
          <SignUpLeftPane signUpStep={signUpStage} />
        </div>
        <div className="w-70perc bg-light ">
          {/* SIGN UP - PROVIDE YOU DETAILS SECTION  */}
          {signUpStage == 1 && (
            <>
              <div className="center mt-38px ">
                <form
                  className="loginbox stepperbody"
                  onSubmit={(e) => onHandleSave(e)}
                >
                  <h1>{t("accountSignUp.signup")}</h1>
                  <h2 className="mb-22px ">
                    {t("accountSignUp.provideyourdetails")}
                  </h2>
                  <div className="cinput ">
                    <input
                      type="text"
                      className={"w-100 mt-2px "}
                      placeholder={`${t("accountSignUp.firstnamePlaceholder")}`}
                      required
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      maxLength={150}
                    />
                    <input
                      type="text"
                      className={"w-100 "}
                      placeholder={`${t("accountSignUp.lastnamePlaceholder")}`}
                      required
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      maxLength={150}
                    />
                  </div>
                  <p className="flex-start text-grey font-lite">
                    {t("accountSignUp.validateMessage")}
                  </p>

                  <input
                    type="text"
                    className="w-100 mt-25px"
                    placeholder={`${t("accountSignUp.birthdatePlaceholder")}`}
                    onFocus={(e) => (e.target.type = "date")}
                    // onBlur={(e) => {
                    //   e.target.type = "text";
                    // }}
                    required
                    max={maxDate}
                    value={birthDate}
                    onChange={(e) => setBirthDate(e.target.value)}
                    maxLength={10}
                  />
                  <p className="text-start text-grey font-lite">
                    {t("accountSignUp.validateMessage2")}
                    <br />
                    {t("accountSignUp.validateMessage21")}
                  </p>

                  <input
                    type="email"
                    className="w-100 mt-11px"
                    placeholder={`${t("accountSignUp.emailPlaceholder")}`}
                    required
                    onChange={(e) => setusername(e.target.value)}
                    value={username}
                    maxLength={150}
                  />
                  <p className="text-start text-grey font-lite">
                    {t("accountSignUp.validateMessage3")}
                  </p>

                  <div className="relative">
                    <input
                      type={showPwd ? "text" : "password"}
                      className="w-100 mt-11px"
                      placeholder={`${t("accountSignUp.passwordPlaceholder")}`}
                      onChange={(e) => setpassword(e.target.value)}
                      value={password}
                      required
                      maxLength={150}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "18px",
                        right: "10px",
                      }}
                      onClick={(e) => setShowPwd(!showPwd)}
                    >
                      {showPwd ? (
                        <VisibilityIcon className="text-grey" />
                      ) : (
                        <VisibilityOffIcon className="text-grey" />
                      )}
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="rounded-2 p-12px text-white border-none mt-18px bg-blue"
                  >
                    {t("accountSignUp.agreeandcontinue")}
                  </button>
                  <p className="text-start text-dark mt-18px">
                    {t("accountSignUp.TandC1")}
                    <b>{t("accountSignUp.TandC2")}</b>
                    {t("accountSignUp.TandC3")}
                    <b
                      className="text-blue pointer-cursor"
                      onClick={handleTandC}
                    >
                      {t("accountSignUp.TandC4")}
                      <br />
                      {t("accountSignUp.TandC5")}
                    </b>
                    {t("accountSignUp.TandC6")}
                    <b
                      className="text-blue pointer-cursor"
                      onClick={handleTandC}
                    >
                      {t("accountSignUp.TandC7")}
                    </b>
                    {t("accountSignUp.TandC8")}
                    <b
                      className="text-blue pointer-cursor"
                      onClick={handleTandC}
                    >
                      {t("accountSignUp.TandC9")}
                    </b>
                  </p>
                </form>
              </div>
            </>
          )}

          {/* SIGN UP - VERIFY EMAIL SECTION  */}
          {signUpStage == 2 && (
            <>
              <div className="center mt-101px ">
                <form className="loginbox stepperbody verify">
                  <img
                    src={message}
                    alt=""
                    style={{ height: "40px", width: "52px" }}
                  />
                  <h1>{t("verifyEmail.verifyYourEmail")}</h1>

                  <p>
                    {t("verifyEmail.part1", { email: username })}
                    <br />
                    {t("verifyEmail.part2")}
                    <br /> {t("verifyEmail.part3")}
                  </p>

                  <p>
                    {t("verifyEmail.part4")}
                    <br />
                    {t("verifyEmail.part5")}
                  </p>
                  
                  <button type="button" className="mt-33px" onClick={e=>onResendEmail(e)}>
                    {t("verifyEmail.resetButton")}
                  </button>
                  
                </form>
              </div>
            </>
          )}
        </div>
      </div>

      <Modal
        open={showTandC}
        onClose={() => setShowTandC(false)}
        aria-labelledby={"Edit Account Admin User"}
        aria-describedby=""
      >
        <Box sx={style}>
          <div className="w-100 px-4 py-3 rounded-3 ">
            <div>
              {/* {JSON.stringify(data)} */}
              <div className=""> 
              <div>
                <ReactQuill
                  theme="snow"
                  value={termsAndCondition}
                  // onChange={handleChange}
                  modules={modules}
                  readOnly={true}
                  className="w-100"
                />
              </div>
              </div>
              <div className="flex-end border-top mt-3 pt-3">
                <button
                  className="cancelbtn "
                  onClick={() => setShowTandC(false)}
                >
                  {t("buttons.close")}
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={showVerifyModal}
        onClose={() => setShowVerifyModal(false)}
        aria-labelledby={"Edit Account Admin User"}
        aria-describedby=""
      >
        <Box sx={style1}>
          <div className="w-100 px-4 py-3 rounded-3">
          <div>
              <div className="idproof-modal text-center relative">
                <div className="page-heading">
                  {/* <CloseRoundedIcon
                    onClick={() => setShowVerifyModal(false)}
                    className="close"
                  /> */}
                  <img src={yellowrm} alt="" />
                </div>
                <div className="idproof-flex-center ">
                  <p>{t("verifyEmail.resetemailParagraph")}</p>
                  <div className="w-100">
                  <input
                    type="email"
                    className="w-100 mt-11px"
                    placeholder={`${t("accountSignUp.emailPlaceholder")}`}
                    required
                    onChange={(e) => setResendEmail(e.target.value)}
                    value={resendEmail}
                    maxLength={150}
                  />
                  </div>
                </div>
                <div className="idproof-margin"></div>
                <div className="flex w-100">
                  <button
                    type="button"
                    className="cancelbtn w-100"
                    onClick={() => setShowVerifyModal(false)}
                  >
                    {t("common.cancel")}
                  </button>
                  <button
                    type="button"
                    className="cancelbtn bg-blue text-white w-100  ml-20px"
                    onClick={(e) => onHandleResendEmail(e)}
                  >
                    <span className="">{t("verifyEmail.resetemail")}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default SignUp;
