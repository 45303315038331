import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface PageState {
  currentPage: number;
  totalRows: number;
  isLoading: boolean;
}

const initialState: PageState = {
  currentPage: 1,
  totalRows: 0,
  isLoading: true,
};

export const accountsListPaginationSlice = createSlice({
  name: 'accountsListPagination',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    setTotalRows: (state, action: PayloadAction<any>) => {
      state.totalRows = action.payload;
    },
    setStartLoading: (state) => {
      state.isLoading = true;
    },
    setStopLoading: (state) => {
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentPage, setTotalRows, setStartLoading, setStopLoading } =
  accountsListPaginationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAccountsListPaginationSlice = (state: RootState) => state.accountsListPagination;

export const accountsListPaginationReducer = accountsListPaginationSlice.reducer;
