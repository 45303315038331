import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "./App.css";
import "react-notifications-component/dist/theme.css";

import {
  ROUTE_DASHBOARD, 
  ROUTE_ACCOUNTS_LIST,
  ROUTE_ACCOUNT_DETAILS,
  ROUTE_STAFF_LIST,
  ROUTE_STAFF_PROFILE,
  ROUTE_TIPS_LIST,
  ROUTE_PAYMENTS_LIST,
  ROUTE_COMMISSIONS_LIST,
  ROUTE_SETTINGS,
  ROUTE_TERMS,
  ROUTE_SA_USERS,
  ROUTE_HOTEL_USERS,
  ROUTE_SA_LOGIN,
  ROUTE_ACCOUNTS_LOGIN,
  ROUTE_LOGIN,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_FORGOT_PASSEMAIL_TEMPLATE,
  ROUTE_RESET_PASSWORD,
  ROUTE_ACCOUNT_SIGNUP,
  ROUTE_ACCOUNT_SIGNUP_VERIFY_ACCOUNT,
  ROUTE_ACCOUNT_SIGNUP_AGREEMENT,
  ROUTE_ACCOUNT_SIGNUP_ACH,
  ROUTE_ACCOUNT_SIGNUP_LOGO_UPLOAD,
  ROUTE_ACCOUNT_GROUP,
  ROUTE_TIP,
  ROUTE_TIP_SUCCESS,
  ROUTE_ACCOUNT_WITHHOLDINGS,
  ROUTE_TIP_THANKYOU,
  ROUTE_TIP_RECEIPT,
  ROUTE_ABOUT,
  ROUTE_SUPPORT,
  ROUTE_404,
  ROUTE_STAFF_REG_WITHOUT_EMAIL
} from "./config/env";
import { 
  ROUTE_QRCODES, ROUTE_QRC_CHECKOUT, ROUTE_QRC_PAYMENT, ROUTE_QRC_REVIEW_PAY, ROUTE_QRC_ORDER, ROUTE_QRC_PURCHASE_HISTORY, ROUTE_QRC_RECEIPT, ROUTE_QRC_SHIPPING
} from "./config/env_qrcodes";
// import Footer from "./components/Footer";

import DashBoard from "./pages/Dashboard"; 
import AccountsList from "./pages/AccountsList";
import AdminUsers from "./pages/AdminUsers";
import StaffList from "./pages/StaffList";
import UsersList from "./pages/AccountUsersList";
import TipsList from "./pages/TipsList";
import CommissionsList from "./pages/CommissionsList";
import PaymentsList from "./pages/PaymentsList";
import { LoginPage } from "./pages/LoginPage";
import { AccountsLoginPage } from "./pages/LoginPage/accountsLogin";
import ProtectedRoutes from "./utilities/ProtectedRoutes";
import AccountDetail from "./pages/AccountDetails";
import StaffDetail from "./pages/StaffDetail";
import Terms from "./pages/Terms";
import Settings from "./pages/Settings";
import Login from "./pages/Login_template";
import ForgotPassword from "./pages/ForgotPassword";
import ForgotPassEmailTemplate from "./pages/ForgotPassEmailTemplate";
import ResetPassword from "./pages/ResetPassword";
import GroupSettings from "./pages/GroupSettings";
import { TipForm } from './pages/Tip';
import { Success } from './pages/Success';

import SignUp from "./pages/AccountSignUp/SignUp";
import BusinessDetails from "./pages/AccountSignUp/BusinessDetails";
import Agreement from "./pages/AccountSignUp/Agreement";
import ACH from "./pages/AccountSignUp/ACH";
import UploadLogo from "./pages/AccountSignUp/UploadLogo";

// STAF REGISTRATION - TEMPORARY - NEED TO REMOVE WHEN MOBILE APP IS UP 
// import { StaffRegister } from "./pages/StaffRegister"; 
import { SetLoader } from "./components/SetLoader";
import Withholdings from "./pages/Withholdings";
import HomePage from "./webpages/HomePage";
import About from "./webpages/About";
import Support from "./webpages/Support";
import ThankYou from "./pages/ThankYouPage";
import ReceiptPage from "./pages/ThankYouPage/ReceiptPage";
import { setIntercept } from "./services/apiService";
import UrlNotFound from "./pages/404/404";
import StaffRegisterWithoutEmail from "./pages/StaffRegisterWithoutEmail";
import QRCodes from "./pages/QRCodes"; 
import QRCodesCheckOut from "./pages/QRCodesCheckOut";
import QRCodesShipping from "./pages/QRCodesShipping";
import QRCodesPayment from "./pages/QRCodesPayment";
import QRReviewAndPay from "./pages/QRCodesPayment/QRReviewAndPay";
import QRCodesPlaceOrder from "./pages/QRCodesPayment/QRCodesPlaceOrder";
import QRCodesYourOrders from "./pages/QRCodesYourOrders";
import ORCodesOrderReceipt from "./pages/QRCodesYourOrders/ORCodesOrderReceipt";

function App() {

  React.useEffect(() => {
    setIntercept();
  }, []);

  return (
    <div className="App">
      <Router  >
        <ReactNotifications />
        <SetLoader />
        {/* <Navbar /> */}

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path={ROUTE_ABOUT} element={<About />} />
          <Route path={ROUTE_SUPPORT} element={<Support />} />
          <Route path={ROUTE_404} element={<UrlNotFound />} />
          <Route path={ROUTE_SA_LOGIN} element={<LoginPage />} />
          <Route path={ROUTE_ACCOUNTS_LOGIN} element={<AccountsLoginPage />} />
          <Route path={ROUTE_ACCOUNT_SIGNUP} element={<SignUp />} />
          <Route path={ROUTE_ACCOUNT_SIGNUP_VERIFY_ACCOUNT} element={<BusinessDetails />} />
          <Route path={ROUTE_ACCOUNT_SIGNUP_AGREEMENT} element={<Agreement />} />
          <Route path={ROUTE_ACCOUNT_SIGNUP_ACH} element={<ACH />} />
          <Route path={ROUTE_ACCOUNT_SIGNUP_LOGO_UPLOAD} element={<UploadLogo />} />

          <Route path={ROUTE_LOGIN} element={<Login />} />
          <Route path={ROUTE_FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={ROUTE_RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={ROUTE_FORGOT_PASSEMAIL_TEMPLATE} element={<ForgotPassEmailTemplate />} />

          {/* // STAFF REGISTRATION - COMMENTED FOR FUTURE USE */}
          {/* <Route path="/register/:id" element={<StaffRegister />} /> */}

          <Route path={ROUTE_TIP + "/:accCode/:psCode/:psType"} element={<TipForm />} />
          {/* accCode: Account Code, psCode: PoolCode OR StaffCode, psType: Used to Identify 'psCode' is Pool OR Staff */}
          <Route path={ROUTE_TIP_SUCCESS} element={<Success />} />
          <Route path={ROUTE_TIP_THANKYOU} element={<ThankYou />} />
          <Route path={ROUTE_TIP_RECEIPT} element={<ReceiptPage />} />

          <Route element={<ProtectedRoutes />}>
            <Route path={`${ROUTE_ACCOUNT_DETAILS}/:id`} element={<AccountDetail />} />
            <Route path={`${ROUTE_ACCOUNT_GROUP}/:id`} element={<GroupSettings />} />

            <Route path={ROUTE_DASHBOARD} element={<DashBoard />} />
            <Route path={ROUTE_ACCOUNTS_LIST} element={<AccountsList />} />
            <Route path={ROUTE_ACCOUNT_WITHHOLDINGS} element={<Withholdings />} />
            <Route path={ROUTE_STAFF_PROFILE} element={<StaffDetail />} />
            <Route path={ROUTE_STAFF_LIST} element={<StaffList />} />
            <Route path={ROUTE_TIPS_LIST} element={<TipsList />} />
            <Route path={ROUTE_PAYMENTS_LIST} element={<PaymentsList />} />
            <Route
              path={ROUTE_COMMISSIONS_LIST}
              element={<CommissionsList />}
            />
            <Route path={ROUTE_SA_USERS} element={<AdminUsers />} />
            <Route path={ROUTE_HOTEL_USERS} element={<UsersList />} />
            <Route path={ROUTE_TERMS} element={<Terms />} />
            <Route path={ROUTE_SETTINGS} element={<Settings />} />

            <Route path={ROUTE_STAFF_REG_WITHOUT_EMAIL} element={<StaffRegisterWithoutEmail />} />

            {/* TO TEST DIFFERENT PROJECT IDS - AFTER TESTING REMOVE THIS ROUTE WITH projectId PARAMETER */}
            <Route path={`${ROUTE_QRCODES}/:projectId`} element={<QRCodes />} />

            <Route path={ROUTE_QRCODES} element={<QRCodes />} />
            <Route path={ROUTE_QRC_CHECKOUT} element={<QRCodesCheckOut />} />
            <Route path={ROUTE_QRC_SHIPPING} element={<QRCodesShipping/>} />
            <Route path={ROUTE_QRC_PAYMENT} element={<QRCodesPayment/>} />
            <Route path={ROUTE_QRC_REVIEW_PAY} element={<QRReviewAndPay/>} />
            <Route path={ROUTE_QRC_ORDER} element={<QRCodesPlaceOrder/>} />
            <Route path={ROUTE_QRC_PURCHASE_HISTORY} element={<QRCodesYourOrders/>} />
            <Route path={ROUTE_QRC_RECEIPT} element={<ORCodesOrderReceipt/>} />
           

          </Route>
        </Routes>
        {/* AS FOOTER WAS LOADING FROM CACHE (SOMETIMES FOOTER SHOWS IN PUBLIC FILES, SOMETIMES DO NOT SHOW IN THE PROTECTED ROUTES) , IT IS MOVED TO "ProtectedRoutes" SO THAT IT SHOULD SHOW IN THE AUTHENICATED FILES ONLY */}
        {/* <Footer /> */}
      </Router>

    </div>
  );
}

export default App;
