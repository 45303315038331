import { createSlice } from "@reduxjs/toolkit";
import { RootState } from '../../store';
import { IAccountDetails } from "../../models/accountDetailsInterfaces";
import { activateAccount, deactivateAccount, fetchAccountDetails, removeAccountLogo, updateAccountDetail, updateBankAccountDetail, uploadAccountLogo, createNewGroup, updateAccTipSettings, updateTipConfirmationMessage, updateAccReviewTitleBody, updateAccReviewSettings,  uploadAccountTipImage, uploadAccountTipLogo, removeAccountTipLogo, removeAccountTipImage, uploadReviewPlatformLinkLogo, updateReviewPlatformsOrder, modifyAccPrimaryUser } from "../../services/accountDetailsServices";
import { ERR_ACC_NAME_EXIST, GROUP_NAME_EXISTS, QRCODE_GENERATION_FAILED } from "../../config/apiResponseCodes"
import { setNotification } from "../../services/apiService";
import i18n from "../../utilities/i18n";
export interface DataState {
    accountDetails: IAccountDetails;
    loading: 'idle' | 'loading'|'updated' | 'failed' |'error'| 'succeeded' | 'uploaded' | 'statusChangeSuccess' | 'groupCreated' | 'groupCreationFailed' | `${GROUP_NAME_EXISTS}` | 'tipSettingsUpdated' | 'accountReviewPlatformDataUpdated' | 'accTipLogoUploaded' | 'accTipImageUploaded' | 'accTipLogoRemoved' | 'accTipImageRemoved' | 'reviewTitleBodyUpdated' | 'accReviewLogoUploaded' | 'accReviewPlatformsOrderReset' | 'primaryUserChangeSuccess'; 
    accountLogo:string;
    loadingForNavbar: string; // CREATED TO RELOAD THE GROUP SUBMENU IN navbar
    reviewLinkLogoURL:string;
}

const initialState: DataState = {
    accountDetails: {} as IAccountDetails,
    accountLogo:'',
    loading: 'idle',
    loadingForNavbar: "",
    reviewLinkLogoURL:"",
}

export const accountDetailsSlice = createSlice({
    name: 'accountDetailsSlice',
    initialState,
    reducers: {
      resetLoading: (state) => {
        state.loading = 'idle';
      },
      resetLoadingForNavbar: (state) => {
        state.loadingForNavbar = '';
      },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAccountDetails.pending, (state) => {
            state.loading = 'loading'
        });
        builder.addCase(fetchAccountDetails.fulfilled, (state, action) => {
            state.accountDetails = action.payload
            state.loading = "succeeded"
        });
        builder.addCase(fetchAccountDetails.rejected, (state) => {
            state.loading = 'failed'
        });

        // ********** UPLOAD ACCOUNT LOGO ********************************
        builder.addCase(uploadAccountLogo.pending, (state) => {
            state.loading = 'loading';
          });
          builder.addCase(uploadAccountLogo.fulfilled, (state, action) => {
            state.accountLogo = action.payload.logoUrl
            state.loading = 'uploaded';
            setNotification(
              i18n.t("messages.appName"),
              i18n.t("messages.hotelLogoUpdated"),
              "success",
              "uploadAccountLogo"
            );
          });
          builder.addCase(uploadAccountLogo.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            console.log('payload2: ', payload);
            setNotification(
              i18n.t("projectName"),
              i18n.t("messages.logoUploadFailed"), 
              "failed",
              "uploadAccountLogo"
            ) 
          });
          
          // ********************* REMOVE ACCOUNT LOGO **********************
          builder.addCase(removeAccountLogo.pending, (state) => {
            state.loading = 'loading';
          }); 
          builder.addCase(removeAccountLogo.fulfilled, (state, action) => {
            state.loading = 'uploaded';
            state.accountLogo ='';
            setNotification(
              i18n.t("messages.appName"),
              i18n.t("messages.removeLogo"),
              "success",
              "removeAccountLogo"
            );
          });
          builder.addCase(removeAccountLogo.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            console.log('payload2: ', payload);
            setNotification(
              i18n.t("projectName"),
              i18n.t("messages.removeLogoFailed"), 
              "failed",
              "removeAccountLogo"
            ) 
          }); 

          builder.addCase(modifyAccPrimaryUser.pending, (state) => {
            state.loading = 'idle';
          });
          builder.addCase(modifyAccPrimaryUser.fulfilled, (state, action) => {
            state.loading = 'primaryUserChangeSuccess';  
            state.accountLogo ='';
            setNotification(
              i18n.t("messages.appName"),
              i18n.t("messages.accPrimaryUserChangeSuccess"),
              "success",
              "modifyAccPrimaryUser"
            );
          });
          builder.addCase(modifyAccPrimaryUser.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            console.log('payload2: ', payload);
            setNotification(
              i18n.t("projectName"),
              i18n.t("messages.accPrimaryUserChangeFailed"), 
              "failed",
              "modifyAccPrimaryUser"
            ) 
          });

          builder.addCase(activateAccount.pending, (state) => {
            state.loading = 'idle';
          });
          builder.addCase(activateAccount.fulfilled, (state, action) => {
            state.loading = 'statusChangeSuccess'; // state.loading = 'succeeded';
            state.accountLogo ='';
            setNotification(
              i18n.t("messages.appName"),
              i18n.t("messages.accountActivated"),
              "success",
              "activateAccount"
            );
          });
          builder.addCase(activateAccount.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            console.log('payload2: ', payload);
            setNotification(
              i18n.t("projectName"),
              i18n.t("messages.accountActivationFailed"), 
              "failed",
              "activateAccount"
            ) 
          });
          
          builder.addCase(deactivateAccount.pending, (state) => {
            state.loading = 'idle';
          });
          builder.addCase(deactivateAccount.fulfilled, (state, action) => {
            state.loading = 'statusChangeSuccess';
            state.accountLogo ='';
            setNotification(
              i18n.t("messages.appName"),
              i18n.t("messages.accountDeactivated"),
              "success",
              "deactivateAccount"
            );
          });
          builder.addCase(deactivateAccount.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            console.log('payload2: ', payload);
            setNotification(
              i18n.t("projectName"),
              i18n.t("messages.accountDeactivationFailed"), 
              "failed",
              "activateAccount"
            ) 
          });


          builder.addCase(updateBankAccountDetail.pending, (state) => {
            state.loading = 'loading';
          });
          builder.addCase(updateBankAccountDetail.fulfilled, (state, action) => {
            state.loading = 'updated';
            setNotification(
              i18n.t("messages.appName"),
              i18n.t("messages.statusUpdated"),
              "success",
              "updateBankAccountDetail"
            );
          });
          builder.addCase(updateBankAccountDetail.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            setNotification(
              i18n.t("messages.appName"),
              payload.response.data.message,
              "failed",
              "updateBankAccountDetail"
            );
          });

          builder.addCase(updateAccountDetail.pending, (state) => {
            state.loading = 'loading';
          });
          builder.addCase(updateAccountDetail.fulfilled, (state, action) => {
            state.loading = 'updated';
            setNotification(
              i18n.t("messages.appName"),
              i18n.t("messages.statusUpdated"),
              "success",
              "updateAccountDetail"
            );
          });
          builder.addCase(updateAccountDetail.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            let errMsg = payload?.response?.data?.message===ERR_ACC_NAME_EXIST ? i18n.t("messages.editAccountNameExist") : i18n.t("messages.updationFailed"); 
            setNotification(
              i18n.t("projectName"),
              errMsg, 
              "failed",
              "updateAccountDetail"
            ); 
          });
          
          builder.addCase(createNewGroup.pending, (state) => {
            state.loading = 'loading';
            state.loadingForNavbar = ""; 
          });
          builder.addCase(createNewGroup.fulfilled, (state, action) => {
            state.loading = 'groupCreated';
            state.loadingForNavbar = "groupCreated"; 
            setNotification(
              i18n.t("messages.appName"),
              i18n.t("messages.groupCreated"),
              "success",
              "updateAccountDetail"
            );
          });
          builder.addCase(createNewGroup.rejected, (state, { payload }: any) => {
            state.loading = "idle"; 
            state.loadingForNavbar = "";
            let msgText = payload?.response?.data?.message==GROUP_NAME_EXISTS ? i18n.t("group.groupNameDuplicate") 
            : payload?.response?.data?.message==QRCODE_GENERATION_FAILED ? i18n.t("group.qrcodeGenerationFailed") 
            : i18n.t("group.groupCreationFailed"); // 'groupCreationFailed'; 
            setNotification(
              i18n.t("projectName"),
              msgText,
              "failed",
              "uploadAccountLogo"
            );
          });

          // *************** MODIFY TIP SETTINGS ************************ 
          builder.addCase(updateAccTipSettings.pending, (state) => {
            state.loading = 'loading';
          });
          builder.addCase(updateAccTipSettings.fulfilled, (state, action) => {
            state.loading = 'tipSettingsUpdated';
            setNotification(
              i18n.t("messages.appName"),
              i18n.t("messages.tipSettingsUpdated"),
              "success",
              "updateAccTipSettingsSuccess"
            );
          });
          builder.addCase(updateAccTipSettings.rejected, (state, { payload }: any) => {
            state.loading = "idle"; 
            let msgText = i18n.t("group.tipSettingsSaveFailed");  
            setNotification(
              i18n.t("projectName"),
              msgText,
              "failed",
              "updateAccTipSettingsFailed"
            );
          });
          
          
          // ***************** MODIFY REVIEW PAGE TITLE AND BODY TEXT **************************
          builder.addCase(updateTipConfirmationMessage.pending, (state) => {
            state.loading = 'loading';
          }); 
          builder.addCase(updateTipConfirmationMessage.fulfilled, (state, action) => {
            state.loading = 'tipConfirmationMessageUpdated';
            setNotification(
              i18n.t("messages.appName"),
              i18n.t("messages.accounTipConfirmMsgUpdated"), 
              "success",
              "updateTipConfirmationMessageSuccess"
            );
          });
          builder.addCase(updateTipConfirmationMessage.rejected, (state, { payload }: any) => {
            state.loading = "idle"; 
            let msgText = i18n.t("group.accounTipConfirmMsgSaveFailed");  
            setNotification(
              i18n.t("projectName"),
              msgText,
              "failed",
              "updateTipConfirmationMessageFailed"
            );
          });



          // ***************** MODIFY REVIEW PAGE TITLE AND BODY TEXT **************************
          builder.addCase(updateAccReviewTitleBody.pending, (state) => {
            state.loading = 'loading';
          }); 
          builder.addCase(updateAccReviewTitleBody.fulfilled, (state, action) => {
            state.loading = 'reviewTitleBodyUpdated';
            setNotification(
              i18n.t("messages.appName"),
              i18n.t("messages.accountReviewTitleBodyUpdated"), 
              "success",
              "updateAccReviewTitleBodySuccess"
            );
          });
          builder.addCase(updateAccReviewTitleBody.rejected, (state, { payload }: any) => {
            state.loading = "idle"; 
            let msgText = i18n.t("group.accountReviewTitleBodySaveFailed");  
            setNotification(
              i18n.t("projectName"),
              msgText,
              "failed",
              "updateAccReviewTitleBodyFailed"
            );
          });

          // ***************** MODIFY REVIEW PLATFORM DATA **************************
          builder.addCase(updateAccReviewSettings.pending, (state) => {
            state.loading = 'loading';
          });
          builder.addCase(updateAccReviewSettings.fulfilled, (state, action) => {
            state.loading = 'accountReviewPlatformDataUpdated';
            setNotification(
              i18n.t("messages.appName"),
              i18n.t("messages.reviewLinkDataUpdated"), 
              "success",
              "updateAccReviewSettingsSuccess"
            );
          });
          builder.addCase(updateAccReviewSettings.rejected, (state, { payload }: any) => {
            state.loading = "idle"; 
            let msgText = i18n.t("messages.reviewLinkDataSaveFailed");  
            setNotification(
              i18n.t("projectName"),
              msgText,
              "failed",
              "updateAccReviewSettingsFailed"
            ); 
          });


        // ********** UPLOAD LOGO FOR ACCOUNT TIP PAGE ********************************
        builder.addCase(uploadAccountTipLogo.pending, (state) => {
          state.loading = 'loading';
        });
        builder.addCase(uploadAccountTipLogo.fulfilled, (state, action) => {
          state.loading = 'accTipLogoUploaded'; 
          setNotification(
            i18n.t("messages.appName"),
            i18n.t("messages.accountTipLogoUploaded"),
            "success",
            "uploadAccountTipLogo"
          );
        });
        builder.addCase(uploadAccountTipLogo.rejected, (state, { payload }: any) => {
          state.loading = 'error';
          console.log('uploadAccountTipLogo payload2: ', payload);
          setNotification(
            i18n.t("projectName"),
            i18n.t("messages.accountTipLogoUploadFailed"), 
            "failed",
            "uploadAccountTipLogo"
          ) 
        });

        // ********************* REMOVE ACCOUNT TIP PAGE LOGO **********************
        builder.addCase(removeAccountTipLogo.pending, (state) => {
          state.loading = 'loading';
        }); 
        builder.addCase(removeAccountTipLogo.fulfilled, (state, action) => {
          state.loading = 'accTipLogoRemoved'; 
          setNotification(
            i18n.t("messages.appName"),
            i18n.t("messages.accountTipLogoRemoved"),
            "success",
            "removeAccountTipLogo"
          );
        });
        builder.addCase(removeAccountTipLogo.rejected, (state, { payload }: any) => {
          state.loading = 'error';
          console.log('removeAccountTipLogo: ', payload);
          setNotification(
            i18n.t("projectName"),
            i18n.t("messages.accountTipLogoRemovalFailed"), 
            "failed",
            "removeAccountTipLogo"
          ) 
        });


        // ********** UPLOAD IMAGE FOR ACCOUNT TIP PAGE ********************************
        builder.addCase(uploadAccountTipImage.pending, (state) => {
          state.loading = 'loading';
        });
        builder.addCase(uploadAccountTipImage.fulfilled, (state, action) => {
          state.loading = 'accTipImageUploaded';
          setNotification(
            i18n.t("messages.appName"),
            i18n.t("messages.accountTipImageUploaded"),
            "success",
            "uploadAccountTipImage"
          );
        });   
        builder.addCase(uploadAccountTipImage.rejected, (state, { payload }: any) => {
          state.loading = 'error';
          console.log('payload4: ', payload);
          setNotification(
            i18n.t("projectName"),
            i18n.t("messages.accountTipImageUploadFailed"), 
            "failed",
            "uploadAccountTipImage"
          ) 
        });

        // ********************* REMOVE ACCOUNT TIP PAGE IMAGE **********************
        builder.addCase(removeAccountTipImage.pending, (state) => {
          state.loading = 'loading';
        }); 
        builder.addCase(removeAccountTipImage.fulfilled, (state, action) => {
          state.loading = 'accTipImageRemoved';
          setNotification(
            i18n.t("messages.appName"),
            i18n.t("messages.accountTipImageRemoved"),
            "success",
            "removeAccountTipImage"
          );
        });
        builder.addCase(removeAccountTipImage.rejected, (state, { payload }: any) => {
          state.loading = 'error';
          console.log('removeAccountTipImage: ', payload);
          setNotification(
            i18n.t("projectName"),
            i18n.t("messages.accountTipImageRemovalFailed"), 
            "failed",
            "removeAccountTipImage"
          ) 
        });


        // ********** UPLOAD REVIEW LINK LOGO  ********************************
        builder.addCase(uploadReviewPlatformLinkLogo.pending, (state) => {
          state.loading = 'loading';
        });
        builder.addCase(uploadReviewPlatformLinkLogo.fulfilled, (state, action) => {
          console.log('uploadReviewPlatformLinkLogo message: ', action.payload.message);
          console.log('uploadReviewPlatformLinkLogo logoUrl: ', action.payload.logoUrl);
          state.loading = 'accReviewLogoUploaded';
          state.reviewLinkLogoURL = action?.payload?.logoUrl ? action.payload.logoUrl : ""; 
          setNotification(
            i18n.t("messages.appName"),
            i18n.t("messages.reviewLinkLogoUploaded"),
            "success",
            "uploadReviewPlatformLinkLogo"
          );
        });   
        builder.addCase(uploadReviewPlatformLinkLogo.rejected, (state, { payload }: any) => {
          state.loading = 'error';
          console.log('payload4: ', payload);
          setNotification(
            i18n.t("projectName"),
            i18n.t("messages.reviewLinkLogoUploadFailed"), 
            "failed",
            "uploadReviewPlatformLinkLogo"
          ) 
        });

        
        // ********** RESET THE DISPLAY ORDER OF REVIEW PLATFORMS  ********************************
        builder.addCase(updateReviewPlatformsOrder.pending, (state) => {
          state.loading = 'loading';
        });
        builder.addCase(updateReviewPlatformsOrder.fulfilled, (state, action) => {
          state.loading = 'accReviewPlatformsOrderReset';
          state.reviewLinkLogoURL = action?.payload?.logoUrl ? action.payload.logoUrl : ""; 
          setNotification(
            i18n.t("messages.appName"),
            i18n.t("messages.reviewPlatformsOrderUpdated"),
            "success",
            "updateReviewPlatformsOrder"
          );
        });   
        builder.addCase(updateReviewPlatformsOrder.rejected, (state, { payload }: any) => {
          state.loading = 'error';
          console.log('payload4: ', payload);
          setNotification(
            i18n.t("projectName"),
            i18n.t("messages.reviewPlatformsOrderFailed"), 
            "failed",
            "updateReviewPlatformsOrder"
          ) 
        });

    }
})

// Action creators are generated for each case reducer function
export const { resetLoading, resetLoadingForNavbar } = accountDetailsSlice.actions;

export const selectAccountDetailsSlice = (state:RootState)=> state.accountDetailsData;
export const accountDetailsReducer = accountDetailsSlice.reducer;