import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { fetchStaffList, inviteStaff, reInviteStaff, deleteStaff } from '../../services/staffServices';
import {  IPagination } from '../../models/interfaces';
import { IStaff } from '../../models/staffInterfaces';
import { setNotification } from '../../services/apiService';
import { t } from 'i18next';
import { ERR_STAFF_ALREADY_INVITED, STAFF_DELETE_ERROR_GROUP_MAP, STAFF_INVITE_INVALID_PHONE, STAFF_INVITE_MSG_SEND_FAILED } from '../../config/apiResponseCodes';

export interface DataState {
  staff: IStaff[];
  loading: 'idle' | 'loading' | 'succeeded' | 'error' | 'invitationSuccess' | 'staffDeleteSuccess' | 'staffDeleteFailed' | 'reInvitationSuccess';
  currentPage: number;
  totalSize: number;
}

const initialState: DataState = {
  staff: [],  
  loading: 'idle',
  currentPage: 1,
  totalSize: 0,
};

export const staffListDataSlice = createSlice({
  name: 'staffListData',
  initialState,
  reducers: {
    setStaffList: (state, action: PayloadAction<any>) => {
      state.staff = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    setLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStaffList.pending, (state) => {
      state.staff = [];
      state.loading = 'loading';
    });
    builder.addCase(fetchStaffList.fulfilled, (state, action) => {
      state.staff = [];
      const m = action.payload as IPagination<IStaff[]>;
      state.staff = m.results;
      state.loading = 'succeeded';
      state.totalSize = m.count;
    });
    builder.addCase(fetchStaffList.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      state.totalSize = 0;
    });

    
    builder.addCase(deleteStaff.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(deleteStaff.fulfilled, (state, action) => {
      state.loading = 'staffDeleteSuccess'; 
      setNotification(
        t("projectName"),
        t("stafflist.modalStaffDeleteMessage"),
        "success",
        "deleteStaffUser"
      );
    });
    builder.addCase(deleteStaff.rejected, (state, { payload }: any) => {
      state.loading = 'staffDeleteFailed'; 
      let msgText = (payload?.response?.data?.message && payload.response.data.message.toLowerCase()==STAFF_DELETE_ERROR_GROUP_MAP) ?  t("stafflist.modalStaffDeleteMappedMsg") : t("stafflist.modalStaffDeleteFailedMessage") ; 
      setNotification(
        t("projectName"),
        t(msgText),
        "failed",
        "deleteStaffUser"
      );
    });

    builder.addCase(inviteStaff.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(inviteStaff.fulfilled, (state, action) => {
      state.loading = 'invitationSuccess';
      setNotification(
        t("projectName"),
        t("messages.inviteStaff"),
        "success",
        "inviteStaff"
      )
    });
    builder.addCase(inviteStaff.rejected, (state, { payload }: any) => {
      state.loading = 'error'; 
      console.log(payload); 
      let msgText = (payload?.message && payload.message.toLowerCase()==STAFF_INVITE_INVALID_PHONE) ?  t("messages.inviteStaffErrorInvalidPhone") : 
        (payload?.message && payload.message.toLowerCase()==STAFF_INVITE_MSG_SEND_FAILED && payload.error && payload.error.trim().length > 0) ? payload.error : 
        (payload?.message && payload.message.toLowerCase()==ERR_STAFF_ALREADY_INVITED) ? t("messages.inviteStaffErrorStaffInvited") : 
        t("messages.inviteStaffError") ; 
      setNotification(
        t("projectName"),
        msgText,
        "failed",
        "inviteStaff"
      )
    });

    // RESEND STAFF INVITATION 
    builder.addCase(reInviteStaff.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(reInviteStaff.fulfilled, (state, action) => {
      state.loading = 'reInvitationSuccess';
      setNotification(
        t("projectName"),
        t("messages.reInviteStaff"),  
        "success",
        "reInviteStaff"
      )
    });
    builder.addCase(reInviteStaff.rejected, (state, { payload }: any) => {
      state.loading = 'error'; 
      console.log(payload); 
      let msgText = (payload?.message && payload.message.toLowerCase()==STAFF_INVITE_INVALID_PHONE) ?  t("messages.inviteStaffErrorInvalidPhone") : 
        (payload?.message && payload.message.toLowerCase()==STAFF_INVITE_MSG_SEND_FAILED && payload.error && payload.error.trim().length > 0) ? payload.error : 
        (payload?.message && payload.message.toLowerCase()==ERR_STAFF_ALREADY_INVITED) ? t("messages.inviteStaffErrorStaffInvited") : 
        t("messages.reInviteStaffError") ; 
      setNotification(
        t("projectName"),
        msgText,
        "failed",
        "reInviteStaff"
      )
    });


    
  },
});

// Action creators are generated for each case reducer function
export const { setStaffList, setLoading ,setCurrentPage} = staffListDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectStaffListDataSlice = (state: RootState) => state.staffListData;

export const staffListDataReducer = staffListDataSlice.reducer;
