import { configureStore } from '@reduxjs/toolkit';
import { filterAllAccountListSliceReducer } from './components/FilterAccount/FilterAllAccountListSlice';
import { authenticationReducer } from './pages/LoginPage/authenticationSlice';
import { accountAuthenticationReducer } from './pages/LoginPage/accountAuthenticationSlice';

import { accountsListSearchReducer } from './pages/AccountsList/accountsListSearchSlice';
import { accountsListPaginationReducer } from './pages/AccountsList/accountsListPaginationSlice';
import { accountsListDataReducer } from './pages/AccountsList/accountsListDataSlice';
// import { staffListDataReducer } from './pages/StaffDetails/staffDetailSlice';
import { termsDataReducer } from './pages/Terms/termsSlice';
import { adminUsersListDataReducer } from './pages/AdminUsers/adminUserSlice';
import { staffListDataReducer } from './pages/StaffList/staffListSlice';
import { tipsListDataReducer } from './pages/TipsList/tipsListDataSlice';
import { usersListDataReducer } from './pages/AccountUsersList/accountUsersListSlice';
import { filterPayeeListSliceReducer } from './components/FilterPayee/PayeeFilterSlice';
import { paymentsListDataReducer } from './pages/PaymentsList/paymentsListDataSlice';
import { commissionsListDataReducer } from './pages/CommissionsList/commissionsListDataSlice';
import { accountSettingsFormDataReducer } from './pages/Settings/settingsSlice';
import { accountStaffDataReducer } from './pages/StaffDetail/staffProfileSlice';
import { accountSignUpReducer } from './pages/AccountSignUp/SignUp/signUpSlice';
import { businessDetailsReducer } from './pages/AccountSignUp/BusinessDetails/businessDetailsSlice';
import { agreementDataReducer } from './pages/AccountSignUp/Agreement/agreementSlice';
import { achDataReducer } from './pages/AccountSignUp/ACH/achSlice';
import { accountDetailsReducer } from './pages/AccountDetails/accountDetailsSlice';
import { tipReducer } from './pages/Tip/tipSlice';
import { stripeTokenSliceReducer } from './pages/Stripe/stripeTokenSlice';
import { groupDetailsReducer } from './pages/GroupSettings/groupDetailsSlice';

import { staffRegisterReducer } from './pages/StaffRegister/staffRegisterSlice';
import { commonReducer } from './pages/CommonSlice/commonSlice';
import { resetPasswordReducer } from './pages/ResetPassword/resetPasswordSlice';
import { withholdingsListDataReducer } from './pages/Withholdings/withholdingsSlice';
import { StaffRegisterWithoutEmailReducer } from './pages/StaffRegisterWithoutEmail/staffRegisterWithoutEmailSlice';
import { QRCodesSliceReducer } from './pages/QRCodes/QRCodesSlice';
import { chartDetailsReducer } from './pages/Dashboard/dashboardSlice';
import { QRCodesYourOrdersSliceReducer } from './pages/QRCodesYourOrders/QRCodesYourOrdersSlice';


export const store = configureStore({
  reducer: {
    //Login page
    authentication: authenticationReducer,

    // ACCOUNT LOGINPAGE 
    accountAuthentication: accountAuthenticationReducer,

    // Accounts List
    accountsListSearch: accountsListSearchReducer,
    accountsListPagination: accountsListPaginationReducer,
    accountsListData: accountsListDataReducer,
    // Dashboard Chart Data
    chartDetailsData: chartDetailsReducer, 
    // Staff List
    staffListData: staffListDataReducer,
    // Tips List
    tipsListData: tipsListDataReducer,
    // adminUser List
    adminUsersListData: adminUsersListDataReducer,
    // User List
    usersListData: usersListDataReducer,
    //Header Page    
    filterAllAccountList: filterAllAccountListSliceReducer,
    // TERMS AND CONDITIONS 
    getTermsdata: termsDataReducer,
    // PAYEE FILTER
    filterPayeeList: filterPayeeListSliceReducer,
    // PAYMENTS LIST 
    paymentsListData: paymentsListDataReducer,
    // COMMISSIONS LIST 
    commissionsListData: commissionsListDataReducer,
    // STAFF DETAILS 
    accountStaffData: accountStaffDataReducer,
    // DEFAULT SETTINGS 
    accountSettingsFromData: accountSettingsFormDataReducer,
    // ACCOUNT SIGNUP 
    accountSignUpData: accountSignUpReducer,
    // ACCOUNT CREATE LINK FROM EMAIL
    businessDetailsData: businessDetailsReducer,
    // ACCOUNT SIGN UP - AGREEMENT PAGE 
    agreementData: agreementDataReducer,
    // ACCOUNT SIGN UP - ACH DATA UPDATE  
    achData: achDataReducer,
    // ACCOUNT DETAILS
    accountDetailsData: accountDetailsReducer,
    // TIP  
    tipData: tipReducer,
    // STRIPE TOKEN 
    stripeTokenData: stripeTokenSliceReducer,
    // STAF REGISTRATION - TEMPORARY - NEED TO REMOVE WHEN MOBILE APP IS UP 
    staffFormData: staffRegisterReducer,
    StaffRegisterWithoutEmail:StaffRegisterWithoutEmailReducer,
    // GROUP DETAILS
    groupDetailsData: groupDetailsReducer,
    // COMMON DATA
    commonData: commonReducer,
    //RESET PASSWORD 
    resetPassword: resetPasswordReducer,
    //WITHHOLDINGS LIST
    withholdingsListData: withholdingsListDataReducer,
    //QRCODES
    qrcodesData: QRCodesSliceReducer,
    //QRCODES ORDER HISTORY
    qrCodesOrdersData: QRCodesYourOrdersSliceReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
