import { createAsyncThunk } from '@reduxjs/toolkit';
import { CheckMobileFormApi, IStaffRegisterAPI, RequestOtp, IAcceptTerms, IStaffRegisterFormTemp } from '../models/staffRegister';
import { get, post } from './apiService';
import { SA_API_URL_STAFF_REGISTER } from '../config/api_urls';

// FUNCTION FOR STAFF REGISTERATION TEMPERORY
export const staffRegisterTemp = createAsyncThunk(
  'staff/register',
  async (staffRegForm: IStaffRegisterFormTemp, thunkAPI: any) => {
    try {
      let url = SA_API_URL_STAFF_REGISTER;
      const response = post(url, staffRegForm);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// STAFF REGISTRATION IS NOW THROUGH ONLY MOBILE APP, SO THESE FUNCTION ARE NOT IN USE NOW
// NEED TO USE THESE IF STAFF REGISTRATION THROUGH WEBSITE RETURNS

// FUNCTION FOR STAFF REGISTERATION 
export const staffRegister = createAsyncThunk(
  'maids/registerMaid',
  async (data: IStaffRegisterAPI, thunkAPI: any) => {
    try {
      let url = 'users/v1/maids/register/?hotel=' + data.hotel;
      const response = post(url, data.registerForm);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION FOR MAID OTP-REQUEST
export const maidOtpRequest = createAsyncThunk(
  'maids/maidOtpRequest',
  async (data: RequestOtp, thunkAPI: any) => {
    try {
      let url = 'users/v1/unregistered-otp/';
      const response = post(url, data);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION TP CHECK MAID WITH CORESPONDING ACCOUNT
export const checkMaid = createAsyncThunk(
  'maids/checkMaid',
  async (data: CheckMobileFormApi, thunkAPI: any) => {
    try {
      let url = 'users/v1/maids/check/?hotel=' + data.hotel;
      const response = post(url, data.mobileForm);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTermsAndCondition = createAsyncThunk(
  'terms/getTermsAndCondition',
  async (group: number, thunkAPI: any) => {
    try {
      let url = 'users/v1/terms-and-conditions/view/?group=' + group;
      const response = get(url);
      //(await response).data
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const acceptTermsAndConditions = createAsyncThunk(
  'terms/acceptTermsAndConditions',
  async (data: IAcceptTerms, thunkAPI: any) => {
    try {
      let url = 'users/v1/terms-and-conditions-log/';
      const response = post(url, data);
      //(await response).data
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
