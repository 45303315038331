import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../helpers/hook";
import { useTranslation } from "react-i18next";
import { Store } from "react-notifications-component";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { Box, Modal } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
//import 'react-phone-input-2/lib/style.css'

import warning from "../../assets/images/warning.svg";

import Table from "../../components/Table";
import Paginations from "../../components/Pagination";
import { Textfield } from "../../components/TextField";
import { SelectField } from "../../components/SelectField";
import FilterSection from "../../components/FilterSection";
import { ButtonModal } from "../../components/ButtonGroups/ButtonModal";
import { StaffTableHeaders } from "../../components/Table/TableHeaders";

import {
  IStaffRequestData,
  IStaffAccountStatus,
  IStaffInvite,
  IStafflistDelete,
  IStaffReInvite,
} from "../../models/staffInterfaces";

import { DATEFILTER_ALLDATES, DATEFILTER_THISMONTH, DATEFILTER_THISWEEK, PAGENAME_STAFF, PAGINATION_NUM_ROWS, PHONEINPUT_COUNTRIES, ROUTE_STAFF_DETAIL, STAFF_STATUS_ACTIVE, PHONEINPUT_COUNTRYCODE_EDITABLE } from "../../config/env";
import { checkActionPermission, PERM_STAFF_EXPORT, } from "../../config/permissions";

import { setNotification } from "../../services/apiService";
import { getAdminUserRole, getFilterAccountId, setLastActivePageAPIData } from "../../services/localStorage";

import {
  exportStaffList,
  fetchStaffList,
  changeStaffAccountStatus,
  deleteStaff,
  inviteStaff,
  reInviteStaff
} from "../../services/staffServices";

import { selectStaffListDataSlice, setCurrentPage, setLoading } from "./staffListSlice";
import { selectAccountStaffDataSlice } from "../StaffDetail/staffProfileSlice.ts"; 

import { ICountry, ICountryCode } from '../../models/countryStateInterfaces.ts';
import { fetchCountryStates } from "../../services/countryStateServices.tsx";

interface CustomizedState {
  calledFrom: string, 
  prevAccIds: string, 
  prevDateFilterType: string,
  prevFromDate: string,
  prevToDate: string,
  prevStatus: string,
  prevTipsType: string,
  prevTipsFromDate: string,
  prevTipsToDate: string,
  prevSearch: string,
  prevSort: string,
  prevPage: number,
  prevNumRowsPerPage: number,
}

function StaffList() {
  const prevAPIDataString = localStorage.getItem('lastActivePageAPIData');
  const prevAPIData = prevAPIDataString ? JSON.parse(prevAPIDataString) : {};
  const pageName = PAGENAME_STAFF; 
  const adminUserRole = getAdminUserRole();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentAccId = getFilterAccountId();

  const location = useLocation();
  const stateData = location.state as CustomizedState; // Type Casting, then you can get the params passed via router

  const [calledFrom, setCalledFrom] = useState(stateData && stateData.calledFrom ? stateData.calledFrom : "");

  const prevAccIds = stateData && stateData.prevAccIds ? stateData.prevAccIds : ""; 
  const prevDateFilterType = stateData && stateData.prevDateFilterType ? stateData.prevDateFilterType : ""; 
  const prevFromDate = stateData && stateData.prevFromDate ? stateData.prevFromDate : ""; 
  const prevToDate = stateData && stateData.prevToDate ? stateData.prevToDate : ""; 
  const prevStatus = stateData && stateData.prevStatus ? stateData.prevStatus : ""; 
  const prevTipsType = stateData && stateData.prevTipsType ? stateData.prevTipsType : ""; 
  const prevTipsFromDate = stateData && stateData.prevTipsFromDate ? stateData.prevTipsFromDate : ""; 
  const prevTipsToDate = stateData && stateData.prevTipsToDate ? stateData.prevTipsToDate : ""; 
  const prevSearch = stateData && stateData.prevSearch ? stateData.prevSearch : ""; 
  const prevSort = stateData && stateData.prevSort ? stateData.prevSort : "";   
  const prevPage = stateData && stateData.prevPage ? stateData.prevPage : 1; 
  const prevNumRowsPerPage = stateData && stateData.prevNumRowsPerPage ? stateData.prevNumRowsPerPage : PAGINATION_NUM_ROWS; 

  const style = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 642,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "13px",
    boxShadow: 24, 
  };

  const { staff, totalSize, currentPage, loading } = useAppSelector(
    selectStaffListDataSlice
  );

  const { countryStates } = useAppSelector(selectAccountStaffDataSlice);

  const [staffTableHeaders, setStaffTableHeaders] = useState(
    StaffTableHeaders()
  );
  const [bgShade, setBgShade] = useState(false);

  const [inviteStaffPopup, setInviteStaffPopup] = useState(false);

  const [selectedAccountIds, setSelectedAccountIds] = useState("");
  const [sortField, setSortField] = useState("full_name");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [dateFilterType, setDateFilterType] = useState<string>("");
  const [tipsFromDate, setTipsFromDate] = useState<string>("");
  const [tipsToDate, setTipsToDate] = useState<string>("");
  const [tipsFilterType, setTipsFilterType] = useState<string>("");
  const [applyFilter, setApplyFilter] = useState<number>(0);
  const [textSearchFilter, setTextSearchFilter] = useState<string>("");
  const [numRowsPerPage, setNumRowsPerPage] = useState(PAGINATION_NUM_ROWS); 
  
  const [initialAPICalled, setInitialAPICalled] = useState(false);

  // 'accept...' VARIABLES USED TO SET/RESET FILTERS ON REFRESH   
  const [acceptAccountFilterIds, setAcceptAccountFilterIds] = useState(""); 
  const [acceptDateFilterData, setAcceptDateFilterData] = useState(""); 
  const [acceptMultiSelectStatusIds, setAcceptMultiSelectStatusIds] = useState(""); 
  const [acceptTipsType, setAcceptTipsType] = useState(""); 
  const [acceptSearchText, setAcceptSearchText] = useState(""); 
  

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [staffCountry, setStaffCountry] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [staffCountryError, setStaffCountryError] = useState(false);


  const defCountryList : ICountry[]= [{ countryCode: t("defaultCountry"), countryName: t("defaultCountryName") } ]; 
  const [countryList, setCountryList] = useState <ICountry[]> (defCountryList);

  const [phoneCountries, setPhoneCountries] = useState ([t("defaultCountry").toLowerCase()]);

  const handleSort = (sortField: string) => {
    setSortField(sortField);
  };

  const fetchall = (): void => {
    if(calledFrom=='staffDetailsPage' && prevAccIds && prevAccIds.length > 0) {
        const req: IStaffRequestData = {
          adminUserRole: Number(adminUserRole),
          page: prevPage,
          numRowsPerPage: prevNumRowsPerPage, 
          hotel: prevAccIds,
          search: prevSearch,
          document_verified: "",
          staffStatus: prevStatus,
          dateFilterType: dateFilterType, 
          joining_from_date: prevFromDate,
          joining_to_date: prevToDate,
          tipsFilterType: tipsFilterType,
          tips_from_date: prevTipsFromDate,
          tips_to_date: prevTipsToDate,
          sort: prevSort,
        };
        dispatch(fetchStaffList(req));
        setLastActivePageAPIData({pageName:pageName, apiData:req}); 
        setTimeout(() => {
          setCalledFrom("");
        }, 1750); 
    }
    else {
      if(!initialAPICalled && prevAPIData.pageName && prevAPIData.pageName==pageName && prevAPIData?.apiData?.hotel && prevAPIData.apiData.hotel.length > 0) { 
        dispatch(fetchStaffList(prevAPIData.apiData));
  
        dispatch(setCurrentPage(prevAPIData.apiData.page));
        setSelectedAccountIds(prevAPIData.apiData.hotel);
        setSortField(prevAPIData.apiData.sort);
        setDateFilterType(prevAPIData.apiData.dateFilterType);
        setStartDate(prevAPIData.apiData.joining_from_date);
        setEndDate(prevAPIData.apiData.joining_to_date);
        setStatus(prevAPIData.apiData.staffStatus);
        setTipsFilterType(prevAPIData.apiData.tipsFilterType);  
        setTipsFromDate(prevAPIData.apiData.tips_from_date);
        setTipsToDate(prevAPIData.apiData.tips_to_date);
        setTextSearchFilter(prevAPIData.apiData.search);
        setNumRowsPerPage(prevAPIData.apiData.numRowsPerPage);         
  
        if(prevAPIData.apiData.hotel && prevAPIData.apiData.hotel.trim().length > 0) {
          setAcceptAccountFilterIds(prevAPIData.apiData.hotel); 
        }
        if(prevAPIData.apiData.dateFilterType && prevAPIData.apiData.dateFilterType.trim().length > 0) {
          setAcceptDateFilterData(prevAPIData.apiData.dateFilterType); 
        }  
        if(prevAPIData.apiData.staffStatus && prevAPIData.apiData.staffStatus.trim().length > 0) {
          setAcceptMultiSelectStatusIds(prevAPIData.apiData.staffStatus); 
        } 
        if(prevAPIData.apiData.tipsFilterType && prevAPIData.apiData.tipsFilterType.trim().length > 0) {
          setAcceptTipsType(prevAPIData.apiData.tipsFilterType); 
        }
        if(prevAPIData.apiData.search && prevAPIData.apiData.search.trim().length > 0) {
          setAcceptSearchText(prevAPIData.apiData.search); 
        }
        
        setTimeout(() => {
          setInitialAPICalled(true);
        }, 1250);
        
      }
      else { 

        if (selectedAccountIds && selectedAccountIds.length > 0) {
          const req: IStaffRequestData = {
            adminUserRole: Number(adminUserRole),
            page: currentPage,
            numRowsPerPage: numRowsPerPage,
            hotel: selectedAccountIds,
            search: textSearchFilter,
            document_verified: "",
            staffStatus: status,
            dateFilterType: dateFilterType, 
            joining_from_date: startDate,
            joining_to_date: endDate,
            tipsFilterType: tipsFilterType, 
            tips_from_date: tipsFromDate,
            tips_to_date: tipsToDate,
            sort: sortField,
          };
          setAcceptDateFilterData(DATEFILTER_ALLDATES);
          dispatch(fetchStaffList(req)); 
          setLastActivePageAPIData({pageName:pageName, apiData:req}); 
          setInitialAPICalled(true);
        }
      }
    }
  };

  const fetchCountries = () => {
    // FETCH ALL COUNTRY AND STATES LIST 
    let dataObj : ICountryCode = { countryCode : "" }
    dispatch(fetchCountryStates(dataObj)); 
  }

  useEffect(() => {
    if(!countryStates || countryStates.length===0) {
      setCountryList(defCountryList); 
      setPhoneCountries([(t("defaultCountry").toLowerCase())]); 
    }
    else {
      let arrCountries: ICountry[] = []; 
      let phCountires = [];   
      countryStates.forEach(item => {
        phCountires.push((item.countryCode.toLowerCase()));
        arrCountries.push({ countryCode: item.countryCode, countryName: item.countryName}); 
      }); 
      setCountryList(arrCountries); 
      setPhoneCountries(phCountires); 
    }
  }, [countryStates]);

  const handleInviteStaff = (e): void => {
    // e.preventdefault(); 
    let foundError = false;
    if(!firstName || firstName.trim().length==0) {
      setFirstNameError(true); 
      foundError = true;
    }
    if(!firstName || firstName.trim().length==0) {
      setLastNameError(true); 
      foundError = true;
    }
    if(!mobile || mobile.trim().length==0) {
      setMobileError(true); 
      foundError = true;
    } 
    if(!staffCountry || staffCountry.trim().length===0) {
      setStaffCountryError(true); 
      foundError = true;
    }

    if(!foundError) {
      if(currentAccId && currentAccId.length!==0 && firstName!=="" && lastName!=="" && mobile!=="" && staffCountry!==""){
        var userData: IStaffInvite = {
          hotelId: Number(currentAccId),
          firstName: firstName,
          lastName: lastName,
          country: staffCountry, 
          mobileNumber:
            mobile && mobile.trim().length > 0
              ? mobile.search("\\+") < 0
                ? "+" + mobile
                : mobile
              : "",
        };
        dispatch(inviteStaff(userData));
      }
    }
    // else{
    //   setNotification(
    //     t("projectName"),
    //     t("messages.inviteStaffFailed"),
    //     "failed",
    //     "inviteStaff"
    //   )
    // }
  };

  const onSetInviteStaffPopup = (e): void => {
    fetchCountries(); 
    setInviteStaffPopup(true)
    setFirstName("");
    setLastName("");
    setMobile("");
    setStaffCountry(""); 
    setFirstNameError(false);
    setLastNameError(false);
    setMobileError(false);
    setStaffCountryError(false); 
  };

  useEffect(() => {
    if(calledFrom=='staffDetailsPage') {
      dispatch(setCurrentPage(prevPage));
      setSelectedAccountIds(prevAccIds); 
      setSortField(prevSort);
      setNumRowsPerPage(prevNumRowsPerPage); 
      setStartDate(prevFromDate);
      setEndDate(prevToDate);
      setDateFilterType(prevDateFilterType); 
      setStatus(prevStatus);
      setTipsFilterType(prevTipsType);
      setTipsFromDate(prevTipsFromDate); 
      setTipsToDate(prevTipsToDate); 
      setTextSearchFilter(prevSearch);
      console.log('prevPage1: ',prevPage); 
      console.log('currentPage: ',currentPage); 
      fetchall();

      if(prevAccIds && prevAccIds.trim().length > 0) {
        setAcceptAccountFilterIds(prevAccIds); 
      }
      if(prevDateFilterType && prevDateFilterType.trim().length > 0) {
        setAcceptDateFilterData(prevDateFilterType); 
      }
      if(prevStatus && prevStatus.trim().length > 0) {
        setAcceptMultiSelectStatusIds(prevStatus); 
      }
      if(prevTipsType && prevTipsType.trim().length > 0) {
        setAcceptTipsType(prevTipsType); 
      }
    }
  }, [calledFrom]);

  useEffect(() => {
    if(loading=='invitationSuccess') { 
      setInviteStaffPopup(false); 
      setLoading('idle'); 
      fetchall(); 
    }
    else if(loading=="staffDeleteSuccess" || loading=="reInvitationSuccess") {
      fetchall();
      setLoading('idle'); 
    }
  },[loading]);

  useEffect(() => {
    if(calledFrom!='staffDetailsPage') {
      if (currentPage != 1) {
        acceptPage(1);
      } else {
        fetchall();
      }
    }
  }, [
    status,
    applyFilter,
    textSearchFilter,
    selectedAccountIds,
    numRowsPerPage,
  ]);

  const acceptPage = (e: any): void => {
    console.log("acceptPage: " + e);
    dispatch(setCurrentPage(e));
  };

  React.useEffect(() => {
    fetchall();
  }, [currentPage, sortField]);

  const setDateFilterDatesType = (fromDate: string, toDate: string, filterType: string) => {
    setStartDate(fromDate);
    setEndDate(toDate);
    setDateFilterType(filterType); 
    setApplyFilter(applyFilter + 1);
  };

  const setTipsFilterDates = (
    fromDate: string,
    toDate: string,
    filterValue: string
  ) => {
    setTipsFromDate(fromDate);
    setTipsToDate(toDate);
    setTipsFilterType(filterValue); 
    setApplyFilter(applyFilter + 1);

    let tableHeaders = staffTableHeaders;
    tableHeaders = [];
    staffTableHeaders.forEach((item, index) => {
      if (item.title == "totalEarnings") {
        item.display_title = t("stafflist.fieldTips", {
          tipsFilterType:
            filterValue == "all"
              ? t("common.allTime")
              : filterValue == DATEFILTER_THISWEEK 
              ? t("common.thisWeek")
              : filterValue == DATEFILTER_THISMONTH
              ? t("common.thisMonth")
              : filterValue,
        });
      }
      tableHeaders.push(item);
    });
    setStaffTableHeaders(tableHeaders);
  };

  const setStatusFilter = (status: string) => {
    setStatus(status);
  };

  const clearFilters = () => {
    console.log("clearFilters clearFilters clearFilters");
    setTipsFilterDates("", "", "all");
    setSortField("full_name");
    setStatus("");
    setDateFilterType("");
    setStartDate("");
    setEndDate("");
    setTipsFromDate("");
    setTipsToDate("");
    setTextSearchFilter("");
    acceptPage(1);
    setNumRowsPerPage(PAGINATION_NUM_ROWS); 
    setApplyFilter(applyFilter + 1);
  };

  const onExportButton = () => {
    const req: IStaffRequestData = {
      adminUserRole: Number(adminUserRole),
      page: currentPage,
      numRowsPerPage: numRowsPerPage,
      hotel: selectedAccountIds,
      search: textSearchFilter,
      document_verified: "",
      staffStatus: status,
      joining_from_date: startDate,
      joining_to_date: endDate,
      tips_from_date: tipsFromDate,
      tips_to_date: tipsToDate,
      sort: sortField,
    };
    exportStaffList(req);
  };

  const onClickTableOption = (
    actionType: string,
    staffId: string,
    accountId: string,
    staffName: string,
    accountName: string,
    staffStatus: string
  ) => {
    if (actionType == "staffAccountRelation") {
      confirmChangeStatus(
        actionType,
        staffId,
        accountId,
        staffName,
        accountName,
        staffStatus
      );
    } else if (actionType == "staffDelete") {
      confirmDeleteStaff(staffId, staffName);
    } else if (actionType == "resendStaffInvite") {
      var apiData: IStaffReInvite = {
        hotelId: Number(accountId),
        staffId: Number(staffId), 
      };
      dispatch(reInviteStaff(apiData));
    }

  };

  const onClickTableData = (actionType: string, data: {}) => {
    navigate(`${ROUTE_STAFF_DETAIL}${data.id}/${data.hotelId}`, {state:{prevAccIds:selectedAccountIds, prevDateFilterType:dateFilterType, prevFromDate:startDate, prevToDate:endDate, prevStatus:status, prevTipsType:tipsFilterType, prevTipsFromDate:tipsFromDate, prevTipsToDate:tipsToDate,  prevSearch:textSearchFilter, prevSort:sortField, prevPage:currentPage, prevNumRowsPerPage:numRowsPerPage}});
  } 

  const confirmChangeStatus = (
    actionType: string,
    staffId: string,
    accountId: string,
    staffName: string,
    accountName: string,
    staffStatus: string
  ) => {
    if (actionType && actionType.length > 0) {
      setBgShade(true);
      Store.addNotification({
        title:
          staffStatus == STAFF_STATUS_ACTIVE.toString() 
            ? t("stafflist.modalDeactivateHeader")
            : t("stafflist.modalActivateHeader"),
        id: "staff-status-modal",
        content: (
          <div className="idproof-modal div-center w-60perc relative">
            <div className="page-heading ">
              <img src={warning} alt="" />
            </div>
            <div className="idproof-flex-center">
              {staffStatus == STAFF_STATUS_ACTIVE.toString() 
                ? t("stafflist.modalDeactivateConfirm", {
                    staff: staffName,
                    account: accountName,
                  })
                : t("stafflist.modalActivateConfirm", {
                    staff: staffName,
                    account: accountName,
                  })}
            </div>
            <div className="idproof-margin"></div>
            <div className="flex w-100 gap-4">
              <ButtonModal
                type="cancel"
                onClick={() => {
                  Store.removeNotification("staff-status-modal");
                  setBgShade(false);
                }}
                buttonLabel={t("common.cancel")}
              />
              <ButtonModal
                type={staffStatus == STAFF_STATUS_ACTIVE.toString()  ? "delete" : ""}
                onClick={() => {
                  setBgShade(false);
                  Store.removeNotification("staff-status-modal");
                  onChangeStatus({ staffId: staffId, accountId: accountId,adminUserRole:Number(adminUserRole) });
                }}
                buttonLabel={
                  staffStatus == STAFF_STATUS_ACTIVE.toString() 
                    ? t("stafflist.actionStaffDeactivate")
                    : t("stafflist.actionStaffActivate")
                }
              />
            </div>
          </div>
        ),
        type: "default",
        container: "center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 1000000000,
          click: false,
          touch: false,
        },
      });
    } else {
      return false;
    }
  };

  const onChangeStatus = (staffAccount: IStaffAccountStatus) => {
    if (
      staffAccount.staffId.trim().length > 0 &&
      staffAccount.staffId.trim() != "0" &&
      staffAccount.accountId.trim().length > 0 &&
      staffAccount.accountId.trim() != "0"
    ) {

      dispatch(changeStaffAccountStatus(staffAccount));

      setNotification(
        t("projectName"),
        t("stafflist.staffStatusChange"),
        "success",
        "changeStaffAccountStatus"
      );

      setTimeout(() => {
        fetchall();
      }, 400);
    }
  };

  const confirmDeleteStaff = (staffId: string, staffName: string) => {
    setBgShade(true);
    Store.addNotification({
      title: t("stafflist.modalDeleteHeader", {
        projectname: t("projectName"),
      }),
      id: "maid-modal",
      content: (
        <div className="idproof-modal div-center text-center w-60perc relative">
          <div className="page-heading">
            <img src={warning} alt="" />
          </div>
          <div className="idproof-flex-center div-center">
            <div>
              {t("stafflist.modalDeleteConfirm", {
                staff: staffName,
                projectname: t("projectName"),
              })}
            </div>
          </div>

          <div className="idproof-margin"></div>
          <div className="flex w-100 gap-4">
            <ButtonModal
              type="cancel"
              onClick={() => {
                Store.removeNotification("maid-modal");
                setBgShade(false);
              }}
              buttonLabel={t("common.cancel")}
            />
            <ButtonModal
              type="delete"
              onClick={() => {
                setBgShade(false);
                Store.removeNotification("maid-modal");
                deleteStaffUser(staffId);
              }}
              buttonLabel={t("common.delete")}
            />
          </div>
        </div>
      ),
      type: "default",
      container: "center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 1000000000,
        click: false,
        touch: false,
      },
    });
  };

  const deleteStaffUser = (staffId: string): void => {
    (async () => {
      const req: IStafflistDelete = {
        staffId:staffId,
        adminUserRole: Number(adminUserRole),
      };

      dispatch(deleteStaff(req));
      Store.removeNotification("maid-modal"); 
      // const delMaid = await dispatch(deleteStaff(req));
      // if (delMaid.meta.requestStatus === "fulfilled") {
      //   fetchall();
      //   setNotification(
      //     t("projectName"),
      //     t("stafflist.modalStaffDeleteMessage"),
      //     "success",
      //     "deleteStaffUser"
      //   );
      // }
    })();
  };

  return (
    <div>
      <div className={bgShade ? "rnc_bg" : "hide"}></div>
      <div className={`box-padding`}>
        <div className="">
          <FilterSection
            headind={`${t("stafflist.headingStafflist")}`}
            filterAccount={true}
            filterTips={true}
            filterDate={true}
            filterStatus={false}
            filterPayStatus={true}  // STAFF AND PAYSTATUS ARE HANDLES IN ONE VARIABLE 
            setDateFilterDatesType={setDateFilterDatesType} 
            setStatusFilter={setStatusFilter}
            setTipsFilterDates={setTipsFilterDates}
            parentPage={PAGENAME_STAFF}
            clearFilters={clearFilters}
            setSelectedAccountIds={setSelectedAccountIds}
            searchInput={true}
            setTextSearchFilter={setTextSearchFilter}
            searchInputPlaceholder={`${t("stafflist.searchStaff")}`}
            buttonLabel={`${t("stafflist.inviteStaff")}`}
            addButton={true}
            headerButtonFunction={(e)=>onSetInviteStaffPopup(e)} 
            needDateAllOption={true}

            acceptAccountFilterIds={acceptAccountFilterIds}
            acceptSearchText={acceptSearchText} 
            acceptDateFilterData={acceptDateFilterData}
            acceptMultiSelectStatusIds={acceptMultiSelectStatusIds} 
            acceptTipsType={acceptTipsType}

          />

          {/* THERE CAN BE MULTIPLE ROWS FOR SAME STAFF WITH DIFFERENT ACCOUNTS - 'is_active' FLAG IN THE RESPONSE SPECIFIES WHETHER THE STAFF IS ACTIVE OR INACTIVE WITH AN ACCOUNT IN EACH ROW */}
          <div className="table-box">
            <Table
              titles={staffTableHeaders}
              data={staff}
              sortedFieldName={sortField}
              handleSort={handleSort}
              onClickTableOption={onClickTableOption}
              onClickTableData={onClickTableData}
              parentPage={PAGENAME_STAFF}  
            />

            {totalSize > 0 && (
              <Paginations
                currentPage={currentPage} 
                numRowsPerPage={numRowsPerPage} 
                totalPageCount={
                  totalSize
                    ? totalSize < numRowsPerPage
                      ? 1
                      : totalSize % numRowsPerPage > 0
                      ? parseInt((totalSize / numRowsPerPage).toString(), 10) +
                        1
                      : parseInt((totalSize / numRowsPerPage).toString(), 10)
                    : 0
                }
                totalRows={totalSize}
                isTableLoading={false}
                setCurrentPage={acceptPage}
                setNumRowsPerPage={setNumRowsPerPage}
                recordsTypeName={`${t("common.staff")}`}
                exportButton={
                  (checkActionPermission(adminUserRole, PERM_STAFF_EXPORT) &&
                  totalSize > 0) ? true : false
                }
                onExportButton={onExportButton}
              />
            )}
          </div>
        </div>
        {/* MODAL FOR INVITE STAFF */}

        <Modal
          open={inviteStaffPopup}
          onClose={() => setInviteStaffPopup(false)}
          aria-labelledby={"Invite Staff"}
          aria-describedby=""
        >
          <Box sx={style}>
            <form
              className="w-100  manage-modal"
            >
              <div className="space-between center border-bottom p-3">
                <h1> {t("stafflist.inviteStaff")}</h1>
                <span onClick={() => setInviteStaffPopup(false)}>
                  <CloseRounded className="pointer" />
                </span>
              </div>

              <div className="bg-light">
                <div>
                  <div className=" border-bottom px-3 py-2">
                    <span className="sub-text ">
                      {t("stafflist.modalSubTextModal1")}
                    </span>
                    <span className="sub-text ">
                      {t("stafflist.modalSubTextModal2")}
                    </span>
                  </div>
                </div>

                <div className="border-bottom px-3 pt-4 pb-2 bg-white">
                  <div className=" w-100 flex gap-18px mb-18px">
                    <div className="w-100">
                      <div className="font-500 mb-5px fontsize-14">
                        {t("stafflist.modalFirstName")}
                      </div>
                      <Textfield
                        type="text"
                        value={firstName}
                        onChange={(e) => {
                            setFirstName(e.target.value);
                            setFirstNameError(false);
                          }
                        }
                        required={true}
                        className= {firstNameError ? "input-error" : "" }
                      />
                    </div>
                    <div className="w-100">
                      <div className="font-500 mb-5px fontsize-14">
                        {t("stafflist.modalLastName")}
                      </div>
                      <Textfield
                        type="text"
                        value={lastName}
                        onChange={(e) => {
                            setLastName(e.target.value);
                            setLastNameError(false);
                          }
                        }
                        required={true} 
                        className= {lastNameError ? "input-error" : "" }
                      />
                    </div>
                  </div>

                  <div className=" w-100 flex gap-18px mb-4">
                    <div className="w-100 hidesl">
                      <div className="font-500 mb-5px fontsize-14">
                        {t("staffprofile.country")}
                      </div>   
                      
                      <SelectField
                        type="inputType"
                        name="txt_country"
                        id="txt_country"
                        className={staffCountryError===true ? " select-placeholder input-error " : ""}
                        placeHolder={t("staffprofile.country")}
                        value={staffCountry}
                        onChange={(e) => {
                          setStaffCountry(e.target.value);
                          setStaffCountryError(false);
                          }
                        }
                        required
                        options={countryList &&
                          countryList.map((item, index) => (
                            <>
                              {index == 0 && <>
                                <option value="" >{t("staffprofile.country")}</option>
                              </>}
                              <option value={item.countryCode} style={{ color: "#000000" }}>
                                {item.countryName}
                              </option>
                            </>
                          ))}
                      />
                    </div>
                    <div className="w-100 hidesl">
                      {
                      (staffCountry && staffCountry.length > 0) && 
                        <>
                          <div className="font-500 mb-5px fontsize-14">
                            {t("stafflist.modalPhoneNumber")}
                          </div> 
                          <PhoneInput
                            inputProps={{
                              name: `mobile`,
                              required: true,
                              autoFocus: false,
                            }}
                            inputClass={mobileError ? "input-error common-input" : "common-input"} 
                            onlyCountries={phoneCountries}
                            country={(staffCountry && staffCountry.length > 0) ? staffCountry.toLowerCase() : t("defaultCountry").toLowerCase()}
                            countryCodeEditable={PHONEINPUT_COUNTRYCODE_EDITABLE}  
                            prefix={'+'}
                            value={mobile}
                            onChange={(code) => {
                                setMobile(code);
                                setMobileError(false);
                              }
                            }
                          />
                        </>
                      }
                      
                    </div>
                  </div>
                </div>
              </div>

              <div className="px-3 py-3">
                <div className="flex w-100 gap-4">
                  <ButtonModal
                    type="cancel"
                    onClick={() => setInviteStaffPopup(false)}
                    buttonLabel={t("common.cancel")}
                  />
                  <ButtonModal
                    buttonType=""
                    buttonLabel={t("common.send")}
                    onClick={(e) => handleInviteStaff(e)}
                  />
                </div>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default StaffList;
