import { get } from './apiService';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ACC_API_URL_ACC_GROUPS_LIST, SA_API_URL_ACC_GROUPS_LIST } from '../config/api_urls';
import { IAccGroupListReq } from '../models/groupInterfaces';
import { arrAccountUserIds } from '../config/env';

// FUNCTION TO FETCH GROUP LIST CO-RESPONDING TO ACCOUNT
export const fetchAccountGroupsList = createAsyncThunk(
  'users/fetchAccountGroupsList',
  async (accGroupReq: IAccGroupListReq, thunkAPI: any) => {
    try {
      const url = (arrAccountUserIds.includes(accGroupReq.userRole) ? ACC_API_URL_ACC_GROUPS_LIST : SA_API_URL_ACC_GROUPS_LIST)+'?hotel_id=' + accGroupReq.accountId; 
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

