import { USER_ROLE_ADMIN_SUPERADMIN, USER_ROLE_ADMIN_ADMIN, USER_ROLE_ACCOUNT_MANAGER, USER_ROLE_ACCOUNT_USER, PAGENAME_TIPS, PAGENAME_COMMISSIONS, PAGENAME_PAYOUTS, PAGENAME_WITHHOLDINGS, PAGENAME_ADMINUSERS, PAGENAME_ACCOUNTUSERS, PAGENAME_STAFF, PAGENAME_TERMS, PAGENAME_SETTINGS } from './env'; 

// MENU NAMES - USED FOR PERMISSION CHECKING
export const MENU_ACCOUNTS          = 'Accounts'; 
export const MENU_DASHBOARD         = 'Dashboard'; 
export const MENU_GROUPS            = 'Groups'; 
export const MENU_PAYMENTS          = 'Payments'; 
export const MENU_ADMIN             = 'Admin'; 


// PERMISSIONS FOR ACCESS TO PAGES AND ACTIONS 
// ****************************************************************************
// ************************* PAGE ACCESS PERMISSIONS **************************
// ****************************************************************************
// FOR TOVA SUPER ADMIN USERS - ALL PERMISSIONS ARE GRANTED, SO NO PERMISSION ARRAY REQUIRED 

// PAGE ACCESS PERMISSION ARRAY FOR TOVA ADMIN USERS
export const ARR_ADMIN_USER_PAGES = [MENU_ACCOUNTS, MENU_DASHBOARD, MENU_GROUPS, MENU_PAYMENTS, MENU_ADMIN, PAGENAME_STAFF, PAGENAME_TIPS, PAGENAME_COMMISSIONS, PAGENAME_PAYOUTS, PAGENAME_ADMINUSERS, PAGENAME_ACCOUNTUSERS, PAGENAME_WITHHOLDINGS, PAGENAME_TERMS, PAGENAME_SETTINGS];  

// PAGE ACCESS PERMISSION ARRAY FOR ACCOUNT MANAGER USERS
export const ARR_ACCOUNT_MANAGER_PAGES = [MENU_ACCOUNTS, MENU_DASHBOARD, MENU_GROUPS, MENU_PAYMENTS, MENU_ADMIN, PAGENAME_STAFF, PAGENAME_TIPS, PAGENAME_PAYOUTS, PAGENAME_ACCOUNTUSERS];  

// PAGE ACCESS PERMISSION ARRAY FOR ACCOUNT USER USERS
export const ARR_ACCOUNT_USER_PAGES = [MENU_ACCOUNTS, MENU_DASHBOARD, MENU_GROUPS, MENU_PAYMENTS, MENU_ADMIN, PAGENAME_STAFF,  PAGENAME_TIPS, PAGENAME_PAYOUTS, PAGENAME_ACCOUNTUSERS];  

// FUNCTION TO CHECK WHETHER THE USER HAVE MENU/PAGE PERMISSION 
export const checkMenuPagePermission = (userRole: any, pageName: string) => {

    if(userRole==USER_ROLE_ADMIN_SUPERADMIN.toString()) {
        return true; 
    }
    else if(userRole==USER_ROLE_ADMIN_ADMIN.toString()) {
        return ARR_ADMIN_USER_PAGES.includes(pageName); 
    }
    else if(userRole==USER_ROLE_ACCOUNT_MANAGER.toString()) {
        return ARR_ACCOUNT_MANAGER_PAGES.includes(pageName); 
    }
    else if(userRole==USER_ROLE_ACCOUNT_USER.toString()) {
        return ARR_ACCOUNT_USER_PAGES.includes(pageName); 
    }
    else {
        return false; 
    }
};

// ******************* ACTION PERMISSIONS **************************
export const PERM_ADD_NEW_ACCOUNT       = "AddNewAccount"; 
export const PERM_EDIT_ACCOUNT_DETAILS  = "EditAccountDetails"; 
export const PERM_ACTIVATE_DEACTIVATE_ACCOUNT  = "ActivateDeactivateAccount"; 
export const PERM_ACCOUNT_EXPORT        = "ExportAccounts"; 
export const PERM_ADD_NEW_GROUP         = "AddNewGroup"; 
export const PERM_EDIT_GROUP_DETAILS    = "EditGroupDetails"; 
export const PERM_INVITE_STAFF          = "InviteStaff"; 
export const PERM_STAFF_MANAGE          = "ManageStaff"; 
export const PERM_STAFF_EDIT            = "EditStaff"; 
export const PERM_STAFF_DELETE          = "DeleteStaff"; 
export const PERM_STAFF_EXPORT          = "ExportStaff"; 
export const PERM_TIPS_REFUND           = "TipRefund"; 
export const PERM_TIPS_EXPORT           = "ExportTips"; 
export const PERM_COMMISSION_EXPORT     = "ExportCommission"; 
export const PERM_PAYOUTS_EXPORT        = "ExportPayouts"; 
export const PERM_PAYOUTS_MANAGE_EDIT   = "PayoutsManageEditSave"; 
export const PERM_PAYOUTS_FAILED_REPROCESS="PayoutsFailedReprocess"; 
export const PERM_PAYOUTS_RECIEPT_VIEW  = "PayoutsReceiptView";  
export const PERM_VIEW_ADMIN_USERS      = "ViewAdminUsers"; 
export const PERM_ADD_ADMIN_USER        = "AddAdminUser"; 
export const PERM_EDIT_ADMIN_USER       = "EditAdminUser"; 
export const PERM_VIEW_ACCOUNT_USERS    = "ViewAccountUsers"; 
export const PERM_ADD_ACCOUNT_USER      = "AddAccountUser"; 
export const PERM_EDIT_ACCOUNT_USER     = "EditAccountUser"; 
export const PERM_PROCESS_PAYMENTS      = 'ProcessPayments'; 
export const PERM_QRCODE_ORDER          = "OrderQRCode"; 
export const PERM_PROCESS_WITHHOLDINGS  = 'ProcessWithHoldings'; 
export const PERM_EXPORT_WITHHOLDINGS   = 'ExportWithHoldings'; 
export const PERM_EDIT_ACCOUNT_COMMISSION="EditAccountCommission"; 
export const PERM_EDIT_ACCOUNT_REVIEW_LINKS = "EditAccountReviewLinks"; 
export const PERM_EDIT_ACC_TIP_CONFIRM_MESSAGE = "EditAccTipConfirmMessage"; 

// ACTION PERMISSIONS ARRAY FOR TOVA ADMIN USERS
export const ARR_ADMIN_USER_ACTIONS     = [PERM_ADD_NEW_ACCOUNT, PERM_EDIT_ACCOUNT_DETAILS,PERM_ACTIVATE_DEACTIVATE_ACCOUNT, PERM_ACCOUNT_EXPORT, PERM_ADD_NEW_GROUP, PERM_EDIT_GROUP_DETAILS, PERM_STAFF_MANAGE, PERM_INVITE_STAFF, PERM_STAFF_EXPORT, PERM_STAFF_EDIT, PERM_STAFF_DELETE, PERM_TIPS_REFUND, PERM_TIPS_EXPORT, PERM_COMMISSION_EXPORT, PERM_PAYOUTS_EXPORT, PERM_PAYOUTS_RECIEPT_VIEW, PERM_VIEW_ADMIN_USERS, PERM_ADD_ADMIN_USER, PERM_EDIT_ADMIN_USER, PERM_VIEW_ACCOUNT_USERS, PERM_ADD_ACCOUNT_USER, PERM_EDIT_ACCOUNT_USER, PERM_PROCESS_PAYMENTS, PERM_EDIT_ACCOUNT_COMMISSION, PERM_EDIT_ACCOUNT_REVIEW_LINKS, PERM_EDIT_ACC_TIP_CONFIRM_MESSAGE];  

// ACTION PERMISSIONS ARRAY FOR ACCOUNT-MANAGER USERS
export const ARR_ACCOUNT_MANAGER_ACTIONS= [PERM_EDIT_ACCOUNT_DETAILS, PERM_ACCOUNT_EXPORT, PERM_ADD_NEW_GROUP, PERM_EDIT_GROUP_DETAILS, PERM_STAFF_MANAGE, PERM_INVITE_STAFF, PERM_STAFF_EXPORT, PERM_STAFF_EDIT, PERM_TIPS_EXPORT, PERM_COMMISSION_EXPORT, PERM_PAYOUTS_EXPORT, PERM_PAYOUTS_MANAGE_EDIT, PERM_PAYOUTS_RECIEPT_VIEW, PERM_PAYOUTS_FAILED_REPROCESS, PERM_VIEW_ACCOUNT_USERS, PERM_ADD_ACCOUNT_USER, PERM_EDIT_ACCOUNT_USER, PERM_PROCESS_PAYMENTS, PERM_EDIT_ACCOUNT_REVIEW_LINKS, PERM_EDIT_ACC_TIP_CONFIRM_MESSAGE, PERM_QRCODE_ORDER];      

// ACTION PERMISSIONS ARRAY FOR ACCOUNT-USER USERS
export const ARR_ACCOUNT_USER_ACTIONS   = [PERM_ACCOUNT_EXPORT, PERM_STAFF_MANAGE, PERM_INVITE_STAFF, PERM_STAFF_EXPORT, PERM_VIEW_ACCOUNT_USERS, PERM_PAYOUTS_RECIEPT_VIEW, PERM_PROCESS_PAYMENTS];   

// FUNCTION TO CHECK WHETHER THE USER HAVE ACTION PERMISSION 
export const checkActionPermission = (role: any, permission: string) => {

    if(role==USER_ROLE_ADMIN_SUPERADMIN.toString()) {
        return true; 
    }
    else if(role==USER_ROLE_ADMIN_ADMIN.toString()) {
        return ARR_ADMIN_USER_ACTIONS.includes(permission); 
    }
    else if(role==USER_ROLE_ACCOUNT_MANAGER.toString()) {
        return ARR_ACCOUNT_MANAGER_ACTIONS.includes(permission); 
    }
    else if(role==USER_ROLE_ACCOUNT_USER.toString()) {
        return ARR_ACCOUNT_USER_ACTIONS.includes(permission); 
    }
    else {
        return false; 
    }
};
