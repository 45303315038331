import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "./apiService";
import { SA_API_URL_ACCOUNTS_LIST_ALL, ACC_API_URL_ACCOUNTS_LIST_ALL, ACC_API_URL_PRIMARY_ACC_LIST } from "../config/api_urls";
import { IAccListAllReq } from "../models/accountsInterfaces";
import { arrAccountUserIds } from "../config/env";


// FUNCTION TO FETCH ALL ACCOUNT LIST
export const fetchAllAccountList = createAsyncThunk(
    'hotels/fetchAllAccountList',
    async (apiData : IAccListAllReq, thunkAPI: any) => {
      try {
        let url = (arrAccountUserIds.includes(apiData?.userRole ? apiData.userRole : 0) ? ACC_API_URL_ACCOUNTS_LIST_ALL : SA_API_URL_ACCOUNTS_LIST_ALL) ;  
        if (apiData?.searchString && apiData.searchString.trim() != '') {
          url = url + '?search=' + apiData.searchString.trim();
        }
        const response = get(url);
        return (await response).data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  );

  // FUNCTION TO FETCH PRIMARY USER ACCOUNT LIST
export const fetchPrimaryUserAccountList = createAsyncThunk(
    'hotels/fetchPrimaryUserAccountList',
    async (apiData : IAccListAllReq, thunkAPI: any) => {
      try {
        let url = ACC_API_URL_PRIMARY_ACC_LIST+'?hotel='+ apiData.searchString ;  
        const response = get(url);
        return (await response).data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  );

