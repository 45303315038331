import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import QRCodeRightSidePanelPayment from '../../components/QRCodeComponents/QRCodeRightSidePanelPayment';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Textfield } from '../../components/TextField';
import PhoneInput from 'react-phone-input-2';
import { PHONEINPUT_COUNTRIES, PHONEINPUT_COUNTRYCODE_EDITABLE, US_STATES_ARRAY } from '../../config/env';
import { SelectField } from '../../components/SelectField';
import OrderedComponent from './OrderedComponent';

const steps = [
    'Shipping',
    'Payment',
    'Review',
];


const QRReviewAndPay = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className="box-padding select-none">
            <div className="box">
                <div className='center border-bottom min-height-127px w-100'>
                    <div className='min-width-350px'>
                        <Stepper activeStep={1} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                </div>
                <div className='space-between'>
                    <div className='w-100'>

                        <OrderedComponent />
                        <div className='px-37px py-40px border-bottom '>
                            <h2 className='text-22px-600-black mb-4'> {t("QRCodesPayment.cardInformation")}</h2>
                            <div className=" w-100 max-w-617px">
                                <form
                                    className="acc-form "
                                    action="onSubmit"
                                //   onSubmit={(e) => onHandleSave(e)}
                                >

                                    <div className="business w-100 p-0px ">
                                        <div className="flex gap-4 mb-3">
                                            <div className="w-100">
                                                <div className="label  text-7D7E7E">
                                                    {t("QRCodesPayment.firstName")}
                                                </div>
                                                <Textfield
                                                    type="text"
                                                    required
                                                    maxLength={150}
                                                    placeholder={`${t(
                                                        "QRCodesPayment.firstName"
                                                    )}`}
                                                />
                                            </div>
                                            <div className="w-100">
                                                <div className="label  text-7D7E7E">
                                                    {t("QRCodesPayment.lastName")}
                                                </div>
                                                <Textfield
                                                    type="text"
                                                    maxLength={150}
                                                    placeholder={`${t(
                                                        "QRCodesPayment.lastName"
                                                    )}`}
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <div className="label  text-7D7E7E">
                                                {t("QRCodesPayment.cardNumber")}
                                            </div>
                                            <Textfield

                                                type="text"
                                                required
                                                maxLength={250}
                                                placeholder={`${t(
                                                    "QRCodesPayment.cardNumber"
                                                )}`}
                                            />
                                        </div>
                                        <div className="flex gap-4 mb-3">
                                            <div className="w-100">
                                                <div className="label  text-7D7E7E">
                                                    {t("QRCodesPayment.expiry")}
                                                </div>
                                                <Textfield
                                                    type="text"
                                                    required
                                                    maxLength={150}
                                                    placeholder={`${t(
                                                        "QRCodesPayment.expiryPlaceholder"
                                                    )}`}
                                                />
                                            </div>
                                            <div className="w-100">
                                                <div className="label  text-7D7E7E">
                                                    {t("QRCodesPayment.CVC")}
                                                </div>
                                                <Textfield
                                                    type="text"
                                                    maxLength={150}
                                                    placeholder={`${t(
                                                        "QRCodesPayment.CVCPlacholder"
                                                    )}`}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="label  text-7D7E7E">
                                                {t("QRCodesPayment.discountCode")}
                                            </div>
                                            <div className='w-100 relative'>
                                                <button className='btn-qr-apply-text'>
                                                    {t("QRCodesPayment.apply")}
                                                </button>
                                                <Textfield
                                                    className=" pe-5 "
                                                    type="text"
                                                    required
                                                    maxLength={250}
                                                    name="txt_address"
                                                    id="txt_address"
                                                    placeholder={`${t(
                                                        "QRCodesPayment.discountCode"
                                                    )}`}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="w-100">
                                                <label className="label create-user text-grey flex-start gap-2 align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="mb-7px"
                                                    />
                                                    {t("QRCodesPayment.saveBillingInformation")}
                                                </label>
                                            </div>
                                        </div>

                                    </div>


                                </form>
                            </div>
                        </div>

                        <div className='px-37px py-40px '>
                            <h2 className='text-22px-600-black mb-4'> {t("QRCodesPayment.billingAddress")}</h2>
                            <div className="flex mb-27px align-items-center">
                                <label className="flex-start gap-2 align-items-center text-14px-500-black">
                                    <input
                                        type="radio"
                                    />
                                    {t("QRCodesPayment.sameShippingAddress")}
                                </label>
                            </div>
                            <div className="flex mb-27px align-items-center">
                                <label className="flex-start gap-2 align-items-center text-14px-500-black">
                                    <input
                                        type="radio"
                                    />
                                    {t("QRCodesPayment.useDifferentBillingAddress")}
                                </label>
                            </div>

                            <div className=" w-100 max-w-617px mt-15px">
                                <form
                                    className="acc-form "
                                    action="onSubmit"
                                //   onSubmit={(e) => onHandleSave(e)}
                                >

                                    <div className="business w-100 p-0px ">
                                        <div className="flex gap-4 mb-3">
                                            <div className="w-100">
                                                <div className="label  text-7D7E7E">
                                                    {t("QRCodesPayment.firstName")}
                                                </div>
                                                <Textfield

                                                    type="text"
                                                    required
                                                    maxLength={150}
                                                    name="txt_businessName"
                                                    id="txt_businessName"
                                                    placeholder={`${t(
                                                        "QRCodesPayment.firstName"
                                                    )}`}
                                                />
                                            </div>
                                            <div className="w-100">
                                                <div className="label  text-7D7E7E">
                                                    {t("QRCodesPayment.lastName")}
                                                </div>
                                                <Textfield

                                                    type="text"
                                                    maxLength={150}
                                                    name="txt_companyName"
                                                    id="txt_companyName"
                                                    placeholder={`${t(
                                                        "QRCodesPayment.lastName"
                                                    )}`}
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <div className="label  text-7D7E7E">
                                                {t("QRCodesPayment.streetAddress")}
                                            </div>
                                            <Textfield

                                                type="text"
                                                required
                                                maxLength={250}
                                                name="txt_address"
                                                id="txt_address"
                                                placeholder={`${t(
                                                    "QRCodesPayment.streetAddress"
                                                )}`}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <div className="label  text-7D7E7E">
                                                {t("QRCodesPayment.apartmentInformation")}
                                            </div>                                           
                                            <div className='w-100 relative'>
                                                <span className='qr-optional-text'>
                                                    {t("QRCodesPayment.optional")}
                                                </span>
                                                <Textfield
                                                    className=" pr-60px "
                                                    type="text"
                                                    required
                                                    maxLength={250}
                                                    name="txt_address"
                                                    id="txt_address"
                                                    placeholder={`${t(
                                                        "QRCodesPayment.apartmentInformation"
                                                    )}`}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex gap-4 mb-3">
                                            <div className="w-100">
                                                <div className="label  text-7D7E7E">
                                                    {t("QRCodesPayment.city")}
                                                </div>
                                                <Textfield

                                                    type="text"
                                                    required
                                                    min={1}
                                                    placeholder={`${t(
                                                        "QRCodesPayment.city"
                                                    )}`}

                                                />
                                            </div>
                                            <div className="w-100">
                                                <div className="label  text-7D7E7E">
                                                    {t("QRCodesPayment.state")}
                                                </div>
                                                <SelectField

                                                    type="inputType"
                                                    name="txt_state"
                                                    id="txt_state"
                                                    // className={
                                                    //   state == "" ? " select-placeholder " : ""
                                                    // }
                                                    placeHolder={t("QRCodesPayment.state")}
                                                    // value={state}
                                                    // onChange={(e) => setState(e.target.value)}
                                                    required
                                                    options={
                                                        US_STATES_ARRAY &&
                                                        US_STATES_ARRAY.map((item, index) => (
                                                            <>
                                                                {index == 0 && (
                                                                    <>
                                                                        <option value="">
                                                                            {t("QRCodesPayment.state")}
                                                                        </option>
                                                                    </>
                                                                )}
                                                                <option
                                                                    value={item.stateCode}
                                                                    style={{ color: "#000000" }}
                                                                >
                                                                    {item.stateName}
                                                                </option>
                                                            </>
                                                        ))
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="flex gap-4 mb-3">
                                            <div className="w-100">
                                                <div className="label  text-7D7E7E">
                                                    {t("QRCodesPayment.postalCode")}
                                                </div>
                                                <Textfield

                                                    type="text"
                                                    required
                                                    maxLength={10}
                                                    name="postalCode"
                                                    id="postalCode"
                                                    placeholder={`${t(
                                                        "QRCodesPayment.postalCode"
                                                    )}`}
                                                />
                                            </div>
                                            <div className="w-100">
                                                <div className="label  text-7D7E7E">
                                                    {t("QRCodesPayment.country")}
                                                </div>
                                                <Textfield

                                                    type="text"
                                                    required
                                                    placeholder={`${t(
                                                        "QRCodesPayment.country"
                                                    )}`}

                                                />
                                            </div>
                                        </div>
                                        
                                    </div>


                                </form>
                            </div>
                        </div>

                    </div>
                    <QRCodeRightSidePanelPayment
                        buttonLabelOne={`${t("QRCodesPayment.reviewPay")}`}
                        buttonOneFunction={() => navigate('/qr-placeorder')}
                    />
                </div>
            </div>
        </div>
    )
}

export default QRReviewAndPay