import React, { useState, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

import {
  USER_GROUP_GUESTS,
  USER_GROUP_HOTELS,
  USER_GROUP_STAFF,
} from "../../config/env";
import { useAppDispatch, useAppSelector } from "../../helpers/hook";
import { selectGetTermsDataSlice } from "./termsSlice";
import { ITerms } from "../../models/termsInterfaces";
import { ButtonInLine } from "../../components/ButtonGroups/ButtonInLine";
import { SelectField } from "../../components/SelectField";
import { getTerms, saveTerms } from "../../services/termsServices";
import { setLastActivePageAPIData } from "../../services/localStorage";

function Terms() {
  const prevAPIDataString = localStorage.getItem('lastActivePageAPIData');
  const prevAPIData = prevAPIDataString ? JSON.parse(prevAPIDataString) : {};
  const pageName = "terms"; 
  const { t } = useTranslation();
  const { termsLoading, terms } = useAppSelector(selectGetTermsDataSlice);
  const dispatch = useAppDispatch();

  const [termsGroupId, setTermsGroupId] = useState(USER_GROUP_STAFF.toString());
  const [newTermsText, setNewTermsText] = useState("");
  const [newTermsTextPrev, setNewTermsTextPrev] = useState("");
  const [editTerms, setEditTerms] = useState(false);
  const [isNewTerms, setIsNewTerms] = useState(false); 
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      [{ 'header': [1, 2, false] }],
    ],
  };

  useEffect(() => {
    if (termsLoading == "succeeded") {
      setNewTermsText(terms.conditionText);
      setIsNewTerms(false);
    }
  }, [termsLoading]);

  useEffect(() => { 
    let tgId = USER_GROUP_STAFF.toString(); 
    // IF REFRESH, CALL API WITH PREV. DATA  
    if(prevAPIData.pageName && prevAPIData.pageName==pageName && prevAPIData?.apiData?.termsGroupId && Number(prevAPIData.apiData.termsGroupId)) { 
      tgId = prevAPIData.apiData.termsGroupId; 
    }
    setTermsGroupId(tgId); 
    dispatch(getTerms(tgId));
    setLastActivePageAPIData({pageName:pageName, apiData:{termsGroupId:tgId}}); 
  }, []);

  const loadTerms = (termId:string) => {
    setTermsGroupId(termId); 
    dispatch(getTerms(termId));
    setLastActivePageAPIData({pageName:pageName, apiData:{termsGroupId:termId}}); 
  }

  const handleChange = (e: any) => {
    setNewTermsText(e);
    setIsNewTerms(false); 
  };

  const saveButton = (): any => {
    const saveEdit = () => {
      var newTerms: ITerms = {
        group: termsGroupId ? Number(termsGroupId) : 0,
        conditionText: newTermsText,
        currentTerms: isNewTerms,
      };
      dispatch(saveTerms(newTerms));
      setEditTerms(false);
    };
    const cancelEdit = () => {
      setNewTermsText(newTermsTextPrev);
      setEditTerms(false);
    };

    return (
      <div className="flex-end gap-4 ">
        <ButtonInLine
          type="edit_or_save"
          onClick={(e) => cancelEdit()}
          buttonLabel={t("common.cancel")}
        />
        <ButtonInLine
          type="edit_or_save"
          onClick={(e) => saveEdit()}
          buttonLabel={t("defaultSettings.save")}
        />
      </div>
    );
  };

  const editButton = (): any => {
    const handleEdit = () => {
      setEditTerms(true);
      setNewTermsTextPrev(newTermsText);
    };
    return (
      <div className="flex-end">
        <ButtonInLine
          type="edit_or_save"
          onClick={(e) => handleEdit()}
          buttonLabel={t("common.edit")}
          penIcon={true}
        />
      </div>
    );
  };

  return (
    <div className="box-padding">
      <div className="box">
        <div className="terms account">
          <h1> {t("navigation.TermsAndConditions")}</h1>
          <SelectField
            name=""
            id=""
            className=" mt-10px"
            value={Number(termsGroupId)}
            onChange={(e) => loadTerms(e.target.value.toString())}
            options={
              <>
                <option value={USER_GROUP_GUESTS}>{t("common.tippers")}</option>
                <option value={USER_GROUP_STAFF}>{t("common.staff")}</option>
                <option value={USER_GROUP_HOTELS}>
                  {t("common.accounts")}
                </option>
                {/* <option value={USER_GROUP_GUESTS}>Kitchen Staff Pool</option> */}
              </>
            }
          />
          <div className="w-100 relative mb-2 mt-3">
            <div className="space-between center">
           {editTerms&& <div className="flex gap-2 w-100 ">
            <input
              type="checkbox"
              name="chk_newTerms"
              id="chk_newTerms"
              className="ml-2px"
              defaultChecked={false}
              disabled={!editTerms}
              checked={isNewTerms}
              onChange={(e) => setIsNewTerms(e.target.checked)}
            />
            <label htmlFor="chk_newTerms" className="fontsize-14 font-bold text-dark">{t("agreement.saveAsNewT&C")}</label>
          </div>}
          <div></div>
          {editTerms ? <div>{saveButton()}</div> : <div>{editButton()}</div>}
            </div>
           
            <ReactQuill
              theme="snow"
              value={newTermsText}
              onChange={handleChange}
              modules={modules}
              readOnly={!editTerms}
              className="w-100 default-terms"
            />{" "}
          </div>
        
        </div>
      </div>
    </div>
  );
}

export default Terms;
