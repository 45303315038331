import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "../../helpers/hook";
import PhoneInput from 'react-phone-input-2';
import dayjs from "dayjs";

import imgBack from "../../assets/images/back.svg";

import { PAGENAME_QRSHIPPING } from "../../config/env";

import { getFilterAccountId } from "../../services/localStorage";
import { fetchVPPricingOne, fetchVPPricingTwo, fetchVPPricingThree, fetchCartDetails, fetchDeliveryOptions } from "../../services/qrCodesServices";

import { selectQrcodesDataSlice, changePaymentShippingAddress, changePaymentDeliveryOption } from "../QRCodes/QRCodesSlice"; 

import { ICartCheckoutData, IVPItemPriceData, IvpPricingPayload,  IShippingAddress, IDeliveryOptions } from '../../models/qrCodeInterfaces';

import { Textfield } from '../../components/TextField';
import { SelectField } from "../../components/SelectField";

import { MIN_MOBILE_NUM_LENGTH, PHONEINPUT_COUNTRIES, PHONEINPUT_COUNTRYCODE_EDITABLE, US_STATES_ARRAY } from '../../config/env';

import { DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD, DH_TEMPLATE_DESIGN_TYPE_POSTCARD, DH_TEMPLATE_DESIGN_TYPE_TABLETENT, ROUTE_QRCODES, ROUTE_QRC_PAYMENT } from "../../config/env_qrcodes";
import { isEmail } from '../../helpers/validators';

const QRCodesShipping = () => {
  const pageName = PAGENAME_QRSHIPPING;
  const accountId = getFilterAccountId();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { vpPricingDataOne, vpPricingDataTwo, vpPricingDataThree, cartData, deliveryOptionsData, tovaTaxPercentage,  deliveryOptionsStatus } = useAppSelector(selectQrcodesDataSlice); 

  const PAGE_STATUS_SHIPPING  = 1;
  const PAGE_STATUS_PAYMETHOD = 2; 

  const [pageProgressStatus, setPageProgressStatus] = useState(PAGE_STATUS_SHIPPING);  
  const [stateCartData, setStateCartData] = useState<ICartCheckoutData[]>([]); 
  const [totalQuantity, setTotalQuantity] = useState(0); 
  const [totalAmount, setTotalAmount] = useState(0); 
  const [shippingAmount, setShippingAmount] = useState(0); 
  const [taxAmount, setTaxAmount] = useState(0); 
  const [stateTaxPercentage, setStateTaxPercentage] = useState(0); 

  const [stateVPPricingDataPostCard, setStateVPPricingDataPostCard] = useState<IVPItemPriceData[]>([]); 
  const [stateVPPricingDataBusinessCard, setStateVPPricingDataBusinessCard] = useState<IVPItemPriceData[]>([]); 
  const [stateVPPricingDataTableTent, setStateVPPricingDataTableTent] = useState<IVPItemPriceData[]>([]); 

  const [loadCartData, setLoadCartData] = useState(0); 

  const [shippingAddressLocked, setShippingAddressLocked] = useState(false); 
  const [inactiveClassName, setInactiveClassName] = useState("");  

  const [firstName, setFirstName]     = useState("");
  const [lastName, setLastName]       = useState("");
  const [address, setAddress]         = useState("");
  const [addressTwo, setAddressTwo]     = useState("");
  const [city, setCity]               = useState("");
  const [state, setState]             = useState("");
  const [postalCode, setPostalCode]   = useState("");
  const [country, setCountry]         = useState(t("defaultCountry"));
  const [mobile, setMobile]           = useState("");
  const [email, setEmail]             = useState("");

  const [deliveryOptionId, setDeliveryOptionId]   = useState("");

  const [stateDeliveryOptions, setStateDeliveryOptions] =  useState<IDeliveryOptions[]>([]); 
  const [stateDeliveryOptionsStatus, setStateDeliveryOptionsStatus] =  useState(""); 


  const style1 = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 460,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "13px",
    boxShadow: 24,
  };

  useEffect(() => {
    // CALL PRICING FROM VISTA PRINT FOR EACH CARD TYPE  
    let pricingPayloadOne : IvpPricingPayload = { cartType: DH_TEMPLATE_DESIGN_TYPE_POSTCARD.toString()} 
    dispatch(fetchVPPricingOne(pricingPayloadOne)); 

    // setTimeout(() => {
    //   dispatch(fetchCartDetails({ accountId: accountId ? accountId.toString() : "" }));
    // }, 1100);
  },[]); 

  useEffect(() => {
    // PRICING FOR POST CARD - STORE IN STATE VARIABLE 
    if(vpPricingDataOne && vpPricingDataOne.length > 0) {
      setStateVPPricingDataPostCard(vpPricingDataOne); 

      let pricingPayloadTwo : IvpPricingPayload = { cartType: DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD.toString()} 
      dispatch(fetchVPPricingTwo(pricingPayloadTwo)); 
    }
    else {
      setStateVPPricingDataPostCard([]); 
    }
  },[vpPricingDataOne]); 

  useEffect(() => {
    // PRICING FOR BUSINESS CARD - STORE IN STATE VARIABLE 
    if(vpPricingDataTwo && vpPricingDataTwo.length > 0) {
      setStateVPPricingDataBusinessCard(vpPricingDataTwo); 
      let pricingPayloadThree : IvpPricingPayload = { cartType: DH_TEMPLATE_DESIGN_TYPE_TABLETENT.toString()} 
      dispatch(fetchVPPricingThree(pricingPayloadThree)); 
    }
    else {
      setStateVPPricingDataBusinessCard([]); 
    }
  },[vpPricingDataTwo]); 

  useEffect(() => {
    // PRICING FOR TABLE TENT - STORE IN STATE VARIABLE 
    if(vpPricingDataThree && vpPricingDataThree.length > 0) {
      setStateVPPricingDataTableTent(vpPricingDataThree); 

      dispatch(fetchCartDetails({ accountId: accountId ? accountId.toString() : "" }));
    }
    else {
      setStateVPPricingDataTableTent([]); 
    }
  },[vpPricingDataThree]); 

  useEffect(() => {
    if(cartData && cartData.length > 0) {
      let arrNew:ICartCheckoutData[] = []; 
      cartData.map(cartItem => {
        let arrPricing = Number(cartItem.cardType)==Number(DH_TEMPLATE_DESIGN_TYPE_POSTCARD) ? stateVPPricingDataPostCard : 
  Number(cartItem.cardType)==Number(DH_TEMPLATE_DESIGN_TYPE_BUSINESSCARD) ? stateVPPricingDataBusinessCard : 
  Number(cartItem.cardType)==Number(DH_TEMPLATE_DESIGN_TYPE_TABLETENT) ? stateVPPricingDataTableTent : []; 
        let priceValue = 0; 
        let selValue = ""; 
        arrPricing.map((pItem, pIndex) => {
          if(pItem.sku==cartItem.sku && Number(pItem.quantity)==Number(cartItem.quantity)) {
            priceValue = pItem.price; 
            selValue = pIndex.toString(); 
          }
        }); 
        arrNew.push({...cartItem, price: priceValue, selectedValue:selValue, locked:true, previewUrl: ""}); 
      });
      setStateCartData(arrNew); 
    }
  },[loadCartData]); 

  useEffect(() => {
    let totQuantity = 0, totAmount = 0;
    if(stateCartData && stateCartData.length > 0) {
      // SET VARIABLES FOR FOOTER 
      stateCartData.map(item => { 
        if(item.id && item.id > 0) {
          totQuantity += item.quantity ? item.quantity : 0;
          totAmount   += item.price ? item.price : 0;  
        }
      }); 
      setTotalQuantity(totQuantity); 
      setTotalAmount(totAmount); 
      if(tovaTaxPercentage) { 
        setStateTaxPercentage(tovaTaxPercentage); 
        setTaxAmount((totAmount+shippingAmount) * (tovaTaxPercentage/100)); 
      }
    }
    else {
      setTotalQuantity(totQuantity); 
      setTotalAmount(totAmount); 
    }
  },[stateCartData]);

  useEffect(() => {
    if(cartData && cartData.length > 0) {
      setLoadCartData(loadCartData+2); 
    }
  },[cartData]); 

  useEffect(() => {
    if(pageProgressStatus > PAGE_STATUS_SHIPPING) { 
      setShippingAddressLocked(true); 
      setInactiveClassName("inactive-bg"); 
    }
    else {
      setShippingAddressLocked(false); 
      setInactiveClassName(""); 
    }
  },[pageProgressStatus]); 

  useEffect(() => {
    let newDeliveryOptions : IDeliveryOptions[] = [];  
    if(deliveryOptionsData && deliveryOptionsData.length > 0) {
      deliveryOptionsData.map(item => {
        let dOptionData : IDeliveryOptions= {
          deliveryOptionId: item.deliveryOptionId,
          amount: item.price.amount, 
          currency: item.price.currency, 
          deliveryArriveByDate: item.deliveryArriveByDate, 
          businessDays: item.businessDays, 
        }
        newDeliveryOptions.push(dOptionData); 
      }); 
      setStateDeliveryOptions(newDeliveryOptions); 
    }
    scrollToSection("div-shippingMethod"); 
  },[deliveryOptionsData]); 

  useEffect(() => {
    if(tovaTaxPercentage) { 
      setStateTaxPercentage(tovaTaxPercentage); 
      setTaxAmount((totalAmount+shippingAmount) * (tovaTaxPercentage/100)); 
    }
    else {
      setStateTaxPercentage(0); 
      setTaxAmount(0); 
    }
  },[tovaTaxPercentage]); 

  useEffect(() => {
    if(stateTaxPercentage && stateTaxPercentage > 0) {
      setTaxAmount((totalAmount+shippingAmount) * (stateTaxPercentage/100)); 
    }
    else {
      setTaxAmount(0); 
    }
  },[shippingAmount]); 

  useEffect(() => {
    if(deliveryOptionsStatus && deliveryOptionsStatus.length > 0) {
      setStateDeliveryOptionsStatus(deliveryOptionsStatus); 
    }
  },[deliveryOptionsStatus]);

  const setShippingAddress = () => {
    if(firstName.trim().length==0 || lastName.trim().length==0 || address.trim().length==0 || city.trim().length==0 || state.trim().length==0 || postalCode.trim().length==0 || mobile.trim().length <  MIN_MOBILE_NUM_LENGTH || email.trim().length==0) {
      setPageProgressStatus(PAGE_STATUS_SHIPPING); 
      return false; 
    }
    else if(isEmail(email)!='') {
      setPageProgressStatus(PAGE_STATUS_SHIPPING); 
      return false; 
    }
    else {
      setPageProgressStatus(PAGE_STATUS_PAYMETHOD); 
      let arrIds = stateCartData.map(item => { return item.id});
      let cartIds = arrIds.join(',') 
       
      let shipAddress : IShippingAddress = {
        cartIds: cartIds, 
        firstName:  firstName,
        lastName: lastName,
        address: address,
        addressTwo: addressTwo,
        city: city,
        state: state,
        postalCode: postalCode, 
        country: (country && country.length > 0) ? country : "",
        mobile: mobile, 
        email: email
      }
      dispatch(fetchDeliveryOptions(shipAddress)); 
      dispatch(changePaymentShippingAddress(shipAddress)); // SET TO REDUX TO PICK FROM PAYMENT PAGE
      setStateDeliveryOptionsStatus("");
    }
  }

  const setDeliveryOption = () => {
    let arrDelOption = stateDeliveryOptions.filter(item => item.deliveryOptionId==deliveryOptionId);  
    let delOption : IDeliveryOptions = arrDelOption[0]; 
    dispatch(changePaymentDeliveryOption(delOption)); // SET TO REDUX TO PICK FROM PAYMENT PAGE
    let arrIds = stateCartData.map(item => { return item.id});
    let cartIds = arrIds.join(',') 
    let shipAddress : IShippingAddress = {
      cartIds: cartIds, 
      firstName:  firstName,
      lastName: lastName,
      address: address,
      addressTwo: addressTwo,
      city: city,
      state: state,
      postalCode: postalCode, 
      country: (country && country.length > 0) ? country : "",
      mobile: mobile, 
      email: email
    }
    navigate(ROUTE_QRC_PAYMENT, { state: {taxPercentage: stateTaxPercentage, deliveryOptions:delOption, shippingAddress: shipAddress} }); 
  }

  const dummyFunction = () => {
    console.log("dummyFunction"); 
    return false; 
  }

  const onEditShippingAddress = () => {
    setShippingAddressLocked(false); 
    setInactiveClassName(""); 
    setPageProgressStatus(PAGE_STATUS_SHIPPING); 
    setStateDeliveryOptions([]); 
    setDeliveryOptionId(""); 
    setShippingAmount(0); 
    setTaxAmount(0);
  } 

  const scrollToSection = (elementId:string) => {
    const element = document.getElementById(elementId); 
    if (element) {
      const offsetTop = element.offsetTop;
      window.scrollTo({
        top: (offsetTop-75),
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="box-padding select-none">
      <div className="box">
        <div className='qr-checkout-nav'>
          <div>
            <div className='w-100'>{t("QRCodesShipping.shippingAddress")}</div>
            <div className="w-100 returnAcc pointer left-center mb-7px mt-7px" onClick={e => navigate(ROUTE_QRCODES, { state: { returnedFrom: pageName }})}>
              <img src={imgBack} alt="back" height={"16px"} className="me-1" />
              {t("QRCodesCheckOut.returntoQRCodes")}
            </div>
          </div>
        </div>

        <div>
          {/* 
            *******************************************************
            ******************** SHIPPING ADDRESS *****************
            *******************************************************
          */}
          <div id='div_shipping' style={{ scrollMarginTop: 100 }} className='px-37px py-30px border-bottom '>
              {/* <h2 className='text-22px-600-black mb-4'> {t("QRCodesShipping.shippingAddress")}</h2> */}
              <div className=" w-100 max-w-617px">
                <div className="business w-100 p-0px ">
                  <div className="flex gap-4 mb-3">
                    <div className="w-100">
                      <div className="label  text-7D7E7E">
                        {t("QRCodesShipping.firstName")}
                      </div>
                      <Textfield 
                          type="text"
                          required
                          maxLength={150}
                          name="txt_firstName"
                          id="txt_firstName"
                          placeholder={`${t("QRCodesShipping.firstName")}`}
                          value={firstName} 
                          onChange={e => setFirstName(e.target.value)}
                          className={firstName.trim().length==0 ? (inactiveClassName+" input-error ") : inactiveClassName}
                          disabled={shippingAddressLocked}
                      />
                    </div>
                    <div className="w-100">
                      <div className="label  text-7D7E7E">
                        {t("QRCodesShipping.lastName")}
                      </div>
                      <Textfield 
                        type="text"
                        maxLength={150}
                        name="txt_lastName"
                        id="txt_lastName"
                        placeholder={`${t("QRCodesShipping.lastName")}`}
                        value={lastName} 
                        onChange={e => setLastName(e.target.value)} 
                        className={lastName.trim().length==0 ? (inactiveClassName+" input-error ") : inactiveClassName }
                        disabled={shippingAddressLocked}
                      />
                    </div>
                  </div> 
                  <div className="mb-3">
                    <div className="label  text-7D7E7E">
                      {t("QRCodesShipping.streetAddress")}
                    </div>
                    <Textfield
                      type="text"
                      required
                      maxLength={250}
                      name="txt_address"
                      id="txt_address"
                      placeholder={`${t("QRCodesShipping.streetAddress")}`} 
                      value={address} 
                      onChange={e => setAddress(e.target.value)}
                      className={address.trim().length==0 ? (inactiveClassName+" input-error ") : inactiveClassName }
                      disabled={shippingAddressLocked}
                    />
                  </div>
                  <div className="mb-3">
                    <div className="label  text-7D7E7E">
                      {t("QRCodesShipping.apartmentInformation")}
                    </div>
                    <div className='w-100 relative'>
                      <span className='qr-optional-text'>{t("QRCodesShipping.optional")}</span>
                      <Textfield
                        className={inactiveClassName+" pr-60px "}
                        type="text"
                        required
                        maxLength={250}
                        name="txt_addressTwo"
                        id="txt_addressTwo"
                        placeholder={`${t("QRCodesShipping.apartmentInformation")}`} 
                        value={addressTwo} 
                        onChange={e => setAddressTwo(e.target.value)} 
                        disabled={shippingAddressLocked}
                      />
                    </div>
                  </div>

                  <div className="flex gap-4 mb-3">
                    <div className="w-100">
                      <div className="label  text-7D7E7E">
                        {t("QRCodesShipping.city")}
                      </div>
                      <Textfield
                        type="text"
                        required
                        min={1}
                        maxLength={150}
                        placeholder={`${t("QRCodesShipping.city")}`} 
                        value={city} 
                        onChange={e => setCity(e.target.value)} 
                        className={city.trim().length==0 ? (inactiveClassName+" input-error ") : inactiveClassName }
                        disabled={shippingAddressLocked} 
                      />
                    </div>
                    <div className="w-100">
                      <div className="label  text-7D7E7E">{t("QRCodesShipping.state")}</div>
                      <SelectField 
                        type="inputType"
                        name="txt_state"
                        id="txt_state"
                        placeHolder={t("QRCodesShipping.state")}
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        required
                        className={state.trim().length==0 ? (inactiveClassName+" input-error ") : inactiveClassName }
                        disabled={shippingAddressLocked} 
                        options={
                          US_STATES_ARRAY && US_STATES_ARRAY.map((item, index) => (
                            <>
                            {index == 0 && (
                                <><option value="">{t("QRCodesShipping.state")}</option></>
                            )}
                            <option value={item.stateCode} style={{ color: "#000000" }} >
                                {item.stateName}
                            </option>
                            </>
                            ))
                          }
                      />
                    </div>
                  </div>

                  <div className="flex gap-4 mb-3">
                    <div className="w-100">
                      <div className="label  text-7D7E7E">
                          {t("QRCodesShipping.postalCode")}
                      </div>
                      <Textfield
                        type="text"
                        required
                        maxLength={10}
                        name="txt_postalCode"
                        id="txt_postalCode"
                        placeholder={`${t("QRCodesShipping.postalCode")}`} 
                        value={postalCode} 
                        onChange={e => setPostalCode(e.target.value)}
                        className={postalCode.trim().length==0 ? (inactiveClassName+" input-error ") : inactiveClassName }
                        disabled={shippingAddressLocked}
                      />
                    </div>

                    <div className="w-100">
                      <div className="label  text-7D7E7E">
                        {t("QRCodesShipping.country")}
                      </div>
                      <Textfield 
                        type="text"
                        name="txt_country"
                        id="txt_country"
                        required
                        placeholder={`${t("QRCodesShipping.country")}`}
                        value={country}
                        disabled={true}
                        onChange={e => setCountry(e.target.value)}
                        className={inactiveClassName}
                      />
                    </div>
                  </div>

                  <div className="mb-3 hidesl">
                    <div className="label  text-7D7E7E">
                      {t("QRCodesShipping.cellPhone")}
                    </div>
                    <PhoneInput
                      inputProps={{
                          name: `mobile`,
                          required: true,
                          autoFocus: false,
                      }}
                      inputClass={mobile.trim().length < MIN_MOBILE_NUM_LENGTH ? (inactiveClassName+" common-input input-error") : (inactiveClassName+" common-input ")} 
                      disabled={shippingAddressLocked} 
                      onlyCountries={PHONEINPUT_COUNTRIES}
                      countryCodeEditable={
                          PHONEINPUT_COUNTRYCODE_EDITABLE
                      }
                      country={"us"}
                      // specialLabel={`${t("adminUserList.mobileModal")}`}
                      value={mobile}
                      onChange={(code) => setMobile(code)}
                      prefix={"+"}
                    />
                  </div>

                  <div className="">
                    <div className="label  text-7D7E7E">
                        {t("QRCodesShipping.emailAddress")}
                    </div>
                    <Textfield
                      type="text"
                      required
                      maxLength={250}
                      name="txt_email"
                      id="txt_email"
                      placeholder={`${t("QRCodesShipping.emailAddress")}`} 
                      value={email}
                      onChange={e => setEmail(e.target.value)} 
                      className={isEmail(email)=='' ? inactiveClassName : (inactiveClassName+" input-error ")}
                      disabled={shippingAddressLocked}
                    />
                  </div>

                  {/* <div className="">&nbsp;</div> */}

                  <div className="flex space-between mt-10px">
                    <div className="label  text-7D7E7E">&nbsp;</div>
                    {inactiveClassName ? 
                      <button className='btn-whiteBlack-h36-r6-f14-500 btn-bw mt-20px' onClick={() => onEditShippingAddress()} >
                        {t("QRCodesShipping.editShippingAddress")}  
                      </button> : 
                      <button className='btn-blueWhite-h36-r6-f16-600 mt-20px ' onClick={() => setShippingAddress()} >
                        {t("QRCodesShipping.submit")} 
                      </button>}
                  </div>
                </div>
              </div>
          </div>
          
          {/* 
            *******************************************************
            ******************** SHIPPING METHOD ******************
            *******************************************************
          */}   
          <div id="div-shippingMethod">&nbsp;</div>       
          {pageProgressStatus && pageProgressStatus >= PAGE_STATUS_PAYMETHOD && <div className='px-37px'>
            <div className='space-between'>
              <div className='w-100 mt-20px'>
              <h2 className='text-22px-600-black mb-4'> {t("QRCodesShipping.shippingMethod")}</h2>
                {stateDeliveryOptionsStatus && stateDeliveryOptionsStatus.length > 0 && stateDeliveryOptionsStatus.includes('fetchDeliveryOptionsError') ? 
                  <> 
                  <div className="flex gap-2 mb-29px mt-20px">
                    <h6>{t("QRCodesShipping.shippingMethodError")}</h6>
                  </div>
                  </> 
                  : 
                  <> 
                    {stateDeliveryOptions && stateDeliveryOptions.length > 0 && stateDeliveryOptions.map(item => {
                      return(<>
                        <div className="flex gap-2 mb-29px">
                          <div className='pt-5px'>
                            <input type="radio" name="rad_delivery" id="rad_delivery" className="cursor-pointer-normal"  onClick={() => {
                              setShippingAmount(item.amount);
                              setDeliveryOptionId(item.deliveryOptionId.toString()); 
                            }
                            } checked={deliveryOptionId==item.deliveryOptionId.toString()} /> </div>
                          <div className='flex-col text-14px-400-black mt-3px cursor-pointer-normal' style={{ width: "311px" }} onClick={() => {
                              setShippingAmount(item.amount);
                              setDeliveryOptionId(item.deliveryOptionId.toString()); 
                            }
                            }>
                            <span className='mb-2'>{t("QRCodesShipping.deliversInxDays",{ddays:item.businessDays})}</span> 
                            <span>{t("QRCodesShipping.deliveredByDate", {dateValue:dayjs(item.deliveryArriveByDate).format(`MM/DD/YYYY`)})}</span>
                          </div>
                          <div className='text-14px-400-black mt-3px min-width-70px'>
                            <div className="div-right">
                              {item.amount == 0 ? t("QRCodesShipping.free") : (t("currency")+item.amount.toFixed(2).toString())}
                              </div>
                          </div>
                        </div>
                      </>);
                      })}
                      </>
                    }
                
              </div> 
              {/* ********************************************************
              ******************** ORDER SUMMARY *********************
              ******************************************************** */}
              <div className='px-4 pt-20px max-width-292px w-100 border-left min-height-66vh'>
                <div className=' flex-col'>
                  <h1 className='text-22px-600-black pb-33px'>{t("QRCodesCheckOut.orderSummary")}</h1>
                  <div className='text-14px-500-black space-between border-bottom pb-3'>
                      <span>{t("QRCodesCheckOut.total")}</span><span>{t("currency")+(totalAmount.toFixed(2)).toString()}</span>
                  </div>
                  <div className='text-14px-500-black space-between border-bottom py-3'>
                      <span>{t("QRCodesCheckOut.shipping")}</span><span>{t("currency")+(shippingAmount.toFixed(2)).toString()}</span>
                  </div>
                  {taxAmount > 0 && <div className='text-14px-500-black space-between border-bottom py-3'>
                      <span>{t("QRCodesCheckOut.tax")}</span><span>{t("currency")+(taxAmount.toFixed(2)).toString()}</span>
                  </div>}
                  <div className='text-16px-600-black space-between  py-3'>
                      <span>{t("QRCodesCheckOut.subTotal")}</span><span>{t("currency")+((totalAmount+shippingAmount+taxAmount).toFixed(2)).toString()}</span>
                  </div>

                  {(!deliveryOptionId || deliveryOptionId.length==0) ? <>
                    <button className='mt-21px btn-inactive-h36-r6-f16-600 inactive-bg inactive-text-light' onClick={() => {
                    dummyFunction();
                  }} >
                      {t("QRCodesShipping.proceedToPayment")} 
                  </button>
                  </> : 
                  <>
                    <button className='mt-21px btn-blueWhite-h36-r6-f16-600' onClick={() => setDeliveryOption()} >
                      {t("QRCodesShipping.proceedToPayment")} 
                  </button>
                  </>}
                  
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </div>

    </div>
  )
}

export default QRCodesShipping; 