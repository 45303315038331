import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authenticateAccount } from '../../services/authenticationService';
import { setAccountTokens } from '../../services/localStorage';
import { RootState } from '../../store';

export interface IAuthentication {
  isProcessingRequest: boolean;
  accessToken?: string;
  loginStatus?: string;
}

const initialState: IAuthentication = { isProcessingRequest: false, loginStatus: '' };

export const accountAuthenticationSlice = createSlice({
  name: 'accountAuthentication',
  initialState,
  reducers: {
    start: (state) => {
      return {
        ...state,
        isProcessingRequest: true,
        loginStatus: ''
      };
    },
    success: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isProcessingRequest: false,
        loginStatus: 'SUCCESS'
      };
    },
    error: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isProcessingRequest: false,
        loginStatus: 'ERROR'
      };
    },
    setLoginStatus: (state, action: PayloadAction<string>) => {
      state.loginStatus= action.payload; 
    },
  },
});

export const authenticateAccountUser = (username: string, password: string) => async (dispatch: any) => {
  dispatch(start());
  try {
    const authData = await authenticateAccount(username, password);
    setAccountTokens(authData);
    dispatch(success(authData));
  } catch (err) {
    dispatch(error(err));
    console.log('account login err: ',err); 
  }
};

// Action creators are generated for each case reducer function
export const { start, success, error, setLoginStatus } = accountAuthenticationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAccountAuthentication = (state: RootState) => state.accountAuthentication;

export const accountAuthenticationReducer = accountAuthenticationSlice.reducer;
