import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import plus from "../../assets/images/plus.svg";
import { useAppDispatch, useAppSelector } from "../../helpers/hook";
import { fetchPayeeByNameStaffOnly } from "../../services/payeeServices";
import { FilterPayeeListSlice } from "./PayeeFilterSlice";
import { ButtonPrimary } from "../ButtonGroups/ButtonPrimary";
import { Textfield } from "../TextField";
import { PAGENAME_WITHHOLDINGS } from "../../config/env"; 
import { ISearchStaffOnly } from "../../models/groupInterfaces";

interface IProps {
  setPayeeIds?: (Ids: string) => void;
  setPayeeNames?: (names: string) => void; 
  setPayeeTypes?: (types: string) => void; 
  resetPayeeFilter?: boolean;
  acceptPayeeFilterData?: string; 
  parentPage?: string;
  divHeight?: () => void; 
}

const FilterPayee: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { payeeList } = useAppSelector(FilterPayeeListSlice);

  const [payeeSearch, setPayeeSearch] = useState("");
  const [allPayeeList, setAllPayeeList] = useState(payeeList);
  const [selectedPayeeIds, setSelectedPayeeIds] = useState("");
  const [selectedPayeeNames, setSelectedPayeeNames] = useState("");
  const [selectedPayeeTypes, setSelectedPayeeTypes] = useState("");
  const [payeeFilterApplied, setPayeeFilterApplied] = useState(false);

  const onchangePayeeSearch = (searchString: string) => {
    setPayeeSearch(searchString);
    if (searchString.trim().length > 2) {
      // parentPage PAGENAME_WITHHOLDINGS
      let dataObj : ISearchStaffOnly = {name:searchString.trim(), staff_only: props.parentPage && props.parentPage==PAGENAME_WITHHOLDINGS ? 'true' : ''} 
      dispatch(fetchPayeeByNameStaffOnly(dataObj));
    } else if (searchString.trim().length == 0) {
      setSelectedPayeeNames(""); 
      setSelectedPayeeTypes("")
      setAllPayeeList([]);
      setSelectedPayeeIds("");
    }
  };

  useEffect(() => {
    setAllPayeeList(payeeList);
  }, [payeeList]);

  useEffect(() => {
    if (!props.resetPayeeFilter) {
      props.setPayeeTypes && props.setPayeeTypes(""); 
      setSelectedPayeeIds("");
      setSelectedPayeeNames(""); 
      setSelectedPayeeTypes("")
      setPayeeSearch("");
      setAllPayeeList([]);
      props.setPayeeNames && props.setPayeeNames(""); 
      // A SLIGHT TIME DELAY TO SET setPayeeTypes FIRST 
      setTimeout(() => {
        props.setPayeeIds && props.setPayeeIds("");
      }, 150);
      setPayeeFilterApplied(false);
    }
  }, [props.resetPayeeFilter]);

  useEffect(() => {
    if (props.acceptPayeeFilterData && props.acceptPayeeFilterData.length > 1) {
      let arrPayeeDet = props.acceptPayeeFilterData?.split(':~~:'); 
      if(arrPayeeDet && arrPayeeDet.length > 1) {
        setSelectedPayeeIds(arrPayeeDet[0]);
        setSelectedPayeeNames(arrPayeeDet[1]); 
        setPayeeSearch(arrPayeeDet[1]); 
        setSelectedPayeeTypes(arrPayeeDet[2]); 
        setAllPayeeList([{'id': Number(arrPayeeDet[0]), 'fullName': arrPayeeDet[1], 'type': arrPayeeDet[2]}]);
        setPayeeFilterApplied(true);
      }
    }
  }, [props.acceptPayeeFilterData]);

  const onApplyButton = () => { // e: any
    // e.preventDefault();
    props.setPayeeTypes && props.setPayeeTypes(selectedPayeeTypes);
    props.setPayeeNames && props.setPayeeNames(selectedPayeeNames); 
    // A SLIGHT TIME DELAY TO SET setPayeeTypes FIRST 
    setTimeout(() => {
      props.setPayeeIds && props.setPayeeIds(selectedPayeeIds);
    }, 150);
    setPayeeFilterApplied(selectedPayeeIds.length > 0 ? true : false);
    setAllPayeeList([]);
  };

  const handleSelect = (payeeId: string, payeeName: string, payeeType: string) => {
    if(payeeId) {
      setSelectedPayeeTypes(payeeType); 
      setSelectedPayeeNames(payeeName); 
      setPayeeSearch(payeeName);
      // SET PAYEEIDS WITH SLIGHT DELAY SO THAT OTHER STATE VARIABLES ARE SET BEFORE IT
      setTimeout(() => {
        setSelectedPayeeIds(payeeId);
      }, 150);
       
    }
  };

  useEffect(() => {
    if (selectedPayeeIds) {
      onApplyButton(); 
    }
  }, [selectedPayeeIds]);

  const dummyFunction = () => {
    // do nothing
  };

  const clearPayeeFilter = () => {
    props.setPayeeTypes && props.setPayeeTypes("");
    onchangePayeeSearch(''); 
    setSelectedPayeeIds("");
    setSelectedPayeeNames(""); 
    setSelectedPayeeTypes(""); 
    setPayeeSearch("");
    setAllPayeeList([]);
    props.setPayeeNames && props.setPayeeNames(""); 
    // A SLIGHT TIME DELAY TO SET setPayeeTypes FIRST
    setTimeout(() => {
      props.setPayeeIds && props.setPayeeIds(""); 
    }, 150);
    
    setPayeeFilterApplied(false);
  };

  return (
    <>
      <div className="filter-option" onMouseOver={e => props.divHeight ? props.divHeight() : dummyFunction()}>
        <div
          className={
            payeeFilterApplied ? "filterApplied filtervalue" : "filtervalue"
          }
        >
          <span title={payeeFilterApplied ? t("common.clearThisFilter") : ""}  className={payeeFilterApplied ? "pointer" : ""} onClick={() =>
          payeeFilterApplied ? clearPayeeFilter() : dummyFunction()}>
          <img src={plus} alt="plus" className={payeeFilterApplied ? "img-transform-45" : ""}/>
          </span> 

          <span>{t("payeeFilter.payee")}</span>
          {payeeFilterApplied && (
            <div className="filterAppliedValue">{payeeSearch}</div>
          )}
        </div>

        <div className="filter-content-body">
          <div className="filter-content">
            <div className="p-2 pb-0">
              <label className="link-h mt-5px mb-0">
                {t("payeeFilter.filterByPayee")}
              </label>
            </div>

            <form className="select-container">
              <div className="p-2">
                <Textfield
                  type="text"
                  placeholder={`${t("payeeFilter.searchPlaceHolder")}`}
                  value={payeeSearch}
                  onChange={(e) => onchangePayeeSearch(e.target.value)}
                />
                {payeeSearch.length > 0 && allPayeeList.length > 0 && (
                  <div className="multiselect min-height-130px mt-2 p-2 border-all pointer-cursor">
                    {allPayeeList &&
                      allPayeeList
                        .filter((item) => {
                          if (payeeSearch === "") {
                            return item;
                          } else if (
                            item.fullName
                              .toLowerCase()
                              .includes(payeeSearch.toLowerCase())
                          ) {
                            return item;
                          }
                        })
                        .map((data) => (
                          <div className=" text-dark " key={data.id}>
                            <label
                              className="flex gap-2 font-500 pointer "
                              style={{ letterSpacing: "0px" }}
                              onClick={(e) =>
                                handleSelect(data.id.toString(), data.fullName, data.type)
                              }
                            >
                              {data.fullName}
                            </label>
                          </div>
                        ))}
                  </div>
                )}
              </div>

              <div className=" p-2 border-top">
                <ButtonPrimary
                  className=" applyBtn "
                  onClick={(e) => onApplyButton()}
                  buttonLabel={t("common.apply")}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterPayee;
