import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../helpers/hook";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";

import continu from "../../../assets/images/arrow1.svg";

import { filterDigitsOnly, getStateNameFromCode } from "../../../config/common_functions"; 
import {
  BUSINESS_CLASSIFICATION_LLC,
  PHONEINPUT_COUNTRIES,
  PHONEINPUT_COUNTRYCODE_EDITABLE,
  US_STATES_ARRAY,
  ROUTE_ACCOUNTS_LOGIN,
} from "../../../config/env";
import {
  ICreateNewAccount,
  INewSignUpVerifyEmailLink,
} from "../../../models/signUpInterfaces";
import {
  newAccountVerifyEmailLink,
  fetchBusinessTypes,
  newAccountSignUpCreate,
} from "../../../services/signUpServices";
import SignUpLeftPane from "../SignUpLeftPane";
import {
  ACC_SIGN_UP_EMAIL_VERIFIED,
  ERR_ACC_SIGN_UP_EMAIL_ALREADY_VERIFIED,
  ERR_LINK_TIME_EXPIRED,
  ERR_ACC_SIGN_UP_LINK_INVALID_TOKEN,
} from "../../../config/apiResponseCodes";
import {
  selectBusinessDetailsSlice,
  setBusinessDetailsResponseType,
} from "./businessDetailsSlice";
import Nav from "../Nav";
import { Textfield } from "../../../components/TextField";
import { SelectField } from "../../../components/SelectField";
import { setNotification } from '../../../services/apiService';

function BusinessDetails(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [signUpStage, setSignUpStage] = useState(3);
  const search = useLocation().search;
  const f_token = new URLSearchParams(search).get("f_token");
  const token = new URLSearchParams(search).get("token");
  const user_id = new URLSearchParams(search).get("user_id");
  const expiry_time = new URLSearchParams(search).get("expiry_time");
  const [showContinue, setShowContinue] = useState(false);
  const [tokenVerified, setTokenVerified] = useState(true);
  // FORM VARIABLES
  const [businessType, setBusinessType] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessClassification, setBusinessClassification] = useState(
    BUSINESS_CLASSIFICATION_LLC.toString()
  );
  const [companyName, setCompanyName] = useState("");
  const [numRooms, setNumRooms] = useState("");
  const [numEmployees, setNumEmployees] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [contactFirstName, setContactFirstName] = useState("");
  const [contactLastName, setContactLastName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactJobTitle, setContactJobTitle] = useState("");

  const businessClassList = [
    {
      id: BUSINESS_CLASSIFICATION_LLC,
      value: t("businessDetails.businessClassificationLLC"),
    },
  ];

  const {
    businessDetailsResponseType,
    businessTypesList,
    createAccountStatus,
    newAccountHotelId,
    newAccountUserEmail,
    newAccountUserFirstName,
    newAccountUserLastName,
    newAccountSecretId,
  } = useAppSelector(selectBusinessDetailsSlice);

  const onHandleSave = (e) => {
    e.preventDefault();
    if(businessPhone.trim().length < 9) {
      setNotification(
        t("projectName"),
        t("messages.addAccountPhoneError"),
        "failed",
        "addAccount"
      );
    }
    else {
      const req: ICreateNewAccount = {
        f_token: f_token ? f_token : "",
        user_id: user_id ? user_id : "",
        token: token ? token : "",
        expiry_time: expiry_time ? expiry_time : "",
        business_type_id: businessType ? Number(businessType) : 1,
        business_classification: businessClassification,
        hotel_name: businessName,
        company_name: companyName,
        number_of_rooms: numRooms,
        number_of_employees: numEmployees,
        mobile_number: businessPhone,
        address: address,
        city: city,
        state: state,
        zip: zipCode,
        first_name: contactFirstName,
        last_name: contactLastName,
        communication_email: contactEmail,
        job_title: contactJobTitle,
      };
      dispatch(newAccountSignUpCreate(req));
    }
  };

  React.useEffect(() => {
    if (
      !f_token ||
      f_token.trim().length == 0 ||
      !token ||
      token.trim().length == 0 ||
      !user_id ||
      user_id.trim().length == 0 ||
      !expiry_time ||
      expiry_time.trim().length == 0
    ) {
      setNotification(
        t("projectName"),
        t("businessDetails.linkNotValid"),
        "failed",
        "linkNotValid"
      );
      setShowContinue(false);
      setTokenVerified(false);
    } else {
      if (tokenVerified) {
        const req: INewSignUpVerifyEmailLink = {
          f_token: f_token,
          token: token,
          user_id: user_id,
          expiry_time: expiry_time,
        };
        dispatch(newAccountVerifyEmailLink(req));
      }
    }
    dispatch(fetchBusinessTypes(""));
  }, []);

  React.useEffect(() => {
    if (
      businessDetailsResponseType == ERR_ACC_SIGN_UP_LINK_INVALID_TOKEN ||
      businessDetailsResponseType == ERR_ACC_SIGN_UP_EMAIL_ALREADY_VERIFIED ||
      businessDetailsResponseType == ERR_LINK_TIME_EXPIRED ||
      businessDetailsResponseType == "error"
    ) {
      setTokenVerified(false);
      let errMsg =
        businessDetailsResponseType == ERR_ACC_SIGN_UP_LINK_INVALID_TOKEN
          ? t("businessDetails.linkNotValid")
          : businessDetailsResponseType ==
            ERR_ACC_SIGN_UP_EMAIL_ALREADY_VERIFIED
          ? t("businessDetails.linkAlreadyVerified")
          : businessDetailsResponseType == ERR_LINK_TIME_EXPIRED
          ? t("businessDetails.linkExpired")
          : t("businessDetails.linkNotValid");
        setNotification(
          t("projectName"),
          errMsg,
          "failed",
          "linkNotValid"
        );
    } else if (businessDetailsResponseType == ACC_SIGN_UP_EMAIL_VERIFIED) {
      setContactFirstName(
        newAccountUserFirstName ? newAccountUserFirstName : ""
      );
      setContactLastName(newAccountUserLastName ? newAccountUserLastName : "");
      setContactEmail(newAccountUserEmail ? newAccountUserEmail : "");
      setTokenVerified(true);
      setShowContinue(true);
    }
  }, [businessDetailsResponseType]);

  React.useEffect(() => {
    if (businessTypesList.length > 0) {
      setBusinessType(businessTypesList[0].id.toString());
    }
  }, [businessTypesList]); 

  const numberOnly = (numVal:string,fieldType:string) =>  {
    let newValue = filterDigitsOnly(numVal); 
    if(fieldType=="numRooms") {
      setNumRooms(((newValue))); 
    }
    else if(fieldType=="numEmployees") {
      setNumEmployees(((newValue))); 
    }
  };

  React.useEffect(() => {
    if (
      createAccountStatus == ERR_ACC_SIGN_UP_LINK_INVALID_TOKEN ||
      createAccountStatus == ERR_ACC_SIGN_UP_EMAIL_ALREADY_VERIFIED ||
      createAccountStatus == ERR_LINK_TIME_EXPIRED ||
      createAccountStatus == "error"
    ) {
      setTokenVerified(false);
      let errMsg =
        createAccountStatus == ERR_ACC_SIGN_UP_LINK_INVALID_TOKEN
          ? t("businessDetails.linkNotValid")
          : createAccountStatus == ERR_ACC_SIGN_UP_EMAIL_ALREADY_VERIFIED
          ? t("businessDetails.linkAlreadyVerified")
          : createAccountStatus == ERR_LINK_TIME_EXPIRED
          ? t("businessDetails.linkExpired")
          : t("businessDetails.linkNotValid");
        setNotification(
          t("projectName"),
          errMsg,
          "failed",
          "linkNotValid"
        );
  
    } else if (createAccountStatus == "succeeded") {
      localStorage.setItem(
        "newAccountSecretId",
        newAccountSecretId ? newAccountSecretId : ""
      );
      localStorage.setItem(
        "newAccountHotelId",
        newAccountHotelId ? newAccountHotelId.toString() : ""
      );
      localStorage.setItem(
        "newAccountUserEmail",
        newAccountUserEmail ? newAccountUserEmail : ""
      );
      localStorage.setItem(
        "newAccountUserFirstName",
        newAccountUserFirstName ? newAccountUserFirstName : ""
      );
      localStorage.setItem(
        "newAccountUserLastName",
        newAccountUserLastName ? newAccountUserLastName : ""
      );
      setNotification(
        t("projectName"),
        t("businessDetails.accountCreated"),
        "success",
        "accountCreated"
      );
      setTimeout(() => {
        navigate(ROUTE_ACCOUNTS_LOGIN);
      }, 750);
    }
  }, [createAccountStatus]);

  return (
    <>
      <Nav />
      <div className="w-100 flex">
        <div className="w-30perc ">
          <SignUpLeftPane signUpStep={signUpStage} />
        </div>
        <div className="w-70perc bg-light ">
          <div className="center ">
            <form className="" onSubmit={(e) => onHandleSave(e)}>
              <div className="business w-100">
                <h1> {t("businessDetails.tellUsHeading")}</h1>
                <h4>{t("businessDetails.subHeading")}</h4>
                <div className="mb-18px max-width-130px">
                  <div className="label">
                    {t("businessDetails.typeofBusinessLabel")}
                  </div>
                  <SelectField
                    name="sel_businessType"
                    id="sel_businessType"
                    className="rounded-pill mb-18px bg-white"
                    value={businessType}
                    onChange={(e) => setBusinessType(e.target.value)}
                    required
                
                    options={businessTypesList &&
                      businessTypesList.map((item, index) => (
                        <>
                          <option value={item.id.toString()}>
                            {item.name}
                          </option>
                        </>
                      ))}
                  />
                </div>

                <div className="flex gap-4 mb-18px">
                  <div className="w-100">
                    <div className="label">
                      {t("businessDetails.businessNameLabel")}
                    </div>
                    <Textfield
                      type="text"
                      required
                      maxLength={150}
                      name="txt_businessName"
                      id="txt_businessName"
                      placeholder={`${t(
                        "businessDetails.businessNamePlaceHolder"
                      )}`}
                      value={businessName}
                      onChange={(e) => setBusinessName(e.target.value)}
                    />
                  </div>
                  <div className="w-100">
                    <div className="label">
                      {t("businessDetails.parentCompanyLabel")}
                    </div>
                    <Textfield
                      type="text"
                      maxLength={150}
                      name="txt_companyName"
                      id="txt_companyName"
                      placeholder={`${t(
                        "businessDetails.companyNamePlaceHolder"
                      )}`}
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex gap-4 mb-18px">
                  <div className="w-100">
                    <div className="label">
                      {t("businessDetails.approximateroomsLabel")}
                    </div>
                    <Textfield
                      type="text"
                      required
                      maxLength={5}
                      name="txt_numRooms"
                      id="txt_numRooms"
                      placeholder={`${t("businessDetails.roomsPlaceHolder")}`}
                      value={numRooms}
                      onChange={(e) => numberOnly(e.target.value,"numRooms")}
                    />
                  </div>
                  <div className="w-100">
                    <div className="label">
                      {t("businessDetails.numberEmployeesLabel")}
                    </div>
                    <Textfield
                      type="text"
                      required
                      maxLength={5}
                      name="txt_numEmployees"
                      id="txt_numEmployees"
                      placeholder={`${t(
                        "businessDetails.employeesPlaceHolder"
                      )}`}
                      value={numEmployees}
                      onChange={(e) => numberOnly(e.target.value,"numEmployees")}
                    />
                  </div>
                </div>

                <div className="flex gap-4 mb-18px">
                  <div className="w-100 hidesl">
                    <div className="label">
                      {t("businessDetails.businessPhoneLabel")}
                    </div>
                    <PhoneInput
                      inputProps={{
                        name: `mobile`,
                        required: true,
                        autoFocus: false,
                      }}
                      inputClass="common-input"
                      onlyCountries={PHONEINPUT_COUNTRIES}
                      countryCodeEditable={PHONEINPUT_COUNTRYCODE_EDITABLE} 
                      country={"us"}
                      prefix={'+'}
                      value={businessPhone}
                      onChange={(code) => setBusinessPhone(code)}
                    />
                  </div>
                  <div className="w-100">
                    <div className="label">
                      {t("businessDetails.businessClassification")}
                    </div>
                    <SelectField
                    type="inputType"
                      name="sel_businessClass"
                      id="sel_businessClass"
                      value={businessClassification}
                      onChange={(e) =>
                        setBusinessClassification(e.target.value)
                      }
                      required
                    
                      options={businessClassList &&
                        businessClassList.map((item, index) => (
                          <>
                            <option value={item.id.toString()}>
                              {item.value}
                            </option>
                          </>
                        ))}
                    />
                  </div>
                </div>

                <div className="mb-18px">
                  <div className="label">
                    {t("businessDetails.addressLabel")}
                  </div>
                  <Textfield
                    type="text"
                    required
                    maxLength={250}
                    name="txt_address"
                    id="txt_address"
                    placeholder={`${t("businessDetails.addressPlaceHolder")}`}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>

                <div className="flex gap-4 mb-18px">
                  <div className="w-25">
                    <div className="label">
                      {t("businessDetails.cityLabel")}
                    </div>
                    <Textfield
                      type="text"
                      required
                      maxLength={150}
                      name="txt_city"
                      id="txt_city"
                      placeholder={`${t("businessDetails.cityPlaceHolder")}`}
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                  <div className="w-25">
                    <div className="label">
                      {t("businessDetails.stateLabel")}
                    </div>
                    <SelectField
                      type="inputType"
                      name="txt_state"
                      id="txt_state" 
                      className={state=="" ? " select-placeholder " : ""} 
                      placeHolder={t("businessDetails.stateLabel")}
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      required
                      options={US_STATES_ARRAY &&
                        US_STATES_ARRAY.map((item, index) => (
                          <>
                          {index==0 && <>
                            <option value="" >{t("businessDetails.stateLabel")}</option>
                          </>}
                            <option value={item.stateCode} style={{color: "#000000"}}>
                              {item.stateName}
                            </option>
                          </>
                        ))}
                    />
                  </div>
                  <div className="w-25">
                    <div className="label">
                      {t("businessDetails.zipcodeLabel")}
                    </div>
                    <Textfield
                      type="text"
                      required
                      maxLength={150}
                      name="txt_zipCode"
                      id="txt_zipCode"
                      placeholder={`${t("businessDetails.zipcodePlaceHolder")}`}
                      value={zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                    />
                  </div>
                  <div className="w-25"></div>
                </div>

                <h3>{t("businessDetails.contactInformationHeading")}</h3>
                <div className="flex gap-4 mb-18px">
                  <div className="w-100">
                    <div className="label">
                      {t("businessDetails.firstNameLabel")}
                    </div>
                    <Textfield
                      type="text"
                      required
                      maxLength={10}
                      name="txt_contactFirstName"
                      id="txt_contactFirstName"
                      placeholder={`${t("businessDetails.lastNameLabel")}`}
                      value={contactFirstName}
                      onChange={(e) => setContactFirstName(e.target.value)}
                    />
                  </div>
                  <div className="w-100">
                    <div className="label">
                      {t("businessDetails.lastNameLabel")}
                    </div>
                    <Textfield
                      type="text"
                      required
                      maxLength={150}
                      name="txt_contactLastName"
                      id="txt_contactLastName"
                      placeholder={`${t("businessDetails.lastNameLabel")}`}
                      value={contactLastName}
                      onChange={(e) => setContactLastName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex gap-4">
                  <div className="w-100">
                    <div className="label">
                      {t("businessDetails.emailLabel")}
                    </div>
                    <Textfield
                      type="email"
                      name="txt_contactEmail"
                      id="txt_contactEmail"
                      placeholder={`${t("businessDetails.emailLabel")}`}
                      value={contactEmail}
                      onChange={(e) => setContactEmail(e.target.value)}
                    />
                  </div>
                  <div className="w-100">
                    <div className="label">
                      {t("businessDetails.JobTitleLabel")}
                    </div>
                    <Textfield
                      type="text"
                      required
                      maxLength={150}
                      name="txt_contactJobTitle"
                      id="txt_contactJobTitle"
                      placeholder={`${t(
                        "businessDetails.jobTitlePlaceHolder"
                      )}`}
                      value={contactJobTitle}
                      onChange={(e) => setContactJobTitle(e.target.value)}
                    />
                  </div>
                </div>
                <div className="border-bottom mt-14px">&nbsp;</div>

                <div className="flex-end">
                  {showContinue && (
                    <button type="submit">
                      {" "}
                      {t("businessDetails.ContinueButton")} &nbsp;
                      <img src={continu} alt="" />
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessDetails;
