import React from "react";
import { useTranslation } from 'react-i18next';
import { APP_VERSION } from '../../config/env'; 

// COMMON FOOTER 
function Footer() {
  const { t } = useTranslation();
  return localStorage.getItem('token') ? ( 
    <div className="mb-10px">
      <div className="footer">
        <div>{t("footer.copyright")}</div>
        <div>{t("footer.privacy")}</div>
        <div>{APP_VERSION}</div>
      </div>
    </div>
  ) : (<></>);
}

export default Footer;
