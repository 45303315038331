import React from 'react';
import { useNavigate } from 'react-router-dom';
import { setFilterAccountId } from '../../services/localStorage';

interface IProps {
  title: string;
  value: string;
  storeId?:number;
}

const UrlTableField: React.FC<IProps> = ({ title, value, storeId }) => {
  const navigate = useNavigate();

  const onClickUrl = (): void => {
    if(storeId && storeId > 0) { 
      setFilterAccountId(storeId);
    }
    navigate(value);
  };
  return (
    <>
      <a className="url-field" onClick={onClickUrl}>
        {title} 
      </a>
    </>
  );
};

export default UrlTableField;
