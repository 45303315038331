import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../helpers/hook";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import PhoneInput from "react-phone-input-2";

import { setNotification } from "../../services/apiService";
import { fetchCartCount } from '../../services/qrCodesServices';
import { setFilterAccountId, getAdminUserRole, setFilterAccountName, setLastActivePageAPIData } from "../../services/localStorage";
import {
  fetchFilterAccounts,
  exportAccounts,
  newAccountCreate,
} from "../../services/accountServices";

// import { changeSearch } from "./accountsListSearchSlice";
import {
  setCurrentPage,
  selectAccountsListPaginationSlice,
} from "./accountsListPaginationSlice";
import {
  selectAccountsListDataSlice,
  setDataLoading,
} from "./accountsListDataSlice";
import {
  IAccountFilterRequestData,
  ICreateNewAccount,
} from "../../models/accountsInterfaces";

import { filterDateValues } from "../../utilities/dateFilterCalculation";

import { filterDigitsOnly } from "../../config/common_functions";
import { PERM_ACCOUNT_EXPORT, PERM_ADD_NEW_ACCOUNT, checkActionPermission } from "../../config/permissions";
import {
  PAGINATION_NUM_ROWS,
  ROUTE_STAFF_LIST,
  ROUTE_ACCOUNT_DETAILS,
  BUSINESS_CLASSIFICATION_LLC,
  PHONEINPUT_COUNTRIES,
  PHONEINPUT_COUNTRYCODE_EDITABLE,
  US_STATES_ARRAY,
  PAGENAME_ACCOUNTS,
  DATEFILTER_ALLDATES,
  USER_ROLE_ACCOUNT_MANAGER,
  USER_ROLE_ACCOUNT_USER
} from "../../config/env";

import FilterSection from "../../components/FilterSection";
import { ButtonModal } from "../../components/ButtonGroups/ButtonModal";
import { Textfield } from "../../components/TextField";
import { SelectField } from "../../components/SelectField";
import Table from "../../components/Table";
import Paginations from "../../components/Pagination";
import { AccountHeadings } from "../../components/Table/TableHeaders";


interface CustomizedState {
  calledFrom: string,
  prevPage: number,
  prevSearch: string,
  prevActive: string,
  prevFromDate: string,
  prevToDate: string,
  prevDateFilterType: string,
  prevSort: string,
  prevNumRowsPerPage: number
}

function AccountsList() {
  const adminUserRole = getAdminUserRole();

  // ************** TO SET DATE FILTER TO THISMONTH BY DEFAULT - START **************
  let defaultFromDate = "";
  let defaultToDate = "";
  // ************** TO SET DATE FILTER TO THISMONTH BY DEFAULT - END ***************

  const prevAPIDataString = localStorage.getItem('lastActivePageAPIData');
  const prevAPIData = prevAPIDataString ? JSON.parse(prevAPIDataString) : {};
  const pageName = PAGENAME_ACCOUNTS;
  
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const stateData = location.state as CustomizedState; // Type Casting, then you can get the params passed via router

  const prevPage = stateData && stateData.prevPage ? stateData.prevPage : 1;
  const prevSearch = stateData && stateData.prevSearch ? stateData.prevSearch : "";
  const prevActive = stateData && stateData.prevActive ? stateData.prevActive : "";
  const prevFromDate = stateData && stateData.prevFromDate ? stateData.prevFromDate : "";
  const prevToDate = stateData && stateData.prevToDate ? stateData.prevToDate : "";
  const prevDateFilterType = stateData && stateData.prevDateFilterType ? stateData.prevDateFilterType : "";
  const prevSort = stateData && stateData.prevSort ? stateData.prevSort : "";
  const prevNumRowsPerPage = stateData && stateData.prevNumRowsPerPage ? stateData.prevNumRowsPerPage : PAGINATION_NUM_ROWS;

  const [calledFrom, setCalledFrom] = useState(stateData && stateData.calledFrom ? stateData.calledFrom : "");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 805,
    maxHeight: 734,
    // overflow: "scroll",
    height: "100%",
    display: "block",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "13px",
    boxShadow: 24,
  };

  const { currentPage } = useAppSelector(
    selectAccountsListPaginationSlice
  );
  const { accounts, totalSize, loading } = useAppSelector(
    selectAccountsListDataSlice
  );

  const [showAddAccount, setShowAddAccount] = useState(false);
  const [filterSearch, setFilterSearch] = useState("");
  const [active, setActive] = useState("");
  const [fromDate, setfromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [dateFilterType, setDateFilterType] = useState(DATEFILTER_ALLDATES);
  const [fetchWithFilter, setFetchWithFilter] = useState(false);
  const [sortField, setSortField] = useState("hotel_name");
  const [numRowsPerPage, setNumRowsPerPage] = useState(PAGINATION_NUM_ROWS);

  const [initialAPICalled, setInitialAPICalled] = useState(false);

  // 'accept...' VARIABLES USED TO SET/RESET FILTERS ON REFRESH   
  const [acceptSearchText, setAcceptSearchText] = useState("");
  const [acceptDateFilterData, setAcceptDateFilterData] = useState("");
  const [acceptStatusFilterData, setAcceptStatusFilterData] = useState("");

  // FORM VARIABLES
  const [businessName, setBusinessName] = useState("");
  const [businessClassification, setBusinessClassification] = useState(
    BUSINESS_CLASSIFICATION_LLC.toString()
  );
  const [companyName, setCompanyName] = useState("");
  const [numRooms, setNumRooms] = useState("");
  const [numEmployees, setNumEmployees] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [formAddress, setFormAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [contactFirstName, setContactFirstName] = useState("");
  const [contactLastName, setContactLastName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactJobTitle, setContactJobTitle] = useState("");
  const [createUser, setCreateUser] = useState(false);

  const [resetStatusFilterToAll, setResetStatusFilterToAll] = useState(0);

  const businessClassList = [
    {
      id: BUSINESS_CLASSIFICATION_LLC,
      value: t("businessDetails.businessClassificationLLC"),
    },
  ];

  const callAccListAPI = (req: any) => {
    dispatch(fetchFilterAccounts(req));

    setLastActivePageAPIData({ pageName: pageName, apiData: req });
    setInitialAPICalled(true);
    // const promise = dispatch(fetchFilterAccounts(req));
    // setListFetchAccountsPromise([promise]);
  }

  // CART ITEMS NUMBER ONLY FOR ACCOUNT LOGINS
  const callCartCount = () => {
    if(Number(adminUserRole) === Number(USER_ROLE_ACCOUNT_MANAGER) || Number(adminUserRole) === Number(USER_ROLE_ACCOUNT_USER)) {
      dispatch(fetchCartCount({ accountId: "" })); // ACCOUNT ID NOT REQUIRED NOW
    }
  }

  useEffect(() => {
    if (calledFrom != 'accountDetailsPage') {
      // IF REFRESH, SET FILTERS AND CALL API WITH PREV. FILTER SETTINGS 
      if (prevAPIData.pageName && prevAPIData.pageName == pageName) {

        callAccListAPI(prevAPIData.apiData);
        setFilterSearch(prevAPIData.apiData.search);
        setActive(prevAPIData.apiData.active);
        setfromDate(prevAPIData.apiData.added_from_date);
        setToDate(prevAPIData.apiData.added_to_date);
        setDateFilterType(prevAPIData.apiData.dateFilterType);
        setSortField(prevAPIData.apiData.sort);
        setNumRowsPerPage(prevAPIData.apiData.numRowsPerPage);
        dispatch(setCurrentPage(prevAPIData.apiData.page));
        setInitialAPICalled(true);
        if (prevAPIData.apiData.search && prevAPIData.apiData.search.trim().length > 0) {
          setAcceptSearchText(prevAPIData.apiData.search);
        }
        if (prevAPIData.apiData.dateFilterType && prevAPIData.apiData.dateFilterType.trim().length > 0) {
          setAcceptDateFilterData(prevAPIData.apiData.dateFilterType);
        }
        if (prevAPIData.apiData.active && prevAPIData.apiData.active.trim().length > 0) {
          setAcceptStatusFilterData(prevAPIData.apiData.active);
        }
      }
      else {
        setAcceptDateFilterData(DATEFILTER_ALLDATES);
        fetchall();
        setInitialAPICalled(true);
      }
    }
    
    callCartCount(); 
  }, []);

  useEffect(() => {
    if (initialAPICalled && calledFrom != 'accountDetailsPage') {
      fetchall();
    }
  }, [currentPage, sortField]);

  useEffect(() => {
    if (initialAPICalled && calledFrom != 'accountDetailsPage') {
      const req: IAccountFilterRequestData = {
        userRole: adminUserRole ? adminUserRole : '0',
        page: currentPage,
        numRowsPerPage: numRowsPerPage,
        search: filterSearch.trim(),
        active: active,
        dateFilterType: dateFilterType,
        added_from_date: fromDate,
        added_to_date: toDate,
        sort: sortField,
      };
      callAccListAPI(req);
    }
  }, [numRowsPerPage]);

  useEffect(() => {
    if (loading == "accountCreated") {
      setShowAddAccount(false);
      setStatusFilter("");
      setResetStatusFilterToAll(resetStatusFilterToAll + 1);
      resetAddAccountForm();
    }
  }, [loading]);

  useEffect(() => {
    if (calledFrom == 'accountDetailsPage') {
      setSortField(prevSort);
      dispatch(setCurrentPage(prevPage));
      setNumRowsPerPage(prevNumRowsPerPage);
      setFilterSearch(prevSearch);
      setActive(prevActive);
      setfromDate(prevFromDate);
      setToDate(prevToDate);
      setDateFilterType(prevDateFilterType);
      fetchall();
      if (prevSearch && prevSearch.trim().length > 0) {
        setAcceptSearchText(prevSearch);
      }
      if (prevDateFilterType && prevDateFilterType.trim().length > 0) {
        setAcceptDateFilterData(prevDateFilterType);
      }
      if (prevActive && prevActive.trim().length > 0) {
        setAcceptStatusFilterData(prevActive);
      }
    }
  }, [calledFrom]);

  const setTextSearchFilter = (searchText: string) => {
    setFilterSearch(searchText);
    if (currentPage != 1) {
      dispatch(setCurrentPage(1));
    }
    else {
      const req: IAccountFilterRequestData = {
        userRole: adminUserRole ? adminUserRole : '0',
        page: currentPage,
        numRowsPerPage: numRowsPerPage,
        search: searchText.trim(),
        active: active,
        dateFilterType: dateFilterType,
        added_from_date: fromDate,
        added_to_date: toDate,
        sort: sortField,
      };
      callAccListAPI(req);
    }
  };

  const fetchall = (): void => {
    if (calledFrom == 'accountDetailsPage') {
      setFilterSearch(prevSearch);
      setActive(prevActive);
      setfromDate(prevFromDate);
      setToDate(prevToDate);
      setDateFilterType(prevDateFilterType);
      setSortField(prevSort);
      setNumRowsPerPage(prevNumRowsPerPage);
      dispatch(setCurrentPage(prevPage));
      const req: IAccountFilterRequestData = {
        userRole: adminUserRole ? adminUserRole : '0',
        page: prevPage,
        numRowsPerPage: prevNumRowsPerPage,
        search: prevSearch,
        dateFilterType: prevDateFilterType,
        active: prevActive,
        added_from_date: prevFromDate,
        added_to_date: prevToDate,
        sort: prevSort,
      };

      setCalledFrom("");
      callAccListAPI(req);
    } else if (fetchWithFilter) {
      dispatch(setDataLoading());
      const req: IAccountFilterRequestData = {
        userRole: adminUserRole ? adminUserRole : '0',
        page: currentPage,
        numRowsPerPage: numRowsPerPage,
        search: filterSearch.trim(),
        active: active,
        dateFilterType: dateFilterType,
        added_from_date: fromDate,
        added_to_date: toDate,
        sort: sortField,
      };
      callAccListAPI(req);
    } else {
      // let sVal = displaySearch;
      // if (displaySearch.length > 2) {
      //   dispatch(setDataLoading());
      //   changeSearch(displaySearch);
      // }
      // sVal = displaySearch;
      // listFetchAccountsPromise.forEach((p) => {
      //   p.abort();
      // });
      const req: IAccountFilterRequestData = {
        userRole: adminUserRole ? adminUserRole : '0',
        page: currentPage,
        numRowsPerPage: numRowsPerPage,
        search: filterSearch.trim(),
        active: active,
        dateFilterType: dateFilterType,
        added_from_date: fromDate,
        added_to_date: toDate,
        sort: sortField,
      };
      callAccListAPI(req);
    }
  };

  const handleSort = (sortField: string) => {
    setSortField(sortField);
  };

  const acceptPage = (e: any): void => {
    dispatch(setCurrentPage(e));
  };

  const onExportButton = () => {
    const req: IAccountFilterRequestData = {
      page: currentPage,
      numRowsPerPage: numRowsPerPage,
      search: filterSearch.trim(),
      active: active,
      dateFilterType: dateFilterType,
      added_from_date: fromDate,
      added_to_date: toDate,
      sort: sortField,
      userRole: adminUserRole ? adminUserRole : '0',
    };
    exportAccounts(req);
  };

  const clearFilters = () => {
    dispatch(setDataLoading());
    const req: IAccountFilterRequestData = {
      userRole: adminUserRole ? adminUserRole : '0',
      page: 1,
      numRowsPerPage: numRowsPerPage,
      search: "",
      active: "",
      dateFilterType: "",
      added_from_date: defaultFromDate,
      added_to_date: defaultToDate,
      sort: sortField,
    };
    callAccListAPI(req);
    acceptPage(1);
    setFilterSearch("");
    setActive("");

    setfromDate(defaultFromDate);
    setToDate(defaultToDate);
    setDateFilterType(DATEFILTER_ALLDATES);

    setFetchWithFilter(false);
    // setDisplaySearch("");
  };

  const setDateFilterDatesType = (fromDate: string, toDate: string, filterType: string) => {
    setDateFilterType(filterType);
    setfromDate(fromDate);
    setToDate(toDate);
    setFetchWithFilter(true);
    acceptPage(1);
    dispatch(setDataLoading());
    const req: IAccountFilterRequestData = {
      userRole: adminUserRole ? adminUserRole : '0',
      page: 1,
      numRowsPerPage: numRowsPerPage,
      search: filterSearch.trim(),
      active: active,
      dateFilterType: filterType,
      added_from_date: fromDate,
      added_to_date: toDate,
      sort: sortField,
    };
    callAccListAPI(req);
  };

  const setStatusFilter = (status: string) => {
    dispatch(setDataLoading());
    const req: IAccountFilterRequestData = {
      userRole: adminUserRole ? adminUserRole : '0',
      page: 1,
      numRowsPerPage: numRowsPerPage,
      search: filterSearch.trim(),
      active: status,
      dateFilterType: dateFilterType,
      added_from_date: fromDate,
      added_to_date: toDate,
      sort: sortField,
    };
    callAccListAPI(req);
    acceptPage(1);
    setFetchWithFilter(true);
    setActive(status);
  };

  const onClickView = (actiontype: string, accountId: string, accName: string) => {
    if (accountId) {
      setFilterAccountId(accountId);
      setFilterAccountName(accName);
      setTimeout(() => {
        navigate(ROUTE_STAFF_LIST);
      }, 500);
    }
  };

  const onClickTableData = (actiontype: string, data: {}) => {
    if (actiontype == "accountDetails") {
      let accId = data && data?.id ? data.id : 0;
      if (accId) {
        setFilterAccountId(accId);
        setFilterAccountName(data?.hotelName);
        setTimeout(() => {
          navigate(ROUTE_ACCOUNT_DETAILS + "/" + accId.toString(), { state: { prevPage: currentPage, prevSearch: filterSearch.trim(), prevActive: active, prevFromDate: fromDate, prevToDate: toDate, prevDateFilterType: dateFilterType, prevSort: sortField, prevNumRowsPerPage: numRowsPerPage } });
        }, 10);
      }
    }
  };


  const onHandleSave = (e) => {
    e.preventDefault();
    if (businessPhone.trim().length < 9) {
      setNotification(
        t("projectName"),
        t("messages.addAccountPhoneError"),
        "failed",
        "addAccount"
      );
    }
    else {
      const req: ICreateNewAccount = {
        business_classification: businessClassification,
        hotel_name: businessName,
        company_name: companyName,
        number_of_rooms: numRooms,
        number_of_employees: numEmployees,
        mobile_number: businessPhone,
        address: formAddress,
        city: city,
        state: state,
        zip: zipCode,
        first_name: contactFirstName,
        last_name: contactLastName,
        communication_email: contactEmail,
        job_title: contactJobTitle,
        create_user: createUser,
      };
      //newAccountCreate FUNTION IS ALSO USED TO CREATE ACCOUNT IN NAVIGATION PAGE (UPDATE BOTH SECTION, IF ANY CHANGES REQUIRED )
      dispatch(newAccountCreate(req));
    }
  };

  const resetAddAccountForm = () => {
    setBusinessName("");
    setBusinessClassification(BUSINESS_CLASSIFICATION_LLC.toString());
    setCompanyName("");
    setNumRooms("");
    setNumEmployees("");
    setBusinessPhone("");
    setFormAddress("");
    setCity("");
    setState("");
    setZipCode("");
    setContactFirstName("");
    setContactLastName("");
    setContactEmail("");
    setContactJobTitle("");
  };

  const numberOnly = (numVal: string, fieldType: string) => {
    let newValue = filterDigitsOnly(numVal);
    if (fieldType == "numRooms") {
      setNumRooms(((newValue)));
    }
    else if (fieldType == "numEmployees") {
      setNumEmployees(((newValue)));
    }
  };

  
  return (
    <div className="box-padding">
      <div className="">
        <FilterSection
          headind={`${t("accounts.headingAccountlist")}`}
          filterDate={true}
          filterStatus={true}
          setDateFilterDatesType={setDateFilterDatesType}
          setStatusFilter={setStatusFilter}
          parentPage={PAGENAME_ACCOUNTS}
          clearFilters={clearFilters}
          searchInput={true}
          searchInputPlaceholder={`${t("accounts.searchAccounts")}`}
          setTextSearchFilter={setTextSearchFilter}
          buttonLabel={`${t("accounts.addAccount")}`}
          addButton={checkActionPermission(adminUserRole, PERM_ADD_NEW_ACCOUNT) ? true : false}
          headerButtonFunction={(e) => setShowAddAccount(true)}
          resetDateFilter={{
            filterOption: DATEFILTER_ALLDATES,
            calledfor: "dateFilter",
          }}
          resetStatusFilterToAll={resetStatusFilterToAll}
          needDateAllOption={true}

          acceptSearchText={acceptSearchText}
          acceptDateFilterData={acceptDateFilterData}
          acceptStatusFilterData={acceptStatusFilterData}
        />

        <div className="table-box">
          <Table
            titles={AccountHeadings()}
            data={accounts}
            sortedFieldName={sortField}
            currentPage={currentPage}
            numRowsPerPage={numRowsPerPage}
            handleSort={handleSort}
            onClickView={onClickView}
            onClickTableData={onClickTableData}
          />


          {totalSize > 0 && (
            <Paginations
              currentPage={currentPage}
              numRowsPerPage={numRowsPerPage}
              totalPageCount={
                totalSize
                  ? totalSize < numRowsPerPage
                    ? 1
                    : totalSize % numRowsPerPage > 0
                      ? parseInt((totalSize / numRowsPerPage).toString(), 10) + 1
                      : parseInt((totalSize / numRowsPerPage).toString(), 10)
                  : 0
              }
              totalRows={totalSize}
              isTableLoading={false}
              setCurrentPage={acceptPage}
              setNumRowsPerPage={setNumRowsPerPage}
              recordsTypeName={`${t("common.accounts")}`}
              exportButton={(checkActionPermission(adminUserRole, PERM_ACCOUNT_EXPORT) && totalSize > 0) ? true : false}
              onExportButton={onExportButton}
            />
          )}
        </div>
      </div>
      <Modal
        open={showAddAccount}
        onClose={() => setShowAddAccount(false)}
        aria-labelledby={"Create Account Admin User"}
        aria-describedby=""
      >
        <Box sx={style}>
          <div className="w-100  manage-modal">

            <div className="space-between center border-bottom p-3">
              <h1> {t("accounts.ModalAddAccountHeading")}</h1>
              <span
                onClick={() => setShowAddAccount(false)}
                className="pointer"
              >
                <CloseRounded />
              </span>
            </div>


            <div className="  pt-3 bg-white">
              <form
                className="acc-form "
                action="onSubmit"
                onSubmit={(e) => onHandleSave(e)}
              >
                <div className="px-3">
                  <div className="business w-100 p-0px ">
                    <div className="flex gap-4 mb-18px">
                      <div className="w-100">
                        <div className="label">
                          {t("accounts.businessNameLabel")}
                        </div>
                        <Textfield
                          type="text"
                          required
                          maxLength={150}
                          name="txt_businessName"
                          id="txt_businessName"
                          placeholder={`${t(
                            "businessDetails.businessNamePlaceHolder"
                          )}`}
                          value={businessName}
                          onChange={(e) => setBusinessName(e.target.value)}
                        />
                      </div>
                      <div className="w-100">
                        <div className="label">
                          {t("businessDetails.parentCompanyLabel")}
                        </div>
                        <Textfield
                          type="text"
                          maxLength={150}
                          name="txt_companyName"
                          id="txt_companyName"
                          placeholder={`${t(
                            "businessDetails.companyNamePlaceHolder"
                          )}`}
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="flex gap-4 mb-18px">
                      <div className="w-100">
                        <div className="label">
                          {t("businessDetails.approximateroomsLabel")}
                        </div>
                        <Textfield
                          type="text"
                          required
                          maxLength={5}
                          min={1}
                          name="txt_numRooms"
                          id="txt_numRooms"
                          placeholder={`${t(
                            "businessDetails.roomsPlaceHolder"
                          )}`}
                          value={numRooms}
                          onChange={(e) => numberOnly(e.target.value, "numRooms")}
                        />
                      </div>
                      <div className="w-100">
                        <div className="label">
                          {t("businessDetails.numberEmployeesLabel")}
                        </div>
                        <Textfield
                          type="text"
                          required
                          maxLength={5}
                          min={1}
                          name="txt_numEmployees"
                          id="txt_numEmployees"
                          placeholder={`${t(
                            "businessDetails.employeesPlaceHolder"
                          )}`}
                          value={numEmployees}
                          onChange={(e) => numberOnly(e.target.value, "numEmployees")}
                        />
                      </div>
                    </div>
                    <div className="flex gap-4 mb-18px">
                      <div className="w-100 hidesl">
                        <div className="label">
                          {t("businessDetails.businessPhoneLabel")}
                        </div>
                        <PhoneInput
                          inputProps={{
                            name: `mobile`,
                            required: true,
                            autoFocus: false,
                          }}
                          inputClass="common-input"
                          onlyCountries={PHONEINPUT_COUNTRIES}
                          countryCodeEditable={PHONEINPUT_COUNTRYCODE_EDITABLE}
                          country={"us"}
                          prefix={'+'}
                          // specialLabel={`${t("adminUserList.mobileModal")}`}
                          value={businessPhone}
                          onChange={(code) => setBusinessPhone(code)}
                        />
                      </div>
                      <div className="w-100">
                        <div className="label">
                          {t("businessDetails.businessClassification")}
                        </div>
                        <SelectField
                          type="inputType"
                          name="sel_businessClass"
                          id="sel_businessClass"
                          className="w-100 "
                          value={businessClassification}
                          onChange={(e) =>
                            setBusinessClassification(e.target.value)
                          }
                          required
                          options={
                            businessClassList &&
                            businessClassList.map((item, index) => (
                              <>
                                <option value={item.id.toString()}>
                                  {item.value}
                                </option>
                              </>
                            ))
                          }
                        />
                      </div>
                    </div>

                    <div className="mb-18px">
                      <div className="label">
                        {t("businessDetails.addressLabel")}
                      </div>
                      <Textfield
                        type="text"
                        required
                        maxLength={250}
                        name="txt_address"
                        id="txt_address"
                        placeholder={`${t(
                          "businessDetails.addressPlaceHolder"
                        )}`}
                        value={formAddress}
                        onChange={(e) => setFormAddress(e.target.value)}
                      />
                    </div>

                    <div className="flex gap-4 mb-18px">
                      <div className="w-25">
                        <div className="label">
                          {t("businessDetails.cityLabel")}
                        </div>
                        <Textfield
                          type="text"
                          required
                          maxLength={150}
                          name="txt_city"
                          id="txt_city"
                          placeholder={`${t(
                            "businessDetails.cityPlaceHolder"
                          )}`}
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                      <div className="w-25">
                        <div className="label">
                          {t("businessDetails.stateLabel")}
                        </div>
                        <SelectField
                          type="inputType"
                          name="txt_state"
                          id="txt_state"
                          className={state == "" ? " select-placeholder " : ""}
                          placeHolder={t("businessDetails.stateLabel")}
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          required
                          options={US_STATES_ARRAY &&
                            US_STATES_ARRAY.map((item, index) => (
                              <>
                                {index == 0 && <>
                                  <option value="" >{t("businessDetails.stateLabel")}</option>
                                </>}
                                <option value={item.stateCode} style={{ color: "#000000" }}>
                                  {item.stateName}
                                </option>
                              </>
                            ))}
                        />
                      </div>
                      <div className="w-25">
                        <div className="label">
                          {t("businessDetails.zipcodeLabel")}
                        </div>
                        <Textfield
                          type="text"
                          required
                          maxLength={150}
                          name="txt_zipCode"
                          id="txt_zipCode"
                          placeholder={`${t(
                            "businessDetails.zipcodePlaceHolder"
                          )}`}
                          value={zipCode}
                          onChange={(e) => setZipCode(e.target.value)}
                        />
                      </div>
                      <div className="w-25"></div>
                    </div>

                    <h3>{t("businessDetails.contactInformationHeading")}</h3>
                    <div className="flex gap-4 mb-18px">
                      <div className="w-100">
                        <div className="label">
                          {t("businessDetails.firstNameLabel")}
                        </div>
                        <Textfield
                          type="text"
                          required
                          maxLength={150}
                          name="txt_contactFirstName"
                          id="txt_contactFirstName"
                          placeholder={`${t(
                            "businessDetails.lastNameLabel"
                          )}`}
                          value={contactFirstName}
                          onChange={(e) =>
                            setContactFirstName(e.target.value)
                          }
                        />
                      </div>
                      <div className="w-100">
                        <div className="label">
                          {t("businessDetails.lastNameLabel")}
                        </div>
                        <Textfield
                          type="text"
                          required
                          maxLength={150}
                          name="txt_contactLastName"
                          id="txt_contactLastName"
                          placeholder={`${t(
                            "businessDetails.lastNameLabel"
                          )}`}
                          value={contactLastName}
                          onChange={(e) => setContactLastName(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="flex gap-4 mb-18px">
                      <div className="w-100">
                        <div className="label">
                          {t("businessDetails.emailLabel")}
                        </div>
                        <Textfield
                          type="email"
                          required
                          maxLength={150}
                          name="txt_contactEmail"
                          id="txt_contactEmail"
                          placeholder={`${t("businessDetails.emailLabel")}`}
                          value={contactEmail}
                          onChange={(e) => setContactEmail(e.target.value)}
                        />
                      </div>
                      <div className="w-100">
                        <div className="label">
                          {t("businessDetails.JobTitleLabel")}
                        </div>
                        <Textfield
                          type="text"
                          required
                          maxLength={150}
                          name="txt_contactJobTitle"
                          id="txt_contactJobTitle"
                          placeholder={`${t(
                            "businessDetails.jobTitlePlaceHolder"
                          )}`}
                          value={contactJobTitle}
                          onChange={(e) => setContactJobTitle(e.target.value)}
                        />
                      </div>
                    </div>


                    <div className="flex gap-4 mb-10px">
                      <div className="w-100">
                        <label className="label create-user flex-start gap-2 align-items-center">
                          <input
                            type="checkbox"
                            checked={createUser}
                            onChange={(e) => setCreateUser(e.target.checked)}
                            className="mb-7px"
                          />
                          {t("businessDetails.createUser")}
                        </label>
                      </div>
                      <div className="w-100"></div>
                    </div>
                  </div>
                </div>

                <div className="center border-bottom"></div>

                <div className="px-3 pt-3">
                  <div className="flex w-100 gap-4">
                    <ButtonModal
                      type="cancel"
                      onClick={(e) => {
                        setShowAddAccount(false);
                        resetAddAccountForm();
                      }
                      }
                      buttonLabel={t("common.cancel")}
                    />
                    <ButtonModal
                      buttonLabel={t("accounts.createAccount")}
                      buttonType="submit"
                    />
                  </div>
                </div>
              </form>
            </div>

          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default AccountsList;
