import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAccountGroupsList, IAccountNameId, IAllAccountList } from '../../models/allAccountListInterface';
import { fetchAllAccountList, fetchPrimaryUserAccountList } from '../../services/headerService';
import { fetchAccountGroupsList } from "../../services/poolServices"; 

import { RootState } from '../../store';

export interface FilterState {
  allAccountList: IAllAccountList[];
  accountsLoading: 'idle' | 'loading' | 'succeeded' | 'error';
  accountGroupsLoading: 'idle' | 'loading' | 'succeeded' | 'error';
  accountGroupsList: IAccountGroupsList[]; 
  primaryUserAccount:[];
}

const initialState: FilterState = {
  accountsLoading: 'idle',
  accountGroupsLoading: 'idle',
  allAccountList: [],
  accountGroupsList: [],
  primaryUserAccount:[],
};

export const filterAllAccountListSlice = createSlice({
  name: 'filterAllAccountListSlice',
  initialState,
  reducers: {
    changeOptions: (state, action: PayloadAction<any>) => {
      state.allAccountList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllAccountList.pending, (state) => {
      state.allAccountList = [];
      state.accountsLoading = 'loading';
    });
    builder.addCase(fetchAllAccountList.fulfilled, (state, action) => {
      const hotels = action.payload as IAccountNameId[];
      let opts: IAllAccountList[] = [];
      hotels.map((hotelNameId) => {
        
      // 22/AUG/2023 - CLIENT ASK TO DISABLE THE VALIDATION WHHILE ADDING USER AND SELECTING ACCOUNT THAT CORRESPONDING ACCOUNT LIST IS SHOWING.!!! (NEED FOR LATER USER)
        // opts.push({ value: hotelNameId.id.toString(), label: hotelNameId.hotelName, active: hotelNameId.isActive ? true : false, payout: hotelNameId.payout ,isChecked:false ,disable:true});
        opts.push({ value: hotelNameId.id.toString(), label: hotelNameId.hotelName, active: hotelNameId.isActive ? true : false, payout: hotelNameId.payout ,isChecked:false });
      }); // .filter(hItem => hItem.isActive==true) COMMENT FOR LATER USE

      state.allAccountList = opts;

      state.accountsLoading = 'succeeded';
    });
    builder.addCase(fetchAllAccountList.rejected, (state, { payload }: any) => {
      state.accountsLoading = 'error';
    });
    
    builder.addCase(fetchAccountGroupsList.pending, (state) => {
      state.accountGroupsList = [];
      state.accountGroupsLoading = 'loading';
    });
    builder.addCase(fetchAccountGroupsList.fulfilled, (state, action) => {
      state.accountGroupsList = action.payload as IAccountGroupsList[];
      state.accountGroupsLoading = 'succeeded';
    });
    builder.addCase(fetchAccountGroupsList.rejected, (state, { payload }: any) => {
      state.accountGroupsLoading = 'error';
    });
  
    builder.addCase(fetchPrimaryUserAccountList.pending, (state) => {
      state.accountsLoading = 'loading';
    });
    builder.addCase(fetchPrimaryUserAccountList.fulfilled, (state, action) => {
      state.primaryUserAccount=action.payload;
      state.accountsLoading = 'succeeded';
    });
    builder.addCase(fetchPrimaryUserAccountList.rejected, (state, { payload }: any) => {
      state.accountsLoading = 'error';
    });


  },
});

// Action creators are generated for each case reducer function
export const { changeOptions } = filterAllAccountListSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const FilterAllAccountListSlice = (state: RootState) =>
  state.filterAllAccountList;

export const filterAllAccountListSliceReducer = filterAllAccountListSlice.reducer;
