import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import PhoneInput from "react-phone-input-2";

import { ButtonModal } from '../../components/ButtonGroups/ButtonModal';
import { SelectField } from '../../components/SelectField';
import { Textfield } from '../../components/TextField';
import { IAccListAllReq } from '../../models/accountsInterfaces';
import { getAdminUserRole } from '../../services/localStorage';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { fetchAllAccountList } from '../../services/headerService';
import { FilterAllAccountListSlice } from '../../components/FilterAccount/FilterAllAccountListSlice';
import { IStaffRegWithoutEmail } from '../../models/staffRegisterWithoutEmailInterfaces';
import { staffRegWithoutEmail } from '../../services/staffRegisterWithoutEmailServices';
import { checkMenuPagePermission } from '../../config/permissions';
import { PAGENAME_TEMP_STAFF_ADD, PHONEINPUT_COUNTRYCODE_EDITABLE } from '../../config/env';

import { isEmail } from "../../helpers/validators";
import { ICountry, ICountryCode } from '../../models/countryStateInterfaces.ts';
import { fetchCountryStates } from "../../services/countryStateServices.tsx";
import { selectAccountStaffDataSlice } from "../StaffDetail/staffProfileSlice.ts"; // "./staffProfileSlice";

import { setNotification } from "../../services/apiService";

import { staffRegisterWithoutEmailSlice } from "./staffRegisterWithoutEmailSlice.ts"; 

const StaffRegisterWithoutEmail = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const adminUserRole = getAdminUserRole();
    const { allAccountList } = useAppSelector(FilterAllAccountListSlice);
    const { countryStates } = useAppSelector(selectAccountStaffDataSlice);
    const { regLoading } = useAppSelector(staffRegisterWithoutEmailSlice); 
      
    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [accountName, setaccountName] = useState("");
    const [mobileNumber, setMobileNumber] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [country, setCountry] = useState<string>("");

    const defCountryList : ICountry[]= [{ countryCode: t("defaultCountry"), countryName: t("defaultCountryName") } ];  
    const [countryList, setCountryList] = useState <ICountry[]> (defCountryList);

    const [phoneCountries, setPhoneCountries] = useState ([t("defaultCountry")]);

    useEffect(() => {
        // FETCH ALL COUNTRY AND STATES LIST 
        let dataObj : ICountryCode = { countryCode : "" }
        dispatch(fetchCountryStates(dataObj)); 

        // FETCH ALL ACCOUNTS 
        let apiData: IAccListAllReq = {
            searchString: "",
            userRole: Number(adminUserRole)
        }
        dispatch(fetchAllAccountList(apiData));
    }, []);

    useEffect(() => {
        if(regLoading==='succeeded') {
            resetValue();
        }
    },[regLoading]);  

    useEffect(() => {
        if(!countryStates || countryStates.length===0) {
          setCountryList(defCountryList); 
          setPhoneCountries([t("defaultCountry")]); 
        }
        else {
          let arrCountries: ICountry[] = []; 
          let phCountires = [];   
          countryStates.forEach(item => {
            phCountires.push(item.countryCode.toLowerCase());
            arrCountries.push({ countryCode: item.countryCode, countryName: item.countryName}); 
          }); 
          setCountryList(arrCountries); 
          setPhoneCountries(phCountires); 
        }
      }, [countryStates]);

    const resetValue = () => {
        setaccountName("");
        setfirstName("");
        setlastName("");
        setMobileNumber("");
        setEmail("");
        setCountry(""); 
    }

    const registerStaff = (e) => {
        e.preventDefault();
        if(email.trim().length > 0 && isEmail(email.trim()).length > 0) {
            setNotification(
                t("projectName"),
                t("staffRegister.invalidEmail"),
                "failed",
                "linkCopiedStaffDetail"
            );
        }
        else {
            var staffRegForm: IStaffRegWithoutEmail = {
                hotel: accountName,
                firstName: firstName,
                lastName: lastName,
                country: country, 
                phone: mobileNumber, 
                email: email 
            }
            dispatch(staffRegWithoutEmail(staffRegForm));
        }  
    }

    return (
        <>
        <div className="box-padding center">
            <div className="box max-width-500 ">
                {checkMenuPagePermission(adminUserRole, PAGENAME_TEMP_STAFF_ADD) ?
                    <form onSubmit={(e) => registerStaff(e)} className="terms account pb-3 px-4">
                        <h1 className='text-center my-3'>{t("staffRegister.staffRegistration")}</h1>

                        <div className="px-3 pt-4 pb-2 bg-white">
                            <div className=" w-100">
                                <div className="label">
                                    {t("accountDetails.accountModal")}
                                </div>
                                <div className="percentage-input w-100">
                                    <SelectField
                                        type="inputType"
                                        name="txt_state"
                                        id="txt_state"
                                        className={
                                            accountName == "" ? " select-placeholder " : ""
                                        }
                                        placeHolder={t("businessDetails.stateLabel")}
                                        value={accountName}
                                        onChange={(e) => setaccountName(e.target.value)}
                                        required
                                        options={
                                            allAccountList &&
                                            allAccountList.map((item, index) => (
                                                <>
                                                    {index == 0 && (
                                                        <>
                                                            <option value="">
                                                                Select
                                                            </option>
                                                        </>
                                                    )}
                                                    <option
                                                        value={item.value}
                                                        style={{ color: "#000000" }}
                                                    >
                                                        {item.label}
                                                    </option>
                                                </>
                                            ))
                                        }
                                    />
                                </div>
                            </div>
                            <div className=" w-100  my-3">
                                <div className="label">
                                    {t("staffRegister.firstName")}
                                </div>
                                <Textfield
                                    type="text"
                                    value={firstName}
                                    name="group_name"
                                    id="group_name"
                                    required={true}
                                    placeholder={`${t("staffRegister.firstName")}`}
                                    onChange={(e) => setfirstName(e.target.value)}
                                    maxLength={100}
                                // className={newGroupNameError ? " input-error " : ""}
                                />
                            </div>
                            <div className=" w-100  my-3">
                                <div className="label">
                                    {t("staffRegister.lastName")}
                                </div>
                                <Textfield
                                    type="text"
                                    value={lastName}
                                    name="group_name"
                                    id="group_name"
                                    required={true}
                                    placeholder={`${t("staffRegister.lastName")}`}
                                    onChange={(e) => setlastName(e.target.value)}
                                    maxLength={100}
                                />
                            </div>
                            <div className=" w-100  my-3">
                                <div className="label">
                                    {t("staffRegister.email")}
                                </div>
                                <Textfield
                                    type="text"
                                    value={email}
                                    name="email"
                                    id="email"
                                    required={false} 
                                    placeholder={`${t("staffRegister.email")}`}
                                    onChange={(e) => setEmail(e.target.value)}
                                    maxLength={150}
                                />
                            </div>

                            <div className=" w-100  my-3">
                                <div className="label">
                                    {t("staffRegister.country")}
                                </div>
                                <SelectField
                                    type="inputType"
                                    name="txt_country"
                                    id="txt_country"
                                    className={""}
                                    placeHolder={t("staffprofile.country")}
                                    value={country}
                                    onChange={(e) => {
                                        setCountry(e.target.value);
                                        }
                                    }
                                    options={countryList &&
                                    countryList.map((item, index) => (
                                        <>
                                        {index == 0 && <>
                                            <option value="" >{t("staffRegister.country")}</option>
                                        </>}
                                        <option value={item.countryCode} style={{ color: "#000000" }}>
                                            {item.countryName}
                                        </option>
                                        </>
                                    ))}
                                />
                            </div>

                            {country && country.length > 0 && <div className=" w-100  my-3">
                                {/* <div className="label">
                                    {t("staffRegister.mobileNumber")}
                                </div> */}
                                <PhoneInput
                                    inputProps={{
                                        name: `mobile`,
                                        required: false,
                                        autoFocus: false,
                                    }} 
                                    inputClass={"common-input"} 
                                    onlyCountries={phoneCountries}
                                    country={(country && country.length > 0) ? country.toLowerCase() : t("defaultCountry").toLowerCase()}
                                    countryCodeEditable={PHONEINPUT_COUNTRYCODE_EDITABLE}  
                                    prefix={'+'}
                                    value={mobileNumber}
                                    onChange={(code) => {
                                        setMobileNumber(code);
                                        }
                                    }
                                />
                            </div>}
                        </div>

                        <div className="px-3 py-4">
                            <div className="flex w-100 gap-4">
                                <ButtonModal
                                    type="cancel"
                                    onClick={() => resetValue()}
                                    buttonLabel={t("common.cancel")}
                                />
                                <ButtonModal
                                    buttonType="submit"
                                    buttonLabel={t("staffRegister.registerNow")}
                                />
                            </div>
                        </div>
                    </form>:   <h1 className='text-center my-3'>{t("staffRegister.messageDenied")}</h1>
                }
            </div>
        </div>
        </>
    )
}

export default StaffRegisterWithoutEmail